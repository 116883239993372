import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useState, useMemo, useEffect } from 'react';
import { serialize } from 'object-to-formdata';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom'; //useHistory, useLocation,
import Select from 'react-select';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import * as TemplatesRedux from '../templates/store/TemplatesRedux';
import * as UserRedux from '../../modules/users/store/UsersRedux';

import { axiosInstance } from '../../../network/apis';
import { RootState } from '../../../setup/redux/Store';
import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { usePermissions } from '../../hooks/usePermissions';
import { CloseButtonRedShadowed } from '../../shared/CloseButtonRedShadowed';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import { ICompany } from '../../types/companies';
import { useConfigureTemplateUserSchema } from './useConfigureTemplateUserSchema';
import { UsersDataListModel } from '../users/types/usersList';
import { UserModel } from '../auth/models/UserModel';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { endpoints, ID } from '../../../_metronic/constants/paths';
import { ErrorMessage } from '../../shared/ErrorMessage';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { initialUsersConfigPayload } from './helpers/templateConfigHelpers';
import { boxStyle } from './boxStyle';

type Props = {
  setShowUserModal: Function;
};
// const temporaryTemplate: string = 'temporaryTemplate';

export const ConfigureTemplateUserName: FC<Props> = ({ setShowUserModal }) => {
  usePermissions('create-template', 'Create Template');
  const { validationSchema, initialValues } = useConfigureTemplateUserSchema();

  const dispatch = useDispatch();

  const params: any = useParams();

  const { formatMessage } = useIntl(); //, locale

  const [loading, setLoading] = useState(false);

  const { setShowExitPrompt } = useExitPrompt(false);

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const users: UsersDataListModel = useSelector<RootState>((state) => {
    return state.USER_users.Users;
  }, shallowEqual) as UsersDataListModel;

  const userOptions = useMemo(
    () => users?.data?.map((user) => ({ label: user.name, value: user.id })),
    [users]
  );

  const createTemplate = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialUsersConfigPayload(values);
    const formData = serialize(payload, { indices: true });
    try {
      let response: AxiosResponse;
      if (params.templateId) {
        if (payload.variable_users.length === 0) formData.append('variable_users', '');
        formData.append('_method', 'PUT');
        response = await axiosInstance.post(
          endpoints.updateCompanyTemplates(companyDetails.id, underConstructionTemplate?.id),
          formData
        );
      } else {
        response = await axiosInstance.post(
          endpoints.companyTemplates(companyDetails.id),
          formData
        );
      }
      // const { id } = response.data;

      const newUnderConstruction: TemplatesRedux.UnderConstructionTemplate = {
        ...underConstructionTemplate,
        ...response.data,
      } as TemplatesRedux.UnderConstructionTemplate;

      dispatch(
        TemplatesRedux.actions.fulfillUnderConstructionTemplate({
          underConstructionTemplate: newUnderConstruction,
        })
      );
      resetForm();
      toast.success(formatMessage({ id: 'The list of users names added successfully' }));
      //   history.push(location.pathname.replace('/create', `/update/${id}`));
      setShowUserModal(false);
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const getUsers = () =>
    dispatch(UserRedux.actions.getUsers({ companyId: companyDetails.id, params: { per_page: 0 } }));

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => createTemplate(values, resetForm),
  });
  const { setFieldValue, values } = formik;

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    if (Number(params?.templateId) === underConstructionTemplate?.id) {
      setFieldValue(
        'variable_users',
        underConstructionTemplate.variable_users.map((user: any) => user.id)
      );
    }
  }, [underConstructionTemplate]);

  useEffect(() => {
    if (params?.templateId) {
      if (!users?.data?.length) getUsers();
    }
  }, [params?.templateId]);

  return (
    <FormikProvider value={formik}>
      <Form id='create_template_users' noValidate autoComplete='none'>
        {Object.keys(formik.touched).length > 0 && (
          <Prompt message={$t('Are you sure you want to ignore the changes?')} />
        )}
        <div
          className='p-5 dialog-defaults b-radius-20'
          style={{ maxHeight: 'fit-content', width: '35vw' }}
        >
          <div className='row'>
            <>
              <div className='mt-2 row'>
                <div className='col-12'>
                  <Select
                    onChange={(value) =>
                      setFieldValue('variable_users', [...values?.variable_users, value?.value])
                    }
                    onFocus={() => getUsers()}
                    placeholder={$t('Users')}
                    onBlur={() => formik.setFieldTouched('variable_users', true)}
                    options={userOptions?.filter(
                      (option) => !values?.variable_users?.includes(option?.value)
                    )}
                    value={null}
                    className='react-select smaller'
                    id='variable_users'
                  />
                  <ErrorMessage name='variable_users' formik={formik} />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='mt-2' style={{ ...boxStyle, overflowY: 'auto' }}>
                    <div className='row p-5'>
                      {values?.variable_users?.map((id: number) => {
                        const user: UserModel = users?.data?.find(
                          (user) => user?.id === id
                        ) as UserModel;

                        return (
                          <div className='col-6'>
                            <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                              <div>
                                <div className='symbol symbol-circle avatar-border symbol-20px symbol-md-30px me-1'>
                                  <img
                                    alt=''
                                    src={user?.image || toAbsoluteUrl('/media/avatars/blank.png')}
                                  />
                                </div>
                                <p className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'>
                                  {user?.name}
                                </p>
                              </div>
                              <CloseButtonRedShadowed
                                onClick={() =>
                                  setFieldValue(
                                    'variable_users',
                                    values.variable_users.filter((item: ID) => item !== id)
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
          <SaveCancelBtnsGroup
            saveBtnLabel='save'
            formik={formik}
            loading={loading}
            cancelOnClick={() => {
              setShowUserModal(false);
            }}
          />
        </div>
      </Form>
    </FormikProvider>
  );
};
