import { FC } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

type Props = any;

export const PreviewPDF: FC<Props> = ({ isButton = false, ...props }) => {
  return isButton ? (
    <button className='btn btn-primary btn-sm btn-primary-shadow mx-2' {...props}>
      <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-3' />
      {props.title ? `${props.title}` : null}
    </button>
  ) : (
    <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' {...props}>
      <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-3' />
    </a>
  );
};
