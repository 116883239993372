/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';

import { toAbsoluteUrl } from '../../../_metronic/helpers';
import * as auth from '../../../app/modules/auth/store/AuthRedux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { delayToCookies } from '../../../utils/cookies';
import { axiosInstance } from '../../../network/apis';
import { UserModel } from '../../modules/auth/models/UserModel';
import { PrivateRoutes } from '../../../_metronic/constants/ROUTES';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../setup/redux/Store';

export const VerificationPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = () => {
    dispatch(auth.actions.logout());
  };

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  useEffect(() => {
    if (user.active) history.push(PrivateRoutes.Homepage);
  }, [user, history]);

  const [time, setTime] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(0);

  useEffect(() => {
    if (moment(Cookies.get('verifyAfter')).isAfter(moment())) {
      const diff = moment(Cookies.get('verifyAfter')).diff(moment());
      setTime(Math.random());
      setResendDisabled(diff);
    } else {
      Cookies.remove('verifyAfter');
    }
  }, [resendDisabled, time]);

  window.addEventListener('load', (ev) => {
    ev.preventDefault();
    if (localStorage.getItem('rememberUser') !== 'true') {
      if (performance.navigation.type === 1) {
        return;
      }
      console.info('User did not select remember me');
      dispatch(auth.actions.logout());
      localStorage.removeItem('presist:user');
      localStorage.removeItem('rememberUser');
    }
    return;
  });

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`,
        }}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='#' className='pt-lg-20'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/AHAD-logo.png')} className='h-50px' />
          </a>
          <div className='pt-lg-10 mb-10'>
            <div className='text-center'></div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-350px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
          <h3 className='mt-7'>
            <FormattedMessage id='A verification link has been sent to your Email' />
          </h3>
          <div className='d-flex justify-content-center gap-3'>
            <button onClick={() => logout()} className='btn btn-secondary'>
              <FormattedMessage id='Verify later' />
            </button>
            <button
              disabled={resendDisabled > 5}
              onClick={async () => {
                try {
                  axiosInstance.post('/send-verify-email');
                  setTime(60);
                  delayToCookies(60, 'verifyAfter');
                } catch (err) {
                  //
                }
              }}
              className='btn btn-secondary'
            >
              <FormattedMessage id='resend_btn' />{' '}
              {Math.ceil(resendDisabled / 1000) > 1 && (
                <>
                  {Math.ceil(resendDisabled / 1000)}
                  <FormattedMessage id='seconds' />
                </>
              )}{' '}
            </button>
          </div>
          <p className='mt-3'>{resendDisabled > 5 && <FormattedMessage id='resend_message' />}</p>
        </div>
      </div>
    </div>
  );
};
