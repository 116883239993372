import createSagaMiddleware from 'redux-saga';
import { configureStore, getDefaultMiddleware, EnhancedStore } from '@reduxjs/toolkit';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { reduxBatch } from '@manaflair/redux-batch';
import { persistStore } from 'redux-persist';

import { rootReducer, rootSaga } from './RootReducer';
import { IAuthActionTypes } from '../../app/modules/auth';

export const configureStoreWithMiddlewares = (initialState = {}): EnhancedStore => {
  const config = {
    whitelist: [IAuthActionTypes.SetUser, IAuthActionTypes.UserLoaded],
  };

  const sagaMiddleware = createSagaMiddleware();
  const middleware = [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
    sagaMiddleware,
  ];

  if (process.env.NODE_ENV !== 'test') {
    middleware.push(createStateSyncMiddleware(config));
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [reduxBatch],
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const store = configureStoreWithMiddlewares();

initMessageListener(store);

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
