import { Meta } from '../../../../types/Meta';

export enum VatRatesTypes {
  'Income' = 1,
  'Expenditure' = 2,
  'Income return' = 3,
  'Expenditure return' = 4,
}
export enum IncomeCodeTypesTypes {
  'Sales subject to the basic ratio 15%' = 1,
  'Sales subject to the basic ratio 5%' = 1.1,
  'Sales tax for government agencies' = 1.2,
  'Sales that state bears its tax' = 2,
  'Zero-rated sales' = 3,
  'Exports' = 4,
  'Exempt sales' = 5,
  'Out of the range' = 0,
}

export enum ExpenditureCodeTypesTypes {
  'Purchases subject to the basic ratio 15%' = 7,
  'Purchases subject to the basic ratio 5%' = 7.1,
  'VAT paid by customs 15%' = 8,
  'VAT paid by customs 5%' = 8.1,
  'Reverse charge purchases 15%' = 9,
  'Reverse charge purchases 5%' = 9.1,
  'Zero-rated purchases' = 10,
  'Exempt purchases' = 11,
  'VAT cannot be deducted' = 0,
}

export enum TransactionTypes {
  'Domestic' = 1,
  'International' = 2,
  'GCC' = 3,
}
export enum TaxAbilityTypes {
  'Taxable' = 1,
  'Zero' = 2,
  'Exempt' = 3,
}
export enum CategoryTypes {
  'Basic' = 1,
  'Optional' = 2,
}

export interface IVatRate {
  is_active: number;
  id: number;
  code: string;
  name: { ar: string; en: string };
  code_type: {
    ar: string;
    en: string;
  };
  description: {
    ar: string;
    en: string;
  };
  type: number;
  transaction_type: number;
  country: string;
  tax_ability: number;
  tax_rate: string;
  created_at: string;
  code_type_index: number;
  rules: [];
  categories: [
    {
      [key: string]: string;
    }
  ];
  can_delete: boolean;
}

export interface VatRatesDataListModel extends Meta {
  data: Array<IVatRate>;
}
