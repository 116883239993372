/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import useWindowSize from '../../../hooks/useWindowSize';
import { LocaleToggler } from '../../../shared/LocaleToggler';
import { FancySide } from '../components/FancySide';
import { LogoAndLoginLink } from '../components/LogoAndRedirectionLink';
import { ResetPassword } from '../components/ResetPassword';

export const ResetPasswordContainer = () => {
  const { width } = useWindowSize();
  return (
    <div
      className={clsx('d-flex flex-row p-10 rounded', {
        'mh-90': width > 1440,
      })}
    >
      <div
        className={clsx('bg-gray-100  shadow-sm p-4 p-lg-10 flex-fill bg-white bls-radius-25', {
          'b-radius-25': width < 992,
          'py-12': width < 992,
          'px-8': width < 992,
        })}
        style={{ width: width < 992 ? 'auto' : '50%' }}
      >
        <LogoAndLoginLink direction='signup' />
        <div className='mb-5 mt-6 d-flex justify-content-between align-items-center'>
          <h1 className='text-dark mb-3'>
            <FormattedMessage id='Reset your password' />
          </h1>
          <LocaleToggler />
        </div>
        <ResetPassword />
      </div>
      <FancySide />
    </div>
  );
};
