import { FC, ReactElement } from 'react';
import { ConfigNewVatRate } from '../../ConfigNewVatRate';

type TAB = {
  id: number;
  tabId: string;
  tabIcon: string;
  viewcomponent: ReactElement;
  editComponent: ReactElement;
  createComponent: ReactElement;
  // viewparam: string;
  // editparam: string;
  // createparam: string;
};

export const VAT_RATES_TABS: TAB[] = [
  {
    id: 1,
    tabId: 'Create Vat Rate',
    tabIcon: 'fa-percent',
    viewcomponent: <ConfigNewVatRate mode={'view'} displayRequiredSection={1} />,
    editComponent: <ConfigNewVatRate mode={'edit'} displayRequiredSection={1} />,
    createComponent: <ConfigNewVatRate mode={'create'} displayRequiredSection={1} />,
  },
  {
    id: 2,
    tabId: 'Create Code Rule',
    tabIcon: 'fa-clipboard-list-check',
    viewcomponent: <ConfigNewVatRate mode={'view'} displayRequiredSection={2} />,
    editComponent: <ConfigNewVatRate mode={'edit'} displayRequiredSection={2} />,
    createComponent: <ConfigNewVatRate mode={'create'} displayRequiredSection={2} />,
  },
  {
    id: 3,
    tabId: 'Categories',
    tabIcon: 'fa-clipboard-list-check',
    viewcomponent: <ConfigNewVatRate mode={'view'} displayRequiredSection={3} />,
    editComponent: <ConfigNewVatRate mode={'edit'} displayRequiredSection={3} />,
    createComponent: <ConfigNewVatRate mode={'create'} displayRequiredSection={3} />,
  },
];

export const vatRateParamsMapView = new Map(VAT_RATES_TABS.map((tab: TAB) => [tab.id, { ...tab }]));
export const vatRateParamsMapEdit = new Map(VAT_RATES_TABS.map((tab: TAB) => [tab.id, { ...tab }]));
export const vatRateParamsMapCreate = new Map(
  VAT_RATES_TABS.map((tab: TAB) => [tab.id, { ...tab }])
);

type VatRateDetailsHandlerProps = { currentView: number; mode: string };

export const VatRateDetailsHandler: FC<VatRateDetailsHandlerProps> = ({ currentView, mode }) => {
  if (mode === 'view') {
    return vatRateParamsMapView.get(currentView)?.viewcomponent || null;
  } else if (mode === 'edit') {
    return vatRateParamsMapEdit.get(currentView)?.editComponent || null;
  } else if (mode === 'create') {
    return vatRateParamsMapCreate.get(currentView)?.createComponent || null;
  } else {
    return null;
  }
};
