import { FC, useEffect, useMemo, useState } from 'react'; //useMemo,
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'; //, useParams

// import * as TableRedux from '../../../store/TableRedux';
import * as CodeRulesRedux from '../../../modules/admins/code_rules/store/CodeRulesRedux';

import { PageTitle } from '../../../../_metronic/layout/core';
import { usePermissions } from '../../../hooks/usePermissions';
import { RootState } from '../../../../setup/redux/Store';
import { TableActions, TableData, TableSelection } from '../../../shared/tables/classes'; // DateViewer,
// import { $t } from '../../../../_metronic/i18n/formatMessage';
import { LOCALE } from '../../../../_metronic/helpers/typescript';

import {
  ICodeRule,
  CodeRulesDataListModel,
} from '../../../modules/admins/code_rules/types/CodeRulesModel';
import {
  CodeRulesCategoryTypeMap,
  CodeRulesCategoryTypesMap,
} from '../../../../_metronic/constants/general';
import { SerachFilters } from '../../../shared/SearchFilters';
import * as TableRedux from '../../../store/TableRedux';
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';
import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { CommonTable2 } from '../../../shared/tables/Table2';
import { Button } from '../../../shared/Button';
import { KTSVG } from '../../../../_metronic/helpers';
import { EditItem } from '../../../shared/tables/actions/EditItem';

// import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';

const TABLE_NAME = 'sys_code_rules_list';

const headerItems = [
  { id: 'ID', maxWidth: 150, width: 150 },
  { id: 'Name', maxWidth: 240, width: 230 },
  { id: 'Error message', maxWidth: 240, width: 230 },
  { id: 'Comparison Category', maxWidth: 240, width: 230 },
  // { id: 'Type of Template', maxWidth: 400, width: 400 },
  // { id: 'Creation Date', maxWidth: 200, width: 200 },
  { id: 'Actions', maxWidth: 150, width: 150 },
];

export const CodeRules: FC = () => {
  return (
    <div>
      <CodeRulesTable />
    </div>
  );
};

export const CodeRulesTable: FC = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage, locale } = useIntl(); //
  // const params: any = useParams();
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  /* -------------------------- Bring Data from store ------------------------- */

  const codeRulesList: CodeRulesDataListModel = useSelector<RootState>((state) => {
    return state.SYS_code_rules.code_rules;
  }, shallowEqual) as CodeRulesDataListModel;

  const CodeRulesCategoryTypesOptions = useMemo(
    () =>
      Array.from(CodeRulesCategoryTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(CodeRulesRedux.actions.fullFillCodeRules({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      CodeRulesRedux.actions.getCodeRules({
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  useEffect(() => {
    RequestData();
  }, [table?.page, table?.pagination]);

  const rowItems = codeRulesList?.data.map((codeRule: ICodeRule, index) => {
    const codeRulesCategoryTypesMapTranslated = {
      text: formatMessage({ id: `${CodeRulesCategoryTypesMap.get(codeRule.category)}` }) || '',
    };
    // const TransactionTypesMapTranslated = {
    //   text: formatMessage({ id: `${TransactionTypesMap.get(codeRule.transaction_type)}` }) || '',
    // };
    // const TaxAbilityTypesMapTranslated = {
    //   text: formatMessage({ id: `${TaxAbilityTypesMap.get(codeRule.tax_ability)}` }) || '',
    // };
    return [
      new TableSelection({
        display: userCan('delete-template'),
        id: codeRule.id,
        tableName: TABLE_NAME,
      }),
      new TableData({ text: `${codeRule.id}` }),

      new TableData({
        text: `${codeRule?.name[locale as LOCALE] ? codeRule?.name[locale as LOCALE] : ''}`,
      }),
      new TableData({
        text: `${
          codeRule?.error_message[locale as LOCALE] ? codeRule?.error_message[locale as LOCALE] : ''
        }`,
      }),
      new TableData(codeRulesCategoryTypesMapTranslated),
      // new TableData(TransactionTypesMapTranslated),
      // new TableData(TaxAbilityTypesMapTranslated),
      // new TableData({ text: codeRule?.tax_rate }),
      // new TableData({ text: codeRule?.description[locale as LOCALE] }),
      new TableActions(
        (
          <div className='d-flex flex-row'>
            <ViewItem
              title={$t('View')}
              onClick={() => history.push(`/admin/code-rules/${codeRule.id}`)}
            />
            <EditItem
              title={$t('Edit')}
              onClick={() => history.push(`/admin/code-rules/${codeRule.id}/edit`)}
            />
            <DeleteItem
              id={codeRule.id}
              title={$t('Delete')}
              message={
                codeRule?.can_delete
                  ? formatMessage({
                      id: 'Are you sure you want to delete this code rule?',
                    })
                  : formatMessage({
                      id: `This code rule is used in a tax rate, and can't be deleted.`,
                    })
              }
              showButtonGroup={codeRule?.can_delete}
              setDeleteItemId={setDeleteItemId}
              deleteItemId={deleteItemId}
              deleteURI={`/admin/rules/${codeRule.id}`}
              methodType='delete'
              payload={codeRule.id}
              successMessage={'Code rule has been deleted successfully!'}
              afterSucessRequest={() => RequestData()}
            />
          </div>
        )
      ),
    ];
  });

  return (
    <div className='table-wrapper card'>
      {/* begin::Header */}
      <div className='card-header border-0 '>
        {/*  ------------------------ start search and filters ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => {
              setShowSearchFilters(!showSearchFilters);
            }}
          >
            {!showSearchFilters && (
              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
            )}
            {showSearchFilters && (
              <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
            )}
          </button>
        </div>

        {/*  ------------------------- End Search and filters -------------------------  */}

        {/*  ------------------------ start Action button ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          {/* begin::Menu */}

          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
          </button>

          {/* begin::Menu 2 */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                {$t('Actions')}{' '}
              </div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mb-3 opacity-75'></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className='menu-item px-3 py-3'>
              <Button
                fa='mail-bulk'
                className='menu-link px-3 btn w-md-190px'
                onClick={() => {
                  history.push(`/admin/code-rule/create`);
                }}
                nameId='Create Code Rule'
                testid='create-code-rule-button'
                faIconMenu={true}
              />
            </div>{' '}
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>

        {/*  ------------------------ End Action button ------------------------  */}
      </div>
      {/* end::Header */}

      {showSearchFilters && (
        <div className='fw-bold m-auto' style={{ width: '95%' }}>
          <div className='px-3'>
            <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
          </div>
          <div className='separator mb-3 opacity-75'></div>
          <SerachFilters
            leftHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'search',
                queryTerm: 'name',
                onChange: (value: string) => {
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'name',
                    })
                  );
                },
                placeholderId: 'Search anything',
              },
            ]}
            rightHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'dropdown',
                queryTerm: 'category',
                onChange: (value: any) =>
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'category',
                    })
                  ),
                placeholderId: 'Comparison Category',
                options: CodeRulesCategoryTypesOptions,
              },
            ]}
            searchFilterHandler={searchFilterHandler}
            clearSearchFilterHandler={clearSearchFilterHandler}
          />
        </div>
      )}

      <CommonTable2
        className='pb-5 pb-xl-8'
        headerWithCheckbox={false}
        headerItems={headerItems}
        rowItems={rowItems}
        tableName={TABLE_NAME}
        initPerPage={20}
        allDisplayedIds={codeRulesList?.data?.map((codeRule) => codeRule.id) || []}
        meta={codeRulesList}
      />
    </div>
  );
};

const CodeRulesWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Code Rules' })}</PageTitle>
      <CodeRules />
    </div>
  );
};

export { CodeRulesWrapper };
