import { IRole } from '../../../types/user';

interface ITableData {
  roles: IRole[];
}

export class TableRolesViewer {
  constructor(private fieldData: ITableData) {}

  renderItem() {
    return (
      <div className='d-flex'>
        {this.fieldData.roles.map((role, i, arr) => {
          return (
            i < 2 && (
              <div className='mx-2'>
                <div className='fw-bolder mb-1 fs-6'>
                  <span className='menu-bullet'>
                    <span className='bullet bullet-dot'></span>
                  </span>{' '}
                  {role.name}
                  {arr.length > 2 && i === 1 && (
                    <i
                      className='fas fa-angle-down mx-2'
                      title={arr.map((role) => role.name).join(' - ')}
                    ></i>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  }
}
