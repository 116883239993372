import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companySalesInvoices = endpoints.companySalesInvoices;
export const companySingleSalesInvoice = endpoints.companySingleSalesInvoice;

export function getSalesInvoiceList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companySalesInvoices(companyId), { params });
}

export function getCurrentSalesInvoiceRequest({
  companyId,
  invoiceId,
}: {
  companyId: ID;
  invoiceId: ID;
}) {
  return axiosInstance.get(companySingleSalesInvoice(companyId, invoiceId));
}
