import { useMemo } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import 'react-phone-input-2/lib/style.css';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';

const useUpdateProfileSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */
  const initialValues = useMemo(
    () => ({
      first_name: '',
      last_name: '',
      phone: '',
      iso: 'sa',
      phoneValidity: false,
      roles: null,
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        first_name: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .matches(
            /[abcdefghijklmnopqrstuvwxyz]+|[\u0621-\u064A]+/,
            $t('Only letters are allwoed ')
          ),
        last_name: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .matches(
            /[abcdefghijklmnopqrstuvwxyz]+|[\u0621-\u064A]+/,
            $t('Only letters are allwoed ')
          ),
        phone: Yup.string().nullable(),
        phoneValidity: Yup.boolean().when('phone', (type, schema) => {
          if (type?.length) return schema.required(required);
        }),
      }),
    [formatMessage]
  );
  /* --------------------------- end formik starter --------------------------- */

  return { initialValues, validationSchema };
};

export { useUpdateProfileSchema };
