type IInitialConfigPayload = {
  type: string;
};

export function initialConfigPayload(values: any): IInitialConfigPayload {
  const payload: IInitialConfigPayload = {
    ...values,
    type: values.type,
  };
  return payload;
}
