import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { SelectOption } from '../../../types/SelectOptions';
import { Search } from '../../../types/types';
import { User } from '../../users/types/usersList';
import { getTemplateList, getTemplateCustomVariableList } from './TemplatesCrud';

export enum ITemplatesActionTypes {
  GET_TEMPLATES = 'GET_TEMPLATES',
  GET_SINGLE_TEMPLATE = 'GET_SINGLE_TEMPLATE',
  FULLFULL_TEMPLATES = 'FULLFULL_TEMPLATES',
  FULLFULL_CURRENT_TEMPLATE = 'FULLFULL_CURRENT_TEMPLATE',
  ADD_SECTIONS = 'ADD_SECTIONS',
  GET_CUSTOM_VARIABLES = 'GET_CUSTOM_VARIABLES',
  // GET_SINGLE_CUSTOM_VARIABLE = 'GET_SINGLE_CUSTOM_VARIABLE',
  FULLFULL_CUSTOMVARIABLES = 'FULLFULL_CUSTOMVARIABLES',
}

export interface IContentSectionVariable {
  id: string;
  type: 'text' | 'number';
  value: string | null | [];
  options?: SelectOption[];
}
export interface IContentSection {
  type: string;
  value: string;
  variables: IContentSectionVariable[];
  hidden: boolean;
}
export interface UnderConstructionTemplate {
  title: string;
  type: number;
  recipient_type: number;
  users: number[];
  clients?: number[] | any;
  variable_users: number[];
  variable_clients: number[];
  id: number | any;
  values:
    | {
        recipient_type: SelectOption;
        title_en: string;
        title_ar: string;
        type: number;
        users: number[];
        clients: number[] | [];
      }
    | any;
  editor?: {
    sections?: Array<IContentSection>;
    variables?: Array<string>;
  };
  sections?: Array<IContentSection>;
  variables?: Array<string>;
  reviewers?: Array<User>;
  creators?: Array<User>;
  signers?: Array<User>;
  can_update?: boolean;
  has_content?: boolean;
}

const initialTemplatesState: ITemplatesState = {
  templates: undefined,
  templateCustomVariables: undefined,
  underConstructionTemplate: undefined,
};

export interface ITemplatesState {
  templates?: any;
  templateCustomVariables?: any;
  underConstructionTemplate?: UnderConstructionTemplate;
  sections?: IContentSection[] | [];
  variables?: string[];
}

export const reducer = persistReducer(
  { storage, key: 'Templates', whitelist: ['UnderConstructionTemplate'] },
  (state: ITemplatesState = initialTemplatesState, action: ActionWithPayload<ITemplatesState>) => {
    switch (action.type) {
      case ITemplatesActionTypes.FULLFULL_TEMPLATES:
        return { ...state, templates: action.payload?.templates };
      case ITemplatesActionTypes.FULLFULL_CURRENT_TEMPLATE:
        return { ...state, underConstructionTemplate: action.payload?.underConstructionTemplate };
      case ITemplatesActionTypes.ADD_SECTIONS:
        const underConstructionTemplate = {
          ...state.underConstructionTemplate,
          sections: action?.payload?.sections,
          variables: action?.payload?.variables,
        } as UnderConstructionTemplate;

        return {
          ...state,
          underConstructionTemplate: { ...underConstructionTemplate },
        };
      case ITemplatesActionTypes.FULLFULL_CUSTOMVARIABLES:
        return { ...state, templateCustomVariables: action.payload?.templateCustomVariables };
      default:
        return state;
    }
  }
);

export const actions = {
  getTemplates: ({ companyId, params }: Search) => ({
    type: ITemplatesActionTypes.GET_TEMPLATES,
    payload: { companyId, params },
  }),
  fulfillTemplates: ({ templates }: ITemplatesState) => ({
    type: ITemplatesActionTypes.FULLFULL_TEMPLATES,
    payload: { templates },
  }),
  fulfillUnderConstructionTemplate: ({ underConstructionTemplate }: ITemplatesState) => ({
    type: ITemplatesActionTypes.FULLFULL_CURRENT_TEMPLATE,
    payload: { underConstructionTemplate },
  }),
  addSectionsToUnderConstructionTemplate: ({ sections, variables }: ITemplatesState) => ({
    type: ITemplatesActionTypes.ADD_SECTIONS,
    payload: { sections, variables },
  }),
  getCustomVariables: ({ companyId, params }: Search) => ({
    type: ITemplatesActionTypes.GET_CUSTOM_VARIABLES,
    payload: { companyId, params },
  }),
  fulfillCustomVariables: ({ templateCustomVariables }: ITemplatesState) => ({
    type: ITemplatesActionTypes.FULLFULL_CUSTOMVARIABLES,
    payload: { templateCustomVariables },
  }),
};

export function* getTemplates(action: ActionWithPayload<Search>) {
  try {
    // Getting Templates request
    const { data } = yield getTemplateList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing Templates in store
    yield put(actions.fulfillTemplates({ templates: data }));
  } catch (error) {
    //
  }
}
export function* getCustomVariables(action: ActionWithPayload<Search>) {
  try {
    // Getting Custom Variables request
    const { data } = yield getTemplateCustomVariableList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing Custom Variables in store
    yield put(actions.fulfillCustomVariables({ templateCustomVariables: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ITemplatesActionTypes.GET_TEMPLATES, getTemplates);
  yield takeLatest(ITemplatesActionTypes.GET_CUSTOM_VARIABLES, getCustomVariables);
}
