import { axiosInstance } from '../../../../../network/apis';
import { ID } from '../../../../../_metronic/constants/paths';

export function getAllVatRates({ params }: { params?: any }) {
  return axiosInstance.get<any>(`/admin/vat_rates`, {
    params,
  });
}

export function getVatRate({ vatRateId }: { vatRateId: ID }) {
  return axiosInstance.get<any>(`/admin/vat_rates/${vatRateId}`);
}
