import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import * as RolesRedux from '../../../app/modules/roles/store/RolesRedux';
import { RootState } from '../../../setup';
import { $t } from '../../../_metronic/i18n/formatMessage'; //, FormatMessage
import { PageTitle } from '../../../_metronic/layout/core';
import { CompaniesDataListModel } from '../../modules/companies/CompanyModel';
import { RolesDataListModel } from '../../modules/roles/types/Roles';
// Hide the unnecessary
// import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { SerachFilters } from '../../shared/SearchFilters';
import * as TableRedux from '../../store/TableRedux';
import { TableData } from '../../shared/tables/classes/TableData';
import { TableActions } from '../../shared/tables/classes/TableActions';
import { TableSelection } from '../../shared/tables/classes/TableSelection';
import { KTSVG } from '../../../_metronic/helpers';
import { Button } from '../../shared/Button';
import { usePermissions } from '../../hooks/usePermissions';
import { CommonTable2 } from '../../shared/tables/Table2';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';

const headerItems = [
  { id: 'Role Name', maxWidth: 280 },
  { id: 'Role Description', width: 300, maxWidth: 400 },
  // Hide the unnecessary
  // { id: 'Role status', maxWidth: 280 },
  { id: 'Actions', maxWidth: 280 },
];
const TABLE_NAME = 'roles_list';

const RolesContent: FC = () => {
  const { userCan } = usePermissions();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const companyId =
    companies?.data?.find((company) => company.slug === params.companySlug)?.id || null;

  const roles: RolesDataListModel = useSelector<RootState>(
    (state) => state.roles.roles,
    shallowEqual
  ) as RolesDataListModel;

  useEffect(() => {
    return () => {
      dispatch(RolesRedux.actions.fulfillRoles({}));
    };
  }, []);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[TABLE_NAME];
  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      RolesRedux.actions.getRoles({
        companyId,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (params.companySlug && companyId && table?.page) {
      RequestData();
    }
  }, [table?.page, table?.pagination, params.companySlug, companyId]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyId && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyId && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };
  const rowItems = roles?.data.map((role) => [
    new TableSelection({ display: userCan('delete-role'), id: role.id, tableName: TABLE_NAME }),
    new TableData({ text: role.name }),
    new TableData({ text: role.description, className: 'text-metallicBlue' }),
    // Hide the unnecessary
    // new TableData({ text: `ADD Status Later` }),
    new TableActions(
      (
        <div className='d-flex flex-row'>
          {/* Hide the unnecessary */}
          {userCan('view-role') && (
            <ViewItem
              onClick={() => history.push(`/c/${params.companySlug}/settings/roles/${role.id}`)}
              title={$t('View')}
            />
          )}
          {userCan('update-role') && (
            <EditItem
              onClick={() =>
                history.push(`/c/${params.companySlug}/settings/roles/${role.id}/edit`)
              }
              title={$t('Edit')}
            />
          )}
          {/* {userCan('delete-role') && <DeleteItem title={$t('Delete')} />} */}
        </div>
      )
    ),
  ]);

  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}

          {/*  ------------------------ start Action button ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
            </button>
            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                  {$t('Actions')}{' '}
                </div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}

              {/* begin::Menu item */}
              {userCan('create-role') && (
                <div className='menu-item px-3 py-3'>
                  <Button
                    className='menu-link px-3 btn w-md-190px'
                    faIconMenu={true}
                    fa='plus'
                    onClick={() => {
                      if (roles?.can_create) {
                        history.push(`/c/${params.companySlug}/settings/roles/add`);
                      } else {
                        setShowConfirmationModal(true);
                      }
                    }}
                    nameId='Add New Role'
                  />
                </div>
              )}
              {/* end::Menu item */}
            </div>
            {/* end::Menu 2 */}
            {/* end::Menu */}
          </div>

          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              // rightHandSide={[]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          headerWithCheckbox={userCan('delete-role')}
          allDisplayedIds={roles?.data?.map((role) => role.id) || []}
          meta={roles}
          headerItems={headerItems}
          rowItems={rowItems}
        />
        {/*  ---------------------warnings confirmation Modal-----------------------  */}

        {showConfirmationModal && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={() => setShowConfirmationModal(false)}
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row mb-8 m-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                  {formatMessage({
                    id: 'You have reached the maximum allowed for your current subscription. Contact support to upgrade.',
                  })}
                </div>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowConfirmationModal(false);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}
        {/*  ---------------------end warnings confirmation Modal-----------------------  */}
      </div>
    </>
  );
};

const RolesWrapper: FC = () => {
  usePermissions('view-role');
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Roles' })}</PageTitle>

      <RolesContent />
    </div>
  );
};

export { RolesWrapper };
