import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { HtmlHTMLAttributes } from 'react';

interface ITableData {
  text: string | HtmlHTMLAttributes<HTMLDivElement> | any;
  className?: string;
  to?: string;
  isLink?: boolean;
  isExternal?: boolean;
  errors_with_color?: any;
  show_errors_icon?: boolean;
  show_errors_icon_message?: any;
  show_errors_icon_color?: 'danger' | 'warning';
  show_processed_icon?: boolean;
  show_title_message?: boolean;
}
export class TableData {
  constructor(private fieldData: ITableData) {}

  RedirectionController({ to, isExternal, text, className }: ITableData) {
    return isExternal ? (
      <a href={to} className={clsx('fw-bolder text-hover-primary d-block mb-1 fs-6', className)}>
        {text}
      </a>
    ) : (
      <Link
        to={{ pathname: to }}
        className={clsx('fw-bolder text-hover-primary d-block mb-1 fs-6', className)}
      >
        {text}
      </Link>
    );
  }

  renderItem() {
    const {
      isLink,
      to,
      text,
      className,
      errors_with_color,
      show_errors_icon,
      show_errors_icon_message,
      show_errors_icon_color,
      show_title_message,
      show_processed_icon,
    } = this.fieldData;

    let badgeClassName = 'badge badge-md badge-danger';
    // if (
    //   error?.includes('نقص في الرقم المرجعي') ||
    //   error?.includes('Missing reference') ||
    //   // error?.includes('بيانات متعارضة') ||
    //   // error?.includes('Conflict detected') ||
    //   error?.includes('بيانات مكررة') ||
    //   error?.includes('Duplicate tax_number') ||
    //   // error?.includes('فاتورة مكررة') ||
    //   // error?.includes('Duplicate invoice') ||
    //   error?.includes('تحقق من المبلغ') ||
    //   error?.includes('Check amount') ||
    //   error?.includes('مسودة') ||
    //   error?.includes('Draft')
    // ) {
    //   badgeClassName = 'badge badge-md badge-warning';
    // }
    if (errors_with_color && errors_with_color?.color === 'warning') {
      badgeClassName = 'badge badge-md badge-warning';
    }
    // if (error?.includes('مقبول') || error?.includes('Approved')) {
    //   badgeClassName = 'badge badge-md badge-success';
    // }
    return (
      <div className={show_errors_icon || show_processed_icon ? 'd-flex' : ''}>
        {isLink && to ? (
          <div className=' m-2'>{this.RedirectionController(this.fieldData)}</div>
        ) : (
          <>
            {!errors_with_color && (
              <div
                className={clsx(
                  'fw-bolder text-hover-primary d-block  m-2 fs-6  text-truncate',
                  className
                )}
                style={{ maxWidth: '200px' }}
                title={show_title_message ? text : ''}
              >
                {text}
              </div>
            )}
            {errors_with_color && <span className={badgeClassName}>{errors_with_color.text}</span>}
          </>
        )}
        {show_errors_icon && (
          <a
            className={
              show_errors_icon_color === 'danger'
                ? 'btn btn-icon btn-bg-light btn-color-danger btn-sm me-1'
                : 'btn btn-icon btn-bg-light btn-color-warning btn-sm me-1'
            }
            title={show_errors_icon_message ? $t(`${show_errors_icon_message}`) : $t('Has errors')}
          >
            <i className='fas fa-info-circle mx-2'></i>
          </a>
        )}
        {show_processed_icon && (
          <a
            className={'btn btn-icon btn-bg-light btn-color-success btn-sm me-1'}
            title={$t('Processed')}
          >
            <i className='fas fa-check-circle mx-2'></i>
          </a>
        )}
      </div>
    );
  }
}

// return (
//   <>
//     {!this.fieldData.error && (
//       <span className='text-lightGreyBlue fs-5'>{formatDate(this.fieldData.date)}</span>
//     )}
//     {this.fieldData.error && (
//       <span className={'badge badge-md badge-light-danger'}>{this.fieldData.error}</span>
//     )}
//   </>
// );
