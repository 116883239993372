import { FC } from 'react';

type Props = any;

export const IgnoreItem: FC<Props> = ({ ...props }) => {
  return (
    <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' {...props}>
      <i className='fas fa-ban mx-2'></i>
    </button>
  );
};
