import { FC } from 'react';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

import { KTSVG } from '../../../../../_metronic/helpers';
import { FormatMessage } from '../../../../../_metronic/i18n/formatMessage';
import { usePermissions } from '../../../../hooks/usePermissions';
import {
  clientSideSignatureOnlyOrFirst,
  companySignatureNeeded,
} from '../../../../../_metronic/helpers/functions/signatures';
import { IDocument } from '../../types/DocumentsModel';

type Props = {
  document: IDocument;
};

const Step2SelectSigner: FC<Props> = ({ document }) => {
  const { userCan } = usePermissions();

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>
          <FormatMessage id='Sign/Select signer' />
        </h2>
      </div>

      <div className='mb-0 fv-row'>
        <div className='mb-0'>
          {/* Sign and Send */}
          {userCan('sign-document') && companySignatureNeeded(document.type) && (
            <label className='d-flex flex-stack mb-5 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen016.svg'
                      className='svg-icon-1 svg-icon-gray-600'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                    <FormattedMessage id='Sign and Send' />
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='step2Selection'
                  value='sign_and_send'
                />
              </span>
            </label>
          )}

          {clientSideSignatureOnlyOrFirst(document.type) && userCan('approve-document') && (
            <label className='d-flex flex-stack mb-0 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label'>
                    <KTSVG
                      path='/media/icons/duotune/graphs/gra008.svg'
                      className='svg-icon-1 svg-icon-gray-600'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                    <FormattedMessage id='Send to client' />
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <Field
                  className='form-check-input'
                  type='radio'
                  name='step2Selection'
                  value='send_to_client'
                />
              </span>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export { Step2SelectSigner };
