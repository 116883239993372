/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

import { DisplayItem } from './classes/DisplayItem';

interface headerItem {
  id?: string;
  width?: number;
  maxWidth?: number;
  [key: string]: any;
}

interface rowItem {
  [key: string]: any;
}

type Props = {
  className?: string;
  headerItems: Array<headerItem>;
  rowItems?: Array<rowItem>[];
  showTotal?: boolean;
  tableName: string;
  totalIndexArray?: Array<number>;
  scrollable?: boolean;
};

const ListTable: React.FC<Props> = ({
  className,
  headerItems,
  rowItems,
  showTotal = false,
  tableName,
  totalIndexArray = [],
  scrollable = false,
}) => {
  const { formatMessage } = useIntl();

  const resultArray = totalIndexArray?.map((item) => {
    const result = rowItems?.reduce(
      (acc, row) => {
        row?.map((rowItem, rowItemIndex) =>
          item === rowItemIndex - 1
            ? (acc[`${tableName + item}`] += parseFloat(rowItem.fieldData.text)
                ? parseFloat(rowItem.fieldData.text)
                : 0)
            : ''
        );

        return acc;
      },
      { [`${tableName + item}`]: 0 }
    );
    return result;
  });

  return (
    <div className={` ${className}`} id={tableName} data-testid={`${tableName}_table`}>
      {/* begin::Header */}
      {/* <div className='card-header border-0 pt-5'>
        {infoMessage && (
          <div className='align-content-center text-center m-auto'>
            <span role='alert' className='text-danger '>
              {infoMessage}
            </span>
          </div>
        )}
      </div> */}
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive mb-5'>
          {/* begin::Table */}
          <table
            className='table table-row-bordered data-table table-row-gray-100 align-middle gs-0 gy-3'
            style={
              scrollable
                ? { borderCollapse: 'collapse', display: 'block' }
                : { borderCollapse: 'collapse' }
            }
          >
            {/* begin::Table head */}

            <thead
              style={
                scrollable
                  ? {
                      display: 'block',
                    }
                  : {}
              }
            >
              <tr>
                {headerItems.map((item) => (
                  <th
                    key={item.id}
                    className={clsx('text-blueGrey fs-5 px-5')}
                    style={{ maxWidth: `${item?.maxWidth}px`, width: `${item?.width || 100}px` }}
                  >
                    {formatMessage({ id: item.id })}
                  </th>
                ))}
              </tr>
            </thead>

            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody
              style={
                scrollable
                  ? {
                      display: 'block',
                      overflowY: 'scroll',
                      height: '30vh',
                    }
                  : {}
              }
            >
              {rowItems?.map((row, rowIndex) => {
                // To Remove first item if display checkbox is not true
                // NOTE: Checkbox is always in the first item in each row
                let rowData = row;

                return (
                  <tr key={rowIndex}>
                    {rowData?.map((rowItem, rowItemIndex) => {
                      return (
                        <td
                          key={rowItemIndex}
                          className={scrollable ? clsx('px-7') : clsx('px-5')}
                          style={
                            scrollable
                              ? {
                                  maxWidth: `${headerItems[rowItemIndex]?.maxWidth}px`,
                                  width: `${headerItems[rowItemIndex]?.width || 100}px`,
                                }
                              : {}
                          }
                        >
                          {DisplayItem(rowItem)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {rowItems?.length === 0 && (
            <>
              <div className='text-center'>
                <FormattedMessage id='No Records To Display' />
              </div>
              <div className='text-center'>
                <img
                  src={toAbsoluteUrl('/media/404/searching-data-3385493.png')}
                  alt=''
                  className='mw-300px'
                />
              </div>{' '}
            </>
          )}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* start::Total */}
        {showTotal && (
          <div className='table-responsive mb-5'>
            <thead>
              <tr>
                {headerItems.map((item, index) => (
                  <th
                    key={item.id}
                    className={clsx('text-blueGrey fs-5 px-5')}
                    style={{ maxWidth: `${item?.maxWidth}px`, width: `${item?.width || 100}px` }}
                  >
                    {totalIndexArray?.includes(index)
                      ? formatMessage({ id: `Total ` }) + ' ' + formatMessage({ id: `${item.id}` })
                      : ''}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                <tr key='totals'>
                  {rowItems?.[0]?.map((rowItem, rowItemIndex) => (
                    <td key={rowItemIndex} className='px-5'>
                      {totalIndexArray?.includes(rowItemIndex)
                        ? `${resultArray[
                            totalIndexArray.findIndex((item) => item === rowItemIndex)
                          ]?.[`${tableName + rowItemIndex}`].toFixed(2)}`
                        : ''}
                    </td>
                  ))}
                </tr>
              }
            </tbody>
          </div>
        )}
        {/* end::Total */}
      </div>
      {/* begin::Body */}
    </div>
  );
};
export { ListTable };
