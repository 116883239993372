import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as DocumentsRedux from './store/DocumentsRedux';
import * as UserRedux from '../../modules/users/store/UsersRedux';

import { PageTitle } from '../../../_metronic/layout/core';

import { usePermissions } from '../../hooks/usePermissions';
import { CommonTable } from '../../shared/tables/Table';
import { RootState } from '../../../setup/redux/Store';
import { CompanyModel } from '../companies/CompanyModel';
import {
  DateViewer,
  TableActions,
  TableData,
  TableSelection,
  TableStatus,
} from '../../shared/tables/classes';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { IDocument, DocumentsDataListModel } from './types/DocumentsModel';
import {
  DOCS_STATUS,
  DocumentDirection,
  DocumentStatuses,
  RecipientTypeMap,
  RECIPIENT_TYPES,
} from '../../../_metronic/constants/general';
import { SearchDocuments } from './SearchDocuments';
import { Badge } from '../../../_metronic/helpers/typescript/csssClasses';
import { PreviewItem } from '../../shared/tables/actions/PreviewItem';
import { KTSVG } from '../../../_metronic/helpers';
import { axiosInstance } from '../../../network/apis';
import { DocumentApproval } from './DocumentProcess/DocumentApproveAndSign';
import { DocumentReviewOnly } from './DocumentProcess/DocumentReviewOnly';
import { Portal } from '../../../_metronic/partials';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { ViewItem } from '../../shared/tables/actions/ViewItem';

export const TABLE_NAME = 'TEMPLATES_TABLE';

const headerItems = [
  { id: 'Document Title', maxWidth: 400, width: 230 },
  { id: 'Type of Document', maxWidth: 400, width: 400 },
  { id: 'Creation Date', maxWidth: 200, width: 200 },
  { id: 'Document Created By', maxWidth: 240, width: 230 },
  { id: 'Recipient type', maxWidth: 400, width: 230 },
  { id: 'Status', maxWidth: 400, width: 230 },
  { id: 'Actions', maxWidth: 400, width: 300 },
];

export const DocumentsToReview: FC = () => {
  const dispatch = useDispatch();

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  useEffect(() => {
    dispatch(
      UserRedux.actions.getUsers({
        companyId: companyDetails.id,
        params: {
          per_page: 0,
        },
      })
    );
  }, []);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(UserRedux.actions.fulfillUsers({}));
    };
  }, []);

  return (
    <div>
      {SearchDocuments({ tableName: TABLE_NAME })}
      <DocumentsToReviewTable />
    </div>
  );
};

export const DocumentsToReviewTable: FC = () => {
  const { userCan, userCanNot } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const params: any = useParams();
  const [documentToApproveModal, setDocumentToApproveModal] = useState<null | IDocument>(null);

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const documents: DocumentsDataListModel = useSelector<RootState>(
    (state) => state.USER_documents.documents,
    shallowEqual
  ) as DocumentsDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(DocumentsRedux.actions.fulfillDocuments({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function requestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      DocumentsRedux.actions.getUnderReviewDocuments({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (!documentToApproveModal) {
      requestData();
    }
  }, [documentToApproveModal]);

  useEffect(() => {
    if (params.companySlug && companyDetails.id && table?.page) {
      requestData();
    }
  }, [table?.page, table?.pagination, table?.query, params.companySlug, companyDetails.id]);

  const rowItems = documents?.data.map((document: IDocument) => [
    new TableSelection({ display: false, id: document.id, tableName: TABLE_NAME }),
    new TableData({ text: document?.title }),
    new TableData({ text: $t(RecipientTypeMap.get(document?.recipient_type)?.label || '') }),
    new DateViewer({ date: document.created_at }),
    new TableData({ text: document?.user?.name }),
    new TableData({ text: $t(DocumentDirection.get(document?.type)?.label || '') }),
    new TableStatus({
      statusId: formatMessage({ id: `${DocumentStatuses.get(document?.status)?.label}` }),
      badge: `${DocumentStatuses.get(document?.status)?.badge}` as Badge,
    }),
    new TableActions(
      (
        /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
        <div className='d-flex flex-row'>
          {/* Create document = update document in the back-end */}
          {userCan('create-document') && document.can_edit && (
            <EditItem
              title={$t('Edit')}
              onClick={() =>
                history.push(`/c/${companyDetails.slug}/documents/update/${document.id}`)
              }
            />
          )}
          {userCan('view-document') && (
            <PreviewItem
              title={$t('Preview')}
              onClick={() =>
                window.open(
                  document.preview_link,
                  '_blank',
                  'toolbar=yes,scrollbars=yes,resizable=yes'
                )
              }
            />
          )}

          <ViewItem
            title={$t('View')}
            onClick={() => history.push(`/d/documents/${document.id}`)}
          />

          {(document.status === DOCS_STATUS.UNDER_REVIEW ||
            (document.status === DOCS_STATUS.WAITING_FOR_COMPANY &&
              document.can_sign &&
              RECIPIENT_TYPES['Need signture from 2 side- Seconed party side first'] ===
                document.recipient_type) ||
            (document.status === DOCS_STATUS.APPROVE && document.can_sign) ||
            (document.status === DOCS_STATUS.WAITING_FOR_SECOND_PARTY &&
              document.is_second_party)) && (
            <a
              title={formatMessage({ id: 'Approve' })}
              onClick={() => setDocumentToApproveModal(document)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr084.svg' className='svg-icon-3' />
            </a>
          )}
        </div>
      )
    ),
  ]);

  const returnToDocumentCreator = async (documentId: number) => {
    try {
      await axiosInstance.post(`/${companyDetails.id}/reviewers/documents/${documentId}`);
      requestData();
      toast.success($t('Document has been sent to creator successfully'));
    } catch (error) {}
  };

  return (
    <div className='table-wrapper'>
      <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5'></div>
      <CommonTable
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={false}
        allDisplayedIds={documents?.data?.map((document) => document.id) || []}
        meta={documents}
        headerItems={headerItems}
        rowItems={rowItems}
      />

      {documentToApproveModal && userCan('review-document') && userCanNot('sign-document') && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={$t('Document Process')}
            closeCallBack={() => setDocumentToApproveModal(null)}
          >
            <DocumentReviewOnly
              document={documentToApproveModal}
              returnToDocumentCreator={returnToDocumentCreator}
              setDocumentToApproveModal={setDocumentToApproveModal}
            />
          </AhadDialog>
        </Portal>
      )}

      {documentToApproveModal && userCan('sign-document') && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={$t('Document Process')}
            closeCallBack={() => setDocumentToApproveModal(null)}
          >
            <DocumentApproval
              document={documentToApproveModal}
              returnToDocumentCreatorAction={returnToDocumentCreator}
              setDocumentToApproveModal={setDocumentToApproveModal}
            />
          </AhadDialog>
        </Portal>
      )}
    </div>
  );
};

const DocumentsToReviewWrapper: FC = () => {
  usePermissions('view-document');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>
        {`${formatMessage({ id: 'Documents' })} ${formatMessage({
          id: 'to review/sign',
        })}`}
      </PageTitle>
      <DocumentsToReview />
    </div>
  );
};

export { DocumentsToReviewWrapper };
