/* eslint-disable @typescript-eslint/no-unused-expressions */
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { allowedImagesTypes } from '../../../../_metronic/constants/general';
import { $t } from '../../../../_metronic/i18n/formatMessage';

const imageMaxSize = 2048;

type ImageSignatureProps = {
  name: string;
  setName: Function;
  image: any;
  setImage: Function;
  action: Function;
  totalSignatures: number;
  signatureImageRef: any;
  setOpenAddSignatureModal: Function;
  maxSignatures: number;
  isOneTimeSignature: boolean;
  isLoading: boolean;
};

export function ImageSignature({
  name,
  setName,
  image,
  setImage,
  action,
  totalSignatures,
  signatureImageRef,
  setOpenAddSignatureModal,
  maxSignatures,
  isOneTimeSignature,
  isLoading,
}: ImageSignatureProps) {
  const { formatMessage } = useIntl();

  return (
    <div
      className='signature-upload-border  d-flex flex-column justify-content-center align-items-center p-3 m-10'
      style={{
        minHeight: '200px',
        minWidth: '200px',
        maxHeight: 'fit-content',
        maxWidth: 'fit-content',
      }}
    >
      <div className='mw-150'>
        {!isOneTimeSignature && (
          <input
            type='text'
            className='form-control form-control-lightSkyBlue fs-4 py-1 text-gray-700 placeholder-gray-400 mw-500px my-5'
            value={name}
            onInput={(e: any) => setName(e.target.value)}
            placeholder={formatMessage({ id: 'Signature Title' })}
          />
        )}
        {typeof image === 'string' && image.length > 0 && (
          <img className='mb-3' alt='' src={image} />
        )}
        {typeof image !== 'string' && allowedImagesTypes.includes(image.type) && (
          <img
            className='mb-3'
            src={URL.createObjectURL(image)}
            alt='FIle'
            style={{ maxWidth: '200px', maxHeight: '200px' }}
          />
        )}
        {image && (
          <div className='d-flex flex-column align-items-center'>
            <span className='text-blueGrey cursor-pointer' onClick={() => setImage('')}>
              <i className='far fa-times'></i> <FormattedMessage id='Remove Image' />
            </span>

            <button
              className='btn btn-outlined-primary w-75 mt-3'
              onClick={() => action(image)}
              disabled={totalSignatures === maxSignatures || isLoading}
            >
              <FormattedMessage id='Use Signature' />
            </button>
          </div>
        )}
      </div>
      {!image && (
        <div className='text-center'>
          <div>{$t('Select signature file image from your local.')}</div>
          <div className='d-flex flex-column gap-3 justify-content-center align-items-center mt-10'>
            <button
              className='btn btn-outlined-primary w-50'
              disabled={totalSignatures >= maxSignatures}
              onClick={() => signatureImageRef.current?.click()}
            >
              <FormattedMessage id='Upload Signature' />
            </button>
            <button
              className='btn btn-outlined-danger w-50'
              onClick={() => {
                if (name?.length || image) {
                  window.confirm(
                    formatMessage({
                      id: 'Are you sure you want to ignore the changes?',
                    })
                  )
                    ? setOpenAddSignatureModal(false)
                    : null;
                } else {
                  setOpenAddSignatureModal(false);
                }
                setImage('');
                setName('');
              }}
            >
              <FormattedMessage id='Cancel' />
            </button>
          </div>
          <input
            type='file'
            style={{ visibility: 'hidden' }}
            ref={signatureImageRef}
            onChange={(e: any) => {
              const file = e?.target?.files[0];
              if (!allowedImagesTypes.includes(file?.type)) {
                toast.error(
                  formatMessage({
                    id: 'You can only upload JPG, SVG,PNG, JPEG files format',
                  })
                );
                return;
              }
              if (file?.size / 1024 > imageMaxSize) {
                toast.error(
                  formatMessage(
                    { id: 'The maximum file size is {maxSize}MB' },
                    { maxSize: `${imageMaxSize / 1024}` }
                  )
                );
              }
              setImage(e?.target?.files?.[0] || '');
            }}
          />
        </div>
      )}
    </div>
  );
}
