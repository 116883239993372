import { FormattedMessage } from 'react-intl';
import { SeparatorLines } from './Lines';

type Props = {
  products?: any;
};

export const AddingProductsTable: React.FC<Props> = ({ products }) => {
  return products.length > 0 ? (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-body pt-3'>
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
            {products.image && (
              <div
                className='symbol-label'
                style={{
                  backgroundImage: products.image,
                }}
              ></div>
            )}
          </div>
          <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
            <div className='flex-grow-1 my-lg-0 my-2 me-2'>
              <p className='text-gray-800 fw-bolder text-hover-primary fs-6'>
                <FormattedMessage id='Product Name' />
              </p>
            </div>
            <div className='d-flex align-items-center'>
              <div className='me-6'>
                <span className='text-gray-800 fw-bolder'>
                  <FormattedMessage id='Price' />
                </span>
              </div>
            </div>
          </div>
        </div>
        {products.map((product: any) => (
          <div className='d-flex align-items-sm-center mb-7'>
            <div className='symbol symbol-60px symbol-2by3 me-4'>
              {products.image && (
                <div
                  className='symbol-label'
                  style={{
                    backgroundImage: products.image,
                  }}
                ></div>
              )}
            </div>
            <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
              <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                <p className='text-gray-800 fw-bolder text-hover-primary fs-6'>{product.label}</p>
              </div>
              <div className='d-flex align-items-center'>
                <div className='me-6'>
                  <span className='text-gray-800 fw-bolder'>{product.price}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className='my-5'>
          <SeparatorLines lines={1} />
        </div>
        <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
          <div className='flex-grow-1 my-lg-0 my-2 me-2 ps-5'>
            <p className='text-gray-800 fw-bolder text-hover-primary fs-6'>
              <FormattedMessage id='Total' />
            </p>
          </div>
          <div className='d-flex align-items-center'>
            <div className='me-6'>
              <span className='text-gray-800 fw-bolder'>
                {products.reduce((acc: number, curr: { price: number }) => acc + curr?.price, 0)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
