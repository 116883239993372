import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

// import { Search } from '../../../types/types';
import { getAllWithholdingTransactionsDetails } from './WithholdingTransactionsDetailsCRUD';

export enum ITransactionsDetailsActionTypes {
  GET_WITHHOLDING_TRANSACTIONS_DETAILS = 'GET_WITHHOLDING_TRANSACTIONS_DETAILS',
  FULL_FILL_WITHHOLDING_TRANSACTIONS_DETAILS = 'FULL_FILL_WITHHOLDING_TRANSACTIONS_DETAILS',
}

const initialTransactionsDetails: ITransactionsDetailsState = {
  withholding_transaction_details: undefined,
};

export interface ITransactionsDetailsState {
  withholding_transaction_details?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_withholding_transaction_details', whitelist: [''] },
  (
    state: ITransactionsDetailsState = initialTransactionsDetails,
    action: ActionWithPayload<ITransactionsDetailsState>
  ) => {
    switch (action.type) {
      case ITransactionsDetailsActionTypes.FULL_FILL_WITHHOLDING_TRANSACTIONS_DETAILS:
        return {
          ...state,
          withholding_transaction_details: action.payload?.withholding_transaction_details,
        };
      default:
        return state;
    }
  }
);

export const actions = {
  getWithholdingTransactionsDetails: ({ companyId, reportId, params }: any) => ({
    type: ITransactionsDetailsActionTypes.GET_WITHHOLDING_TRANSACTIONS_DETAILS,
    payload: { companyId, reportId, params },
  }),
  fullFillWithholdingTransactionsDetails: ({
    withholding_transaction_details,
  }: ITransactionsDetailsState) => ({
    type: ITransactionsDetailsActionTypes.FULL_FILL_WITHHOLDING_TRANSACTIONS_DETAILS,
    payload: { withholding_transaction_details },
  }),
};

export function* getWithholdingTransactionsDetails(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getAllWithholdingTransactionsDetails({
      companyId: action.payload?.companyId,
      reportId: action.payload?.reportId,
      params: action.payload?.params,
    });
    yield put(
      actions.fullFillWithholdingTransactionsDetails({ withholding_transaction_details: data })
    );
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(
    ITransactionsDetailsActionTypes.GET_WITHHOLDING_TRANSACTIONS_DETAILS,
    getWithholdingTransactionsDetails
  );
}
