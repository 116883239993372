import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  formik: any;
  loading: boolean;
  saveBtnLabel?: string;
  cancelOnClick?: any;
  id?: string;
  containerClassName?: string;
  disabled?: boolean;
};
export const SaveCancelBtnsGroup: FC<Props> = ({
  formik,
  loading,
  saveBtnLabel,
  cancelOnClick,
  id,
  containerClassName,
  disabled,
}) => {
  return (
    <div className={clsx('mt-4', containerClassName, { 'col-xl-4': !containerClassName })}>
      <div className='row'>
        <div className='col-md-6'>
          <button
            type='submit'
            id={id || 'submit'}
            className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
            disabled={formik.isSubmitting || disabled}
          >
            {!loading && (
              <span className='indicator-label'>
                <FormattedMessage id={saveBtnLabel || 'save'} />
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <FormattedMessage id='Please wait...' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div className='col-md-6'>
          <button
            onClick={() => cancelOnClick()}
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
          >
            <FormattedMessage id='Cancel' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaveCancelBtnsGroup;
