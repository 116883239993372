import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ActionWithPayload } from '../interface/ActionWithPayload';
import { ModalName } from '../types/types';

export enum IModalTypes {
  SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL',
  CLOSE_ACTIVE_MODAL = 'CLOSE_ACTIVE_MODAL',
}

interface IModalState {
  activeModal: '' | undefined | ModalName;
}

const initialModalState: IModalState = {
  activeModal: undefined,
};

export const reducer = persistReducer(
  { storage, key: 'modal', whitelist: [''] },
  (state: IModalState = initialModalState, action: ActionWithPayload<IModalState>) => {
    switch (action.type) {
      case IModalTypes.CLOSE_ACTIVE_MODAL:
        return { ...state, activeModal: undefined };
      case IModalTypes.SET_ACTIVE_MODAL:
        const modaleName = action?.payload?.activeModal;
        return { ...state, activeModal: modaleName };
      default:
        return state;
    }
  }
);

export const actions = {
  setActiveModal: (modalName: ModalName) => ({
    type: IModalTypes.SET_ACTIVE_MODAL,
    payload: { activeModal: modalName },
  }),
  closeActiveModal: () => ({
    type: IModalTypes.CLOSE_ACTIVE_MODAL,
  }),
};
