import { FC, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../setup/redux/Store';

import { SelectOptions } from '../types/SelectOptions';
// import useDebounce from '../hooks/useDebounce/useDebounce';

export type SearchFilterType = 'search' | 'dropdown' | 'dateRange';

interface ISearchField {
  TABLE_NAME?: string;
  type: 'search';
  queryTerm: string;
  placeholderId: string;
  onSubmit?: Function;
  onChange: Function;
}

interface IDropdown {
  TABLE_NAME?: string;
  type: 'dropdown';
  queryTerm: string;
  placeholderId: string;
  onChange: Function;
  options: SelectOptions;
}

interface IDateRangePicker {
  TABLE_NAME?: string;
  type: 'dateRange';
  queryTerm: string;
  onChange: Function;
  placeholderFrom?: 'string';
  placeholderTo?: 'string';
  isClearable?: boolean;
}

export type SearchFileterFild = ISearchField | IDropdown | IDateRangePicker | never;

const SearchField: FC<ISearchField> = (item) => {
  const { formatMessage, locale } = useIntl();
  const [search, setSearch] = useState('');
  const searchRef = useRef();

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[item.TABLE_NAME!];

  const searchHandler = (value: any) => {
    setSearch(value);
    item.onChange(value);
  };
  // useDebounce(() => item.onChange(search), 1000, [search]);

  useEffect(() => {
    if (!table?.query) {
      setSearch('');
    }
  }, [table?.query]);

  return (
    <div className='d-flex align-items-center gap-4'>
      <div className='position-relative w-100'>
        <span className='svg-icon svg-icon-2 svg-icon-primary position-absolute top-50 translate-middle ms-8 mt-1'>
          <i className='fal fa-search'></i>
        </span>
        <input
          type='text'
          className='form-control form-control-lightSkyBlue fs-4 py-1 ps-14 text-gray-700 placeholder-gray-400 mw-500px'
          name='search'
          value={search}
          onChange={(e: any) => searchHandler(e.target.value)}
          placeholder={formatMessage({ id: item.placeholderId })}
          ref={() => searchRef}
        />
        <span
          className='svg-icon svg-icon-2 svg-icon-primary position-absolute top-50 translate-middle mt-1'
          style={{
            left: locale === 'en' ? 'auto' : '-9px',
            right: locale === 'en' ? '9px' : 'auto',
            cursor: 'pointer',
            zIndex: 2,
            minHeight: '1.9rem',
            minWidth: '2rem',
            borderRadius: '5px',
            textAlign: 'center',
            paddingTop: '0.5rem',
            background: 'white',
          }}
        >
          {search.length > 0 && (
            <i
              className='fal fa-times '
              onClick={(e: any) => {
                // setSearch('');
                searchHandler('');
              }}
            ></i>
          )}
        </span>
      </div>
    </div>
  );
};

const DropDownField: FC<IDropdown> = (item) => {
  const { formatMessage } = useIntl();
  const [valueSelect, setValueSelect] = useState<any>();

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[item.TABLE_NAME!];

  const valueChangeHandler = (value: any) => {
    setValueSelect(value);
    value?.value ? item.onChange(value?.value) : item.onChange('');
  };

  useEffect(() => {
    if (!table?.query) {
      setValueSelect(null);
    }
  }, [table?.query]);

  return (
    <Select
      onChange={(value: any) => valueChangeHandler(value)}
      options={item.options}
      placeholder={formatMessage({ id: item.placeholderId })}
      className='react-select smaller w-260px'
      id={item.queryTerm}
      isClearable
      value={valueSelect}
    />
  );
};

const DateRangePicker: FC<IDateRangePicker> = (item) => {
  const { formatMessage } = useIntl();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isError, setIsError] = useState(false);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[item.TABLE_NAME!];

  useEffect(() => {
    if (!table?.query) {
      setStartDate(null);
      setEndDate(null);
      setIsError(false);
    }
  }, [table?.query]);

  useEffect(() => {
    if (endDate && startDate && moment(endDate).diff(moment(startDate), 'days') > -1) {
      const start = moment(startDate).format('YYYY-MM-DD');
      const end = moment(endDate).format('YYYY-MM-DD');
      item.onChange(start, end);
      setIsError(false);
    }
    if (endDate === null && startDate === null) {
      item.onChange('', '');
    }
    if (moment(endDate).diff(moment(startDate), 'days') <= -1) setIsError(true);
  }, [startDate, endDate]);

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row gap-3'>
        <DatePicker
          isClearable={item.isClearable}
          selected={startDate}
          dateFormat='dd/MM/yyyy'
          onChange={(date: any) => setStartDate(date)}
          selectsStart
          placeholderText={formatMessage({ id: item.placeholderFrom || 'Date from' })}
          className='form-control form-control-sm b-radius-16 basic-input my-1'
          startDate={startDate}
          endDate={endDate}
        />
        <DatePicker
          isClearable={item.isClearable}
          selected={endDate}
          dateFormat='dd/MM/yyyy'
          onChange={(date: any) => setEndDate(date)}
          className='form-control form-control-sm b-radius-16 basic-input my-1'
          placeholderText={formatMessage({ id: item.placeholderTo || 'Date to' })}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
      <div>
        {isError && (
          <p className='text-danger fs-6'>
            <FormattedMessage id='DateRangeError' />
          </p>
        )}
      </div>
    </div>
  );
};

const fields: { [key: string]: Function } = {
  searchField: (item: ISearchField) => SearchField(item),
  dropdownField: (item: IDropdown) => DropDownField(item),
  dateRangeField: (item: IDateRangePicker) => DateRangePicker(item),
};

type Props = {
  leftHandSide?: Array<SearchFileterFild>;
  rightHandSide?: Array<SearchFileterFild>;
  width?: number | string;
  searchFilterHandler?: () => void;
  clearSearchFilterHandler?: () => void;
};
export const SerachFilters: FC<Props> = ({
  leftHandSide,
  rightHandSide,
  width,
  searchFilterHandler,
  clearSearchFilterHandler,
}) => {
  return (
    <div
      className='search-filter justify-content-between b-radius-5 bg-white content-wrapper row'
      style={{
        width: width ? `${width}%` : '100%',
        // height: 65,
        margin: '5px 0 20px 0',
        padding: '14.5px 17.2px 14px 14px',
      }}
    >
      {leftHandSide && (
        <div className='d-flex  justify-content-lg-start justify-content-around align-items-center gap-2 col-md-6 col-sm-12  my-3'>
          {leftHandSide?.map((item: SearchFileterFild) => (
            <div key={item.queryTerm}>{fields[`${item.type}Field`](item)}</div>
          ))}
        </div>
      )}
      {rightHandSide && (
        <div className='d-flex  justify-content-lg-end  justify-content-around  align-items-center gap-2 col-md-6 col-sm-12 m-auto'>
          {rightHandSide?.map((item) => (
            <div
              key={item.queryTerm}
              style={{
                minWidth: '9vw',
              }}
            >
              <div key={item.queryTerm}>{fields[`${item.type}Field`](item)}</div>
            </div>
          ))}
        </div>
      )}
      <div className='d-flex justify-content-end col-md-12 col-sm-12 my-3'>
        <button
          type='button'
          className='btn btn-primary btn-sm b-radius-16'
          onClick={searchFilterHandler}
        >
          <FormattedMessage id='Search' />
        </button>

        <button
          type='button'
          className='btn btn-outline-primary btn-sm b-radius-16'
          onClick={clearSearchFilterHandler}
        >
          <FormattedMessage id='Clear' />
        </button>
      </div>
    </div>
  );
};
