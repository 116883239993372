import { FC } from 'react';
import { FormattedPlural, useIntl } from 'react-intl';

type Props = {
  amount: number | undefined;
  irrigular?: boolean;
  wordId: string;
  PluralId?: string;
};

export const CustomPlural: FC<Props> = ({ amount, irrigular, wordId, PluralId }) => {
  const { formatMessage } = useIntl();
  return (
    <FormattedPlural
      value={amount || 0}
      one={formatMessage({ id: wordId })}
      other={formatMessage({ id: irrigular ? PluralId : wordId + 's' })}
    />
  );
};
