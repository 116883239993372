import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import { getAllSalesInvoiceItems, getSalesLineItem } from './SalesLineItemsCRUD';

export enum ISalesLineItemsActionTypes {
  GET_SALES_LINE_ITEMS = 'GET_SALES_LINE_ITEMS',
  FULL_FILL_SALES_LINE_ITEMS = 'FULL_FILL_SALES_LINE_ITEMS',
  GET_CURRENT_SALE_LINE_ITEMS = 'GET_CURRENT_SALE_LINE_ITEMS',
  FULL_FILL_CURRENT_SALES_LINE_ITEMS = 'FULL_FILL_CURRENT_SALES_LINE_ITEMS',
}

const initialSales: ISalesState = {
  salesLineItems: undefined,
  currentSalesLineItems: undefined,
};

export interface ISalesState {
  salesLineItems?: any;
  currentSalesLineItems?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_sales_line_items', whitelist: [''] },
  (state: ISalesState = initialSales, action: ActionWithPayload<ISalesState>) => {
    switch (action.type) {
      case ISalesLineItemsActionTypes.FULL_FILL_SALES_LINE_ITEMS:
        return { ...state, salesLineItems: action.payload?.salesLineItems };
      case ISalesLineItemsActionTypes.FULL_FILL_CURRENT_SALES_LINE_ITEMS:
        return { ...state, currentSalesLineItems: action.payload?.currentSalesLineItems };
      default:
        return state;
    }
  }
);

export const actions = {
  getSalesLineItems: ({ companyId, params }: Search) => ({
    type: ISalesLineItemsActionTypes.GET_SALES_LINE_ITEMS,
    payload: { companyId, params },
  }),
  fullFillSalesLineItems: ({ salesLineItems }: ISalesState) => ({
    type: ISalesLineItemsActionTypes.FULL_FILL_SALES_LINE_ITEMS,
    payload: { salesLineItems },
  }),
  getCurrentSalesLineItems: ({ companyId, saleId }: any) => ({
    type: ISalesLineItemsActionTypes.GET_CURRENT_SALE_LINE_ITEMS,
    payload: { companyId, saleId },
  }),
  fullFillCurrentSalesLineItems: ({ currentSalesLineItems }: ISalesState) => ({
    type: ISalesLineItemsActionTypes.FULL_FILL_CURRENT_SALES_LINE_ITEMS,
    payload: { currentSalesLineItems },
  }),
};

export function* getSalesLineItems(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllSalesInvoiceItems({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });

    yield put(actions.fullFillSalesLineItems({ salesLineItems: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentSalesLineItems(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getSalesLineItem({
      companyId: action.payload?.companyId,
      saleId: action.payload?.saleId,
    });
    yield put(actions.fullFillCurrentSalesLineItems({ currentSalesLineItems: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ISalesLineItemsActionTypes.GET_SALES_LINE_ITEMS, getSalesLineItems);
  yield takeLatest(
    ISalesLineItemsActionTypes.GET_CURRENT_SALE_LINE_ITEMS,
    getCurrentSalesLineItems
  );
}
