import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { requestHandler, successHandler, errorHandler } from '../interceptors';

export interface AxiosConfig extends AxiosRequestConfig {
  handlerEnabled?: boolean;
}

const config: AxiosConfig = {
  // ?Base url backend url looks like `somedomain.com/v1`
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL + '/v' + process.env.REACT_APP_BACKEND_VERSION,
};

export const axiosInstance: AxiosInstance = axios.create(config);

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
