import { useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MenuComponent } from '../../../assets/ts/components';
import { useLayout } from '../../core';
import * as CompaniesRedux from '../../../../app/modules/companies/store/UserCompaniesRedux';
import { CompaniesDataListModel } from '../../../../app/modules/companies/CompanyModel';
import { RootState } from '../../../../setup/redux/Store';
import { ICompany } from '../../../../app/types/companies';
import { Logo } from '../../../../app/shared/LogoWithCompany';

export function PublicHeader() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params: any = useParams();
  const { classes, attributes } = useLayout();

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  useEffect(() => {
    if (
      (!companyDetails?.slug || companyDetails?.slug !== params?.companySlug) &&
      companies?.data?.length
    ) {
      const id = companies?.data?.find((company) => company.slug === params.companySlug)
        ?.id as number;
      if (id) {
        dispatch(
          CompaniesRedux.actions.getCurrentCompanyById({
            id: id,
          })
        );
      }
    }
  }, [companyDetails, companies, params]);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [pathname]);

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div className='logo-and-toggle' id='kt_aside_logo'>
        <Logo />
      </div>
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      ></div>
    </div>
  );
}
