import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

// import { Search } from '../../../types/types';
import { getAllInvoicesWithholdingReportTransactionsDetails } from './InvoicesWithholdingReportTransactionsDetailsCRUD';

export enum IInvoicesWithholdingReportTransactionsDetailsActionTypes {
  GET_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS = 'GET_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS',
  FULL_FILL_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS = 'FULL_FILL_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS',
}

const initialInvoicesWithholdingReportTransactionsDetails: IInvoicesWithholdingReportTransactionsDetailsState =
  {
    invoices_withholding_report_transaction_details: undefined,
  };

export interface IInvoicesWithholdingReportTransactionsDetailsState {
  invoices_withholding_report_transaction_details?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_invoices_withholding_reports_transaction_details', whitelist: [''] },
  (
    state: IInvoicesWithholdingReportTransactionsDetailsState = initialInvoicesWithholdingReportTransactionsDetails,
    action: ActionWithPayload<IInvoicesWithholdingReportTransactionsDetailsState>
  ) => {
    switch (action.type) {
      case IInvoicesWithholdingReportTransactionsDetailsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS:
        return {
          ...state,
          invoices_withholding_report_transaction_details:
            action.payload?.invoices_withholding_report_transaction_details,
        };
      default:
        return state;
    }
  }
);

export const actions = {
  getInvoicesWithholdingReportTransactionsDetails: ({ companyId, reportId, params }: any) => ({
    type: IInvoicesWithholdingReportTransactionsDetailsActionTypes.GET_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS,
    payload: { companyId, reportId, params },
  }),
  fullFillInvoicesWithholdingReportTransactionsDetails: ({
    invoices_withholding_report_transaction_details,
  }: IInvoicesWithholdingReportTransactionsDetailsState) => ({
    type: IInvoicesWithholdingReportTransactionsDetailsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS,
    payload: { invoices_withholding_report_transaction_details },
  }),
};

export function* getInvoicesWithholdingReportTransactionsDetails(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getAllInvoicesWithholdingReportTransactionsDetails({
      companyId: action.payload?.companyId,
      reportId: action.payload?.reportId,
      params: action.payload?.params,
    });
    yield put(
      actions.fullFillInvoicesWithholdingReportTransactionsDetails({
        invoices_withholding_report_transaction_details: data,
      })
    );
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(
    IInvoicesWithholdingReportTransactionsDetailsActionTypes.GET_INVOICES_WITHHOLDING_REPORT_TRANSACTIONS_DETAILS,
    getInvoicesWithholdingReportTransactionsDetails
  );
}
