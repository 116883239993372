import { FC, ReactElement } from 'react';
import { ClientsAddEdit } from '../../AddClientsWrapper';

type TAB = {
  id: number;
  tabId: string;
  tabIcon: string;
  viewcomponent: ReactElement;
  editComponent: ReactElement;
  createComponent: ReactElement;
  display: boolean;
};

export const THIRD_PARTY_TABS: TAB[] = [
  {
    id: 1,
    tabId: 'Basic information',
    tabIcon: 'fa-clipboard-list-check',
    viewcomponent: <ClientsAddEdit mode={'view'} displayRequiredSection={1} />,
    editComponent: <ClientsAddEdit mode={'edit'} displayRequiredSection={1} />,
    createComponent: <ClientsAddEdit mode={'create'} displayRequiredSection={1} />,
    display: true,
  },
  {
    id: 2,
    tabId: 'Contact information',
    tabIcon: 'fa-clipboard-list-check',
    viewcomponent: <ClientsAddEdit mode={'view'} displayRequiredSection={2} />,
    editComponent: <ClientsAddEdit mode={'edit'} displayRequiredSection={2} />,
    createComponent: <ClientsAddEdit mode={'create'} displayRequiredSection={2} />,
    display: true,
  },
  {
    id: 3,
    tabId: 'Delegates information',
    tabIcon: 'fa-clipboard-list-check',
    viewcomponent: <ClientsAddEdit mode={'view'} displayRequiredSection={3} />,
    editComponent: <ClientsAddEdit mode={'edit'} displayRequiredSection={3} />,
    createComponent: <ClientsAddEdit mode={'create'} displayRequiredSection={3} />,
    display: true,
  },
  {
    id: 4,
    tabId: 'Tax settings',
    tabIcon: 'fa-clipboard-list-check',
    viewcomponent: <ClientsAddEdit mode={'view'} displayRequiredSection={4} />,
    editComponent: <ClientsAddEdit mode={'edit'} displayRequiredSection={4} />,
    createComponent: <ClientsAddEdit mode={'create'} displayRequiredSection={4} />,
    display: true,
  },
];

const thirdPartyParamsMapView = new Map(THIRD_PARTY_TABS.map((tab: TAB) => [tab.id, { ...tab }]));
const thirdPartyParamsMapEdit = new Map(THIRD_PARTY_TABS.map((tab: TAB) => [tab.id, { ...tab }]));
const thirdPartyParamsMapCreate = new Map(THIRD_PARTY_TABS.map((tab: TAB) => [tab.id, { ...tab }]));

type ThirdPartyDetailsHandlerProps = { currentView: number; mode: string };

export const ThirdPartyDetailsHandler: FC<ThirdPartyDetailsHandlerProps> = ({
  currentView,
  mode,
}) => {
  if (mode === 'view') {
    return thirdPartyParamsMapView.get(currentView)?.viewcomponent || null;
  } else if (mode === 'edit') {
    return thirdPartyParamsMapEdit.get(currentView)?.editComponent || null;
  } else if (mode === 'create') {
    return thirdPartyParamsMapCreate.get(currentView)?.createComponent || null;
  } else {
    return null;
  }
};
