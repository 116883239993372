import { axiosInstance } from '../../../../network/apis';
import { endpoints } from '../../../../_metronic/constants/paths';
import { AuthModel } from '../models/AuthModel';

export const LOGIN_URL = endpoints.login;
export const AUTH_USER_URL = endpoints.authUser;
export const REGISTER_URL = endpoints.register;
export const REQUEST_PASSWORD_URL = endpoints.forgetPassword;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axiosInstance.post(LOGIN_URL, {
    username: email,
    password,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    grant_type: process.env.REACT_APP_GRANT_TYPE,
  });
}
// get user data from server
export function authUser(email: string, password: string, token: string) {
  return axiosInstance.get(AUTH_USER_URL, {
    params: {
      email,
      password,
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

interface IRegisterObject {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
}
// Server should return AuthModel
export function register(registerObject: IRegisterObject) {
  const { email, firstname, lastname, password, confirmPassword } = registerObject;

  return axiosInstance.post<AuthModel>(REGISTER_URL, {
    email,
    first_name: firstname.trim(),
    last_name: lastname.trim(),
    password,
    password_confirmation: confirmPassword,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axiosInstance.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email });
}
