import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';

import { CustomVariableTypes } from '../types/CustomVariablesModel';

const useConfigureCustomVariablesSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const customVariablesInitialValues: { [key: string]: any } = useMemo(
    () => ({
      type: {
        value: CustomVariableTypes.Text,
        label: formatMessage({ id: 'Text' }),
        requestValue: 'text',
      },
      name_en: '',
      name_ar: '',
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const customVariablesValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.object().required(required),
        name_en: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_en', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        name_ar: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_ar', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
      }),
    [formatMessage]
  );

  return {
    customVariablesInitialValues,
    customVariablesValidationSchema,
  };
};

export { useConfigureCustomVariablesSchema };
