/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import useWindowSize from '../../../hooks/useWindowSize';
import { LocaleToggler } from '../../../shared/LocaleToggler';
import { FancySide } from '../components/FancySide';
import { Login } from '../components/Login';
import { LogoAndLoginLink } from '../components/LogoAndRedirectionLink';
import * as CompaniesRedux from '../../../../app/modules/companies/store/UserCompaniesRedux';
import { useEffect } from 'react';

export const LoginContainer = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    dispatch(CompaniesRedux.actions.fulFillCompanies({}));
    dispatch(CompaniesRedux.actions.fulfillSingleComany({}));
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */
  return (
    <div
      className={clsx('d-flex flex-row p-10 rounded', {
        'mh-90': width > 1440,
      })}
    >
      <div
        className={clsx('bg-gray-100  shadow-sm p-4 p-lg-10 flex-fill bg-white bls-radius-25', {
          'b-radius-25': width < 992,
          'py-12': width < 992,
          'px-8': width < 992,
        })}
        style={{ width: width < 992 ? 'auto' : '50%' }}
      >
        <LogoAndLoginLink direction='signup' />
        <div className='mb-5 mt-6 d-flex justify-content-between align-items-center'>
          <h1 className='text-dark mb-3'>
            <FormattedMessage id='Sign in to Ahad' />
          </h1>
          <LocaleToggler />
        </div>

        <Login />
      </div>
      <FancySide />
    </div>
  );
};
