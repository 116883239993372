import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../../network/apis';
import { RootState } from '../../../../setup/redux/Store';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { useQuerySearch } from '../../../hooks/useQuerySearch/useQuerySearch';
import { UserModel } from '../models/UserModel';
import * as auth from '../../../../app/modules/auth/store/AuthRedux';
import { PrivateRoutes } from '../../../../_metronic/constants/ROUTES';

export const Verify = () => {
  const query = useQuerySearch();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  useEffect(() => {
    verifyUser();
  }, []);

  const verifyUser = async () => {
    if (!['expires', 'hash', 'signature', 'id'].every((key) => query.has(key))) {
      toast.error(formatMessage({ id: 'invalid_url_we_will_redirect_you' }));
      history.push('/');
      return <></>;
    }

    try {
      await axiosInstance.post('/verify' + location.search);
      toast.success(formatMessage({ id: 'Your account has been successfully verified!' }));
      if (user?.id) {
        dispatch(auth.actions.setUser({ ...user, active: true }));
        history.push(PrivateRoutes.Homepage);
      }
    } catch (error) {
      DisplayErrors(error);
      toast.error(formatMessage({ id: 'Error!, please try again' }));
    }
    history.push(PrivateRoutes.Homepage);
  };

  return <div></div>;
};
