import clsx from 'clsx';
import { useIntl } from 'react-intl'; //FormattedMessage,
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '../../../../setup';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
// import { capitalize } from '../../../../utils/strings';
import { toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
// import { useHistory } from 'react-router-dom';
// import { PrivateRoutes } from '../../../constants/ROUTES';

export function HeaderUserMenuContainer() {
  const { locale } = useIntl(); //, formatMessage
  // const history = useHistory();

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;
  // const viewMyDocsHandler = () => {
  //   history.push(PrivateRoutes.DocumentsToSign);
  // };

  return (
    <div className={clsx('d-flex align-items-center m-3 m-lg-5 ')} id='kt_header_user_menu_toggle'>
      <div
        className={clsx(
          'cursor-pointer symbol symbol-circle avatar-border d-flex flex-row symbol-30px symbol-md-40px'
        )}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement={locale === 'ar' ? 'bottom-end' : 'bottom-start'}
        data-kt-menu-flip='bottom'
      >
        <img
          src={toAbsoluteUrl(user?.image || toAbsoluteUrl('/media/avatars/blank.png'))}
          alt=''
          className='circular-light-border'
        />
        {/* <div className='capitalized mx-2 d-flex flex-column justify-content-center'>
          <p className='text-uppercase'>
            <FormattedMessage
              id='fullName'
              values={{
                firstname: capitalize(user?.first_name),
                lastname: capitalize(user?.last_name),
              }}
            />
          </p>
        </div> */}
      </div>

      <HeaderUserMenu />

      {/* <div
        className={clsx('d-flex align-items-center ms-1 ms-lg-3 cursor-pointer')}
        title={`${formatMessage({ id: 'my_docs' })}`}

        onClick={viewMyDocsHandler}
      >
        <div className='badge badge-lg badge-circle circular-light-border w-40px h-40px'>
          <i className='fas fa-signature'></i>
        </div>
      </div> */}
    </div>
  );
}
