import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import { getAllInvoiceItems, getLineItem } from './LineItemsCRUD';

export enum ILineItemsActionTypes {
  GET_LINE_ITEMS = 'GET_LINE_ITEMS',
  FULL_FILL_LINE_ITEMS = 'FULL_FILL_LINE_ITEMS',
  GET_CURRENT_LINE_ITEMS = 'GET_CURRENT_LINE_ITEMS',
  FULL_FILL_CURRENT_LINE_ITEMS = 'FULL_FILL_CURRENT_LINE_ITEMS',
}

const initialLineItem: ILineItemState = {
  LineItems: undefined,
  currentLineItems: undefined,
};

export interface ILineItemState {
  LineItems?: any;
  currentLineItems?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_line_items', whitelist: [''] },
  (state: ILineItemState = initialLineItem, action: ActionWithPayload<ILineItemState>) => {
    switch (action.type) {
      case ILineItemsActionTypes.FULL_FILL_LINE_ITEMS:
        return { ...state, LineItems: action.payload?.LineItems };
      case ILineItemsActionTypes.FULL_FILL_CURRENT_LINE_ITEMS:
        return { ...state, currentLineItems: action.payload?.currentLineItems };
      default:
        return state;
    }
  }
);

export const actions = {
  getLineItems: ({ companyId, params }: Search) => ({
    type: ILineItemsActionTypes.GET_LINE_ITEMS,
    payload: { companyId, params },
  }),
  fullFillLineItems: ({ LineItems }: ILineItemState) => ({
    type: ILineItemsActionTypes.FULL_FILL_LINE_ITEMS,
    payload: { LineItems },
  }),
  getCurrentLineItems: ({ companyId, lineItemId }: any) => ({
    type: ILineItemsActionTypes.GET_CURRENT_LINE_ITEMS,
    payload: { companyId, lineItemId },
  }),
  fullFillCurrentLineItems: ({ currentLineItems }: ILineItemState) => ({
    type: ILineItemsActionTypes.FULL_FILL_CURRENT_LINE_ITEMS,
    payload: { currentLineItems },
  }),
};

export function* getLineItems(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllInvoiceItems({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });

    yield put(actions.fullFillLineItems({ LineItems: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentLineItems(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getLineItem({
      companyId: action.payload?.companyId,
      lineItemId: action.payload?.lineItemId,
    });
    yield put(actions.fullFillCurrentLineItems({ currentLineItems: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ILineItemsActionTypes.GET_LINE_ITEMS, getLineItems);
  yield takeLatest(ILineItemsActionTypes.GET_CURRENT_LINE_ITEMS, getCurrentLineItems);
}
