/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom'; //useLocation,
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

import { toast } from 'react-toastify';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';

import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CompanyModel } from '../companies/CompanyModel';

import { SeparatorLines } from '../../shared/Lines';
import { DownloadButton } from '../../shared/DownloadButton';
import { FileUploader } from '../../pages/Companies/CompanyDetails/components/FileUploader';
// import { Label } from '../../shared/Label';

import { axiosInstance } from '../../../network/apis';

import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { ImportNewPurchasesInvoicesDisclamer } from './ImportNewPurchasesInvoicesDisclamer';

type Props = {
  mode: 'create'; //| 'edit' | 'view'
};

export const ImportNewPurchasesInvoicesWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [file, setFile] = useState<any>('');
  const [loading, setLoading] = useState(false);

  /* ----------------------------- Getting Templates ----------------------------- */

  const importInvoices = async () => {
    setLoading(true);

    const formData = serialize({}, { indices: true });
    if (file) {
      formData.append('file', file);
    }
    const uploadURL = `${companyDetails.id}/purchases/import`;

    await axiosInstance
      .post(`${uploadURL}`, formData)
      .then((res) => {
        toast.success(
          formatMessage({
            id: 'File is uploaded successfully. Data will be shown once the processing is done',
          })
        );
        history.push(`/c/${companyDetails.slug}/financial-management/purchases-invoices`);
        return res;
      })
      .catch((err) => {
        formData.delete('file');
        setFile('');
        DisplayErrors(err);
        return err;
      });

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => importInvoices(),
  });

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  /* ----------------------- end getting data from store ---------------------- */

  /* -------------------------------- On Init -------------------------------- */

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && (
              <>
                {$t('Import invoices')} {$t('Purchases')}
              </>
            )}
          </div>
        </div>
        <SeparatorLines lines={2} />

        <DownloadButton
          fa='arrow-down'
          onClick={
            () => {
              return axiosInstance
                .get(`${companyDetails.id}/invoices/templates/purchases`, {
                  responseType: 'blob',
                })
                .then((response) => {
                  let file = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  });
                  let fileURL = URL.createObjectURL(file);

                  // create <a> tag dinamically
                  let fileLink = document.createElement('a');
                  fileLink.href = fileURL;

                  // it forces the name of the downloaded file
                  fileLink.download = 'Ahad_import-purchases-template';

                  // triggers the click event
                  fileLink.click();
                });
            }

            // window.open(
            //   `${process.env.REACT_APP_BACKEND_BASE_URL?.replace(
            //     /\/api$/,
            //     ``
            //   )}/Ahad_import-purchases-template.xlsx`
            // )
            // window.open('https://api.ahadtest.com/Ahad_import-purchases-template.xlsx')
          }
          nameId='Template'
        />

        <SeparatorLines lines={2} />
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_template' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  {/*  ------------------------purchasess file -----------------------  */}
                  <div className='col-md-6' style={{ maxWidth: '40%' }}>
                    <FileUploader
                      setFile={setFile}
                      file={file}
                      maxSize={2 * 1024}
                      supportedFiles={[
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                        '.csv',
                        'text/csv',
                      ]}
                      typesOnly='xlsx'
                    />
                  </div>
                  {/*  ------------------------end purchasess file -----------------------  */}
                </div>

                {/* Save and Cancel Buttons */}
                {mode === 'create' && (
                  <>
                    <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.push(
                          `/c/${companyDetails.slug}/financial-management/purchases-invoices`
                        );
                      }}
                    />
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
      <ImportNewPurchasesInvoicesDisclamer />
    </div>
  );
};

const ImportNewPurchasesInvoicesWrapperCreate: FC = () => {
  usePermissions(['create-invoice']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Purchases invoices' })}</PageTitle>
      {/* <div> Coming Soon </div> */}
      <ImportNewPurchasesInvoicesWrapper mode='create' />
    </div>
  );
};
export { ImportNewPurchasesInvoicesWrapperCreate };
