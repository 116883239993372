import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: JSX.Element | string;
  hasBullet?: boolean;
  linkId?: string;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  linkId,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', { active: isActive })}
        to={to}
        id={linkId?.replaceAll(' ', '_')}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {fontIcon && typeof fontIcon === 'string' ? (
          <span className='menu-icon'>
            <i className={fontIcon + ' mx-2'}></i>
          </span>
        ) : (
          fontIcon
        )}
        {!fontIcon && icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { AsideMenuItem };
