type IInitialConfigPayload = {
  [key: string]: any;
};

export function initialConfigPayload(values: any): IInitialConfigPayload {
  const payload: IInitialConfigPayload = {
    ...values,
  };
  return payload;
}
