import { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { DisplayErrors } from '../../../../utils/DisplayErrors';
import * as CompanyRedux from '../../../modules/admins/companies/store/CompaniesRedux';
import { TextArea } from '../../../shared/textarea';
import * as ModalRedux from '../../../store/ModalRedux';

export const RequestEditsForm = ({ companyId }: any) => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      message: '',
    }),
    [formatMessage]
  );

  const requestEditsSchema = useMemo(
    () =>
      Yup.object().shape({
        message: Yup.string()
          .required(formatMessage({ id: 'This field is required' }))
          .min(
            3,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: 3 }
            )
          )
          .max(
            15,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: 15 }
            )
          ),
      }),
    [formatMessage]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: requestEditsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        dispatch(
          CompanyRedux.actions.updateCompanyStatus({
            id: companyId,
            status: false,
            message: values.message,
          })
        );

        dispatch(ModalRedux.actions.closeActiveModal());
      } catch (err: any) {
        DisplayErrors(err);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  return (
    <form
      noValidate
      autoComplete='none'
      id='request-edits'
      onSubmit={formik.handleSubmit}
      style={{
        width: '600px',
        paddingRight: locale === 'en' ? '4em' : '',
        paddingLeft: locale === 'ar' ? '4em' : '',
      }}
    >
      <div>
        <TextArea
          placeholder={formatMessage({ id: 'Edit reason' })}
          labelId={formatMessage({ id: 'Edit reason' })}
          name='message'
          type='message'
          formik={formik}
        />
      </div>

      {/* begin::Form group */}
      <div className='col-xl-4 mt-5'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-sm btn-blue w-100 mb-5  shadowed mt-4'
          disabled={formik.isSubmitting}
        >
          {!loading && (
            <span className='indicator-label'>
              <i className='far fa-share mx-2 text-white'></i>
              <FormattedMessage id='Send' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Please wait...' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};
