/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PublicRoutes } from '../../../_metronic/constants/ROUTES';
import { RegistrationContainer } from './containers/RegistrationContainer';
import { LoginContainer } from './containers/LoignContainer';
import { ForgetPasswordContainer } from './containers/ForgetPasswordContainer';
import { ResetPasswordContainer } from './containers/ResetPasswordContainer';
import { Verify } from './containers/Verify';
import { AboutUs } from '../public/AboutUs';
import { TermsAndConditionsUs } from '../public/TermsConditions';
import { NonRegisteredClientDocument } from '../documents/NonRegisteredClientDocument';
import { RouteWithLayout } from '../../routing/RouteWithLayout';
import { PublicLayout } from '../../../_metronic/layout/PublicLayout';

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-main');
    return () => {
      document.body.classList.remove('bg-main');
    };
  }, []);

  return (
    <Switch>
      <Route path={PublicRoutes.login} component={LoginContainer} />
      <Route path={PublicRoutes.signup} component={RegistrationContainer} />
      <Route path={PublicRoutes.forgetPassword} component={ForgetPasswordContainer} />
      <Route exact path={PublicRoutes.verify} component={Verify} />
      <Route path={PublicRoutes.resetPassword} component={ResetPasswordContainer} />
      {/* ---------------------------------- pages --------------------------------- */}
      <Route exact path={PublicRoutes.about} component={AboutUs} />
      <Route exact path={PublicRoutes.TermsAndConditions} component={TermsAndConditionsUs} />
      {/* <Route
        path={PublicRoutes.NonRegisteredClientDocument}
        component={NonRegisteredClientDocument}
      /> */}
      <RouteWithLayout
        exact
        path={PublicRoutes.NonRegisteredClientDocument}
        layout={PublicLayout}
        component={NonRegisteredClientDocument}
      />

      <Redirect from='/auth' exact={true} to='/auth/login' />
      <Redirect to='/auth/login' />
    </Switch>
  );
}
