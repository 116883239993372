import { emailPattern, uriStrictPatterns, specialCharacters } from '../../constants/patterns';

export const validPassword = (password: string): boolean => {
  return password.length >= 6 && password.length <= 10 && !password.includes('"');
};

export const validWebsite = (url: string): boolean => {
  return uriStrictPatterns.test(url);
};

export function isValidEmail(value: string): boolean {
  return emailPattern.test(value);
}

export function isEmptyString(str: string): boolean {
  return str.length === 0;
}

export function nameWithoutSpcialCharsStartOrEnd(str: string | undefined): boolean {
  if (!str) return true;
  const string = str.split('');
  return (
    !specialCharacters.test(string[0]) &&
    string[0] !== ' ' &&
    !specialCharacters.test(string[string.length - 1])
  );
}
