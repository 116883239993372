import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import {
  getAllWithholdingReports,
  getWithholdingReport,
  getAllWithholdingTransactions,
  getAllPurchasesWithholdingTransactions,
} from './WithholdingReportsCRUD';

export enum IWithholdingReportsActionTypes {
  GET_WITHHOLDING_REPORTS = 'GET_WITHHOLDING_REPORTS',
  FULL_FILL_WITHHOLDING_REPORTS = 'FULL_FILL_WITHHOLDING_REPORTS',
  GET_CURRENT_WITHHOLDING_REPORT = 'GET_CURRENT_WITHHOLDING_REPORT',
  FULL_FILL_CURRENT_WITHHOLDING_REPORT = 'FULL_FILL_CURRENT_WITHHOLDING_REPORT',
  GET_WITHHOLDING_TRANSACTIONS = 'GET_WITHHOLDING_TRANSACTIONS',
  FULL_FILL_WITHHOLDING_TRANSACTIONS = 'FULL_FILL_WITHHOLDING_TRANSACTIONS',
  GET_WITHHOLDING_PURCHASES_TRANSACTIONS = 'GET_WITHHOLDING_PURCHASES_TRANSACTIONS',
  FULL_FILL_WITHHOLDING_PURCHASES_TRANSACTIONS = 'FULL_FILL_WITHHOLDING_PURCHASES_TRANSACTIONS',
}

const initialWithholdingReports: IWithholdingReportsState = {
  withholding_reports: undefined,
  currentWithholdingReport: undefined,
  withholding_transactions: undefined,
  withholding_purchases_transactions: undefined,
};

export interface IWithholdingReportsState {
  withholding_reports?: any;
  currentWithholdingReport?: any;
  withholding_transactions?: any;
  withholding_purchases_transactions?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_withholding_reports', whitelist: [''] },
  (
    state: IWithholdingReportsState = initialWithholdingReports,
    action: ActionWithPayload<IWithholdingReportsState>
  ) => {
    switch (action.type) {
      case IWithholdingReportsActionTypes.FULL_FILL_WITHHOLDING_REPORTS:
        return { ...state, withholding_reports: action.payload?.withholding_reports };
      case IWithholdingReportsActionTypes.FULL_FILL_CURRENT_WITHHOLDING_REPORT:
        return { ...state, currentWithholdingReport: action.payload?.currentWithholdingReport };
      case IWithholdingReportsActionTypes.FULL_FILL_WITHHOLDING_TRANSACTIONS:
        return { ...state, withholding_transactions: action.payload?.withholding_transactions };
      case IWithholdingReportsActionTypes.FULL_FILL_WITHHOLDING_PURCHASES_TRANSACTIONS:
        return {
          ...state,
          withholding_purchases_transactions: action.payload?.withholding_purchases_transactions,
        };
      default:
        return state;
    }
  }
);

export const actions = {
  getWithholdingReports: ({ companyId, params }: Search) => ({
    type: IWithholdingReportsActionTypes.GET_WITHHOLDING_REPORTS,
    payload: { companyId, params },
  }),
  fullFillWithholdingReports: ({ withholding_reports }: IWithholdingReportsState) => ({
    type: IWithholdingReportsActionTypes.FULL_FILL_WITHHOLDING_REPORTS,
    payload: { withholding_reports },
  }),
  getCurrentWithholdingReport: ({ companyId, reportId }: any) => ({
    type: IWithholdingReportsActionTypes.GET_CURRENT_WITHHOLDING_REPORT,
    payload: { companyId, reportId },
  }),
  fullFillCurrentWithholdingReport: ({ currentWithholdingReport }: IWithholdingReportsState) => ({
    type: IWithholdingReportsActionTypes.FULL_FILL_CURRENT_WITHHOLDING_REPORT,
    payload: { currentWithholdingReport },
  }),
  getWithholdingTransactions: ({ companyId, params }: Search) => ({
    type: IWithholdingReportsActionTypes.GET_WITHHOLDING_TRANSACTIONS,
    payload: { companyId, params },
  }),
  fullFillWithholdingTransactions: ({ withholding_transactions }: IWithholdingReportsState) => ({
    type: IWithholdingReportsActionTypes.FULL_FILL_WITHHOLDING_TRANSACTIONS,
    payload: { withholding_transactions },
  }),
  getWithholdingPurchasesTransactions: ({ companyId, params }: Search) => ({
    type: IWithholdingReportsActionTypes.GET_WITHHOLDING_PURCHASES_TRANSACTIONS,
    payload: { companyId, params },
  }),
  fullFillWithholdingPurchasesTransactions: ({
    withholding_purchases_transactions,
  }: IWithholdingReportsState) => ({
    type: IWithholdingReportsActionTypes.FULL_FILL_WITHHOLDING_PURCHASES_TRANSACTIONS,
    payload: { withholding_purchases_transactions },
  }),
};

export function* getWithholdingReports(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllWithholdingReports({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillWithholdingReports({ withholding_reports: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentWithholdingReport(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getWithholdingReport({
      companyId: action.payload?.companyId,
      reportId: action.payload?.reportId,
    });
    yield put(actions.fullFillCurrentWithholdingReport({ currentWithholdingReport: data }));
  } catch (error) {
    //
  }
}

export function* getWithholdingTransactions(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllWithholdingTransactions({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillWithholdingTransactions({ withholding_transactions: data }));
  } catch (error) {
    //
  }
}

export function* getWithholdingPurchasesTransactions(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllPurchasesWithholdingTransactions({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(
      actions.fullFillWithholdingPurchasesTransactions({ withholding_purchases_transactions: data })
    );
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IWithholdingReportsActionTypes.GET_WITHHOLDING_REPORTS, getWithholdingReports);
  yield takeLatest(
    IWithholdingReportsActionTypes.GET_WITHHOLDING_TRANSACTIONS,
    getWithholdingTransactions
  );
  yield takeLatest(
    IWithholdingReportsActionTypes.GET_CURRENT_WITHHOLDING_REPORT,
    getCurrentWithholdingReport
  );
  yield takeLatest(
    IWithholdingReportsActionTypes.GET_WITHHOLDING_PURCHASES_TRANSACTIONS,
    getWithholdingPurchasesTransactions
  );
}
