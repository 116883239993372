import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import * as DocumentsRedux from './store/DocumentsRedux';
import * as UserRedux from '../../modules/users/store/UsersRedux';

import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CommonTable } from '../../shared/tables/Table';
import { RootState } from '../../../setup/redux/Store';
import { CompanyModel } from '../companies/CompanyModel';
import {
  DateViewer,
  TableActions,
  TableData,
  TableSelection,
  TableStatus,
} from '../../shared/tables/classes';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { IDocument, DocumentsDataListModel } from './types/DocumentsModel';
import {
  DOCS_STATUS,
  DocumentDirection,
  DocumentStatuses,
  RecipientTypeMap,
} from '../../../_metronic/constants/general';
import { SearchDocuments } from './SearchDocuments';
import { Badge } from '../../../_metronic/helpers/typescript/csssClasses';
import { ViewItem } from '../../shared/tables/actions/ViewItem';

const TABLE_NAME = 'DOCUMENTS_TABLE';

const headerItems = [
  { id: 'Document Title', maxWidth: 240, width: 230 },
  { id: 'Type of Document', maxWidth: 400, width: 400 },
  { id: 'Creation Date', maxWidth: 150, width: 150 },
  { id: 'Document Created By', maxWidth: 180, width: 180 },
  { id: 'Recipient type', maxWidth: 150, width: 150 },
  { id: 'Status', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];

export const Documents: FC = () => {
  const dispatch = useDispatch();

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  useEffect(() => {
    dispatch(
      UserRedux.actions.getUsers({
        companyId: companyDetails.id,
        params: {
          per_page: 0,
        },
      })
    );
  }, []);

  /* --------------------------------- Leaving useEffect --------------------------- */
  useEffect(() => {
    return () => {
      dispatch(UserRedux.actions.fulfillUsers({}));
    };
  }, []);
  /* -------------------------------END Leaving useEffect  -------------------------------- */

  return (
    <div>
      {SearchDocuments({ tableName: TABLE_NAME })}

      <DocumentsTable />
    </div>
  );
};

export const DocumentsTable: FC = () => {
  const { formatMessage } = useIntl();
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  // const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const documents: DocumentsDataListModel = useSelector<RootState>(
    (state) => state.USER_documents.documents,
    shallowEqual
  ) as DocumentsDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(DocumentsRedux.actions.fulfillDocuments({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      DocumentsRedux.actions.getDocuments({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  }, [table?.page, table?.pagination, table?.query, params.companySlug, companyDetails.id]);

  const rowItems = documents?.data.map((document: IDocument) => [
    new TableSelection({
      display: userCan('delete-document'),
      id: document.id,
      tableName: TABLE_NAME,
    }),
    new TableData({ text: document?.title }),
    new TableData({ text: $t(RecipientTypeMap.get(document?.recipient_type)?.label || '') }),
    new DateViewer({ date: document.created_at }),
    new TableData({ text: document?.user?.name }),
    new TableData({ text: $t(DocumentDirection.get(document?.type)?.label || '') }),
    new TableStatus({
      statusId: formatMessage({ id: `${DocumentStatuses.get(document?.status)?.label}` }),
      badge: `${DocumentStatuses.get(document?.status)?.badge}` as Badge,
    }),
    new TableActions(
      (
        /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
        <div className='d-flex flex-row'>
          <ViewItem
            title={$t('View')}
            onClick={() => history.push(`/d/documents/${document.id}?slug=${companyDetails.slug}`)}
          />
          {/* Create document = update document in the back-end */}
          {userCan('create-document') &&

            ![DOCS_STATUS.CLOSED, DOCS_STATUS.EXPIRED].includes(document.status) && (
              <EditItem
                title={$t('Edit')}
                onClick={() =>
                  history.push(`/c/${companyDetails.slug}/documents/update/${document.id}`)
                }
              />
            )}
          {/* {userCan('delete-document') && (
            <DeleteItem
              id={document.id}
              messageId={'Are you sure you want to delete this document?'}
              setDeleteItemId={setDeleteItemId}
              deleteItemId={deleteItemId}
              deleteURI={`/${companyDetails.id}/documents`}
              payload={[document.id]}
              successMessage={'Document has been deleted successfully!'}
              afterSucessRequest={() => RequestData()}
            />
          )} */}
        </div>
      )
    ),
  ]);

  return (
    <div className='table-wrapper'>
      {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline mx-5'>
        {userCan('delete-document') && (
          <DeleteItem
            isButton
            messageId={'Are you sure you want to delete this document?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/documents`}
            payload={deleteItemId}
            successMessage={'Client has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )}
      </div> */}
      <CommonTable
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={userCan('delete-document')}
        allDisplayedIds={documents?.data?.map((document) => document.id) || []}
        meta={documents}
        headerItems={headerItems}
        rowItems={rowItems}
      />
    </div>
  );
};

const DocumentsWrapper: FC = () => {
  usePermissions('view-document');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Documents' })}</PageTitle>
      <Documents />
    </div>
  );
};

export { DocumentsWrapper };
