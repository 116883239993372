/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useLayout } from '../core';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const Footer: FC = () => {
  const { classes } = useLayout();
  const { formatMessage } = useIntl();
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <Link to='/' className='text-hover-primary'>
            {formatMessage({
              id: 'AHAD',
            })}
          </Link>
          {/* <span className='text-gray-800 text-hover-primary'>
            <Logo withCompanyName={false} />
          </span> */}
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <span
              className='text-dustyBlue mx-1 cursor-pointer menu-link ps-0 pe-2'
              onClick={() => window.open('https://ahad.ws/about-us/terms_and_conditions/')}
            >
              <FormattedMessage id='Terms and Conditions' />
            </span>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
