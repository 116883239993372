import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ID } from '../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { PurchasesInvoiceListModel } from '../types/purchasesInvoiceList';
// import { user } from '../../../types/user';
import {
  getPurchasesInvoiceList,
  getCurrentPurchasesInvoiceRequest,
} from './PurchasesInvoicesCrud';

export enum IPurchasesInvoicesActionTypes {
  GET_PURCHASES_INVOICES = 'GET_PURCHASES_INVOICES',
  FULLFULL_PURCHASES_INVOICES = 'FULLFULL_PURCHASES_INVOICES',
  GET_CURRENT_PURCHASE_INVOICE = 'GET_CURRENT_PURCHASE_INVOICE',
  FULLFULL_CURRENT_PURCHASE_INVOICE = 'FULLFULL_CURRENT_PURCHASE_INVOICE ',
}

const initialInvoicesState: IPurchasesInvoicesState = {
  PurchasesInvoices: undefined,
  currentPurchaseInvoice: undefined,
};

export interface IPurchasesInvoicesState {
  PurchasesInvoices?: PurchasesInvoiceListModel;
  currentPurchaseInvoice?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_purchases_invoices', whitelist: [''] },
  (
    state: IPurchasesInvoicesState = initialInvoicesState,
    action: ActionWithPayload<IPurchasesInvoicesState>
  ) => {
    switch (action.type) {
      case IPurchasesInvoicesActionTypes.FULLFULL_PURCHASES_INVOICES:
        return { ...state, PurchasesInvoices: action.payload?.PurchasesInvoices };
      case IPurchasesInvoicesActionTypes.FULLFULL_CURRENT_PURCHASE_INVOICE:
        return { ...state, currentPurchaseInvoice: action.payload?.currentPurchaseInvoice };
      default:
        return state;
    }
  }
);

type CompanyInvoicesSearch = { companyId: ID | any; params?: any };
type CurrentInvoiceBasicData = { companyId: ID | any; invoiceId?: ID | any };

export const actions = {
  getPurchasesInvoices: ({ companyId, params }: CompanyInvoicesSearch) => ({
    type: IPurchasesInvoicesActionTypes.GET_PURCHASES_INVOICES,
    payload: { companyId, params },
  }),
  fulfillPurchasesInvoices: ({ PurchasesInvoices }: IPurchasesInvoicesState) => ({
    type: IPurchasesInvoicesActionTypes.FULLFULL_PURCHASES_INVOICES,
    payload: { PurchasesInvoices },
  }),
  getCurrentPurchaseInvoice: ({ companyId, invoiceId }: CurrentInvoiceBasicData) => ({
    type: IPurchasesInvoicesActionTypes.GET_CURRENT_PURCHASE_INVOICE,
    payload: { companyId, invoiceId },
  }),
  fulfillCurrentPurchaseInvoice: ({ currentPurchaseInvoice }: IPurchasesInvoicesState) => ({
    type: IPurchasesInvoicesActionTypes.FULLFULL_CURRENT_PURCHASE_INVOICE,
    payload: { currentPurchaseInvoice },
  }),
};

export function* getPurchasesInvoicesSaga(action: ActionWithPayload<CompanyInvoicesSearch>) {
  try {
    // Getting PurchasesInvoices request
    const { data } = yield getPurchasesInvoiceList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing invoices to store
    yield put(actions.fulfillPurchasesInvoices({ PurchasesInvoices: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentPurchaseInvoice(action: ActionWithPayload<CurrentInvoiceBasicData>) {
  try {
    // Getting Purchases Invoices request
    const { data } = yield getCurrentPurchasesInvoiceRequest({
      companyId: action.payload?.companyId,
      invoiceId: action.payload?.invoiceId,
    });
    // Storing invoices to store
    yield put(actions.fulfillCurrentPurchaseInvoice({ currentPurchaseInvoice: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IPurchasesInvoicesActionTypes.GET_PURCHASES_INVOICES, getPurchasesInvoicesSaga);
  yield takeLatest(
    IPurchasesInvoicesActionTypes.GET_CURRENT_PURCHASE_INVOICE,
    getCurrentPurchaseInvoice
  );
}
