import ReactGA from 'react-ga4';

const createReport = () => {
  ReactGA.event({
    category: 'Report',
    action: 'Create Report Action',
    label: 'Created',
    // value: 'Create Report Value',
  });
};

const approveReport = () => {
  ReactGA.event({
    category: 'Report',
    action: 'Approve Report Action',
    label: 'Approved',
    // value: 'Approve Report Value',
  });
};

const GAEvents = {
  createReport,
  approveReport,
};

export { GAEvents };
