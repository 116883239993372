import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { RootState } from '../../../../setup';
import AhadDialog from '../../../../setup/shared/AhadDialog';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';
import { ID } from '../../../../_metronic/constants/paths';
import { KTSVG } from '../../../../_metronic/helpers';
import { companySlugTo } from '../../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { Portal } from '../../../../_metronic/partials';
import { CompanyModel } from '../../../modules/companies/CompanyModel';
import { createDocumentFromTemplateIdRequest } from '../../../modules/documents/store/DocumentsCrud';
import * as DocumentsRedux from '../../../modules/documents/store/DocumentsRedux';
import { Input } from '../../Input';
import SaveCancelBtnsGroup from '../../SaveCancelBtnsGroup';

type Props = {
  templateId: ID;
};

export const UseTemplateToCreateDocument: FC<Props> = ({ templateId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<ID | null>(null);
  const [createDocumentModalOpen, setCreateDocumentModalOpen] = useState(false);

  /* ----------------------- company details from store ----------------------- */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const formik = useFormik({
    initialValues: { title: '' },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(
          commonValidations.nameMinimum,
          formatMessage(
            { id: 'Please enter at least {minCharacters} characters' },
            { minCharacters: commonValidations.nameMinimum }
          )
        )
        .required(formatMessage({ id: 'This field is required' })),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const companyId = companyDetails.id as ID;
        const response = await createDocumentFromTemplateIdRequest({
          companyId,
          templateId: selectedTemplateId as ID,
          title: values.title,
        });
        dispatch(
          DocumentsRedux.actions.fulfillUnderConstructionDocument({
            underConstructionDocument: response.data,
          })
        );
        toast.success(formatMessage({ id: 'Your document has been successfully created!' }));
        history.push(companySlugTo(companyDetails?.slug, `documents/update/${response.data.id}`));
      } catch (err: any) {
        DisplayErrors(err);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <a
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        title={$t('Create Document')}
        onClick={() => {
          setSelectedTemplateId(templateId);
          setCreateDocumentModalOpen(true);
        }}
      >
        <KTSVG path='/media/icons/duotune/layouts/lay001.svg' className='svg-icon-3' />
      </a>
      {createDocumentModalOpen && (
        <Portal className='main_modal'>
          <AhadDialog
            closeCallBack={() => {
              setCreateDocumentModalOpen(false);
              setSelectedTemplateId(null);
              formik.resetForm();
            }}
            specialContainerClass='dialog-content-container-add-user'
            specialTitleFormat={<FormattedMessage id='CREATE NEW DOCUMENT' />}
          >
            <form
              noValidate
              autoComplete='none'
              id='request-edits'
              onSubmit={formik.handleSubmit}
              style={{ width: '500px', minWidth: '500px' }}
            >
              <div className='mb-4'>
                <Input
                  placeholder={formatMessage({ id: 'Document Title' })}
                  labelId={formatMessage({ id: 'Document Title' })}
                  name='title'
                  type='title'
                  formik={formik}
                />
              </div>
              <SaveCancelBtnsGroup
                saveBtnLabel='Create'
                containerClassName='col-xl-12'
                formik={formik}
                loading={loading}
                cancelOnClick={() => {
                  setCreateDocumentModalOpen(false);
                  setSelectedTemplateId(null);
                  formik.resetForm();
                }}
              />
            </form>
          </AhadDialog>
        </Portal>
      )}
    </>
  );
};
