import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { RootState } from '../../../setup/redux/Store';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
// Hide the unnecessary
// import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { KTSVG } from '../../../_metronic/helpers';
import { CompanyModel } from '../companies/CompanyModel';
import { UsersDataListModel } from './types/usersList';
import { SerachFilters } from '../../shared/SearchFilters';

import * as TableRedux from '../../store/TableRedux';
import * as UsersRedux from '../users/store/UsersRedux';
import * as RolesRedux from '../roles/store/RolesRedux';

import { TableStatus, ItemWithImage } from '../../shared/tables/classes';
import { TableData } from '../../shared/tables/classes/TableData';
import { TableActions } from '../../shared/tables/classes/TableActions';
import { TableSelection } from '../../shared/tables/classes/TableSelection';
import { DateViewer } from '../../shared/tables/classes/DateViewer';
import { RolesDataListModel } from '../roles/types/Roles';
import { TableRolesViewer } from '../../shared/tables/classes/TableRolesViewer';
import { usePermissions } from '../../hooks/usePermissions';
import { Button } from '../../shared/Button';
import { CommonTable2 } from '../../shared/tables/Table2';
import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';

const headerItems = [
  { id: 'Name', maxWidth: 440, width: 440 },
  { id: 'Email', maxWidth: 240 },
  { id: 'Roles', maxWidth: 300, width: 300 },
  { id: 'Last Active', maxWidth: 270, width: 270 },
  { id: 'Status', maxWidth: 100, width: 100 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];
const headerItemsWithoutActions = [
  { id: 'Name', maxWidth: 440, width: 440 },
  { id: 'Email', maxWidth: 240 },
  { id: 'Roles', maxWidth: 300, width: 300 },
  { id: 'Last Active', maxWidth: 270, width: 270 },
  { id: 'Status', maxWidth: 100, width: 100 },
];

const TABLE_NAME = 'users_list';

export const Users: FC<any> = ({ viewEditRole }) => {
  return (
    <div>
      {/*  ---------------------------- Start users table ---------------------------  */}
      <UsersTable viewEditRole={viewEditRole} />
      {/*  ---------------------------- End users table ---------------------------  */}
    </div>
  );
};

export const UsersTable: FC<any> = ({ viewEditRole }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const { formatMessage } = useIntl();
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);

  /* -------------------------- Bring Data from store ------------------------- */

  const roles: RolesDataListModel = useSelector<RootState>(
    (state) => state.roles.roles,
    shallowEqual
  ) as RolesDataListModel;

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const users: UsersDataListModel = useSelector<RootState>(
    (state) => state.USER_users.Users,
    shallowEqual
  ) as UsersDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = useMemo(() => tables[TABLE_NAME], [tables[TABLE_NAME]]);
  /* ----------------------- Getting roles for dropdown ----------------------- */
  useEffect(() => {
    if (companyDetails?.id && params.companySlug) {
      dispatch(
        RolesRedux.actions.getRoles({ companyId: companyDetails.id, params: { per_page: 0 } })
      );
    }
  }, [dispatch, companyDetails?.id, params.companySlug]);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(UsersRedux.actions.fulfillUsers({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      UsersRedux.actions.getUsers({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (params.companySlug && companyDetails?.id && table?.page) {
      RequestData();
    }
  }, [table?.page, table?.pagination, params.companySlug, companyDetails?.id]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  const rowItems = users?.data.map((user) => [
    new TableSelection({
      display: userCan('delete-user') || viewEditRole,
      id: user.id,
      tableName: TABLE_NAME,
      disabled: viewEditRole?.mode === 'view',
    }),
    new ItemWithImage({ name: user.name, image: user?.image }),
    new TableData({ text: user.email }),
    new TableRolesViewer({ roles: user.roles }),
    new DateViewer({ date: user.last_visit ? user.last_visit : '' }),
    new TableStatus({
      statusId: user.active ? 'Active' : 'Non active',
      badge: user.active ? 'badge-success' : 'badge-lightGreyBlue',
    }),
    new TableActions(
      (
        /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
        <div className='d-flex flex-row'>
          {user.active && !viewEditRole?.mode && (
            <ViewItem
              title={$t('View')}
              onClick={() =>
                history.push(`/c/${companyDetails.slug}/company-management/users/${user.id}`)
              }
            />
          )}
          {user.active && !viewEditRole?.mode && user.id !== companyDetails.owner.id && (
            <>
              {userCan('update-user') && (
                <EditItem
                  title={$t('Edit')}
                  onClick={() =>
                    history.push(
                      `/c/${companyDetails.slug}/company-management/users/${user.id}/edit`
                    )
                  }
                />
              )}
              {/* Hide the unnecessary */}
              {userCan('delete-user') && !user.isCompanyAdmin && (
                <DeleteItem
                  id={user.id}
                  title={$t('Delete')}
                  deleteURI={`${companyDetails.id}/users/${user.id}`}
                  successMessage={'The user has been deleted'}
                  messageId={'are you sure you want to delete this user?'}
                  methodType='delete'
                  showButtonGroup={true}
                  setDeleteItemId={setDeleteItemId}
                  deleteItemId={deleteItemId}
                  afterSucessRequest={() => RequestData()}
                />
              )}
            </>
          )}
          {user.is_pending && (
            <>
              {userCan('delete-user') && !user.isCompanyAdmin && (
                <DeleteItem
                  id={user.id}
                  title={$t('Delete')}
                  deleteURI={`${companyDetails.id}/invitations/${user.id}`}
                  successMessage={'The user has been deleted'}
                  messageId={'are you sure you want to delete this user?'}
                  methodType='delete'
                  showButtonGroup={true}
                  setDeleteItemId={setDeleteItemId}
                  deleteItemId={deleteItemId}
                  afterSucessRequest={() => RequestData()}
                />
              )}
            </>
          )}
        </div>
      )
    ),
  ]);

  return (
    <div className='table-wrapper card'>
      {/* begin::Header */}
      <div className='card-header border-0 '>
        {/*  ------------------------ start search and filters ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => {
              setShowSearchFilters(!showSearchFilters);
            }}
          >
            {!showSearchFilters && (
              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
            )}
            {showSearchFilters && (
              <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
            )}
          </button>
        </div>

        {/*  ------------------------- End Search and filters -------------------------  */}

        {/*  ------------------------ start Action button ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 2 */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                {$t('Actions')}{' '}
              </div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mb-3 opacity-75'></div>
            {/* end::Menu separator */}

            {/* begin::Menu item */}
            {userCan('invite-user') && !viewEditRole?.mode && (
              <div className='menu-item px-3 py-3'>
                <Button
                  className='menu-link px-3 btn w-md-190px'
                  faIconMenu={true}
                  fa='plus'
                  onClick={() => {
                    if (users?.can_create) {
                      history.push(
                        `/c/${params.companySlug}/company-management/users/invite-users`
                      );
                    } else {
                      setShowConfirmationModal(true);
                    }
                  }}
                  nameId='Invite users'
                />
              </div>
            )}
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>

        {/*  ------------------------ End Action button ------------------------  */}
      </div>
      {/* end::Header */}

      {showSearchFilters && (
        <div className='fw-bold m-auto' style={{ width: '95%' }}>
          <div className='px-3'>
            <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
          </div>
          <div className='separator mb-3 opacity-75'></div>
          <SerachFilters
            leftHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'search',
                queryTerm: 'name',
                onChange: (value: string) => {
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'name',
                    })
                  );
                },
                placeholderId: 'Search anything',
              },
            ]}
            rightHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'dropdown',
                queryTerm: 'role',
                onChange: (value: any) =>
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'role',
                    })
                  ),
                placeholderId: 'role',
                options: roles?.data?.map((role) => ({
                  value: role.id.toString(),
                  label: role.name,
                })),
              },
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'dropdown',
                queryTerm: 'status',
                onChange: (value: any) =>
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'status',
                    })
                  ),
                placeholderId: 'Status',
                options: [
                  { value: '1', label: formatMessage({ id: 'Active' }) },
                  // { value: '2', label: formatMessage({ id: 'Suspended' }) },
                  { value: '0', label: formatMessage({ id: 'Non active' }) },
                ],
              },
            ]}
            searchFilterHandler={searchFilterHandler}
            clearSearchFilterHandler={clearSearchFilterHandler}
          />
        </div>
      )}
      <CommonTable2
        className='pb-5 pb-xl-8'
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={userCan('delete-user') || viewEditRole}
        disableHeaderWithCheckbox={viewEditRole?.mode === 'view'}
        allDisplayedIds={users?.data?.map((user) => user.id)}
        meta={users}
        headerItems={!viewEditRole?.mode ? headerItems : headerItemsWithoutActions}
        rowItems={rowItems}
      />

      {/*  ---------------------warnings confirmation Modal-----------------------  */}

      {showConfirmationModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => setShowConfirmationModal(false)}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 m-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                {formatMessage({
                  id: 'You have reached the maximum allowed for your current subscription. Contact support to upgrade.',
                })}
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowConfirmationModal(false);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end warnings confirmation Modal-----------------------  */}
    </div>
  );
};

const UsersWrapper: FC = () => {
  usePermissions('view-user');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Users' })}</PageTitle>
      <Users />
    </div>
  );
};

export { UsersWrapper };
