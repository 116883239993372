import clsx from 'clsx';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { $t } from '../../../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../../../_metronic/layout/core';
// import { usePermissions } from '../../../../hooks/usePermissions';

import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '../../../../../setup/redux/Store';
import { IVatRate } from '../../../../modules/admins/vat-rates/types/VatRatesModel';

import useWindowSize from '../../../../hooks/useWindowSize';
import { SeparatorLines } from '../../../../shared/Lines';
import { VAT_RATES_TABS, VatRateDetailsHandler } from './components/TabSelection';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

export const VatRatesDetails: FC<Props> = ({ mode }) => {
  // const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const { width } = useWindowSize();

  // const { formatMessage } = useIntl();
  // const location = useLocation();

  const tabOptions = VAT_RATES_TABS.map((tab) => ({
    value: tab.id,
    label: $t(tab.tabId),
  }));

  return (
    <>
      {width > 1156 ? (
        <div className='brts-radius-20 blts-radius-20 bg-white h-40px pt-3 px-2 details_nav'>
          {VAT_RATES_TABS.map((tab) => (
            <span
              className={clsx(
                `mx-2 cursor-pointer text-blueGrey fs-6 ${selectedTab === tab.id ? 'current' : ''}`
              )}
              onClick={() => {
                setSelectedTab(tab.id);
              }}
            >
              <i className={clsx(`far ${tab.tabIcon} mx-1`)}></i>
              {$t(tab.tabId)}
            </span>
          ))}
        </div>
      ) : (
        <Select
          onChange={(value) => setSelectedTab(value?.value || 1)}
          options={tabOptions}
          value={tabOptions.find((itm) => selectedTab === itm.value)}
          className='react-select smaller'
          id='company_type'
        />
      )}

      <section>
        <SeparatorLines lines={1} />
        <VatRateDetailsHandler currentView={selectedTab} mode={mode} />
      </section>
    </>
  );
};

const VatRatesDetailsWrapperCreate: FC = () => {
  // usePermissions('update-company');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Create vat rate' })}</PageTitle>
      <VatRatesDetails mode='create' />
    </div>
  );
};

const VatRatesDetailsWrapperView: FC = () => {
  // usePermissions('view-company');
  const history = useHistory();
  const location = useLocation();
  // const { userCan } = usePermissions();

  const vatRateItem: IVatRate = useSelector<RootState>((state) => {
    return state.SYS_vat_rates.vat_rate;
  }, shallowEqual) as IVatRate;

  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Preview vat rate' })}</PageTitle>
      {(vatRateItem?.type === 1 || vatRateItem?.type === 2) && (
        <div className='m-3'>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-primary btn-sm btn-primary-shadow'
              title={formatMessage({ id: 'Edit vat rate' })}
              onClick={() => {
                history.push(location.pathname + '/edit');
              }}
            >
              <i className='fa fa-edit cursor-pointer'></i>
              {formatMessage({ id: 'Edit vat rate' })}
            </button>
          </div>
        </div>
      )}

      <VatRatesDetails mode='view' />
    </div>
  );
};
const VatRatesDetailsWrapperEdit: FC = () => {
  // usePermissions('update-company');

  const history = useHistory();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const vatRateItem: IVatRate = useSelector<RootState>((state) => {
    return state.SYS_vat_rates.vat_rate;
  }, shallowEqual) as IVatRate;
  // disable edit if type is 3 or 4
  if (vatRateItem?.type === 3 || vatRateItem?.type === 4) {
    history.push(location.pathname.replace('/edit', ''));
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Edit vat rate' })}</PageTitle>
      <VatRatesDetails mode='edit' />
    </div>
  );
};

export { VatRatesDetailsWrapperCreate, VatRatesDetailsWrapperView, VatRatesDetailsWrapperEdit };
