/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { PrivateRoutes } from './PrivateRoutes';
import { PrivateRoutes as R } from '../../_metronic/constants/ROUTES';
import { Logout, AuthPage } from '../modules/auth';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { RootState } from '../../setup';
import { UserModel } from '../modules/auth/models/UserModel';

import 'react-toastify/dist/ReactToastify.css';

const Routes: FC = () => {
  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  return (
    <Switch>
      {!user ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='*/error' component={ErrorsPage} />
      {/* <Route path='/error' component={ErrorsPage} /> */}
      <Route path='/logout' component={Logout} />

      {!user ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <>
          <PrivateRoutes />
          {!user.active && <Redirect from='*' to={R.verification} />}
        </>
      )}
    </Switch>
  );
};

export { Routes };
