import React from 'react';
import { AsideDefault } from './components/aside/AsideDefault';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { RightSection } from './components/RightSection/RightSection';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { Toolbar } from './components/toolbar/Toolbar';
import { Footer } from './components/Footer';

const BasicLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault user='USER' />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-row flex-grow-1 mb-3'>
            <div className='flex-fill pt-3'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='content'>
                <Content>{children}</Content>
              </div>
            </div>
            {/* Right Section */}
            <div className='right-aside mw-auto'>
              <RightSection />
            </div>
            {/* End Right Section */}
          </div>
          <Footer />
        </div>
      </div>
    </PageDataProvider>
  );
};

export { BasicLayout };
