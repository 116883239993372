type InitialConfigPayload = {
  type: Number;
};

export function initialConfigPayload(values: any): InitialConfigPayload {
  const payload: InitialConfigPayload = {
    ...values,
  };
  return payload;
}
