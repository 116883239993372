import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../setup';
import AhadDialog from '../../../setup/shared/AhadDialog';
// import { PrivateRoutes } from '../../../_metronic/constants/ROUTES';

import { PageTitle } from '../../../_metronic/layout/core';
import { Portal } from '../../../_metronic/partials';
import { actions } from '../../store/ModalRedux';
import { ICompany } from '../../types/companies';
import { ModalName } from '../../types/types';
import { InviteUsersForm } from './InviteUsers/InviteUsers';
import { usePermissions } from '../../hooks/usePermissions';

const UsersAdd: FC = () => {
  // Open the user add modal
  useEffect(() => {
    dispatch(actions.setActiveModal('invite-user'));
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const modal: ModalName = useSelector<RootState>(
    (state) => state.modal.activeModal,
    shallowEqual
  ) as ModalName;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  return (
    <>
      <div></div>
      <div className='content-wrapper bg-white b-radius-25 '>
        {modal === 'invite-user' && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={() =>
                history.push(
                  location.pathname.replace(
                    '/company-management/users/invite-users',
                    '/company-management/users'
                  )
                )
              }
              specialContainerClass='dialog-content-container-add-user'
              specialTitleFormat={
                <>
                  <FormattedMessage id='Invite users to' />{' '}
                  <span className='text-darkishBlue'>
                    {companyDetails?.name || companyDetails?.slug}
                  </span>
                </>
              }
            >
              <InviteUsersForm />
            </AhadDialog>
          </Portal>
        )}
      </div>
    </>
  );
};

const UsersAddWrapper: FC = () => {
  usePermissions(['invite-user']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      {/* { title: 'Users', path: PrivateRoutes.Users, isActive: true } */}
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Users' })}</PageTitle>
      <UsersAdd />
    </div>
  );
};

export { UsersAddWrapper };
