import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import * as TableRedux from '../../store/TableRedux';
import { IRowCheckbox } from './classes/TableSelection';

export const TableCheckbox = ({ fieldData }: { fieldData: IRowCheckbox }) => {
  const dispatch = useDispatch();
  const table = useSelector<RootState>((state) => state.table.tables, shallowEqual) as any;
  const { id, tableName } = fieldData;

  return (
    table && (
      <input
        id={id?.toString()}
        className='form-check-input widget-13-check'
        type='checkbox'
        // -1 means select all
        checked={[id, -1].some((x) => table?.[tableName]?.selection.includes(x))}
        readOnly
        disabled={fieldData.disabled}
        onClick={() =>
          dispatch(
            TableRedux.actions.toggleSelection({ id: fieldData.id, tableName: fieldData.tableName })
          )
        }
      />
    )
  );
};
