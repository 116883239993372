import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as modal from '../../app/store/ModalRedux';
import { $t } from '../../_metronic/i18n/formatMessage';

type Props = {
  titleId?: string;
  specialTitleFormat?: any;
  withoutClose?: boolean;
  withoutCancel?: boolean;
  children?: ReactElement;
  closeCallBack?: Function;
  specialContainerClass?: string;
};

export const AhadDialog: FC<Props> = ({
  titleId,
  specialTitleFormat,
  withoutClose,
  children,
  withoutCancel,
  closeCallBack,
  specialContainerClass,
}) => {
  const dispatch = useDispatch();
  const { locale } = useIntl();

  const closeActiveModal = () => {
    dispatch(modal.actions.closeActiveModal());
    if (closeCallBack) closeCallBack();
  };

  return (
    <div className='dialog-content-wrapper'>
      <div className={'dialog-content-container'}>
        <div className=' w-100'>
          <div className='d-flex justify-content-between mx-13'>
            <div></div>
            {titleId && <div className=''>{titleId ? <h6>{$t(titleId)}</h6> : null}</div>}
            {specialTitleFormat && (
              <div className='' dir={locale === 'ar' ? 'rtl' : 'ltr'}>
                {specialTitleFormat}
              </div>
            )}
            {withoutClose ? (
              <i></i>
            ) : (
              <i className='fas fa-times cursor-pointer' onClick={closeActiveModal}></i>
            )}
          </div>
        </div>
        <div className={specialContainerClass || 'dialog-content'}>{children}</div>
      </div>
    </div>
  );
};

export default AhadDialog;
