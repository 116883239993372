import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ID } from '../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { getRolesList } from './RolesCrud';

export enum IRolesActionTypes {
  GET_ROLES = 'GET_ROLES',
  FULLFULL_ROLES = 'FULLFULL_ROLES',
}

const initialRolesState: IRolesState = {
  roles: undefined,
};

export interface IRolesState {
  roles?: {};
}

export const reducer = persistReducer(
  { storage, key: 'roles', whitelist: [''] },
  (state: IRolesState = initialRolesState, action: ActionWithPayload<IRolesState>) => {
    switch (action.type) {
      case IRolesActionTypes.FULLFULL_ROLES:
        return { ...state, roles: action.payload?.roles };
      default:
        return state;
    }
  }
);
type CompanyRolesSearch = { companyId: ID | any; params?: any };

export const actions = {
  fulfillRoles: ({ roles }: IRolesState) => ({
    type: IRolesActionTypes.FULLFULL_ROLES,
    payload: { roles },
  }),
  getRoles: ({ companyId, params }: CompanyRolesSearch) => ({
    type: IRolesActionTypes.GET_ROLES,
    payload: { companyId, params },
  }),
};

export function* getRoles(action: ActionWithPayload<CompanyRolesSearch>) {
  try {
    const { data } = yield getRolesList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fulfillRoles({ roles: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IRolesActionTypes.GET_ROLES, getRoles);
}
