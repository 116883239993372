/* eslint-disable jsx-a11y/alt-text */
import { FC } from 'react';
import { useIntl } from 'react-intl';

const arabic_disclamer_messages = [
  'الرجاء تحميل القالب أولاً، لمعرفة البيانات المطلوبة في الملف',
  'يرجى الملاحظة أن الخانات التالية مطلوبة و في حال عدم إدخالها ستتم الاشارة على الفاتورة بعلامة حمراء: اسم العميل، نوع العميل/المورد',
  'يرجى الملاحظة في حال عدم إدخال الدولة في بيانات الطرف الثالث، سيتم اعتماد دولة الممكلة العربية السعودية للطرف الثالث تلقائياً',
  'يرجى الملاحظة في حال عدم إدخال نوع الطرف الثالث في البيانات، سيتم احتساب الطرف الثالث كمورد و عميل في نفس الوقت تلقائياً',
  'يرجى الملاحظة أن الحساب من نوع “تجميعي”، يجب إدخاله يدوياً على النظام',
  'يرجى إدخال الرقم الضريبي، في حال أن الطرف الثالث مسجلاً لدى هيئة الزكاة والضريبة والجمارك',
];

const english_disclamer_messages = [
  'Please download the template first to know what data is needed',
  'Please note that the following fields are required, and if they are not full-filed, the invoice will be marked with a red mark: customer name, client/vendor type.',
  'If no country was added, the country of the 3rd party will be by default KSA',
  'The type of the 3rd party is by default “Both” (Client and supplier)',
  '“Aggregate” 3rd parties can’t be added through the excel. Needs to be added manually',
  'Please add the tax number of the 3rd party if he is “Tax registered”',
];

const ImportNewClientsDisclamer: FC = () => {
  const { locale } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='content-wrapper bg-white b-radius-25 mt-5'>
        <div className='d-flex p-5 m-5'>
          <div className='d-flex px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {locale === 'ar' && (
              <ul>
                {arabic_disclamer_messages.map((message, index) => {
                  return <li key={index}>{message}.</li>;
                })}
              </ul>
            )}
            {locale === 'en' && (
              <ul>
                {english_disclamer_messages.map((message, index) => {
                  return <li key={index}>{message}.</li>;
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export { ImportNewClientsDisclamer };
