import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  htmlFor?: string;
  msg: string;
  isRequired?: boolean;
};

export const Label: FC<Props> = ({ htmlFor, msg, isRequired }) => {
  return (
    <label
      className={clsx('form-label fs-6 my-1 fw-bolder text-dark', { required: isRequired })}
      htmlFor={htmlFor}
    >
      <FormattedMessage id={msg || '-.-'} />
    </label>
  );
};
