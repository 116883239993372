import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyPurchases = endpoints.companyPurchases;
export const companySinglePurchase = endpoints.companySinglePurchase;

export function getAllPurchases({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyPurchases(companyId), { params });
}

export function getPurchase({ companyId, purchaseId }: { companyId: ID; purchaseId: ID }) {
  return axiosInstance.get(companySinglePurchase(companyId, purchaseId));
}
