import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as CurrentCompany from '../../../../app/store/CurrentCompanyRedux';

import { formatDateTime } from '../../../../app/shared/tables/classes/DateViewer';
import { INotification } from '../../../../app/types/notifications';
import { axiosInstance } from '../../../../network/apis';
import { removeQuotes } from '../../../../utils/strings';
import { toAbsoluteUrl } from '../../../helpers';
import { RootState } from '../../../../setup';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';

type Props = {
  notifications: [];
  isLoading: boolean;
};

const NotificationsMenu: FC<Props> = ({ notifications, isLoading }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-rounded fw-bold fs-6 w-400px'
      data-kt-menu='true'
    >
      <div
        id='notifications-menu'
        style={{ zIndex: 104, maxHeight: '400px', height: 'fit-content', overflowY: 'scroll' }}
      >
        {notifications?.length > 0 &&
          notifications?.map((notification: INotification, index, arr) => (
            <div
              key={index}
              onClick={async () => {
                if (notification?.company_id && !user?.isSuperAdmin) {
                  await axiosInstance.post(`${notification?.company_id}/switch`).then((res) => {
                    dispatch(CurrentCompany.actions.setCurrentCompany({ ...res.data }));
                  });
                }
                if (history.location.pathname === notification?.url) {
                  window.location.reload();
                } else {
                  history.push(notification?.url);
                }
              }}
            >
              <div className='cursor-pointer py-6 d-flex flex-row mx-5 menu-item'>
                <div className='cursor-pointer symbol symbol-circle avatar-border d-flex flex-row symbol-30px symbol-md-40px show menu-dropdown'>
                  <img
                    className='circular-light-border'
                    src={notification?.user?.image || toAbsoluteUrl('/media/avatars/blank.png')}
                    alt=''
                  />
                </div>
                <div className='mx-3 px-3'>
                  <p className='fw-bolder'>{notification?.user?.name}</p>
                  <p>
                    {removeQuotes(notification.content)}{' '}
                    <FormattedMessage
                      id='at_date_time'
                      values={{ date: formatDateTime(notification.created_at) }}
                    />
                  </p>
                </div>
              </div>
              {index < arr.length - 1 && <div className='separator spearator-pale-grey'></div>}
            </div>
          ))}
      </div>
    </div>
  );
};

export { NotificationsMenu };
