/* eslint-disable jsx-a11y/alt-text */
import { FC } from 'react';
import { useIntl } from 'react-intl';

const arabic_disclamer_messages = [
  'الرجاء تحميل القالب أولاً، لمعرفة البيانات المطلوبة في الملف',
  'يرجى الملاحظة أن الخانات التالية مطلوبة و في حال عدم إدخالها ستتم الاشارة على الفاتورة بالعلامة الحمراء: الرقم المرجعي أو الرقم الضريبي للعميل، رقم الفاتورة، تاريخ الفاتورة، المعدل الضريبي',
  'في حال لم يتم تحديد نوع الفاتورة، سيتم احتسابها على انها فاتورة أصلية',
  'عند عدم ادخال كمية للمنتج، سيتم احتساب الكمية على انها كمية واحد فقط',
  'عند عدم إدخال سعر منتج مدخل على نظام عهد، سيتم احتساب سعر المنتج المدخل على النظام. وفي حال عدم إدخال سعر لمنتج غير مدخل على النظام، ستتم الاشارة على الفاتورة بالعلامة الحمراء',
  'الرجاء استخدام عامود (رقم الفاتورة المرتبطة) لربط فاتورة الاسترجاع بالفاتورة الأصلية',
  'يرجى الملاحظة في حال عدم ادخال رقم فاتورة مرتبطة، ف سيتم احتساب الفاتورة على أنها فاتورةمرتجع غير مرتبطة بفاتورة أصلية',
  'يرجى الملاحظة أن الصيغة المستخدمة للتاريخ يوم/شهر/سنة',
];

const english_disclamer_messages = [
  'Please download the template first to know what data is needed',
  'Please note that the following fields are required, and if they are not full-filed, the invoice will be marked with a red mark: customer reference number or tax number, invoice number, invoice date, VAT rate',
  'If the invoice type hasn’t been selected, it will be considered as original invoice',
  'if the product quantity field are not full-field, it will be counted as one quantity',
  'if the price field of a product that exist on the system not fullfield, it will be calculated with the price that exist on the system. If the price field of a product that doesn’t exist on the system are not fullfield, the invoice will be marked with a red mark',
  'Please use the (Linked Invoice Number) column to link the return invoice to the original invoice',
  'Please note that if the linked original invoice column is not fullfield, the return invoice will be considered as a return invoice that doesn’t have linked original invoice',
  'The date format that must be used is dd-mm-yyyy',
];
// const english_disclamer_messages = [
//   'Please download the template first, to know the required data in the file',
//   'Please note that the following fields are required and if not entered, the invoice will be marked with a red mark: Reference number or customer tax number, invoice number, invoice date, tax rate',
//   'If the type of invoice is not specified, it will be calculated as an original invoice',
//   'If no quantity is entered for the product, the quantity will be calculated as one quantity only',
//   'If no price is entered for a product entered on the system, the price of the product entered on the system will be calculated. If no price is entered for a product not entered on the system, the invoice will be marked with a red mark',
//   'Please use the (Invoice Number) column to link the return invoice to the original invoice',
//   'Please note that if no invoice number is entered, the invoice will be calculated as a return invoice not linked to an original invoice',
//   'Please note that the format used for the date is dd-mm-yyyy',
// ];

const ImportNewPurchasesInvoicesDisclamer: FC = () => {
  const { locale } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='content-wrapper bg-white b-radius-25 mt-5'>
        <div className='d-flex p-5 m-5'>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {locale === 'ar' && (
              <ul>
                {arabic_disclamer_messages.map((message, index) => {
                  return <li key={index}>{message}.</li>;
                })}
              </ul>
            )}
            {locale === 'en' && (
              <ul>
                {english_disclamer_messages.map((message, index) => {
                  return <li key={index}>{message}.</li>;
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export { ImportNewPurchasesInvoicesDisclamer };
