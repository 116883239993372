import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useState, useMemo, useEffect } from 'react';
import { serialize } from 'object-to-formdata';
// Hide the unnecessary
// import { FormattedMessage } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import * as TemplatesRedux from '../templates/store/TemplatesRedux';

import { axiosInstance } from '../../../network/apis';
import { RootState } from '../../../setup/redux/Store';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { $t } from '../../../_metronic/i18n/formatMessage';
import {
  isReviewerNeeded,
  companySignatureNeeded,
} from '../../../_metronic/helpers/functions/signatures';
import { usePermissions } from '../../hooks/usePermissions';
import { Label } from '../../shared/Label';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import { ICompany } from '../../types/companies';
import { useConfigureTemplateSchema } from './useConfigureTemplateSchema';
import { endpoints } from '../../../_metronic/constants/paths';
import { ErrorMessage } from '../../shared/ErrorMessage';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { initialBasicsConfigPayload } from './helpers/templateConfigHelpers';
import { createOptions } from '../../../utils/createOptions';
import { DocumentDirectionTypes } from '../../../_metronic/constants/general';

type Props = {
  setCurrentState: Function;
};

export const ConfigureTemplateBasics: FC<Props> = ({ setCurrentState }) => {
  usePermissions('create-template', 'Create Template');
  const { basicsInitialValues, basicsValidationSchema } = useConfigureTemplateSchema();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  const [loading, setLoading] = useState(false);

  const { setShowExitPrompt } = useExitPrompt(false);

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const { isLoading: signersLoading, data: signersList } = useQuery(
    'Signers',
    () =>
      axiosInstance(`/${companyDetails.id}/users`, {
        params: {
          permissions: 'sign-document',
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  const { isLoading: creatorsLoading, data: creatorsList } = useQuery(
    'Creators',
    () =>
      axiosInstance(`/${companyDetails.id}/users`, {
        params: {
          permissions: 'create-document',
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  const { isLoading: reviewersLoading, data: reviewersList } = useQuery(
    'Reviewers',
    () =>
      axiosInstance(`/${companyDetails.id}/users`, {
        params: {
          permissions: 'review-document',
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  const signerOptions = useMemo(() => createOptions(signersList), [signersList]);

  const creatorsOptions = useMemo(() => createOptions(creatorsList), [creatorsList]);

  const reviewersOptions = useMemo(() => createOptions(reviewersList), [reviewersList]);

  const createTemplate = async (values: any, resetForm: any) => {
    setLoading(true);
    const payload: { [key: string]: any } = initialBasicsConfigPayload(values);
    const formData = serialize(payload, { indices: true });

    try {
      let response: AxiosResponse;
      if (params.templateId) {
        formData.append('_method', 'PUT');
        response = await axiosInstance.post(
          endpoints.updateCompanyTemplates(companyDetails.id, underConstructionTemplate?.id),
          formData
        );
      } else {
        response = await axiosInstance.post(
          endpoints.companyTemplates(companyDetails.id),
          formData
        );
      }
      const { id } = response.data;

      const newUnderConstruction: TemplatesRedux.UnderConstructionTemplate = {
        ...payload,
        id: values?.id || id,
        values: {
          ...underConstructionTemplate.values,
          ...payload,
        },
        ...response.data,
      } as TemplatesRedux.UnderConstructionTemplate;

      dispatch(
        TemplatesRedux.actions.fulfillUnderConstructionTemplate({
          underConstructionTemplate: newUnderConstruction,
        })
      );
      resetForm();
      setCurrentState('select_template');
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: basicsInitialValues,
    validationSchema: basicsValidationSchema,
    onSubmit: (values, { resetForm }) => createTemplate(values, resetForm),
  });

  const { setFieldValue } = formik;

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  useEffect(() => {
    if (underConstructionTemplate?.signers?.length) {
      formik.setFieldValue('signers', createOptions(underConstructionTemplate?.signers));
    }
    if (underConstructionTemplate?.creators?.length) {
      formik.setFieldValue('creators', createOptions(underConstructionTemplate?.creators));
    }
    if (underConstructionTemplate?.reviewers?.length) {
      formik.setFieldValue('reviewers', createOptions(underConstructionTemplate?.reviewers));
    }
  }, [underConstructionTemplate]);

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    if (Number(params?.templateId) === underConstructionTemplate?.id) {
      setFieldValue('recipient_type', underConstructionTemplate?.recipient_type);
      setFieldValue('type', underConstructionTemplate?.type);
    }
  }, [underConstructionTemplate]);

  return (
    <FormikProvider value={formik}>
      <Form id='template_select_basics' noValidate autoComplete='none'>
        {Object.keys(formik.touched).length > 0 && (
          <Prompt message={$t('Are you sure you want to ignore the changes?')} />
        )}
        <div
          className='p-5 dialog-defaults b-radius-20'
          style={{ maxHeight: 'fit-content', width: '50vw' }}
        >
          {companySignatureNeeded(underConstructionTemplate?.recipient_type) && (
            <div className='row'>
              <div className='col-md-12'>
                <Label msg='Signers' htmlFor='signers' isRequired />
                <Select
                  onChange={(value) => setFieldValue('signers', value)}
                  onBlur={() => formik.setFieldTouched('signers', true)}
                  options={signerOptions}
                  value={formik.values.signers}
                  placeholder={$t('Signers')}
                  isLoading={signersLoading}
                  isMulti
                  className='react-select smaller'
                  id='signers'
                />
                <ErrorMessage name='signers' formik={formik} />
              </div>
            </div>
          )}
          {underConstructionTemplate?.users
            .map((user: any) => user.id)
            .some((user: any) =>
              formik?.values?.signers.map((user: any) => user.value).includes(user)
            ) &&
            underConstructionTemplate.type === DocumentDirectionTypes.Internal && (
              <div className='fv-plugins-message-container mt-2 mx-3'>
                <span role='alert' className='text-danger'>
                  {$t("UserCant'tBeSecondParty")}
                </span>
              </div>
            )}

          {/* For selecting document creators */}
          <div className='row mt-3'>
            <div className='col-md-12'>
              <Label msg='Creators' htmlFor='creators' isRequired />
              <Select
                onChange={(value) => setFieldValue('creators', value)}
                onBlur={() => formik.setFieldTouched('creators', true)}
                options={creatorsOptions}
                value={formik.values.creators}
                isLoading={creatorsLoading}
                placeholder={$t('Creators')}
                isMulti
                className='react-select smaller'
                id='creators'
              />
              <ErrorMessage name='creators' formik={formik} />
            </div>
          </div>

          {/* For selecting reviewers */}
          {isReviewerNeeded(underConstructionTemplate?.recipient_type) && (
            <div className='row mt-3 mb-1'>
              <div className='col-md-12'>
                <Label msg='Reviewers' htmlFor='reviewers' isRequired />
                <Select
                  onChange={(value) => setFieldValue('reviewers', value)}
                  onBlur={() => formik.setFieldTouched('reviewers', true)}
                  isLoading={reviewersLoading}
                  options={reviewersOptions}
                  isMulti
                  value={formik.values.reviewers}
                  placeholder={$t('Reviewers')}
                  className='react-select smaller'
                  id='reviewers'
                />
                <ErrorMessage name='reviewers' formik={formik} />
              </div>
            </div>
          )}

          {/* Display product table in document */}
          {/* Hide the unnecessary */}
          {/* <div className='col-md-12'>
            <label className='mt-9 mx-9 form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input my-5'
                type='checkbox'
                onClick={() =>
                  setFieldValue('is_confidential', formik.values.is_confidential === 0 ? 1 : 0)
                }
                checked={formik?.values?.is_confidential === 1}
              />
              <span className='form-check-label fw-bold text-gray-800'>
                <FormattedMessage id='is_confidential' />
              </span>
            </label>
          </div> */}

          <SaveCancelBtnsGroup
            saveBtnLabel='save'
            formik={formik}
            loading={loading}
            cancelOnClick={() => {
              history.push(`/c/${companyDetails.slug}/templates`);
            }}
          />
        </div>
      </Form>
    </FormikProvider>
  );
};
