import { toAbsoluteUrl } from '../helpers';
import { ILanguage } from '../helpers/typescript';

export const languages: ILanguage[] = [
  {
    locale: 'ar',
    name: 'العربية',
    name_en: 'Arabic',
    flag: toAbsoluteUrl('/media/flags/saudi-arabia.svg'),
    dir: 'rtl',
  },
  {
    locale: 'en',
    name: 'English',
    name_en: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    dir: 'ltr',
  },
];

export const defaultLanguage: ILanguage = {
  locale: 'ar',
  name: 'العربية',
  name_en: 'Arabic',
  flag: toAbsoluteUrl('/media/flags/saudi-arabia.svg'),
  dir: 'rtl',
};

export const defaultLocale = 'en';
export const defaultDir = 'rtl';
