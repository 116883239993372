import {
  SelectOption,
  CustomVariablesSelectOption,
  VatRateTypeSelectOption,
  InvoicesTypeSelectOption,
  ImportInvoicesTypeSelectOption,
  IncomeCodeTypeSelectOption,
  ExpenditureCodeTypeSelectOption,
  TransactionTypeSelectOption,
  ProductTypeSelectOption,
  TaxAbilityTypeSelectOption,
  CategoryTypeSelectOption,
  ThirdPartyTypeSelectOption,
  AccountTypeSelectOption,
  ReportsPeriodTypeSelectOption,
  MonthlyReportsPeriodTypeSelectOption,
  QuarterlyReportsPeriodTypeSelectOption,
  ReportsStatusTypeSelectOption,
  CodeRulesCategoryTypeSelectOption,
  CodeRulesThirdPartyTypeSelectOption,
  CodeRulesOperatorTypeSelectOption,
  CodeRulesColumnTypeSelectOption,
  PlanTypeSelectOption,
} from '../../app/types/SelectOptions';
import { Badge } from '../helpers/typescript/csssClasses';
import { ID } from './paths';

export const perpage = [10, 20, 40, 60];

export const defaultPagination = 10;

export const allowedImagesTypes = [
  'image/jpg',
  'image/jpeg',
  'jpeg',
  'png',
  'image/png',
  'image/svg+xml',
];

export const allowedImagesAndPdf = [...allowedImagesTypes, 'application/pdf'];

export const clientsTypesMap = new Map([
  [1, 'Entity'],
  [2, 'Individual'],
]);

export const RecipientTypeMap: Map<ID, SelectOption> = new Map([
  [1, { value: 1, label: 'Need signtures from 2 side company side first' }],
  [2, { value: 2, label: 'Need signture from 2 side- Seconed party side first' }],
  [3, { value: 3, label: 'Need signture from one side- Company only' }],
  [4, { value: 4, label: 'Need signture from one side- Seconed party only' }],
  [5, { value: 5, label: 'No signtures required' }],
]);

export enum DocuemntTypes {
  'Agreement Type A' = 1,
  'Agreement Type B' = 2,
  'Formal' = 3,
  'Nonformal' = 4,
}

export const DocumentDirection: Map<ID, SelectOption> = new Map([
  [1, { label: 'Internal', value: 1 }],
  [2, { label: 'External', value: 2 }],
]);

export enum DocumentDirectionTypes {
  'Internal' = 1,
  'External' = 2,
}

export const DocumentStatuses: Map<ID, { label: string; badge: Badge; id: ID }> = new Map([
  [1, { label: 'STATUS_DRAFT', badge: 'badge-dustyBlue', id: 1 }],
  [2, { label: 'STATUS_CLOSED', badge: 'badge-success', id: 2 }],
  [3, { label: 'STATUS_UNDER_REVIEW', badge: 'badge-primary', id: 3 }],
  [4, { label: 'STATUS_ADJUSTMENT_REQUESTED', badge: 'badge-danger', id: 4 }],
  [5, { label: 'STATUS_APPROVE', badge: 'badge-success', id: 5 }],
  [7, { label: 'STATUS_EXPIRED', badge: 'badge-metallicBlue', id: 7 }],
  [8, { label: 'STATUS_WAITING_FOR_COMPANY', badge: 'badge-info', id: 8 }],
  [9, { label: 'STATUS_WAITING_FOR_SECOND_PARTY', badge: 'badge-clearBlue', id: 9 }],
]);

export enum DOCS_STATUS {
  'DRAFT' = 1,
  'CLOSED' = 2,
  'UNDER_REVIEW' = 3,
  'ADJUSTMENT_REQUESTED' = 4,
  'APPROVE' = 5,
  // NOTE: NO STATUS WITH NUMBER 6
  'EXPIRED' = 7,
  'WAITING_FOR_COMPANY' = 8,
  'WAITING_FOR_SECOND_PARTY' = 9,
}

export enum RECIPIENT_TYPES {
  'Need signtures from 2 side company side first' = 1,
  'Need signture from 2 side- Seconed party side first' = 2,
  'Need signture from one side- Company only' = 3,
  'Need signture from one side- Seconed party only' = 4,
  'No signtures required' = 5,
}

// custom variables
export const CustomVariablesTypeMap: Map<ID, CustomVariablesSelectOption> = new Map([
  [1, { value: 1, label: 'Text', requestValue: 'text' }],
  [2, { value: 2, label: 'Number', requestValue: 'number' }],
  [3, { value: 3, label: 'Date', requestValue: 'date' }],
]);

export const CustomVariablesTypesMap = new Map([
  ['text', 'Text'],
  ['number', 'Number'],
  ['date', 'Date'],
]);

//Vat Rate
export const VatRateTypeMap: Map<ID, VatRateTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Income' }],
  [2, { value: 2, label: 'Expenditure' }],
  // [3, { value: 3, label: 'Income return' }],
  // [4, { value: 4, label: 'Expenditure return' }],
]);

export const FilterVatRateTypeMap: Map<ID, VatRateTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Income' }],
  [2, { value: 2, label: 'Expenditure' }],
  [3, { value: 3, label: 'Income return' }],
  [4, { value: 4, label: 'Expenditure return' }],
]);

export const VatRateTypesMap = new Map([
  [1, 'Income'],
  [2, 'Expenditure'],
  [3, 'Income return'],
  [4, 'Expenditure return'],
]);

//Invoices
export const InvoicesTypeMap: Map<ID, InvoicesTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Sale' }],
  [2, { value: 2, label: 'Purchase' }],
  [3, { value: 3, label: 'Sale Credit Note' }],
  [4, { value: 4, label: 'Purchase Debit Note' }],
]);

export const InvoicesTypesMap = new Map([
  [1, 'Sale'],
  [2, 'Purchase'],
  [3, 'Sale Credit Note'],
  [4, 'Purchase Debit Note'],
]);

export const ImportInvoicesTypeMap: Map<ID, ImportInvoicesTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Sale' }],
  [2, { value: 2, label: 'Purchase' }],
]);

//Sales Invoices
export const SalesInvoicesTypeMap: Map<ID, InvoicesTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Sale' }],
  [3, { value: 3, label: 'Sale Credit Note' }],
]);
export const SalesInvoicesState: Map<ID, InvoicesTypeSelectOption> = new Map([
  [0, { value: 2, label: 'the Draft' }],
  [1, { value: 1, label: 'Issued invoice' }],
]);

export const SalesInvoicesErrorTypes: Map<ID, InvoicesTypeSelectOption> = new Map([
  [0, { value: '0', label: 'Invoices without notes' }],
  [1, { value: '1', label: 'Critical notes' }],
  [2, { value: '2', label: 'Notifications' }],
]);

export const SalesInvoicesTypesMap = new Map([
  [1, 'Sale'],
  [3, 'Sale Credit Note'],
]);

// Purchases Invoices
export const PurchasesInvoicesTypeMap: Map<ID, InvoicesTypeSelectOption> = new Map([
  [2, { value: 2, label: 'Purchase' }],
  [4, { value: 4, label: 'Purchase Debit Note' }],
]);

export const PurchasesInvoicesTypesMap = new Map([
  [2, 'Purchase'],
  [4, 'Purchase Debit Note'],
]);

export const PurchasesInvoicesErrorTypes: Map<ID, InvoicesTypeSelectOption> = new Map([
  [0, { value: '0', label: 'Invoices without notes' }],
  [1, { value: '1', label: 'Critical notes' }],
  [2, { value: '2', label: 'Notifications' }],
]);

//Income Code Types
export const IncomeCodeTypeMap: Map<ID, IncomeCodeTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Sales subject to the basic ratio 15%' }],
  [1.1, { value: 1.1, label: 'Sales subject to the basic ratio 5%' }],
  [1.2, { value: 1.2, label: 'Sales tax for government agencies' }],
  [2, { value: 2, label: 'Sales that state bears its tax' }],
  [3, { value: 3, label: 'Zero-rated sales' }],
  [4, { value: 4, label: 'Exports' }],
  [5, { value: 5, label: 'Exempt sales' }],
  [0, { value: 0, label: 'Out of the range' }],
]);

export const IncomeCodeTypesMap = new Map([
  [1, 'Sales subject to the basic ratio 15%'],
  [1.1, 'Sales subject to the basic ratio 15%'],
  [1.2, 'Sales tax for government agencies'],
  [2, 'Sales that state bears its tax'],
  [3, 'Zero-rated sales'],
  [4, 'Exports'],
  [5, 'Exempt sales'],
  [0, 'Out of the range'],
]);

//Expenditure Code Types
export const ExpenditureCodeTypeMap: Map<ID, ExpenditureCodeTypeSelectOption> = new Map([
  [7, { value: 7, label: 'Purchases subject to the basic ratio 15%' }],
  [7.1, { value: 7.1, label: 'Purchases subject to the basic ratio 5%' }],
  [8, { value: 8, label: 'VAT paid by customs 15%' }],
  [8.1, { value: 8.1, label: 'VAT paid by customs 5%' }],
  [9, { value: 9, label: 'Reverse charge purchases 15%' }],
  [9.1, { value: 9.1, label: 'Reverse charge purchases 5%' }],
  [10, { value: 10, label: 'Zero-rated purchases' }],
  [11, { value: 11, label: 'Exempt purchases' }],
  [12.1, { value: 12.1, label: 'Rent' }],
  [12.2, { value: 12.2, label: 'Royalties' }],
  [12.3, { value: 12.3, label: 'Management fees' }],
  [12.4, { value: 12.4, label: 'International Calling Services - Unrelated Non-Resident' }],
  [12.5, { value: 12.5, label: 'International calling services - associated non-resident' }],
  [12.6, { value: 12.6, label: 'Technical or advisory services - unrelated non-resident' }],
  [12.7, { value: 12.7, label: 'Technical or advisory services - associated non-resident' }],
  [12.8, { value: 12.8, label: 'Airline tickets, air or sea freight' }],
  [12.9, { value: 12.9, label: 'Land transportation within the Kingdom' }],
  [13.1, { value: 13.1, label: 'Dividend profits' }],
  [13.2, { value: 13.2, label: 'loan returns' }],
  [13.3, { value: 13.3, label: 'Insurance and reinsurance' }],
  [13.4, { value: 13.4, label: 'Any other payments' }],
  [0, { value: 0, label: 'Out of the range' }],
]);

export const ExpenditureCodeTypesMap = new Map([
  [7, 'Purchases subject to the basic ratio 15%'],
  [7.1, 'Purchases subject to the basic ratio 5%'],
  [8, 'VAT paid by customs 15%'],
  [8.1, 'VAT paid by customs 5%'],
  [9, 'Reverse charge purchases 15%'],
  [9.1, 'Reverse charge purchases 5%'],
  [10, 'Zero-rated purchases'],
  [11, 'Exempt purchases'],
  [12.1, 'Rent'],
  [12.2, 'Royalties'],
  [12.3, 'Management fees'],
  [12.4, 'International Calling Services - Unrelated Non-Resident'],
  [12.5, 'International calling services - associated non-resident'],
  [12.6, 'Technical or advisory services - unrelated non-resident'],
  [12.7, 'Technical or advisory services - associated non-resident'],
  [12.8, 'Airline tickets, air or sea freight'],
  [12.9, 'Land transportation within the Kingdom'],
  [13.1, 'Dividend profits'],
  [13.2, 'loan returns'],
  [13.3, 'Insurance and reinsurance'],
  [13.4, 'Any other payments'],
  [0, 'Out of the range'],
]);

//Transaction Type
export const TransactionTypeMap: Map<ID, TransactionTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Domestic' }],
  [2, { value: 2, label: 'International' }],
  [3, { value: 3, label: 'GCC' }],
]);

export const TransactionTypesMap = new Map([
  [1, 'Domestic'],
  [2, 'International'],
  [3, 'GCC'],
]);

//Product Type
export const ProductTypeMap: Map<ID, ProductTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Sale' }],
  [2, { value: 2, label: 'Purchase' }],
]);

export const ProductTypesMap = new Map([
  [1, 'Sale'],
  [2, 'Purchase'],
]);

export const PlanTypeMap: Map<ID, PlanTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Paid Plan' }],
  [2, { value: 2, label: 'Customized Plan' }],
  [3, { value: 3, label: 'Fallback Plan' }],
]);

export const PlanTypesMap = new Map([
  [1, 'Paid Plan'],
  [2, 'Customized Plan'],
  [3, 'Fallback Plan'],
]);

//Tax Ability Type
export const TaxAbilityTypeMap: Map<ID, TaxAbilityTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Taxable' }],
  [2, { value: 2, label: 'Zero' }],
  [3, { value: 3, label: 'Exempt' }],
]);

export const TaxAbilityTypesMap = new Map([
  [1, 'Taxable'],
  [2, 'Zero'],
  [3, 'Exempt'],
]);

//Tax Ability Type
export const CategoryTypeMap: Map<ID, CategoryTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Basic' }],
  [2, { value: 2, label: 'Optional' }],
]);

export const CateoryTypesMap = new Map([
  [1, 'Basic'],
  [2, 'Optional'],
]);

//Third party types
export const ThirdPartyTypeMap: Map<ID, ThirdPartyTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Client' }],
  [2, { value: 2, label: 'Vendor' }],
  [3, { value: 3, label: 'Both' }],
]);

export const ThirdPartyTypesMap = new Map([
  [1, 'Client'],
  [2, 'Vendor'],
  [3, 'Both'],
]);
//AccountTypeMap types
export const AccountTypeMap: Map<ID, AccountTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Account' }],
  [2, { value: 2, label: 'Aggregate' }],
]);

export const AccountTypesMap = new Map([
  [1, 'Account'],
  [2, 'Aggregate'],
]);

//Reports Period
export const ReportsPeriodTypeMap: Map<ID, ReportsPeriodTypeSelectOption> = new Map([
  // [1, { value: 1, label: 'All' }],
  [2, { value: 2, label: 'Monthly' }],
  [3, { value: 3, label: 'Quarterly' }],
]);

export const ReportsPeriodTypesMap = new Map([
  // [1, 'All'],
  [2, 'Monthly'],
  [3, 'Quarterly'],
]);

//Monthly Reports Period
export const MonthlyReportsPeriodTypeMap: Map<ID, MonthlyReportsPeriodTypeSelectOption> = new Map([
  [1, { value: 1, label: 'January' }],
  [2, { value: 2, label: 'February' }],
  [3, { value: 3, label: 'March' }],
  [4, { value: 4, label: 'April' }],
  [5, { value: 5, label: 'May' }],
  [6, { value: 6, label: 'June' }],
  [7, { value: 7, label: 'July' }],
  [8, { value: 8, label: 'August' }],
  [9, { value: 9, label: 'September' }],
  [10, { value: 10, label: 'October' }],
  [11, { value: 11, label: 'November' }],
  [12, { value: 12, label: 'December' }],
]);
export const MonthlyReportsPeriodTypesMap = new Map([
  [1, 'January'],
  [2, 'February'],
  [3, 'March'],
  [4, 'April'],
  [5, 'May'],
  [6, 'June'],
  [7, 'July'],
  [8, 'August'],
  [9, 'September'],
  [10, 'October'],
  [11, 'November'],
  [12, 'December'],
]);
//Quarterly Reports Period
export const QuarterlyReportsPeriodTypeMap: Map<ID, QuarterlyReportsPeriodTypeSelectOption> =
  new Map([
    [1, { value: 1, label: 'Q1' }],
    [4, { value: 4, label: 'Q2' }],
    [7, { value: 7, label: 'Q3' }],
    [10, { value: 10, label: 'Q4' }],
  ]);
export const QuarterlyReportsPeriodTypesMap = new Map([
  [1, 'Q1'],
  [4, 'Q2'],
  [7, 'Q3'],
  [10, 'Q4'],
]);

//Reports Status types
export const ReportsStatusTypeMap: Map<ID, ReportsStatusTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Report Draft' }],
  [2, { value: 2, label: 'Report Approved' }],
  [3, { value: 3, label: 'Report Canceled' }],
]);

export const ReportsStatusTypesMap = new Map([
  [1, 'Report Draft'],
  [2, 'Report Approved'],
  [3, 'Report Canceled'],
]);

// Transactions Status types
export const TransactionDetailsErrorStatusTypeMap: Map<ID, ReportsStatusTypeSelectOption> = new Map(
  [
    [1, { value: 1, label: 'Has errors' }],
    [2, { value: 2, label: 'Has no errors' }],
  ]
);

export const TransactionDetailsErrorStatusTypesMap = new Map([
  [1, 'Has errors'],
  [2, 'Has no errors'],
]);

//Code Rules
export const CodeRulesCategoryTypeMap: Map<ID, CodeRulesCategoryTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Code expiration date' }],
  [2, { value: 2, label: 'Transaction amount' }],
  [3, { value: 3, label: 'Third party nationality' }],
  [4, { value: 4, label: 'Third party type' }],
  [5, { value: 5, label: 'Transaction date' }],
  [6, { value: 6, label: 'the Tax Registration' }],
]);

export const CodeRulesCategoryTypesMap = new Map([
  [1, 'Code expiration date'],
  [2, 'Transaction amount'],
  [3, 'Third party nationality'],
  [4, 'Third party type'],
  [5, 'Transaction date'],
  [6, 'the Tax Registration'],
]);

//Code Rules
export const CodeRulesThirdPartyTypeMap: Map<ID, CodeRulesThirdPartyTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Account-Individual' }],
  [2, { value: 2, label: 'Account-Entity' }],
  [3, { value: 3, label: 'Aggregate' }],
]);

export const CodeRulesThirdPartyTypesMap = new Map([
  [1, 'Account-Individual'],
  [2, 'Account-Entity'],
  [3, 'Aggregate'],
]);

//Code Rules Oprator
export const CodeRulesOperatorTypeMap: Map<ID, CodeRulesOperatorTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Not before than' }],
  // [2, { value: 2, label: 'Not later than' }],
]);

export const CodeRulesOperatorTypesMap = new Map([
  [1, 'Not before than'],
  // [2, 'Not later than'],
]);

//Code Rules Column
export const CodeRulesColumnTypeMap: Map<ID, CodeRulesColumnTypeSelectOption> = new Map([
  [1, { value: 1, label: 'Report Date' }],
]);

export const CodeRulesColumnTypesMap = new Map([[1, 'Report Date']]);
