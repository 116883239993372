import { FC, useState, useEffect, useLayoutEffect } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { serialize } from 'object-to-formdata';
import Select from 'react-select';
import clsx from 'clsx';

import * as TemplatesRedux from '../templates/store/TemplatesRedux';

import Editor from '../../shared/JoditEditorMemo';
import { usePermissions } from '../../hooks/usePermissions';
import { RootState } from '../../../setup';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { ConfigureTemplateClientName } from './ConfigureTemplateClientName';
import { ConfigureTemplateUserName } from './ConfigureTemplateUserName';

import { JODIT_CONFIGURATION } from '../../../_metronic/constants/JODIT_CONFIGURATION';
import { insertIntoArray } from '../../../_metronic/helpers/functions/insertIntoArray';
import { companySlugTo, copyText } from '../../../_metronic/helpers/functions';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { AddingProductsTable } from '../../shared/AddingProductsTable';
import { axiosInstance } from '../../../network/apis';
import { endpoints } from '../../../_metronic/constants/paths';
import { CompanyModel } from '../companies/CompanyModel';
import { toast } from 'react-toastify';

/**
 * @description name of the localstorage item to store blocks
 * @type {string}
 *
 */
const temporaryTemplate: string = 'temporaryTemplate';

/**
 * @description TemplateEditor
 * after cloning template we use JoditEditor as <Editor /> to edit content
 * ? why we used localstorage in this component
 * * becaue when updating state or store by adding block and move to the first block
 * * it loses all added data and considers it the only block there and all other are removed
 * * this behaviour is not logical but we couldn't catch the root cause
 */

const TemplateEditor: FC = () => {
  usePermissions('create-template', 'Create Template');

  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const { formatMessage } = useIntl();

  const [template, setTemplate] = useState<TemplatesRedux.UnderConstructionTemplate | any>(null);
  const [lastEdited, setLastEdited] = useState<any>(null);
  const [addProdcutsByTemplateCreator, setAddProdcutsByTemplateCreator] = useState<any>(false);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>();
  const [showClientModal, setShowClientModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  // const [processRequest, setProcessRequest] = useState<boolean>(false);

  /* --------------------- UnderConstructionTemplate --------------------------- */

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  /* -------------------------------- End Store ------------------------------- */

  useLayoutEffect(() => {
    if (!template && underConstructionTemplate?.sections) {
      setTemplate(underConstructionTemplate);
      localStorage.setItem(temporaryTemplate, JSON.stringify(underConstructionTemplate));
    }
    const productSection = underConstructionTemplate?.sections?.find(
      (section) => section.type === 'product'
    );

    const selectedProductsIds = productSection?.variables[0].value;
    const productsOptions = productSection?.variables[0].options;
    if (productSection && Array.isArray(selectedProductsIds)) {
      setAddProdcutsByTemplateCreator(true);
      const idsToNums = selectedProductsIds.map((id) => Number(id)).filter((num) => !!num);
      setSelectedProducts(
        productsOptions
          ?.filter((product: any) => selectedProductsIds.map(() => idsToNums.includes(+product.id)))
          .map((product: any) => ({
            label: product.name,
            value: product.value,
            price: product.price,
          }))
      );
    }
  }, [underConstructionTemplate]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(temporaryTemplate);
    };
  }, []);

  /* ---------------------------- End editor config --------------------------- */

  const updateEditorContent = (newContent: string, index: number) => {
    let localStorageItem = JSON.parse(localStorage.getItem(temporaryTemplate) as string);
    const updatedSections = localStorageItem?.sections?.map((section: any, i: number) => {
      return i === index ? { ...section, value: newContent } : { ...section };
    });
    updateSections(localStorageItem, updatedSections);
  };

  const toggleProductsVisibilityInDocument = (index: number) => {
    let localStorageItem = JSON.parse(localStorage.getItem(temporaryTemplate) as string);
    const updatedSections = localStorageItem?.sections?.map((section: any, i: number) => {
      return i === index && section.type === 'product'
        ? { ...section, hidden: !section.hidden }
        : { ...section };
    });
    updateSections(localStorageItem, updatedSections);
  };

  const updateSections = (localStorageItem: any, updatedSections: any) => {
    localStorageItem.sections = updatedSections;
    localStorage.setItem(temporaryTemplate, JSON.stringify(localStorageItem));
    setTemplate(localStorageItem);
    dispatch(
      TemplatesRedux.actions.addSectionsToUnderConstructionTemplate({
        sections: updatedSections,
      })
    );
  };

  const addNewSection = (index: number) => {
    const allSections = template.sections;
    const newItem = { type: 'text', variables: [], value: `<div style="direction: rtl;"></div>` };
    const updatedSections = insertIntoArray(newItem, allSections, index + 1);

    dispatch(
      TemplatesRedux.actions.addSectionsToUnderConstructionTemplate({
        sections: updatedSections,
      })
    );

    setTemplate({ ...template, sections: updatedSections });
    localStorage.setItem(
      temporaryTemplate,
      JSON.stringify({ ...template, sections: updatedSections })
    );
  };

  const removeSection = (index: number) => {
    const allSections = template.sections;

    const updatedSections = allSections.filter((_section: any, idx: number) => idx !== index);

    dispatch(
      TemplatesRedux.actions.addSectionsToUnderConstructionTemplate({
        sections: updatedSections,
      })
    );

    setTemplate({ ...template, sections: updatedSections });
    localStorage.setItem(
      temporaryTemplate,
      JSON.stringify({ ...template, sections: updatedSections })
    );
  };

  const moveSection = (index: number, direction: 'up' | 'down') => {
    let updateData = JSON.parse(localStorage.getItem(temporaryTemplate) as string);
    let sections = [...updateData.sections];

    function moveItem(from: number, to: number) {
      var f = sections.splice(from, 1)[0];
      sections.splice(to, 0, f);
    }

    moveItem(index, direction === 'up' ? index - 1 : index + 1);
    localStorage.setItem(temporaryTemplate, JSON.stringify({ ...updateData, sections }));
    setTemplate({ ...updateData, sections });
    dispatch(
      TemplatesRedux.actions.addSectionsToUnderConstructionTemplate({
        sections,
      })
    );
  };

  const hasClientValues = () => {
    if (underConstructionTemplate.variable_clients.length === 0) {
      // template has no Client Name values please choose some or delete variable it self from template.
      // toast.error(
      //   formatMessage({
      //     id: 'template has no Client Name values please choose some or delete variable it self from template.',
      //   })
      // );
      return false;
    } else {
      // 'template has Client Name values'
      return true;
    }
  };
  const hasClientName = () => {
    if (
      template.sections
        .map((section: any) => section.value)
        .join('')
        .includes('اسم العميل') ||
      template.sections
        .map((section: any) => section.value)
        .join('')
        .includes('Client Name')
    ) {
      // template has Client Name
      return true;
    } else {
      // template has no Client name
      return false;
      // formData.append('clients', '');
    }
  };

  const hasUserValues = () => {
    if (underConstructionTemplate.variable_users.length === 0) {
      // template has no employee name values please choose some or delete variable it self from template.

      // toast.error(
      //   formatMessage({
      //     id: 'template has no employee name values please choose some or delete variable it self from template.',
      //   })
      // );
      return false;
    } else {
      // template has employee name values
      return true;
    }
  };
  const hasUserName = () => {
    if (
      template.sections
        .map((section: any) => section.value)
        .join('')
        .includes('اسم الموظف') ||
      template.sections
        .map((section: any) => section.value)
        .join('')
        .includes('Employee Name')
    ) {
      // template has employee name
      return true;
    } else {
      // template has no employee name
      return false;
    }
  };

  const requestHasAutomaticVariableError = (response: any) => {
    if (response.status !== 200) {
      if (response.response.status === 422) {
        return true;
      } else {
        return false;
      }
    }
  };

  const saveTemplate = async (type: string) => {
    setLoading(true);
    const payload: { [key: string]: any } = { ...underConstructionTemplate };
    delete payload.editor;
    delete payload.title;
    delete payload.type;
    delete payload.recipient_type;
    delete payload.creators;
    delete payload.users;
    delete payload.values;
    delete payload.clients;
    delete payload.signers;
    delete payload.reviewers;
    delete payload.variable_users;
    delete payload.variable_clients;

    payload.sections.forEach((section: any, index: number) => {
      // ! This Handles hidden boolean equivalent 0->false 1->true for formData correction
      section.hidden = !!section.hidden ? 1 : 0;
      if (section['type'] === 'product') {
        if (!payload.sections[index].value) {
          payload.sections[index].value = 'MISSING_TABLE_VALUE';
        }
        if (addProdcutsByTemplateCreator && selectedProducts?.length) {
          payload.variables = [];
          const products = selectedProducts.map((product: any) => product.value);
          payload.variables[index] = { [payload.sections[index].variables[0].id]: products };
        } else {
          payload.variables = [];
          payload.variables[index] = { [payload.sections[index].variables[0].id]: null };
        }
      }
    });

    if (hasUserName()) {
      if (!hasUserValues()) {
        // block request
        setLoading(false);
        return toast.error(
          formatMessage({
            id: 'Template has no employee name values please choose some or delete variable it self from template.',
          })
        );
      }
    }
    if (hasClientName()) {
      if (!hasClientValues()) {
        // block request
        setLoading(false);
        return toast.error(
          formatMessage({
            id: 'Template has no client Name values please choose some or delete variable it self from template.',
          })
        );
      }
    }
    const formData = serialize(payload, { indices: true });

    if (!hasClientName()) {
      formData.append('variable_clients', '');
    }
    if (!hasUserName()) {
      formData.append('variable_users', '');
    }
    formData.append('_method', 'PUT');

    const response = await axiosInstance
      .post(
        endpoints.updateCompanyTemplates(companyDetails.id, underConstructionTemplate?.id),
        formData
      )
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    if (requestHasAutomaticVariableError(response)) {
      // block request
      setLoading(false);
      return toast.error(
        formatMessage({
          id: `${response.response.data.errors.sections.map((error: any) => error)}`,
        })
      );
    }
    const newUnderConstruction: TemplatesRedux.UnderConstructionTemplate = {
      ...underConstructionTemplate,
      ...payload,
      id: response?.data?.id || params?.templateId,
      values: response.data,
      ...response.data,
    } as TemplatesRedux.UnderConstructionTemplate;

    dispatch(
      TemplatesRedux.actions.fulfillUnderConstructionTemplate({
        underConstructionTemplate: newUnderConstruction,
      })
    );

    setLoading(false);
    toast.success(formatMessage({ id: 'Changes have been saved' }));
    // return response?.data?.preview_link;
    // switch between buttons
    if (type === 'saveAndPreview') {
      const link = response?.data?.preview_link;
      window.open(link, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
    } else if (type === 'saveAndExit') {
      setLastEdited(null);
      history.push(companySlugTo(companyDetails.slug, 'templates'));
    }
  };

  const saveAndPreview = async () => {
    await saveTemplate('saveAndPreview');
    // if (hasUserName()) {
    //   if (!hasUserValues()) {
    //     // block request
    //     return toast.error(
    //       formatMessage({
    //         id: 'Template has no employee name values please choose some or delete variable it self from template.',
    //       })
    //     );
    //   }
    // }
    // if (hasClientName()) {
    //   if (!hasClientValues()) {
    //     // block request
    //     return toast.error(
    //       formatMessage({
    //         id: 'Template has no client Name values please choose some or delete variable it self from template.',
    //       })
    //     );
    //   }
    // }
    // const link = await saveTemplate('saveAndPreview');
    // window.open(link, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
  };

  const saveAndExit = async () => {
    await saveTemplate('saveAndExit');
    // if (hasUserName()) {
    //   if (!hasUserValues()) {
    //     // block request
    //     return toast.error(
    //       formatMessage({
    //         id: 'Template has no employee name values please choose some or delete variable it self from template.',
    //       })
    //     );
    //   }
    // }
    // if (hasClientName()) {
    //   if (!hasClientValues()) {
    //     // block request
    //     return toast.error(
    //       formatMessage({
    //         id: 'Template has no client Name values please choose some or delete variable it self from template.',
    //       })
    //     );
    //   }
    // }

    // setLastEdited(null);
    // await saveTemplate('saveAndExit');
    // history.push(companySlugTo(companyDetails.slug, 'templates'));
  };

  const shouldAsk = () => {
    return lastEdited !== null;
  };

  return (
    <div className='pb-5'>
      {shouldAsk() && <Prompt message={$t('Are you sure you want to ignore the changes?')} />}
      {showClientModal && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={$t('Add Client')}
            closeCallBack={() => {
              setShowClientModal(false);
            }}
          >
            <ConfigureTemplateClientName setShowClientModal={setShowClientModal} />
          </AhadDialog>
        </Portal>
      )}
      {showUserModal && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={$t('Add Users')}
            closeCallBack={() => {
              setShowUserModal(false);
            }}
          >
            <ConfigureTemplateUserName setShowUserModal={setShowUserModal} />
          </AhadDialog>
        </Portal>
      )}
      <div className='d-flex align-items-stretch gap-2 flex-lg-grow-1 flex-row-reverse'>
        <button
          className='btn btn-outlined-primary'
          disabled={loading}
          onClick={() => history.push(companySlugTo(companyDetails?.slug, 'templates'))}
        >
          <i className='fas fa-save' />
          <FormattedMessage id='Dismiss' />
        </button>
        <button
          className='btn btn-outlined-primary'
          disabled={loading}
          onClick={() => saveAndExit()}
        >
          <i className='fas fa-save' />
          <FormattedMessage id='Save and Exit' />
        </button>
        <button
          className='btn btn-outlined-primary'
          disabled={loading}
          onClick={() => saveTemplate('saveOnly')}
        >
          <i className='fas fa-save' />
          <FormattedMessage id='Save' />
        </button>
        <button
          className='btn btn-outlined-primary'
          disabled={loading}
          onClick={() => saveAndPreview()}
        >
          <i className='fas fa-save' />
          <FormattedMessage id='Save and preview' />
        </button>
      </div>
      <div className='mb-10 mt-10'>
        {/* -------------------------------- variables ------------------------------- */}
        {template?.variables?.map((variable: string) => (
          <span
            className={clsx('btn btn-sm btn-flex btn-light fw-bolder m-1', {
              'btn-light-primary': template.sections
                .map((section: any) => section.value)
                .join('')
                .includes(`{${variable}}`),
            })}
            onClick={() => {
              let str = '';
              copyText(`{${variable}}`);
              // TODO: Open Modal with variable names and then send the names to the request
              if (lastEdited > -1) {
                const section = template?.sections?.[lastEdited]?.value;
                // insert is not a function error so I used this method instead
                // str = section.insert(0, `<span>{${variable}}</span>`);
                str = `<span>{${variable}}</span>\n${section}`;

                try {
                  updateEditorContent(str, lastEdited);
                } catch (error) {
                  console.error(error);
                }
              }
              if (variable === 'Client Name' || variable === 'اسم العميل') {
                setShowClientModal(true);
              } else if (variable === 'Employee Name' || variable === 'اسم الموظف') {
                setShowUserModal(true);
              }
            }}
          >
            {variable}
          </span>
        ))}
      </div>
      {/* -------------------------------- sections -------------------------------- */}
      {template?.sections?.map(
        (
          { value, ...section }: TemplatesRedux.IContentSection,
          index: number,
          list: Array<any>
        ) => {
          if (section.type === 'product') {
            return (
              <div className='px-20 py-5 d-flex flex-row align-items-end section-container'>
                <div className='container'>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    {/* Display product table in document */}
                    <input
                      className='form-check-input'
                      type='checkbox'
                      onClick={() => toggleProductsVisibilityInDocument(index)}
                      checked={!section.hidden}
                    />
                    <span className='form-check-label fw-bold text-gray-400'>
                      <FormattedMessage id='Display products table in document' />
                    </span>
                  </label>
                  {!section.hidden && (
                    <div>
                      <div className='d-flex align-items-center bg-light-success rounded p-5 my-5'>
                        <div className='flex-grow-1 me-2'>
                          <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                            <FormattedMessage id='You can add specific products or leave this action to document creator' />
                          </a>
                        </div>
                      </div>
                      <label className='form-check form-switch form-check-custom form-check-solid mt-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          onClick={() =>
                            setAddProdcutsByTemplateCreator(!addProdcutsByTemplateCreator)
                          }
                          checked={addProdcutsByTemplateCreator}
                        />
                        <span className='form-check-label fw-bold text-gray-400'>
                          <FormattedMessage id='Add prodcuts' />
                        </span>
                      </label>
                      {addProdcutsByTemplateCreator && (
                        <div className='col-sm-12 col-md-6 my-4'>
                          <Select
                            styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                            placeholder={$t('Products')}
                            className='react-select smaller'
                            id='product-select'
                            isMulti
                            value={selectedProducts}
                            onChange={(value) => {
                              setSelectedProducts(value);
                            }}
                            options={section?.variables?.[0]?.options?.map((option: any) => ({
                              label: option.name,
                              value: option.value,
                              price: option.price,
                            }))}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {addProdcutsByTemplateCreator && (
                    <div className='col-md-6 col-sm-12'>
                      <AddingProductsTable products={selectedProducts} />
                    </div>
                  )}
                </div>
                <div className='section-container-buttons mx-2 d-flex flex-column gap-5'>
                  <i
                    className={clsx('cursor-pointer far fa-arrow-alt-up fs-1 d-hidden ', {
                      'd-block': index > 0,
                      'd-none': index === 0,
                    })}
                    onClick={() => {
                      moveSection(index, 'up');
                    }}
                  />
                  <i
                    className={clsx('cursor-pointer far fa-arrow-alt-down fs-1 d-hidden', {
                      'd-block': index < list.length - 1,
                      'd-none': index === list.length - 1,
                    })}
                    onClick={() => {
                      moveSection(index, 'down');
                    }}
                  />
                  <i
                    className='cursor-pointer far fa-plus fs-1'
                    onClick={() => addNewSection(index)}
                  />
                  {list.length > 1 && (
                    <i
                      className='cursor-pointer far fa-trash fs-1'
                      onClick={() => removeSection(index)}
                    />
                  )}
                </div>
              </div>
            );
          }

          return (
            <div
              key={JSON.stringify(index)}
              className='px-20 py-5 d-flex flex-row align-items-end section-container'
            >
              <div style={{ minWidth: '97%' }}>
                <Editor
                  value={value}
                  config={JODIT_CONFIGURATION}
                  onChange={(text: any) => {
                    setLastEdited(index);
                    updateEditorContent(text, index);
                  }}
                  onBlur={(text) => setLastEdited(index)}
                />
              </div>
              <div className='section-container-buttons mx-2 d-flex flex-column gap-5'>
                <i
                  className={clsx('cursor-pointer far fa-arrow-alt-up fs-1 d-hidden ', {
                    'd-block': index > 0,
                    'd-none': index === 0,
                  })}
                  onClick={() => {
                    moveSection(index, 'up');
                  }}
                />
                <i
                  className={clsx('cursor-pointer far fa-arrow-alt-down fs-1 d-hidden', {
                    'd-block': index < list.length - 1,
                    'd-none': index === list.length - 1,
                  })}
                  onClick={() => {
                    moveSection(index, 'down');
                  }}
                />
                <i
                  className='cursor-pointer far fa-plus fs-1'
                  onClick={() => addNewSection(index)}
                />
                {list.length > 1 && (
                  <i
                    className='cursor-pointer far fa-trash fs-1'
                    onClick={() => removeSection(index)}
                  />
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export { TemplateEditor };
