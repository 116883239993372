import { FC } from 'react';

type Props = any;

export const ErrorItemDanger: FC<Props> = ({ ...props }) => {
  return (
    <a className='btn btn-icon btn-bg-light btn-color-danger btn-sm me-1' {...props}>
      <i className='fas fa-info-circle mx-2'></i>
    </a>
  );
};
