import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

import * as DocumentsRedux from './store/DocumentsRedux';
import { axiosInstance } from '../../../network/apis';
import { RootState } from '../../../setup/redux/Store';
import { DocumentComments } from '../comments/DocumentComments';
import { CurrentCompanyUser } from '../../types/CompanyTypes';
import { useQuerySearch } from '../../hooks/useQuerySearch/useQuerySearch';

export function SingleDocumentToTakeAction() {
  const query: any = useQuerySearch();
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  let slug = query.get('slug');

  const currentCompanyUser: CurrentCompanyUser = useSelector<RootState>(
    (state) => state.current.current,
    shallowEqual
  ) as CurrentCompanyUser;

  /* --------------------------- Viewer only  stuff --------------------------- */

  const requestDocument = async () => {
    const docURL =
      !slug && !currentCompanyUser.company?.id
        ? `/auth/documents/${params.documentId}`
        : `/documents/${params.documentId}`;

    return await axiosInstance(docURL, {
      params: { version: query.get('version') },
    })
      .then((res) => res.data)
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error(
            formatMessage({
              id: 'Either you are not authorized to view this page or this document has a newer version',
            })
          );
        }
        if (error.response.status !== 200) history.push('/');
      });
  };

  const requestDocumentComments = async () =>
    await axiosInstance(`/documents/${params.documentId}/comments`).then((res) => res.data.data);

  const {

    isRefetching: gettingDocument,
    data: document,
    refetch: refetchDoc,
  } = useQuery('documents-to-takeAction', requestDocument);

  const { data: documentComments } = useQuery('document-comments', requestDocumentComments);

  useEffect(() => {
    if (documentComments) {
      dispatch(DocumentsRedux.actions.fulfillDocumentsComments({ comments: documentComments }));
    }
  }, [documentComments]);

  const sendComment = async (comment: any) => {
    await axiosInstance.post(`/documents/${params.documentId}/comments`, comment);
  };


  useEffect(() => {
    if (!gettingDocument) {
      refetchDoc();
    }
  }, [params]);

  return (
    <div>
      {/* -------------------------- SIGNER - Second Party ------------------------- */}
      {document?.preview_link && (
        <DocumentComments
          document={document}
          sendComment={sendComment}
          comments={documentComments}
          viewOnly={!document.is_second_party}
          refetchDoc={refetchDoc}

          gettingDocument={gettingDocument}
        />
      )}
    </div>
  );
}
