import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

// import { Search } from '../../../types/types';
import { getAllInvoicesReportTransactionsDetails } from './InvoicesReportTransactionsDetailsCRUD';

export enum IInvoicesReportTransactionsDetailsActionTypes {
  GET_INVOICES_REPORT_TRANSACTIONS_DETAILS = 'GET_INVOICES_REPORT_TRANSACTIONS_DETAILS',
  FULL_FILL_INVOICES_REPORT_TRANSACTIONS_DETAILS = 'FULL_FILL_INVOICES_REPORT_TRANSACTIONS_DETAILS',
}

const initialInvoicesReportTransactionsDetails: IInvoicesReportTransactionsDetailsState = {
  invoices_report_transaction_details: undefined,
};

export interface IInvoicesReportTransactionsDetailsState {
  invoices_report_transaction_details?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_invoices_report_transaction_details', whitelist: [''] },
  (
    state: IInvoicesReportTransactionsDetailsState = initialInvoicesReportTransactionsDetails,
    action: ActionWithPayload<IInvoicesReportTransactionsDetailsState>
  ) => {
    switch (action.type) {
      case IInvoicesReportTransactionsDetailsActionTypes.FULL_FILL_INVOICES_REPORT_TRANSACTIONS_DETAILS:
        return {
          ...state,
          invoices_report_transaction_details: action.payload?.invoices_report_transaction_details,
        };
      default:
        return state;
    }
  }
);

export const actions = {
  getInvoicesReportTransactionsDetails: ({ companyId, reportId, params }: any) => ({
    type: IInvoicesReportTransactionsDetailsActionTypes.GET_INVOICES_REPORT_TRANSACTIONS_DETAILS,
    payload: { companyId, reportId, params },
  }),
  fullFillInvoicesReportTransactionsDetails: ({
    invoices_report_transaction_details,
  }: IInvoicesReportTransactionsDetailsState) => ({
    type: IInvoicesReportTransactionsDetailsActionTypes.FULL_FILL_INVOICES_REPORT_TRANSACTIONS_DETAILS,
    payload: { invoices_report_transaction_details },
  }),
};

export function* getInvoicesReportTransactionsDetails(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getAllInvoicesReportTransactionsDetails({
      companyId: action.payload?.companyId,
      reportId: action.payload?.reportId,
      params: action.payload?.params,
    });
    yield put(
      actions.fullFillInvoicesReportTransactionsDetails({
        invoices_report_transaction_details: data,
      })
    );
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(
    IInvoicesReportTransactionsDetailsActionTypes.GET_INVOICES_REPORT_TRANSACTIONS_DETAILS,
    getInvoicesReportTransactionsDetails
  );
}
