/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { KTSVG } from '../../../helpers';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup/redux/Store';
import { formatDateTime } from '../../../../app/shared/tables/classes/DateViewer';

export const CommentsDrawer: FC = () => {
  const comments: any = useSelector<RootState>(
    (state) => state.USER_documents.comments,
    shallowEqual
  ) as any;

  return (
    <>
      {/* begin::Demos drawer */}
      <div
        id='kt_engage_demos'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='explore'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_engage_demos_toggle'
        data-kt-drawer-close='#kt_engage_demos_close'
      >
        {/* begin::Card  */}
        <div className='card shadow-none w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_explore_header'>
            <h5 className='card-title fw-bold text-gray-600'>
              <FormattedMessage id='Comments' />
            </h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_engage_demos_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body' id='kt_explore_body'>
            {/* begin::Content */}
            <div
              id='kt_explore_scroll'
              className='scroll-y me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_engage_demos_body'
              data-kt-scroll-dependencies='#kt_engage_demos_header'
              data-kt-scrregular
              licenseoll-offset='5px'
            >
              {comments?.map((comment: any) => (
                <div className='rounded border border-dashed border-gray-300 py-4 px-6 mb-5'>
                  <div className='d-flex flex-stack'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-1'>
                        <div className='fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1'>
                          {comment?.user?.name}
                        </div>
                      </div>
                      <div className='fs-6 text-gray-800'>{comment.comment.text}</div>
                      <div className='fs-7 text-muted'>{comment.content.text}</div>
                    </div>

                    <div className='text-center'>
                      <div className='text-nowrap fs-7'>{formatDateTime(comment.created_at)}</div>
                      <div className='text-nowrap fs-7'>{comment.comment.emoji}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
