export const secondPartySignaturesNeeded = (type: number) => {
  // 'Need signtures from 2 side company side first'
  // 'Need signture from 2 side- Seconed party side first'
  // 'Need signture from one side- Seconed party only'
  return [1, 2, 4].includes(type);
};

export const isReviewerNeeded = (type: number) => {
  // 'Need signtures from 2 side company side first'
  // 'Need signture from 2 side- Seconed party side first'
  // 'Need signture from one side- Seconed party only'
  // 'Need signture from one side- Company only';
  return [1, 2, 4, 3].includes(type);
};

export const companySignatureOnlyNeeded = (type: number) => {
  // 'Need signture from one side- Company only';
  return [3].includes(type);
};

export const companySideSignature = (type: number) => {
  // 'Need signture from one side- Company only';
  // 'No signtures required'
  return [3, 5].includes(type);
};

export const companySignatureLast = (type: number) => {
  // 'Need signture from 2 side- Seconed party side first'
  return [2].includes(type);
};

export const companySignatureNeeded = (type: number) => {
  return [1, 2, 3].includes(type);
};

export const clientSideSignatureOnlyOrFirst = (type: number) => {
  // 'Need signture from 2 side- Seconed party side first'
  // 'Need signture from one side- Seconed party only'
  return [2, 4].includes(type);
};
