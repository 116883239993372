/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl'; //FormattedMessage,
import { Label } from '../../../../shared/Label';
import { Input } from '../../../../shared/Input';
import { $t } from '../../../../../_metronic/i18n/formatMessage';
import Select from 'react-select';
import { ErrorMessage } from '../../../../shared/ErrorMessage';

import {
  MonthlyReportsPeriodTypeMap,
  // QuarterlyReportsPeriodTypeMap,
} from '../../../../../_metronic/constants/general';
import { toSelectOption } from '../../../../../_metronic/helpers/functions/Select';

const Step1: FC<any> = ({ formik }) => {
  const { formatMessage, locale } = useIntl();

  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  const monthlyPeriodOptions = useMemo(() => {
    return Array.from(MonthlyReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  // const quarterlyPeriodOptions = useMemo(() => {
  //   return Array.from(QuarterlyReportsPeriodTypeMap.values())
  //     .flat()
  //     .map((option) => toSelectOption(option, locale));
  // }, [locale]);

  function generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = max - 5;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }
  const yearsOptions = useMemo(() => {
    return Array.from(generateArrayOfYears())
      .flat()
      .map((option) => {
        return { value: option, label: option };
      });
  }, [locale]);

  return (
    <div className='w-100 m-5'>
      <div className='fv-row'>
        <div className='row'>
          {/*  ------------------------ Report Name -----------------------  */}
          {locales.map((locale) => (
            <div className='form-label mb-3 col-md-6'>
              <Label
                msg={formatMessage({
                  id: 'Report name in ' + locale.toUpperCase(),
                })}
                htmlFor={'Report name in ' + locale.toUpperCase()}
              />

              <Input
                // className='form-control form-control-lg form-control-solid'
                formik={formik}
                placeholder={formatMessage({
                  id: 'Report name in ' + locale.toUpperCase(),
                })}
                autoComplete='off'
                name={'report_name_' + locale}
                type='text'
                dir={locale === 'ar' ? 'rtl' : 'ltr'}
                id={'Report name in ' + locale.toUpperCase()}
              />
              {/* <div className='text-danger mt-2'>
                <ErrorMessage name={'report_name_' + locale} />
              </div> */}
            </div>
          ))}
          {/*  --------------------- end Report Name-----------------------  */}
          <div className='col-md-12 fv-row'>
            <div className='row fv-row'>
              {/*  ---------------------  Report Period select-----------------------  */}
              {formik.values.report_period[0]?.value && (
                <>
                  {
                    <div className='col-md-6'>
                      <Label msg='Period' htmlFor='monthly_period' isRequired />
                      <Select
                        onChange={(value) => formik.setFieldValue('monthly_period', [value])}
                        // onBlur={() => formik.setFieldTouched('monthly_period', true)}
                        options={monthlyPeriodOptions?.filter(
                          (option) => formik.values.monthly_period[0]?.value !== option.value
                        )}
                        value={formik.values.monthly_period}
                        placeholder={$t('Period')}
                        className='react-select smaller'
                        id='monthly_period'
                      />
                      <ErrorMessage name='monthly_period' formik={formik} />
                    </div>
                  }
                  {/* {formik.values.report_period[0]?.value === 3 && (
                    <div className='col-md-6'>
                      <Label msg='Period' htmlFor='monthly_period' isRequired />
                      <Select
                        onChange={(value) => formik.setFieldValue('monthly_period', [value])}
                        // onBlur={() => formik.setFieldTouched('monthly_period', true)}
                        options={quarterlyPeriodOptions?.filter(
                          (option) => formik.values.monthly_period[0]?.value !== option.value
                        )}
                        value={formik.values.monthly_period}
                        placeholder={$t('Period')}
                        className='react-select smaller'
                        id='monthly_period'
                      />
                      <ErrorMessage name='monthly_period' formik={formik} />
                    </div>
                  )} */}
                  <div className='col-md-6'>
                    <Label msg='Year' htmlFor='year_period' isRequired />
                    <Select
                      onChange={(value) => formik.setFieldValue('year_period', [value])}
                      // onBlur={() => formik.setFieldTouched('year_period', true)}
                      options={yearsOptions?.filter(
                        (option) => formik.values.year_period[0]?.value !== option.value
                      )}
                      value={formik.values.year_period}
                      placeholder={$t('Year')}
                      className='react-select smaller'
                      id='year_period'
                    />
                    <ErrorMessage name='year_period' formik={formik} />
                  </div>
                  {/*  ------------------------ end Year -----------------------  */}
                </>
              )}
              {/*  --------------------- end Report Period select-----------------------  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
