import { axiosInstance } from '../../../../../network/apis';
import { endpoints, ID } from '../../../../../_metronic/constants/paths';

export const companyUsers = endpoints.companyUsers;
export const companySingleUser = endpoints.companySingleUser;
export const companyDocumentReviewers = endpoints.companyDocumentReviewers;

export function getUserGroupsList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyUsers(companyId), { params });
}

export function getSingleUserGroupRequest({ companyId, userId }: { companyId: ID; userId: ID }) {
  return axiosInstance.get(companySingleUser(companyId, userId));
}
