import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyRoles = endpoints.companyRoles;
export const editCompanyRoles = endpoints.editCompanyRoles;
export const permissions = endpoints.permissions;

// Server should return AuthModel
export function getRolesList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyRoles(companyId), { params });
}

export function getPermissions(companyId: any) {
  return axiosInstance.get(permissions(companyId));
}

export function addPermissions(companyId: number, payload: {}) {
  return axiosInstance.post(companyRoles(companyId), payload);
}

export function editPermissions(companyId: ID, roleId: ID, payload: {}) {
  return axiosInstance.post(editCompanyRoles(companyId, roleId), payload);
}
