import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import {
  getInvoicesReport,
  getAllInvoicesReports,
  getAllInvoicesReportTransactions,
} from './InvoicesReportsCRUD';

export enum IInvoicesReportsActionTypes {
  GET_INVOICES_REPORTS = 'GET_INVOICES_REPORTS',
  FULL_FILL_INVOICES_REPORTS = 'FULL_FILL_INVOICES_REPORTS',
  GET_CURRENT_INVOICES_REPORT = 'GET_CURRENT_INVOICES_REPORT',
  FULL_FILL_CURRENT_INVOICES_REPORT = 'FULL_FILL_CURRENT_INVOICES_REPORT',
  GET_INVOICES_REPORT_TRANSACTIONS = 'GET_INVOICES_REPORT_TRANSACTIONS',
  FULL_FILL_INVOICES_REPORT_TRANSACTIONS = 'FULL_FILL_INVOICES_REPORT_TRANSACTIONS',
}

const initialInvoicesReports: IInvoicesReportsState = {
  invoicesReports: undefined,
  currentInvoicesReport: undefined,
  invoicesReportTransactions: undefined,
};

export interface IInvoicesReportsState {
  invoicesReports?: any;
  currentInvoicesReport?: any;
  invoicesReportTransactions?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_invoices_reports', whitelist: [''] },
  (
    state: IInvoicesReportsState = initialInvoicesReports,
    action: ActionWithPayload<IInvoicesReportsState>
  ) => {
    switch (action.type) {
      case IInvoicesReportsActionTypes.FULL_FILL_INVOICES_REPORTS:
        return { ...state, invoicesReports: action.payload?.invoicesReports };
      case IInvoicesReportsActionTypes.FULL_FILL_CURRENT_INVOICES_REPORT:
        return { ...state, currentInvoicesReport: action.payload?.currentInvoicesReport };
      case IInvoicesReportsActionTypes.FULL_FILL_INVOICES_REPORT_TRANSACTIONS:
        return { ...state, invoicesReportTransactions: action.payload?.invoicesReportTransactions };
      default:
        return state;
    }
  }
);

export const actions = {
  getInvoicesReports: ({ companyId, params }: Search) => ({
    type: IInvoicesReportsActionTypes.GET_INVOICES_REPORTS,
    payload: { companyId, params },
  }),
  fullFillInvoicesReports: ({ invoicesReports }: IInvoicesReportsState) => ({
    type: IInvoicesReportsActionTypes.FULL_FILL_INVOICES_REPORTS,
    payload: { invoicesReports },
  }),
  getCurrentInvoicesReport: ({ companyId, reportId }: any) => ({
    type: IInvoicesReportsActionTypes.GET_CURRENT_INVOICES_REPORT,
    payload: { companyId, reportId },
  }),
  fullFillCurrentInvoicesReport: ({ currentInvoicesReport }: IInvoicesReportsState) => ({
    type: IInvoicesReportsActionTypes.FULL_FILL_CURRENT_INVOICES_REPORT,
    payload: { currentInvoicesReport },
  }),
  getInvoicesReportTransactions: ({ companyId, params }: Search) => ({
    type: IInvoicesReportsActionTypes.GET_INVOICES_REPORT_TRANSACTIONS,
    payload: { companyId, params },
  }),
  fullFillInvoicesReportTransactions: ({ invoicesReportTransactions }: IInvoicesReportsState) => ({
    type: IInvoicesReportsActionTypes.FULL_FILL_INVOICES_REPORT_TRANSACTIONS,
    payload: { invoicesReportTransactions },
  }),
};

export function* getInvoicesReports(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllInvoicesReports({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillInvoicesReports({ invoicesReports: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentInvoicesReport(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getInvoicesReport({
      companyId: action.payload?.companyId,
      reportId: action.payload?.reportId,
    });
    yield put(actions.fullFillCurrentInvoicesReport({ currentInvoicesReport: data }));
  } catch (error) {
    //
  }
}

export function* getInvoicesReportTransactions(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllInvoicesReportTransactions({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillInvoicesReportTransactions({ invoicesReportTransactions: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IInvoicesReportsActionTypes.GET_INVOICES_REPORTS, getInvoicesReports);
  yield takeLatest(
    IInvoicesReportsActionTypes.GET_INVOICES_REPORT_TRANSACTIONS,
    getInvoicesReportTransactions
  );
  yield takeLatest(
    IInvoicesReportsActionTypes.GET_CURRENT_INVOICES_REPORT,
    getCurrentInvoicesReport
  );
}
