import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { GeneralNumbersCard } from './GeneralNumbersCard';
import { generalNumber } from './statistic';

const generalNumbers: generalNumber[] = [
  {
    title: 'Clients',
    faIcon: 'fas fa-address-book fs-2',
    value: 0,
    valueType: 'number',
    color: 'dark',
    svgIcon: '/media/icons/duotune/communication/com005.svg',
    // iconColor,
    // valueType: 'currency',
  },
  {
    title: 'Users',
    faIcon: 'fas fa-user fs-2',
    value: 0,
    valueType: 'number',
    color: 'warning',
    svgIcon: '/media/icons/duotune/communication/com006.svg',
  },
  // { title: 'Documents', faIcon: 'far fa-file', value: 0, valueType: 'number' },
  // { title: 'Products', faIcon: 'far fa-cubes', value: 0, valueType: 'number' },
  {
    title: 'Total_revenue',
    faIcon: 'fas fa-chart-bar fs-2',
    value: 0,
    valueType: 'number',
    color: 'info',
    svgIcon: '/media/icons/duotune/graphs/gra007.svg',
  },
  // { title: 'Templates', faIcon: 'far fa-file-plus', value: 0, valueType: 'number' },
  // { title: 'Contracts', faIcon: 'far fa-file-signature', value: 23, valueType: 'number' },
];

export const GeneralNumbersGrid: FC<any> = ({ generalNumbersData }) => {
  generalNumbers.map((generalNumberItem) => {
    return (generalNumberItem.value =
      generalNumbersData[`${generalNumberItem.title.toLowerCase()}`]);
  });

  return (
    <>
      <div className='' style={{ backgroundColor: 'inherit' }}>
        <p className='fs-1 pt-5 fs-1 nav-link disabled'>
          <i className='fas fa-coins mx-3' />
          <FormattedMessage id='General Numbers' />
        </p>

        <div className='row g-5 g-xl-8 mt-2 general-numbers-bg'>
          {generalNumbers.map((data, index) => (
            <div className='col-xl-4' key={index}>
              <GeneralNumbersCard
                data={data}
                key={index}
                className='card-xl-stretch mb-5 mb-xl-8'
                description='General numbers statistics'
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
