export function capitalize(str: string): string {
  if (!(typeof str === 'string')) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeQuotes(str: string): string {
  let clearedString = str;
  if (str[0] === "'") {
    clearedString = clearedString.slice(1);
  }
  if (str[str.length - 1] === "'") {
    clearedString = clearedString.slice(0, str.length - 1);
  }
  return clearedString;
}
