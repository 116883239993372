/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { UserModel } from '../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../setup/redux/Store';
import { capitalize } from '../../../utils/strings';

const ProfileHeader: React.FC = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  // getusersData  from redux
  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  return (
    <div className=' bg-white b-radius-20 card my-20 my-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img
                src={toAbsoluteUrl(user?.image || toAbsoluteUrl('/media/avatars/blank.png'))}
                alt='AHAD User'
              />
              <div
                className={`position-absolute translate-middle bottom-0 start-100 mb-6 ${
                  user.active ? 'bg-success' : user.is_pending ? 'bg-warning' : 'bg-danger'
                } rounded-circle border border-4 border-white h-20px w-20px`}
              ></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    <FormattedMessage
                      id='fullName'
                      values={{
                        firstname: capitalize(user?.first_name),
                        lastname: capitalize(user?.last_name),
                      }}
                    />
                  </p>
                  <p>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </p>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href={`tel:+${user.phone}`}
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/electronics/elc002.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user.phone}+
                  </a>
                  <a
                    href={`mailto:${user.email}`}
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user.email}
                  </a>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div
                    className='border border-gray-300 border-dashed cursor-pointer rounded min-w-125px py-3 px-4 me-6 mb-3 text-primary'
                    onClick={() => window.open('https://ahad.ws/prices/')}
                  >
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/finance/fin010.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>{formatMessage({ id: 'Prices' })}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>
                      {formatMessage({ id: 'Prices list' })}
                    </div>
                  </div>

                  <div
                    className='border border-gray-300 border-dashed cursor-pointer rounded min-w-125px py-3 px-4 me-6 mb-3 text-primary'
                    onClick={() => window.open('https://ahad.ws/waiting-list/')}
                  >
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil001.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>{formatMessage({ id: 'Reservation' })}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>
                      {formatMessage({ id: 'Reservation follow up' })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-flush h-lg-50 w-lg-25'>
            <div className='card-header pt-5'>
              <h3 className='card-title text-gray-800 fw-bolder fs-2'>
                {formatMessage({ id: 'News' })}
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body pt-5'>
              <div className='d-flex flex-stack'>
                <span
                  onClick={() =>
                    window.open(
                      'https://ahad.ws/news-articles/tax-regulations/%d8%a7%d9%84%d8%b2%d9%83%d8%a7%d8%a9-%d9%88%d8%a7%d9%84%d8%b6%d8%b1%d9%8a%d8%a8%d8%a9-%d9%88%d8%a7%d9%84%d8%ac%d9%85%d8%a7%d8%b1%d9%83-%d8%aa%d9%8f%d8%b4%d9%83%d9%91%d9%84-%d9%84%d8%ac%d9%86%d8%a9/'
                    )
                  }
                  className='text-primary fw-semibold fs-6 me-2 cursor-pointer'
                >
                  {formatMessage({
                    id: 'Zakat, Tax and Customs Authority (ZATCA) forms an advisory committee to present recommendations and proposals',
                  })}
                </span>
                <button
                  type='button'
                  className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                >
                  <span className='svg-icon svg-icon-2'></span>
                </button>
              </div>
              <div className='separator separator-dashed my-3'></div>
              <div className='d-flex flex-stack'>
                <span
                  onClick={() =>
                    window.open(
                      'https://ahad.ws/news-articles/general/%d8%a7%d9%84%d8%b2%d9%83%d8%a7%d8%a9-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a%d8%a9-%d8%aa%d9%8c%d9%82%d8%b1-%d8%aa%d8%b9%d8%af%d9%8a%d9%84-%d8%aa%d8%b9%d9%84%d9%8a%d9%85%d8%a7%d8%aa-%d8%aa/'
                    )
                  }
                  className='text-primary fw-semibold fs-6 me-2 cursor-pointer'
                >
                  {formatMessage({
                    id: 'Zakat, Tax and Customs Authority (ZATCA) approves the amendment of the transaction pricing instructions',
                  })}
                </span>
                <button
                  type='button'
                  className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                >
                  <span className='svg-icon svg-icon-2'></span>
                </button>
              </div>
              <div className='separator separator-dashed my-3'></div>
              <div className='d-flex flex-stack'>
                <span
                  onClick={() =>
                    window.open(
                      'https://ahad.ws/news-articles/tax-regulations/%d8%a7%d9%84%d8%b2%d9%83%d8%a7%d8%a9-%d9%88%d8%a7%d9%84%d8%b6%d8%b1%d9%8a%d8%a8%d8%a9-%d9%88%d8%a7%d9%84%d8%ac%d9%85%d8%a7%d8%b1%d9%83-%d8%aa%d8%ad%d8%af%d8%af-%d9%85%d8%b9%d9%8a%d8%a7%d8%b1-%d8%a7/'
                    )
                  }
                  className='text-primary fw-semibold fs-6 me-2 cursor-pointer'
                >
                  {formatMessage({
                    id: 'Zakat, Tax and Customs Authority (ZATCA) determines the criteria for selecting target establishments in the third group of electronic invoicing',
                  })}
                </span>
                <button
                  type='button'
                  className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                >
                  <span className='svg-icon svg-icon-2'></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* overflow-auto */}
        <div className='d-flex  h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` + (location.pathname === '/' && 'active')
                }
                to='/'
              >
                {formatMessage({ id: 'Companies' })}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/user-profile' && 'active')
                }
                to='/user-profile'
              >
                {formatMessage({ id: 'User Profile' })}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/user-companies' && 'active')
                }
                to='/user-companies'
              >
                {formatMessage({ id: 'User companies' })}
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/d/documents' && 'active')
                }
                to='/d/documents'
              >
                {formatMessage({ id: 'my_docs' })}
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ProfileHeader };
