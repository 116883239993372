import { SelectOptions } from '../app/types/SelectOptions';

export function createOptions(
  array: any[],
  label = 'name',
  value = 'id',
  is_return_code = 'is_return_code'
): SelectOptions {
  return (
    array?.map((item) => ({
      ...item,
      label: item[label],
      value: item[value],
      is_return_code: item?.[is_return_code] ?? false,
    })) || []
  );
}
