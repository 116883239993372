/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

import * as TableRedux from '../../store/TableRedux';

import { RootState } from '../../../setup/redux/Store';
import { perpage } from '../../../_metronic/constants/general';
import { ID } from '../../../_metronic/constants/paths';
import { Meta } from '../../types/Meta';
import { DisplayItem } from './classes/DisplayItem';
import { TableSelection } from './classes/TableSelection';

interface headerItem {
  id?: string;
  width?: number;
  maxWidth?: number;
  [key: string]: any;
}

interface rowItem {
  [key: string]: any;
}

type Props = {
  className?: string;
  meta: Meta;
  headerItems: Array<headerItem>;
  rowItems?: Array<rowItem>[];
  headerWithCheckbox?: boolean;
  disableHeaderWithCheckbox?: boolean;
  hideFooter?: boolean;
  showTotal?: boolean;
  hidePagination?: boolean;
  tableName: string;
  initPerPage?: number;
  allDisplayedIds: Array<number | ID>;
  totalIndexArray?: Array<number>;
  scrollable?: boolean;
};

const CommonTable: React.FC<Props> = ({
  className,
  headerItems,
  rowItems,
  headerWithCheckbox,
  disableHeaderWithCheckbox = false,
  hideFooter = false,
  showTotal = false,
  hidePagination = false,
  tableName,
  allDisplayedIds,
  meta,
  initPerPage,
  totalIndexArray = [],
  scrollable = false,
}) => {
  const { formatMessage } = useIntl();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  const resultArray = totalIndexArray?.map((item) => {
    const result = rowItems?.reduce(
      (acc, row) => {
        row?.map((rowItem, rowItemIndex) =>
          item === rowItemIndex - 1
            ? (acc[`${tableName + item}`] += parseFloat(rowItem.fieldData.text)
                ? parseFloat(rowItem.fieldData.text)
                : 0)
            : ''
        );

        return acc;
      },
      { [`${tableName + item}`]: 0 }
    );
    return result;
  });

  useEffect(() => {
    dispatch(TableRedux.actions.initTable({ tableName, allDisplayedIds, initPerPage }));
    return () => {
      dispatch(TableRedux.actions.terminateTable({ tableName }));
    };
  }, [tableName]);

  useEffect(() => {
    dispatch(
      TableRedux.actions.changeTablePagination({
        tableName,
        pagination: initPerPage || 20,
      })
    );
  }, []);

  useEffect(() => {
    if (allDisplayedIds?.length && meta.current_page !== page) {
      setPage(meta.current_page);
      dispatch(TableRedux.actions.updateIds({ tableName, allDisplayedIds }));
    }
  }, [allDisplayedIds, meta]);

  useEffect(() => {
    dispatch(TableRedux.actions.updateIds({ tableName, allDisplayedIds }));
  }, [allDisplayedIds?.length]);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[tableName];

  return (
    <div className={`${className}`} id={tableName} data-testid={`${tableName}_table`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive mb-5'>
          {/* begin::Table */}
          <table
            className='table table-row-bordered data-table table-row-gray-100 align-middle gs-0 gy-3'
            style={
              scrollable
                ? { borderCollapse: 'collapse', borderRadius: '1em', display: 'block' }
                : { borderCollapse: 'collapse', borderRadius: '1em' }
            }
          >
            {/* begin::Table head */}

            <thead
              style={
                scrollable
                  ? {
                      display: 'block',
                    }
                  : {}
              }
            >
              <tr>
                {headerWithCheckbox && rowItems && rowItems?.length > 0 ? (
                  <th className='px-5' style={{ maxWidth: `1px`, width: `1px` }}>
                    {DisplayItem(
                      new TableSelection({
                        display: headerWithCheckbox,
                        id: -1,
                        tableName,
                        disabled: disableHeaderWithCheckbox,
                      })
                    )}
                  </th>
                ) : null}
                {headerItems.map((item) => (
                  <th
                    key={item.id}
                    className={clsx('text-blueGrey fs-5 px-5')}
                    style={{ maxWidth: `${item?.maxWidth}px`, width: `${item?.width || 100}px` }}
                  >
                    {formatMessage({ id: item.id })}
                  </th>
                ))}
              </tr>
            </thead>

            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody
              style={
                scrollable
                  ? {
                      display: 'block',
                      overflowY: 'scroll',
                      height: '30vh',
                    }
                  : {}
              }
            >
              {rowItems?.map((row, rowIndex) => {
                // To Remove first item if display checkbox is not true
                // NOTE: Checkbox is always in the first item in each row
                let rowData = row;
                if (!row[0].fieldData.display) {
                  rowData = rowData.slice(1);
                }
                return (
                  <tr key={rowIndex}>
                    {rowData?.map((rowItem, rowItemIndex) => {
                      return (
                        <td
                          key={rowItemIndex}
                          className={scrollable ? clsx('px-7') : clsx('px-5')}
                          style={
                            scrollable
                              ? {
                                  maxWidth: `${headerItems[rowItemIndex]?.maxWidth}px`,
                                  width: `${headerItems[rowItemIndex]?.width || 100}px`,
                                }
                              : {}
                          }
                        >
                          {DisplayItem(rowItem)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {rowItems?.length === 0 && (
            <>
              <div className='text-center'>
                <FormattedMessage id='No Records To Display' />
              </div>
              <div className='text-center'>
                <img
                  src={toAbsoluteUrl('/media/404/searching-data-3385493.png')}
                  alt=''
                  className='mw-300px'
                />
              </div>{' '}
            </>
          )}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* start::Total */}
        {showTotal && (
          <div className='table-responsive mb-5'>
            <thead>
              <tr>
                {headerItems.map((item, index) => (
                  <th
                    key={item.id}
                    className={clsx('text-blueGrey fs-5 px-5')}
                    style={{ maxWidth: `${item?.maxWidth}px`, width: `${item?.width || 100}px` }}
                  >
                    {totalIndexArray?.includes(index)
                      ? formatMessage({ id: `Total ` }) + ' ' + formatMessage({ id: `${item.id}` })
                      : ''}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                <tr key='totals'>
                  {rowItems?.[0]?.map((rowItem, rowItemIndex) => (
                    <td key={rowItemIndex} className='px-5'>
                      {totalIndexArray?.includes(rowItemIndex)
                        ? `${resultArray[
                            totalIndexArray.findIndex((item) => item === rowItemIndex)
                          ]?.[`${tableName + rowItemIndex}`].toFixed(2)}`
                        : ''}
                    </td>
                  ))}
                </tr>
              }
            </tbody>
          </div>
        )}
        {/* end::Total */}

        {!hideFooter && (
          <div className='row justify-content-between mx-5 align-items-baseline'>
            <div className='col-xs-12 col-md-6 col-xl-4 col-xxl-3 my-3 text-lightGreyBlue fs-6'>
              <FormattedMessage
                id='PaginationStatistics'
                values={{ from: meta?.from, to: meta?.to, total: meta?.total }}
              />
            </div>
            <div className='col-xs-12 col-md-6 col-xl-4 col-xxl-3 my-3'>
              <Pagination
                activePage={meta?.current_page}
                itemsCountPerPage={meta?.per_page}
                totalItemsCount={meta?.total || 0}
                pageRangeDisplayed={5}
                onChange={(page: number) =>
                  dispatch(TableRedux.actions.changePage({ tableName, page }))
                }
                itemClass='page-item'
                linkClass='page-link'
              />
            </div>

            <div className='d-flex align-items-baseline text-lightGreyBlue col-xs-12 col-md-6 col-xl-4 col-xxl-3 my-3'>
              {!hidePagination && (
                <>
                  <p className='w-200px fs-6'>
                    <FormattedMessage id='Items per page' />
                  </p>
                  <select
                    className='form-select form-select-solid'
                    name='table-pagination'
                    defaultValue={table?.pagination || initPerPage}
                    onChange={(e) =>
                      dispatch(
                        TableRedux.actions.changeTablePagination({
                          tableName,
                          pagination: Number(e.target.value),
                        })
                      )
                    }
                  >
                    {perpage?.map((num: any) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {/* begin::Body */}
    </div>
  );
};
export { CommonTable };
