import { FC } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

type Props = any;

export const EditDateItem: FC<Props> = ({ ...props }) => {
  return (
    <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' {...props}>
      <KTSVG path='/media/icons/duotune/abstract/abs031.svg' className='svg-icon-3' />
    </button>
  );
};
