import * as Yup from 'yup';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';

export interface ICreateNewSalesReturn {
  invoice_type: string;
  invoice_reference: [];
  // report_name_ar: string;
  // report_name_en: string;
  // report_period: [];
  // monthly_period: [];
  // year_period: [];
}

const useConfigureReportsSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const returnSalesInitialValues: ICreateNewSalesReturn = useMemo(
    () => ({
      invoice_type: 'hasReference',
      invoice_reference: [],
      invoice_date: '',
      payment_date: '',
      invoice_number: '',
      invoice_number_method: '1',
      clients: [],
      invoice_items: [
        {
          // name: [],
          description: '',
          // category: [],
          quantity: 0,
          // max_quantity: 0,
          price: 0,
          price_before_vat: 0,
          discount_amount: 0,
          total_before_discount: 0,
          vat_rate: [],
          tax_value: 0,
          total: 0,
        },
      ],
      // report_name_ar: '',
      // report_name_en: '',
      // report_period: [],
      // monthly_period: [],
      // year_period: [],
      status: '0',
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const returnSalesValidationSchema = useMemo(
    () => [
      Yup.object({
        invoice_type: Yup.string().required(required),
      }),
      Yup.object({
        invoice_reference: Yup.array()
          // .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
      }),
      Yup.object({
        status: Yup.string().required(required),
        invoice_number_method: Yup.string(),
        invoice_date: Yup.string().when('status', {
          is: (val: any) => {
            return val === '0';
          },
          then: Yup.string().nullable(),
          otherwise: Yup.string().required(required),
        }),
        invoice_number: Yup.string().when(['status', 'invoice_number_method'], {
          is: (status: any, invoice_number_method: any) => {
            return status === '0' || invoice_number_method === '2';
          },
          then: Yup.string().nullable(),
          otherwise: Yup.string()
            .required(required)
            .min(
              commonValidations.invoiceMinimum,
              formatMessage(
                { id: 'Please enter at least {minCharacters} characters' },
                { minCharacters: commonValidations.invoiceMinimum }
              )
            )
            .max(
              commonValidations.invoiceMaximum,
              formatMessage(
                { id: 'The Maximum characters is {maxCharacters}' },
                { maxCharacters: commonValidations.invoiceMaximum }
              )
            )
            .test(
              'invoice_number',
              formatMessage({ id: "You can't enter special character at first or last!" }),
              (val) => nameWithoutSpcialCharsStartOrEnd(val)
            ),
        }),

        clients: Yup.array().when('status', {
          is: (val: any) => {
            return val === '0';
          },
          then: Yup.array().nullable(),
          otherwise: Yup.array()
            .required(required)
            .min(1, formatMessage({ id: 'Please choose at least one' })),
        }),

        invoice_items: Yup.array()
          .of(
            Yup.object().shape({
              // name: Yup.array()
              //   .required(required)
              //   .min(1, formatMessage({ id: 'Please choose at least one' })),
              // category: Yup.array()
              //   .required(required)
              //   .min(1, formatMessage({ id: 'Please choose at least one' })),
              max_quantity: Yup.number().typeError($t('Only number are allowed!')),
              quantity: Yup.number().when('max_quantity', {
                is: (val: any) => {
                  return val >= 0;
                },
                then: Yup.number()
                  .integer($t('Only positive integer numbers are allowed!'))
                  .positive($t('Only positive integer numbers are allowed!'))
                  .typeError($t('Only positive integer numbers are allowed!'))
                  .min(0, $t('Only positive integer numbers are allowed!'))
                  .max(Yup.ref('max_quantity'), ({ max }) =>
                    formatMessage(
                      { id: 'Quantity must be less than or equal to {max_quantity}' },
                      {
                        max_quantity: `${max}`,
                      }
                    )
                  ),
                otherwise: Yup.number()
                  .integer($t('Only positive integer numbers are allowed!'))
                  .positive($t('Only positive integer numbers are allowed!'))
                  .typeError($t('Only positive integer numbers are allowed!'))
                  .min(1, $t('Only positive integer numbers are allowed!')),
              }),
              price: Yup.number().typeError($t('Only number are allowed!')).moreThan(0, required),
              price_before_vat: Yup.number().typeError($t('Only number are allowed!')).nullable(),
              total_before_discount: Yup.number(),
              max_discount_amount: Yup.number().typeError($t('Only number are allowed!')),
              discount_amount: Yup.number().when(['max_discount_amount'], {
                is: (max_discount_amount: any) => {
                  return max_discount_amount > 0;
                },
                then: Yup.number()
                  .typeError($t('Only positive numbers are allowed!'))
                  .moreThan(-1, $t('Only positive numbers are allowed!'))
                  .nullable()
                  .max(Yup.ref('max_discount_amount'), ({ max }) =>
                    formatMessage(
                      { id: 'Discount amount must be less than or equal to {max_discount_amount}' },
                      {
                        max_discount_amount: `${max}`,
                      }
                    )
                  ),
                otherwise: Yup.number(),
              }),
              vat_rate: Yup.array()
                .required(required)
                .min(1, formatMessage({ id: 'Please choose at least one' })),
              tax_value: Yup.number().typeError($t('Only number are allowed!')).nullable(),
              total: Yup.number().typeError($t('Only number are allowed!')).nullable(),
            })
          )
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
      }),
    ],
    [formatMessage]
  );

  return { returnSalesValidationSchema, returnSalesInitialValues };
};
// export { createReportSchemas, inits };
export { useConfigureReportsSchema };
