import { FC, useEffect, useState } from 'react';
import { DocTypesList } from '../components/DocTypesList';
import { RecentActions } from '../components/RecentActions';
import { DoctypeType, RencentAction } from '../types';

const DocTypes: Array<DoctypeType> = [
  {
    titleId: 'Agreements',
    iconBGColor: 'light-rose',
    descriptionId: 'Contains contracts, docs ..',
    amount: undefined,
    faIcon: 'fa-print',
  },
  {
    titleId: 'Formal Notice',
    iconBGColor: 'light-blue',
    descriptionId: 'Docs like Vacations …',
    amount: undefined,
    faIcon: 'fa-edit',
  },
  {
    titleId: 'Non - Formal Notice',
    iconBGColor: 'light-green',
    descriptionId: 'Contains contracts, docs ..',
    amount: undefined,
    faIcon: 'fa-envelope',
  },
];

const recentActionsDemo: RencentAction[] = [
  {
    id: 1,
    actionTitle: 'Real Estate Sales Contract',
    status: 'adjneeded',
    image: './media/avatars/150-2.jpg',
    by: 'Mohamed Othman',
    price: '15000',
  },
  {
    id: 2,
    actionTitle: 'Freelancer Developer',
    image: './media/avatars/150-5.jpg',
    status: 'closed',
    by: 'Sarah Mahmoud',
    price: '750',
  },
  {
    id: 3,
    actionTitle: 'Product Purchase Contract',
    status: 'waitingforcompanysignature',
    by: 'Sarah Mahmoud',
    price: '30000',
    viewedBy: {
      id: 1,
      viewer: 'Ahmed Ali',
      date: '20-4-2021',
      action: 'Write some feedback',
    },
  },
];

const DockTypesAndRecentActions: FC = () => {
  const [docTypes, setDocTypes] = useState(DocTypes);

  useEffect(() => {
    setTimeout(() => {
      setDocTypes(
        [...DocTypes].map((type) => ({ ...type, amount: Math.ceil(Math.random() * 100) }))
      );
    }, 2000);
  }, []);

  return (
    <aside id='docktype-recent-actions' className='bg-white'>
      <DocTypesList docTypes={docTypes} />
      <div className='separator border-gray-200'></div>
      <RecentActions recentActions={recentActionsDemo} />
    </aside>
  );
};

export { DockTypesAndRecentActions };
