export const backendBaseUrl =
  process.env.REACT_APP_BACKEND_BASE_URL + '/v' + process.env.REACT_APP_BACKEND_VERSION;

export type ID = string | number | null;

export const endpoints = {
  login: '/oauth/token',

  authUser: '/user',

  register: 'register',

  forgetPassword: 'forget-password',

  companies: 'companies',

  userCompanies: () => `/my-companies`,

  permissions: (companyId: ID) => `${companyId}/permissions`,

  previewTemplates: '/preview/templates',
  // roles
  companyRoles: (companyId: ID) => `${companyId}/roles`,

  editCompanyRoles: (companyId: ID, roleId: ID) => `${companyId}/roles/${roleId}`,
  // users
  companyUsers: (companyId: ID) => `${companyId}/users`,

  companySingleUser: (companyId: ID, userId: ID) => `${companyId}/users/${userId}`,
  // document reviewers
  companyDocumentReviewers: (companyId: ID) => `${companyId}/document/reviewers`,
  // clients
  companyClients: (companyId: ID) => `${companyId}/clients`,

  companySingleClient: (companyId: ID, clientId: ID) => `${companyId}/clients/${clientId}`,
  // products
  companyProducts: (companyId: ID) => `${companyId}/products`,

  companySingleProduct: (companyId: ID, productId: ID) => `${companyId}/products/${productId}`,
  // invoices
  companyInvoices: (companyId: ID) => `${companyId}/invoices`,

  // sales invoices
  companySalesInvoices: (companyId: ID) => `${companyId}/invoices/sales`,

  companySingleSalesInvoice: (companyId: ID, invoiceId: ID) =>
    `${companyId}/invoices/sales/${invoiceId}`,

  // purchases invoices
  companyPurchasesInvoices: (companyId: ID) => `${companyId}/invoices/purchases`,

  companySinglePurchasesInvoice: (companyId: ID, invoiceId: ID) =>
    `${companyId}/invoices/purchases/${invoiceId}`,

  // sales
  companySales: (companyId: ID) => `${companyId}/sales`,

  companySingleSale: (companyId: ID, saleId: ID) => `${companyId}/sales/${saleId}`,

  // sales lines items
  companySalesLineItems: (companyId: ID) => `${companyId}/invoice_items`,

  companySingleSalesLineItems: (companyId: ID, saleId: ID) =>
    `${companyId}/invoice_items/${saleId}`,

  // purchases
  companyPurchases: (companyId: ID) => `${companyId}/purchases`,

  companySinglePurchase: (companyId: ID, purchaseId: ID) => `${companyId}/purchases/${purchaseId}`,

  // purchases lines items
  companyPurchasesLineItems: (companyId: ID) => `${companyId}/invoice_items`,

  companySinglePurchasesLineItems: (companyId: ID, purchaseId: ID) =>
    `${companyId}/invoice_items/${purchaseId}`,

  // reports
  companyReports: (companyId: ID) => `${companyId}/tax_return`,

  companyTransactions: (companyId: ID) => `${companyId}/report/transactions`,

  companySingleReport: (companyId: ID, reportId: ID) => `${companyId}/tax_return/${reportId}`,

  // reports/transactions details
  companyTransactionsDetails: (companyId: ID, reportId: ID) =>
    `${companyId}/tax_return/${reportId}/transactions`,

  // invoices reports
  companyInvoicesReports: (companyId: ID) => `${companyId}/reports/vat`,

  companySingleInvoiceReport: (companyId: ID, reportId: ID) =>
    `${companyId}/reports/vat/${reportId}`,

  companyInvoicesReportTransactions: (companyId: ID) => `${companyId}/report/vat/invoices`,

  // invoices reports/transactions details
  companyInvoicesReportTransactionsDetails: (companyId: ID, reportId: ID) =>
    `${companyId}/reports/vat/${reportId}/invoices`,

  // invoices withholding reports
  companyWithholdingInvoicesReports: (companyId: ID) => `${companyId}/reports/withholding`,

  companyWithholdingSingleInvoiceReport: (companyId: ID, reportId: ID) =>
    `${companyId}/reports/withholding/${reportId}`,

  companyInvoicesWithholdingReportTransactions: (companyId: ID) =>
    `${companyId}/report/withholding/invoices`,

  companyInvoicesWithholdingReportPurchases: (companyId: ID) => `${companyId}/invoice_items`,

  // invoices withholding reports/transactions details
  companyInvoicesWithholdingReportTransactionsDetails: (companyId: ID, reportId: ID) =>
    `${companyId}/reports/withholding/${reportId}/invoices`,

  // lines items
  companyLineItems: (companyId: ID) => `${companyId}/invoice_items`,

  companySingleLineItems: (companyId: ID, lineItemId: ID) =>
    `${companyId}/invoice_items/${lineItemId}`,

  // Logs
  companyLogs: (companyId: ID) => `${companyId}/logs`,
  // templates
  companyTemplates: (companyId: ID) => `${companyId}/templates`,

  getCompanyTemplate: (companyId: ID, templateId: ID) => `${companyId}/templates/${templateId}`,

  updateCompanyTemplates: (companyId: ID, templateId: ID) => `${companyId}/templates/${templateId}`,

  cloneTemplate: (companyId: ID, template: ID, templateConfigId: ID) =>
    `${companyId}/templates/${template}/clone/${templateConfigId}`,

  // custom variables for template
  customvariables: (companyId: ID) => `${companyId}/variables`,
  // documents
  companyDocuments: (companyId: ID) => `${companyId}/documents`,

  companyDocumentToTakeAction: `auth/documents`,

  companyunderReviewDocuments: (companyId: ID) => `${companyId}/reviewers/documents`,

  getCompanyDocument: (companyId: ID, documentId: ID) => `${companyId}/documents/${documentId}`,

  updateCompanyDocuments: (companyId: ID, documentId: ID) => `${companyId}/documents/${documentId}`,

  cloneDocumentFromTemplate: ({ companyId, templateId }: { companyId: ID; templateId: ID }) =>
    `${companyId}/templates/${templateId}/documents`,
};
