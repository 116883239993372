import clsx from 'clsx';
import { useFormik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useQuery } from 'react-query';

import * as Yup from 'yup';
import * as TableRedux from '../../../store/TableRedux';

import { RootState } from '../../../../setup';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';
import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { CompaniesDataListModel } from '../../../modules/companies/CompanyModel';
import {
  addPermissions,
  getPermissions,
  editPermissions,
} from '../../../modules/roles/store/RolesCrud';
import { Input } from '../../../shared/Input';
import { SeparatorLines } from '../../../shared/Lines';
import { LocaleTitle } from '../../../shared/LocaleTitle';
import { TextArea } from '../../../shared/textarea';
import { handleSelection } from '../handleSelection';
import { PermissionArray } from '../types/permission';
import { initialValues } from './initialValues';
import { axiosInstance } from '../../../../network/apis';
import useExitPrompt from '../../../hooks/useExitPrompt/useExitPrompt';

import { capitalize } from '../../../../utils/strings';
import { Users } from '../../../modules/users/UsersWrapper';
import { usePermissions } from '../../../hooks/usePermissions';

type Props = {
  mode: 'create' | 'edit' | 'view';
};
const tableName = 'users_list';

export const AddEditRolesForm: FC<Props> = ({ mode }) => {
  const [loading, setLoading] = useState(false);
  const [allPermissions, setAllPermissions] = useState<any>([]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  const [displayed, setDisplayed] = useState<Array<number>>([0, 1]);
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  usePermissions('create-role', 'Add role');
  const [viewEditRole, setViewEditRole] = useState<any>({});

  // for directions in ui
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const companyId =
    companies?.data?.find((company) => company.slug === params.companySlug)?.id || null;

  const gettingPermissions = async () => await getPermissions(companyId);

  useEffect(() => {
    gettingPermissions().then((res) => {
      const data = res.data;
      const titles = Object.keys(data);
      setAllPermissions(titles.map((title) => ({ title, permissions: data[title] })));
    });
  }, []);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[tableName];

  const AddEditRoleSchema = useMemo(
    () =>
      Yup.object().shape({
        name_en: Yup.string()
          .required($t('This field is required'))
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_en', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        name_ar: Yup.string()
          .required($t('This field is required'))
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_ar', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_ar: Yup.string()
          .trim()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_ar',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_en: Yup.string()
          .trim()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_en',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        permissions: Yup.array()
          .min(1, $t('You need to select at least one permission'))
          .required($t('You need to select at least one permission')),
      }),
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema: AddEditRoleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        if (typeof companyId !== 'number') {
          toast.error(formatMessage({ id: 'Error! You will need to refresh the page' }));
          return;
        }
        const payload: any = {};

        table.selection.includes(-1) ? (payload.users = [-1]) : (payload.users = table.selection);

        dispatch(TableRedux.actions.clearSelection({ tableName }));
        payload.description = {
          ar: values.description_ar ? values.description_ar : '',
          en: values.description_en ? values.description_en : '',
        };
        payload.name = { ar: values.name_ar, en: values.name_en };
        payload.permissions = _.uniq(values.permissions);
        const formData = serialize(payload);
        if (mode === 'create') {
          await addPermissions(companyId, formData);
          toast.success(formatMessage({ id: 'The role has been created successfully!' }));
        } else {
          formData.append('_method', 'PUT');
          await editPermissions(companyId, params.roleId, formData);
          toast.success(formatMessage({ id: 'The role has been edited successfully!' }));
        }

        history.push(`/c/${params.companySlug}/settings/roles`);
      } catch (error: any) {
        DisplayErrors(error);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });
  const { touched, setFieldValue } = formik; //values, errors,

  /* ------------------------- edit and display fetch ------------------------- */
  const { data: role, refetch: getRoleDetails } = useQuery(
    'Role',
    () =>
      axiosInstance(`${companyId}/roles/${params.roleId}`).then((res) => {
        return res.data;
      }),
    { enabled: false, keepPreviousData: false, cacheTime: 0 }
  );

  useEffect(() => {
    if (params?.roleId) {
      getRoleDetails();
    }
  }, [params]);

  useEffect(() => {
    if (role?.id) {
      setFieldValue('name_en', role?.name_translations?.en);
      setFieldValue('name_ar', role?.name_translations?.ar);
      setFieldValue('description_en', role?.description_translations?.en);
      setFieldValue('description_ar', role?.description_translations?.ar);
      setFieldValue(
        'permissions',
        role?.permissions.map((permission: any) => permission.id)
      );
      dispatch(
        TableRedux.actions.bulkToggleSelection({
          tableName,
          idList: role?.users?.map((user: any) => user.id),
        })
      );
      setViewEditRole({ mode });
    }
  }, [role]);

  const shouldAsk = () => Object.keys(touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <form
      className={clsx('p-5')}
      noValidate
      autoComplete='none'
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='table-wrapper bg-white pb-10'>
        <h3 className='m-2 mx-5'>{$t('Role Settings')}</h3>
        <SeparatorLines lines={2} />
        <div className='p-10 ' style={{ width: '100%' }}>
          <div className='row  '>
            <div className='d-flex justify-content-between'>
              <div className='d-flex justify-content-start'></div>
              {mode === 'view' && (
                <div
                  className='d-flex justify-content-end'
                  style={{ textAlign: 'end', alignSelf: 'center' }}
                >
                  <button
                    className='btn btn-primary btn-sm btn-primary-shadow'
                    title={formatMessage({ id: 'Edit role' })}
                    onClick={() => history.push(location.pathname + '/edit')}
                  >
                    <i className='fa fa-edit cursor-pointer'></i>
                    {formatMessage({ id: 'Edit role' })}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className='m-10'>
            <div className='row fv-row'>
              {locales.map((lang) => (
                <div className='col-xl-6'>
                  <Input
                    placeholder={LocaleTitle({ id: 'Role Name', lang })}
                    autoComplete='off'
                    labelId={LocaleTitle({ id: 'Role Name', lang })}
                    name={`name_${lang}`}
                    type='text'
                    formik={formik}
                    dir={lang === 'ar' ? 'rtl' : 'ltr'}
                    disabled={viewEditRole.mode === 'view'}
                  />
                </div>
              ))}
            </div>
            <div className='row fv-row mt-10'>
              {locales.map((lang) => (
                <div className='col-xl-6'>
                  <TextArea
                    placeholder={LocaleTitle({ id: 'Role Description', lang })}
                    autoComplete='off'
                    labelId={LocaleTitle({ id: 'Role Description', lang })}
                    name={`description_${lang}`}
                    type='text'
                    formik={formik}
                    dir={lang === 'ar' ? 'rtl' : 'ltr'}
                    rows='6'
                    disabled={viewEditRole.mode === 'view'}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='table-wrapper bg-white mt-10 pb-10'>
        <h3 className='m-2 mx-5'>{$t('Permissions')}</h3>
        {formik.errors.permissions && (formik.touched.permissions || formik.isSubmitting) && (
          <p role='alert' className='text-danger mx-4 fs-4'>
            {formik.errors.permissions}
          </p>
        )}
        <SeparatorLines lines={2} />

        <div className='m-auto my-5' style={{ width: '95%' }}>
          <div className='accordion' id='accordionExample'>
            {allPermissions.map(
              (permission: { title: string; permissions: PermissionArray }, index: number) => (
                <div className='accordion-item mt-5'>
                  <h2
                    className='accordion-header'
                    id='headingOne'
                    onClick={() => setDisplayed(() => _.xor([index], displayed))}
                  >
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      <i className={`mx-3 far fa-arrow-${locale === 'ar' ? 'left' : 'right'}`}></i>{' '}
                      {$t(capitalize(permission.title))}
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className={clsx('accordion-collapse collapse', {
                      show: displayed.includes(index),
                    })}
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    {permission.permissions.map((currentPermission, i, party: PermissionArray) => {
                      return (
                        <div className='accordion-body' key={currentPermission.id}>
                          <label className='form-check form-check-sm form-check-custom form-check-solid me-5 flex-grow-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              disabled={viewEditRole.mode === 'view'}
                              checked={!!formik.values.permissions.includes(currentPermission.id)}
                              onClick={() =>
                                formik.setFieldValue(
                                  'permissions',
                                  handleSelection(
                                    currentPermission.id,
                                    currentPermission.name,
                                    permission.title,
                                    allPermissions,
                                    party,
                                    formik.values.permissions
                                  )
                                )
                              }
                            />
                            <span className='form-check-label'>{$t(currentPermission.name)}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className='table-wrapper bg-white mt-10 mb-10'>
        <Users viewEditRole={viewEditRole} />
      </div>

      {viewEditRole.mode !== 'view' && (
        <div className='text-center col-xl-3 d-flex gap-3 m-5'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-sm w-100 mb-5 btn-primary btn-shadow btn-primary-shadow mt-4'
            disabled={formik.isSubmitting}
          >
            {!loading && (
              <span className='indicator-label'>
                <FormattedMessage id='save' />
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <FormattedMessage id='Please wait...' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            onClick={() => {
              formik.resetForm();
              history.push(`/c/${params.companySlug}/settings/roles`);
            }}
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 mb-5  shadowed mt-4'
          >
            <FormattedMessage id='Cancel' />
          </button>
        </div>
      )}
    </form>
  );
};
