import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Label } from '../../../shared/Label';
import { axiosInstance } from '../../../../network/apis';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface IOptions {
  value: number;
  label: string;
}
interface IResponseApi {
  id: number;
  name: {
    ar: string;
    en: string;
  };
  description: {
    ar: string;
    en: string;
  };
  assigned_companies_count: number;
  price: string;
}
interface IselectedPlan {
  value: number;
  label: string;
  isDisabled?: boolean;
}
export const RequestRenewPlanForm = ({ companyDetails, refetchData }: any) => {
  const { formatMessage, locale } = useIntl();
  const [loading, setLoading] = useState(false);
  const [planOptions, setPlanOptions] = useState<any[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<IselectedPlan>();
  const [confirmSelectPlan, setConfirmSelectPlan] = useState(false);
  const getPlanOptions = useCallback(() => {
    axiosInstance
      .get('admin/plans')
      .then((response) => {
        const res: IResponseApi[] = response.data.data;
        const enhanceResponse: IOptions[] = res.map((plan) => ({
          value: plan.id,
          label: plan?.name?.[locale as 'en' | 'ar'],
        }));

        const selectedOption = enhanceResponse.filter(
          (item) => item.value === companyDetails.plan_id
        )[0];

        const removeOldSelectObject = enhanceResponse.filter(
          (item) => item.value !== selectedOption.value
        );

        const disabelSelectedOption = { ...selectedOption, isDisabled: true };

        const newArray = [...removeOldSelectObject, disabelSelectedOption];

        setPlanOptions(newArray);
      })
      .catch((error) => {
        console.log('plans', error);
      });
  }, []);

  /* --------------------------------- Formik --------------------------------- */
  const formik = useFormik({
    initialValues: {
      plan_id: { value: null, label: '' },
    },
    onSubmit: (values) => {
      if (+companyDetails.remaining_subscription_days > 7) {
        toast.warning(formatMessage({ id: 'this is your current plan' }), {
          toastId: 'current_plan',
        });
      } else {
        setLoading(true);
        axiosInstance
          .post(`admin/companies/${companyDetails.id}/renew-plan`)
          .then((response) => {
            setLoading(false);
            toast.success(formatMessage({ id: 'renewed plan successfully' }));
            // window.location.reload();
            setConfirmSelectPlan(false);
            refetchData();
          })
          .catch((error) => {
            toast.error(formatMessage({ id: error?.response?.data?.message }), {
              toastId: 'changedPlan',
            });
            setLoading(false);
          });
      }
    },
  });
  const { setFieldValue, values } = formik;
  /* --------------------------------- Formik --------------------------------- */

  useEffect(() => {
    getPlanOptions();
  }, [getPlanOptions]);

  useEffect(() => {
    if (!planOptions) return;
    const selectedOption = planOptions.filter((item) => item.value === companyDetails.plan_id)[0];
    setFieldValue('plan_id', selectedOption);
    setSelectedPlan(selectedOption);
    setFieldValue('start_date', companyDetails.start_date);
  }, [planOptions]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      id='my-form'
      style={{
        width: '600px',
        paddingRight: locale === 'en' ? '4em' : '',
        paddingLeft: locale === 'ar' ? '4em' : '',
      }}
    >
      <Fragment>
        {!confirmSelectPlan ? (
          <Fragment>
            <div>
              <Label msg='current plan' htmlFor='plan_id' />

              <p className={'form-label fs-6 my-1 fw-bolder text-dark'}>{selectedPlan?.label}</p>
            </div>
            <hr />

            {values.plan_id?.value === companyDetails.plan_id && (
              <div>
                {/*  remianing subscribtion days */}
                <Label msg='Remaining subscribtion days' htmlFor='remaining_days' />

                <p className={'form-label fs-6 my-1 fw-bolder text-dark'}>
                  {companyDetails.remaining_subscription_days}
                </p>
              </div>
            )}
            <div className='col-xl-4 mt-5'>
              <button
                className='btn btn-sm btn-blue w-100 mb-5  shadowed mt-4'
                type='button'
                disabled={+companyDetails.remaining_subscription_days > 7}
                onClick={() => {
                  +companyDetails.remaining_subscription_days <= 7 && setConfirmSelectPlan(true);
                }}
              >
                <span className='indicator-label'>
                  <FormattedMessage id='Renew plan' />
                </span>
              </button>
            </div>
          </Fragment>
        ) : (
          <div>
            <p>
              <FormattedMessage
                id={`The subscription days that will be renewed will be added to the remaining days of the current subscription. Are you sure you want to renew the subscription plan for this company?`}
              />
              <div className='col-xl-4 mt-5 w-100 d-flex flex-row justify-content-between'>
                <button
                  type='submit'
                  className='btn btn-sm btn-blue w-100 mb-5  shadowed m-4 '
                  id='my-form'
                  disabled={loading}
                >
                  {!loading && (
                    <span className='indicator-label'>
                      <FormattedMessage id='Confirm' />
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <FormattedMessage id='Please wait...' />
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setConfirmSelectPlan(false);
                  }}
                  className='btn btn-danger fw-bold  w-100 mb-5  shadowed mt-4'
                >
                  <FormattedMessage id='Cancel' />
                </button>
              </div>
            </p>
          </div>
        )}
      </Fragment>
    </form>
  );
};
