import { axiosInstance } from '../../../../network/apis';
import { ICompany } from '../../../types/companies';

export function getUsersCompaniesRequest({ params }: { params?: any }) {
  return axiosInstance.get<ICompany>(`/companies`, { params });
}

export function getCurrentCompanyById({ id }: { id: number }) {
  return axiosInstance.get<ICompany>(`/companies/${id}`);
}

export function updateCompanyProfile({ id, body }: { id: number; body: any }) {
  return axiosInstance.post<ICompany>(`/companies/${id}/profile`, { ...body });
}

export function getAccountOwnerInfoRequest() {
  return axiosInstance.get(`/user`);
}
