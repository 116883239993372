/* -------------------------------------------------------------------------- */
/* -----------------DEPRICATED BUT LEFT FOR REFERENCE OR TO REUSE------------ */
/* -------------------------------------------------------------------------- */

import { FC, useRef, useEffect, useState } from 'react';
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux';

import * as auth from './AuthRedux';

import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { RootState } from '../../../../setup';

const mapState = (state: RootState) => ({ auth: state.auth });
const connector = connect(mapState, auth.actions);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const token = useSelector<RootState>((state) => state.auth.token, shallowEqual);

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      return () => (didRequest.current = true);
    };

    if (token) {
      requestUser();
    } else {
      dispatch(props.logout());
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
};

export default connector(AuthInit);
