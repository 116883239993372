import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { INotification } from '../../../../app/types/notifications';
import { axiosInstance } from '../../../../network/apis';
import { RootState } from '../../../../setup/redux/Store';
import { NotificationsMenu } from '../../../partials/layout/menus/NotificationsMenu';
import { HeaderUserMenuContainer } from './HeaderUserMenuContainer';
import { Notifications } from './Notifications';

export const toolbarUserAvatarHeightClass = '';

type Props = {
  withoutAlert?: boolean;
};

const UserMenuAndAlerts: FC<Props> = ({ withoutAlert }) => {
  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  // FIXME: may be we need to give make notifications related to a specific company
  const {
    isLoading,
    data: notifications,
    refetch,
  } = useQuery(
    'Notifications',
    () =>
      axiosInstance('/notifications').then((res) => {
        return res.data;
      }),
    { retryDelay: 15000, retry: 3 }
  );

  window.Echo.channel(`ahad_database_user_${user?.id}`).listen(
    '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
    (e: any) => {
      // console.log('channel notifications', e);
      if (e.data.content === 'Your import has completed successfully') refetch();
    }
  );

  // window?.Echo?.private(`App.Models.User.${user?.id}`).notification((notification: any) => {
  //   if (notification) refetch();
  // });

  const messagesLength = useMemo(
    () =>
      notifications?.filter((notification: INotification) => notification.read_at === null)?.length,
    [notifications]
  );

  const readNotifications = () => {
    try {
      axiosInstance.post('/notifications');
      refetch();
    } catch (error) {
      //
    }
  };

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='d-flex align-items-stretch justify-content-end flex-lg-grow-1 order-2 order-md-2'>
        <HeaderUserMenuContainer />
      </div>
      {!withoutAlert && (
        <>
          <div
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement={'bottom-end'}
            data-kt-menu-flip='bottom'
            className='d-flex align-items-center m-3 m-lg-5 order-1'
            onClick={() => {
              readNotifications();
            }}
          >
            <Notifications
              type='number'
              count={messagesLength > 100 ? '99+' : messagesLength}
              faIcon='fal fa-bell'
            />
            <NotificationsMenu notifications={notifications} isLoading={isLoading} />
          </div>
        </>
      )}
    </div>
  );
};

export { UserMenuAndAlerts };
