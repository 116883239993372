import { FC } from 'react';
import { DemosToggleDrawer } from './demos-drawer/DemosToggleDrawer';
import { CommentsDrawer } from './demos-drawer/CommentsDrawer';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup/redux/Store';

const RightToolbar: FC = () => {
  const comments: any = useSelector<RootState>(
    (state) => state.USER_documents.comments,
    shallowEqual
  ) as any;

  return (
    <div style={{ visibility: !comments ? 'hidden' : 'visible' }}>
      <div className='engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2'>
        <DemosToggleDrawer />
      </div>
      <CommentsDrawer />
    </div>
  );
};

export { RightToolbar };
