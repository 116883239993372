import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companySalesLineItems = endpoints.companySalesLineItems;
export const companySingleSalesLineItems = endpoints.companySingleSalesLineItems;

export function getAllSalesInvoiceItems({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companySalesLineItems(companyId), { params });
}

export function getSalesLineItem({ companyId, saleId }: { companyId: ID; saleId: ID }) {
  return axiosInstance.get(companySingleSalesLineItems(companyId, saleId));
}
