import { FC } from 'react';

type Props = {
  formik: any;
  name: string;
};

export const ErrorMessage: FC<Props> = ({ formik, name }) => {
  return (
    <>
      {formik?.touched?.[name] && formik?.errors?.[name] && (
        <div className='fv-plugins-message-container mt-2 mx-3'>
          <span role='alert' className='text-danger'>
            {formik?.errors?.[name]}
          </span>
        </div>
      )}
    </>
  );
};
