import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ID } from '../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { UsersDataListModel } from '../types/usersList';
import { user } from '../../../types/user';
import { getUsersList, getSingleUserRequest, getDocumentReviewers } from './UsersCrud';

export enum IUsersActionTypes {
  GET_USERS = 'GET_USERS',
  GET_SINGLE_USER = 'GET_SINGLE_USER',
  FULLFULL_Users = 'FULLFULL_Users',
  FULLFULL_SINGLE_USER = 'FULLFULL_SINGLE_USER',
  GET_DOCUMENT_REVIEWERS = 'GET_DOCUMENT_REVIEWERS',
  FULLFULL_DOCUMENT_REVIEWERS = 'FULLFULL_DOCUMENT_REVIEWERS',
}

const initialUsersState: IUsersState = {
  Users: undefined,
  singleUser: undefined,
  reviewers: undefined,
};

export interface IUsersState {
  Users?: UsersDataListModel;
  singleUser?: user;
  reviewers?: { id: number; name: string }[];
}

export const reducer = persistReducer(
  { storage, key: 'Users', whitelist: [''] },
  (state: IUsersState = initialUsersState, action: ActionWithPayload<IUsersState>) => {
    switch (action.type) {
      case IUsersActionTypes.FULLFULL_Users:
        return { ...state, Users: action.payload?.Users };
      case IUsersActionTypes.FULLFULL_SINGLE_USER:
        return { ...state, singleUser: action.payload?.singleUser };
      case IUsersActionTypes.FULLFULL_DOCUMENT_REVIEWERS:
        return { ...state, reviewers: action.payload?.reviewers };
      default:
        return state;
    }
  }
);

type CompanyUsersSearch = { companyId: ID | any; params?: any };
type SingleUserBasicData = { companyId: ID | any; userId?: ID | any };

export const actions = {
  getUsers: ({ companyId, params }: CompanyUsersSearch) => ({
    type: IUsersActionTypes.GET_USERS,
    payload: { companyId, params },
  }),
  fulfillUsers: ({ Users }: IUsersState) => ({
    type: IUsersActionTypes.FULLFULL_Users,
    payload: { Users },
  }),
  getSingleUser: ({ companyId, userId }: SingleUserBasicData) => ({
    type: IUsersActionTypes.GET_SINGLE_USER,
    payload: { companyId, userId },
  }),
  fulfillSingleUser: ({ singleUser }: IUsersState) => ({
    type: IUsersActionTypes.FULLFULL_SINGLE_USER,
    payload: { singleUser },
  }),
  getDocumentReviewers: ({ companyId, params }: CompanyUsersSearch) => ({
    type: IUsersActionTypes.GET_DOCUMENT_REVIEWERS,
    payload: { companyId },
  }),
  fullfullDocumentReviewers: ({ reviewers }: IUsersState) => ({
    type: IUsersActionTypes.FULLFULL_DOCUMENT_REVIEWERS,
    payload: { reviewers },
  }),
};

export function* getUsers(action: ActionWithPayload<CompanyUsersSearch>) {
  try {
    // Getting Users request
    const { data } = yield getUsersList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing users to store
    yield put(actions.fulfillUsers({ Users: data }));
  } catch (error) {
    //
  }
}

export function* getSingleUser(action: ActionWithPayload<SingleUserBasicData>) {
  try {
    // Getting Users request
    const { data } = yield getSingleUserRequest({
      companyId: action.payload?.companyId,
      userId: action.payload?.userId,
    });
    // Storing users to store
    yield put(actions.fulfillSingleUser({ singleUser: data }));
  } catch (error) {
    //
  }
}

export function* getDocumentReviewersSaga(action: ActionWithPayload<SingleUserBasicData>) {
  try {
    // Getting Users request
    const { data } = yield getDocumentReviewers({ companyId: action.payload?.companyId });
    // Storing users to store
    yield put(actions.fullfullDocumentReviewers({ reviewers: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IUsersActionTypes.GET_DOCUMENT_REVIEWERS, getDocumentReviewersSaga);
  yield takeLatest(IUsersActionTypes.GET_USERS, getUsers);
  yield takeLatest(IUsersActionTypes.GET_SINGLE_USER, getSingleUser);
}
