import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

// import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { $t } from '../../../_metronic/i18n/formatMessage';
// import { commonValidations } from '../../../_metronic/constants/GeneralValidations';

const useConfigureEditDateAndVatRateSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const editDateAndVatRateInitialValues: { [key: string]: any } = useMemo(
    () => ({
      vat_rate: [],
      payment_date: '',
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const editDateAndVatRateValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        vat_rate: Yup.array()
          // .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
        payment_date: Yup.string().required(required),
      }),

    [formatMessage]
  );

  return {
    editDateAndVatRateInitialValues,
    editDateAndVatRateValidationSchema,
  };
};

export { useConfigureEditDateAndVatRateSchema };
