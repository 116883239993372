import { serialize } from 'object-to-formdata';
import { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../../network/apis';
import { RootState } from '../../../../setup/redux/Store';
import AhadDialog from '../../../../setup/shared/AhadDialog';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import Select from 'react-select';
import { Portal } from '../../../../_metronic/partials';
import SaveCancelBtnsGroup from '../../SaveCancelBtnsGroup';
import * as UserRedux from '../../../modules/users/store/UsersRedux';
import { ICompany } from '../../../types/companies';
import { ErrorMessage } from '../../ErrorMessage';
import { CloseButtonRedShadowed } from '../../CloseButtonRedShadowed';
import { UserModel, UsersDataListModel } from '../../../modules/auth/models/UserModel';
import { ID } from '../../../../_metronic/constants/paths';
import { boxStyle } from '../../../modules/templates/boxStyle';
import CreatableSelect from 'react-select/creatable';
import { emailPattern } from '../../../../_metronic/constants/patterns';

type Props = {
  id?: string | number | null;
  itemData?: any; // reports object
  title?: string;
  invoiceObject?: any;
  setInvoiceObject?: Function;
  requestURI?: string;
  successMessage?: string;
  showButtonGroup?: boolean;
  afterSucessRequest?: Function;
};

export const SendIssuedInvoices: FC<Props> = ({
  id,
  itemData,
  title,
  invoiceObject,
  setInvoiceObject,
  requestURI,
  successMessage,
  showButtonGroup = false,
  afterSucessRequest,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const users: UsersDataListModel = useSelector<RootState>(
    (state) => state.USER_users.Users,
    shallowEqual
  ) as UsersDataListModel;

  const getUsers = () =>
    dispatch(UserRedux.actions.getUsers({ companyId: companyDetails.id, params: { per_page: 0 } }));

  useEffect(() => {
    if (id === invoiceObject?.id) {
      if (!users?.data?.length) getUsers();
    }
  }, [invoiceObject]);

  const userOptions = useMemo(
    () => users?.data?.map((user) => ({ label: user.name, value: user.uuid, email: user.email })),
    [users]
  );

  const linksOptions = useMemo(
    () => [
      {
        label: !companyDetails?.tax_number
          ? formatMessage({ id: 'full invoice' })
          : formatMessage({ id: 'full tax invoice' }),
        value: itemData.long_invoice_preview_link,
      },
      {
        label: !companyDetails?.tax_number
          ? formatMessage({ id: 'simplified invoice' })
          : formatMessage({ id: 'simplified tax invoice' }),
        value: itemData.simple_invoice_preview_link,
      },
    ],
    [itemData]
  );

  const sendReports = async (values: any, resetForm: Function) => {
    if (!requestURI) return;
    try {
      let attachmentObject = {} as {
        [key: string]: any;
      };

      for (let i = 0; i < values?.links?.length; i++) {
        attachmentObject[`${values?.links[i]?.label}`] = values?.links[i]?.value;
      }

      const formData = serialize({
        users_emails: values?.users,
        attachments: attachmentObject,
      });
      await axiosInstance.post(requestURI, formData);
      successMessage && toast.success(formatMessage({ id: successMessage }) || '');
      setInvoiceObject && setInvoiceObject(null);
      afterSucessRequest && afterSucessRequest();
      resetForm();
      dispatch(UserRedux.actions.fulfillUsers({}));
    } catch (err) {
      DisplayErrors(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      users: [] as any,
      links: [] as any,
    },
    validationSchema: Yup.object().shape({
      users: Yup.array().min(1, formatMessage({ id: 'Please choose at least one' })),
      links: Yup.array().min(1, formatMessage({ id: 'Please choose at least one' })),
    }),

    onSubmit: (values, { resetForm }) => sendReports(values, resetForm),
  });

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(UserRedux.actions.fulfillUsers({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */
  const createOption = (email: string) => ({
    email,
    value: email,
    label: email,
  });
  const handleCreate = (inputValue: string) => {
    const newOption = createOption(inputValue);
    const isValidEmail = emailPattern.test(newOption.value);
    if (isValidEmail) {
      formik.setFieldValue('users', [...formik.values?.users, newOption?.value]);
    }
  };
  return (
    <div>
      {invoiceObject && invoiceObject.id === id && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={title || 'Send invoices'}
            closeCallBack={() => {
              dispatch(UserRedux.actions.fulfillUsers({}));
              setInvoiceObject && setInvoiceObject(null);
              formik.resetForm();
            }}
            specialContainerClass='dialog-content w-600px'
          >
            <FormikProvider value={formik}>
              <Form id='create_template' noValidate autoComplete='none'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='col-12'>
                      <CreatableSelect
                        formatCreateLabel={(inputValue) => inputValue}
                        onCreateOption={handleCreate}
                        isClearable
                        onChange={(value: any) => {
                          if (!value) return;
                          formik.setFieldValue('users', [...formik.values?.users, value?.email]);
                        }}
                        onFocus={() => getUsers()}
                        placeholder={$t('Choose a user or enter an email')}
                        onBlur={() => formik.setFieldTouched('users', true)}
                        options={
                          formik.values?.users.length > 0
                            ? userOptions?.filter(
                                (user) => !formik.values?.users?.includes(user?.email)
                              )
                            : userOptions
                        }
                        formatOptionLabel={({ label, value, email }: any) => (
                          <div className='col'>
                            <div className='text-dark fw-bolder fs-8 d-inline line-clamp-1 row m-2'>
                              {label}
                            </div>
                            <br />
                            <div className='  fs-8 d-inline line-clamp-1 row m-2'>{email}</div>
                          </div>
                        )}
                        value={null}
                        className='react-select smaller'
                        id='users'
                      />
                      <ErrorMessage name='users' formik={formik} />
                    </div>
                    <div className='col-12'>
                      <div className='mt-2' style={{ ...boxStyle, overflowY: 'auto' }}>
                        <div className='row p-5'>
                          {formik.values?.users?.map((id: any) => {
                            const user: UserModel = users?.data?.find(
                              (user) => user.email === id
                            ) as UserModel;
                            return (
                              <div className='col-6'>
                                <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                                  <div>
                                    <div className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'>
                                      {user?.name}
                                    </div>
                                    <br />
                                    <div className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'>
                                      {user ? user?.email : id}
                                    </div>
                                  </div>
                                  <CloseButtonRedShadowed
                                    onClick={() =>
                                      formik.setFieldValue(
                                        'users',
                                        formik?.values?.users?.filter((item: ID) => item !== id)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <Select
                        onChange={(value: any) => formik.setFieldValue('links', value)}
                        placeholder={$t('Invoices')}
                        isMulti
                        onBlur={() => formik.setFieldTouched('links', true)}
                        options={
                          formik.values?.links?.length > 0
                            ? linksOptions.filter(
                                (link) => !formik.values?.links?.includes(link?.value)
                              )
                            : linksOptions
                        }
                        value={formik.values?.links}
                        className='react-select smaller'
                        id='links'
                      />
                      <ErrorMessage name='links' formik={formik} />
                    </div>
                  </div>

                  {showButtonGroup && (
                    <SaveCancelBtnsGroup
                      saveBtnLabel='Send invoices'
                      formik={formik}
                      containerClassName='col-xl-6'
                      loading={false}
                      cancelOnClick={() => {
                        dispatch(UserRedux.actions.fulfillUsers({}));
                        setInvoiceObject && setInvoiceObject(null);
                        formik.resetForm();
                      }}
                    />
                  )}
                </div>
              </Form>
            </FormikProvider>
          </AhadDialog>
        </Portal>
      )}

      <a
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        title={$t('Send invoices')}
        onClick={() => setInvoiceObject && setInvoiceObject(itemData)}
      >
        <i className='far fa-paper-plane svg-icon-3'></i>
      </a>
    </div>
  );
};
