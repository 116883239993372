export class TableImage {
  constructor(private fieldData: { image: string }) {}

  renderItem() {
    return (
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-circle avatar-border symbol-30px symbol-md-40px mx-3'>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          {this.fieldData.image && <img src={this.fieldData.image || ''} />}
        </div>
      </div>
    );
  }
}
