/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom'; //useParams,
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

import Select from 'react-select';
import { toast } from 'react-toastify';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';

import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CompanyModel } from '../companies/CompanyModel';

import { SeparatorLines } from '../../shared/Lines';
import { Input } from '../../shared/Input';
import { Label } from '../../shared/Label';
import { CommonTable } from '../../shared/tables/Table';
import { InfoItem } from '../../shared/tables/actions/InfoItem';
import { ViewSalesItem } from '../../shared/tables/actions/ViewSalesItem';
import { ViewPurchasesItem } from '../../shared/tables/actions/ViewPurchasesItem';
import { TableActions, TableData, TableSelection } from '../../shared/tables/classes'; // DateViewer,
import * as TableRedux from '../../store/TableRedux';

import { axiosInstance } from '../../../network/apis';

import { ErrorMessage } from '../../shared/ErrorMessage';

import {
  ReportsPeriodTypeMap,
  MonthlyReportsPeriodTypeMap,
  QuarterlyReportsPeriodTypeMap,
} from '../../../_metronic/constants/general'; //DocumentDirection,
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';

import { useConfigureInvoicesReportsSchema } from './useConfigureInvoicesReportsSchema';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';

import { initialConfigPayload } from './helpers/reportsConfigHelpers';
import * as InvoicesReportsRedux from './store/InvoicesReportsRedux';
import { SalesWrapper } from '../sales/SalesWrapper';
import { PurchasesWrapper } from '../purchases/PurchasesWrapper';

import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { HorizontalConfigNewInvoicesReportsWrapper } from './components/HorizontalConfigNewInvoicesReportsWrapper';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

const TABLE_NAME = 'transactons_list';

const headerItemsMonthly = [
  { id: 'Select', maxWidth: 150, width: 150 },
  { id: 'Month', maxWidth: 240, width: 230 },
  { id: 'Total', maxWidth: 240, width: 230 },
  { id: 'Unprocessed sales', maxWidth: 150, width: 150 },
  { id: 'Unprocessed sales amount', maxWidth: 230, width: 230 },
  { id: 'Unprocessed purchases', maxWidth: 150, width: 150 },
  { id: 'Unprocessed purchases amount', maxWidth: 250, width: 250 },
  { id: 'Tax amount', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];
const headerItemsQuarterly = [
  { id: 'Select', maxWidth: 150, width: 150 },
  { id: 'Quarter', maxWidth: 240, width: 230 },
  { id: 'Total', maxWidth: 240, width: 230 },
  { id: 'Unprocessed sales', maxWidth: 150, width: 150 },
  { id: 'Unprocessed sales amount', maxWidth: 230, width: 230 },
  { id: 'Unprocessed purchases', maxWidth: 150, width: 150 },
  { id: 'Unprocessed purchases amount', maxWidth: 250, width: 250 },
  { id: 'Tax amount', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];

export const TransactionsTable: FC<any> = ({ paramsObj, onTransactionSelection }) => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();

  /* -------------------------- Bring Data from store ------------------------- */
  const transactionsList: any = useSelector<RootState>((state) => {
    return state.USER_invoices_reports.invoicesReportTransactions;
  }, shallowEqual) as any;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[TABLE_NAME];

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;
  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(InvoicesReportsRedux.actions.fullFillInvoicesReportTransactions({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestTransactions(paramsObj: any) {
    const table = tables[TABLE_NAME];
    dispatch(
      InvoicesReportsRedux.actions.getInvoicesReportTransactions({
        companyId: companyDetails.id,
        params: {
          ...paramsObj,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    // throttle request

    if (paramsObj.month && paramsObj.year) {
      RequestTransactions(paramsObj);
    }

    // RequestData();
  }, [table?.page, table?.pagination, paramsObj]);

  const rowItems = transactionsList?.data?.map((transaction: any, index: any) => {
    return [
      new TableSelection({
        display: true,
        id: transaction.id,
        tableName: TABLE_NAME,
        disabled: transaction.selected, //auto select
      }),
      new TableData({
        text: `${transaction.date}`,
        // error: sale?.errors?.vat_rate,
      }),
      new TableData({
        text: `${transaction.total}`,
      }),
      new TableData({
        text: `${transaction.sales}`,
      }),
      new TableData({
        text: `${parseFloat(transaction.sales_amount).toFixed(2)}`,
      }),
      new TableData({
        text: `${transaction.purchases}`,
      }),
      new TableData({
        text: `${parseFloat(transaction.purchases_amount).toFixed(2)}`,
      }),
      new TableData({
        text: `${parseFloat(transaction.tax_amount).toFixed(2)}`,
      }),

      new TableActions(
        (
          <div className='d-flex flex-row'>
            {userCan('view-report') && transaction.selected && (
              <ViewSalesItem
                title={$t('Preview sales')}
                onClick={() => {
                  //  pass transaction object to parent component and call open modal function.
                  onTransactionSelection({ ...transaction, type: 'sales' });
                }}
              />
            )}
            {userCan('view-report') && (
              <ViewPurchasesItem
                title={$t('Preview purchases')}
                onClick={() => {
                  //  pass transaction object to parent component and call open modal function.
                  onTransactionSelection({ ...transaction, type: 'purchases' });
                }}
              />
            )}
            {!transaction.selected &&
              transaction.used_in_previous_report &&
              transaction.purchases > 0 && (
                <InfoItem title={$t('There is unprocessed purchases for this period.')} />
              )}
            {!transaction.selected && !transaction.used_in_previous_report && (
              <InfoItem title={$t('This period didn’t entered any approved report before.')} />
            )}
          </div>
        )
      ),
    ];
  });

  return (
    <div className='table-wrapper'>
      {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5'> */}
      {/* {userCan('delete-template') && (
          <DeleteItem
            isButton
            messageId={'Are you sure you want to delete this template?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/templates`}
            payload={deleteItemId}
            successMessage={'Template has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )} */}
      {/* </div> */}
      <CommonTable
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={false}
        allDisplayedIds={transactionsList?.data?.map((transaction: any, index: any) => index) || []} //transactionsList?.data?.map((sales) => sales.id) ||
        meta={transactionsList}
        headerItems={paramsObj?.period === 2 ? headerItemsMonthly : headerItemsQuarterly}
        rowItems={rowItems}
      />
    </div>
  );
};

export const ConfigNewInvoicesReportsWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { invoicesReportsInitialValues, invoiceReportsValidationSchema } =
    useConfigureInvoicesReportsSchema();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { userCan } = usePermissions();
  const { formatMessage, locale } = useIntl();
  const [paramsObj, setParamsObj] = useState<any>({});
  const [transactionObj, setTransactionObj] = useState<any>({});
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [showPurchasesModal, setShowPurchasesModal] = useState(false);
  const [showPreviousPurchasesConfirmationModal, setShowPreviousPurchasesConfirmationModal] =
    useState(false);
  const [previousPurchasesConfirmation, setPreviousPurchasesConfirmation] = useState<any>('');
  const [approvePreviousPurchases, setApprovePreviousPurchases] = useState<any>(null);
  const [excludeIDs, setExcludeIDs] = useState<any>([]);

  // const [attachment, setAttachment] = useState<any>('');
  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[TABLE_NAME];

  const [loading, setLoading] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  /* ----------------------------- on Transaction Selection Handler ----------------------------- */
  // pass transaction detail from child(transaction table) to parent (confihg new report)
  const onTransactionSelectionHandler = (transaction: any) => {
    setTransactionObj(transaction);
    if (transaction.type === 'sales') {
      setShowSalesModal(true);
    } else {
      setShowPurchasesModal(true);
    }
  };
  useEffect(() => {
    if (transactionObj.type === 'sales') {
      const table = tables['sales_list'];
      if (table && showSalesModal) {
        if (excludeIDs.length > 0 && table.ids && table.selection.length < excludeIDs.length) {
          dispatch(
            TableRedux.actions.bulkToggleSelection({ idList: excludeIDs, tableName: 'sales_list' })
          );
        }
      }
    } else {
      const table = tables['purchases_list'];
      if (table) {
        if (excludeIDs.length > 0 && table.ids && table.selection.length < excludeIDs.length) {
          dispatch(
            TableRedux.actions.bulkToggleSelection({
              idList: excludeIDs,
              tableName: 'purchases_list',
            })
          );
        }
      }
    }
  }, [excludeIDs, tables['sales_list']?.ids, tables['purchases_list']?.ids]);
  /* ----------------------------- create Reports ----------------------------- */

  const createReports = async (values: any, resetForm: any) => {
    setLoading(true);

    const selectedtransactionsList = table.selection;
    // const selectedtransactionsList = transactionsList?.data
    //   .filter((transaction: any, index: any) => {
    //     if (table.selection.includes(-1)) {
    //       return transaction;
    //     } else {
    //       return table.selection.includes(transaction.id);
    //     }
    //   })
    //   .map((selectedtransaction: any) => {
    //     return selectedtransaction.date;
    //   });

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    payload['name'] = { ar: '', en: '' };
    payload['name']['ar'] = values.report_name_ar ? values.report_name_ar.trim() : '';
    payload['name']['en'] = values.report_name_en ? values.report_name_en.trim() : '';
    payload['period'] = values.report_period[0]?.value;
    payload['transactions'] = selectedtransactionsList;
    payload['exclude'] = excludeIDs;
    payload['approve'] = approvePreviousPurchases;
    payload['monthly_period'] = values.monthly_period[0]?.value;
    payload['yearly_period'] = values.year_period[0]?.value;

    delete payload['report_period'];
    delete payload['report_name_ar'];
    delete payload['report_name_en'];
    delete payload['year_period'];
    // delete payload['monthly_period'];

    const formData = serialize(payload, { indices: true });
    try {
      if (mode === 'create') {
        await axiosInstance.post(`${companyDetails.id}/reports`, formData).then((res) => {
          if (res.status === 200 && res.data.message) {
            toast.success(formatMessage({ id: `${res.data.message}` }));
          } else {
            toast.success(formatMessage({ id: 'Reports has been created successfully!' }));
          }
          resetForm();
        });
      }
      //  else if (mode === 'edit') {
      //   formData.append('_method', 'PUT');
      //   await axiosInstance.post(`${companyDetails.id}/reports/${salesItem.id}`, formData);
      //   resetForm();
      //   toast.success(formatMessage({ id: 'Sales has been updated successfully!' }));
      // }
      history.push(`/c/${companyDetails.slug}/invoices-tax-module/reports`);
    } catch (err: any) {
      if (
        err &&
        err.response.status === 422 &&
        Object.keys(err.response.data.errors).includes('previous_purchases_confirmation')
      ) {
        setLoading(false);
        setShowPreviousPurchasesConfirmationModal(true);
        setPreviousPurchasesConfirmation(
          err.response.data.errors.previous_purchases_confirmation[0]
        );
      } else {
        DisplayErrors(err);
      }
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: invoicesReportsInitialValues,
    validationSchema: invoiceReportsValidationSchema,
    onSubmit: (values, { resetForm }) => createReports(values, resetForm),
  });
  const { setFieldValue } = formik; //,values

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const transactionsList: any = useSelector<RootState>((state) => {
    return state.USER_invoices_reports.invoicesReportTransactions;
  }, shallowEqual) as any;

  const reportPeriodOptions = useMemo(() => {
    return Array.from(ReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const monthlyPeriodOptions = useMemo(() => {
    return Array.from(MonthlyReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const quarterlyPeriodOptions = useMemo(() => {
    return Array.from(QuarterlyReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  function generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = max - 5;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  const yearsOptions = useMemo(() => {
    return Array.from(generateArrayOfYears())
      .flat()
      .map((option) => {
        return { value: option, label: option };
      });
  }, [locale]);

  // const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  /* ----------------------- end getting data from store ---------------------- */

  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  // set intial values for formik
  useEffect(() => {
    formik.setFieldValue(
      `report_period`,
      reportPeriodOptions?.filter((option) => {
        if (companyDetails.settings?.period_type) {
          return option.value === companyDetails.settings?.period_type;
        } else {
          return option.value === 3;
        }
      })
      // reportPeriodOptions?.filter((option) => option.value === 2)
    );
  }, [companyDetails.settings?.period_type]);

  // useEffect(() => {
  //   if (formik.values.report_period[0]?.value === 1) {
  //     formik.setFieldValue(`monthly_period`, []);
  //     formik.setFieldValue(`year_period`, []);
  //   }
  // }, [formik.values.report_period]);

  // reset table selection or select all
  // useEffect(() => {
  //   dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
  //   if (
  //     formik.values?.report_period?.[0]?.value &&
  //     formik.values?.report_period?.[0]?.value !== 1
  //   ) {
  //     dispatch(TableRedux.actions.toggleSelection({ id: -1, tableName: TABLE_NAME }));
  //   }
  // }, [formik.values.report_period, formik.values.monthly_period, formik.values.year_period]);

  // reset table selection and select default value auto select
  useEffect(() => {
    // get transaction table ids
    if (transactionsList) {
      transactionsList?.data?.forEach((element: any, index: any) => {
        if (element.selected) {
          dispatch(
            TableRedux.actions.bulkToggleSelection({
              idList: Array.from(new Set([...table.selection, element.id])),
              tableName: TABLE_NAME,
            })
          );
        }
      });
    }

    //   // dispatch(TableRedux.actions.toggleSelection({ id: 0, tableName: TABLE_NAME }));
  }, [formik.values.monthly_period, formik.values.year_period, transactionsList]);
  useEffect(() => {
    // clear selection
    dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
  }, [formik.values.monthly_period, formik.values.year_period]);

  useEffect(() => {
    // create params object
    // const paramsObj: any = {};
    if (formik.values.report_period[0]?.value) {
      setParamsObj((prevState: any) => {
        return { ...prevState, period: formik.values.report_period[0]?.value };
      });
    }
    if (formik.values.year_period[0]?.value) {
      setParamsObj((prevState: any) => {
        return { ...prevState, year: formik.values.year_period[0]?.value };
      });
    }

    if (formik.values.monthly_period[0]?.value) {
      setParamsObj((prevState: any) => {
        return { ...prevState, month: formik.values.monthly_period[0]?.value };
      });
    }
  }, [formik.values.report_period, formik.values.year_period, formik.values.monthly_period]);

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && $t('Create report')}
            {mode === 'view' && $t('Preview report')}
            {mode === 'edit' && $t('Edit report')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_reports' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-start'>
                      {/* Badges */}
                      {/* {mode !== 'create' && currentReport?.status && (
                        <div className='m-2'>{badge.renderItem()}</div>
                      )} */}
                    </div>
                    {mode === 'view' && userCan('update-report') && (
                      <div
                        className='d-flex justify-content-end'
                        style={{ textAlign: 'end', alignSelf: 'center' }}
                      >
                        <button
                          className='btn btn-primary btn-sm btn-primary-shadow'
                          title={formatMessage({ id: 'Edit reports' })}
                          onClick={() => history.push(location.pathname + '/edit')}
                        >
                          <i className='fa fa-edit cursor-pointer'></i>
                          {formatMessage({ id: 'Edit report' })}
                        </button>
                      </div>
                    )}
                  </div>

                  {/*  ---------------------  Report type select-----------------------  */}
                  {/* <div className='col-6'>
                    <Label msg='Report type' htmlFor='report_period' isRequired />
                    <Select
                      onChange={(value) => setFieldValue('report_period', [value])}
                      onBlur={() => formik.setFieldTouched('report_period', true)}
                      options={reportPeriodOptions?.filter(
                        (option) => formik.values.report_period[0]?.value !== option.value
                      )}
                      value={formik.values.report_period}
                      placeholder={$t('Report type')}
                      isDisabled={mode === 'view'}
                      className='react-select smaller'
                      id='report_period'
                    />
                    <ErrorMessage name='report_period' formik={formik} />
                  </div> */}
                  {/*  --------------------- end Report type select-----------------------  */}
                  {/*  --------------------- Year -----------------------  */}
                  {/* <div className='col-6'></div> */}
                  {/*  ------------------------ end Year -----------------------  */}
                  {/*  ------------------------ Report Name -----------------------  */}
                  {locales.map((locale) => (
                    <div className='col-md-6'>
                      <Label
                        msg={formatMessage({
                          id: 'Report name in ' + locale.toUpperCase(),
                        })}
                        htmlFor={'Report name in ' + locale.toUpperCase()}
                        isRequired
                      />

                      <Input
                        placeholder={formatMessage({
                          id: 'Report name in ' + locale.toUpperCase(),
                        })}
                        autoComplete='off'
                        // labelId={formatMessage({
                        //   id: 'Report name in ' + locale.toUpperCase(),
                        // })}
                        required
                        name={'report_name_' + locale}
                        type='text'
                        formik={formik}
                        dir={locale === 'ar' ? 'rtl' : 'ltr'}
                        // rows='6'
                        id={'Report name in ' + locale.toUpperCase()}
                        disabled={mode === 'view'}
                      />
                    </div>
                  ))}
                  {/*  --------------------- end Report Name-----------------------  */}
                  {/*  ---------------------  Report Period select-----------------------  */}
                  {formik.values.report_period[0]?.value !== 1 && (
                    <>
                      {formik.values.report_period[0]?.value === 2 && (
                        <div className='col-md-6'>
                          <Label msg='Period' htmlFor='monthly_period' />
                          <Select
                            onChange={(value) => setFieldValue('monthly_period', [value])}
                            onBlur={() => formik.setFieldTouched('monthly_period', true)}
                            options={monthlyPeriodOptions?.filter(
                              (option) => formik.values.monthly_period[0]?.value !== option.value
                            )}
                            value={formik.values.monthly_period}
                            placeholder={$t('Period')}
                            isDisabled={mode === 'view'}
                            className='react-select smaller'
                            id='monthly_period'
                          />
                          <ErrorMessage name='monthly_period' formik={formik} />
                        </div>
                      )}
                      {formik.values.report_period[0]?.value === 3 && (
                        <div className='col-md-6'>
                          <Label msg='Period' htmlFor='monthly_period' />
                          <Select
                            onChange={(value) => setFieldValue('monthly_period', [value])}
                            onBlur={() => formik.setFieldTouched('monthly_period', true)}
                            options={quarterlyPeriodOptions?.filter(
                              (option) => formik.values.monthly_period[0]?.value !== option.value
                            )}
                            value={formik.values.monthly_period}
                            placeholder={$t('Period')}
                            isDisabled={mode === 'view'}
                            className='react-select smaller'
                            id='monthly_period'
                          />
                          <ErrorMessage name='monthly_period' formik={formik} />
                        </div>
                      )}
                      <div className='col-md-6'>
                        <Label msg='Year' htmlFor='year_period' />
                        <Select
                          onChange={(value) => setFieldValue('year_period', [value])}
                          onBlur={() => formik.setFieldTouched('year_period', true)}
                          options={yearsOptions?.filter(
                            (option) => formik.values.year_period[0]?.value !== option.value
                          )}
                          value={formik.values.year_period}
                          placeholder={$t('Year')}
                          isDisabled={mode === 'view'}
                          className='react-select smaller'
                          id='year_period'
                        />
                        <ErrorMessage name='year_period' formik={formik} />
                      </div>
                      {/*  ------------------------ end Year -----------------------  */}
                    </>
                  )}
                  {/*  --------------------- end Report Period select-----------------------  */}
                </div>
              </div>
              <div className='row my-3'>
                <div className='m-auto' style={{ maxWidth: '90vw' }}>
                  <TransactionsTable
                    paramsObj={paramsObj}
                    onTransactionSelection={onTransactionSelectionHandler}
                  />
                </div>
              </div>
              {/* Save and Cancel Buttons */}
              {mode !== 'view' && (
                <div className='m-auto my-5' style={{ maxWidth: '90vw' }}>
                  <SaveCancelBtnsGroup
                    saveBtnLabel='save'
                    formik={formik}
                    loading={loading}
                    cancelOnClick={() => {
                      history.push(`/c/${companyDetails.slug}/invoices-tax-module/reports`);
                    }}
                  />
                </div>
              )}
            </Form>
          </FormikProvider>
        </div>

        {/*  --------------------- sales Modal-----------------------  */}

        {mode === 'create' && showSalesModal && (
          <Portal className='width_modal'>
            <AhadDialog
              closeCallBack={() => setShowSalesModal(false)}
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row my-3' style={{ fontSize: '1.5rem' }}>
                  <FormattedMessage id='Please select sales to exclude from report' />
                </div>
                <div className='row my-3'>
                  <SalesWrapper transactionObj={transactionObj} paramsObj={paramsObj} />
                </div>

                <div className='col-md-12'>
                  <div className='row my-3'>
                    <div className='col-md-3'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const table = tables['sales_list'];
                          // setExcludeIDs((prevState: any) => {
                          //   return [
                          //     ...new Set(
                          //       [...prevState, ...table.selection].filter((item) => item !== -1)
                          //     ),
                          //   ];
                          // });
                          setExcludeIDs([
                            ...new Set([...table.selection].filter((item) => item !== -1)),
                          ]);
                          setShowSalesModal(false);
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Exclude'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-3'>
                      <button
                        onClick={() => {
                          setShowSalesModal(false);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}
        {/*  --------------------- end sales Modal-----------------------  */}
        {/*  --------------------- puchases Modal-----------------------  */}
        {mode === 'create' && showPurchasesModal && (
          <Portal className='width_modal'>
            <AhadDialog
              closeCallBack={() => setShowPurchasesModal(false)}
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row my-3' style={{ fontSize: '1.5rem' }}>
                  <FormattedMessage id='Please select purchases to exclude from report' />
                </div>
                <div className='row my-3'>
                  <PurchasesWrapper transactionObj={transactionObj} paramsObj={paramsObj} />
                </div>

                <div className='col-md-12'>
                  <div className='row my-3'>
                    <div className='col-md-3'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const table = tables['purchases_list'];
                          // setExcludeIDs((prevState: any) => {
                          //   return [
                          //     ...new Set(
                          //       [...prevState, ...table.selection].filter((item) => item !== -1)
                          //     ),
                          //   ];
                          // });
                          setExcludeIDs([
                            ...new Set([...table.selection].filter((item) => item !== -1)),
                          ]);
                          setShowPurchasesModal(false);
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Exclude'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-3'>
                      <button
                        onClick={() => {
                          setShowPurchasesModal(false);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}

        {/*  ---------------------end puchases Modal-----------------------  */}
        {/*  ---------------------previos puchases Modal-----------------------  */}

        {showPreviousPurchasesConfirmationModal && previousPurchasesConfirmation && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={
                () => setShowPreviousPurchasesConfirmationModal(false)

                // history.push(location.pathname.replace('/invite-users', '/users'))
              }
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                  {previousPurchasesConfirmation}
                </div>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setApprovePreviousPurchases(1);
                          setShowPreviousPurchasesConfirmationModal(false);
                          formik.submitForm();
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Add'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setApprovePreviousPurchases(0);
                          setShowPreviousPurchasesConfirmationModal(false);
                          formik.submitForm();
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Exclude' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}
        {/*  ---------------------end previos puchases Modal-----------------------  */}
      </div>
    </div>
  );
};

const ConfigNewInvoicesReportsWrapperCreate: FC = () => {
  usePermissions(['create-report']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'VAT' })}</PageTitle>
      <HorizontalConfigNewInvoicesReportsWrapper />
      {/* <ConfigNewInvoicesReportsWrapper mode='create' /> */}
    </div>
  );
};

const ConfigNewInvoicesReportsWrapperEdit: FC = () => {
  usePermissions(['update-report']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'VAT' })}</PageTitle>
      <ConfigNewInvoicesReportsWrapper mode='edit' />
    </div>
  );
};

const ConfigNewInvoicesReportsWrapperView: FC = () => {
  usePermissions(['view-report']);
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'VAT' })}</PageTitle>
      <ConfigNewInvoicesReportsWrapper mode='view' />
    </div>
  );
};
export {
  ConfigNewInvoicesReportsWrapperCreate,
  ConfigNewInvoicesReportsWrapperEdit,
  ConfigNewInvoicesReportsWrapperView,
};
