import { FC, useEffect } from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/ar';

import arMessages from './messages/ar.json';
import enMessages from './messages/en.json';

import { languages, defaultLanguage } from '../constants/languages';

const allMessages = {
  ar: arMessages,
  en: enMessages,
};

const I18nProvider: FC = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  useEffect(() => {
    const body = document.querySelector('body');
    const html = document.getElementsByTagName('html');
    const title = document.getElementsByTagName('title');
    const root = document.getElementById('root');
    const style_ar: HTMLLinkElement = document.getElementById('style-link-ar') as HTMLLinkElement;
    const style_en: HTMLLinkElement = document.getElementById('style-link-en') as HTMLLinkElement;
    const language = languages.find((language) => language.locale === locale) || defaultLanguage;
    html[0].lang = language.locale;
    if (language.dir === 'rtl') {
      style_en?.parentNode?.removeChild(style_en);
      title[0].innerText = 'شبكة عهد – الشريك الضريبي للأفراد والمنشآت الصغيرة والمتوسطة';
    } else {
      style_ar?.parentNode?.removeChild(style_ar);
      title[0].innerText =
        'AHAD Network – the tax partner for individuals and small and medium enterprises';
    }
    // if (style) style.href = `/css/style.${language.dir}.min.css`;
    if (body) body.dir = language.dir;
    if (root) root.dir = language.dir;
  }, [locale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
