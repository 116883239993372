import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import SVG from 'react-inlinesvg';
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { allowedImagesTypes } from '../../../../../_metronic/constants/general';
import { axiosInstance } from '../../../../../network/apis';
import { useState } from 'react';
import AhadDialog from '../../../../../setup/shared/AhadDialog';
import { Portal } from '../../../../../_metronic/partials';

export const MultipleFilesUploader = ({
  setFile,
  file,
  maxSize,
  supportedFiles,
  disabled = false,
  maxfileUploaded,
  urlApiDelete = '',
  mode,
}) => {
  const [isDeleteInputOpen, setIsDeleteInputOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedFileObject, setDeletedFileObject] = useState(null);
  const { formatMessage } = useIntl();
  let allowedOnly = [...supportedFiles];
  const typeExel = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    '.csv',
    'text/csv',
  ];
  const typePdf = ['application/pdf'];

  const newFile = (selectedFile) => {
    const newFile = file.filter((file) => file !== selectedFile);
    setIsDeleteInputOpen(true);
    if (urlApiDelete && urlApiDelete.length > 0 && mode === 'edit' && selectedFile?.id) {
      // open delete modal
      setShowDeleteModal(true);
      setDeletedFileObject(selectedFile);
    } else {
      setFile(newFile);
      setIsDeleteInputOpen(false);
    }
  };

  const deleteFile = (selectedFile) => {
    console.log('selectedFile', selectedFile);
    console.log('files', file);
    console.log('urlApiDelete', urlApiDelete);
    // file is all files array from BE and selectedFile is the file that we want to delete
    const newFile = file.filter((file) => file !== selectedFile);

    axiosInstance
      .delete(`${urlApiDelete}/${selectedFile?.id}`)
      .then((response) => {
        console.log(response);
        setFile(newFile);
        setIsDeleteInputOpen(false);
        setShowDeleteModal(false);
        setDeletedFileObject(null);
      })
      .catch((error) => {
        console.log(error);
        setIsDeleteInputOpen(false);
        setShowDeleteModal(false);
        setDeletedFileObject(null);
      });
  };

  const downloadImage = async (imageSrc, nameOfDownload) => {
    console.log(imageSrc, nameOfDownload);
    const anchorElement = document.createElement('a');
    anchorElement.download = nameOfDownload;
    anchorElement.href = imageSrc;
    anchorElement.target = '_target';
    anchorElement.click();
  };

  return (
    <>
      <div className='file-input d-block' style={{ backgroundColor: 'inherit' }}>
        <input
          style={{
            zIndex: '0',
          }}
          type='file'
          multiple
          accept={allowedOnly}
          onChange={(e) => {
            const files = Array.from(e.target.files).filter((file, index) => {
              if (file?.size / 1024 > maxSize) {
                toast.error(
                  formatMessage(
                    { id: 'The maximum file size is {maxSize}MB' },
                    { maxSize: `${maxSize / 1024}` }
                  ),
                  { toastId: 'maximumSizeError' }
                );
                return false;
              } else if (index + 1 > maxfileUploaded) {
                toast.error(
                  formatMessage(
                    { id: 'The maximum number of files is {maxfileUploaded}' },
                    { maxfileUploaded: maxfileUploaded }
                  ),
                  { toastId: 'maximumFilesError' }
                );
                return false;
              } else {
                return true;
              }
            });
            if (files?.length > 0) {
              const checkingType = files.map((item) => item.type === 'image/webp');
              files.map(
                (item) =>
                  item.type === 'image/webp' &&
                  toast.error(
                    'بإمكانك رفع الملفات بالصيغ التالية فقط: JPG, SVG, PNG, JPEG, PDF, xsl, xsls',
                    { toastId: 'checkingType' }
                  )
              );
              console.log(checkingType);

              const res = files.filter((item) => item.type !== 'image/webp');
              setFile((prev) => [...prev, ...res]);
            }
          }}
          disabled={disabled || maxfileUploaded === file?.length}
        />
        {/* ------------------------------- Button View ------------------------------ */}

        {!disabled && (
          <button
            type='button'
            className='btn btn-primary'
            disabled={disabled || maxfileUploaded === file?.length}
          >
            {formatMessage({ id: 'choose-file' })}
          </button>
        )}
        {!disabled && (
          <span
            className='label'
            data-js-label
            style={{
              paddingInline: '8px',
            }}
          >
            {file?.length === 0 && <FormattedMessage id='No file selected' />}
          </span>
        )}
        {/* ------------------------------- Button View ------------------------------ */}
      </div>

      {/* ------------------------------- thump Files ------------------------------  */}
      {file &&
        file?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr auto',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '10px',
                }}
              >
                {/* ---------------------------------- Thump ---------------------------------  */}
                <div
                  style={{
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {typePdf.includes(item?.type) ? (
                    <SVG
                      src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                      className='cursor-pointer'
                    />
                  ) : typeExel.includes(item?.type) ? (
                    <SVG
                      src={toAbsoluteUrl('/media/svg/files/xlsx_icon.svg')}
                      className='cursor-pointer'
                      style={{ width: '62px' }}
                    />
                  ) : allowedImagesTypes.includes(item?.type) ? (
                    <img
                      src={URL.createObjectURL(item)}
                      alt='FIle'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  ) : null}

                  {item.url && (
                    <>
                      {item.url.includes('.pdf') ||
                      item.url.includes('.xlsx') ||
                      item.url.includes('.xls') ||
                      item.url.includes('.csv') ? (
                        <a href={item?.url} download>
                          {item.url.includes('.pdf') && (
                            <SVG
                              src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                              className='cursor-pointer'
                            />
                          )}
                          {(item.url.includes('.xls') ||
                            item.url.includes('.xlsx') ||
                            item.url.includes('.csv')) && (
                            <SVG
                              src={toAbsoluteUrl('/media/svg/files/xlsx_icon.svg')}
                              className='cursor-pointer'
                              style={{ width: '62px' }}
                            />
                          )}
                        </a>
                      ) : (
                        <div
                          style={{
                            width: '60px',
                            height: '60px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => downloadImage(item?.url, 'file')}
                        >
                          <img
                            src={item?.url}
                            alt='FIle'
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* ---------------------------------- Thump ---------------------------------  */}

                <p>
                  {item.url && item.url.split('/')[item.url.split('/').length - 1]}
                  {item?.name}
                </p>

                {!disabled && (
                  <button
                    type='button'
                    disabled={isDeleteInputOpen}
                    onClick={() => newFile(item)}
                    style={{
                      border: 'none',
                      background: 'transparent',
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-1 svg-icon-gray-600'
                    />
                  </button>
                )}
              </div>
            </div>
          );
        })}
      {/* ------------------------------- thump Files ------------------------------  */}

      {showDeleteModal && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={'Delete'}
            closeCallBack={() => {
              setShowDeleteModal(false);
              setIsDeleteInputOpen(false);
              setDeletedFileObject(null);
            }}
            specialContainerClass='dialog-content w-600px'
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                <FormattedMessage id='Are you sure you want to delete the attached file?' />
              </div>

              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        console.log('Confirm delete', deletedFileObject);
                        deleteFile(deletedFileObject);
                      }}
                    >
                      <span className='indicator-label'>
                        <FormattedMessage id={'Confirm'} />
                      </span>
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={() => {
                        setShowDeleteModal(false);
                        setIsDeleteInputOpen(false);
                        setDeletedFileObject(null);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
    </>
  );
};
