import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as DocumentsRedux from '../../modules/documents/store/DocumentsRedux';
// import * as DocumentsRedux from './store/DocumentsRedux';
import { axiosInstance } from '../../../network/apis';
import { useQuerySearch } from '../../hooks/useQuerySearch/useQuerySearch';
import { DocumentComments } from '../comments/DocumentComments';
import {
  NonRegisteredClientDocumentTypes,
  paramsType,
} from './types/NonRegisteredClientDocumentTypes';

export function NonRegisteredClientDocument() {
  const query: any = useQuerySearch();
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();

  /* --------------------------- Viewer only  stuff --------------------------- */

  if (!NonRegisteredClientDocumentTypes[params.type as paramsType]) {
    history.push('/');
  }

  const requestDocument = async () =>

    await axiosInstance(`/auth/documents/${params.documentId}`, {
      params: { version: query.get('version') },
    })
      .then((res) => res.data)
      .catch((error) => {
        toast.error(error.response.message);
        if (error.response.status !== 200) {
          history.push('/');
        }
      });

  const requestDocumentComments = async () => {
    if (NonRegisteredClientDocumentTypes[params.type as paramsType] === 'sign') {
      return await axiosInstance(`documents/${params.documentId}/comments`).then(
        (res) => res.data.data
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: document, refetch: refetchDoc } = useQuery('public-document', requestDocument);

  const { data: documentComments } = useQuery('document-comments', requestDocumentComments);

  useEffect(() => {
    if (documentComments) {
      dispatch(DocumentsRedux.actions.fulfillDocumentsComments({ comments: documentComments }));
    }
  }, [documentComments]);


  const sendComment = async (comment: any) => {
    const response = await axiosInstance.post(`/documents/${params.documentId}/comments`, comment);
    dispatch(DocumentsRedux.actions.addNewComment({ comment: response.data }));
  };
  return (
    <div>
      {/* -------------------------- SIGNER - Second Party ------------------------- */}
      {NonRegisteredClientDocumentTypes[params.type as paramsType] === 'sign' &&
        document?.preview_link && (
          <DocumentComments
            document={document}
            comments={documentComments}
            sendComment={sendComment}
            refetchDoc={refetchDoc}
          />
        )}

      {/* ------------------------------- Viewer Only ------------------------------ */}
      {NonRegisteredClientDocumentTypes[params.type as paramsType] === 'display' &&
        document?.preview_link && (
          <DocumentComments
            document={document}
            comments={[]}
            viewOnly
            sendComment={sendComment}
            refetchDoc={refetchDoc}
          />
        )}
    </div>
  );
}
