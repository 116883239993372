import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import {
  getAllInvoicesWithholdingReports,
  getInvoicesWithholdingReport,
  getAllInvoicesWithholdingTransactions,
  getAllInvoicesWithholdingPurchasesTransactions,
} from './InvoicesWithholdingReportsCRUD';

export enum IInvoicesWithholdingReportsActionTypes {
  GET_INVOICES_WITHHOLDING_REPORTS = 'GET_INVOICES_WITHHOLDING_REPORTS',
  FULL_FILL_INVOICES_WITHHOLDING_REPORTS = 'FULL_FILL_INVOICES_WITHHOLDING_REPORTS',
  GET_CURRENT_INVOICES_WITHHOLDING_REPORT = 'GET_CURRENT_INVOICES_WITHHOLDING_REPORT',
  FULL_FILL_CURRENT_INVOICES_WITHHOLDING_REPORT = 'FULL_FILL_CURRENT_INVOICES_WITHHOLDING_REPORT',
  GET_INVOICES_WITHHOLDING_TRANSACTIONS = 'GET_INVOICES_WITHHOLDING_TRANSACTIONS',
  FULL_FILL_INVOICES_WITHHOLDING_TRANSACTIONS = 'FULL_FILL_INVOICES_WITHHOLDING_TRANSACTIONS',
  GET_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS = 'GET_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS',
  FULL_FILL_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS = 'FULL_FILL_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS',
}

const initialInvoicesWithholdingReports: IInvoicesWithholdingReportsState = {
  invoicesWithholdingReports: undefined,
  currentInvoicesWithholdingReport: undefined,
  invoicesWithholdingReportTransactions: undefined,
  invoicesWithholdingReportPurchasesTransactions: undefined,
};

export interface IInvoicesWithholdingReportsState {
  invoicesWithholdingReports?: any;
  currentInvoicesWithholdingReport?: any;
  invoicesWithholdingReportTransactions?: any;
  invoicesWithholdingReportPurchasesTransactions?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_invoices_withholding_reports', whitelist: [''] },
  (
    state: IInvoicesWithholdingReportsState = initialInvoicesWithholdingReports,
    action: ActionWithPayload<IInvoicesWithholdingReportsState>
  ) => {
    switch (action.type) {
      case IInvoicesWithholdingReportsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_REPORTS:
        return { ...state, invoicesWithholdingReports: action.payload?.invoicesWithholdingReports };
      case IInvoicesWithholdingReportsActionTypes.FULL_FILL_CURRENT_INVOICES_WITHHOLDING_REPORT:
        return {
          ...state,
          currentInvoicesWithholdingReport: action.payload?.currentInvoicesWithholdingReport,
        };
      case IInvoicesWithholdingReportsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_TRANSACTIONS:
        return {
          ...state,
          invoicesWithholdingReportTransactions:
            action.payload?.invoicesWithholdingReportTransactions,
        };
      case IInvoicesWithholdingReportsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS:
        return {
          ...state,
          invoicesWithholdingReportPurchasesTransactions:
            action.payload?.invoicesWithholdingReportPurchasesTransactions,
        };
      default:
        return state;
    }
  }
);

export const actions = {
  getInvoicesWithholdingReports: ({ companyId, params }: Search) => ({
    type: IInvoicesWithholdingReportsActionTypes.GET_INVOICES_WITHHOLDING_REPORTS,
    payload: { companyId, params },
  }),
  fullFillInvoicesWithholdingReports: ({
    invoicesWithholdingReports,
  }: IInvoicesWithholdingReportsState) => ({
    type: IInvoicesWithholdingReportsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_REPORTS,
    payload: { invoicesWithholdingReports },
  }),
  getCurrentInvoicesWithholdingReport: ({ companyId, reportId }: any) => ({
    type: IInvoicesWithholdingReportsActionTypes.GET_CURRENT_INVOICES_WITHHOLDING_REPORT,
    payload: { companyId, reportId },
  }),
  fullFillCurrentInvoicesWithholdingReport: ({
    currentInvoicesWithholdingReport,
  }: IInvoicesWithholdingReportsState) => ({
    type: IInvoicesWithholdingReportsActionTypes.FULL_FILL_CURRENT_INVOICES_WITHHOLDING_REPORT,
    payload: { currentInvoicesWithholdingReport },
  }),
  getInvoicesWithholdingTransactions: ({ companyId, params }: Search) => ({
    type: IInvoicesWithholdingReportsActionTypes.GET_INVOICES_WITHHOLDING_TRANSACTIONS,
    payload: { companyId, params },
  }),
  fullFillInvoicesWithholdingTransactions: ({
    invoicesWithholdingReportTransactions,
  }: IInvoicesWithholdingReportsState) => ({
    type: IInvoicesWithholdingReportsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_TRANSACTIONS,
    payload: { invoicesWithholdingReportTransactions },
  }),
  getInvoicesWithholdingPurchasesTransactions: ({ companyId, params }: Search) => ({
    type: IInvoicesWithholdingReportsActionTypes.GET_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS,
    payload: { companyId, params },
  }),
  fullFillInvoicesWithholdingPurchasesTransactions: ({
    invoicesWithholdingReportPurchasesTransactions,
  }: IInvoicesWithholdingReportsState) => ({
    type: IInvoicesWithholdingReportsActionTypes.FULL_FILL_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS,
    payload: { invoicesWithholdingReportPurchasesTransactions },
  }),
};

export function* getInvoicesWithholdingReports(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllInvoicesWithholdingReports({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillInvoicesWithholdingReports({ invoicesWithholdingReports: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentInvoicesWithholdingReport(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getInvoicesWithholdingReport({
      companyId: action.payload?.companyId,
      reportId: action.payload?.reportId,
    });
    yield put(
      actions.fullFillCurrentInvoicesWithholdingReport({ currentInvoicesWithholdingReport: data })
    );
  } catch (error) {
    //
  }
}

export function* getInvoicesWithholdingTransactions(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllInvoicesWithholdingTransactions({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(
      actions.fullFillInvoicesWithholdingTransactions({
        invoicesWithholdingReportTransactions: data,
      })
    );
  } catch (error) {
    //
  }
}

export function* getInvoicesWithholdingPurchasesTransactions(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllInvoicesWithholdingPurchasesTransactions({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(
      actions.fullFillInvoicesWithholdingPurchasesTransactions({
        invoicesWithholdingReportPurchasesTransactions: data,
      })
    );
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(
    IInvoicesWithholdingReportsActionTypes.GET_INVOICES_WITHHOLDING_REPORTS,
    getInvoicesWithholdingReports
  );
  yield takeLatest(
    IInvoicesWithholdingReportsActionTypes.GET_INVOICES_WITHHOLDING_TRANSACTIONS,
    getInvoicesWithholdingTransactions
  );
  yield takeLatest(
    IInvoicesWithholdingReportsActionTypes.GET_CURRENT_INVOICES_WITHHOLDING_REPORT,
    getCurrentInvoicesWithholdingReport
  );
  yield takeLatest(
    IInvoicesWithholdingReportsActionTypes.GET_INVOICES_WITHHOLDING_PURCHASES_TRANSACTIONS,
    getInvoicesWithholdingPurchasesTransactions
  );
}
