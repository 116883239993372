import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  formik: any;
  loading: boolean;
  showSaveBtn?: boolean;
  showApproveBtn?: boolean;
  saveBtnLabel?: string;
  approveBtnLabel?: string;
  saveOnClick?: any;
  approveOnClick?: any;
  cancelOnClick?: any;
  id?: string;
  containerClassName?: string;
  disabled?: boolean;
};
const SaveApproveCancelBtnsGroup: FC<Props> = ({
  formik,
  loading,
  showSaveBtn,
  showApproveBtn,
  saveBtnLabel,
  approveBtnLabel,
  saveOnClick,
  approveOnClick,
  cancelOnClick,
  id,
  containerClassName,
  disabled,
}) => {
  return (
    <div className={clsx('mt-4', containerClassName, { 'col-xl-8': !containerClassName })}>
      <div className='row'>
        {showSaveBtn && (
          <div className='col-md-4'>
            <button
              type='button'
              id='save_button'
              onClick={() => saveOnClick()}
              className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
              disabled={formik.isSubmitting || disabled}
            >
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id={saveBtnLabel || 'save'} />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  <FormattedMessage id='Please wait...' />
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
        {showApproveBtn && (
          <div className='col-md-4'>
            <button
              type='button'
              id='approve_button'
              className='btn btn-sm w-100 btn-success btn-shadow btn-success-shadow'
              onClick={() => approveOnClick()}
              disabled={formik.isSubmitting || disabled}
            >
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id={approveBtnLabel || 'approve'} />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  <FormattedMessage id='Please wait...' />
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
        <div className='col-md-4'>
          <button
            onClick={() => cancelOnClick()}
            type='button'
            id='cancel_button'
            className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
          >
            <FormattedMessage id='Cancel' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaveApproveCancelBtnsGroup;
