/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl'; //FormattedMessage,
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

// import { Alert } from 'react-bootstrap-v5';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';
import { DisplayErrors } from '../../../utils/DisplayErrors';

// import { PrivateRoutes } from '../../../_metronic/constants/ROUTES';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { SeparatorLines } from '../../shared/Lines';
import { CompanyModel } from '../companies/CompanyModel';
import { endpoints, ID } from '../../../_metronic/constants/paths';
import { ErrorMessage } from '../../shared/ErrorMessage';
import * as TemplatesRedux from '../templates/store/TemplatesRedux';
import { DocumentDirection, RecipientTypeMap } from '../../../_metronic/constants/general';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';

import * as UserRedux from '../../modules/users/store/UsersRedux';
import * as ClientsRedux from '../../modules/clients/store/ClientsRedux';

import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { axiosInstance } from '../../../network/apis';
import { useQuery } from 'react-query';

import { createOptions } from '../../../utils/createOptions';
import { initialAllConfigPayload } from './helpers/templateConfigHelpers';
import { UsersDataListModel } from '../users/types/usersList';
import { UserModel } from '../auth/models/UserModel';
import { ClientsDataListModel, IClient } from '../clients/types/clientsList';
import { useConfigureTemplateSchema } from './useConfigureTemplateSchema';
import { Input } from '../../shared/Input';
import { Label } from '../../shared/Label';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import { CloseButtonRedShadowed } from '../../shared/CloseButtonRedShadowed';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';

import { boxStyle } from './boxStyle';
import { LOCALE } from '../../../_metronic/helpers/typescript';
import { DocumentDirectionTypes } from '../../../_metronic/constants/general';

type Props = {
  mode: 'edit' | 'view';
};

export const TemplateConfiguration: FC<Props> = ({ mode }) => {
  const dispatch = useDispatch();
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { allConfigValidationSchema, allConfigInitialValues } = useConfigureTemplateSchema();

  const history = useHistory();
  const location = useLocation();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();

  const [loading, setLoading] = useState(false);
  // display and Hide the unused form depending on the template type INTERNAL OR EXTERNAL
  const [templateTypeNumber, setTemplateTypeNumber] = useState(1);
  // display and Hide the unused form depending on the reciepent type SIGNATURES NEEDED
  const [reciepentTypeNumber, setReciepentTypeNumber] = useState(1);

  /* ----------------------------- Getting Templates ----------------------------- */
  const getCurrentTemplate = async () => {
    try {
      await axiosInstance(endpoints.getCompanyTemplate(companyDetails.id, params?.templateId)).then(
        (res) => {
          const values = res.data;

          const { clients, users, editor } = values; //creators, reviewers, signers,

          const newUnderConstruction = {
            values,
            users: users?.map((user: any) => user.id),
            clients: clients?.map((client: any) => client.id),
            sections: editor?.sections,
            variables: editor?.variables,
            ...values,
          };
          dispatch(
            TemplatesRedux.actions.fulfillUnderConstructionTemplate({
              underConstructionTemplate: newUnderConstruction,
            })
          );
        }
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };

  const createTemplate = async (values: any, resetForm: any) => {
    setLoading(true);

    // TODO: make new object with document recipent types in general.ts file to be more redable
    if (templateTypeNumber === 1 || templateTypeNumber === 2 || templateTypeNumber === 4) {
      if (reciepentTypeNumber === 1) {
        values = { ...values, clients: [] };
      } else if (reciepentTypeNumber === 2) {
        values = { ...values, users: [] };
      }
      if (templateTypeNumber === 4) {
        values = { ...values, signers: [] };
      }
    } else if (templateTypeNumber === 3) {
      values = { ...values, users: [], clients: [] };
    } else if (templateTypeNumber === 5) {
      values = { ...values, users: [], clients: [], signers: [], reviewers: [] };
    }

    const payload: { [key: string]: any } = initialAllConfigPayload(values);
    if (
      values.users
        .map((user: any) => user)
        .some((user: any) => values.signers.map((signer: any) => signer).includes(user)) &&
      (underConstructionTemplate.type === DocumentDirectionTypes.Internal ||
        reciepentTypeNumber === 1)
    ) {
      // in case of internal and 2nd party list includes signers
      setLoading(false);
      return toast.error(formatMessage({ id: "UserCant'tBeSecondParty" }));
    }
    // to stop recieving notification
    if (
      underConstructionTemplate.clients.map((client: any) => client.id).toString() ===
      values.clients.toString()
    ) {
      delete payload.clients;
    }
    if (
      underConstructionTemplate.users.map((user: any) => user.id).toString() ===
      values.users.toString()
    ) {
      delete payload.users;
    }

    const formData = serialize(payload, { indices: true });

    if (templateTypeNumber === 1 || templateTypeNumber === 2 || templateTypeNumber === 4) {
      if (reciepentTypeNumber === 1) {
        formData.append('clients', '');
      } else if (reciepentTypeNumber === 2) {
        formData.append('users', '');
      }
      if (templateTypeNumber === 4) {
        formData.append('signers', '');
      }
    } else if (templateTypeNumber === 3) {
      formData.append('users', '');
      formData.append('clients', '');
    } else if (templateTypeNumber === 5) {
      formData.append('users', '');
      formData.append('clients', '');
      formData.append('signers', '');
      formData.append('reviewers', '');
    }
    // to stop recieving notification

    if (
      underConstructionTemplate.clients.map((client: any) => client.id).toString() ===
      values.clients.toString()
    ) {
      formData.delete('clients');
    }
    if (
      underConstructionTemplate.users.map((user: any) => user.id).toString() ===
      values.users.toString()
    ) {
      formData.delete('users');
    }

    try {
      let response: AxiosResponse;
      if (params.templateId) {
        formData.append('_method', 'PUT');
        response = await axiosInstance.post(
          endpoints.updateCompanyTemplates(companyDetails.id, underConstructionTemplate?.id),
          formData
        );
      } else {
        response = await axiosInstance.post(
          endpoints.companyTemplates(companyDetails.id),
          formData
        );
      }
      const { id } = response.data;

      // const newUnderConstruction: TemplatesRedux.UnderConstructionTemplate = {
      //   ...payload,
      //   id: values?.id || id,
      //   values,
      // } as TemplatesRedux.UnderConstructionTemplate;

      const newUnderConstruction: TemplatesRedux.UnderConstructionTemplate = {
        ...underConstructionTemplate,
        ...response.data,
      } as TemplatesRedux.UnderConstructionTemplate;

      dispatch(
        TemplatesRedux.actions.fulfillUnderConstructionTemplate({
          underConstructionTemplate: newUnderConstruction,
        })
      );
      resetForm();
      toast.success(formatMessage({ id: 'Template configuration has been updated successfully!' }));

      // setReciepentTypeNumber(underConstructionTemplate.type);
      // setTemplateTypeNumber(underConstructionTemplate.recipient_type);
      if (!underConstructionTemplate?.has_content) {
        history.push(
          location.pathname.replace(`/preview-configurations/${id}/edit`, `/update/${id}`)
        );
      } else {
        history.goBack();
      }
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: allConfigInitialValues,
    validationSchema: allConfigValidationSchema,
    onSubmit: (values, { resetForm }) => createTemplate(values, resetForm),
  });
  const { setFieldValue, values } = formik;

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;

  const users: UsersDataListModel = useSelector<RootState>(
    (state) => state.USER_users.Users,
    shallowEqual
  ) as UsersDataListModel;

  const getUsers = () =>
    dispatch(UserRedux.actions.getUsers({ companyId: companyDetails.id, params: { per_page: 0 } }));

  const clients: ClientsDataListModel = useSelector<RootState>(
    (state) => state.USER_clients.Clients,
    shallowEqual
  ) as ClientsDataListModel;

  const { isLoading: signersLoading, data: signersList } = useQuery(
    'Signers',
    () =>
      axiosInstance(`/${companyDetails.id}/users`, {
        params: {
          permissions: 'sign-document',
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  const { isLoading: creatorsLoading, data: creatorsList } = useQuery(
    'Creators',
    () =>
      axiosInstance(`/${companyDetails.id}/users`, {
        params: {
          permissions: 'create-document',
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  const { isLoading: reviewersLoading, data: reviewersList } = useQuery(
    'Reviewers',
    () =>
      axiosInstance(`/${companyDetails.id}/users`, {
        params: {
          permissions: 'review-document',
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  const getClients = () =>
    dispatch(
      ClientsRedux.actions.getClients({ companyId: companyDetails.id, params: { per_page: 0 } })
    );

  const typesOptions = useMemo(
    () =>
      Array.from(RecipientTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  const userOptions = useMemo(
    () => users?.data?.map((user) => ({ label: user.name, value: user.id })),
    [users]
  );

  const clientsOptions = useMemo(
    () =>
      clients?.data?.map((client) => ({
        label: client?.name?.[locale as LOCALE],
        value: client.id,
      })),
    [clients]
  );
  const signerOptions = useMemo(() => createOptions(signersList), [signersList]);

  const creatorsOptions = useMemo(() => createOptions(creatorsList), [creatorsList]);

  const reviewersOptions = useMemo(() => createOptions(reviewersList), [reviewersList]);

  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    if (params?.templateId) {
      getUsers();
      getClients();
      getCurrentTemplate();
    }
  }, [params?.templateId]);

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    if (Number(params?.templateId) === underConstructionTemplate?.id) {
      setFieldValue('id', underConstructionTemplate.id);
      setFieldValue('title', underConstructionTemplate.title);
      setFieldValue('type', underConstructionTemplate.type);
      setFieldValue(
        'users',
        underConstructionTemplate.users.map((user: any) => user.id)
      );
      setFieldValue(
        'clients',
        underConstructionTemplate.clients.map((client: any) => client.id)
      );
      setFieldValue(
        'reviewers',
        underConstructionTemplate?.reviewers?.map((reviewer: any) => reviewer.id)
      );
      setFieldValue(
        'signers',
        underConstructionTemplate?.signers?.map((signer: any) => signer.id)
      );
      setFieldValue(
        'creators',
        underConstructionTemplate?.creators?.map((signer: any) => signer.id)
      );
      setFieldValue(
        'recipient_type',
        typesOptions.find((item) => item.value === underConstructionTemplate.recipient_type)
      );
      setReciepentTypeNumber(underConstructionTemplate.type);
      setTemplateTypeNumber(underConstructionTemplate.recipient_type);
    }
  }, [underConstructionTemplate]);

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'view' && $t('Preview template configuration')}
            {mode === 'edit' && $t('Edit template configuration')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_template' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                {mode === 'view' && (
                  <div className='row  '>
                    <div className='d-flex justify-content-end'>
                      <button
                        className='btn btn-primary btn-sm btn-primary-shadow'
                        title={formatMessage({ id: 'Edit template configuration' })}
                        onClick={() => history.push(location.pathname + '/edit')}
                      >
                        <i className='fa fa-edit cursor-pointer'></i>
                        {formatMessage({ id: 'Edit template configuration' })}
                      </button>
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='col-6'>
                    <Label
                      msg='Template Title'
                      htmlFor='template_title'
                      isRequired={mode === 'edit'}
                    />
                    <Input
                      disabled={mode === 'view'}
                      placeholder={$t('Template Title')}
                      autoComplete='off'
                      formik={formik}
                      name={`title`}
                      id='template_title'
                    />
                  </div>
                  <div className='col-6'>
                    <Label
                      msg='Template Type'
                      htmlFor='recipient_type'
                      isRequired={mode === 'edit'}
                    />
                    <Select
                      isDisabled={mode === 'view' || !underConstructionTemplate?.can_update}
                      onChange={(value) => {
                        setTemplateTypeNumber(value.value);
                        return setFieldValue('recipient_type', value);
                      }}
                      onBlur={() => formik.setFieldTouched('recipient_type', true)}
                      options={typesOptions}
                      value={formik.values.recipient_type}
                      className='react-select smaller'
                      id='recipient_type'
                    />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <Label msg='Second party type' isRequired={mode === 'edit'} />
                    <div className='d-flex gap-3 mt-1'>
                      {Array.from(DocumentDirection.values())
                        .flat()
                        .map((type) => (
                          <span className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input mx-2'
                              type='radio'
                              name='appStorage'
                              id={type.label}
                              checked={values.type === type.value}
                              value={`${type.value}`}
                              disabled={mode === 'view'}
                              onChange={(e) => {
                                setReciepentTypeNumber(type.value);
                                return setFieldValue('type', type.value);
                              }}
                            />
                            <Label msg={type.label} htmlFor={type.label} />
                          </span>
                        ))}
                    </div>
                  </div>
                </div>

                {(templateTypeNumber === 1 ||
                  templateTypeNumber === 2 ||
                  templateTypeNumber === 4) && (
                  <>
                    {mode === 'edit' && (
                      <div className='mt-2 row'>
                        {reciepentTypeNumber === 1 && (
                          <div className='col-12'>
                            <Select
                              onChange={(value) =>
                                setFieldValue('users', [...values?.users, value?.value])
                              }
                              onFocus={() => getUsers()}
                              placeholder={$t('Users')}
                              onBlur={() => formik.setFieldTouched('users', true)}
                              options={userOptions?.filter(
                                (option) => !values?.users?.includes(option?.value)
                              )}
                              value={null}
                              className='react-select smaller'
                              id='users'
                            />
                            <ErrorMessage name='users' formik={formik} />
                          </div>
                        )}
                        {reciepentTypeNumber === 2 && (
                          <div className='col-12'>
                            <Select
                              onChange={(value) =>
                                setFieldValue('clients', [...values.clients, value?.value])
                              }
                              placeholder={$t('Clients')}
                              onFocus={() => getClients()}
                              onBlur={() => formik.setFieldTouched('clients', true)}
                              options={clientsOptions?.filter(
                                (option) => !values?.clients?.includes(option?.value)
                              )}
                              value={null}
                              className='react-select smaller'
                              id='clients'
                            />
                            <ErrorMessage name='clients' formik={formik} />
                          </div>
                        )}
                      </div>
                    )}
                    <div className='row d-flex gap-3 mt-3'>
                      {(reciepentTypeNumber === 1 ||
                        (values.type === 1 && values.users.length > 0)) && (
                        <div className='col-12 '>
                          <Label msg='Second party List' isRequired={mode === 'edit'} />
                          <div className='mt-0' style={{ ...boxStyle, overflowY: 'auto' }}>
                            <div className='row p-5'>
                              {values?.users?.map((id: number) => {
                                const user: UserModel = users?.data?.find(
                                  (user) => user?.id === id
                                ) as UserModel;

                                return (
                                  <div className='col-6'>
                                    <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                                      <div>
                                        <div className='symbol symbol-circle avatar-border symbol-20px symbol-md-30px me-1 '>
                                          <img
                                            alt=''
                                            src={
                                              user?.image ||
                                              toAbsoluteUrl('/media/avatars/blank.png')
                                            }
                                          />
                                        </div>

                                        <p
                                          className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'
                                          style={{ minHeight: '30px' }}
                                        >
                                          {user?.name}
                                        </p>
                                      </div>
                                      {mode === 'edit' && (
                                        <CloseButtonRedShadowed
                                          onClick={() =>
                                            setFieldValue(
                                              'users',
                                              values.users.filter((item: ID) => item !== id)
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                      {(reciepentTypeNumber === 2 ||
                        (values.type === 2 && values.clients.length > 0)) && (
                        <div className='col-12'>
                          <Label msg='Second party List' isRequired={mode === 'edit'} />
                          <div className='mt-0' style={{ ...boxStyle, overflowY: 'auto' }}>
                            <div className='row p-5'>
                              {values?.clients?.map((id: number) => {
                                const client: IClient = clients?.data.find(
                                  (user) => user.id === id
                                ) as IClient;
                                return (
                                  <div className='col-6'>
                                    <div className='userBox p-2 mt-1 d-flex justify-content-between'>
                                      <a
                                        className='text-dark fw-bolder fs-6'
                                        style={{ minHeight: '30px' }}
                                      >
                                        {client?.name?.[locale as LOCALE]}
                                      </a>
                                      {mode === 'edit' && (
                                        <CloseButtonRedShadowed
                                          onClick={() => {
                                            setFieldValue(
                                              'clients',
                                              [...values.clients].filter((item: ID) => item !== id)
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {/* reviewers, signersand creator */}
                <div className='row d-flex gap-3 mt-3'>
                  {templateTypeNumber !== 5 && (
                    <>
                      {/* Reviewers */}
                      {mode === 'edit' && (
                        <div className='mt-2 '>
                          <div className='col-12'>
                            <Select
                              onChange={(value) =>
                                setFieldValue('reviewers', [...values?.reviewers, value?.value])
                              }
                              onFocus={() => getUsers()}
                              placeholder={$t('Reviewers')}
                              onBlur={() => formik.setFieldTouched('reviewers', true)}
                              options={reviewersOptions?.filter(
                                (option) => !values?.reviewers?.includes(option?.value)
                              )}
                              isLoading={reviewersLoading}
                              value={null}
                              className='react-select smaller'
                              id='reviewers'
                            />
                            <ErrorMessage name='reviewers' formik={formik} />
                          </div>
                        </div>
                      )}
                      {(mode === 'edit' || (values.reviewers && values.reviewers.length > 0)) && (
                        <div className='col-12 '>
                          <Label msg='Reviewers List' isRequired={mode === 'edit'} />
                          <div className='mt-0' style={{ ...boxStyle, overflowY: 'auto' }}>
                            <div className='row p-5'>
                              {values?.reviewers?.map((id: number) => {
                                const user: UserModel = users?.data?.find(
                                  (user) => user?.id === id
                                ) as UserModel;

                                return (
                                  <div className='col-6'>
                                    <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                                      <div>
                                        <div className='symbol symbol-circle avatar-border symbol-20px symbol-md-30px me-1'>
                                          <img
                                            alt=''
                                            src={
                                              user?.image ||
                                              toAbsoluteUrl('/media/avatars/blank.png')
                                            }
                                          />
                                        </div>

                                        <p
                                          className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'
                                          style={{ minHeight: '30px' }}
                                        >
                                          {user?.name}
                                        </p>
                                      </div>
                                      {mode === 'edit' && (
                                        <CloseButtonRedShadowed
                                          onClick={() =>
                                            setFieldValue(
                                              'reviewers',
                                              values.reviewers.filter((item: ID) => item !== id)
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                      {templateTypeNumber !== 4 && (
                        <>
                          {/* Signers */}
                          {mode === 'edit' && (
                            <div className='mt-2 '>
                              <div className='col-12'>
                                <Select
                                  onChange={(value) =>
                                    setFieldValue('signers', [...values?.signers, value?.value])
                                  }
                                  onFocus={() => getUsers()}
                                  placeholder={$t('Signers')}
                                  onBlur={() => formik.setFieldTouched('signers', true)}
                                  options={signerOptions?.filter(
                                    (option) => !values?.signers?.includes(option?.value)
                                  )}
                                  isLoading={signersLoading}
                                  value={null}
                                  className='react-select smaller'
                                  id='signers'
                                />
                                <ErrorMessage name='signers' formik={formik} />
                              </div>
                            </div>
                          )}
                          {(mode === 'edit' || (values.signers && values.signers.length > 0)) && (
                            <div className='col-12 '>
                              <Label msg='Signers List' isRequired={mode === 'edit'} />
                              <div className='mt-0' style={{ ...boxStyle, overflowY: 'auto' }}>
                                <div className='row p-5'>
                                  {values?.signers?.map((id: number) => {
                                    const user: UserModel = users?.data?.find(
                                      (user) => user?.id === id
                                    ) as UserModel;

                                    return (
                                      <div className='col-6'>
                                        <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                                          <div>
                                            <div className='symbol symbol-circle avatar-border symbol-20px symbol-md-30px me-1'>
                                              <img
                                                alt=''
                                                src={
                                                  user?.image ||
                                                  toAbsoluteUrl('/media/avatars/blank.png')
                                                }
                                              />
                                            </div>

                                            <p
                                              className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'
                                              style={{ minHeight: '30px' }}
                                            >
                                              {user?.name}
                                            </p>
                                          </div>
                                          {mode === 'edit' && (
                                            <CloseButtonRedShadowed
                                              onClick={() =>
                                                setFieldValue(
                                                  'signers',
                                                  values.signers.filter((item: ID) => item !== id)
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {/* Creators */}
                  {mode === 'edit' && (
                    <div className='mt-2 '>
                      <div className='col-12'>
                        <Select
                          onChange={(value) =>
                            setFieldValue('creators', [...values?.creators, value?.value])
                          }
                          onFocus={() => getUsers()}
                          placeholder={$t('Creators')}
                          onBlur={() => formik.setFieldTouched('creators', true)}
                          options={creatorsOptions?.filter(
                            (option) => !values?.creators?.includes(option?.value)
                          )}
                          isLoading={creatorsLoading}
                          value={null}
                          className='react-select smaller'
                          id='creators'
                        />
                        <ErrorMessage name='creators' formik={formik} />
                      </div>
                    </div>
                  )}
                  {(mode === 'edit' || (values.creators && values.creators.length > 0)) && (
                    <div className='col-12 '>
                      <Label msg='Creators List' isRequired={mode === 'edit'} />
                      <div className='mt-0' style={{ ...boxStyle, overflowY: 'auto' }}>
                        <div className='row p-5'>
                          {values?.creators?.map((id: number) => {
                            const user: UserModel = users?.data?.find(
                              (user) => user?.id === id
                            ) as UserModel;

                            return (
                              <div className='col-6'>
                                <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                                  <div>
                                    <div className='symbol symbol-circle avatar-border symbol-20px symbol-md-30px me-1'>
                                      <img
                                        alt=''
                                        src={
                                          user?.image || toAbsoluteUrl('/media/avatars/blank.png')
                                        }
                                      />
                                    </div>

                                    <p
                                      className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'
                                      style={{ minHeight: '30px' }}
                                    >
                                      {user?.name}
                                    </p>
                                  </div>
                                  {mode === 'edit' && (
                                    <CloseButtonRedShadowed
                                      onClick={() =>
                                        setFieldValue(
                                          'creators',
                                          values.creators.filter((item: ID) => item !== id)
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* Save and Cancel Buttons */}
                {mode === 'edit' && (
                  <>
                    <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.goBack();
                      }}
                    />
                    {/* <button
                      type='submit'
                      className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
                    >
                      {!loading && $t('save')}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow m-5'
                      onClick={() => history.goBack()}
                    >
                      {$t('Cancel')}
                    </button> */}
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const TemplateConfigurationEdit: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Template Configuration' })}</PageTitle>
      <TemplateConfiguration mode='edit' />
    </div>
  );
};

const TemplateConfigurationView: FC = () => {
  const { formatMessage } = useIntl();
  // const history = useHistory();
  // const location = useLocation();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Template Configuration' })}</PageTitle>
      <TemplateConfiguration mode='view' />
    </div>
  );
};

export { TemplateConfigurationEdit, TemplateConfigurationView };
