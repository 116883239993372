import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'; //
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { PageTitle } from '../../../_metronic/layout/core';
// import { Button } from '../../shared/Button';
import { usePermissions } from '../../hooks/usePermissions';
import { KTSVG } from '../../../_metronic/helpers';
import { RootState } from '../../../setup/redux/Store';
import {
  TableActions,
  TableData,
  TableSelection,
  DateViewer,
  TableStatus,
} from '../../shared/tables/classes'; // DateViewer,

import { reportStatusColorsMap } from '../../../_metronic/constants/colors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { SerachFilters } from '../../shared/SearchFilters';
import { PreviewItemOptions } from '../../shared/tables/actions/PreviewItemOptions';

import * as TableRedux from '../../store/TableRedux';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { CancelItem } from '../../shared/tables/actions/CancelItem';
import { Label } from '../../shared/Label';
import { ErrorMessage } from '../../shared/ErrorMessage';
import Select from 'react-select';

// import { LOCALE } from '../../../_metronic/helpers/typescript';
import { axiosInstance } from '../../../network/apis';
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';

import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';

import { CompanyModel } from '../companies/CompanyModel';

import * as InvoicesReportsRedux from './store/InvoicesReportsRedux';
import { IInvoicesReport, InvoicesReportsDataListModel } from './types/InvoicesReportsModel';
import { ReportsStatusTypeMap, ReportsPeriodTypesMap } from '../../../_metronic/constants/general';
import { CommonTable2 } from '../../shared/tables/Table2';
import { SendApprovedReports } from '../../shared/tables/actions/SendApprovedReports';
import { PreviewPDF } from '../../shared/tables/actions/PreviewPDF';
import { backendBaseUrl } from '../../../_metronic/constants/paths';
import {
  MonthlyReportsPeriodTypeMap,
  QuarterlyReportsPeriodTypeMap,
} from '../../../_metronic/constants/general';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { initialConfigPayload } from './helpers/reportsConfigHelpers';

const TABLE_NAME = 'invoices_reports_list';

const headerItems = [
  //   { id: 'ID', maxWidth: 150, width: 150 },
  { id: 'Report name', maxWidth: 240, width: 230 },
  { id: 'Report type', maxWidth: 240, width: 230 },
  { id: 'Start Date', maxWidth: 240, width: 230 },
  { id: 'End Date', maxWidth: 240, width: 230 },
  { id: 'Number of Sales', maxWidth: 150, width: 150 },
  { id: 'Number of Purchases', maxWidth: 250, width: 250 },
  { id: 'Net Tax', maxWidth: 150, width: 150 },
  { id: 'Status', maxWidth: 230, width: 230 },
  // { id: 'Amount', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 150, width: 150 },
];

export const InvoicesReports: FC = () => {
  return (
    <div>
      <InvoicesReportsTable />
    </div>
  );
};

export const InvoicesReportsTable: FC = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [reportID, setreportID] = useState<any>(null);
  const [reportObject, setReportObject] = useState<any>(null);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [showExportTransactionStatementModal, setShowExportTransactionStatementModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  /* -------------------------- Bring Data from store ------------------------- */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const reportsList: InvoicesReportsDataListModel = useSelector<RootState>((state) => {
    return state.USER_invoices_reports.invoicesReports;
  }, shallowEqual) as InvoicesReportsDataListModel;

  const ReportsStatusTypeOptions = useMemo(
    () =>
      Array.from(ReportsStatusTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  const monthlyPeriodOptions = useMemo(() => {
    return Array.from(MonthlyReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const quarterlyPeriodOptions = useMemo(() => {
    return Array.from(QuarterlyReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  function generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = max - 5;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  const yearsOptions: any[] = useMemo(() => {
    return Array.from(generateArrayOfYears())
      .flat()
      .map((option) => {
        return { value: option, label: `${option}` };
      });
  }, [locale]);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(InvoicesReportsRedux.actions.fullFillInvoicesReports({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      InvoicesReportsRedux.actions.getInvoicesReports({
        companyId: companyDetails?.id,
        params: {
          is_withholding: 0,
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (table) {
      RequestData();
    }
  }, [table?.page, table?.pagination]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  const cancelReport = async (reportId: any) => {
    const formData = serialize({}, { indices: true });
    formData.append('status', '3');
    formData.append('_method', 'PUT');
    await axiosInstance.post(`${companyDetails.id}/reports/vat/${reportId}`, formData);
    RequestData();
    toast.success(formatMessage({ id: 'Report has been canceled successfully!' }));
    setShowModal(false);
  };

  const exportTransactionStatement = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    payload['month'] = values.monthly_period[0]?.value;
    payload['year'] = values.year_period[0]?.value;

    delete payload.year_period;

    const formData = serialize(payload, { indices: true });
    try {
      await axiosInstance
        .post(`${companyDetails.id}/reports/vat/transaction-statement`, formData, {
          responseType: 'blob',
        })
        .then((response) => {
          let file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          let fileURL = URL.createObjectURL(file);

          // create <a> tag dinamically
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;

          // it forces the name of the downloaded file
          fileLink.download = 'Ahad_transaction-statement';

          // triggers the click event
          fileLink.click();
        })
        .then(() => {
          setShowExportTransactionStatementModal(false);
          resetForm();
          toast.success(
            formatMessage({ id: 'Transaction statement has been created successfully!' })
          );
        });
    } catch (err: any) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const required = $t('This field is required');

  const formik = useFormik({
    initialValues: {
      monthly_period: [],
      year_period: [],
    },
    validationSchema: Yup.object().shape({
      monthly_period: Yup.array()
        .required(required)
        .min(1, formatMessage({ id: 'Please choose at least one' })),
      year_period: Yup.array()
        .required(required)
        .min(1, formatMessage({ id: 'Please choose at least one' })),
    }),

    onSubmit: (values, { resetForm }) => exportTransactionStatement(values, resetForm),
  });
  const { setFieldValue } = formik; //,values

  const rowItems = reportsList?.data.map((report: IInvoicesReport, index) => {
    const ReportsPeriodTypesMapTranslated = {
      text: formatMessage({ id: `${ReportsPeriodTypesMap.get(report?.report_type)}` }) || '',
    };

    return [
      new TableSelection({
        display: userCan('delete-report'),
        id: report.id,
        tableName: TABLE_NAME,
      }),
      new TableData({ text: report?.name ? `${report.name?.[locale]}` : '' }),
      new TableData(ReportsPeriodTypesMapTranslated),
      new DateViewer({ date: report.start_date }),
      new DateViewer({ date: report.end_date }),
      new TableData({
        text: `${report.total_sales}`,
      }),
      new TableData({
        text: `${report.total_purchases}`,
      }),
      new TableData({
        text: `${parseFloat(report.total_tax).toFixed(2)}`,
      }),
      // new TableData({
      //   text: `${report.status}`,
      //   error: report.status,
      // }),

      new TableStatus({
        statusId: report.status,
        badge: reportStatusColorsMap?.get(report.status),
      }),

      new TableActions(
        (
          <div className='d-flex flex-row'>
            {userCan('view-report') && (
              <ViewItem
                title={$t('View')}
                onClick={() =>
                  history.push(`/c/${companyDetails.slug}/invoices-tax-module/reports/${report.id}`)
                }
              />
            )}
            {userCan('update-report') &&
              !(report.status === 'ملغي' || report.status === 'Canceled') && (
                <EditItem
                  title={$t('Edit')}
                  onClick={() =>
                    history.push(
                      `/c/${companyDetails.slug}/invoices-tax-module/reports/${report.id}/edit`
                    )
                  }
                />
              )}

            {userCan('delete-report') &&
              (report.status === 'مسودة' || report.status === 'Draft') && (
                <DeleteItem
                  id={report.id}
                  title={$t('Delete')}
                  messageId={'Are you sure you want to delete this report?'}
                  showButtonGroup={true}
                  setDeleteItemId={setDeleteItemId}
                  deleteItemId={deleteItemId}
                  deleteURI={`/${companyDetails.id}/reports/vat`}
                  payload={deleteItemId}
                  successMessage={'Report has been deleted successfully!'}
                  TABLE_NAME={TABLE_NAME}
                  afterSucessRequest={() => RequestData()}
                />
              )}

            {userCan('update-report') &&
              (report.status === 'مقبول' || report.status === 'Approved') &&
              report.can_cancel && (
                <CancelItem
                  title={$t('Cancel report')}
                  onClick={async () => {
                    setreportID(report.id);
                    setShowModal(true);
                  }}
                />
              )}
            {userCan('view-report') &&
              (report?.report_preview_link ||
                report?.declaration_preview_link ||
                report?.vat_package_link ||
                report?.vat_transactions_log_link) && (
                <PreviewItemOptions
                  title={$t('Preview')}
                  reportLinkes={{
                    'Detailed document': report.report_preview_link,
                    'Short document': report.declaration_preview_link,
                    'Detailed table': report.vat_package_link,
                    'Transactions table': report.vat_transactions_log_link,
                    'Transaction Statement': report.vat_transactions_statement_link,
                  }}
                  // onClick={() =>
                  //   window.open(
                  //     report.report_preview_link,
                  //     '_blank',
                  //     'toolbar=yes,scrollbars=yes,resizable=yes'
                  //   )
                  // }
                />
              )}
            {userCan('view-report') && (report.status === 'مسودة' || report.status === 'Draft') && (
              <PreviewPDF
                id='preview_draft_report_PDF'
                title={$t('Download report file')}
                onClick={() =>
                  window.open(
                    `${backendBaseUrl}/reports/${report?.id}/preview?lang=${locale}`,
                    '_blank',
                    'toolbar=yes,scrollbars=yes,resizable=yes'
                  )
                }
              />
            )}
            {userCan('send-report') &&
              (report.status === 'مقبول' || report.status === 'Approved') && (
                <SendApprovedReports
                  id={report.id}
                  itemData={report}
                  title={$t('Send reports')}
                  requestURI={`/${companyDetails.id}/vat-report/attachments/send`}
                  showButtonGroup={true}
                  setReportObject={setReportObject}
                  reportObject={reportObject}
                  successMessage={'Report/s has been sent successfully!'}
                  afterSucessRequest={() => RequestData()}
                />
              )}
          </div>
        )
      ),
    ];
  });
  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}

          {/*  ------------------------ start Action button ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
            </button>
            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                  {$t('Actions')}{' '}
                </div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}

              {/* begin::Menu item */}

              {userCan('create-report') && (
                <div className='menu-item px-3 py-3'>
                  <button
                    className='menu-link px-3 btn w-md-190px'
                    onClick={() => {
                      history.push(`/c/${params.companySlug}/invoices-tax-module/reports/create`);
                    }}
                  >
                    <span className='menu-icon'>
                      <i className='fas fa-mail-bulk'></i> &nbsp;
                    </span>
                    <FormattedMessage id='Create report' />
                  </button>
                </div>
              )}

              {/* end::Menu item */}

              {/* begin::Menu item */}

              {userCan('create-report') && (
                <div className='menu-item px-3 py-3'>
                  <button
                    className='menu-link px-3 btn w-md-190px'
                    onClick={() => {
                      setShowExportTransactionStatementModal(true);
                    }}
                  >
                    <span className='menu-icon'>
                      <i className='fas fa-arrow-down'></i> &nbsp;
                    </span>
                    <FormattedMessage id='Export Transaction Statement' />
                  </button>
                </div>
              )}

              {/* end::Menu item */}
            </div>
            {/* end::Menu 2 */}
            {/* end::Menu */}
          </div>

          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'status',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'status',
                      })
                    ),
                  placeholderId: 'Report status',
                  options: ReportsStatusTypeOptions,
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          initPerPage={20}
          headerWithCheckbox={userCan('delete-report')}
          allDisplayedIds={reportsList?.data?.map((report) => report.id) || []}
          meta={reportsList}
          headerItems={headerItems}
          rowItems={rowItems}
        />
      </div>
      {showModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                <FormattedMessage id='Are you sure you want to cancel this report?' />
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        cancelReport(reportID);
                      }}
                    >
                      <span className='indicator-label'>
                        <FormattedMessage id={'Confirm'} />
                      </span>
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}

      {showExportTransactionStatementModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowExportTransactionStatementModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={
              <>
                <FormattedMessage id='Export Transaction Statement' />
              </>
            }
          >
            <>
              <div className='row mb-8 mt-2' style={{ width: '90%', minWidth: '60vw' }}>
                <div className='d-flex px-5 '>
                  <FormikProvider value={formik}>
                    <Form
                      id='create_reports'
                      noValidate
                      autoComplete='none'
                      style={{ width: '100%' }}
                    >
                      <div className='row my-3'>
                        {/*  ---------------------  Report Period select-----------------------  */}
                        {+companyDetails.settings?.period_type !== 1 && (
                          <>
                            {+companyDetails.settings?.period_type === 2 && (
                              <div className='col-md-6'>
                                <Label msg='Period' htmlFor='monthly_period' />
                                <Select
                                  onChange={(value) => setFieldValue('monthly_period', [value])}
                                  onBlur={() => formik.setFieldTouched('monthly_period', true)}
                                  options={monthlyPeriodOptions}
                                  value={formik.values.monthly_period}
                                  placeholder={$t('Period')}
                                  className='react-select smaller'
                                  id='monthly_period'
                                />
                                <ErrorMessage name='monthly_period' formik={formik} />
                              </div>
                            )}
                            {+companyDetails.settings?.period_type === 3 && (
                              <div className='col-md-6'>
                                <Label msg='Period' htmlFor='monthly_period' />
                                <Select
                                  onChange={(value) => setFieldValue('monthly_period', [value])}
                                  onBlur={() => formik.setFieldTouched('monthly_period', true)}
                                  options={quarterlyPeriodOptions}
                                  value={formik.values.monthly_period}
                                  placeholder={$t('Period')}
                                  className='react-select smaller'
                                  id='monthly_period'
                                />
                                <ErrorMessage name='monthly_period' formik={formik} />
                              </div>
                            )}
                            <div className='col-md-6'>
                              <Label msg='Year' htmlFor='year_period' />
                              <Select
                                onChange={(value) => setFieldValue('year_period', [value])}
                                onBlur={() => formik.setFieldTouched('year_period', true)}
                                options={yearsOptions}
                                value={formik.values.year_period}
                                placeholder={$t('Year')}
                                className='react-select smaller'
                                id='year_period'
                              />
                              <ErrorMessage name='year_period' formik={formik} />
                            </div>
                            {/*  ------------------------ end Year -----------------------  */}
                          </>
                        )}
                        {/*  --------------------- end Report Period select-----------------------  */}
                      </div>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // cancelReport(reportID);
                        formik.submitForm();
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'save'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={() => {
                        setShowExportTransactionStatementModal(false);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
    </>
  );
};

const InvoicesReportsWrapper: FC = () => {
  usePermissions(['view-report']);

  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'VAT' })}</PageTitle>
      <InvoicesReports />
    </div>
  );
};

export { InvoicesReportsWrapper };
