import { ICompany } from '../../../../types/companies';
import { axiosInstance } from '../../../../../network/apis';

export function getAllCompanies({ params }: { params?: any }) {
  return axiosInstance.get<Array<ICompany>>(`/admin/companies`, {
    params,
  });
}

export function updateStatus({ id, status, message}: { id?: number, status?: boolean, message?: string}) {
  return axiosInstance.post<ICompany>(`/admin/companies/${id}/activate`, {
    status, message,
  });
}