import { useState, useMemo, FC } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { Portal } from '../../../../_metronic/partials';
import AhadDialog from '../../../../setup/shared/AhadDialog';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { SaveCancelBtnsGroup } from '../../SaveCancelBtnsGroup';
import { KTSVG } from '../../../../_metronic/helpers';
import { axiosInstance } from '../../../../network/apis';

type Props = {
  id?: string | number | null;
  itemData?: any; // reports object
  title?: string;
  approveItemObject?: any;
  setApproveItemObject?: Function;
  requestURI?: string;
  messageId?: string;
  successMessage?: string;
  showButtonGroup?: boolean;
  afterSucessRequest?: Function;
};

export const ApproveCompaniesDeletionRequests: FC<Props> = ({
  id,
  itemData,
  title,
  approveItemObject,
  setApproveItemObject,
  requestURI,
  messageId,
  successMessage,
  showButtonGroup = false,
  afterSucessRequest,
}) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  const approveDeletionRequest = async (values: any, resetForm: any, setSubmitting: any) => {
    if (!requestURI) return;
    setSubmitting(true);
    setLoading(true);
    try {
      const formData = serialize({
        company_id: itemData?.company?.id,
      });
      await axiosInstance.post(requestURI, formData);
      successMessage && toast.success(formatMessage({ id: successMessage }) || '');
      setApproveItemObject && setApproveItemObject(null);
      afterSucessRequest && afterSucessRequest();
    } catch (error) {
      DisplayErrors(error);
    } finally {
      setSubmitting(false);
      setLoading(false);
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: useMemo(() => Yup.object().shape({}), []),

    onSubmit: (values, { resetForm, setSubmitting }) =>
      approveDeletionRequest(values, resetForm, setSubmitting),
  });

  return (
    <div>
      {approveItemObject && approveItemObject.id === id && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={title || 'Approve'}
            closeCallBack={() => {
              setApproveItemObject && setApproveItemObject(null);
            }}
            specialContainerClass='dialog-content w-600px'
          >
            <FormikProvider value={formik}>
              <Form id='create_template' noValidate autoComplete='none'>
                <div className='d-flex flex-column'>
                  <div>
                    {messageId && (
                      <h2>
                        <FormattedMessage id={messageId} />
                      </h2>
                    )}
                  </div>

                  {showButtonGroup && (
                    <SaveCancelBtnsGroup
                      saveBtnLabel='Approve'
                      formik={formik}
                      containerClassName='col-xl-6'
                      loading={loading}
                      cancelOnClick={() => {
                        setApproveItemObject && setApproveItemObject(null);
                      }}
                    />
                  )}
                </div>
              </Form>
            </FormikProvider>
          </AhadDialog>
        </Portal>
      )}

      <a
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        title={$t('Approve')}
        onClick={() => setApproveItemObject && setApproveItemObject(itemData)}
      >
        <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-3' />
      </a>
    </div>
  );
};
