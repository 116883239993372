/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import * as Yup from 'yup';

import { Input } from '../../../shared/Input';
import { useHistory, useLocation } from 'react-router-dom';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';
import { axiosInstance } from '../../../../network/apis';
import { useQuerySearch } from '../../../hooks/useQuerySearch/useQuerySearch';

const initialValues = {
  password: '',
  password_confirmation: '',
};

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const query = useQuerySearch();

  useEffect(() => {
    if (!['token', 'email'].every((key) => query.has(key))) {
      toast.error(formatMessage({ id: 'invalid_url_we_will_redirect_you' }));
      history.push('/');
    }
  }, []);

  const { formatMessage } = useIntl();

  const loginSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .min(
            commonValidations.passwordMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.passwordMinimum }
            )
          )
          .max(
            commonValidations.passwordMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.passwordMaximum }
            )
          )
          .required(formatMessage({ id: 'This field is required' })),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          formatMessage({ id: 'Passwords should be matched' })
        ),
      }),
    [formatMessage]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post(`reset-password${location.search}`, {
          ...values,
        });
        toast.success(response.data.status);
        formik.resetForm();
        history.push('/');
      } catch (error: any) {
        if (error.response.status === 404) {
          toast.error(formatMessage({ id: 'invalid_url_we_will_redirect_you' }));
          history.push('/');
        }
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  return (
    <form
      noValidate
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-lg-16 pb-lg-10 p-md-10'
      style={{ backgroundImage: 'linear-gradient(to bottom, #fafbfd, #fff)', borderRadius: '30px' }}
      autoComplete='off'
      onSubmit={formik.handleSubmit}
      id='kt_login_signin_form'
      data-testid='sign-in-form'
    >
      <div className='fv-row mb-10'>
        <Input
          placeholder={formatMessage({ id: 'Password' })}
          autoComplete='off'
          labelId='Password'
          formik={formik}
          name='password'
          type='password'
        />
        <Input
          placeholder={formatMessage({ id: 'Confirm Password' })}
          autoComplete='off'
          labelId='Confirm Password'
          formik={formik}
          name='password_confirmation'
          type='password'
        />
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm w-100 mb-5 btn-primary btn-shadow btn-primary-shadow'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='Reset your password' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Please wait...' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
