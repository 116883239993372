import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import { getAllSales, getSale } from './SalesCRUD';

export enum ISalesActionTypes {
  GET_SALES = 'GET_SALES',
  FULL_FILL_SALES = 'FULL_FILL_SALES',
  GET_CURRENT_SALE = 'GET_CURRENT_SALE',
  FULL_FILL_CURRENT_SALE = 'FULL_FILL_CURRENT_SALES',
  GET_CURRENT_REFERENCE_SALE = 'GET_CURRENT_REFERENCE_SALE',
  FULL_FILL_CURRENT_REFERENCE_SALE = 'FULL_FILL_CURRENT_REFERENCE_SALE',
}

const initialSales: ISalesState = {
  sales: undefined,
  currentSale: undefined,
  currentReferenceSale: undefined,
};

export interface ISalesState {
  sales?: any;
  currentSale?: any;
  currentReferenceSale?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_sales', whitelist: [''] },
  (state: ISalesState = initialSales, action: ActionWithPayload<ISalesState>) => {
    switch (action.type) {
      case ISalesActionTypes.FULL_FILL_SALES:
        return { ...state, sales: action.payload?.sales };
      case ISalesActionTypes.FULL_FILL_CURRENT_SALE:
        return { ...state, currentSale: action.payload?.currentSale };
      case ISalesActionTypes.FULL_FILL_CURRENT_REFERENCE_SALE:
        return { ...state, currentReferenceSale: action.payload?.currentReferenceSale };
      default:
        return state;
    }
  }
);

export const actions = {
  getSales: ({ companyId, params }: Search) => ({
    type: ISalesActionTypes.GET_SALES,
    payload: { companyId, params },
  }),
  fullFillSales: ({ sales }: ISalesState) => ({
    type: ISalesActionTypes.FULL_FILL_SALES,
    payload: { sales },
  }),
  getCurrentSale: ({ companyId, saleId }: any) => ({
    type: ISalesActionTypes.GET_CURRENT_SALE,
    payload: { companyId, saleId },
  }),
  fullFillCurrentSale: ({ currentSale }: ISalesState) => ({
    type: ISalesActionTypes.FULL_FILL_CURRENT_SALE,
    payload: { currentSale },
  }),
  getCurrentReferenceSale: ({ companyId, saleId }: any) => ({
    type: ISalesActionTypes.GET_CURRENT_REFERENCE_SALE,
    payload: { companyId, saleId },
  }),
  fullFillCurrentReferenceSale: ({ currentReferenceSale }: ISalesState) => ({
    type: ISalesActionTypes.FULL_FILL_CURRENT_REFERENCE_SALE,
    payload: { currentReferenceSale },
  }),
};

export function* getSales(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllSales({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillSales({ sales: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentSale(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getSale({
      companyId: action.payload?.companyId,
      saleId: action.payload?.saleId,
    });
    yield put(actions.fullFillCurrentSale({ currentSale: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentReferenceSale(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getSale({
      companyId: action.payload?.companyId,
      saleId: action.payload?.saleId,
    });
    yield put(actions.fullFillCurrentReferenceSale({ currentReferenceSale: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ISalesActionTypes.GET_SALES, getSales);
  yield takeLatest(ISalesActionTypes.GET_CURRENT_SALE, getCurrentSale);
  yield takeLatest(ISalesActionTypes.GET_CURRENT_REFERENCE_SALE, getCurrentReferenceSale);
}
