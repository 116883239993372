/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom'; //useLocation,
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

import { toast } from 'react-toastify';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';

import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CompanyModel } from '../companies/CompanyModel';

import { SeparatorLines } from '../../shared/Lines';
import { DownloadButton } from '../../shared/DownloadButton';
import { FileUploader } from '../../pages/Companies/CompanyDetails/components/FileUploader';

import { axiosInstance } from '../../../network/apis';

import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';

type Props = {
  mode: 'create'; //| 'edit' | 'view'
};

export const ImportNewSalesWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [file, setFile] = useState<any>('');

  const [loading, setLoading] = useState(false);

  /* ----------------------------- Getting Templates ----------------------------- */

  const importSales = async () => {
    setLoading(true);

    const formData = serialize({}, { indices: true });
    if (file) {
      formData.append('file', file);
    }
    await axiosInstance
      .post(`${companyDetails.id}/old_sales/import`, formData)
      .then((res) => {
        toast.success(
          formatMessage({
            id: 'File is uploaded successfully. Data will be shown once the processing is done',
          })
        );
        history.push(`/c/${companyDetails.slug}/tax-module/sales`);
        return res;
      })
      .catch((err) => {
        formData.delete('file');
        setFile('');
        DisplayErrors(err);
        return err;
      });

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => importSales(),
  });

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  /* ----------------------- end getting data from store ---------------------- */

  /* -------------------------------- On Init -------------------------------- */

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && $t('Import sales')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <DownloadButton
          fa='arrow-down'
          onClick={
            () =>
              window.open(
                `${process.env.REACT_APP_BACKEND_BASE_URL?.replace(
                  /\/api$/,
                  ``
                )}/Ahad_import-old-sales-template.xlsx`

              )
            // window.open('https://api.ahadtest.com/Ahad_import-sales-template.xlsx')
          }
          nameId='Template'
        />
        <SeparatorLines lines={2} />
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_template' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  {/*  ------------------------sales file -----------------------  */}
                  <div className='col-md-6' style={{ maxWidth: '40%' }}>
                    <FileUploader
                      setFile={setFile}
                      file={file}
                      maxSize={2 * 1024}
                      supportedFiles={[
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                        '.csv',
                        'text/csv',
                      ]}
                      typesOnly='xlsx'
                    />
                  </div>
                  {/*  ------------------------end sales file -----------------------  */}
                </div>

                {/* Save and Cancel Buttons */}
                {mode === 'create' && (
                  <>
                    <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.push(`/c/${companyDetails.slug}/tax-module/sales`);
                      }}
                    />
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const ImportNewSalesWrapperCreate: FC = () => {
  usePermissions(['create-sale']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales' })}</PageTitle>
      {/* <div> Coming Soon </div> */}
      <ImportNewSalesWrapper mode='create' />
    </div>
  );
};

// const ImportNewSalesWrapperEdit: FC = () => {
//   const { formatMessage } = useIntl();
//   return (
//     <div className='d-flex flex-column flex-root'>
//       <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales' })}</PageTitle>
//       <ImportNewSalesWrapper mode='edit' />
//     </div>
//   );
// };

// const ImportNewSalesWrapperView: FC = () => {
//   const { formatMessage } = useIntl();

//   return (
//     <div className='d-flex flex-column flex-root'>
//       <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales' })}</PageTitle>
//       <ImportNewSalesWrapper mode='view' />
//     </div>
//   );
// };
export { ImportNewSalesWrapperCreate }; //ImportNewSalesWrapperEdit, ImportNewSalesWrapperView
