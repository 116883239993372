/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { languages } from '../../_metronic/constants/languages';
import { setWholePageDirection } from '../../_metronic/helpers';
import { setLanguage } from '../../_metronic/i18n/Metronici18n';

export const LocaleToggler = () => {
  const { locale } = useIntl();

  const currentLanguage = languages.find((x) => x.locale === locale);

  return (
    <>
      {languages.map(
        (language) =>
          language.locale !== currentLanguage?.locale && (
            <div
              className='menu-item px-3'
              key={language.locale}
              onClick={() => {
                setLanguage(language.locale);
                setWholePageDirection(language);
              }}
            >
              <a
                href='#'
                className={clsx('menu-link d-flex px-5', {
                  active: language.locale === currentLanguage?.locale,
                })}
              >
                <span className='symbol symbol-20px me-4'>
                  <img className='rounded-1' src={language.flag} alt='metronic' />
                </span>
                {language.name}
              </a>
            </div>
          )
      )}
    </>
  );
};
