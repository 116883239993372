import { FC, useEffect, useMemo, useState } from 'react'; //useMemo,
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { serialize } from 'object-to-formdata';

import { RootState } from '../../../setup/redux/Store';
import * as TableRedux from '../../store/TableRedux';
import * as SalesInvoicesRedux from './store/SalesInvoicesRedux';
import { axiosInstance } from '../../../network/apis';

import { KTSVG } from '../../../_metronic/helpers';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { CompanyModel } from '../companies/CompanyModel';
import {
  SalesInvoicesErrorTypes,
  SalesInvoicesState,
  SalesInvoicesTypeMap,
  SalesInvoicesTypesMap,
} from '../../../_metronic/constants/general';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';

import { SerachFilters } from '../../shared/SearchFilters';
import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import { usePermissions } from '../../hooks/usePermissions';
import { Button } from '../../shared/Button';

import { SalesInvoiceTypes, SalesInvoiceListModel } from './types/salesInvoiceList';
import { CommonTable2 } from '../../shared/tables/Table2';
import { TableSelection } from '../../shared/tables/classes/TableSelection';
import { DateViewer, TableActions, TableData } from '../../shared/tables/classes';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';
import { PriceViewer } from '../../shared/tables/classes/PriceViewer';
// import { TableImage } from '../../shared/tables/classes/Image';
import { LOCALE } from '../../../_metronic/helpers/typescript';
import { ErrorItemDanger } from '../../shared/tables/actions/ErrorItemDanger';
import { ErrorItemWarning } from '../../shared/tables/actions/ErrorItemWarning';
import { toast } from 'react-toastify';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import DownloadButton from '../../shared/DownloadButton';
import { SendIssuedInvoices } from '../../shared/tables/actions/SendIssuedInvoices';
import { backendBaseUrl } from '../../../_metronic/constants/paths';
import { ErrorItemInfo } from '../../shared/tables/actions/ErrorItemInfo';

const headerItems = [
  { id: 'Invoice number', maxWidth: 230, width: 230 },
  { id: 'Invoice date', maxWidth: 230, width: 230 },
  { id: 'Invoice amount', maxWidth: 230, width: 230 },
  { id: 'Client Name', maxWidth: 230, width: 230 },
  { id: 'Tax amount', maxWidth: 230, width: 230 },
  { id: 'Invoice type', maxWidth: 230, width: 230 },
  { id: 'Invoice Description', maxWidth: 230, width: 230 },
  { id: 'Number of items', maxWidth: 230, width: 230 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];
const headerItemsWithoutActions = [
  { id: 'Select', maxWidth: 150, width: 150 },
  { id: 'Invoice number', maxWidth: 230, width: 230 },
  { id: 'Invoice date', maxWidth: 230, width: 230 },
  { id: 'Invoice amount', maxWidth: 230, width: 230 },
  { id: 'Client Name', maxWidth: 230, width: 230 },
  { id: 'Tax amount', maxWidth: 230, width: 230 },
  { id: 'Invoice type', maxWidth: 230, width: 230 },
  { id: 'Invoice Description', maxWidth: 230, width: 230 },
  { id: 'Number of items', maxWidth: 230, width: 230 },
];
const TABLE_NAME = 'sales_invoices_list';

export const Invoices: FC<any> = ({ transactionObj, paramsObj }) => {
  return (
    <>
      <InvoicesTable transactionObj={transactionObj} paramsObj={paramsObj} />
    </>
  );
};

export const InvoicesTable: FC<any> = ({ transactionObj, paramsObj }) => {
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  const [invoiceObject, setInvoiceObject] = useState<any>(null);

  const [editItemId, setEditItemId] = useState<any>(null);

  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [showErrorsConfirmationModal, setShowErrorsConfirmationModal] = useState(false);
  const [errorsConfirmation, setErrorsConfirmation] = useState<any>([]);
  const [showWarningsConfirmationModal, setShowWarningsConfirmationModal] = useState(false);
  const [warningsConfirmation, setWarningsConfirmation] = useState<any>([]);
  const [showModalButton, setShowModalButton] = useState(false);
  const [loading, setLoading] = useState(false);
  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const invoices: SalesInvoiceListModel = useSelector<RootState>(
    (state) => state.USER_sales_invoices.SalesInvoices,
    shallowEqual
  ) as SalesInvoiceListModel;

  const SalesinvoiceTypesOptions = useMemo(() => {
    return Array.from(SalesInvoicesTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const SalesinvoiceStateOptions = useMemo(() => {
    return Array.from(SalesInvoicesState.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const SalesinvoiceErrorTypesOptions = useMemo(() => {
    return Array.from(SalesInvoicesErrorTypes.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* ----------------------- Getting roles for dropdown ----------------------- */

  function RequestData() {
    dispatch(
      SalesInvoicesRedux.actions.getSalesInvoices({
        companyId: companyDetails?.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
          invoice_date: transactionObj?.id,
          // period: paramsObj?.period,
          ...paramsObj,
        },
      })
    );
  }

  useEffect(() => {
    if (table && params.companySlug && companyDetails?.id) {
      RequestData();
    }
  }, [
    table?.page,
    table?.pagination,
    params.companySlug,
    companyDetails?.id,
    transactionObj,
    paramsObj,
  ]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  const deleteInvoices = async (values: { selectedIds: number[]; approve: number }) => {
    setLoading(true);
    try {
      let payload = {
        ids: values.selectedIds,
        approve: values.approve,
      };

      if (values.approve) {
        payload['approve'] = values.approve;
      }
      const formData = serialize(payload);
      formData.append('_method', 'DELETE');
      await axiosInstance.post(`/${companyDetails.id}/invoices/bulk`, formData);
      toast.success(formatMessage({ id: 'Invoice has been deleted successfully!' }) || '');
      setDeleteItemId && setDeleteItemId(null);
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      RequestData();
    } catch (err: any) {
      if (err && err.response.status === 422 && err.response.data.messages.length > 0) {
        setLoading(false);
        setShowWarningsConfirmationModal(true);
        setWarningsConfirmation(err.response.data.messages);
        setShowModalButton(err.response.data.can_approve);
      } else {
        DisplayErrors(err);
      }
    }
    setLoading(false);
  };

  const rowItems = invoices?.data.map((invoice) => {
    if (transactionObj?.date) {
      return [
        new TableSelection({
          display: userCan('delete-invoice') || userCan('create-report'),
          id: invoice.id,
          tableName: TABLE_NAME,
        }),
        new TableData({
          text: `${
            invoice?.status === 1
              ? invoice?.invoice_number
                ? invoice?.invoice_number
                : ''
              : invoice?.status === 0
              ? formatMessage({ id: 'Draft ' })
              : ''
          }`,
        }),
        new DateViewer({ date: invoice?.invoice_date }),
        new TableData({ text: `${invoice?.amount}` }),
        new TableData({ text: invoice?.client?.name }),
        new PriceViewer({ price: invoice.calculated_vat }),
        new TableData({
          text: `${formatMessage({ id: `${SalesInvoicesTypesMap.get(invoice?.type)}` })}` || '',
        }),
        new TableData({
          text: `${
            invoice.description[locale as LOCALE] ? invoice.description[locale as LOCALE] : ''
          }`,
        }),
        new TableData({
          text: `${invoice?.invoice_items_count ? invoice?.invoice_items_count : 0}`,
        }),
      ];
    } else {
      return [
        new TableSelection({
          display: userCan('delete-invoice') && !transactionObj?.date,
          id: invoice.id,
          tableName: TABLE_NAME,
        }),
        new TableData({
          text: `${
            invoice?.status === 1
              ? invoice?.invoice_number
                ? invoice?.invoice_number
                : ''
              : invoice?.status === 0
              ? formatMessage({ id: 'Draft ' })
              : ''
          }`,
          show_processed_icon: invoice?.processed,
        }),
        new DateViewer({ date: invoice?.invoice_date }),
        new TableData({ text: `${invoice?.amount}` }),
        new TableData({ text: invoice?.client?.name }),
        new PriceViewer({ price: invoice.calculated_vat }),
        new TableData({
          text: `${formatMessage({ id: `${SalesInvoicesTypesMap.get(invoice?.type)}` })}` || '',
        }),
        new TableData({
          text: `${
            invoice.description[locale as LOCALE] ? invoice.description[locale as LOCALE] : ''
          }`,
        }),
        new TableData({
          text: `${invoice?.invoice_items_count ? invoice?.invoice_items_count : 0}`,
        }),

        // new TableImage({ image: invoice.image }),
        // new TableData({
        //   text: SalesinvoiceTypesOptions.filter((option) => invoice.type === option.value)[0]?.label,
        // }),
        new TableActions(
          (
            <div className='d-flex flex-row'>
              <>
                {userCan('view-invoice') && invoice.type === SalesInvoiceTypes.Sale && (
                  <ViewItem
                    title={$t('View')}
                    onClick={() =>
                      history.push(
                        `/c/${companyDetails.slug}/financial-management/sales-invoices/sale/${invoice.id}`
                      )
                    }
                  />
                )}
                {userCan('view-invoice') &&
                  invoice.type === SalesInvoiceTypes['Sale Credit Note'] && (
                    <ViewItem
                      title={$t('View')}
                      onClick={() =>
                        history.push(
                          `/c/${companyDetails.slug}/financial-management/sales-invoices/returnsale/${invoice.id}`
                        )
                      }
                    />
                  )}
                {((invoice?.is_sequential && invoice?.status === 0) || !invoice?.is_sequential) &&
                  userCan('update-invoice') &&
                  invoice.type === SalesInvoiceTypes.Sale &&
                  (invoice?.can_update &&
                  !invoice?.can_delete &&
                  !(invoice?.return_invoices?.length > 0) ? (
                    <EditItem
                      title={$t('Edit')}
                      onClick={() =>
                        history.push(
                          `/c/${companyDetails.slug}/financial-management/sales-invoices/sale/${invoice.id}/edit`
                        )
                      }
                    />
                  ) : (
                    <EditItemWithModal
                      id={invoice.id}
                      title={$t('Edit')}
                      messageId={
                        invoice?.has_draft_return_invoice
                          ? formatMessage({
                              id: 'his invoice contains a return draft invoice, are you sure you want to proceed',
                            })
                          : invoice?.can_update
                          ? invoice?.can_delete
                            ? invoice?.return_invoices?.length > 0
                              ? formatMessage(
                                  {
                                    id: 'This invoice has returns invoices with number {returnInvoicesNumbers} linked with it, also this invoice it’s already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed with the editing process?',
                                  },
                                  {
                                    returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                      (return_invoice, index) => {
                                        return `${index + 1}: ${return_invoice.invoice_number} `;
                                      }
                                    )} )`,
                                  }
                                )
                              : formatMessage({
                                  id: 'This invoice already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed the editing process?',
                                })
                            : invoice?.return_invoices?.length > 0
                            ? invoice?.can_update_return
                              ? formatMessage(
                                  {
                                    id: 'This invoice has returns invoices with numbers {returnInvoicesNumbers}. Are you sure you want to proceed with the editing process?',
                                  },
                                  {
                                    returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                      (return_invoice, index) => {
                                        return `${index + 1}: ${return_invoice.invoice_number} `;
                                      }
                                    )} )`,
                                  }
                                )
                              : formatMessage({
                                  id: 'This invoice is related to returned invoices entered in an approved report, and it can’t be edited',
                                })
                            : ''
                          : formatMessage({
                              id: "This invoice is included in an approved report, and can't be edited",
                            })
                      }
                      editURI={`/c/${companyDetails.slug}/financial-management/sales-invoices/sale/${invoice.id}/edit`}
                      showButtonGroup={invoice?.can_update && invoice?.can_update_return}
                      editItemId={editItemId}
                      setEditItemId={setEditItemId}
                    />
                  ))}
                {((invoice?.is_sequential && invoice?.status === 0) || !invoice?.is_sequential) &&
                  userCan('update-invoice') &&
                  invoice.type === SalesInvoiceTypes['Sale Credit Note'] &&
                  (invoice?.can_update &&
                  !invoice?.can_delete &&
                  !(invoice?.return_invoices?.length > 0) ? (
                    <EditItem
                      title={$t('Edit')}
                      onClick={() =>
                        history.push(
                          `/c/${companyDetails.slug}/financial-management/sales-invoices/returnsale/${invoice.id}/edit`
                        )
                      }
                    />
                  ) : (
                    <EditItemWithModal
                      id={invoice.id}
                      title={$t('Edit')}
                      messageId={
                        invoice?.can_update
                          ? invoice?.can_delete
                            ? invoice?.return_invoices?.length > 0
                              ? formatMessage(
                                  {
                                    id: 'This invoice has returns invoices with number {returnInvoicesNumbers} linked with it, also this invoice it’s already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed with the editing process?',
                                  },
                                  {
                                    returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                      (return_invoice, index) => {
                                        return `${index + 1}: ${return_invoice.invoice_number} `;
                                      }
                                    )} )`,
                                  }
                                )
                              : formatMessage({
                                  id: 'This invoice already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed the editing process?',
                                })
                            : invoice?.return_invoices?.length > 0
                            ? formatMessage(
                                {
                                  id: 'This invoice has returns invoices with numbers {returnInvoicesNumbers}. Are you sure you want to proceed with the editing process?',
                                },
                                {
                                  returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                    (return_invoice, index) => {
                                      return `${index + 1}: ${return_invoice.invoice_number} `;
                                    }
                                  )} )`,
                                }
                              )
                            : ''
                          : formatMessage({
                              id: "This invoice is included in an approved report, and can't be edited",
                            })
                      }
                      editURI={`/c/${companyDetails.slug}/financial-management/sales-invoices/returnsale/${invoice.id}/edit`}
                      showButtonGroup={invoice?.can_update}
                      editItemId={editItemId}
                      setEditItemId={setEditItemId}
                    />
                  ))}

                {((invoice?.is_sequential && invoice?.status === 0) || !invoice?.is_sequential) &&
                  userCan('delete-invoice') && (
                    <DeleteItem
                      id={invoice.id}
                      title={$t('Delete')}
                      message={
                        invoice?.can_update
                          ? invoice?.can_delete
                            ? invoice?.return_invoices?.length > 0
                              ? formatMessage(
                                  {
                                    id: 'This invoice has returns invoices with number {returnInvoicesNumbers} linked with it, also this invoice it’s already exist in a previous report, and deleting it will cause changes to the report, are you sure you want to proceed with the deletion process?',
                                  },
                                  {
                                    returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                      (return_invoice, index) => {
                                        return `${index + 1}: ${return_invoice.invoice_number} `;
                                      }
                                    )} )`,
                                  }
                                )
                              : formatMessage({
                                  id: 'This invoice already exist in a previous report, and deleting it will cause changes to the report, are you sure you want to proceed the deletion process?',
                                })
                            : invoice?.return_invoices?.length > 0
                            ? formatMessage(
                                {
                                  id: 'This invoice has returns invoices with numbers {returnInvoicesNumbers}. Are you sure you want to proceed with the deletion process?',
                                },
                                {
                                  returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                    (return_invoice, index) => {
                                      return `${index + 1}: ${return_invoice.invoice_number} `;
                                    }
                                  )} )`,
                                }
                              )
                            : formatMessage({
                                id: 'Are you sure you want to delete this invoice?',
                              })
                          : formatMessage({
                              id: "This invoice is included in an approved report, and can't be deleted",
                            })
                      }
                      showButtonGroup={invoice?.can_update}
                      setDeleteItemId={setDeleteItemId}
                      deleteItemId={deleteItemId}
                      deleteURI={`/${companyDetails.id}/invoices`}
                      payload={invoice.id}
                      successMessage={'Invoice has been deleted successfully!'}
                      afterSucessRequest={() => RequestData()}
                    />
                  )}

                {userCan('view-invoice') && invoice?.is_sequential && (
                  <select
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 form-select  '
                    onChange={(e) => {
                      if (invoice.status === 1) {
                        if (e.target.value === 'full invoice') {
                          window.open(
                            invoice?.long_invoice_preview_link!,
                            '_blank', // <- This is what makes it open in a new window.
                            'toolbar=yes,scrollbars=yes,resizable=yes'
                          );
                        }
                        if (e.target.value === 'simplified invoice') {
                          window.open(
                            invoice?.simple_invoice_preview_link!,
                            '_blank', // <- This is what makes it open in a new window.
                            'toolbar=yes,scrollbars=yes,resizable=yes'
                          );
                        }
                      } else {
                        if (e.target.value === 'full invoice') {
                          window.open(
                            `${backendBaseUrl}/invoices/${invoice?.id}/preview?simple=0&lang=${locale}`,
                            '_blank', // <- This is what makes it open in a new window.
                            'toolbar=yes,scrollbars=yes,resizable=yes'
                          );
                        }
                        if (e.target.value === 'simplified invoice') {
                          window.open(
                            `${backendBaseUrl}/invoices/${invoice?.id}/preview?simple=1&lang=${locale}`,
                            '_blank', // <- This is what makes it open in a new window.
                            'toolbar=yes,scrollbars=yes,resizable=yes'
                          );
                        }
                      }

                      e.target.selectedIndex = 0;
                    }}
                  >
                    <option value='' hidden></option>
                    {
                      <option value='full invoice' style={{ color: 'black', fontSize: '1.5rem' }}>
                        {!companyDetails?.tax_number
                          ? formatMessage({ id: 'full invoice' })
                          : formatMessage({ id: 'full tax invoice' })}
                      </option>
                    }
                    {
                      <option
                        value='simplified invoice'
                        style={{ color: 'black', fontSize: '1.5rem' }}
                      >
                        {!companyDetails?.tax_number
                          ? formatMessage({ id: 'simplified invoice' })
                          : formatMessage({ id: 'simplified tax invoice' })}
                      </option>
                    }
                  </select>
                )}

                {userCan('send-invoice') && invoice.is_sequential && invoice.status === 1 && (
                  <SendIssuedInvoices
                    id={invoice.id}
                    itemData={invoice}
                    title={$t('Send invoices')}
                    requestURI={`/${companyDetails.id}/invoices/attachments/send`}
                    showButtonGroup={true}
                    setInvoiceObject={setInvoiceObject}
                    invoiceObject={invoiceObject}
                    successMessage={'Invoice/s has been sent successfully!'}
                    afterSucessRequest={() => RequestData()}
                  />
                )}
                {userCan('view-invoice') && invoice?.errors?.info && (
                  <ErrorItemInfo
                    title={$t('Has errors')}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setErrorsConfirmation(
                        invoice?.errors?.info?.map((error: any) => error?.text)
                      );
                      setShowErrorsConfirmationModal(true);
                    }}
                  />
                )}
                {userCan('view-invoice') && invoice?.errors?.warning && (
                  <ErrorItemWarning
                    title={$t('Has errors')}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setErrorsConfirmation(
                        invoice?.errors?.warning?.map((error: any) => error?.text)
                      );
                      setShowErrorsConfirmationModal(true);
                    }}
                  />
                )}
                {userCan('view-invoice') && invoice?.errors?.danger && (
                  <ErrorItemDanger
                    title={$t('Has errors')}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setErrorsConfirmation(
                        invoice?.errors?.danger.map((error: any) => error?.text)
                      );
                      setShowErrorsConfirmationModal(true);
                    }}
                  />
                )}
              </>
            </div>
          )
        ),
      ];
    }
  });
  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(SalesInvoicesRedux.actions.fulfillSalesInvoices({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */
  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}

          {/*  ------------------------ start Action button ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            {/* begin::Menu */}
            {!transactionObj?.date && (
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                id='actionsButton'
              >
                <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
              </button>
            )}
            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                  {$t('Actions')}{' '}
                </div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}
              {/* begin::Menu item */}
              {userCan('create-invoice') && (
                <div className='menu-item px-3'>
                  <Button
                    fa='mail-bulk'
                    className='menu-link px-3 btn w-md-190px'
                    onClick={() => {
                      history.push(
                        `/c/${params.companySlug}/financial-management/sales-invoices/sale/create`
                      );
                    }}
                    nameId='Add sales invoice'
                    testid='add-sale-button'
                    faIconMenu={true}
                  />
                </div>
              )}
              {/* end::Menu item */}
              {/* begin::Menu item */}
              {userCan('create-invoice') && (
                <div className='menu-item px-3'>
                  <Button
                    className='menu-link px-3 btn w-md-190px'
                    faIconMenu={true}
                    fa='undo'
                    onClick={() => {
                      history.push(
                        `/c/${params.companySlug}/financial-management/sales-invoices/createreturnsale`
                      );
                    }}
                    nameId='Add sales return invoice'
                    testid='add-sale-return-button'
                  />
                </div>
              )}
              {/* end::Menu item */}
              {/* begin::Menu item */}
              {userCan('view-invoice') && !transactionObj?.date && (
                <div className='menu-item px-3'>
                  <Button
                    fa='arrow-down'
                    faIconMenu={true}
                    className='menu-link px-3 btn w-md-190px'
                    nameId='Export sales invoices'
                    testid='Export-sales-invoices'
                    onClick={() => {
                      let paramsObj = {};

                      paramsObj = {
                        ...paramsObj,
                        ...table?.query,
                      };

                      return axiosInstance
                        .get(`${companyDetails.id}/invoices/sales/export`, {
                          responseType: 'blob',
                          params: paramsObj,
                        })
                        .then((response) => {
                          let file = new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          });
                          let fileURL = URL.createObjectURL(file);

                          // create <a> tag dinamically
                          let fileLink = document.createElement('a');
                          fileLink.href = fileURL;

                          // it forces the name of the downloaded file
                          fileLink.download = 'Ahad_export-sales-invoices';

                          // triggers the click event
                          fileLink.click();
                        });
                    }}
                  />
                </div>
              )}
              {/* end::Menu item */}
              {/* begin::Menu item */}
              {userCan('create-invoice') &&
                companyDetails?.settings?.invoice_number_method === '1' && (
                  <div className='menu-item px-3 '>
                    <Button
                      fa='plus'
                      className='menu-link px-3 btn w-md-190px'
                      onClick={() =>
                        history.push(
                          `/c/${params.companySlug}/financial-management/sales-invoices/import`
                        )
                      }
                      nameId='Import invoices'
                      testid='Import-invoices'
                      faIconMenu={true}
                    />
                  </div>
                )}
              {/* end::Menu item */}
              {/* begin::Menu item */}
              {userCan('delete-invoice') && (
                <div className='menu-item px-3'>
                  <button
                    className='menu-link px-3 btn w-md-190px'
                    title={$t('Delete')}
                    disabled={table?.selection?.length === 0}
                    onClick={() => {
                      // setDeleteItemId &&
                      // setDeleteItemId(table?.selection.includes(-1) ? table?.ids : table?.selection)
                      deleteInvoices({
                        selectedIds: table?.selection.includes(-1) ? table?.ids : table?.selection,
                        approve: 0,
                      });
                    }}
                  >
                    <span className='menu-icon'>
                      <i className='far fa-trash'></i> &nbsp;
                    </span>

                    {formatMessage({
                      id: 'Delete',
                    })}
                  </button>
                </div>
              )}
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mt-3 opacity-75'></div>
              {/* end::Menu separator */}
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content px-3 py-3'>
                  <DownloadButton
                    fa='arrow-down'
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_BACKEND_BASE_URL?.replace(
                          /\/api$/,
                          ``
                        )}/Ahad_sales_vat_rates_codes_${locale}.pdf`
                      )
                    }
                    nameId='VAT rates guide'
                    faIconMenu={true}
                  />
                </div>
              </div>
              {/* end::Menu item */}
            </div>
            {/* end::Menu 2 */}
            {/* end::Menu */}
          </div>

          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}
        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'included_in_approved_report',
                  onChange: (value: any) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value === '0' ? '0' : value,
                        tableName: TABLE_NAME,
                        queryTerm: 'included_in_approved_report',
                      })
                    );
                  },
                  placeholderId: 'Invoices condition',
                  options: [
                    {
                      value: '1',
                      label: `${formatMessage({ id: 'included in reports' })}`,
                    },
                    {
                      value: '0',
                      label: `${formatMessage({ id: 'not included in report' })}`,
                    },
                  ],
                },
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'error_type',
                  onChange: (value: any) => {
                    console.log(typeof value);
                    console.log(value);
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value === 2 ? 0 : value,
                        tableName: TABLE_NAME,
                        queryTerm: 'error_type',
                      })
                    );
                  },
                  placeholderId: 'invoices notes',
                  options: SalesinvoiceErrorTypesOptions,
                },
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'status',
                  onChange: (value: any) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value === 2 ? 0 : value,
                        tableName: TABLE_NAME,
                        queryTerm: 'status',
                      })
                    );
                  },
                  placeholderId: 'invoice status',
                  options: SalesinvoiceStateOptions,
                },
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'type',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'type',
                      })
                    ),
                  placeholderId: 'Invoice type',
                  options: SalesinvoiceTypesOptions,
                },
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dateRange',
                  queryTerm: 'dateRange',
                  isClearable: true,
                  onChange: (valueFrom: string, valueTo: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: valueFrom,
                        tableName: TABLE_NAME,
                        queryTerm: 'date_from',
                      })
                    );
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: valueTo,
                        tableName: TABLE_NAME,
                        queryTerm: 'date_to',
                      })
                    );
                  },
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        {/* Hide the unnecessary */}
        {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline mx-5'>
        {userCan('delete-user') && (
          <DeleteItem
            isButton
            messageId={'Are you sure you want to delete this invoice?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/invoices`}
            payload={deleteItemId}
            successMessage={'Invoice has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )}
      </div> */}
        {/* <h1 className='text-center'>comming soon</h1> */}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          allDisplayedIds={invoices?.data?.map((invoice) => invoice.id) || []}
          meta={invoices}
          scrollable={transactionObj?.date && invoices?.data?.length > 5 ? true : false}
          headerItems={!transactionObj?.date ? headerItems : headerItemsWithoutActions}
          rowItems={rowItems}
          initPerPage={transactionObj?.date ? 10 : 20}
          hidePagination={transactionObj?.date ? true : false}
          headerWithCheckbox={userCan('delete-invoice') && !transactionObj?.date}
        />
      </div>
      {/*  ---------------------errors confirmation Modal-----------------------  */}

      {showErrorsConfirmationModal && errorsConfirmation && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => setShowErrorsConfirmationModal(false)}
            specialContainerClass='dialog-content-container '
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 m-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                {errorsConfirmation.length === 1 && (
                  <span className='min-w-250px'>{errorsConfirmation[0]}</span>
                )}
                {errorsConfirmation.length > 1 && (
                  <ol>
                    {errorsConfirmation.map((warning: any, index: any) => (
                      <li className='min-w-250px' key={index}>
                        {warning}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
              {/* <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                <span>{errorsConfirmation}</span>
              </div> */}
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // setApproveWarnings(0);
                        setShowErrorsConfirmationModal(false);
                        // formik.submitForm();
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end errors confirmation Modal-----------------------  */}

      {/*  ---------------------warnings confirmation Modal-----------------------  */}

      {showWarningsConfirmationModal && warningsConfirmation && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowWarningsConfirmationModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                {warningsConfirmation.length === 1 && <span>{warningsConfirmation[0]}</span>}
                {warningsConfirmation.length > 1 && (
                  <ol>
                    {warningsConfirmation.map((warning: any, index: any) => (
                      <li key={index}>{warning}</li>
                    ))}
                  </ol>
                )}
              </div>

              {showModalButton && (
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        // disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowWarningsConfirmationModal(false);
                          deleteInvoices({
                            selectedIds: table?.selection.includes(-1)
                              ? table?.ids
                              : table?.selection,
                            approve: 1,
                          });
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Approve'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowWarningsConfirmationModal(false);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end warnings confirmation Modal-----------------------  */}
    </>
  );
};

const SalesInvoicesWrapper: FC<any> = ({ transactionObj, paramsObj }) => {
  usePermissions('view-invoice');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales invoices' })}</PageTitle>
      <Invoices transactionObj={transactionObj} paramsObj={paramsObj} />
    </div>
  );
};

export { SalesInvoicesWrapper };
