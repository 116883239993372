import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom'; //useParams,, useLocationPrompt,

import { Form, useFormik, FormikProvider } from 'formik'; //Formik,FormikValues,
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';

import { RootState } from '../../../../setup/redux/Store';

import * as TableRedux from '../../../store/TableRedux';
import * as WithholdingReportsRedux from '../store/WithholdingReportsRedux';

import { CompanyModel } from '../../companies/CompanyModel';

import { axiosInstance } from '../../../../network/apis';
import { DisplayErrors } from '../../../../utils/DisplayErrors';

import { KTSVG } from '../../../../_metronic/helpers';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { ICreateReport, useConfigureReportsSchema } from './CreateReportWizardHelper';
import { initialConfigPayload } from '../helpers/reportsConfigHelpers';

import { ReportsPeriodTypeMap } from '../../../../_metronic/constants/general';
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';
import { $t } from '../../../../_metronic/i18n/formatMessage';

import useExitPrompt from '../../../hooks/useExitPrompt/useExitPrompt';

import AhadDialog from '../../../../setup/shared/AhadDialog';
import { SeparatorLines } from '../../../shared/Lines';

import { Portal } from '../../../../_metronic/partials';
import { PurchasesWrapper } from '../../purchases/PurchasesWrapper';

import { Step1 } from './steps/Step1';
import { Step2 } from './steps/Step2';
import { Step3 } from './steps/Step3';

const TABLE_NAME = 'withholding_transactons_list';

const HorizontalConfigNewWithholdingReportsWrapper: FC = () => {
  const { formatMessage, locale } = useIntl(); //
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[TABLE_NAME];

  const [paramsObj, setParamsObj] = useState<any>({ is_withholding: 1 });
  const [transactionObj, setTransactionObj] = useState<any>({});
  const [showPurchasesModal, setShowPurchasesModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [excludeIDs, setExcludeIDs] = useState<any>([]);
  const [selectedtransactionsList, setSelectedtransactionsList] = useState<any>([]);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const { reportsInitialValues, reportsValidationSchema } = useConfigureReportsSchema();
  const [currentSchema, setCurrentSchema] = useState(reportsValidationSchema[0]);
  const [initValues] = useState<ICreateReport>(reportsInitialValues);
  const [isSubmitButton, setSubmitButton] = useState(false);

  const createReports = async (values: any, resetForm: any) => {
    setLoading(true);
    // console.log('createReports');
    // console.log(values);
    console.log('Create withholding report');

    // const selectedtransactionsList = table.selection;

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    payload['name'] = { ar: '', en: '' };
    payload['name']['ar'] = values.report_name_ar ? values.report_name_ar.trim() : '';
    payload['name']['en'] = values.report_name_en ? values.report_name_en.trim() : '';
    payload['period'] = values.report_period[0]?.value;
    payload['transactions'] = selectedtransactionsList;
    payload['exclude'] = excludeIDs;
    payload['monthly_period'] = values.monthly_period[0]?.value;
    payload['yearly_period'] = values.year_period[0]?.value;
    payload['is_withholding'] = 1;

    delete payload['report_period'];
    delete payload['report_name_ar'];
    delete payload['report_name_en'];
    delete payload['year_period'];

    // delete payload['monthly_period'];

    const formData = serialize(payload, { indices: true });
    try {
      await axiosInstance.post(`${companyDetails.id}/tax_return`, formData).then((res) => {
        if (res.status === 200 && res.data.message) {
          toast.success(formatMessage({ id: `${res.data.message}` }));
        } else {
          toast.success(formatMessage({ id: 'Reports has been created successfully!' }));
        }
        resetForm();
      });

      history.push(`/c/${companyDetails.slug}/tax-module/withholdingreports`);
    } catch (err: any) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const submitStep = (values: ICreateReport, resetForm: any) => {
    if (!stepper.current) {
      return;
    }

    // setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1);

    setCurrentSchema(reportsValidationSchema[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
      if (stepper.current?.getCurrentStepIndex() === 3) {
        setSelectedtransactionsList(table.selection);
      }
    } else {
      // stepper.current.goto(1);
      // console.log(isSubmitButton);
      // console.log('submitStep');
      // create report
      createReports(values, resetForm);
      // resetForm();
    }
  };
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    // setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1);

    stepper.current.goPrev();

    setCurrentSchema(reportsValidationSchema[stepper.current.currentStepIndex - 1]);
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  useEffect(() => {
    if (stepper.current?.getCurrentStepIndex() === 3) {
      setSubmitButton(true);
      setSelectedtransactionsList(table.selection);
    } else {
      setSelectedtransactionsList([]);
      setSubmitButton(false);
    }
  }, [stepper.current?.getCurrentStepIndex()]);

  /* ----------------------------- on Transaction Selection Handler ----------------------------- */
  // pass transaction detail from child(transaction table) to parent (confihg new report)
  const onTransactionSelectionHandler = (transaction: any) => {
    setTransactionObj(transaction);

    setShowPurchasesModal(true);
  };
  useEffect(() => {
    const table = tables['purchases_list'];
    if (table) {
      if (excludeIDs.length > 0 && table.ids && table.selection.length < excludeIDs.length) {
        dispatch(
          TableRedux.actions.bulkToggleSelection({
            idList: excludeIDs,
            tableName: 'purchases_list',
          })
        );
      }
    }
  }, [excludeIDs, tables['purchases_list']?.ids]);

  const formik = useFormik<any>({
    initialValues: initValues,
    validationSchema: currentSchema,
    onSubmit: (values, { resetForm }) => submitStep(values, resetForm),
  });

  const { handleSubmit } = formik; //values, setFieldValue,

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const withholdingTransactionsList: any = useSelector<RootState>((state) => {
    return state.USER_withholding_reports.withholding_transactions;
  }, shallowEqual) as any;

  const reportPeriodOptions = useMemo(() => {
    return Array.from(ReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  // set intial values for formik
  useEffect(() => {
    formik.setFieldValue(
      `report_period`,
      reportPeriodOptions?.filter((option) => {
        return option.value === 2;
      })
      // reportPeriodOptions?.filter((option) => option.value === 2)
    );
  }, [companyDetails.settings?.period_type]);

  // reset table selection and select default value auto select
  useEffect(() => {
    // get transaction table ids
    if (withholdingTransactionsList) {
      withholdingTransactionsList?.data?.forEach((element: any, index: any) => {
        if (element.selected) {
          dispatch(
            TableRedux.actions.bulkToggleSelection({
              idList: Array.from(new Set([...table.selection, element.id])),
              tableName: TABLE_NAME,
            })
          );
        }
      });
    }

    //   // dispatch(TableRedux.actions.toggleSelection({ id: 0, tableName: TABLE_NAME }));
  }, [formik.values.monthly_period, formik.values.year_period, withholdingTransactionsList]);

  useEffect(() => {
    // clear selection
    dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
    // clear transaction table ids
    setExcludeIDs([]);
  }, [formik.values.monthly_period, formik.values.year_period]);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(WithholdingReportsRedux.actions.fullFillWithholdingTransactions({}));
      dispatch(WithholdingReportsRedux.actions.fullFillWithholdingPurchasesTransactions({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  useEffect(() => {
    // create params object
    // const paramsObj: any = {};
    if (formik.values.report_period[0]?.value) {
      setParamsObj((prevState: any) => {
        return { ...prevState, period: formik.values.report_period[0]?.value };
      });
    }
    if (formik.values.year_period[0]?.value) {
      setParamsObj((prevState: any) => {
        return { ...prevState, year: formik.values.year_period[0]?.value };
      });
    }

    if (formik.values.monthly_period[0]?.value) {
      setParamsObj((prevState: any) => {
        return { ...prevState, month: formik.values.monthly_period[0]?.value };
      });
    }
  }, [formik.values.report_period, formik.values.year_period, formik.values.monthly_period]);

  return (
    <div className='bg-white '>
      <div className='d-flex px-5 '>
        <div className='d-flex justify-content-center px-10 py-3 fs-1 ' style={{ width: '100%' }}>
          {$t('Create withholding reports')}
        </div>
      </div>
      <SeparatorLines lines={2} />
      <div
        ref={stepperRef}
        className='stepper stepper-links d-flex flex-column pt-15'
        id='kt_create_withholding_report_stepper'
      >
        <div className='stepper-nav mb-5'>
          <div className='stepper-item current' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{formatMessage({ id: 'Report name' })}</h3>
          </div>

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{formatMessage({ id: 'Transactions' })}</h3>
          </div>

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{formatMessage({ id: 'Create Vat Rate' })}</h3>
          </div>
        </div>

        <FormikProvider value={formik}>
          {/* <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}> {() => (*/}
          <Form
            noValidate
            autoComplete='none'
            style={{ width: '100%' }}
            className='mx-auto  w-100 pt-15 pb-10'
            id='kt_create_withholding_report_form'
          >
            {Object.keys(formik.touched).length > 0 && (
              <Prompt message={$t('Are you sure you want to ignore the changes?')} />
            )}

            <div className='current justify-content-center' data-kt-stepper-element='content'>
              <Step1 formik={formik} />
            </div>

            <div className='justify-content-center' data-kt-stepper-element='content'>
              <Step2 paramsObj={paramsObj} onTransactionSelection={onTransactionSelectionHandler} />
            </div>

            <div className='justify-content-center' data-kt-stepper-element='content'>
              <Step3
                selectedtransactionsList={selectedtransactionsList}
                excludeIDs={excludeIDs}
                step={stepper.current?.currentStepIndex}
              />
              {/* paramsObj={paramsObj} onTransactionSelection={onTransactionSelectionHandler} */}
            </div>

            <div className='d-flex flex-stack pt-15'>
              <div className='mx-2'>
                <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-sm btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                >
                  {locale === 'ar' && (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-4 me-1'
                    />
                  )}
                  {locale === 'en' && (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                  )}

                  {formatMessage({ id: 'Back' })}
                </button>
              </div>

              <div>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary me-3'
                  // disabled={formik.isSubmitting}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                  }}
                >
                  <span className='indicator-label'>
                    {!isSubmitButton && formatMessage({ id: 'Continue' })}
                    {isSubmitButton && formatMessage({ id: 'Submit' })}
                    {locale === 'ar' && (
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    )}
                    {locale === 'en' && (
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    )}
                  </span>
                </button>
              </div>
            </div>
          </Form>
          {/*  )}</Formik> */}
        </FormikProvider>
      </div>

      {/*  --------------------- puchases Modal-----------------------  */}
      {showPurchasesModal && (
        <Portal className='width_modal'>
          <AhadDialog
            closeCallBack={() => setShowPurchasesModal(false)}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row my-3' style={{ fontSize: '1.5rem' }}>
                <FormattedMessage id='Please select purchases to exclude from report' />
              </div>
              <div className='row my-3'>
                <PurchasesWrapper transactionObj={transactionObj} paramsObj={paramsObj} />
              </div>

              <div className='col-md-12'>
                <div className='row my-3'>
                  <div className='col-md-3'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      // disabled={formik.isSubmitting}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const table = tables['purchases_list'];
                        // setExcludeIDs((prevState: any) => {
                        //   return [
                        //     ...new Set(
                        //       [...prevState, ...table.selection].filter((item) => item !== -1)
                        //     ),
                        //   ];
                        // });
                        setExcludeIDs([
                          ...new Set([...table.selection].filter((item) => item !== -1)),
                        ]);
                        setShowPurchasesModal(false);
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Exclude'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-3'>
                    <button
                      onClick={() => {
                        setShowPurchasesModal(false);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}

      {/*  ---------------------end puchases Modal-----------------------  */}
    </div>
  );
};

export { HorizontalConfigNewWithholdingReportsWrapper };
