import { Meta } from '../../../types/Meta';

export enum ReportsPeriodsTypes {
  'All' = 1,
  'Monthly' = 2,
  'Quarterly' = 3,
}
export enum ReportsTypes {
  'Draft' = 1,
  'Approved' = 2,
}

export enum VatRatesTypes {
  'Income' = 1,
  'Expenditure' = 2,
  'Income return' = 3,
  'Expenditure return' = 4,
}

export interface IInvoicesWithholdingReport {
  id: number;
  start_date: string;
  end_date: string;
  total_sales: string;
  total_purchases: string;
  total_tax: string;
  status: string;
  can_cancel: boolean;
  custom_rules_errors_approve: boolean;
  preview_link: string;
  report_preview_link: string;
  declaration_preview_link: string;
  excel_link: string;
  vat_package_link: string;
  vat_transactions_log_link: string;
  report_type: number;
  name: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
}

export interface InvoicesWithholdingReportsDataListModel extends Meta {
  data: Array<IInvoicesWithholdingReport>;
}
