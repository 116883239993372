import { FC } from 'react';

type Props = any;

export const ViewPurchasesItem: FC<Props> = ({ ...props }) => {
  return (
    <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' {...props}>
      <i className='far fa-shopping-cart mx-2'></i>
    </a>
  );
};
