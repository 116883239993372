import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companySales = endpoints.companySales;
export const companySingleSale = endpoints.companySingleSale;

export function getAllSales({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companySales(companyId), { params });
}

export function getSale({ companyId, saleId }: { companyId: ID; saleId: ID }) {
  return axiosInstance.get(companySingleSale(companyId, saleId));
}
