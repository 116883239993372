import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Input } from '../../../shared/Input';
import { TextArea } from '../../../shared/textarea';
import {
  CodeRulesCategoryTypeMap,
  CodeRulesColumnTypeMap,
  CodeRulesOperatorTypeMap,
  CodeRulesThirdPartyTypeMap,
} from '../../../../_metronic/constants/general';
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';
import * as CodeRulesRedux from '../../../modules/admins/code_rules/store/CodeRulesRedux';
import { RootState } from '../../../../setup/redux/Store';
import { LocaleTitle } from '../../../shared/LocaleTitle';
import Select from 'react-select';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { Label } from '../../../shared/Label';
import { countires } from '../../../../_metronic/constants/countries';
import { formatDate } from '../../../shared/tables/classes/DateViewer';
import SaveCancelBtnsGroup from '../../../shared/SaveCancelBtnsGroup';
import { serialize } from 'object-to-formdata';
import { axiosInstance } from '../../../../network/apis';
import { DisplayErrors } from '../../../../utils/DisplayErrors';

interface IParams {
  codeRuleId: string;
}
type TProps = {
  mode: 'create' | 'edit' | 'view';
};

export const ConfigNewCodeRule: FC<TProps> = ({ mode }) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const { formatMessage, locale } = useIntl();
  const params: IParams = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentCodeRule: any = useSelector<RootState>((state) => {
    return state.SYS_code_rules.code_rule;
  }, shallowEqual) as any;
  const CodeRulesCategoryTypesOptions = useMemo(
    () =>
      Array.from(CodeRulesCategoryTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );
  const [loading, setLoading] = useState(false);

  const codeRulesThirdPartyTypeMapsOptions = useMemo(() => {
    return Array.from(CodeRulesThirdPartyTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);
  const codeRulesOperatorTypeMapsOptions = useMemo(() => {
    return Array.from(CodeRulesOperatorTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);
  const codeRulesColumnTypeMapsOptions = useMemo(() => {
    return Array.from(CodeRulesColumnTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);
  /* ------------------------------- Local State ------------------------------ */
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  /* -------------------------------- Functions ------------------------------- */
  const RequestData = async (codeRuleId: any) => {
    try {
      dispatch(CodeRulesRedux.actions.getCodeRule({ codeRuleId: codeRuleId }));
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };
  /* --------------------------------- Formik --------------------------------- */

  const formik = useFormik({
    initialValues: {
      id: null,
      name: {
        ar: '',
        en: '',
      },
      error_message: {
        ar: '',
        en: '',
      },
      category: {
        value: '',
        label: '',
      },
      date_range: [],
      nationalities: [],
      is_not: 0,
      amount_range: null,
      third_party_countries: null,
      third_party_account_types: null,
      must_be_tax_registered: null,
      countries: null,
      operator: [
        {
          value: 0,
          label: '',
        },
      ],
      column: null,
      period: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      const payload: { [key: string]: any } = {};

      console.log('values', values);

      payload['error_message'] = { ar: '', en: '' };
      payload['error_message']['ar'] = values.error_message.ar.trim();
      payload['error_message']['en'] = values.error_message.en.trim();

      const formData = serialize(payload, { indices: true });
      formData.append('_method', 'PUT');

      try {
        await axiosInstance.post(`/admin/rules/${currentCodeRule.id}`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'Code rule has been updated successfully!' }));
        history.push(`/admin/code-rules`);
      } catch (err) {
        DisplayErrors(err);
      }
      setLoading(false);
    },
  });
  const { setFieldValue, values, setFieldTouched, resetForm } = formik;
  /* --------------------------------- Formik --------------------------------- */
  const selectedOption = CodeRulesCategoryTypesOptions.filter(
    (item) => item?.value === currentCodeRule?.category
  )[0];

  useEffect(() => {
    if (currentCodeRule?.id) {
      setFieldValue(
        'date_range',
        currentCodeRule?.data?.date_range ? currentCodeRule?.data?.date_range : ''
      );
      setFieldValue('name.ar', currentCodeRule.name.ar ? currentCodeRule.name.ar : '');
      setFieldValue('name.en', currentCodeRule.name.en ? currentCodeRule.name.en : '');
      setFieldValue(
        'error_message.ar',
        currentCodeRule.error_message.ar ? currentCodeRule.error_message.ar : ''
      );
      setFieldValue(
        'error_message.en',
        currentCodeRule.error_message.en ? currentCodeRule.error_message.en : ''
      );
      setFieldValue('category', selectedOption);

      if (currentCodeRule?.data?.amount_range) {
        setFieldValue(
          'min_amount',
          currentCodeRule?.data?.amount_range[0] ? currentCodeRule?.data?.amount_range[0] : ''
        );
        setFieldValue(
          'max_amount',
          currentCodeRule?.data?.amount_range[1] ? currentCodeRule?.data?.amount_range[1] : ''
        );
      }
      if (currentCodeRule?.data?.column) {
        const res = codeRulesColumnTypeMapsOptions.filter(
          (item) => item.value.toString() === currentCodeRule?.data?.column.toString()
        );
        setFieldValue('column', res);
      }
      if (currentCodeRule?.data?.period) {
        setFieldValue('period', currentCodeRule?.data?.period ? currentCodeRule?.data?.period : '');
      }

      if (currentCodeRule?.data?.operator) {
        const res = codeRulesOperatorTypeMapsOptions.filter(
          (item) => item.value.toString() === currentCodeRule?.data?.operator.toString()
        );
        setFieldValue('operator', res);
      }

      if (currentCodeRule?.data?.countries) {
        const { exclude, include } = currentCodeRule?.data?.countries;

        const allcountires = countires?.map((nationality: any) => ({
          value: nationality.iso,
          label: nationality?.emoji + ' ' + nationality?.name?.[locale],
        }));

        if (exclude) {
          const exclude_data = allcountires.filter((country: { value: string; label: string }) =>
            exclude.some((data: string[]) => data.includes(country.value))
          );
          setFieldValue('nationalities', exclude_data ? exclude_data : []);
          setFieldValue('is_not', 1);
        }

        if (include) {
          const include_Data = allcountires.filter((country: { value: string; label: string }) =>
            include.some((data: string[]) => data.includes(country.value))
          );
          setFieldValue('nationalities', include_Data ? include_Data : []);
          setFieldValue('is_not', 0);
        }
      }
      if (currentCodeRule?.data?.third_party_account_types) {
        const res = currentCodeRule?.data?.third_party_account_types;
        const filterdRes = codeRulesThirdPartyTypeMapsOptions.filter(
          (item: { value: string; label: string }) =>
            res.some((data: string[]) => data.includes(item.value))
        );
        setFieldValue('third_party_account_types', filterdRes);
      }
    }
  }, [currentCodeRule, selectedOption, CodeRulesCategoryTypesOptions]);

  useEffect(() => {
    if (params?.codeRuleId) {
      RequestData(params?.codeRuleId);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(CodeRulesRedux.actions.fullFillCodeRule({}));
    };
  }, []);

  return (
    <div>
      {mode === 'view' && (
        <>
          <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'View code rule' })}</PageTitle>

          <div className='row my-3'>
            <div className='d-flex justify-content-between'></div>
            <div
              className='d-flex justify-content-end'
              style={{ textAlign: 'end', alignSelf: 'center' }}
            >
              <button
                type='button'
                className='btn btn-primary btn-sm btn-primary-shadow'
                title={formatMessage({ id: 'Edit code rule' })}
                onClick={() => history.push(location.pathname + '/edit')}
              >
                <i className='fa fa-edit cursor-pointer'></i>
                {formatMessage({ id: 'Edit code rule' })}
              </button>
            </div>
          </div>
        </>
      )}
      {mode === 'edit' && (
        <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Edit code rule' })}</PageTitle>
      )}

      {}
      <div className='d-flex flex-column flex-root' data-testid='page-title'>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            width: '100%',
            paddingRight: locale === 'en' ? '4em' : '',
            paddingLeft: locale === 'ar' ? '4em' : '',
          }}
        >
          <div className='row'>
            {locales.map((lang) => (
              <div className='col-6'>
                <Input
                  placeholder={formatMessage({ id: `Code_rule_in_${lang}` })}
                  autoComplete='off'
                  labelId={`Code_rule_in_${lang}`}
                  formik={formik}
                  name={`name.[${lang}]`}
                  type='text'
                  disabled
                />
              </div>
            ))}

            {locales.map((lang) => (
              <div className='col-xl-6'>
                <TextArea
                  placeholder={LocaleTitle({ id: 'Role Description', lang })}
                  autoComplete='off'
                  labelId={`Code_Rule_Error_Message_${lang}`}
                  name={`error_message.[${lang}]`}
                  type='text'
                  formik={formik}
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  rows='6'
                  disabled={mode === 'view'}
                />
              </div>
            ))}
          </div>
          <div className='col-xl-6'>
            <label className='form-label fs-6 my-1 fw-bolder text-dark'>
              {formatMessage({ id: 'Comparison Category' })}
            </label>
            <Select
              placeholder={formatMessage({ id: 'Comparison Category' })}
              onChange={(value: any) => setFieldValue('category', value)}
              onBlur={() => setFieldTouched('category', true)}
              options={CodeRulesCategoryTypesOptions}
              value={values?.category}
              className='react-select smaller'
              id='Comparison_Category'
              isDisabled
            />
          </div>

          <Fragment>
            {formik?.values?.category?.value === CodeRulesCategoryTypesOptions[0]?.value && (
              <>
                <div className='col-12 form-label fs-6 my-1 fw-bolder text-dark'>
                  <label className={'form-label fs-6 my-1 fw-bolder text-dark required'}>
                    {formatMessage({
                      id: 'Date Range',
                    })}
                  </label>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <DatePicker
                      isClearable={mode !== 'view'}
                      selected={
                        formik.values.date_range[0] ? new Date(formik.values.date_range[0]) : null
                      }
                      dateFormat='dd/MM/yyyy'
                      placeholderText={formatMessage({
                        id: 'Start Date',
                      })}
                      id='start_date_id'
                      className='form-control form-control-md b-radius-16 basic-input '
                      onChange={(date: any) =>
                        formik.setFieldValue(
                          `start_date`,
                          date
                            ? // remove offset
                              new Date(
                                date.getTime() - date.getTimezoneOffset() * 60000
                              ).toISOString()
                            : ''
                        )
                      }
                      maxDate={new Date()}
                      onChangeRaw={(e) => {
                        setFieldTouched(`start_date`, true, true);
                      }}
                      autoComplete='off'
                      disabled
                    />
                  </div>
                  {formik.values.date_range[1] && (
                    <div className='col-md-4 '>
                      <DatePicker
                        isClearable={mode !== 'view'}
                        selected={
                          formik.values.date_range[1] ? new Date(formik.values.date_range[1]) : null
                        }
                        dateFormat='dd/MM/yyyy'
                        placeholderText={formatMessage({
                          id: 'Start Date',
                        })}
                        id='end_date_id'
                        className='form-control form-control-md b-radius-16 basic-input '
                        onChange={(date: any) =>
                          formik.setFieldValue(
                            `end_date`,
                            date
                              ? // remove offset
                                new Date(
                                  date.getTime() - date.getTimezoneOffset() * 60000
                                ).toISOString()
                              : ''
                          )
                        }
                        maxDate={new Date()}
                        onChangeRaw={(e) => {
                          setFieldTouched(`end_date`, true, true);
                        }}
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  )}
                  {formik.values.date_range.length === 1 && (
                    <div className='col-md-4 my-3'>
                      <Input
                        disabled
                        className={'form-check-input'}
                        type={'checkbox'}
                        name={'present'}
                        id='present'
                        defaultChecked={false}
                        checked={formik.values.date_range.length === 1 ? true : false}
                      />
                      {<Label msg='Present' htmlFor='present' />}
                    </div>
                  )}
                </div>
                {formik.values.date_range[0] && formik.values.date_range.length === 1 && (
                  <div>
                    {formatMessage(
                      {
                        id: 'Transaction date should be greater than or equal {startDate} till present. If not , show the error message',
                      },
                      { startDate: formatDate(formik.values.date_range[0]) }
                    )}
                  </div>
                )}
                {formik.values.date_range[0] &&
                  formik.values.date_range[1] &&
                  formik.values.date_range.length === 2 && (
                    <div>
                      {formatMessage(
                        {
                          id: 'Transaction date should be greater than or equal {startDate} and less than {endDate}. If not , show the error message',
                        },
                        {
                          startDate: formatDate(formik.values.date_range[0]),
                          endDate: formatDate(formik.values.date_range[1]),
                        }
                      )}
                    </div>
                  )}
              </>
            )}

            {formik?.values?.category?.value === CodeRulesCategoryTypesOptions[1]?.value && (
              <>
                <div className='col-12'>
                  <label className={'form-label fs-6 my-1 fw-bolder text-dark required'}>
                    {formatMessage({
                      id: 'Amount',
                    })}
                  </label>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Min',
                      })}
                      // required
                      autoComplete='off'
                      // labelId={formatMessage({
                      //   id: 'Amount',
                      // })}
                      name={'min_amount'}
                      type={'text'}
                      formik={formik}
                      disabled
                    />
                  </div>
                  {currentCodeRule?.data?.amount_range[1] && (
                    <div className='col-md-6 '>
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id: 'Max',
                        })}
                        // required
                        autoComplete='off'
                        // labelId={formatMessage({
                        //   id: 'Amount',
                        // })}
                        name={'max_amount'}
                        type={'text'}
                        formik={formik}
                        disabled
                      />
                    </div>
                  )}
                </div>

                {currentCodeRule?.data?.amount_range[0] && !currentCodeRule?.data?.amount_range[1] && (
                  <div>
                    {formatMessage(
                      {
                        id: 'Transaction amount should be greater than or equal {minAmount}. If not , show the error message',
                      },
                      { minAmount: currentCodeRule?.data?.amount_range[0] }
                    )}
                  </div>
                )}
                {currentCodeRule?.data?.amount_range[0] && currentCodeRule?.data?.amount_range[1] && (
                  <div>
                    {formatMessage(
                      {
                        id: 'Transaction amount should be greater than or equal {minAmount} and less than or equal {maxAmount}. If not , show the error message',
                      },
                      {
                        minAmount: currentCodeRule?.data?.amount_range[0],
                        maxAmount: currentCodeRule?.data?.amount_range[1],
                      }
                    )}
                  </div>
                )}
              </>
            )}

            {formik?.values?.category?.value === CodeRulesCategoryTypesOptions[2]?.value && (
              <div className='row'>
                <div className='col-md-6 mb-3'>
                  <Label msg='Nationalities' htmlFor='nationalities-select' />
                  <Select
                    placeholder={formatMessage({ id: 'Nationalities' })}
                    className='react-select smaller'
                    id='nationalities-select'
                    value={formik.values.nationalities}
                    isDisabled
                    isMulti
                  />
                </div>

                {formik.values.is_not !== 0 && (
                  <div className='col-md-6 mt-10 '>
                    <Input
                      disabled
                      className={'form-check-input'}
                      type={'checkbox'}
                      name={'is_not'}
                      id='is_not'
                      defaultChecked={false}
                      checked={formik.values.is_not === 0 ? false : true}
                      onChange={(value: any) => {
                        return formik.setFieldValue(`is_not`, value.target.checked ? 1 : 0);
                      }}
                      onBlur={() => formik.setFieldTouched(`is_not`, true)}
                    />
                    {<Label msg='Is not' htmlFor='is_not' />}
                  </div>
                )}
                {formik.values.is_not === 0 && (
                  <div>
                    {formatMessage({
                      id: 'Third parties of the linked transactions must have the chosen nationalities. If not , show the error message.',
                    })}
                  </div>
                )}
                {formik.values.is_not === 1 && (
                  <div>
                    {formatMessage({
                      id: 'Third parties of the linked transactions must not have the chosen nationalities. If not , show the error message.',
                    })}
                  </div>
                )}
              </div>
            )}

            {formik?.values?.category?.value === CodeRulesCategoryTypesOptions[3]?.value && (
              <>
                {/*  --------------------- Third party types -----------------------  */}
                <div className='col-md-6 mb-3'>
                  <Label msg='Third party type' htmlFor='third_party_types-select' />
                  <Select
                    placeholder={formatMessage({ id: 'Third party type' })}
                    className='react-select smaller'
                    id='third_party_types-select'
                    value={formik.values.third_party_account_types}
                    onChange={(value) => {
                      formik.setFieldValue('third_party_types', value);
                    }}
                    onBlur={() => formik.setFieldTouched('third_party_types', true)}
                    options={codeRulesThirdPartyTypeMapsOptions}
                    isDisabled
                    isMulti
                  />
                  <div>
                    {formatMessage({
                      id: 'Third parties of the linked transactions must have the chosen types. If not , show the error message.',
                    })}
                  </div>
                </div>
              </>
            )}

            {formik.values.category.value === CodeRulesCategoryTypesOptions[4].value && (
              <div className='row'>
                {/*  --------------------- Third Transaction date -----------------------  */}
                <div className='col-md-6 mb-3'>
                  <Label msg='Operator' htmlFor='operator-select' />
                  <Select
                    placeholder={formatMessage({ id: 'Operator' })}
                    className='react-select smaller'
                    id='operator-select'
                    value={formik.values.operator}
                    options={codeRulesOperatorTypeMapsOptions}
                    isDisabled
                  />
                </div>
                <div className='col-6 mb-3'>
                  <Input
                    setTouchedOnInput
                    placeholder={formatMessage({
                      id: 'Number of months',
                    })}
                    required
                    autoComplete='off'
                    labelId={formatMessage({
                      id: 'Number of months',
                    })}
                    name={'period'}
                    type={'text'}
                    formik={formik}
                    disabled
                  />
                </div>
                <div className='col-md-6 mb-3'>
                  <Label msg='Comparison column' htmlFor='column-select' />
                  <Select
                    placeholder={formatMessage({ id: 'Comparison column' })}
                    className='react-select smaller'
                    id='column-select'
                    value={formik.values?.column}
                    options={codeRulesColumnTypeMapsOptions}
                    isDisabled
                  />
                </div>
                {/*  --------------------- end Third Transaction date -----------------------  */}
                {formik.values.operator[0].value === 1 && +parseInt(formik.values.period) > 0 && (
                  <div>
                    {formatMessage(
                      {
                        id: 'Transaction date should not be before than {period} month/s of the report date. If not , show the error message.',
                      },
                      {
                        period: parseInt(formik.values.period),
                      }
                    )}
                  </div>
                )}
                {formik.values.operator[0].value === 2 && +parseInt(formik.values.period) > 0 && (
                  <div>
                    {formatMessage(
                      {
                        id: 'Transaction date should not be later than {period} month/s of the report date. If not , show the error message.',
                      },
                      {
                        period: formik.values.period,
                      }
                    )}
                  </div>
                )}
              </div>
            )}
          </Fragment>

          {/* Save and Cancel Buttons */}
          {mode !== 'view' && (
            <>
              <SaveCancelBtnsGroup
                saveBtnLabel='save'
                formik={formik}
                loading={loading}
                cancelOnClick={() => {
                  formik.resetForm();
                  history.push(`/admin/code-rules/${currentCodeRule.id}`);
                }}
              />
            </>
          )}
        </form>
      </div>
    </div>
  );
};
function ViewCodeRules() {
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <ConfigNewCodeRule mode='view' />
    </div>
  );
}

function EditCodeRules() {
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <ConfigNewCodeRule mode='edit' />
    </div>
  );
}

export { ViewCodeRules, EditCodeRules };
