import React from 'react';
import { useRouteMatch } from 'react-router';
import { DockTypesAndRecentActions } from './container/DockTypesAndRecentActions';

const RightSection = () => {
  const isMainPage = useRouteMatch("/dashboard");

  switch (true) {
    case !!isMainPage:
      return <DockTypesAndRecentActions />;
    default:
      return null;
  }
};

export { RightSection };
