import { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import clsx from 'clsx';

import { PageTitle } from '../../../_metronic/layout/core';
import { GeneralNumbersGrid } from './components/GeneralNumbers';
import { HomePageVatStatistics } from './components/HomePageVatStatistics';
// import { HomePageDocumentsStatistics } from './components/HomePageDocumentsStatistics';
import { axiosInstance } from '../../../network/apis';

import { usePermissions } from '../../hooks/usePermissions';
import { CurrentCompanyUser } from '../../types/CompanyTypes';

import { RootState } from '../../../setup/redux/Store';

// import { LinearCalendar } from './LinearCalendar';

const DashboardPage: FC = () => {
  const { formatMessage } = useIntl();
  const { userCan } = usePermissions();

  const history = useHistory();

  // documents statistics
  // const [activeTab, setActiveTab] = useState(1);
  // const [statisticsData, setStatisticsData] = useState({});
  const [generalNumbersData, setGeneralNumbersData] = useState({});

  // vat rate statistics
  const [activeVATTab, setVATActiveTab] = useState(1);
  const [statisticsVATData, setStatisticsVATData] = useState({});

  const currentCompanyUser: CurrentCompanyUser = useSelector<RootState>(
    (state) => state.current.current,
    shallowEqual
  ) as CurrentCompanyUser;

  // request general numbers for documents function
  const requestGeneralNumbers = async () => {
    return await axiosInstance(`/${currentCompanyUser.company?.id}/statistics/generals/all`)
      .then((res) => {
        setGeneralNumbersData(res.data);
        // console.log('request general numbers for documents function', res.data);
        // return res.data;
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error(
            formatMessage({
              id: 'you are not authorized',
            })
          );
        }
        // if (error.response.status !== 200) history.push('/');
      });
  };

  // request VAT card status function
  const requestVATStatistics = async (statisticsVATURL: any) => {
    // console.log(statisticsVATURL);

    return await axiosInstance(statisticsVATURL)
      .then((res) => {
        setStatisticsVATData(res.data);
        // console.log('request VAT card status function', res.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error(
            formatMessage({
              id: 'you are not authorized',
            })
          );
        }
        if (error.response.status !== 200) history.push('/');
      });
  };
  // request document card status function
  // const requestStatistics = async (statisticsURL: any) => {
  //   return await axiosInstance(statisticsURL)
  //     .then((res) => {
  //       setStatisticsData(res.data);
  //       // console.log('request document card status function', res.data);

  //       // return res.data;
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 403) {
  //         toast.error(
  //           formatMessage({
  //             id: 'you are not authorized',
  //           })
  //         );
  //       }
  //       if (error.response.status !== 200) history.push('/');
  //     });
  // };

  // set tab change handler for documents statistics card component
  const setVATTab = (tabId: number) => {
    let statisticsVATURL = '';
    if (currentCompanyUser.company?.id) {
      if (tabId === 1) {
        statisticsVATURL = `/${currentCompanyUser.company?.id}/stats/all`;
      }
      setVATActiveTab(tabId);
      // make request to get card data then pass it to the statistic component as props
      requestVATStatistics(statisticsVATURL);
    }
  };

  // set tab change handler for documents statistics card component
  // const setTab = (tabId: number) => {
  //   let statisticsURL = '';
  //   if (currentCompanyUser.company?.id) {
  //     if (tabId === 1) {
  //       statisticsURL = `/${currentCompanyUser.company?.id}/statistics/all`;
  //     }
  //     if (tabId === 2) {
  //       statisticsURL = `/${currentCompanyUser.company?.id}/statistics/created-by-auth`;
  //     }
  //     if (tabId === 3) {
  //       statisticsURL = `/${currentCompanyUser.company?.id}/statistics/reviewed-by-auth`;
  //     }
  //     if (tabId === 4) {
  //       statisticsURL = `/${currentCompanyUser.company?.id}/statistics/signed-by-auth`;
  //     }
  //     setActiveTab(tabId);
  //     // make request to get card data then pass it to the statistic component as props
  //     requestStatistics(statisticsURL);
  //   }
  // };

  useEffect(() => {
    //vat statistics card
    if (userCan('statistics-company')) {
      setVATTab(1);
    }

    // document statistics card
    // if (userCan('statistics-company')) {
    //   setTab(1);
    // }
    // else {
    //   setTab(2);
    // }
    if (currentCompanyUser.company?.id) {
      requestGeneralNumbers();
    }
    // return function cleanup() {};
  }, [currentCompanyUser.company?.id]);

  return (
    <>
      {/* <div className='date-period-selection-container'></div> */}
      {/* <div>{LinearCalendar}</div> */}
      {/* begin:: Nav */}
      {/*Vat Dashboard */}
      {userCan('statistics-company') && (
        <>
          <ul className='nav py-5'>
            <li className='nav-item'>
              <a
                onClick={() => setVATTab(1)}
                className={clsx('fs-1 nav-link', { disabled: activeVATTab === 1 })}
                style={{ cursor: 'pointer' }}
                id='kt_vat_tab_1'
              >
                <i className='fas fa-coins me-2' />
                <span>{formatMessage({ id: 'Tax statistics' })}</span>
              </a>
            </li>
          </ul>
          <HomePageVatStatistics statisticsVATData={statisticsVATData} />
          <GeneralNumbersGrid generalNumbersData={generalNumbersData} />
        </>
      )}{' '}
      {/* document Dashboard */}
      {/* {userCan('statistics-company') && (
        <>
          <ul className='nav  pt-15 pb-5 px-5'>
            <li className='nav-item'>
              <a
                onClick={() => setTab(1)}
                className={clsx('nav-link', { disabled: activeTab === 1 })}
                style={{ cursor: 'pointer' }}
                id='kt_tab_1'
              >
                <span>{formatMessage({ id: 'All documents statistics' })}</span>
              </a>
            </li>

            <li className='nav-item'>
              <a
                onClick={() => setTab(2)}
                className={clsx('nav-link', { disabled: activeTab === 2 })}
                style={{ cursor: 'pointer' }}
                id='kt_tab_2'
              >
                <span>{formatMessage({ id: 'Documents I created' })}</span>
              </a>
            </li>
            <li className='nav-item'>
              <a
                onClick={() => setTab(3)}
                className={clsx('nav-link', { disabled: activeTab === 3 })}
                style={{ cursor: 'pointer' }}
                id='kt_tab_3'
              >
                <span>{formatMessage({ id: 'Documents I approved' })}</span>
              </a>
            </li>
            <li className='nav-item'>
              <a
                onClick={() => setTab(4)}
                className={clsx('nav-link', { disabled: activeTab === 4 })}
                style={{ cursor: 'pointer' }}
                id='kt_tab_4'
              >
                <span>{formatMessage({ id: 'Documents I signed' })}</span>
              </a>
            </li>
          </ul>
          <HomePageDocumentsStatistics statisticsData={statisticsData} />
          <GeneralNumbersGrid generalNumbersData={generalNumbersData} />
        </>
      )} */}
      {/* end:: Nav */}
    </>
  );
};

const DashboardWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Dashboard' })}</PageTitle>
      <DashboardPage />
    </div>
  );
};

export { DashboardWrapper };
