import clsx from 'clsx';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  formik?: any;
  labelId?: string;
  withValidation?: boolean;
  name: string;
  type?: string;
  [something: string]: any;
};

export const TextArea: FC<Props> = ({ labelId, formik, withValidation, name, type, ...props }) => {
  const [currentType, setCurrentType] = useState(type || 'text');

  const toggleState = () => setCurrentType(currentType === 'password' ? 'text' : 'password');

  return (
    <>
      {labelId && (
        <label
          className={clsx('form-label fs-6 my-1 fw-bolder text-dark', {
            required: !!props.required,
          })}
          htmlFor={name + '_input'}
        >
          <FormattedMessage id={labelId} />
        </label>
      )}
      <textarea
        {...formik?.getFieldProps(name)}
        id={name + '_input'}
        className={clsx(
          'form-control form-control-lg b-radius-16 basic-input-text-area my-1',
          { 'is-invalid': withValidation && formik?.touched?.[name] && formik?.errors?.[name] },
          {
            'is-valid': withValidation && formik?.touched?.[name] && !formik?.errors?.[name],
          }
        )}
        type={currentType}
        name={name}
        autoComplete='off'
        {...props}
      />
      {type === 'password' && (
        <i
          id='toggle-password-visibility'
          data-testid='toggle-password-visibility'
          className={clsx('cursor-pointer far', {
            'fa-eye-slash': currentType === 'text',
            'fa-eye': currentType === 'password',
          })}
          onClick={() => toggleState()}
        ></i>
      )}

      {formik?.touched?.[name] && formik?.errors?.[name] && (
        <div className='fv-plugins-message-container mt-2 mx-3'>
          <span role='alert' className='text-danger'>
            {formik?.errors?.[name]}
          </span>
        </div>
      )}
    </>
  );
};
