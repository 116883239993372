import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import * as DocumentsRedux from './store/DocumentsRedux';

import { PageTitle } from '../../../_metronic/layout/core';
import { CommonTable } from '../../shared/tables/Table';
import { RootState } from '../../../setup/redux/Store';
import { CompanyModel } from '../companies/CompanyModel';
import {
  DateViewer,
  TableActions,
  TableData,
  TableSelection,
  TableStatus,
} from '../../shared/tables/classes';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { IDocument, DocumentsDataListModel } from './types/DocumentsModel';
import {
  DOCS_STATUS,
  DocumentDirection,
  DocumentStatuses,
  RecipientTypeMap,
} from '../../../_metronic/constants/general';
import { Badge } from '../../../_metronic/helpers/typescript/csssClasses';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { PreviewItem } from '../../shared/tables/actions/PreviewItem';
import { KTSVG } from '../../../_metronic/helpers';

const TABLE_NAME = 'DOCUMENTS_TO_TAKE_ACTION';

const headerItems = [
  { id: 'Document Title', maxWidth: 400, width: 400 },
  { id: 'Type of Document', maxWidth: 400, width: 400 },
  { id: 'Creation Date', maxWidth: 200, width: 200 },
  { id: 'Document Created By', maxWidth: 300, width: 300 },
  { id: 'Recipient type', maxWidth: 200, width: 200 },
  { id: 'Status', maxWidth: 240, width: 240 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];

export const DocumentsToTakeAction: FC = () => {
  return <DocumentsTable />;
};

export const DocumentsTable: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  // const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const documents: DocumentsDataListModel = useSelector<RootState>(
    (state) => state.USER_documents.documents,
    shallowEqual
  ) as DocumentsDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(DocumentsRedux.actions.fulfillDocuments({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      DocumentsRedux.actions.getDocumentsToTakeAction({
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (table?.page) {
      RequestData();
    }
  }, [table?.page, table?.pagination, table?.query, params.companySlug, companyDetails.id]);

  const rowItems = documents?.data.map((document: IDocument) => [
    new TableSelection({
      display: false,
      id: document.id,
      tableName: TABLE_NAME,
    }),
    new TableData({ text: document?.title }),
    new TableData({ text: $t(RecipientTypeMap.get(document?.recipient_type)?.label || '') }),
    new DateViewer({ date: document.created_at }),
    new TableData({ text: document?.user?.name }),
    new TableData({ text: $t(DocumentDirection.get(document?.type)?.label || '') }),
    new TableStatus({
      statusId: formatMessage({ id: `${DocumentStatuses.get(document?.status)?.label}` }),
      badge: `${DocumentStatuses.get(document?.status)?.badge}` as Badge,
    }),
    new TableActions(
      (
        /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
        <div className='d-flex flex-row'>
          <PreviewItem
            title={$t('Preview')}
            onClick={() => {
              window.open(
                document?.preview_link,
                '_blank',
                'toolbar=yes,scrollbars=yes,resizable=yes'
              );
            }}
          />
          <ViewItem
            title={$t('View')}
            onClick={() => history.push(`/d/documents/${document.id}`)}
          />
          {document.status !== DOCS_STATUS.CLOSED && (
            <a
              title={$t('Take Action')}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                dispatch(
                  DocumentsRedux.actions.fulfillDocumentToTakeAction({
                    documentToTakeAction: document,
                  })
                );
                history.push(`/d/documents/${document.id}`);
              }}
            >
              <KTSVG
                path='/media/icons/duotune/art/art008.svg'
                className='svg-icon-2 svg-icon-gray-500'
              />
            </a>
          )}
        </div>
      )
    ),
  ]);

  return (
    <div className='table-wrapper'>
      <CommonTable
        tableName={TABLE_NAME}
        initPerPage={20}
        allDisplayedIds={documents?.data?.map((document) => document.id) || []}
        meta={documents}
        headerItems={headerItems}
        rowItems={rowItems}
      />
    </div>
  );
};

const DocumentsToTakeActionWrapper: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'my_docs' })}</PageTitle>
      <DocumentsToTakeAction />
    </div>
  );
};

export { DocumentsToTakeActionWrapper };
