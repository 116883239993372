import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyDocuments = endpoints.companyDocuments;
export const companyDocumentsToTakeAction = endpoints.companyDocumentToTakeAction;
export const companyunderReviewDocuments = endpoints.companyunderReviewDocuments;
export const createDocumentFromTemplateIdAPI = endpoints.cloneDocumentFromTemplate;

export function getDocumentList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyDocuments(companyId), { params });
}

export function getDocumenToTakeActiontList({ params }: { params?: any }) {
  return axiosInstance.get(companyDocumentsToTakeAction, { params });
}

export function getUnderReviewDocumentsRequest({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyunderReviewDocuments(companyId), { params });
}

export function createDocumentFromTemplateIdRequest({
  companyId,
  templateId,
  title,
}: {
  companyId: ID;
  templateId: ID;
  title: string;
}) {
  return axiosInstance.post(createDocumentFromTemplateIdAPI({ companyId, templateId }), {
    title,
  });
}
