import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { getAllVatRates, getVatRate } from './VatRatesCRUD';

export enum IVatRatesActionTypes {
  GET_VAT_RATES = 'GET_VAT_RATES',
  FULL_FILL_VAT_RATES = 'FULL_FILL_VAT_RATES',
  GET_VAT_RATE = 'GET_VAT_RATE',
  FULL_FILL_VAT_RATE = 'FULL_FILL_VAT_RATE',
}

const initialVatRates: IVatRateState = {
  vat_rates: undefined,
  vat_rate: undefined,
};

export interface IVatRateState {
  vat_rates?: any;
  vat_rate?: any;
}

export const reducer = persistReducer(
  { storage, key: 'SYS_vat_rates', whitelist: [''] },
  (state: IVatRateState = initialVatRates, action: ActionWithPayload<IVatRateState>) => {
    switch (action.type) {
      case IVatRatesActionTypes.FULL_FILL_VAT_RATES:
        return { ...state, vat_rates: action.payload?.vat_rates };
      case IVatRatesActionTypes.FULL_FILL_VAT_RATE:
        return { ...state, vat_rate: action.payload?.vat_rate };
      default:
        return state;
    }
  }
);

type Search = { params?: any };

export const actions = {
  getVatRates: ({ params }: Search) => ({
    type: IVatRatesActionTypes.GET_VAT_RATES,
    payload: { params },
  }),
  fullFillVatRates: ({ vat_rates }: IVatRateState) => ({
    type: IVatRatesActionTypes.FULL_FILL_VAT_RATES,
    payload: { vat_rates },
  }),
  getVatRate: ({ vatRateId }: any) => ({
    type: IVatRatesActionTypes.GET_VAT_RATE,
    payload: { vatRateId },
  }),
  fullFillVatRate: ({ vat_rate }: IVatRateState) => ({
    type: IVatRatesActionTypes.FULL_FILL_VAT_RATE,
    payload: { vat_rate },
  }),
};

export function* getVatRates(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllVatRates({
      params: action.payload?.params,
    });
    yield put(actions.fullFillVatRates({ vat_rates: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentVatRate(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getVatRate({
      vatRateId: action.payload?.vatRateId,
    });
    yield put(actions.fullFillVatRate({ vat_rate: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IVatRatesActionTypes.GET_VAT_RATES, getVatRates);
  yield takeLatest(IVatRatesActionTypes.GET_VAT_RATE, getCurrentVatRate);
}
