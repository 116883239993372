import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyPurchasesLineItems = endpoints.companyPurchasesLineItems;
export const companySinglePurchasesLineItems = endpoints.companySinglePurchasesLineItems;

export function getAllPurchasesInvoiceItems({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyPurchasesLineItems(companyId), { params });
}

export function getPurchasesLineItem({ companyId, purchaseId }: { companyId: ID; purchaseId: ID }) {
  return axiosInstance.get(companySinglePurchasesLineItems(companyId, purchaseId));
}
