import clsx from 'clsx';
import { Badge } from '../../../../_metronic/helpers/typescript/csssClasses';
import { FormatMessage } from '../../../../_metronic/i18n/formatMessage';

interface IStatusField {
  badge: Badge | undefined;
  statusId: string;
  title?: string | undefined;
}

export class TableStatus {
  constructor(private fieldData: IStatusField) {}

  renderItem() {
    return (
      <span className={clsx('badge', this.fieldData.badge)} title={this.fieldData.title ?? ''}>
        <FormatMessage id={this.fieldData.statusId} />
      </span>
    );
  }
}
