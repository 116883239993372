import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'; //
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; //, Route, Switch
import { RootState } from '../../../setup/redux/Store';
import { usePermissions } from '../../hooks/usePermissions';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { PageTitle } from '../../../_metronic/layout/core';
import { Portal } from '../../../_metronic/partials';
import { CreateCompanyForm } from '../../pages/Companies/CreateCompanyForm';
import { ModalName } from '../../types/types';
import { CompaniesDataListModel } from './CompanyModel'; //CompanyModel,

import * as ModalRedux from '../../store/ModalRedux';
import * as UserCompaniesRedux from '../../../app/modules/companies/store/UserCompaniesRedux';
import * as CurrentCompany from '../../../app/store/CurrentCompanyRedux';
import { axiosInstance } from '../../../network/apis';
// import { ProfileHeader } from '../profile/ProfileHeader';
import { CommonCardListTable } from '../../shared/tables/CardListTable';
// import { Projects } from '../profile/components/Projects';

const TABLE_NAME = 'companies_list';

const Companies: FC = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const params: any = useParams();
  /* --------------------------------- Leaving -------------------------------- */
  // useEffect(() => {
  //   return () => {
  //     dispatch(UserCompaniesRedux.actions.fulFillCompanies({}));
  //   };
  // }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  const modal: ModalName = useSelector<RootState>(
    (state) => state.modal.activeModal,
    shallowEqual
  ) as ModalName;

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];
  function RequestData() {
    dispatch(
      UserCompaniesRedux.actions.getUserCompanies({
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
    dispatch(UserCompaniesRedux.actions.getAccountOwnerInfo());
    // dispatch(
    //   ClientsRedux.actions.getClients({
    //     companyId: companyDetails.id,
    //     params: {
    //       ...table?.query,
    //       page: table?.page,
    //       per_page: table?.pagination,
    //     },
    //   })
    // );
  }

  useEffect(() => {
    if (table?.page) {
      RequestData();
    }
  }, [table?.page, table?.pagination, table?.query]);

  // getusersData  from redux
  const user: any = useSelector<RootState>((state) => state.auth.user, shallowEqual) as any;
  const accountOwnerInfo: any = useSelector<RootState>(
    (state) => state?.USER_companies.accountOwnerInfo,
    shallowEqual
  ) as any;

  useEffect(() => {
    if (user && params?.companySlug) {
      const getData = async () => {
        const response = await axiosInstance.post(`${params?.companySlug}/switch`);
        dispatch(CurrentCompany.actions.setCurrentCompany({ ...response.data }));
      };

      getData();
    } else if (user) {
      const getData = async () => {
        await dispatch(CurrentCompany.actions.setCurrentCompany({ company: null, user: user }));
      };

      getData();
    }
  }, [user]);

  //.......................... Leaving ............................./
  useEffect(() => {
    return () => {
      dispatch(UserCompaniesRedux.actions.fulfillAccountOwnerInfo({}));
    };
  }, []);

  //.......................... End Leaving ............................./

  return (
    <div className='mb-10'>
      {userCan('create-company') &&
        accountOwnerInfo?.available_companies_count > accountOwnerInfo?.owned_companies_count && (
          <div className='d-flex flex-row-reverse mb-5'>
            <button
              className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
              onClick={() => dispatch(ModalRedux.actions.setActiveModal('create-company'))}
            >
              <span className='mt-0'>
                <i className='far fa-suitcase text-offWhite' />
                <FormattedMessage id='Create New Company' />
              </span>
            </button>
          </div>
        )}

      {modal === 'create-company' && (
        <Portal className='full_modal'>
          <AhadDialog titleId='Add New Company'>
            <CreateCompanyForm />
          </AhadDialog>
        </Portal>
      )}
      <CommonCardListTable
        tableName={TABLE_NAME}
        initPerPage={20}
        allDisplayedIds={companies?.data?.map((client) => client.id) || []}
        meta={companies}
        cardItems={companies?.data}
      />
    </div>
  );
};

const CompaniesSelectionWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Companies' })}</PageTitle>
        <Companies />
      </div>
      {/* <Companies /> */}

      {/* <ProfileHeader />
      <Switch>
        <Route path='/'>
          <Companies />
        </Route>
      </Switch> */}
    </>
  );
};

export { CompaniesSelectionWrapper };
