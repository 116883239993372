import { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { RootState } from '../../../../setup/redux/Store';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../../_metronic/layout/core';
import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
import { EditItem } from '../../../shared/tables/actions/EditItem';
import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import { CommonTable } from '../../../shared/tables/Table';
import { CompanyModel } from '../../companies/CompanyModel';
import { UsersDataListModel } from './types/usersList';
import { SerachFilters } from '../../../shared/SearchFilters';

import * as TableRedux from '../../../store/TableRedux';
import * as UsersRedux from './store/UsersGroupRedux';
import * as RolesRedux from '../../roles/store/RolesRedux';

import { TableStatus, ItemWithImage } from '../../../shared/tables/classes';
import { TableData } from '../../../shared/tables/classes/TableData';
import { TableActions } from '../../../shared/tables/classes/TableActions';
import { TableSelection } from '../../../shared/tables/classes/TableSelection';
import { DateViewer } from '../../../shared/tables/classes/DateViewer';
import { TableRolesViewer } from '../../../shared/tables/classes/TableRolesViewer';
import { usePermissions } from '../../../hooks/usePermissions';
import { Button } from '../../../shared/Button';

const headerItems = [
  { id: 'Name', maxWidth: 440, width: 440 },
  { id: 'Email', maxWidth: 240 },
  { id: 'Roles', maxWidth: 300, width: 300 },
  { id: 'Last Active', maxWidth: 270, width: 270 },
  { id: 'Status', maxWidth: 100, width: 100 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];

const TABLE_NAME = 'users_list';

export const UserGroups: FC = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  /* -------------------------- Bring Data from store ------------------------- */

  return (
    <div>
      <div className='d-flex flex-row-reverse'>
        {/* // TODO: permissions */}
        {userCan('invite-user') && (
          <Button
            onClick={() => history.push(`/c/${params.companySlug}/groups/users/cu-users-group`)}
            fa='plus'
            nameId='Add user group'
            btnType='outlined'
          />
        )}
      </div>
      {/*  ------------------------ start search and filters ------------------------  */}
      <SerachFilters
        leftHandSide={[
          {
            type: 'search',
            queryTerm: 'name',
            onChange: (value: string) => {
              dispatch(
                TableRedux.actions.updateQuery({
                  value: value,
                  tableName: TABLE_NAME,
                  queryTerm: 'name',
                })
              );
            },
            placeholderId: 'Search anything',
          },
        ]}
        rightHandSide={[]}
      />

      {/*  ------------------------- End Search and filters -------------------------  */}

      <div className='table-wrapper'>
        <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline mx-5'>
          {/* // TODO: permissions */}
          {userCan('delete-user') && <Button fa='trash' nameId='Delete' btnType='outlined' />}
        </div>
        {/*  ---------------------------- Start users table ---------------------------  */}
        <UsersGroupsTable />
        {/*  ---------------------------- End users table ---------------------------  */}
      </div>
    </div>
  );
};

export const UsersGroupsTable: FC = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const users: UsersDataListModel = useSelector<RootState>(
    (state) => state.USER_users.Users,
    shallowEqual
  ) as UsersDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = useMemo(() => tables[TABLE_NAME], [tables[TABLE_NAME]]);
  /* ----------------------- Getting roles for dropdown ----------------------- */
  useEffect(() => {
    if (companyDetails?.id && params.companySlug) {
      dispatch(
        RolesRedux.actions.getRoles({ companyId: companyDetails.id, params: { per_page: 0 } })
      );
    }
  }, [dispatch, companyDetails?.id, params.companySlug]);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(UsersRedux.actions.fulfillUsers({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  useEffect(() => {
    if (params.companySlug && companyDetails.id && table?.page) {
      const table = tables[TABLE_NAME];
      dispatch(
        UsersRedux.actions.getUsers({
          companyId: companyDetails.id,
          params: {
            ...table?.query,
            page: table?.page,
            per_page: table?.pagination,
          },
        })
      );
    }
  }, [table?.page, table?.pagination, table?.query, params.companySlug, companyDetails.id]);

  const rowItems = users?.data.map((user) => [
    new TableSelection({ display: userCan('delete-user'), id: user.id, tableName: TABLE_NAME }),
    new ItemWithImage({ name: user.name, image: user?.image }),
    new TableData({ text: user.email }),
    new TableRolesViewer({ roles: user.roles }),
    new DateViewer({ date: user.created_at || 'Undefined' }),
    new TableStatus({
      statusId: user.active ? 'Active' : 'Non active',
      badge: user.active ? 'badge-success' : 'badge-lightGreyBlue',
    }),
    new TableActions(
      (
        /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
        <div className='d-flex flex-row'>
          <ViewItem
            title={$t('View')}
            onClick={() =>
              history.push(`/c/${companyDetails.slug}/company-management/users/${user.id}`)
            }
          />
          {user.id !== companyDetails.owner.id && (
            <>
              {userCan('update-user') && (
                <EditItem
                  title={$t('Edit')}
                  onClick={() =>
                    history.push(
                      `/c/${companyDetails.slug}/company-management/users/${user.id}/edit`
                    )
                  }
                />
              )}
              {userCan('delete-user') && <DeleteItem title={$t('Delete')} />}
            </>
          )}
        </div>
      )
    ),
  ]);

  return (
    <div>
      <CommonTable
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={userCan('delete-user')}
        allDisplayedIds={users?.data?.map((user) => user.id)}
        meta={users}
        headerItems={headerItems}
        rowItems={rowItems}
      />
    </div>
  );
};

const UsersGroupsWrapper: FC = () => {
  // TODO: permissions
  usePermissions('view-user');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Users Groups' })}</PageTitle>
      <UserGroups />
    </div>
  );
};

export { UsersGroupsWrapper };
