import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import * as TableRedux from '../../store/TableRedux';
import * as TemplatesRedux from './store/TemplatesRedux';

import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { SerachFilters } from '../../shared/SearchFilters';
import { CommonTable } from '../../shared/tables/Table';
import { RootState } from '../../../setup/redux/Store';
import { CompanyModel } from '../companies/CompanyModel';
import { DateViewer, TableActions, TableData, TableSelection } from '../../shared/tables/classes';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { ITemplate, TemplatesDataListModel } from './types/TemplatesModel';
import { DocumentDirection, RecipientTypeMap } from '../../../_metronic/constants/general';
import { SelectOption } from '../../types/SelectOptions';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { UseTemplateToCreateDocument } from '../../shared/tables/actions/UseTemplateToCreateDocument';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { PreviewItemSettings } from '../../shared/tables/actions/PreviewItemSettings';
import { PreviewItem } from '../../shared/tables/actions/PreviewItem';

import { Portal } from '../../../_metronic/partials';
import AhadDialog from '../../../setup/shared/AhadDialog';

const TABLE_NAME = 'TEMPLATES_TABLE';

const headerItems = [
  { id: 'Template Title', maxWidth: 240, width: 230 },
  { id: 'Template Created By', maxWidth: 240, width: 230 },
  { id: 'Recipient type', maxWidth: 230, width: 230 },
  { id: 'Type of Template', maxWidth: 400, width: 400 },
  { id: 'Creation Date', maxWidth: 200, width: 200 },
  { id: 'Actions', maxWidth: 150, width: 150 },
];

export const Templates: FC = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { locale } = useIntl();
  const params: any = useParams();

  const typesOptions = useMemo(
    () =>
      Array.from(RecipientTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  return (
    <div>
      {/*  ------------------------- End Search and filters -------------------------  */}
      <div className='d-flex flex-row-reverse'>
        {userCan('create-template') && (
          <div className='d-flex flex-row-reverse'>
            {userCan('create-template') && (
              <button
                className='btn btn-outlined'
                onClick={() => {
                  history.push(`/c/${params.companySlug}/templates/create`);
                }}
              >
                <i className='fas fa-mail-bulk'></i> <FormattedMessage id='Create Template' />
              </button>
            )}
          </div>
        )}
      </div>
      {/*  ------------------------ start search and filters ------------------------  */}
      <SerachFilters
        leftHandSide={[
          {
            type: 'search',
            queryTerm: 'name',
            onChange: (value: string) => {
              dispatch(
                TableRedux.actions.updateQuery({
                  value: value,
                  tableName: TABLE_NAME,
                  queryTerm: 'name',
                })
              );
            },
            placeholderId: 'Search anything',
          },
        ]}
        rightHandSide={[
          {
            type: 'dropdown',
            queryTerm: 'type',
            onChange: (value: any) =>
              dispatch(
                TableRedux.actions.updateQuery({
                  value: value,
                  tableName: TABLE_NAME,
                  queryTerm: 'type',
                })
              ),
            placeholderId: 'Recipient type',
            options: [...DocumentDirection.values()].map((val: SelectOption) => ({
              value: val.value,
              label: $t(val.label),
            })),
          },
          {
            type: 'dropdown',
            queryTerm: 'recipient_type',
            onChange: (value: any) =>
              dispatch(
                TableRedux.actions.updateQuery({
                  value: value,
                  tableName: TABLE_NAME,
                  queryTerm: 'recipient_type',
                })
              ),
            placeholderId: 'Type of Template',
            options: typesOptions,
          },
        ]}
      />

      <TemplatesTable />
    </div>
  );
};

export const TemplatesTable: FC = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [previewEditModal, setPreviewEditModal] = useState<any>();
  const params: any = useParams();
  // const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const templates: TemplatesDataListModel = useSelector<RootState>(
    (state) => state.USER_templates.templates,
    shallowEqual
  ) as TemplatesDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(TemplatesRedux.actions.fulfillTemplates({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      TemplatesRedux.actions.getTemplates({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  }, [table?.page, table?.pagination, table?.query, params.companySlug, companyDetails.id]);

  const rowItems = templates?.data.map((template: ITemplate) => [
    new TableSelection({
      display: userCan('delete-template'),
      id: template.id,
      tableName: TABLE_NAME,
    }),
    new TableData({ text: template?.title }),
    new TableData({ text: template?.user?.name }),
    new TableData({ text: $t(DocumentDirection.get(template?.type)?.label || '') }),
    new TableData({ text: $t(RecipientTypeMap.get(template?.recipient_type)?.label || '') }),
    new DateViewer({ date: template.created_at }),
    new TableActions(
      (
        /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
        <div className='d-flex flex-row'>
          {userCan('view-document') && (
            <PreviewItem
              title={$t('Preview')}
              onClick={() =>
                window.open(
                  template.preview_link,
                  '_blank',
                  'toolbar=yes,scrollbars=yes,resizable=yes'
                )
              }
            />
          )}
          {userCan('update-template') && (
            <EditItem
              title={$t('Edit')}
              data-testid={`template-${template.id}`}
              onClick={() => {
                if (
                  Object.entries(template.documents)
                    .filter((type) => !['closed', 'expired'].includes(type[0]))
                    .some((docs) => docs[1] > 0)
                ) {
                  setPreviewEditModal(template);
                } else {
                  history.push(`/c/${companyDetails.slug}/templates/update/${template.id}`);
                }
              }}
            />
          )}
          {userCan('update-template') && (
            <PreviewItemSettings
              title={$t('Preview Configuration')}
              // data-testid={`template-${template.id}`}
              onClick={() => {
                if (
                  Object.entries(template.documents)
                    .filter((type) => !['closed', 'expired'].includes(type[0]))
                    .some((docs) => docs[1] > 0)
                ) {
                  setPreviewEditModal(template);
                } else {
                  history.push(
                    `/c/${companyDetails.slug}/templates/preview-configurations/${template.id}`
                  );
                }
              }}
            />
          )}
          {/* {userCan('delete-template') && (
            <DeleteItem
              id={template.id}
              messageId={'Are you sure you want to delete this template?'}
              setDeleteItemId={setDeleteItemId}
              deleteItemId={deleteItemId}
              deleteURI={`/${companyDetails.id}/templates`}
              payload={[template.id]}
              successMessage={'Template has been deleted successfully!'}
              afterSucessRequest={() => RequestData()}
            />
          )} */}
          {template.has_content && template.can_create && (
            <UseTemplateToCreateDocument templateId={template.id} />
          )}
        </div>
      )
    ),
  ]);

  return (
    <div className='table-wrapper'>
      {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5'> */}
      {/* {userCan('delete-template') && (
          <DeleteItem
            isButton
            messageId={'Are you sure you want to delete this template?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/templates`}
            payload={deleteItemId}
            successMessage={'Template has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )} */}
      {/* </div> */}
      <CommonTable
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={userCan('delete-template')}
        allDisplayedIds={templates?.data?.map((template) => template.id) || []}
        meta={templates}
        headerItems={headerItems}
        rowItems={rowItems}
      />

      {previewEditModal && (
        <Portal className='full_modal'>
          <AhadDialog titleId={$t('Template Edit')} closeCallBack={() => setPreviewEditModal(null)}>
            <>
              <h2>
                <FormattedMessage id='There are documents linked with this template, are you sure you want to edit it?' />
              </h2>
              <ul>
                {Object.entries(previewEditModal.documents)
                  .filter((status) => !['closed', 'expired'].includes(status[0]))
                  .map(([status, docCount]: any) => {
                    return (
                      <li>
                        <FormattedMessage
                          id='document_number_report'
                          values={{
                            status: formatMessage({ id: `STATUS_${status.toUpperCase()}` }),
                            docCount,
                          }}
                        />
                      </li>
                    );
                  })}
              </ul>

              <button
                className='btn btn-sm btn-primary btn-shadow btn-primary-shadow me-5'
                onClick={() => {
                  history.push(`/c/${companyDetails.slug}/templates/update/${previewEditModal.id}`);
                  setPreviewEditModal(null);
                }}
              >
                {$t('Confirm')}
              </button>
              <button
                className='btn btn-sm btn-danger btn-shadow btn-danger-shadow'
                onClick={() => setPreviewEditModal(null) /* Closes Modal */}
              >
                {$t('Cancel')}
              </button>
            </>
          </AhadDialog>
        </Portal>
      )}
    </div>
  );
};

const TemplatesWrapper: FC = () => {
  usePermissions(['view-template', 'create-document']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Templates' })}</PageTitle>
      <Templates />
    </div>
  );
};

export { TemplatesWrapper };
