import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { companyStatuses } from '../../../types/companies';
import { CompanyModel } from '../CompanyModel';
import { axiosInstance } from '../../../../network/apis';
import * as CurrentCompany from '../../../../app/store/CurrentCompanyRedux';
import { useDispatch, useSelector } from 'react-redux';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
import * as UserCompaniesRedux from '../../../../app/modules/companies/store/UserCompaniesRedux';
import { RootState } from '../../../../setup';

const TABLE_NAME = 'companies_list';

type Props = {
  company: CompanyModel;
};

const statusIcon = {
  'New Company': 'layer-plus',
  'Needed to edit': 'money-check-edit',
  'Under Review': 'search',
  Approved: 'check-circle',
  'Deletion requested': 'trash',
  Deleted: 'trash',
};

const companyStatusesBorderColors: [companyStatuses, string][] = [
  ['New Company', '#ffce03'], //yellow
  ['Approved', '#03d5a2'], // green
  ['Needed to edit', '#e1306c'], // red
  ['Under Review', '#ffce03'], //yellow
  ['Deletion requested', '#e1306c'], // red
  ['Deleted', '#e1306c'], // red
];
export const companyStatusColorsMap = new Map(companyStatusesBorderColors);

const relation: [string, { color: string; icon: string }][] = [
  ['owner', { color: '#ffce03', icon: 'fa-star' }], //yellow
  ['admin', { color: '#bce3ff', icon: 'fa-gem' }], // green
  ['user', { color: 'white', icon: 'fa-user' }], // red
];
export const relationMap = new Map(relation);

export const CompanyCard: FC<Props> = ({ company }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const [deleteCompanieId, setDeleteCompanieId] = useState<number | null>(null);
  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[TABLE_NAME];

  return (
    <div
      onClick={() => history.push(`/c/${company?.slug}/dashboard`)}
      className='b-radius-20 d-flex flex-column position-relative justify-content-start align-items-center cursor-pointer company-card bg-white'
      style={{
        maxWidth: '388px',
        maxHeight: '305px',
        height: '305px',
        pointerEvents: `${company.status === 'Deletion requested' ? 'none' : 'auto'}`,
        opacity: `${company.status === 'Deletion requested' ? 0.7 : 1}`,
      }}
    >
      <div
        style={{
          height: '68%',
          width: '100%',

          borderBottom: `1px solid #cedcee`,
          backgroundImage: `url(${
            company.branding.logo.light
              ? company.branding.logo.light
              : company.branding.logo.dark
              ? company.branding.logo.dark
              : company.short_name
              ? ''
              : toAbsoluteUrl('/media/svg/CMD.svg')
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '25%',
        }}
        className='b-radius-20 position-relative'
      >
        {company.status && (
          <div className='company-card-status position-absolute m-auto text-center pt-1 fs-6'>
            <div className='p-1 fs-5'>
              <i className={`far fa-${statusIcon[company.status]} text-success`}></i>{' '}
              <FormattedMessage id={company.status} />
            </div>
          </div>
        )}
        <div
          className='company-card-role position-absolute mx-5 my-3'
          style={{
            background: relationMap.get(company.role)?.color || '',
          }}
        >
          <i className={`far ${relationMap.get(company.role)?.icon}`} />{' '}
          {company.role && <FormattedMessage id={company.role} />}
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className='my-4 position-absolute'
          style={{
            right: `${locale === 'en' ? '2%' : '88%'}`,
          }}
        >
          {(company.can_send_delete_request || company.can_update_company) && (
            <div className='dropdown'>
              <button
                className='btn btn-icon-primary btn-sm btn-icon btn-active-light-primary ms-1 dropdown-toggle-button
                
              '
                type='button'
                id={'dropdownMenuButton1' + company.id}
                data-bs-toggle='dropdown'
                auto-bs-close='true'
                data-bs-auto-close='true'
                aria-expanded='false'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  // get value of id from button
                  const id = e.currentTarget.id;

                  // remove show class from all dropdowns and show class from all buttons
                  const dropdowns = document.querySelectorAll('.dropdown-menu');
                  dropdowns.forEach((dropdown) => {
                    dropdown.classList.remove('show');
                  });
                  const buttons = document.querySelectorAll('.dropdown-toggle-button');
                  buttons.forEach((button) => {
                    button.classList.remove('show');
                  });

                  // add show class to current dropdown
                  const dropdown = document.querySelector(`#${id} + .dropdown-menu`);
                  dropdown?.classList.add('show');
                }}
              >
                <i className='far fa-ellipsis-h' />
              </button>
              <ul
                className='dropdown-menu'
                aria-labelledby={'dropdownMenuButton1' + company.id}
                style={{
                  zIndex: 99,
                }}
              >
                {company.can_update_company && (
                  <li
                    onClick={async () => {
                      const response = await axiosInstance.post(`${company.slug}/switch`);
                      dispatch(CurrentCompany.actions.setCurrentCompany({ ...response.data }));
                      history.push(`/c/${company.slug}/settings/companydetails`);
                    }}
                  >
                    <button className='btn btn-outlined' title={formatMessage({ id: 'Edit' })}>
                      <i className='far fa-edit' /> <FormattedMessage id='Edit' />
                    </button>
                    {/* <Link className='dropdown-item' to={`/c/${company.slug}/settings/companydetails`}> */}

                    {/* </Link> */}
                  </li>
                )}
                {company.can_send_delete_request && (
                  <li>
                    <DeleteItem
                      id={company.id}
                      title={$t('Delete')}
                      messageId={'messageDeleteCompany'}
                      showButtonGroup={true}
                      setDeleteItemId={setDeleteCompanieId}
                      deleteItemId={deleteCompanieId}
                      deleteURI={`companies/${company.id}/send-deletion-request`}
                      payload={company.id}
                      successMessage={'company has been deleted successfully!'}
                      afterSucessRequest={() => {
                        dispatch(
                          UserCompaniesRedux.actions.getUserCompanies({
                            params: {
                              ...table?.query,
                              page: table?.page,
                              per_page: table?.pagination,
                            },
                          })
                        );
                      }}
                      methodType='delete'
                      isCompanyButton
                    />
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        {company?.short_name && !company?.branding?.logo?.light && !company?.branding?.logo?.dark && (
          <div className='d-flex justify-content-center align-items-center h-100 mt-5'>
            <div className='symbol symbol-125px symbol-circle'>
              <span className='symbol-label bg-light-primary text-primary'>
                <h2 style={{ fontSize: '3em' }} className='mt-2'>
                  {company.short_name.slice(0, 1)}
                </h2>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='pt-10 fs-2'>{company.name || company.slug}</div>
    </div>
  );
};
