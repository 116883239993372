import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RootState } from '../../../setup';
import { PrivateRoutes } from '../../../_metronic/constants/ROUTES';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { ICompany } from '../../types/companies';

const NewCompanyAdded: FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const lastAddedCompany: ICompany = useSelector<RootState>(
    (state) => state.USER_companies.lastAddedCompany,
    shallowEqual
  ) as ICompany;

  const currentCompany: ICompany = useSelector<RootState>(
    (state) => state?.current?.current?.company,
    shallowEqual
  ) as ICompany;

  useEffect(() => {
    if (!lastAddedCompany?.id) {
      toast.info(
        formatMessage({
          id: 'No company added this session, you will be redirected to companies list',
        })
      );
      history.push(PrivateRoutes.Companies);
    }
  });

  return lastAddedCompany?.id ? (
    <div
      className='b-radius-20 d-flex justify-content-center align-items-center position-relative'
      style={{ backgroundColor: '#f3f5fb', width: '100%', height: '60vh' }}
    >
      <div
        className='b-radius-30 bg-white d-flex flex-column gap-3 justify-content-center align-items-center'
        style={{ width: '45%', height: '70%' }}
      >
        <i
          className='fas fa-check-circle text-success'
          style={{ fontSize: '100px', marginTop: '-6rem' }}
        ></i>
        <h2 data-testid='slug' className='mt-12'>
          {lastAddedCompany.slug}
        </h2>
        <h5 className='text-metallicBlue'>{$t(lastAddedCompany.type)}</h5>
        <button
          className='btn btn-sm btn-blue w-40 mb-5  shadowed mt-4'
          onClick={() => history.push(`/c/${lastAddedCompany.slug}/settings/companydetails`)}
          disabled={currentCompany?.id ? false : true}
        >
          <FormattedMessage id='Complete company profile' />
        </button>
      </div>
    </div>
  ) : null;
};

const NewCompanyAddedWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'New Company Added' })}</PageTitle>
      <NewCompanyAdded />
    </div>
  );
};

export { NewCompanyAddedWrapper };
