import { companyStatuses } from './companies';
import { IRole } from './user';

export enum CompanyTypes {
  'Freelancer' = 1,
  'Corporate' = 2,
}

export enum ClientTypes {
  'Entity' = 1,
  'Individual' = 2,
}

export enum PersonDecisionTypes {
  'Not Approver' = 0,
  'Approver' = 1,
}

export enum ThirdPartyTypes {
  'Client' = 1,
  'Vendor' = 2,
  'Both' = 3,
}
export enum AccountTypes {
  'Account' = 1,
  'Aggregate' = 2,
}

export interface CurrentCompanyUser {
  company: {
    id: number;
    slug: string;
    name: string;
    type: string;
    status: companyStatuses;
    commercial_registration_document: string;
    tax_number_document: string;
    certificate_number_document: string;
    commercial_registration: number;
    tax_number: number;
    certificate_number: string | null;
    website: string;
    plan?: null;
    owner: {
      id: number;
      first_name: string;
      last_name: string;
      name: string;
      email: string;
      image?: null;
      position: [];
      active: boolean;
      companies_count: number;
      created_at: string;
    };
    created_at: string;
    members: number;
    branding: {
      logo: {
        light: string;
        dark: string;
      };
      mark: {
        light: string;
        dark: string;
      };
    };
  } | null;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    name: string;
    email: string;
    image?: string | null;
    position: [];
    active: boolean;
    roles: IRole[];
    companies_count: number;
    created_at: string;
  } | null;
}
