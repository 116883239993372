import { useState } from 'react';
import { Permission } from '../../app/types/types';
import { ICompany } from '../../app/types/companies';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../setup/redux/Store';
// import { PrivateRoutes } from './ROUTES';

interface sideMenuItem {
  to: string;
  icon?: string;
  titleId: string;
  fontIcon?: JSX.Element | string | undefined;
  minimumPermissions: Permission[];
  children?: Array<{
    to: string;
    titleId: string;
    hasBullet: boolean;
    minimumPermissions?: Permission[];
    disabled?: boolean;
  }>;
}
function useAsideMenuMainItems() {
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const [sideMenuMainItems, setSideMenuMainItems] = useState<sideMenuItem[]>([
    // ... your main menu items
    {
      to: '/dashboard',
      titleId: 'Dashboard',
      fontIcon: 'far fa-tachometer-fast',
      minimumPermissions: ['all_users'],
    },
    {
      to: '/settings',
      titleId: 'Settings',
      fontIcon: 'far fa-building',
      minimumPermissions: ['view-company'],
      children: [
        {
          to: '/settings/companydetails',
          titleId: 'Company Details',
          hasBullet: true,
          minimumPermissions: ['view-company'],
        },
        {
          to: '/settings/roles',
          titleId: 'Roles',
          hasBullet: true,
          minimumPermissions: ['view-role'],
        },
        {
          to: '/settings/audit-log',
          titleId: 'View Audit Log',
          hasBullet: true,
          minimumPermissions: ['is_owner'],
        },
      ],
    },
    {
      to: '/company-management',
      titleId: 'Company management',
      fontIcon: 'far fa-user',
      minimumPermissions: ['view-user', 'view-product', 'view-client', 'view-third_party'],
      children: [
        {
          to: '/company-management/products',
          titleId: 'Products',
          hasBullet: true,
          minimumPermissions: ['view-product'],
        },
        {
          to: '/company-management/users',
          titleId: 'Users',
          hasBullet: true,
          minimumPermissions: ['view-user'],
        },
        {
          to: '/company-management/clients-and-vendors',
          titleId: 'Clients & Vendors',
          hasBullet: true,
          minimumPermissions: ['view-client', 'view-third_party'],
        },
      ],
    },
    {
      to: '/financial-management',
      titleId: 'Financial management',
      fontIcon: 'far fa-chart-bar',
      minimumPermissions: ['view-invoice'],
      children: [
        {
          to: '/financial-management/sales-invoices',
          titleId: 'Sales invoices',
          hasBullet: true,
          minimumPermissions: ['view-invoice'],
        },
        {
          to: '/financial-management/purchases-invoices',
          titleId: 'Purchases invoices',
          hasBullet: true,
          minimumPermissions: ['view-invoice'],
          disabled: companyDetails ? !companyDetails?.settings?.vat_registered : false,
        },
        {
          to: '/financial-management/lineitems',
          titleId: 'Line items',
          hasBullet: true,
          minimumPermissions: ['view-invoice'],
        },
      ],
    },
    {
      to: '/invoices-tax-module',
      titleId: 'Tax',
      fontIcon: 'far fa-percent',
      minimumPermissions: ['view-report'],
      children: [
        {
          to: '/invoices-tax-module/reports',
          titleId: 'VAT',
          hasBullet: true,
          minimumPermissions: ['view-report'],
        },
        {
          to: '/invoices-tax-module/withholdingreports',
          titleId: 'Withholding Reports',
          hasBullet: true,
          minimumPermissions: ['view-report'],
        },
      ],
    },
    {
      to: '/dashboard/document',
      titleId: 'Documents',
      fontIcon: 'far fa-file-alt',
      minimumPermissions: ['view-document'],
      children: [
        {
          to: '/documents',
          titleId: 'Documents',
          hasBullet: true,
          minimumPermissions: ['view-document'],
        },
        {
          to: '/doc-review',
          titleId: 'to review/sign',
          hasBullet: true,
          minimumPermissions: ['review-document', 'sign-document'],
        },
      ],
    },
    {
      to: '/dashboard/template',
      titleId: 'Templates',
      fontIcon: 'far fa-file-plus',
      // User who can create a document should be able to view template
      minimumPermissions: ['view-template', 'create-document'],
      children: [
        {
          to: '/templates',
          titleId: 'Templates',
          hasBullet: true,
          // User who can create a document should be able to view template
          minimumPermissions: ['view-template', 'create-document'],
        },
        {
          to: '/custom-variables',
          titleId: 'Custom Variables',
          hasBullet: true,
          minimumPermissions: ['create-document'],
        },
      ],
    },
  ]);

  const [adminSideMenuItems] = useState<sideMenuItem[]>([
    // ... your admin menu items
    // {
    //   to: '/admin/dashboard',
    //   titleId: 'Dashboard',
    //   fontIcon: 'far fa-tachometer-fast',
    //   minimumPermissions: ['super_admin'],
    // },
    {
      to: '/',
      titleId: 'Companies',
      fontIcon: 'far fa-file-plus',
      minimumPermissions: ['super_admin'],
      children: [
        {
          to: '/admin/companies',
          titleId: 'Companies',
          hasBullet: true,
          minimumPermissions: ['super_admin'],
        },
        {
          to: '/admin/deletion-requests',
          titleId: 'Companies deletion requests',
          hasBullet: true,
          minimumPermissions: ['super_admin'],
        },
      ],
    },

    {
      to: '/admin/subscription-plans',
      titleId: 'Subscription plans',
      fontIcon: 'fal fa-money-bill-wave',
      minimumPermissions: ['super_admin'],
    },

    {
      to: '/admin/users',
      titleId: 'Users',
      fontIcon: 'fal fa-user',
      minimumPermissions: ['super_admin'],
    },
    {
      to: '/admin/vat-rates',
      titleId: 'Vat Rates',
      fontIcon: 'fal fa-percent',
      minimumPermissions: ['super_admin'],
    },
    {
      to: '/admin/code-rules',
      titleId: 'Code Rules',
      fontIcon: 'fal fa-clipboard-list-check',
      minimumPermissions: ['super_admin'],
    },
    {
      to: '/admin/about-us',
      titleId: 'About us',
      fontIcon: 'fal fa-address-card',
      minimumPermissions: ['super_admin'],
    },
    {
      to: '/admin/terms-conditions',
      titleId: 'Terms and Conditions',
      fontIcon: 'fal fa-gavel',
      minimumPermissions: ['super_admin'],
    },
    {
      to: '/admin/transactions',
      titleId: 'Transactions Log',
      fontIcon: 'fal fa-credit-card',
      minimumPermissions: ['super_admin'],
    },
  ]);

  // You can add more functions to update or manipulate the menu items as needed

  // re-render when companyDetails.settings?.vat_registered changes and
  const reRenderSideMenuMainItems = () => {
    setSideMenuMainItems((prev) => {
      return prev.map((item) => {
        // console.log(item);

        if (item.to === '/financial-management') {
          let newItem = {
            ...item,
            children: item.children?.map((child) => {
              if (child.to === '/financial-management/purchases-invoices') {
                return {
                  ...child,
                  disabled: !companyDetails?.settings?.vat_registered,
                };
              }
              return child;
            }),
          };
          return newItem;
        }

        return item;
      });
    });
  };

  return {
    sideMenuMainItems,
    adminSideMenuItems,
    reRenderSideMenuMainItems,
  };
}

export default useAsideMenuMainItems;
