import { FC } from 'react';
import { useIntl } from 'react-intl';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { RootState } from '../../setup/redux/Store';
// import { CurrentCompanyUser } from '../types/CompanyTypes';
import { LogoImage } from './LogoImage';

type Props = {
  withCompanyName?: boolean;
};

export const Logo: FC<Props> = ({ withCompanyName }) => {
  const { formatMessage } = useIntl(); //locale,
  // const currentCompanyUser: CurrentCompanyUser = useSelector<RootState>(
  //   (state) => state.current.current
  // ) as CurrentCompanyUser;

  return (
    <div>
      <Link to='/'>
        <div className='row'>
          <div className='col-3 logo-image'>
            <LogoImage />
          </div>
          <div className='col-9 logo-company-name-ahad m-auto'>
            <span className='m-auto'>{formatMessage({ id: 'AHAD' })}</span>
          </div>
        </div>
      </Link>
      {/* {withCompanyName && (
        <div
          style={{
            position: 'relative',
            left: locale === 'ar' ? '-30px' : '30px',
          }}
          className='text-blueGrey fs-6 fw-lighter logo-company-name'
        >
          {currentCompanyUser?.company?.name || currentCompanyUser?.company?.slug}
        </div>
      )} */}
    </div>
  );
};
