/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl'; //FormattedMessage,
import { shallowEqual, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom'; //useLocation,
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

// import { Alert } from 'react-bootstrap-v5';
import Select from 'react-select';
import { toast } from 'react-toastify';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../../setup/redux/Store';
import { DisplayErrors } from '../../../../utils/DisplayErrors';

import { $t } from '../../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../../_metronic/layout/core';
import { SeparatorLines } from '../../../shared/Lines';

import { CustomVariablesTypeMap } from '../../../../_metronic/constants/general'; //DocumentDirection,
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';

import { axiosInstance } from '../../../../network/apis';

import { initialConfigPayload } from '../helpers/customVariableConfigHelpers';

import { useConfigureCustomVariablesSchema } from '../CustomVariables/useConfigureCustomVariablesSchema';
import { Input } from '../../../shared/Input';
import { Label } from '../../../shared/Label';
import SaveCancelBtnsGroup from '../../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../../hooks/useExitPrompt/useExitPrompt';
import { ICompany } from '../../../types/companies';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

export const ConfigNewCustomVariables: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { customVariablesValidationSchema, customVariablesInitialValues } =
    useConfigureCustomVariablesSchema();

  const history = useHistory();
  const { formatMessage, locale } = useIntl();

  const [loading, setLoading] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  /* ----------------------------- Getting Templates ----------------------------- */

  const createCustomVariable = async (values: any, resetForm: any) => {
    setLoading(true);
    // console.log('values', values);

    const payload: { [key: string]: any } = initialConfigPayload({
      type: values.type.requestValue.toLowerCase(),
    });
    payload['name'] = { ar: '', en: '' };
    payload['name']['ar'] = values.name_ar.trim();
    payload['name']['en'] = values.name_en.trim();
    payload['type'] = values.type.requestValue.toLowerCase();

    const formData = serialize(payload, { indices: true });

    try {
      await axiosInstance.post(`${companyDetails.id}/variables`, formData);
      resetForm();
      toast.success(formatMessage({ id: 'Custom Variable has been updated successfully!' }));
      history.push(`/c/${companyDetails.slug}/custom-variables`);
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: customVariablesInitialValues,
    validationSchema: customVariablesValidationSchema,
    onSubmit: (values, { resetForm }) => createCustomVariable(values, resetForm),
  });
  const { setFieldValue } = formik; //,values

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;
  /* ----------------------- end getting data from store ---------------------- */
  const typesOptions = useMemo(
    () =>
      Array.from(CustomVariablesTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );
  console.log('typesOptions', typesOptions);

  /* -------------------------------- On Init -------------------------------- */

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && $t('Create Custom Variable')}
            {mode === 'view' && $t('Preview template configuration')}
            {mode === 'edit' && $t('Edit template configuration')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_template' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  {/*  ------------------------ Custom variable name en/ar -----------------------  */}
                  <div className='row mb-3 mt-3'>
                    {locales.map((locale) => (
                      <div className='col-md-6'>
                        <Input
                          setTouchedOnInput
                          placeholder={formatMessage({
                            id: 'Custom variable name in ' + locale.toUpperCase(),
                          })}
                          required
                          autoComplete='off'
                          labelId={formatMessage({
                            id: 'Custom variable name in ' + locale.toLocaleUpperCase(),
                          })}
                          name={'name_' + locale}
                          type={'name_' + locale}
                          formik={formik}
                          disabled={mode === 'view'}
                        />
                      </div>
                    ))}
                  </div>
                  {/*  --------------------- end Custom variable name en/ar -----------------------  */}
                  <div className='col-6'>
                    <Label
                      msg='Custom variable type'
                      htmlFor='custom_variable_type'
                      isRequired={mode === 'create'}
                    />
                    <Select
                      isDisabled={mode === 'view'}
                      onChange={(value) => {
                        setFieldValue('type', value);
                      }}
                      onBlur={() => formik.setFieldTouched('type', true)}
                      options={typesOptions}
                      defaultValue={formik.values.type}
                      className='react-select smaller'
                      id='custom_variable_type'
                    />
                  </div>
                </div>

                {/* Save and Cancel Buttons */}
                {mode === 'create' && (
                  <>
                    <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.push(`/c/${companyDetails.slug}/custom-variables`);
                      }}
                    />
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const ConfigNewCustomVariablesCreate: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Custom Variables' })}</PageTitle>
      <ConfigNewCustomVariables mode='create' />
    </div>
  );
};

const ConfigNewCustomVariablesEdit: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Custom Variables' })}</PageTitle>
      <ConfigNewCustomVariables mode='edit' />
    </div>
  );
};

const ConfigNewCustomVariablesView: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Custom Variables' })}</PageTitle>
      <ConfigNewCustomVariables mode='view' />
    </div>
  );
};

export {
  ConfigNewCustomVariablesCreate,
  ConfigNewCustomVariablesEdit,
  ConfigNewCustomVariablesView,
};
