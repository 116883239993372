import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { UserModel } from '../models/UserModel';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

export enum IAuthActionTypes {
  Login = 'Login',
  Logout = 'Logout',
  Register = 'Register',
  UserLoaded = 'UserLoaded',
  SetUser = 'SetUser',
}

const initialAuthState: IAuthState = {
  user: undefined,
  token: undefined,
};

export interface IAuthState {
  user?: UserModel;
  token?: string;
}

export const reducer = persistReducer(
  { storage, key: 'auth', whitelist: ['user', 'token'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case IAuthActionTypes.Login: {
        const token = action.payload?.token;
        return { token, user: undefined };
      }

      case IAuthActionTypes.Register: {
        const token = action.payload?.token;
        return { token, user: undefined };
      }

      case IAuthActionTypes.Logout: {
        return initialAuthState;
      }

      case IAuthActionTypes.SetUser: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case IAuthActionTypes.UserLoaded: {
        const user = action.payload?.user;
        const token = action.payload?.token;
        return { ...state, user, token };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: ({ token, user }: IAuthState) => ({
    type: IAuthActionTypes.Login,
    payload: { token, user },
  }),

  fulfillUser: ({ token, user }: IAuthState) => ({
    type: IAuthActionTypes.UserLoaded,
    payload: { user, token },
  }),

  logout: () => ({ type: IAuthActionTypes.Logout }),

  setUser: (user: UserModel) => ({ type: IAuthActionTypes.SetUser, payload: { user } }),
};

export function* loginSaga(action: ActionWithPayload<IAuthState>) {
  yield put(actions.fulfillUser(action.payload || {}));
}

export function* saga() {
  yield takeLatest(IAuthActionTypes.Login, loginSaga);
}
