import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'react-bootstrap-v5';
import ContentShimmer from 'react-content-shimmer';

import { TemplatePreview } from './ConfigNewTemplateWrapper';
import { range } from '../../../utils/range';

type SelectTemplateProps = {
  templatesPreview: TemplatePreview[];
  setCurrentState: Function;
  cloneTemplate: Function;
  loading?: Boolean;
};

export const SelectTemplate: FC<SelectTemplateProps> = ({
  templatesPreview,
  setCurrentState,
  cloneTemplate,
  loading,
}) => {
  return (
    <Container className='w-900px'>
      <Row>
        <Col xs={8} lg={9}>
          <h2>
            <FormattedMessage id='Templates List' />
          </h2>
          <h6>
            <FormattedMessage
              id='Browse Template'
              values={{ template: templatesPreview.length.toString() }}
            />
          </h6>
        </Col>
        <Col xs={4} lg={3}>
          <span onClick={() => setCurrentState('config')} className='cursor-pointer'>
            <i className='far fa-edit' /> <FormattedMessage id='Basic configuration' />
          </span>
        </Col>
      </Row>
      <Row>
        {loading &&
          range({ length: 3 }).map(() => (
            <Col xs={12} sm={6} md={6} lg={4}>
              <div>
                <ContentShimmer
                  style={{
                    borderRadius: 20,
                    width: 240,
                    height: 290,
                  }}
                />
              </div>
            </Col>
          ))}
        {!loading &&
          templatesPreview?.map((template: TemplatePreview) => {
            return (
              <Col xs={12} sm={6} md={6} lg={4}>
                <div className='template-preview-card m-auto position-relative my-4'>
                  <div
                    className='d-flex flex-column p-5 absolute-center gap-3'
                    style={{
                      width: '90%',
                    }}
                  >
                    <button
                      className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
                      onClick={() => cloneTemplate(template)}
                    >
                      <FormattedMessage id='Use Template' />
                    </button>
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        window.open(
                          template.preview_link,
                          '_blank',
                          'toolbar=yes,scrollbars=yes,resizable=yes'
                        );
                      }}
                    >
                      <FormattedMessage id='Preview Template' />
                    </button>
                  </div>
                  <div
                    className='b-radius-20'
                    style={{
                      width: 240,
                      height: 290,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url(${template.thumbnail})`,
                    }}
                  ></div>
                  <p className='fs-7 text-center my-1'>{template?.title}</p>
                </div>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};
