import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl'; //FormattedMessage,
import { SeparatorLines } from '../../../../shared/Lines';

import { components, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import { shallowEqual, useSelector } from 'react-redux';

import { CompanyModel } from '../../../companies/CompanyModel';
import { RootState } from '../../../../../setup/redux/Store';
import { Label } from '../../../../shared/Label';
import { axiosInstance } from '../../../../../network/apis';
import { debounce } from '../../../../../utils/debounce';
import { ErrorItemDanger } from '../../../../shared/tables/actions/ErrorItemDanger';

const Step2: FC<any> = ({ formik }) => {
  const { formatMessage } = useIntl();

  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const loadSalesOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails?.id}/invoices/sales`, {
          params: {
            name: inputValue,
            per_page: 0,
            is_return: 0,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((sale: any) => ({
              ...sale,
              label: sale?.invoice_number,
              value: sale.id,
              id: sale.id,
            }))
          );
        });
    }, 1000),
    [companyDetails?.id]
  );

  return (
    <div className='mb-10 fv-row'>
      {/*  ------------------------ sales reference invoice -----------------------  */}
      <Label msg='Reference invoice number' htmlFor='sales_category' isRequired />
      <AsyncSelect
        cacheOptions
        loadOptions={loadSalesOptions}
        onChange={(value) => formik.setFieldValue(`invoice_reference`, value ? [value] : [])}
        onBlur={() => formik.setFieldTouched(`invoice_reference`, true)}
        // onFocus={() => loadSalesOptions}
        value={formik.values?.invoice_reference}
        defaultOptions
        placeholder={formatMessage({
          id: 'Reference invoice number',
        })}
        isClearable
        isOptionDisabled={(option: any) =>
          option?.status?.toString() === '0' ||
          option?.has_draft_return_invoice ||
          !option?.is_returnable ||
          option?.remaining_amount_with_vat === 0 ||
          (option?.errors?.danger && option?.errors?.danger.length > 0)
        }
        className='form-control form-control-md p-0 mb-2 w-300px'
        menuPlacement='auto'
        noOptionsMessage={() => {
          return (
            <span role='alert' className='text-danger'>
              {formatMessage({
                id: 'No invoice found with this reference number',
              })}
            </span>
          );
        }}
        loadingMessage={() => (
          <div className='d-flex justify-content-center' id='loading_indicator'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
        styles={{
          menu: (base) => ({
            ...base,
            marginBottom: 2,
            paddingBottom: 2,
            zIndex: 3,
          }),
          menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
          }),
          noOptionsMessage: (base: any) => ({
            ...base,
            color: 'red',
          }),
        }}
        components={{
          // DropdownIndicator: () => null,
          // IndicatorSeparator: () => null,
          // LoadingIndicator: () => (
          //   <div className='d-flex justify-content-center' id='Loading_indicator'>
          //     <div className='spinner-border text-primary' role='status'>
          //       <span className='visually-hidden'>Loading...</span>
          //     </div>
          //   </div>
          // ),

          Option: ({ children, ...props }: OptionProps<any, false>) => {
            return (
              <components.Option {...props}>
                <div
                  className='d-flex justify-content-between'
                  title={
                    props.data?.is_returnable
                      ? ''
                      : formatMessage({
                          id: 'The products of this invoice, are not returnable',
                        })
                  }
                  id={`${props.data?.invoice_number}`}
                >
                  <span>
                    {props.data?.label}{' '}
                    {props.data?.errors.danger && props.data?.errors.danger.length > 0 && (
                      <>
                        <ErrorItemDanger title={formatMessage({ id: 'Has errors' })} />
                      </>
                    )}
                    {props?.data.has_draft_return_invoice && (
                      <ErrorItemDanger title={formatMessage({ id: 'Has draft invoice' })} />
                    )}
                  </span>
                  <span>{props.data?.remaining_amount_with_vat}</span>
                </div>
              </components.Option>
            );
          },

          MenuList: ({ children, ...props }) => {
            return (
              <components.MenuList {...props}>
                <div className='d-flex justify-content-between mb-2' id='invoice_reference_list'>
                  <span>
                    {formatMessage({
                      id: 'Invoice number',
                    })}
                  </span>
                  <span>
                    {formatMessage({
                      id: 'Remaining amount',
                    })}
                  </span>
                </div>
                <SeparatorLines lines={2} />

                {children}
              </components.MenuList>
            );
          },
        }}
        id={`invoice_reference`}
      />
      {formik.errors?.invoice_reference && (
        <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
          <span role='alert' className='text-danger'>
            {formik.errors?.invoice_reference}
          </span>
        </div>
      )}
      {/* <Input
        setTouchedOnInput
        placeholder={formatMessage({
          id: 'Reference invoice number',
        })}
        autoComplete='off'
        labelId={formatMessage({
          id: 'Reference invoice number',
        })}
        name={'invoice_reference'}
        type={'text'}
        formik={formik}
        className='form-control'

        // disabled={mode === 'view'}
      />
      {foundReferenceInvoiceIsLoading && !formik.errors.invoice_reference && (
        <div className='d-flex justify-content-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
      {!foundReferenceInvoiceIsLoading && !formik.errors.invoice_reference && (
        <div className='fv-plugins-message-container mt-2 mx-3'>
          {!foundReferenceInvoiceDisableField && (
            <span role='alert' className='text-info'>
              {formatMessage({
                id: 'No invoice found with this reference number',
              })}
            </span>
          )}
          {foundReferenceInvoiceDisableField &&
            !salesItem?.is_return &&
            formik.values.vat_rate.length === 0 &&
            +salesItem?.remaining_amount === 0 && (
              <span role='alert' className='text-danger'>
                {formatMessage({
                  id: 'You can’t add a return invoice for this reference invoice number, because the full amount has been returned before.',
                })}
              </span>
            )}
          {foundReferenceInvoiceDisableField &&
            !salesItem?.is_return &&
            formik.values.vat_rate.length === 0 &&
            +salesItem?.remaining_amount > 0 && (
              <span role='alert' className='text-danger'>
                {formatMessage({
                  id: 'Return process is not available for this invoice, please check the original invoice.',
                })}
              </span>
            )}
          {foundReferenceInvoiceDisableField &&
            salesItem?.is_return &&
            referenceSalesItem?.is_return && (
              <span role='alert' className='text-danger'>
                {formatMessage({
                  id: 'You can’t add a returned invoice number to a return invoice.',
                })}
              </span>
            )}
        </div>
      )} */}

      {/*  ------------------------end sales reference invoice -----------------------  */}
    </div>
  );
};

export { Step2 };
