/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../setup';
import { $t } from '../../../../_metronic/i18n/formatMessage'; //, FormatMessage
import { PageTitle } from '../../../../_metronic/layout/core';
import { SerachFilters } from '../../../shared/SearchFilters';
// import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
// import { EditItem } from '../../../shared/tables/actions/EditItem';
// import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import * as CompanyRedux from '../../../modules/admins/companies/store/CompaniesRedux';
import * as TableRedux from '../../../store/TableRedux';
import { TableStatus } from '../../../shared/tables/classes';
import { TableData } from '../../../shared/tables/classes/TableData';
import { TableActions } from '../../../shared/tables/classes/TableActions';
import { TableSelection } from '../../../shared/tables/classes/TableSelection';
import { DateViewer } from '../../../shared/tables/classes/DateViewer';
// import { CommonTable } from '../../../shared/tables/Table';
import { CompaniesDataListModel, CompanyModel } from '../../../modules/companies/CompanyModel';
import { companyStatusColorsMap } from '../../../../_metronic/constants/colors';
import { KTSVG } from '../../../../_metronic/helpers';
import { ModalName } from '../../../types/types';
import * as ModalRedux from '../../../store/ModalRedux';
import { Portal } from '../../../../_metronic/partials';
import AhadDialog from '../../../../setup/shared/AhadDialog';
import { RequestEditsForm } from './RequestEditsForm';
import clsx from 'clsx';
import { RequestChangePlanForm } from './RequestChangePlanForm';
import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import { RequestRenewPlanForm } from './RequestRenewPlanForm';
import { CommonTable2 } from '../../../shared/tables/Table2';
// import { Button } from '../../../shared/Button';

const headerItems = [
  { id: 'Company name', maxWidth: 280 },
  { id: 'Registration date', maxWidth: 280 },
  { id: 'Company profile status', maxWidth: 280 },
  { id: 'Number of users', maxWidth: 140 },
  { id: 'Remaining subscribtion days', maxWidth: 140 },
  { id: 'Actions', maxWidth: 280 },
];

const CompaniesContent: FC = () => {
  const TABLE_NAME = 'sys_companies_list';
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [popupChangePlan, setPopupChangePlan] = useState(false);
  const [popupRenewPlan, setPopupRenewPlan] = useState(false);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [companyDetails, setCompanyDetails] = useState<any>();

  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state.SYS_companies.companies
  ) as CompaniesDataListModel;

  const modal: ModalName = useSelector<RootState>(
    (state) => state.modal.activeModal,
    shallowEqual
  ) as ModalName;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  function RequestData() {
    dispatch(
      CompanyRedux.actions.getCompanies({
        params: { ...table?.query, page: table?.page, per_page: table?.pagination },
      })
    );
  }

  useEffect(() => {
    RequestData();
  }, []);

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };
  useEffect(() => {
    RequestData();
  }, [table?.page, table?.pagination]);

  /* --------------------------------- refetch table data -------------------------------- */
  const refetchData = () => {
    dispatch(
      CompanyRedux.actions.getCompanies({
        params: { ...table?.query, page: table?.page, per_page: table?.pagination },
      })
    );
    setPopupRenewPlan(false);
    setPopupChangePlan(false);
  };
  /* --------------------------------- refetch table data -------------------------------- */

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(CompanyRedux.actions.fulFillCompanies({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */
  const rowItems = companies?.data.map((company: CompanyModel, index) => {
    if (index === 0) {
    }
    return [
      new TableSelection({ display: true, id: company.id, tableName: TABLE_NAME }),
      new TableData({ text: company.name || formatMessage({ id: 'No name' }) }),
      new DateViewer({ date: company.created_at }),
      new TableStatus({
        statusId: company.status,
        badge: companyStatusColorsMap?.get(company?.status),
      }),
      new TableData({ text: company.members.toString() }),
      new TableData({
        text:
          company?.remaining_subscription_days === null
            ? formatMessage({ id: 'Unlimited' })
            : company?.remaining_subscription_days?.toString(),
      }),
      new TableActions(
        (
          <div className='d-flex flex-row'>
            {company.status !== 'Deletion requested' && company.status !== 'Deleted' && (
              <span
                className={clsx('btn btn-icon btn-bg-light btn-sm me-1', {
                  'btn-active-color-primary': company.status !== 'Approved',
                })}
                title={$t('Approve')}
                onClick={() => {
                  if (company.status !== 'Approved') {
                    dispatch(
                      CompanyRedux.actions.updateCompanyStatus({ id: company.id, status: true })
                    );
                  }
                }}
              >
                <KTSVG path='/media/icons/duotune/general/gen026.svg' className='svg-icon-3' />
              </span>
            )}
            {company.status !== 'Deletion requested' && company.status !== 'Deleted' && (
              <span
                className={clsx('btn btn-icon btn-bg-light btn-sm me-1', {
                  'btn-active-color-primary': company.status !== 'Needed to edit',
                })}
                title={$t('Request Edits')}
                onClick={() => {
                  if (company.status !== 'Needed to edit') {
                    dispatch(ModalRedux.actions.setActiveModal('request-edits-modal'));
                    setCompanyId(company.id);
                  }
                }}
              >
                <KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-3' />
              </span>
            )}
            {company.status !== 'Deletion requested' && company.status !== 'Deleted' && (
              <div
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title={$t('Edit')}
                onClick={() => {
                  setPopupChangePlan(true);
                  setCompanyDetails(company);
                }}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </div>
            )}

            {company.status !== 'Deletion requested' &&
              company.status !== 'Deleted' &&
              company.remaining_subscription_days !== null &&
              company.remaining_subscription_days <= 7 && (
                <div
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  title={$t('Renew')}
                  onClick={() => {
                    setPopupRenewPlan(true);
                    setCompanyDetails(company);
                  }}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr058.svg' className='svg-icon-3' />
                </div>
              )}

            <ViewItem
              title={$t('View')}
              onClick={() => history.push(`/admin/companies/${company.id}`)}
            />
          </div>
        )
      ),
    ];
  });

  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'status',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'status',
                      })
                    ),
                  placeholderId: 'Status',
                  options: [
                    { value: 1, label: formatMessage({ id: 'New Company' }) },
                    { value: 2, label: formatMessage({ id: 'Approved' }) },
                    { value: 3, label: formatMessage({ id: 'Needs edit' }) },
                    { value: 4, label: formatMessage({ id: 'Deletion requested' }) },
                    { value: 5, label: formatMessage({ id: 'Deleted' }) },
                  ],
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          headerWithCheckbox
          allDisplayedIds={companies?.data?.map((company) => company.id) || []}
          meta={companies}
          headerItems={headerItems}
          rowItems={rowItems}
          initPerPage={20}
        />
      </div>

      {modal === 'request-edits-modal' && (
        <Portal className='full_modal'>
          <AhadDialog titleId='Request edits'>
            <RequestEditsForm companyId={companyId} />
          </AhadDialog>
        </Portal>
      )}
      {popupChangePlan && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId='ahad system settings'
            closeCallBack={() => setPopupChangePlan(false)}
          >
            <RequestChangePlanForm
              companyDetails={companyDetails}
              refetchData={() => refetchData()}
            />
          </AhadDialog>
        </Portal>
      )}

      {popupRenewPlan && (
        <Portal className='full_modal'>
          <AhadDialog titleId='Renew plan' closeCallBack={() => setPopupRenewPlan(false)}>
            <RequestRenewPlanForm
              companyDetails={companyDetails}
              refetchData={() => refetchData()}
            />
          </AhadDialog>
        </Portal>
      )}
    </>
  );
};

const CompaniesWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Companies' })}</PageTitle>

      <CompaniesContent />
    </div>
  );
};

export { CompaniesWrapper };
