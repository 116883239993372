import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from '../../../helpers';

interface IProps {
  className?: string;
}
let switchLink: string = '';

export default function SwitchButton({ className }: IProps) {
  const { formatMessage } = useIntl();

  if (process.env.REACT_APP_ENV === 'dev') {
    switchLink = 'http://localhost:5173';
  } else if (process.env.REACT_APP_ENV === 'stage') {
    switchLink = 'https://fai-staging.ahadtest.com/';
  } else if (process.env.REACT_APP_ENV === 'prelive') {
    switchLink = 'https://prelive.fai.sa/';
  } else if (process.env.REACT_APP_ENV === 'production') {
    switchLink = 'https://app.fai.sa/';
  } else {
    toast.error('Error in switch button');
  }

  return (
    <>
      {switchLink && (
        <div className='d-flex align-items-center justify-content-end'>
          <a href={switchLink} target='_blank' rel='noreferrer' className={className}>
            {!className && (
              <>
                {' '}
                <img
                  src={toAbsoluteUrl('/media/icons/SwitchIcon.svg')}
                  className='w-24px h-24px me-3'
                  alt='Switch Icon'
                />
                {formatMessage({ id: 'switch_to_new_system' })}
              </>
            )}
            {className && (
              <>
                {formatMessage({ id: 'switch_to_new_system' })}
                <img
                  src={toAbsoluteUrl('/media/icons/SwitchIcon.svg')}
                  className='w-24px h-24px m-3'
                  alt='Switch Icon'
                />
              </>
            )}
          </a>
        </div>
      )}
    </>
  );
}
