import { useState, FC, useEffect } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import * as ModalRedux from '../../store/ModalRedux';
import * as TemplatesRedux from '../templates/store/TemplatesRedux';

import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { Portal } from '../../../_metronic/partials';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { ModalName } from '../../types/types';
import { RootState } from '../../../setup';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { ConfigureNewTemplateForm } from './ConfigureNewTemplateForm';
import { axiosInstance } from '../../../network/apis';
import { ICompany } from '../../types/companies';
import { endpoints } from '../../../_metronic/constants/paths';
import { companySlugTo } from '../../../_metronic/helpers/functions';
import { SelectTemplate } from './SelecteTemplate';
import { TemplateEditor } from './TemplateEditor';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { ConfigureTemplateBasics } from './ConfigureTemplateBasics';

export interface TemplatePreview {
  id: number;
  title: { en: string; ar: string };
  thumbnail: string;
  preview_link: string;
}

type templateCreationSteps = 'config' | 'select_basics' | 'select_template' | 'edit';

const ConfigTemplate: FC = () => {
  usePermissions('create-template', 'Create Template');
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();

  const { setShowExitPrompt, setDisable } = useExitPrompt(true);

  const [templatesPreview, setTemplatesPreview] = useState<TemplatePreview[]>([]);
  const [currentState, setCurrentState] = useState<templateCreationSteps>('config');
  const [, /* prevrecipientType */ setPrevrecipientType] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    dispatch(ModalRedux.actions.setActiveModal('create-template'));
  }, []);
  /* ---------------------------------- STORE --------------------------------- */
  const modal: ModalName = useSelector<RootState>((state) => state.modal.activeModal) as ModalName;

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails
  ) as ICompany;

  /* ----------------------------- Getting Templates ----------------------------- */
  useEffect(() => {
    if (params?.templateId) {
      getCurrentTemplate();
    }
  }, [params?.templateId]);

  useEffect(() => {
    if (currentState === 'select_template' && Boolean(underConstructionTemplate?.recipient_type)) {
      setPrevrecipientType(underConstructionTemplate?.recipient_type);

      setLoading(true);

      axiosInstance
        .get(endpoints.previewTemplates, {
          params: {
            recipient_type: underConstructionTemplate?.recipient_type,
          },
        })
        .then((res) => {
          setTemplatesPreview(res.data.data);
        })
        .finally(() => setLoading(false));
    }

    if (params?.templateId && underConstructionTemplate?.editor?.sections?.length) {
      setCurrentState('edit');
    }
  }, [underConstructionTemplate, currentState]);

  useEffect(() => {
    setShowExitPrompt(true);
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  const getCurrentTemplate = async () => {
    try {
      await axiosInstance(endpoints.getCompanyTemplate(companyDetails.id, params?.templateId)).then(
        (res) => {
          const values = res.data;

          if (values?.editor?.section?.length > 0) {
            setCurrentState('edit');
          }

          const { clients, creators, reviewers, signers, users, editor } = values;

          if (values.id) {
            if (isEmpty(creators) || isEmpty(reviewers) || isEmpty(signers)) {
              setCurrentState('select_basics');
            } else if (!values?.editor?.section?.length || !values.has_content) {
              setCurrentState('select_template');
            }

            // preview when has no content and edit template from template configuration
            // if (
            //   (!isEmpty(reviewers) || !isEmpty(signers) || !isEmpty(clients)) &&
            //   !values.has_content
            // ) {
            //   setCurrentState('select_template');
            // }
          }

          const newUnderConstruction = {
            values,
            users: users?.map((user: any) => user.id),
            clients: clients?.map((client: any) => client.id),
            sections: editor?.sections,
            variables: editor?.variables,
            ...values,
          };
          dispatch(
            TemplatesRedux.actions.fulfillUnderConstructionTemplate({
              underConstructionTemplate: newUnderConstruction,
            })
          );
        }
      );
    } catch (err) {
      toast.error($t('Invalid url, you will be redirected to home page'));
      history.push('/');
    }
  };

  const cloneTemplate = (template: any) => {
    axiosInstance
      .get(endpoints.cloneTemplate(companyDetails.id, template.id, underConstructionTemplate.id))
      .then((res) => {
        dispatch(
          TemplatesRedux.actions.addSectionsToUnderConstructionTemplate({
            sections: res.data.sections,
            variables: res.data.variables,
          })
        );
        if (res.data?.sections?.length > 0) setCurrentState('edit');
      });
  };

  return (
    <div className='pb-5'>
      <Prompt message={$t('Are you sure you want to ignore the changes?')} />
      {/* ---------------------------- CREATING TEMPLATE --------------------------- */}
      {/* On creating phase we should be in the create route after creating 
          template we will be redirected to edit path with the templates id. 
          everything after that is considered an edit to the created template.
          even if you clicked back to basic config 
      */}
      {currentState === 'config' && modal === 'create-template' && (
        <Portal className='full_modal'>
          <AhadDialog
            withoutClose
            closeCallBack={() => history.push(companySlugTo(companyDetails.slug, 'templates'))}
            titleId={$t('New Template Settings')}
            specialContainerClass='dialog-content-container-add-user'
          >
            <ConfigureNewTemplateForm setCurrentState={setCurrentState} />
          </AhadDialog>
        </Portal>
      )}
      {/* ------------------------------ SELECT BASICS ----------------------------- */}
      {currentState === 'select_basics' && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => history.push(companySlugTo(companyDetails.slug, 'templates'))}
            titleId={$t('New Template Settings')}
            specialContainerClass='dialog-content-container-template'
          >
            <ConfigureTemplateBasics setCurrentState={setCurrentState} />
          </AhadDialog>
        </Portal>
      )}
      {/* ----------------------------- SELECT TEMPLATE ---------------------------- */}
      {currentState === 'select_template' && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => history.push(companySlugTo(companyDetails.slug, 'templates'))}
            titleId={$t('New Template Settings')}
            specialContainerClass='dialog-content-container-template'
          >
            <SelectTemplate
              loading={loading}
              templatesPreview={templatesPreview}
              setCurrentState={setCurrentState}
              cloneTemplate={cloneTemplate}
            />
          </AhadDialog>
        </Portal>
      )}
      {/* ---------------------------------- EDIT ---------------------------------- */}
      {currentState === 'edit' && <TemplateEditor />}
    </div>
  );
};

const ConfigTemplateWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Create Template' })}</PageTitle>
      <ConfigTemplate />
    </div>
  );
};

export { ConfigTemplateWrapper };
