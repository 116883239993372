import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { PrivateRoutes } from '../../../_metronic/constants/ROUTES';

import { PageTitle } from '../../../_metronic/layout/core';
import { Portal } from '../../../_metronic/partials';
import { actions } from '../../store/ModalRedux';
import { ModalName } from '../../types/types';
import { CreateCompanyForm } from './CreateCompanyForm';

const Companies: FC = () => {
  const dispatch = useDispatch();
  const modal: ModalName = useSelector<RootState>(
    (state) => state.modal.activeModal,
    shallowEqual
  ) as ModalName;

  return (
    <>
      <div className='d-flex flex-row-reverse'>
        <button
          className='btn btn-sm btn-submit '
          onClick={() => dispatch(actions.setActiveModal('create-company'))}
        >
          <FormattedMessage id='Create New Company' />
        </button>
      </div>

      {modal === 'create-company' && (
        <Portal className='main_modal'>
          <AhadDialog titleId='Add New Company'>
            <CreateCompanyForm />
          </AhadDialog>
        </Portal>
      )}
    </>
  );
};

const CompaniesWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle
        breadcrumbs={[{ title: 'Companies', path: PrivateRoutes.Companies, isActive: true }]}
      >
        {formatMessage({ id: 'Companies' })}
      </PageTitle>
      <Companies />
    </div>
  );
};

export { CompaniesWrapper };
