/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { setWholePageDirection } from '../../../helpers';
import { setLanguage } from '../../../i18n/Metronici18n';
import { languages } from '../../../constants/languages';

const Languages: FC = () => {
  const { locale } = useIntl();

  const currentLanguage = languages.find((x) => x.locale === locale);

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement={locale === 'ar' ? 'right-start' : 'left-start'}
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          <FormattedMessage id='Language' />
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((language) => (
          <div
            className='menu-item px-3'
            key={language.locale}
            onClick={() => {
              setLanguage(language.locale);
              setWholePageDirection(language);
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {
                active: language.locale === currentLanguage?.locale,
              })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={language.flag} alt='metronic' />
              </span>
              {language.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Languages };
