import { companyStatuses } from '../../app/types/companies';
import { userStatus } from '../../app/types/user';
import { Badge } from '../helpers/typescript/csssClasses';
import { ID } from './paths';

const companyStatusesColors: [companyStatuses, Badge][] = [
  ['New Company', 'badge-blueGrey'],
  ['Approved', 'badge-success'],
  ['Needed to edit', 'badge-warning'],
  ['Under Review', 'badge-powderBlue'],
  ['Deletion requested', 'badge-danger'],
  ['Deleted', 'badge-danger'],
];

export const companyStatusColorsMap = new Map(companyStatusesColors);

type StatusColor = { labelId: userStatus; badge: Badge };

const userStatusColors: [id: ID, statusColor: StatusColor][] = [
  [1, { labelId: 'Active', badge: 'badge-outline-success' }],
  [2, { labelId: 'Suspended', badge: 'badge-outline-blueGrey' }],
  [0, { labelId: 'Non active', badge: 'badge-outline-danger' }],
];

export const userStatusColorsMap = new Map(userStatusColors);

type reportStatuses = string;

const reportStatusesColors: [reportStatuses, Badge][] = [
  ['Approved', 'badge-success'],
  ['Draft', 'badge-warning'],
  ['Canceled', 'badge-danger'],
  ['مقبول', 'badge-success'],
  ['مسودة', 'badge-warning'],
  ['ملغي', 'badge-danger'],
];

export const reportStatusColorsMap = new Map(reportStatusesColors);
