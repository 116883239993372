import { useState, FC, useEffect, useCallback } from 'react'; // useState,
import { FieldArray } from 'formik'; //, useFormik
import { shallowEqual, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { components, OptionProps } from 'react-select';
import { createOptions } from '../../../../../utils/createOptions';

import 'react-phone-input-2/lib/style.css';

import { ErrorMessage } from '../../../../shared/ErrorMessage';
import {
  ReturnPurchasesInvoiceItemsErrors,
  ReturnPurchasesInvoiceItemsTouched,
} from '../ReturnPurchasesInvoiceItemsErrors';
import DatePicker from 'react-datepicker';

import { PurchasesVatRatesTypes } from '../../types/purchasesInvoiceList';

import { ThirdPartyTypes } from '../../../../types/CompanyTypes';

import { axiosInstance } from '../../../../../network/apis';
import { ICompany } from '../../../../types/companies';
import { RootState } from '../../../../../setup/redux/Store';
import { Input } from '../../../../shared/Input';
import { LocaleTitle } from '../../../../shared/LocaleTitle';
import { TextArea } from '../../../../shared/textarea';
import { SeparatorLines } from '../../../../shared/Lines';

import useWindowSize from '../../../../hooks/useWindowSize';

import { KTSVG } from '../../../../../_metronic/helpers';
import { LOCALE } from '../../../../../_metronic/helpers/typescript';
import { MultipleFilesUploader } from '../../../../pages/Companies/CompanyDetails/components/multipleFilesUploader';
import { Label } from '../../../../shared/Label';
import AsyncSelect from 'react-select/async';
import { debounce } from '../../../../../utils/debounce';
import { toast } from 'react-toastify';

const headerItemsWithActions = [
  { id: 'Product', minWidth: 200, width: 200, maxWidth: 200 },
  { id: 'Returned quantity', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Unit Price', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Price before discount', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Discount amount', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Price before VAT', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'TAX rate', minWidth: 250, width: 250, maxWidth: 250 },
  { id: '_VAT', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Total', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Actions', minWidth: 75, width: 75, maxWidth: 75 },
];
const headerItemsWithoutActions = [
  { id: 'Product', minWidth: 200, width: 200, maxWidth: 200 },
  { id: 'Returned quantity', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Unit Price', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Price before discount', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Discount amount', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Price before VAT', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'TAX rate', minWidth: 250, width: 250, maxWidth: 250 },
  { id: '_VAT', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Total', minWidth: 100, width: 100, maxWidth: 100 },
];
const headerItemsForCashback = [
  { id: 'Unit Price', minWidth: 250, width: 250, maxWidth: 250 },
  { id: 'TAX rate', minWidth: 350, width: 350, maxWidth: 350 },
  { id: '_VAT', minWidth: 250, width: 250, maxWidth: 250 },
  { id: 'Total', minWidth: 250, width: 250, maxWidth: 250 },
];
const allowedFiles = [
  'application/pdf',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
const Step3: FC<any> = ({
  formik,
  multiple_document,
  set_multiple_document,
  multiple_document_maxFiles,
}) => {
  const { formatMessage, locale } = useIntl();

  const { width } = useWindowSize();
  const { values, setFieldValue } = formik; //touched,, setFieldTouched,errors,

  const [productOptions, setProductOptions] = useState<any>(null);
  const [vatRateOptions, setVatRateOptions] = useState<any>(null);
  const [purchasesCategoriesOptions, setPurchasesCategoriesOptions] = useState<any>(null);
  const [lineItemIndex, setLineItemIndex] = useState<number>(0);

  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const invoice: any = useSelector<RootState>((state) => {
    return state.USER_purchases_invoices.currentPurchaseInvoice;
  }, shallowEqual) as any;

  /* ------------------------- get client data ------------------------ */

  const loadClientsOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails.id}/clients`, {
          params: {
            name: inputValue,
            per_page: 0,
            third_party_type: `${ThirdPartyTypes.Vendor},${ThirdPartyTypes.Both}`,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((Vendor: any) => ({
              ...Vendor,
              label: Vendor?.name?.[locale as LOCALE],
              value: Vendor.id,
            }))
          );
        });
    }, 1000),
    []
  );
  /* ------------------------- get products data ------------------------ */
  const loadProductsOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails.id}/products`, {
          params: {
            name: inputValue,
            per_page: 0,
            type: `2`,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((product: any) => ({
              ...product,
              label: product?.name?.[locale as LOCALE],
              value: product.id,
            }))
          );
        });
    }, 1000),
    []
  );
  const onFocusloadProductsOptions = async (i: any) => {
    setLineItemIndex(i);
    await axiosInstance
      .get(`${companyDetails.id}/products`, {
        params: {
          type: `2`,
          per_page: 0,
        },
      })
      .then((res) => {
        setProductOptions(
          res.data.data.map((product: any) => ({
            ...product,
            label: product?.name?.[locale as LOCALE],
            value: product.id,
          }))
        );
      });
  };

  /* ------------------------- get vat rates data ------------------------ */

  const loadVatRateOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails.id}/vat_rates`, {
          params: {
            name: inputValue,
            type: `${PurchasesVatRatesTypes.Expenditure}`,
            invoice_date: values.invoice_date,
            client_id: values.clients?.[0]?.value,
            category: values.invoice_items?.[lineItemIndex]?.category?.[0]?.value,
            product_id: values.invoice_items?.[lineItemIndex]?.name?.[0]?.value,
            amount: values.invoice_items?.[lineItemIndex]?.price_before_vat,
            per_page: 0,
          },
        })
        .then((res) => {
          callback(res.data.data);
        });
    }, 1000),
    [values, lineItemIndex]
  );

  const onFocusloadVatRateOptions = async (i: any) => {
    setLineItemIndex(i);
    await axiosInstance
      .get(`${companyDetails.id}/vat_rates`, {
        params: {
          type: `${PurchasesVatRatesTypes.Expenditure}`,
          invoice_date: values.invoice_date,
          client_id: values.clients?.[0]?.value,
          category: values.invoice_items?.[i]?.category?.[0]?.value,
          product_id: values.invoice_items?.[i]?.name?.[0]?.value,
          amount: values.invoice_items?.[i]?.price_before_vat,
          per_page: 0,
        },
      })
      .then((res) => {
        setVatRateOptions(res.data.data);
      });
  };
  const loadReturnVatRateOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails.id}/vat_rates`, {
          params: {
            name: inputValue,
            type: `${PurchasesVatRatesTypes['Expenditure return']}`,
            invoice_date: values.invoice_date,
            client_id: values.clients?.[0]?.value,
            category: values.invoice_items?.[lineItemIndex]?.category?.[0]?.value,
            product_id: values.invoice_items?.[lineItemIndex]?.name?.[0]?.value,
            amount: values.invoice_items?.[lineItemIndex]?.price_before_vat,
            per_page: 0,
          },
        })
        .then((res) => {
          callback(res.data.data);
        });
    }, 1000),
    [values, lineItemIndex]
  );

  const onFocusloadReturnVatRateOptions = async (i: any) => {
    setLineItemIndex(i);
    await axiosInstance
      .get(`${companyDetails.id}/vat_rates`, {
        params: {
          type: `${PurchasesVatRatesTypes['Expenditure return']}`,
          invoice_date: values.invoice_date,
          client_id: values.clients?.[0]?.value,
          category: values.invoice_items?.[i]?.category?.[0]?.value,
          product_id: values.invoice_items?.[i]?.name?.[0]?.value,
          amount: values.invoice_items?.[i]?.price_before_vat,
          per_page: 0,
        },
      })
      .then((res) => {
        setVatRateOptions(res.data.data);
      });
  };

  /* ------------------------- get categories data ------------------------ */

  const loadPurchasesCategoriesOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`/categories`, {
          params: {
            name: inputValue,
            vat_rate: values.invoice_items?.[lineItemIndex]?.vat_rate?.[0]?.value,
            type: `2`,
            per_page: 0,
            company: `${companyDetails.id}`,
          },
        })
        .then((res) => {
          callback(createOptions(res.data.purchases));
        });
    }, 1000),
    [values, lineItemIndex]
  );

  const onFocusLoadPurchasesCategoriesOptions = async (i: any) => {
    setLineItemIndex(i);
    await axiosInstance
      .get(`/categories`, {
        params: {
          type: `2`,
          vat_rate: values.invoice_items?.[i]?.vat_rate?.[0]?.value,
          per_page: 0,
          company: `${companyDetails.id}`,
        },
      })
      .then((res) => {
        setPurchasesCategoriesOptions(createOptions(res.data.purchases));
      });
  };

  /* ----------------------------- categories VatRates CheckValidity ----------------------------- */
  const categoriesVatRatesCheckValidity = async (i: any) => {
    if (
      values.invoice_items?.[i]?.vat_rate?.[0]?.value &&
      values.invoice_items?.[i]?.category?.[0]?.value
    ) {
      await axiosInstance
        .get(
          `/categories/vat_rates/check-validity/${values.invoice_items?.[i]?.category?.[0]?.value}/${values.invoice_items?.[i]?.vat_rate?.[0]?.value}`
        )
        .then((res) => {
          if (res?.data?.has_warning && res?.data?.warning_msg) {
            toast.warning(formatMessage({ id: `${res?.data?.warning_msg}` }));
          } else if (res?.data?.info_msg) {
            toast.info(formatMessage({ id: `${res?.data?.info_msg}` }));
          }
        })
        .catch((err) => {
          if (err?.data?.message) {
            toast.error(formatMessage({ id: `${err?.data?.message}` }));
          }
        });
    }
  };

  useEffect(() => {
    if (formik.values?.invoice_type === 'hasNoReference') {
      categoriesVatRatesCheckValidity(lineItemIndex);
    }
  }, [
    JSON.stringify(values?.invoice_items?.[lineItemIndex]?.vat_rate?.[0]?.value),
    JSON.stringify(values?.invoice_items?.[lineItemIndex]?.category?.[0]?.value),
  ]);

  /* ----------------------------- end categories VatRates CheckValidity ----------------------------- */
  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    if (formik.values.invoice_type === 'hasReference') {
      /* -------------------------------- getting invoice from redux and setting it to formik -------------------------------- */
      formik.setFieldValue('min_invoice_date', invoice?.invoice_date);
      formik.setFieldValue(
        'clients',
        invoice?.client
          ? [
              {
                label: invoice?.client?.name,
                value: invoice?.client?.id,
              },
            ]
          : []
      );

      formik.setFieldValue(
        'invoice_items',
        invoice?.invoice_items.map((invoice_item: any) => {
          const { id, remaining_quantity, remaining_discount } = invoice_item; //, calculated_vat
          return {
            reference_id: id ? id : '',
            category: invoice_item?.category
              ? [
                  {
                    value: invoice_item?.category?.id,
                    label: invoice_item?.category?.name,
                  },
                ]
              : [],
            name: invoice_item?.product
              ? [
                  {
                    value: invoice_item?.product?.id,
                    label: invoice_item?.product?.name?.[locale as LOCALE],
                  },
                ]
              : [],
            description: invoice_item?.description,
            quantity: 0,
            max_quantity: remaining_quantity,
            price: invoice_item?.product_price,
            total_before_discount: 0,
            max_discount_amount: remaining_discount,
            discount_amount: 0,
            price_before_vat: 0,
            vat_rate:
              formik.values?.invoice_type === 'hasReference'
                ? invoice_item?.['vat_rate']?.reverse_code?.id
                  ? [
                      {
                        ...invoice_item?.['vat_rate']?.reverse_code,
                        label: invoice_item?.['vat_rate']?.reverse_code?.name,
                        value: invoice_item?.['vat_rate']?.reverse_code?.id,
                      },
                    ]
                  : invoice_item?.['vat_rate']
                  ? [
                      {
                        label: 'Non-Returnable',
                        value: 0,
                      },
                    ]
                  : []
                : [
                    {
                      ...invoice_item?.['vat_rate'],
                      label: invoice_item?.['vat_rate']?.name,
                      value: invoice_item?.['vat_rate']?.id,
                    },
                  ],
            reverse_vat_rate: invoice_item?.['vat_rate']?.reverse_code?.id
              ? [
                  {
                    ...invoice_item?.['vat_rate']?.reverse_code,
                    label: invoice_item?.['vat_rate']?.reverse_code?.code,
                    value: invoice_item?.['vat_rate']?.reverse_code?.id,
                  },
                ]
              : [],
            tax_value: 0,
            total: 0,
          };
        })
      );
    } else if (formik.values.invoice_type === 'hasNoReference') {
      formik.setFieldValue('invoice_items', [
        {
          reference_id: '',
          category: [],
          name: [],
          description: '',
          quantity: 0,
          price: 0,
          total_before_discount: 0,
          discount_amount: 0,
          price_before_vat: 0,
          vat_rate: [],
          reverse_vat_rate: [],
          tax_value: 0,
          total: 0,
        },
      ]);
    } else if (formik.values.invoice_type === 'cashback') {
      formik.setFieldValue('invoice_items', [
        {
          reference_id: '',
          category: [],
          name: [],
          description: '',
          quantity: 1,
          price: 0,
          price_before_vat: 0,
          vat_rate: [],
          reverse_vat_rate: [],
          tax_value: 0,
          total: 0,
        },
      ]);
    }
  }, [invoice, formik.values.invoice_type]);
  /* ----------------------- end getting data from store ---------------------- */

  /* ----------------------------- select product ----------------------------- */
  const selectProduct = (item: any, value: any, i: any) => {
    setFieldValue(`invoice_items[${i}]['name']`, [value]);
    // if (value?.id) {
    setFieldValue(`invoice_items[${i}]['description']`, value.description[locale]);
    setFieldValue(`invoice_items[${i}]['quantity']`, 1);
    setFieldValue(`invoice_items[${i}]['price']`, value.price);
    setFieldValue(`invoice_items[${i}]['total_before_discount']`, value.price * 1);

    // calculate items values on selecting product
    const total_before_discount = 1 * value.price;

    const total_before_vat = total_before_discount - item?.discount_amount;

    const tax_value =
      item.vat_rate?.length > 0 ? total_before_vat * (item.vat_rate?.[0]?.rate / 100) : 0;

    const total = total_before_vat + tax_value;
    setFieldValue(`invoice_items[${i}]['total_before_discount']`, total_before_discount);
    setFieldValue(`invoice_items[${i}]['price_before_vat']`, total_before_vat);
    setFieldValue(`invoice_items[${i}]['tax_value']`, tax_value);
    setFieldValue(`invoice_items[${i}]['total']`, total);
    // } else {
    //   setFieldValue(`invoice_items[${i}]['description']`, '');
    //   setFieldValue(`invoice_items[${i}]['quantity']`, 0);
    //   setFieldValue(`invoice_items[${i}]['price']`, 0);
    //   setFieldValue(`invoice_items[${i}]['total_before_discount']`, 0);
    // }
  };

  /* ----------------------------- remove item from invoice ----------------------------- */
  const removeItem = (remove: any, i: any) => {
    return remove(i);
  };
  /* ----------------------------- add item to invoice ----------------------------- */
  const addItem = (push: any) => {
    return push({
      description: '',
      quantity: 1,
      price: 0,
      total_before_discount: 0,
      discount_amount: 0,
      price_before_vat: 0,
      vat_rate: [],
      tax_value: 0,
      total: 0,
    });
  };

  /* ----------------------------- calculate values for item function ----------------------------- */
  const calculateItemValues = (item: any, i: any, fieldName: string, value: any) => {
    setFieldValue(`invoice_items[${i}][${fieldName}]`, value);

    if (formik.values?.invoice_type === 'cashback') {
      formik.setFieldValue(`invoice_items[${i}]['quantity']`, 1);
    }
    const { quantity, price, vat_rate, discount_amount } = item;

    // if (fieldName === 'price_before_vat' || fieldName === 'tax_value') {
    //   setFieldValue(
    //     `invoice_items[${i}]['total']`,
    //     parseInt(item.tax_value) + parseInt(item.total_before_vat)
    //   );
    // } else {
    if (
      formik.values.invoice_type === 'hasReference' &&
      invoice?.invoice_items[i].discount > 0 &&
      fieldName !== 'discount_amount'
    ) {
      setFieldValue(
        `invoice_items[${i}]['discount_amount']`,
        (invoice?.invoice_items[i].discount / invoice?.invoice_items[i].quantity) * quantity
      );
      let total_before_discount = quantity * price;

      let total_before_vat =
        total_before_discount -
        (invoice?.invoice_items[i].discount / invoice?.invoice_items[i].quantity) * quantity;

      let tax_value = vat_rate?.length > 0 ? total_before_vat * (vat_rate?.[0]?.rate / 100) : 0;

      let total = total_before_vat + tax_value;
      setFieldValue(`invoice_items[${i}]['total_before_discount']`, total_before_discount);
      setFieldValue(`invoice_items[${i}]['price_before_vat']`, total_before_vat);
      setFieldValue(`invoice_items[${i}]['tax_value']`, tax_value);
      setFieldValue(`invoice_items[${i}]['total']`, total);
    } else {
      let total_before_discount = quantity * price;

      // handle decimal subtract (Floating point JS)
      let total_before_vat = Math.round((total_before_discount - +discount_amount) * 1e12) / 1e12;

      let tax_value = vat_rate?.length > 0 ? total_before_vat * (vat_rate?.[0]?.rate / 100) : 0;

      let total = total_before_vat + tax_value;
      setFieldValue(`invoice_items[${i}]['total_before_discount']`, total_before_discount);
      setFieldValue(`invoice_items[${i}]['price_before_vat']`, total_before_vat);
      setFieldValue(`invoice_items[${i}]['tax_value']`, tax_value);
      setFieldValue(`invoice_items[${i}]['total']`, total);
    }

    // }
  };

  /* --------------------------------- attachment -------------------------------- */

  useEffect(() => {
    const oldFiles = multiple_document.filter((item: any) => item?.url);
    const newFiles = multiple_document.filter((item: any) => !item?.url);
    if (oldFiles.length + newFiles.length > multiple_document_maxFiles) {
      const difference = oldFiles.length + newFiles.length - multiple_document_maxFiles;
      const res = multiple_document.slice(0, -difference);
      set_multiple_document(res);
    }
  }, [multiple_document]);
  /* ---------------------------------end attachment -------------------------------- */

  return (
    <>
      <div className='content-wrapper bg-white b-radius-25 m-auto'>
        <div className='d-flex p-5' style={{ width: '100%' }}>
          <div className=' ' style={{ width: width <= 768 ? '85vw' : '100%' }}>
            <div className='d-flex flex-row align-items-center m-auto p-10'>
              <div className='d-flex align-items-center flex-equal fw-row me-4 order-2 mt-2'>
                <label className='d-flex flex-column mb-0 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='form-switch form-switch-custom form-switch-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        name='status'
                        checked={values?.status === '1' ? true : false}
                        onChange={(event: any) => {
                          setFieldValue('status', event.target.checked ? '1' : '0');
                          setFieldValue('invoice_number', event.target.checked ? '' : null);
                          formik.setFieldTouched('invoice_number', false, false);
                          formik.setFieldTouched('clients', false, false);
                          formik.setFieldTouched('invoice_date', false, false);
                        }}
                        id='invoice_status'
                      />
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                        {values?.status === '0' && <FormattedMessage id='Draft invoice' />}
                        {values?.status === '1' && <FormattedMessage id='Issued invoice' />}
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
            <div className='row p-10'>
              {/*begin::Wrapper*/}
              <div className='d-flex flex-column align-items-start flex-md-row'>
                {/*begin:: Date */}
                <div
                  className='d-flex align-items-center flex-equal fw-row me-4 order-2 mt-2'
                  title={formatMessage({
                    id: 'Invoice date',
                  })}
                >
                  <div className='col-md-6'>
                    <DatePicker
                      isClearable
                      selected={
                        formik.values.invoice_date ? new Date(formik.values.invoice_date) : null
                      }
                      dateFormat='dd/MM/yyyy'
                      placeholderText={formatMessage({
                        id: 'Invoice date',
                      })}
                      id='invoice_date_id'
                      className='form-control form-control-white fw-bolder pe-5  basic-input'
                      onChange={(date: any) =>
                        formik.setFieldValue(
                          `invoice_date`,
                          date
                            ? // remove offset
                              new Date(
                                date.getTime() - date.getTimezoneOffset() * 60000
                              ).toISOString()
                            : ''
                        )
                      }
                      minDate={new Date(formik.values.min_invoice_date)}
                      maxDate={new Date()}
                      onChangeRaw={(e) => {
                        formik.setFieldTouched(`invoice_date`, true, true);
                      }}
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      autoComplete='off'
                      // disabled
                    />
                    <ErrorMessage name='invoice_date' formik={formik} />
                  </div>
                </div>
                {/*end:: Date */}
                {/*begin::Input group*/}
                {values?.status === '1' && (
                  <div
                    className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-md-2 me-4'
                    title={formatMessage({
                      id: 'Invoice number',
                    })}
                  >
                    <span className='fs-2x fw-bolder text-gray-800'>
                      {formatMessage({
                        id: 'Invoice #',
                      })}
                    </span>
                    <Input
                      setTouchedOnInput
                      // disabled
                      className='form-control form-control-flush fw-bolder text-muted w-125px'
                      autoComplete='off'
                      formik={formik}
                      name={`invoice_number`}
                      type='text'
                      error={values.status === '1' ? formik.errors?.invoice_number : null}
                      touched={values.status === '1' ? true : false}
                      id={`invoice number`}
                      placeholder={formatMessage({
                        id: 'Invoice number',
                      })}
                    />
                  </div>
                )}
                {/*end::Input group*/}
                {/*begin::Input group*/}

                {/*begin:: Date */}
                <div
                  className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row mt-2'
                  title={formatMessage({
                    id: 'Payment date',
                  })}
                >
                  <div className='col-md-6'>
                    <DatePicker
                      isClearable
                      selected={
                        formik.values.payment_date ? new Date(formik.values.payment_date) : null
                      }
                      dateFormat='dd/MM/yyyy'
                      placeholderText={formatMessage({
                        id: 'Payment date',
                      })}
                      id='payment_date_id'
                      className='form-control form-control-white fw-bolder pe-5  basic-input'
                      onChange={(date: any) =>
                        formik.setFieldValue(
                          `payment_date`,
                          date
                            ? // remove offset
                              new Date(
                                date.getTime() - date.getTimezoneOffset() * 60000
                              ).toISOString()
                            : ''
                        )
                      }
                      minDate={
                        formik.values.invoice_date
                          ? new Date(formik.values.invoice_date)
                          : new Date()
                      }
                      maxDate={new Date()}
                      onChangeRaw={(e) => {
                        formik.setFieldTouched(`payment_date`, true, true);
                      }}
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      autoComplete='off'
                      // disabled
                    />
                    <ErrorMessage name='payment_date' formik={formik} />
                  </div>
                </div>
                {/*end:: Date */}

                {/*end::Input group*/}
              </div>
              {/*end::Top*/}
              {/*begin::Separator*/}
              <div className='separator separator-dashed my-10'></div>
              {/*end::Separator*/}
              {/*begin::Wrapper*/}
              <div className='mb-0'>
                {/*begin::Row*/}
                <div className='row gx-10 mb-5'>
                  {/*begin::Col*/}
                  <div className='col-lg-6'>
                    {/*  --------------------- Clients select-----------------------  */}
                    <Label
                      msg='Vendor'
                      htmlFor='Vendor'
                      isRequired={values.status === '1' ? true : false}
                    />
                    <AsyncSelect
                      key={JSON.stringify(`client`)}
                      onChange={(value) => formik.setFieldValue('clients', [value])}
                      onBlur={() => formik.setFieldTouched('clients', true)}
                      value={formik.values?.clients}
                      // isMulti
                      isDisabled={formik.values?.invoice_type === 'hasReference'}
                      // className='react-select smaller'
                      className='form-control form-control-md p-0'
                      id='client'
                      cacheOptions
                      loadOptions={loadClientsOptions}
                      defaultOptions
                      placeholder={formatMessage({
                        id: 'Vendor',
                      })}
                      isClearable
                      menuPlacement='auto'
                      noOptionsMessage={() => {
                        return (
                          <span role='alert' className='text-danger'>
                            {formatMessage({
                              id: 'No options found',
                            })}
                          </span>
                        );
                      }}
                      loadingMessage={() => (
                        <div className='d-flex justify-content-center'>
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      )}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 2,
                          paddingBottom: 2,
                          zIndex: 3,
                        }),
                        menuPortal: (base: any) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        noOptionsMessage: (base: any) => ({
                          ...base,
                          color: 'red',
                        }),
                      }}
                      components={{
                        Option: ({ children, ...props }: OptionProps<any, false>) => {
                          return (
                            <components.Option {...props}>
                              <div className='d-flex justify-content-between'>
                                <span>{props.data?.label}</span>
                              </div>
                            </components.Option>
                          );
                        },
                      }}
                    />
                    <ErrorMessage name='clients' formik={formik} />
                    {/*  --------------------- end Clients select-----------------------  */}
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
                {/*begin::Row*/}
                <div className='row gx-10 mb-7'>
                  {/*begin::Col*/}

                  {/*begin::Input group*/}
                  {['ar', 'en'].map((lang) => (
                    <div className='col-lg-6'>
                      <label className='form-label fs-6 fw-bolder text-gray-700 mb-3'>
                        {LocaleTitle({ id: 'feedback', lang })}
                      </label>
                      <TextArea
                        placeholder={LocaleTitle({
                          id: 'feedback',
                          lang,
                        })}
                        autoComplete='off'
                        // labelId={LocaleTitle({ id: 'Product Description', lang })}
                        name={`description_${lang}`}
                        // disabled
                        type='text'
                        formik={formik}
                        rows='6'
                        dir={locale === 'ar' ? 'rtl' : 'ltr'}
                        error={formik.errors?.[`description_${lang}`]}
                        touched={formik.values[`description_${lang}`]}
                        id={`description_${lang}`}
                        // className='form-control form-control-md'
                      />
                    </div>
                  ))}
                  {/*end::Input group*/}
                </div>
                {/*end::Row*/}
                <div className='col-md-6 mt-3'>
                  <MultipleFilesUploader
                    mode='create'
                    setFile={set_multiple_document}
                    file={multiple_document}
                    maxSize={2048}
                    supportedFiles={allowedFiles}
                    maxfileUploaded={multiple_document_maxFiles}
                  />
                </div>
              </div>
              {/*end::Wrapper*/}
            </div>
            <div className='row p-10'>
              {/*begin::Table wrapper*/}
              <div className='table-wrapper card'>
                <div className='card-body '>
                  <div className={`${width < 900 ? 'table-responsive' : ''} mb-10`}>
                    {/*begin::Table*/}
                    <table className='table g-5 gs-0 mb-0 fw-bolder text-gray-700 pb-5 pb-xl-8 '>
                      {/*begin::Table head*/}
                      <thead>
                        <tr className='border-bottom fs-7 fw-bolder text-gray-700 text-uppercase'>
                          {formik.values?.invoice_type === 'hasNoReference'
                            ? headerItemsWithActions.map((item) => (
                                <th
                                  key={item.id}
                                  className={
                                    width <= 768
                                      ? `min-w-${item?.minWidth || 100}px max-w-${
                                          item?.maxWidth || 100
                                        }px  w-${item?.width || 100}px`
                                      : ` max-w-${item?.maxWidth || 100}px  w-${
                                          item?.width || 100
                                        }px`
                                  }
                                >
                                  {formatMessage({ id: item.id })}
                                </th>
                              ))
                            : formik.values.invoice_type !== 'cashback'
                            ? headerItemsWithoutActions.map((item) => (
                                <th
                                  key={item.id}
                                  className={
                                    width <= 768
                                      ? `min-w-${item?.minWidth || 100}px max-w-${
                                          item?.maxWidth || 100
                                        }px  w-${item?.width || 100}px`
                                      : ` max-w-${item?.maxWidth || 100}px  w-${
                                          item?.width || 100
                                        }px`
                                  }
                                >
                                  {formatMessage({ id: item.id })}
                                </th>
                              ))
                            : headerItemsForCashback.map((item) => (
                                <th
                                  key={item.id}
                                  className={
                                    width <= 768
                                      ? `min-w-${item?.minWidth || 100}px max-w-${
                                          item?.maxWidth || 100
                                        }px  w-${item?.width || 100}px`
                                      : ` max-w-${item?.maxWidth || 100}px  w-${
                                          item?.width || 100
                                        }px`
                                  }
                                >
                                  {formatMessage({ id: item.id })}
                                </th>
                              ))}
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody>
                        {/*begin::Field array*/}
                        <FieldArray name='invoice_items'>
                          {({ push, remove }) => {
                            const error: ReturnPurchasesInvoiceItemsErrors[] = formik.errors
                              ?.invoice_items as ReturnPurchasesInvoiceItemsErrors[];
                            const itemsValues = formik.values.invoice_items;
                            const itemsTouched: any = formik.touched
                              .invoice_items as ReturnPurchasesInvoiceItemsTouched[];
                            return (
                              <>
                                {itemsValues?.map((item: any, i: any, arr: any) => {
                                  return (
                                    <tr
                                      key={JSON.stringify(i)}
                                      className='border-bottom border-bottom-dashed'
                                    >
                                      {formik.values.invoice_type !== 'cashback' && (
                                        <>
                                          <td className='pe-7'>
                                            <AsyncSelect
                                              key={JSON.stringify(
                                                `invoice_reference_product ${
                                                  formik.values?.invoice_items?.[i]['name'] + i
                                                }`
                                              )}
                                              cacheOptions
                                              loadOptions={loadProductsOptions}
                                              onChange={(value) => {
                                                if (
                                                  formik.values?.invoice_type !== 'hasReference' &&
                                                  formik.values?.invoice_items?.[i]['id']
                                                ) {
                                                  setFieldValue(`invoice_items[${i}]['name']`, [
                                                    value,
                                                  ]);
                                                } else {
                                                  selectProduct(item, value, i);
                                                }
                                              }}
                                              onBlur={() =>
                                                formik.setFieldTouched(
                                                  `invoice_items[${i}]['name']`,
                                                  true
                                                )
                                              }
                                              onFocus={() => {
                                                onFocusloadProductsOptions(i);
                                              }}
                                              value={formik.values?.invoice_items?.[i]['name']}
                                              defaultOptions={productOptions}
                                              placeholder={formatMessage({
                                                id: 'Product Name',
                                              })}
                                              isDisabled={
                                                formik.values?.invoice_type === 'hasReference'
                                              }
                                              isClearable
                                              className='form-control form-control-md p-0 mb-2'
                                              menuPlacement='auto'
                                              noOptionsMessage={() => {
                                                return (
                                                  <span role='alert' className='text-danger'>
                                                    {formatMessage({
                                                      id: 'No options found',
                                                    })}
                                                  </span>
                                                );
                                              }}
                                              loadingMessage={() => (
                                                <div className='d-flex justify-content-center'>
                                                  <div
                                                    className='spinner-border text-primary'
                                                    role='status'
                                                  >
                                                    <span className='visually-hidden'>
                                                      Loading...
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                              styles={{
                                                menu: (base) => ({
                                                  ...base,
                                                  marginBottom: 2,
                                                  paddingBottom: 2,
                                                  zIndex: 3,
                                                }),
                                                menuPortal: (base: any) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                                noOptionsMessage: (base: any) => ({
                                                  ...base,
                                                  color: 'red',
                                                }),
                                              }}
                                              components={{
                                                Option: ({
                                                  children,
                                                  ...props
                                                }: OptionProps<any, false>) => {
                                                  return (
                                                    <components.Option {...props}>
                                                      <div className='d-flex justify-content-between'>
                                                        <span>{props.data?.label}</span>
                                                      </div>
                                                    </components.Option>
                                                  );
                                                },
                                              }}
                                              id={`invoice_reference_product ${
                                                formik.values?.invoice_items?.[i]['name'] + i
                                              }`}
                                            />
                                            {itemsTouched?.[i]?.name && error?.[i]?.name && (
                                              <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                <span role='alert' className='text-danger'>
                                                  {error?.[i]?.name}
                                                </span>
                                              </div>
                                            )}

                                            <Input
                                              setTouchedOnInput
                                              disabled={
                                                formik.values?.invoice_type === 'hasReference'
                                              }
                                              className='form-control form-control-md mb-2'
                                              autoComplete='off'
                                              formik={formik}
                                              name={`invoice_items[${i}]['description']`}
                                              type='text'
                                              error={
                                                error?.length &&
                                                error?.[i] &&
                                                error?.[i].description
                                              }
                                              touched={itemsTouched?.[i]?.description}
                                              id={`Item description ${i}`}
                                              placeholder={formatMessage({
                                                id: 'Description',
                                              })}
                                            />

                                            <AsyncSelect
                                              key={
                                                formik.values?.invoice_date.toString() +
                                                formik.values?.clients?.[0]?.category +
                                                formik.values?.clients?.[0]?.value +
                                                formik.values?.invoice_items?.[i]['category']?.[0]
                                                  ?.value +
                                                formik.values?.invoice_items?.[i]['name']?.[0]
                                                  ?.value +
                                                i
                                              }
                                              onChange={(value) => {
                                                setFieldValue(`invoice_items[${i}]['category']`, [
                                                  value,
                                                ]);
                                              }}
                                              onBlur={() =>
                                                formik.setFieldTouched(
                                                  `invoice_items[${i}]['category']`,
                                                  true
                                                )
                                              }
                                              onFocus={() => {
                                                onFocusLoadPurchasesCategoriesOptions(i);
                                              }}
                                              value={formik.values?.invoice_items?.[i]['category']}
                                              placeholder={formatMessage({
                                                id: 'Categories',
                                              })}
                                              isDisabled={
                                                formik.values?.invoice_type === 'hasReference'
                                              }
                                              className='form-control form-control-md p-0 mb-2'
                                              menuPlacement='auto'
                                              id={`invoice_items[${i}]['category']`}
                                              loadOptions={loadPurchasesCategoriesOptions}
                                              defaultOptions={purchasesCategoriesOptions}
                                              isClearable
                                              noOptionsMessage={() => {
                                                return (
                                                  <span role='alert' className='text-danger'>
                                                    {formatMessage({
                                                      id: 'No options found',
                                                    })}
                                                  </span>
                                                );
                                              }}
                                              loadingMessage={() => (
                                                <div className='d-flex justify-content-center'>
                                                  <div
                                                    className='spinner-border text-primary'
                                                    role='status'
                                                  >
                                                    <span className='visually-hidden'>
                                                      Loading...
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                              styles={{
                                                menu: (base) => ({
                                                  ...base,
                                                  marginBottom: 2,
                                                  paddingBottom: 2,
                                                  zIndex: 3,
                                                }),
                                                menuPortal: (base: any) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                                noOptionsMessage: (base: any) => ({
                                                  ...base,
                                                  color: 'red',
                                                }),
                                              }}
                                              components={{
                                                Option: ({
                                                  children,
                                                  ...props
                                                }: OptionProps<any, false>) => {
                                                  return (
                                                    <components.Option {...props}>
                                                      <div
                                                        className='d-flex justify-content-between'
                                                        title={props.data?.label}
                                                      >
                                                        <span className=''>
                                                          {props.data?.label}
                                                        </span>
                                                      </div>
                                                    </components.Option>
                                                  );
                                                },
                                              }}
                                            />

                                            {itemsTouched?.[i]?.category && error?.[i]?.category && (
                                              <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                <span role='alert' className='text-danger'>
                                                  {error?.[i]?.category}
                                                </span>
                                              </div>
                                            )}
                                          </td>
                                          <td className='ps-0'>
                                            <Input
                                              setTouchedOnInput
                                              disabled={
                                                formik.values?.invoice_type === 'hasReference'
                                                  ? !formik.values?.invoice_items?.[i][
                                                      'reverse_vat_rate'
                                                    ]?.[0]?.id ||
                                                    formik.values?.invoice_items?.[i][
                                                      'max_quantity'
                                                    ] === 0
                                                  : false
                                              }
                                              onKeyUp={(e: any) => {
                                                calculateItemValues(
                                                  item,
                                                  i,
                                                  'quantity',
                                                  e?.target?.value
                                                );
                                              }}
                                              className='form-control form-control-md mb-2'
                                              autoComplete='off'
                                              formik={formik}
                                              name={`invoice_items[${i}]['quantity']`}
                                              type='text'
                                              error={
                                                error?.length && error?.[i] && error?.[i].quantity
                                              }
                                              touched={itemsTouched?.[i]?.quantity}
                                              id={`Returned quantity ${i}`}
                                              placeholder={formatMessage({
                                                id: 'Returned quantity',
                                              })}
                                            />
                                          </td>
                                        </>
                                      )}
                                      <td className='ps-0'>
                                        <Input
                                          setTouchedOnInput
                                          disabled={formik.values?.invoice_type === 'hasReference'}
                                          className='form-control form-control-md'
                                          autoComplete='off'
                                          formik={formik}
                                          name={`invoice_items[${i}]['price']`}
                                          onKeyUp={(e: any) => {
                                            calculateItemValues(item, i, 'price', e?.target?.value);
                                          }}
                                          type='text'
                                          error={error?.length && error?.[i] && error?.[i].price}
                                          touched={itemsTouched?.[i]?.price}
                                          id={`Price ${i}`}
                                          placeholder={formatMessage({
                                            id: 'Unit Price',
                                          })}
                                        />
                                      </td>
                                      {formik.values.invoice_type !== 'cashback' && (
                                        <>
                                          <td className='ps-0'>
                                            <Input
                                              setTouchedOnInput
                                              disabled={true}
                                              className='form-control form-control-md'
                                              autoComplete='off'
                                              formik={formik}
                                              name={`invoice_items[${i}]['total_before_discount']`}
                                              onKeyUp={(e: any) => {
                                                calculateItemValues(
                                                  item,
                                                  i,
                                                  'total_before_discount',
                                                  e?.target?.value
                                                );
                                              }}
                                              type='text'
                                              error={
                                                error?.length &&
                                                error?.[i] &&
                                                error?.[i].total_before_discount
                                              }
                                              touched={
                                                itemsTouched?.[i]?.total_before_discount
                                                  ? true
                                                  : false
                                              }
                                              id={`Item total price ${i}`}
                                              placeholder={formatMessage({
                                                id: 'Price before discount',
                                              })}
                                            />
                                          </td>
                                          <td className='ps-0'>
                                            <Input
                                              setTouchedOnInput
                                              disabled={
                                                formik.values?.invoice_type === 'hasReference'
                                                  ? !formik.values?.invoice_items?.[i][
                                                      'reverse_vat_rate'
                                                    ]?.[0]?.id ||
                                                    formik.values?.invoice_items?.[i][
                                                      'max_quantity'
                                                    ] === 0
                                                  : false
                                              }
                                              className='form-control form-control-md'
                                              autoComplete='off'
                                              formik={formik}
                                              name={`invoice_items[${i}]['discount_amount']`}
                                              onKeyUp={(e: any) => {
                                                calculateItemValues(
                                                  item,
                                                  i,
                                                  'discount_amount',
                                                  e?.target?.value
                                                );
                                              }}
                                              type='text'
                                              error={
                                                error?.length &&
                                                error?.[i] &&
                                                error?.[i].discount_amount
                                              }
                                              touched={
                                                itemsTouched?.[i]?.discount_amount ? true : false
                                              }
                                              id={`Item total price ${i}`}
                                              placeholder={formatMessage({
                                                id: 'Discount amount',
                                              })}
                                            />
                                          </td>
                                          <td className='ps-0'>
                                            <Input
                                              setTouchedOnInput
                                              disabled={true}
                                              className='form-control form-control-md'
                                              autoComplete='off'
                                              formik={formik}
                                              name={`invoice_items[${i}]['price_before_vat']`}
                                              onKeyUp={(e: any) => {
                                                calculateItemValues(
                                                  item,
                                                  i,
                                                  'price_before_vat',
                                                  e?.target?.value
                                                );
                                              }}
                                              type='text'
                                              error={
                                                error?.length &&
                                                error?.[i] &&
                                                error?.[i].price_before_vat
                                              }
                                              touched={
                                                itemsTouched?.[i]?.price_before_vat ? true : false
                                              }
                                              id={`Item total price ${i}`}
                                              placeholder={formatMessage({
                                                id: 'Price before VAT',
                                              })}
                                            />
                                          </td>
                                        </>
                                      )}
                                      <td className='ps-0'>
                                        <AsyncSelect
                                          key={
                                            formik.values?.invoice_date.toString() +
                                            formik.values?.clients?.[0]?.value +
                                            formik.values?.invoice_items?.[i]['vat_rate']?.[0]
                                              ?.value +
                                            formik.values?.invoice_items?.[i]['name']?.[0]?.value +
                                            i
                                          }
                                          onChange={(value) => {
                                            setFieldValue(
                                              `invoice_items[${i}]['vat_rate']`,
                                              value ? [value] : []
                                            );

                                            setFieldValue(
                                              `invoice_items[${i}]['tax_value']`,
                                              value
                                                ? item.price_before_vat * (value?.rate / 100)
                                                : 0
                                            );
                                            setFieldValue(
                                              `invoice_items[${i}]['total']`,
                                              value
                                                ? item.price_before_vat +
                                                    item.price_before_vat * (value?.rate / 100)
                                                : 0
                                            );
                                          }}
                                          onBlur={() =>
                                            formik.setFieldTouched(
                                              `invoice_items[${i}]['vat_rate']`,
                                              true
                                            )
                                          }
                                          onFocus={() => {
                                            if (formik.values?.invoice_type === 'hasReference') {
                                              onFocusloadVatRateOptions(i);
                                            } else {
                                              onFocusloadReturnVatRateOptions(i);
                                            }
                                          }}
                                          value={formik.values?.invoice_items?.[i]['vat_rate']}
                                          placeholder={formatMessage({
                                            id: 'TAX rate',
                                          })}
                                          isDisabled={
                                            formik.values?.invoice_type === 'hasReference'
                                          }
                                          className='form-control form-control-md p-0 mb-2'
                                          menuPlacement='auto'
                                          id={`invoice_items[${i}]['vat_rate']`}
                                          loadOptions={
                                            formik.values?.invoice_type === 'hasReference'
                                              ? loadVatRateOptions
                                              : loadReturnVatRateOptions
                                          }
                                          defaultOptions={vatRateOptions}
                                          isClearable
                                          noOptionsMessage={() => {
                                            return (
                                              <span role='alert' className='text-danger'>
                                                {formatMessage({
                                                  id: 'No options found',
                                                })}
                                              </span>
                                            );
                                          }}
                                          loadingMessage={() => (
                                            <div className='d-flex justify-content-center'>
                                              <div
                                                className='spinner-border text-primary'
                                                role='status'
                                              >
                                                <span className='visually-hidden'>Loading...</span>
                                              </div>
                                            </div>
                                          )}
                                          styles={{
                                            menu: (base) => ({
                                              ...base,
                                              marginBottom: 2,
                                              paddingBottom: 2,
                                              zIndex: 3,
                                            }),
                                            menuPortal: (base: any) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                            noOptionsMessage: (base: any) => ({
                                              ...base,
                                              color: 'red',
                                            }),
                                          }}
                                          components={{
                                            Option: ({
                                              children,
                                              ...props
                                            }: OptionProps<any, false>) => {
                                              return (
                                                <components.Option {...props}>
                                                  <div className='d-flex justify-content-between'>
                                                    <span
                                                      className='mw-75'
                                                      title={
                                                        props.data?.name
                                                          ? props.data?.name
                                                          : props.data?.label
                                                      }
                                                    >
                                                      {props.data?.name
                                                        ? props.data?.name
                                                        : props.data?.label}
                                                    </span>
                                                  </div>
                                                </components.Option>
                                              );
                                            },
                                          }}
                                          formatGroupLabel={(data: any) => (
                                            <div>
                                              <h3 className='d-flex justify-content-between align-items-center '>
                                                {data.label}
                                              </h3>
                                              <SeparatorLines lines={3} />
                                            </div>
                                          )}
                                        />

                                        {itemsTouched?.[i]?.vat_rate && error?.[i]?.vat_rate && (
                                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                            <span role='alert' className='text-danger'>
                                              {error?.[i]?.vat_rate}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                      <td className='ps-0'>
                                        <Input
                                          setTouchedOnInput
                                          disabled={true}
                                          className='form-control form-control-md'
                                          autoComplete='off'
                                          formik={formik}
                                          name={`invoice_items[${i}]['tax_value']`}
                                          onKeyUp={(e: any) => {
                                            calculateItemValues(
                                              item,
                                              i,
                                              'tax_value',
                                              e?.target?.value
                                            );
                                          }}
                                          type='text'
                                          error={
                                            error?.length && error?.[i] && error?.[i].tax_value
                                          }
                                          touched={itemsTouched?.[i]?.tax_value}
                                          id={`Item tax value ${i}`}
                                          placeholder={formatMessage({
                                            id: '_VAT',
                                          })}
                                        />
                                      </td>

                                      <td className='ps-0'>
                                        <Input
                                          setTouchedOnInput
                                          disabled={true}
                                          className='form-control form-control-md'
                                          autoComplete='off'
                                          formik={formik}
                                          name={`invoice_items[${i}]['total']`}
                                          value={formik.values?.invoice_items[i]['total']}
                                          type='text'
                                          error={error?.length && error?.[i] && error?.[i].total}
                                          touched={itemsTouched?.[i]?.total}
                                          id={`Item total ${i}`}
                                          placeholder={formatMessage({
                                            id: 'Total',
                                          })}
                                        />
                                      </td>

                                      {formik.values?.invoice_type === 'hasNoReference' && (
                                        <td className='pt-5 text-center'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-icon btn-active-color-primary'
                                            title={formatMessage({
                                              id: 'Remove item',
                                            })}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              removeItem(remove, i);
                                            }}
                                          >
                                            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                            <span className='svg-icon svg-icon-3'>
                                              <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-1 svg-icon-gray-600'
                                              />
                                            </span>
                                            {/*end::Svg Icon*/}
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                                {formik.values?.invoice_type === 'hasNoReference' && (
                                  <tr className='border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700'>
                                    <th className='text-primary'>
                                      <button
                                        className='btn btn-primary py-1'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          addItem(push);
                                        }}
                                      >
                                        {formatMessage({
                                          id: 'Add Item',
                                        })}
                                      </button>
                                    </th>
                                  </tr>
                                )}
                              </>
                            );
                          }}
                        </FieldArray>
                        {typeof formik.errors.invoice_items === 'string' && (
                          <ErrorMessage name='invoice_items' formik={formik} />
                        )}
                        {/*end::Field array*/}
                      </tbody>
                      {/*end::Table body*/}
                      {formik.values?.invoice_type !== 'cashback' && (
                        <>
                          {' '}
                          {/*begin::Table foot*/}
                          <tfoot>
                            <tr className='align-top fw-bolder text-gray-700'>
                              <th colSpan={3} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: 'Total ',
                                })}
                                :
                              </th>
                              <th colSpan={1} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: 'Price before discount',
                                })}
                              </th>
                              <th colSpan={1} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: 'Discount amount',
                                })}
                              </th>
                              <th colSpan={1} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: 'Price before VAT',
                                })}
                              </th>
                              <th colSpan={1} className='fs-4 ps-0'></th>
                              <th colSpan={1} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: '_VAT',
                                })}
                              </th>
                              <th colSpan={1} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: 'Total',
                                })}
                              </th>
                            </tr>

                            <tr className='align-top fw-bolder text-gray-700'>
                              <th colSpan={3} className='fs-4 ps-0'></th>
                              <th colSpan={1} className='fs-4 text-nowrap'>
                                <span>
                                  {/* get sum of total in all total_before_discount */}
                                  {Number.isInteger(
                                    formik.values?.invoice_items?.reduce(
                                      (accumulator: any, object: any) => {
                                        return accumulator + object.total_before_discount;
                                      },
                                      0
                                    )
                                  )
                                    ? formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.total_before_discount;
                                        },
                                        0
                                      )
                                    : formik.values?.invoice_items
                                        ?.reduce((accumulator: any, object: any) => {
                                          return accumulator + object.total_before_discount;
                                        }, 0)
                                        .toFixed(2)}
                                </span>
                              </th>
                              <th colSpan={1} className='fs-4 text-nowrap'>
                                <span>
                                  {/* get sum of total in all total_before_discount */}
                                  {Number.isInteger(
                                    formik.values?.invoice_items?.reduce(
                                      (accumulator: any, object: any) => {
                                        return accumulator + +object.discount_amount;
                                      },
                                      0
                                    )
                                  )
                                    ? formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + +object.discount_amount;
                                        },
                                        0
                                      )
                                    : formik.values?.invoice_items
                                        ?.reduce((accumulator: any, object: any) => {
                                          return accumulator + +object.discount_amount;
                                        }, 0)
                                        .toFixed(2)}
                                </span>
                              </th>
                              <th colSpan={2} className='fs-4 text-nowrap'>
                                <span>
                                  {/* get sum of total in all total_before_discount */}
                                  {Number.isInteger(
                                    formik.values?.invoice_items?.reduce(
                                      (accumulator: any, object: any) => {
                                        return accumulator + object.price_before_vat;
                                      },
                                      0
                                    )
                                  )
                                    ? formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.price_before_vat;
                                        },
                                        0
                                      )
                                    : formik.values?.invoice_items
                                        ?.reduce((accumulator: any, object: any) => {
                                          return accumulator + object.price_before_vat;
                                        }, 0)
                                        .toFixed(2)}
                                </span>
                              </th>
                              <th colSpan={1} className='fs-4 text-nowrap'>
                                <span>
                                  {/* get sum of total in all tax_value */}
                                  {Number.isInteger(
                                    formik.values?.invoice_items?.reduce(
                                      (accumulator: any, object: any) => {
                                        return accumulator + object.tax_value;
                                      },
                                      0
                                    )
                                  )
                                    ? formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.tax_value;
                                        },
                                        0
                                      )
                                    : formik.values?.invoice_items
                                        ?.reduce((accumulator: any, object: any) => {
                                          return accumulator + object.tax_value;
                                        }, 0)
                                        .toFixed(2)}
                                </span>
                              </th>
                              <th colSpan={1} className='fs-4 text-nowrap'>
                                <span>
                                  {/* get sum of total in all invoice_items */}
                                  {Number.isInteger(
                                    formik.values?.invoice_items?.reduce(
                                      (accumulator: any, object: any) => {
                                        return accumulator + object.total;
                                      },
                                      0
                                    )
                                  )
                                    ? formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.total;
                                        },
                                        0
                                      )
                                    : formik.values?.invoice_items
                                        ?.reduce((accumulator: any, object: any) => {
                                          return accumulator + object.total;
                                        }, 0)
                                        .toFixed(2)}
                                </span>
                              </th>
                            </tr>
                          </tfoot>
                          {/*end::Table foot*/}
                        </>
                      )}
                      {formik.values?.invoice_type === 'cashback' && (
                        <>
                          {' '}
                          {/*begin::Table foot*/}
                          <tfoot>
                            <tr className='align-top fw-bolder text-gray-700'>
                              <th colSpan={2} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: 'Total ',
                                })}{' '}
                                :
                              </th>
                              {formik.values.invoice_type !== 'cashback' && (
                                <th colSpan={2} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Price before VAT',
                                  })}
                                </th>
                              )}
                              <th
                                colSpan={formik.values.invoice_type === 'cashback' ? 1 : 2}
                                className='fs-4 ps-0'
                              >
                                {formatMessage({
                                  id: '_VAT',
                                })}
                              </th>
                              <th colSpan={2} className='fs-4 ps-0'>
                                {formatMessage({
                                  id: 'Total',
                                })}
                              </th>
                            </tr>

                            <tr className='align-top fw-bolder text-gray-700'>
                              <th colSpan={2} className='fs-4 ps-0'></th>
                              {formik.values.invoice_type !== 'cashback' && (
                                <th colSpan={2} className='fs-4 text-nowrap'>
                                  <span>
                                    {/* get sum of total in all price_before_vat */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.price_before_vat;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.price_before_vat;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + object.price_before_vat;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                              )}
                              <th
                                colSpan={formik.values.invoice_type === 'cashback' ? 1 : 2}
                                className='fs-4 text-nowrap'
                              >
                                <span>
                                  {/* get sum of total in all tax_value */}
                                  {Number.isInteger(
                                    formik.values?.invoice_items?.reduce(
                                      (accumulator: any, object: any) => {
                                        return accumulator + object.tax_value;
                                      },
                                      0
                                    )
                                  )
                                    ? formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.tax_value;
                                        },
                                        0
                                      )
                                    : formik.values?.invoice_items
                                        ?.reduce((accumulator: any, object: any) => {
                                          return accumulator + object.tax_value;
                                        }, 0)
                                        .toFixed(2)}
                                </span>
                              </th>
                              <th colSpan={2} className='fs-4 text-nowrap'>
                                <span>
                                  {/* get sum of total in all invoice_items */}
                                  {Number.isInteger(
                                    formik.values?.invoice_items?.reduce(
                                      (accumulator: any, object: any) => {
                                        return accumulator + object.total;
                                      },
                                      0
                                    )
                                  )
                                    ? formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.total;
                                        },
                                        0
                                      )
                                    : formik.values?.invoice_items
                                        ?.reduce((accumulator: any, object: any) => {
                                          return accumulator + object.total;
                                        }, 0)
                                        .toFixed(2)}
                                </span>
                              </th>
                            </tr>
                          </tfoot>
                          {/*end::Table foot*/}
                        </>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Step3 };
