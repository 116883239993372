import { FC, useEffect } from 'react';

import { $t } from '../../../../../_metronic/i18n/formatMessage';

import { usePermissions } from '../../../../hooks/usePermissions';

import { InfoItem } from '../../../../shared/tables/actions/InfoItem';
import { ViewSalesItem } from '../../../../shared/tables/actions/ViewSalesItem';
import { ViewPurchasesItem } from '../../../../shared/tables/actions/ViewPurchasesItem';
import { TableActions, TableData, TableSelection } from '../../../../shared/tables/classes'; // DateViewer,

import { CompanyModel } from '../../../companies/CompanyModel';

import { RootState } from '../../../../../setup/redux/Store';
import * as InvoicesReportsRedux from '../../store/InvoicesReportsRedux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CommonTable2 } from '../../../../shared/tables/Table2';

const TABLE_NAME = 'transactons_list';

const headerItemsMonthly = [
  { id: 'Select', maxWidth: 150, width: 150 },
  { id: 'Month', maxWidth: 240, width: 230 },
  { id: 'Total', maxWidth: 240, width: 230 },
  { id: 'Unprocessed sales', maxWidth: 150, width: 150 },
  { id: 'Unprocessed sales amount', maxWidth: 230, width: 230 },
  { id: 'Unprocessed purchases', maxWidth: 150, width: 150 },
  { id: 'Unprocessed purchases amount', maxWidth: 250, width: 250 },
  { id: 'Tax amount', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];
const headerItemsQuarterly = [
  { id: 'Select', maxWidth: 150, width: 150 },
  { id: 'Quarter', maxWidth: 240, width: 230 },
  { id: 'Total', maxWidth: 240, width: 230 },
  { id: 'Unprocessed sales', maxWidth: 150, width: 150 },
  { id: 'Unprocessed sales amount', maxWidth: 230, width: 230 },
  { id: 'Unprocessed purchases', maxWidth: 150, width: 150 },
  { id: 'Unprocessed purchases amount', maxWidth: 250, width: 250 },
  { id: 'Tax amount', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];

export const TransactionsTable: FC<any> = ({ paramsObj, onTransactionSelection }) => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();

  /* -------------------------- Bring Data from store ------------------------- */
  const transactionsList: any = useSelector<RootState>((state) => {
    return state.USER_invoices_reports.invoicesReportTransactions;
  }, shallowEqual) as any;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[TABLE_NAME];

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;
  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(InvoicesReportsRedux.actions.fullFillInvoicesReportTransactions({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestTransactions(paramsObj: any) {
    const table = tables[TABLE_NAME];
    dispatch(
      InvoicesReportsRedux.actions.getInvoicesReportTransactions({
        companyId: companyDetails.id,
        params: {
          ...paramsObj,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    // throttle request

    if (paramsObj.month && paramsObj.year) {
      RequestTransactions(paramsObj);
    }

    // RequestData();
  }, [table?.page, table?.pagination, paramsObj]);

  const rowItems = transactionsList?.data?.map((transaction: any, index: any) => {
    return [
      new TableSelection({
        display: true,
        id: transaction.id,
        tableName: TABLE_NAME,
        disabled: transaction.selected, //auto select
      }),
      new TableData({
        text: `${transaction.date}`,
        // error: sale?.errors?.vat_rate,
      }),
      new TableData({
        text: `${transaction.total}`,
      }),
      new TableData({
        text: `${transaction.sales}`,
      }),
      new TableData({
        text: `${parseFloat(transaction.sales_amount).toFixed(2)}`,
      }),
      new TableData({
        text: `${transaction.purchases}`,
      }),
      new TableData({
        text: `${parseFloat(transaction.purchases_amount).toFixed(2)}`,
      }),
      new TableData({
        text: `${parseFloat(transaction.tax_amount).toFixed(2)}`,
      }),

      new TableActions(
        (
          <div className='d-flex flex-row'>
            {userCan('view-report') && transaction.selected && (
              <ViewSalesItem
                title={$t('Preview sales')}
                onClick={() => {
                  //  pass transaction object to parent component and call open modal function.
                  onTransactionSelection({ ...transaction, type: 'sales' });
                }}
              />
            )}
            {userCan('view-report') && (
              <ViewPurchasesItem
                title={$t('Preview purchases')}
                onClick={() => {
                  //  pass transaction object to parent component and call open modal function.
                  onTransactionSelection({ ...transaction, type: 'purchases' });
                }}
              />
            )}
            {!transaction.selected &&
              transaction.used_in_previous_report &&
              transaction.purchases > 0 && (
                <InfoItem title={$t('There is unprocessed purchases for this period.')} />
              )}
            {!transaction.selected && !transaction.used_in_previous_report && (
              <InfoItem title={$t('This period didn’t entered any approved report before.')} />
            )}
          </div>
        )
      ),
    ];
  });

  return (
    <div className='table-wrapper card'>
      {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5'> */}
      {/* {userCan('delete-template') && (
          <DeleteItem
            isButton
            messageId={'Are you sure you want to delete this template?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/templates`}
            payload={deleteItemId}
            successMessage={'Template has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )} */}
      {/* </div> */}
      <CommonTable2
        className='pb-5 pb-xl-8'
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={false}
        allDisplayedIds={transactionsList?.data?.map((transaction: any, index: any) => index) || []} //transactionsList?.data?.map((sales) => sales.id) ||
        meta={transactionsList}
        headerItems={paramsObj?.period === 2 ? headerItemsMonthly : headerItemsQuarterly}
        rowItems={rowItems}
      />
    </div>
  );
};

const Step2: FC<any> = ({ paramsObj, onTransactionSelection }) => {
  return (
    <div>
      <TransactionsTable paramsObj={paramsObj} onTransactionSelection={onTransactionSelection} />
    </div>
  );
};

export { Step2 };
