import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom'; //

import AhadDialog from '../../../../setup/shared/AhadDialog';
import { KTSVG } from '../../../../_metronic/helpers';
import { $t, FormatMessage } from '../../../../_metronic/i18n/formatMessage';
import { Portal } from '../../../../_metronic/partials';

type Props = {
  id?: string | number | null;
  title?: string;
  messageId?: string;
  editURI?: string | any;
  showButtonGroup?: boolean;
  editItemId?: any;
  setEditItemId?: Function;
  isButton?: boolean;
  buttonMessageId?: string;

  dialogTitleId?: string;
  cancelId?: string;
  onCancel?: Function;
  editId?: string;
  onEdit?: Function;
  afterSucessRequest?: Function;
  moduleId?: string;
  alertMessage?: string;
  successMessage?: string;
  payload?: number;
  TABLE_NAME?: string;
  isFullWidth?: boolean;
};

export const EditItemWithModal: FC<Props> = ({
  id,
  title,
  messageId,
  editURI,
  showButtonGroup = false,
  editItemId,
  setEditItemId,
  isButton = false,
  buttonMessageId,
  isFullWidth = false,
  dialogTitleId,
  alertMessage,
  TABLE_NAME = '',
}) => {
  /* ---------------------------- Add custom styles --------------------------- */
  const customStylesPerant = {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  };
  const customStylesButtonChild = {
    width: '100%',
  };
  /* ---------------------------- Add custom styles --------------------------- */
  const history = useHistory();
  useEffect(() => {
    if (editItemId && editURI && !messageId) {
      history.push(editURI);
    }
  }, [editItemId, messageId, editURI]);

  return (
    <div style={isFullWidth ? customStylesPerant : undefined}>
      {editItemId && editItemId === id && messageId && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={dialogTitleId || 'Edit'}
            closeCallBack={() => setEditItemId && setEditItemId(null)}
            specialContainerClass='dialog-content w-600px'
          >
            <div className='d-flex flex-column'>
              {alertMessage?.length && (
                <div className='d-flex align-items-center bg-light-danger rounded p-5'>
                  <span className='svg-icon svg-icon-danger me-5'></span>
                  <div className='flex-grow-1 me-2'>
                    <p className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                      {alertMessage}
                    </p>
                  </div>
                </div>
              )}
              <div>
                {
                  <h2>
                    <FormattedMessage id={messageId} />
                  </h2>
                }
              </div>
              {showButtonGroup && editURI && (
                <>
                  <div className='col-md-8'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <button
                          className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                          type='button'
                          // disabled={formik.isSubmitting}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(editURI);
                            // console.log('editURI', editURI);
                          }}
                        >
                          <span className='indicator-label'>
                            <FormattedMessage id={'Confirm'} />
                          </span>
                        </button>
                      </div>
                      <div className='col-md-6'>
                        <button
                          onClick={() => {
                            setEditItemId && setEditItemId(null);
                          }}
                          type='button'
                          id='kt_login_signup_form_cancel_button'
                          className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                        >
                          <FormattedMessage id='Cancel' />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
                // <SaveCancelBtnsGroup
                //   saveBtnLabel='Edit'
                //   // formik={formik}
                //   containerClassName='col-xl-6'
                //   loading={false}
                //   cancelOnClick={() => {
                //     setEditItemId && setEditItemId(null);
                //   }}
                // />
              )}
            </div>
          </AhadDialog>
        </Portal>
      )}
      {isButton ? (
        <button
          style={isFullWidth ? customStylesButtonChild : undefined}
          className='btn btn-primary btn-sm btn-primary-shadow'
          title={$t('Edit')}
          onClick={() => setEditItemId && setEditItemId(id)}
        >
          <i className='fa fa-edit '></i>
          <FormatMessage id={buttonMessageId ? buttonMessageId : 'Edit'} />
        </button>
      ) : (
        <a
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          title={$t('Edit')}
          onClick={() => setEditItemId && setEditItemId(id)}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
      )}
    </div>
  );
};
