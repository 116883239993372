import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as TableRedux from '../../store/TableRedux';
import { SerachFilters } from '../../shared/SearchFilters';
import { RootState } from '../../../setup/redux/Store';
import { $t } from '../../../_metronic/i18n/formatMessage';
import {
  DocumentDirection,
  DocumentStatuses,
  RecipientTypeMap,
} from '../../../_metronic/constants/general';
import { SelectOption } from '../../types/SelectOptions';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { UsersDataListModel } from '../users/types/usersList';

export function SearchDocuments({ tableName }: { tableName: string }) {
  const { locale } = useIntl();

  const typesOptions = useMemo(
    () =>
      Array.from(RecipientTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  const documentsStatusOptions = useMemo(
    () =>
      Array.from(DocumentStatuses.values()).map((option) => ({
        label: $t(option.label),
        value: option.id,
      })),
    [locale]
  );

  const dispatch = useDispatch();
  const users: UsersDataListModel = useSelector<RootState>(
    (state) => state.USER_users.Users,
    shallowEqual
  ) as UsersDataListModel;

  const usersOptions = useMemo(
    () => users?.data?.map((option: any) => ({ label: option.name, value: option.id })),
    [locale, users]
  );

  return (
    <SerachFilters
      leftHandSide={[
        {
          type: 'search',
          queryTerm: 'name',
          onChange: (value: string) => {
            dispatch(
              TableRedux.actions.updateQuery({
                value: value,
                tableName: tableName,
                queryTerm: 'name',
              })
            );
          },
          placeholderId: 'Search anything',
        },
      ]}
      rightHandSide={[
        {
          type: 'dropdown',
          queryTerm: 'recipient_type',
          onChange: (value: any) =>
            dispatch(
              TableRedux.actions.updateQuery({
                value: value,
                tableName: tableName,
                queryTerm: 'recipient_type',
              })
            ),
          placeholderId: 'Type of Document',
          options: typesOptions,
        },
        {
          type: 'dropdown',
          queryTerm: 'status',
          onChange: (value: any) =>
            dispatch(
              TableRedux.actions.updateQuery({
                value: value,
                tableName: tableName,
                queryTerm: 'status',
              })
            ),
          placeholderId: 'Document Status',
          options: documentsStatusOptions,
        },
        {
          type: 'dropdown',
          queryTerm: 'user',
          onChange: (value: any) =>
            dispatch(
              TableRedux.actions.updateQuery({
                value: value,
                tableName: tableName,
                queryTerm: 'user',
              })
            ),
          placeholderId: 'Document Created By',
          options: usersOptions,
        },
        {
          type: 'dropdown',
          queryTerm: 'type',
          onChange: (value: any) =>
            dispatch(
              TableRedux.actions.updateQuery({
                value: value,
                tableName: tableName,
                queryTerm: 'type',
              })
            ),
          placeholderId: 'Recipient type',
          options: [...DocumentDirection.values()].map((val: SelectOption) => ({
            value: val.value,
            label: $t(val.label),
          })),
        },
      ]}
    />
  );
}
