import { useState, FC, useEffect, useCallback } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { components, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import { createOptions } from '../../../utils/createOptions';

import 'react-phone-input-2/lib/style.css';

import { ErrorMessage } from '../../shared/ErrorMessage';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { SalesInvoiceItemsErrors, SalesInvoiceItemsTouched } from './SalesInvoiceItemsErrors';
import * as InvoicesRedux from './store/SalesInvoicesRedux';
import DatePicker from 'react-datepicker';
import { SalesInvoice, SalesVatRatesTypes } from './types/salesInvoiceList';

import { ThirdPartyTypes } from '../../types/CompanyTypes';

import { axiosInstance } from '../../../network/apis';
import { PageTitle } from '../../../_metronic/layout/core';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { ICompany } from '../../types/companies';
import { RootState } from '../../../setup/redux/Store';
import { serialize } from 'object-to-formdata';
import { SeparatorLines } from '../../shared/Lines';
import { Input } from '../../shared/Input';
import { LocaleTitle } from '../../shared/LocaleTitle';
import { TextArea } from '../../shared/textarea';

import { KTSVG } from '../../../_metronic/helpers';
import useWindowSize from '../../hooks/useWindowSize';
import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';

import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { useConfigureReturnSalesInvoiceSchema } from './useConfigureReturnSalesInvoiceSchema';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import { usePermissions } from '../../hooks/usePermissions';
import { LOCALE } from '../../../_metronic/helpers/typescript';
import { debounce } from '../../../utils/debounce';

import { ErrorItemDanger } from '../../shared/tables/actions/ErrorItemDanger';
import { ErrorItemWarning } from '../../shared/tables/actions/ErrorItemWarning';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';

import { HorizontalConfigNewReturnSalesWrapper } from './returnsalescomponents/HorizontalConfigNewReturnSalesWrapper';
import { MultipleFilesUploader } from '../../pages/Companies/CompanyDetails/components/multipleFilesUploader';
import { Label } from '../../shared/Label';
import { backendBaseUrl } from '../../../_metronic/constants/paths';
import { LineItemErrorIcon } from '../../shared/tables/actions/LineItemErrorIcon';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

const headerItems = [
  { id: 'Product', minWidth: 200, width: 200, maxWidth: 200 },
  { id: 'Returned quantity', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Unit Price', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Price before discount', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Discount amount', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Price before VAT', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'TAX rate', minWidth: 250, width: 250, maxWidth: 250 },
  { id: '_VAT', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Total', minWidth: 100, width: 100, maxWidth: 100 },
  { id: 'Actions', minWidth: 75, width: 75, maxWidth: 75 },
];
const headerItemsForCashback = [
  { id: 'Unit Price', minWidth: 250, width: 250, maxWidth: 250 },
  { id: 'TAX rate', minWidth: 350, width: 350, maxWidth: 350 },
  { id: '_VAT', minWidth: 250, width: 250, maxWidth: 250 },
  { id: 'Total', minWidth: 250, width: 250, maxWidth: 250 },
];

const allowedFiles = [
  'application/pdf',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

const ConfigReturnSalesNewInvoiceWrapper: FC<Props> = ({ mode }) => {
  const [multiple_document, set_multiple_document] = useState<File[]>([]);
  const [multiple_document_maxFiles] = useState<number>(2);
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const history = useHistory();
  const params: any = useParams();
  const { formatMessage, locale } = useIntl();
  const [loading, setLoading] = useState(false);
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const { returnSalesInvoiceInitialValues, returnSalesInvoiceValidationSchema } =
    useConfigureReturnSalesInvoiceSchema();
  const { width } = useWindowSize();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userApproved, setUserApproved] = useState(false);

  const [showErrorsConfirmationModal, setShowErrorsConfirmationModal] = useState(false);
  const [errorsConfirmation, setErrorsConfirmation] = useState<any>([]);
  const [editItemId, setEditItemId] = useState<any>(null);

  const [productOptions, setProductOptions] = useState<any>(null);
  const [vatRateOptions, setVatRateOptions] = useState<any>(null);
  const [salesCategoriesOptions, setSalesCategoriesOptions] = useState<any>(null);
  const [lineItemIndex, setLineItemIndex] = useState<number>(0);

  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const invoice: SalesInvoice = useSelector<RootState>((state) => {
    return state.USER_sales_invoices.currentSaleInvoice;
  }, shallowEqual) as SalesInvoice;

  /* ----------------------------- formik starter ----------------------------- */
  const formik = useFormik({
    initialValues: returnSalesInvoiceInitialValues,
    validationSchema: returnSalesInvoiceValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) =>
      createReturnInvoice(values, setSubmitting, resetForm),
  });
  const { values, errors, setFieldValue, setFieldTouched } = formik; //touched,

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  /* ------------------------- get client data ------------------------ */
  const loadClientsOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails?.id}/clients`, {
          params: {
            name: inputValue,
            per_page: 0,
            third_party_type: `${ThirdPartyTypes.Client},${ThirdPartyTypes.Both}`,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((client: any) => ({
              ...client,
              label: client?.name?.[locale as LOCALE],
              value: client.id,
            }))
          );
        });
    }, 1000),
    []
  );

  /* ------------------------- get products data ------------------------ */
  const loadProductsOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails?.id}/products`, {
          params: {
            name: inputValue,
            per_page: 0,
            type: `1`,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((product: any) => ({
              ...product,
              label: product?.name?.[locale as LOCALE],
              value: product.id,
            }))
          );
        });
    }, 1000),
    []
  );

  const onFocusloadProductsOptions = async (i: any) => {
    setLineItemIndex(i);
    await axiosInstance
      .get(`${companyDetails?.id}/products`, {
        params: {
          type: `1`,
          per_page: 0,
        },
      })
      .then((res) => {
        setProductOptions(
          res.data.data.map((product: any) => ({
            ...product,
            label: product?.name?.[locale as LOCALE],
            value: product.id,
          }))
        );
      });
  };

  /* ------------------------- get reference invoice (linked invoice) data ------------------------ */
  const loadSalesOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails?.id}/invoices/sales`, {
          params: {
            name: inputValue,
            per_page: 0,
            is_return: 0,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((sale: any) => ({
              ...sale,
              label: sale?.invoice_number,
              value: sale.id,
            }))
          );
        });
    }, 1000),
    []
  );

  /* ------------------------- get vat rates data ------------------------ */

  const loadReturnVatRateOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails?.id}/vat_rates`, {
          params: {
            name: inputValue,
            type: `${SalesVatRatesTypes['Income return']}`,
            invoice_date: values.invoice_date,
            client_id: values.clients?.[0]?.value,
            category: values.invoice_items?.[lineItemIndex]?.category?.[0]?.value,
            product_id: values.invoice_items?.[lineItemIndex]?.name?.[0]?.value,
            amount: values.invoice_items?.[lineItemIndex]?.total_before_discount,
            per_page: 0,
          },
        })
        .then((res) => {
          callback(res.data.data);
        });
    }, 1000),
    [values, lineItemIndex]
  );

  const onFocusloadReturnVatRateOptions = async (i: any) => {
    setLineItemIndex(i);
    await axiosInstance
      .get(`${companyDetails?.id}/vat_rates`, {
        params: {
          type: `${SalesVatRatesTypes['Income return']}`,
          invoice_date: values.invoice_date,
          client_id: values.clients?.[0]?.value,
          category: values.invoice_items?.[i]?.category?.[0]?.value,
          product_id: values.invoice_items?.[i]?.name?.[0]?.value,
          amount: values.invoice_items?.[i]?.total_before_discount,
          per_page: 0,
        },
      })
      .then((res) => {
        setVatRateOptions(res.data.data);
      });
  };

  /* ------------------------- get categories data ------------------------ */
  const loadSalesCategoriesOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`/categories`, {
          params: {
            name: inputValue,
            vat_rate: values.invoice_items?.[lineItemIndex]?.vat_rate?.[0]?.value,
            type: `1`,
            per_page: 0,
            company: `${companyDetails?.id}`,
          },
        })
        .then((res) => {
          callback(createOptions(res.data.sales));
        });
    }, 1000),
    [values, lineItemIndex]
  );

  const onFocusLoadSalesCategoriesOptions = async (i: any) => {
    setLineItemIndex(i);
    await axiosInstance
      .get(`/categories`, {
        params: {
          type: `1`,
          vat_rate: values.invoice_items?.[i]?.vat_rate?.[0]?.value,
          per_page: 0,
          company: `${companyDetails?.id}`,
        },
      })
      .then((res) => {
        setSalesCategoriesOptions(createOptions(res.data.sales));
      });
  };

  /* ----------------------- end getting data from store ---------------------- */
  /* ----------------------------- Getting Sales invoices ----------------------------- */
  const getCurrentInvoice = async (invoiceId: any) => {
    try {
      dispatch(
        InvoicesRedux.actions.getCurrentSaleInvoice({
          companyId: companyDetails?.id,
          invoiceId: invoiceId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };
  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    if (params?.invoiceId && mode !== 'create') {
      getCurrentInvoice(params?.invoiceId);
    }
  }, [params?.invoiceId]);

  useEffect(() => {
    if (invoice?.id && mode !== 'create') {
      if (invoice?.documents.length > 0) {
        set_multiple_document(invoice?.documents);
      }
      if (invoice?.is_cashback) {
        setFieldValue(`invoice_type`, 'cashback');
      }
      setFieldValue('status', `${invoice?.status}`);
      setFieldValue('invoice_date', invoice?.invoice_date ? invoice?.invoice_date : '');
      setFieldValue('invoice_number', invoice?.invoice_number ? invoice?.invoice_number : '');
      setFieldValue('payment_date', invoice?.payment_date ? invoice?.payment_date : '');
      setFieldValue('clients', [
        {
          label: invoice?.client?.name,
          value: invoice?.client?.id,
        },
      ]);
      if (invoice?.linked_invoice) {
        setFieldValue('invoice_reference', [
          {
            label: invoice?.linked_invoice?.invoice_number,
            value: invoice?.linked_invoice?.id,
          },
        ]);
      }
      setFieldValue('description_ar', invoice?.description?.ar ? invoice?.description?.ar : '');
      setFieldValue('description_en', invoice?.description?.en ? invoice?.description?.en : '');

      const invoice_items_mapped = invoice.invoice_items.map((item: any) => {
        return {
          ...item,
          name: item?.product
            ? [
                {
                  value: item?.product?.id,
                  label: item?.product?.name?.[locale as LOCALE],
                },
              ]
            : [],
          category: item?.category
            ? [
                {
                  value: item?.category?.id,
                  label: item?.category?.name,
                },
              ]
            : [],
          vat_rate: [
            {
              rate: item?.vat_rate?.tax_rate,
              value: item?.vat_rate?.id,
              label: item?.vat_rate?.name ? item?.vat_rate?.name : item?.vat_rate?.label,
            },
          ],
          max_quantity: item?.max_allowed_quantity,
          quantity: item?.quantity,
          price: item?.product_price,
          total_before_discount: item?.amount_before_discount,
          max_discount_amount: item?.remaining_discount,
          discount_amount: item?.discount,
          price_before_vat: item?.amount,
          tax_value: item?.calculated_vat,
          total: item?.total,
        };
      });
      setFieldValue('invoice_items', invoice_items_mapped);
    }
  }, [invoice]);

  /* ----------------------------- invoice serial configuration ----------------------------- */
  // sequential invoice status
  useEffect(() => {
    setFieldValue('invoice_number_method', companyDetails?.settings?.invoice_number_method);
  }, [companyDetails]);

  /* ----------------------------- remove item from invoice ----------------------------- */
  const removeItem = (remove: any, i: any) => {
    return remove(i);
  };
  /* ----------------------------- add item to invoice ----------------------------- */
  const addItem = (push: any) => {
    return push({
      description: '',
      quantity: 1,
      price: 0,
      total_before_discount: 0,
      vat_rate: [],
      tax_value: 0,
      total: 0,
    });
  };

  /* ----------------------------- calculate values for item function ----------------------------- */
  const calculateItemValues = (item: any, i: any, fieldName: string, value: any) => {
    setFieldValue(`invoice_items[${i}][${fieldName}]`, value);

    if (formik.values?.invoice_type === 'cashback') {
      formik.setFieldValue(`invoice_items[${i}]['quantity']`, 1);
    }
    const { quantity, price, vat_rate, discount_amount } = item;

    // if (fieldName === 'price_before_vat' || fieldName === 'tax_value') {
    //   setFieldValue(
    //     `invoice_items[${i}]['total']`,
    //     parseInt(item.tax_value) + parseInt(item.total_before_vat)
    //   );
    // } else {
    if (
      invoice?.reference_id &&
      +invoice?.invoice_items[i].discount > 0 &&
      fieldName !== 'discount_amount'
    ) {
      setFieldValue(
        `invoice_items[${i}]['discount_amount']`,
        +invoice?.invoice_items[i].discount * quantity
      );
      let total_before_discount = quantity * price;

      let total_before_vat = total_before_discount - +invoice?.invoice_items[i].discount * quantity;

      let tax_value = vat_rate?.length > 0 ? total_before_vat * (vat_rate?.[0]?.rate / 100) : 0;

      let total = total_before_vat + tax_value;
      setFieldValue(`invoice_items[${i}]['total_before_discount']`, total_before_discount);
      setFieldValue(`invoice_items[${i}]['price_before_vat']`, total_before_vat);
      setFieldValue(`invoice_items[${i}]['tax_value']`, tax_value);
      setFieldValue(`invoice_items[${i}]['total']`, total);
    } else {
      let total_before_discount = quantity * price;

      // handle decimal subtract (Floating point JS)
      let total_before_vat = Math.round((total_before_discount - +discount_amount) * 1e12) / 1e12;

      let tax_value = vat_rate?.length > 0 ? total_before_vat * (vat_rate?.[0]?.rate / 100) : 0;

      let total = total_before_vat + tax_value;
      setFieldValue(`invoice_items[${i}]['total_before_discount']`, total_before_discount);
      setFieldValue(`invoice_items[${i}]['price_before_vat']`, total_before_vat);
      setFieldValue(`invoice_items[${i}]['tax_value']`, tax_value);
      setFieldValue(`invoice_items[${i}]['total']`, total);
    }
    // setFieldValue(`invoice_items[${i}]['total_before_discount']`, total_before_discount);
    // setFieldValue(`invoice_items[${i}]['price_before_vat']`, total_before_vat);
    // setFieldValue(`invoice_items[${i}]['tax_value']`, tax_value);
    // setFieldValue(`invoice_items[${i}]['total']`, total);
  };

  /* ----------------------------- select product ----------------------------- */
  const selectProduct = (item: any, value: any, i: any) => {
    setFieldValue(`invoice_items[${i}]['name']`, [value]);
    if (value?.id) {
      setFieldValue(`invoice_items[${i}]['description']`, value.description[locale]);
      setFieldValue(`invoice_items[${i}]['quantity']`, 1);
      setFieldValue(`invoice_items[${i}]['price']`, value.price);
      setFieldValue(`invoice_items[${i}]['total_before_discount']`, value.price * 1);

      // calculate items values on selecting product
      const total_before_discount = 1 * value.price;
      const tax_value =
        item.vat_rate?.length > 0 ? total_before_discount * (item.vat_rate?.[0]?.rate / 100) : 0;
      const total = total_before_discount + tax_value;
      setFieldValue(`invoice_items[${i}]['total_before_discount']`, total_before_discount);
      setFieldValue(`invoice_items[${i}]['tax_value']`, tax_value);
      setFieldValue(`invoice_items[${i}]['total']`, total);
    } else {
      setFieldValue(`invoice_items[${i}]['description']`, '');
      setFieldValue(`invoice_items[${i}]['quantity']`, 0);
      setFieldValue(`invoice_items[${i}]['price']`, 0);
      setFieldValue(`invoice_items[${i}]['total_before_discount']`, 0);
    }
  };
  /* ----------------------------- create invoice ----------------------------- */
  const createReturnInvoice = async (values: any, setSubmitting: any, resetForm: any) => {
    setLoading(true);
    setSubmitting(true);

    // console.log('createReturnInvoice');
    // console.log(formik);
    // console.log(values);
    // console.log('Create return sales');
    if (values.invoice_items.reduce((a: any, b: any) => a + b.total, 0) === 0) {
      toast.error(formatMessage({ id: 'invoice total cant be zero' }));
      setSubmitting(false);
      setLoading(false);
      return;
    }
    if (!userApproved && values.status !== '0') {
      setShowConfirmationModal(true);
      setSubmitting(false);
      setLoading(false);
      return;
    }
    const payload: any = {};
    if (values.status === '1') {
      payload['invoice_number'] = values.invoice_number;
      payload['status'] = 1;
    } else {
      payload['status'] = 0;
    }
    payload['invoice_date'] = values.invoice_date;
    payload['payment_date'] = values.payment_date;
    payload['client_id'] = values.clients[0]?.value;
    payload['description'] = { ar: '', en: '' };
    payload['description']['ar'] = values.description_ar ? values.description_ar.trim() : '';
    payload['description']['en'] = values.description_en ? values.description_en.trim() : '';
    payload['is_return'] = 1;
    payload['is_cashback'] = formik.values.invoice_type === 'cashback' ? 1 : 0;

    try {
      if (mode === 'create') {
        const invoice_items_mapped = values.invoice_items.map((item: any) => {
          return {
            description: item?.description,
            amount: item?.total_before_discount,
            quantity: item?.quantity,
            product_price: item?.price,
            vat_rate_id: item?.vat_rate?.[0]?.value,
            category_id: item?.category?.[0]?.value,
            product_id: item?.name?.[0]?.value,
            discount: item?.discount_amount,
          };
        });
        payload['invoice_items'] = invoice_items_mapped;
        payload['documents'] = multiple_document;
        const formData = serialize(payload, { indices: true });
        await axiosInstance
          .post(`${companyDetails?.id}/invoices/return/sales`, formData)
          .then((res) => {
            if (values.invoice_number_method === '2') {
              toast.success(
                formatMessage(
                  { id: 'The invoice with number {invoiceNumber} has been created successfully!' },
                  { invoiceNumber: `${res.data}` }
                )
              );
            } else {
              toast.success(formatMessage({ id: 'The invoice has been created successfully!' }));
            }
          });
        resetForm();
      } else if (mode === 'edit') {
        const invoice_items_mapped = values.invoice_items.map((item: any) => {
          return {
            id: item?.id,
            description: item?.description,
            amount: item?.total_before_discount,
            quantity: item?.quantity,
            product_price: item?.price,
            vat_rate_id: item?.vat_rate?.[0]?.value,
            category_id: item?.category?.[0]?.value,
            product_id: item?.name?.[0]?.value,
            discount: item?.discount_amount,
          };
        });
        payload['invoice_items'] = invoice_items_mapped;
        payload['documents'] = multiple_document.filter((item: any) => !item?.url);
        const formData = serialize(payload, { indices: true });
        formData.append('_method', 'PUT');
        await axiosInstance
          .post(`${companyDetails?.id}/invoices/return/sales/${invoice.id}`, formData)
          .then((res) => {
            if (values.invoice_number_method === '2') {
              toast.success(
                formatMessage(
                  { id: 'The invoice with number {invoiceNumber} has been edited successfully!' },
                  { invoiceNumber: `${res.data}` }
                )
              );
            } else {
              toast.success(formatMessage({ id: 'The invoice has been edited successfully!' }));
            }
          });
        resetForm();
      }
      history.push(`/c/${companyDetails.slug}/financial-management/sales-invoices`);
    } catch (err: any) {
      DisplayErrors(err);
      setSubmitting(false);
    }
    setLoading(false);
  };

  /* --------------------------------- attachment -------------------------------- */

  useEffect(() => {
    const oldFiles = multiple_document.filter((item: any) => item?.url);
    const newFiles = multiple_document.filter((item: any) => !item?.url);
    if (oldFiles.length + newFiles.length > multiple_document_maxFiles) {
      const difference = oldFiles.length + newFiles.length - multiple_document_maxFiles;
      const res = multiple_document.slice(0, -difference);
      set_multiple_document(res);
    }
  }, [multiple_document]);
  /* ---------------------------------end attachment -------------------------------- */

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
      dispatch(InvoicesRedux.actions.fulfillCurrentSaleInvoice({}));
    };
  }, []);

  /* ------------------------------- End Leaving ------------------------------ */
  return (
    <div className='content-wrapper bg-white b-radius-25 m-auto'>
      <div className='d-flex px-5 '>
        <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '95%' }}>
          {mode === 'create' && $t('Add sales invoice')}
          {mode === 'view' && $t('Preview return sales invoice')}
          {mode === 'edit' && $t('Edit return sales invoice')}
        </div>
      </div>
      <SeparatorLines lines={2} />
      <div className='row mx-3'>
        <div className='container mt-5'></div>
        <div className='row flex-row-reverse'>
          {mode === 'view' &&
            userCan('update-invoice') &&
            ((invoice?.is_sequential && invoice?.status === 0) || !invoice?.is_sequential) && (
              <div className='col-md-2'>
                <div
                  className='d-flex justify-content-end'
                  style={{ textAlign: 'end', alignSelf: 'center' }}
                >
                  <EditItemWithModal
                    isFullWidth={true}
                    id={invoice?.id}
                    title={$t('Edit')}
                    isButton
                    buttonMessageId={'Edit invoice'}
                    messageId={
                      invoice?.can_update
                        ? invoice?.can_delete
                          ? invoice?.return_invoices?.length > 0
                            ? formatMessage(
                                {
                                  id: 'This invoice has returns invoices with number {returnInvoicesNumbers} linked with it, also this invoice it’s already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed with the editing process?',
                                },
                                {
                                  returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                    (return_invoice, index) => {
                                      return `${index + 1}: ${return_invoice.invoice_number} `;
                                    }
                                  )} )`,
                                }
                              )
                            : formatMessage({
                                id: 'This invoice already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed the editing process?',
                              })
                          : invoice?.return_invoices?.length > 0
                          ? invoice?.can_update_return
                            ? formatMessage(
                                {
                                  id: 'This invoice has returns invoices with numbers {returnInvoicesNumbers}. Are you sure you want to proceed with the editing process?',
                                },
                                {
                                  returnInvoicesNumbers: `( ${invoice?.return_invoices?.map(
                                    (return_invoice, index) => {
                                      return `${index + 1}: ${return_invoice.invoice_number} `;
                                    }
                                  )} )`,
                                }
                              )
                            : formatMessage({
                                id: 'This invoice is related to returned invoices entered in an approved report, and it can’t be edited',
                              })
                          : ''
                        : formatMessage({
                            id: "This invoice is included in an approved report, and can't be edited",
                          })
                    }
                    editURI={`/c/${companyDetails.slug}/financial-management/sales-invoices/returnsale/${invoice?.id}/edit`}
                    showButtonGroup={invoice?.can_update && invoice?.can_update_return}
                    editItemId={editItemId}
                    setEditItemId={setEditItemId}
                  />
                </div>
              </div>
            )}
          {mode === 'view' &&
            userCan('view-invoice') &&
            invoice?.is_sequential &&
            invoice.status === 1 &&
            formik.values.invoice_type !== 'cashback' && (
              <>
                {invoice?.long_invoice_preview_link && (
                  <div className='col-md-2'>
                    <a
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      href={invoice?.long_invoice_preview_link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <span className='indicator-label'>
                        {!companyDetails?.tax_number ? (
                          <FormattedMessage id={'full invoice'} />
                        ) : (
                          <FormattedMessage id={'full tax invoice'} />
                        )}
                      </span>
                    </a>
                  </div>
                )}
                {invoice?.simple_invoice_preview_link && (
                  <div className='col-md-2'>
                    <a
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      href={invoice?.simple_invoice_preview_link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <span className='indicator-label'>
                        {!companyDetails?.tax_number ? (
                          <FormattedMessage id={'simplified invoice'} />
                        ) : (
                          <FormattedMessage id={'simplified tax invoice'} />
                        )}
                      </span>
                    </a>
                  </div>
                )}
              </>
            )}
          {mode === 'view' &&
            userCan('view-invoice') &&
            invoice?.is_sequential &&
            invoice.status === 0 &&
            formik.values.invoice_type !== 'cashback' && (
              <>
                <div className='col-md-2'>
                  <a
                    className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                    href={`${backendBaseUrl}/invoices/${invoice?.id}/preview?simple=0&lang=${locale}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='indicator-label'>
                      {!companyDetails?.tax_number ? (
                        <FormattedMessage id={'full invoice'} />
                      ) : (
                        <FormattedMessage id={'full tax invoice'} />
                      )}
                    </span>
                  </a>
                </div>

                <div className='col-md-2'>
                  <a
                    className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                    href={`${backendBaseUrl}/invoices/${invoice?.id}/preview?simple=1&lang=${locale}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='indicator-label'>
                      {!companyDetails?.tax_number ? (
                        <FormattedMessage id={'simplified invoice'} />
                      ) : (
                        <FormattedMessage id={'simplified tax invoice'} />
                      )}
                    </span>
                  </a>
                </div>
              </>
            )}
        </div>
      </div>
      <div className='d-flex p-5' style={{ width: '100%' }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete='none' id='create_invoice'>
            {Object.keys(formik.touched).length > 0 && (
              <Prompt message={$t('Are you sure you want to ignore the changes?')} />
            )}
            <div className=' ' style={{ width: width <= 768 ? '85vw' : '100%' }}>
              <div className='d-flex flex-row align-items-center m-auto p-10'>
                <div className='d-flex align-items-center flex-equal fw-row me-4 order-2 mt-2'>
                  <label className='d-flex flex-column mb-0 cursor-pointer'>
                    <span className='d-flex align-items-center me-2'>
                      <span className='form-switch form-switch-custom form-switch-solid me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          name='status'
                          checked={values?.status === '1' ? true : false}
                          onChange={(event: any) => {
                            setFieldValue('status', event.target.checked ? '1' : '0');
                            setFieldValue('invoice_number', event.target.checked ? '' : null);
                            formik.setFieldTouched('invoice_number', false, false);
                            formik.setFieldTouched('clients', false, false);
                            formik.setFieldTouched('invoice_date', false, false);
                          }}
                          disabled={mode === 'view' || (mode === 'edit' && invoice?.status === 1)}
                          id='invoice_status'
                        />
                      </span>

                      <span className='d-flex flex-column'>
                        <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                          {values?.status === '0' && <FormattedMessage id='Draft invoice' />}
                          {values?.status === '1' && <FormattedMessage id='Issued invoice' />}
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <div className='row p-10'>
                {/*begin::Wrapper*/}
                <div className='d-flex flex-column align-items-start flex-md-row'>
                  {/*begin:: Date */}
                  <div
                    className='d-flex align-items-center flex-equal fw-row me-4 order-2 mt-2'
                    title={formatMessage({
                      id: 'Invoice date',
                    })}
                  >
                    <div className='col-md-6'>
                      <DatePicker
                        isClearable={mode !== 'view'}
                        selected={
                          formik.values.invoice_date ? new Date(formik.values.invoice_date) : null
                        }
                        dateFormat='dd/MM/yyyy'
                        placeholderText={formatMessage({
                          id: 'Invoice date',
                        })}
                        id='invoice_date_id'
                        className='form-control fw-bolder pe-5  basic-input'
                        onChange={(date: any) =>
                          formik.setFieldValue(
                            `invoice_date`,
                            date
                              ? // remove offset
                                new Date(
                                  date.getTime() - date.getTimezoneOffset() * 60000
                                ).toISOString()
                              : ''
                          )
                        }
                        maxDate={new Date()}
                        minDate={
                          mode === 'edit' && invoice?.linked_invoice?.invoice_date
                            ? new Date(invoice?.linked_invoice?.invoice_date)
                            : null
                        }
                        onChangeRaw={(e) => {
                          setFieldTouched(`invoice_date`, true, true);
                        }}
                        // onKeyDown={(e) => {
                        //   e.preventDefault();
                        // }}
                        autoComplete='off'
                        disabled={mode === 'view'}
                      />
                      <ErrorMessage name='invoice_date' formik={formik} />
                    </div>
                  </div>
                  {/*end:: Date */}
                  {/*begin::Input group*/}
                  {((mode === 'view' && values?.status === '1') ||
                    (mode === 'edit' &&
                      values?.status === '1' &&
                      values?.invoice_number_method &&
                      values?.invoice_number_method === '1')) && (
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-md-2 me-4'
                      title={formatMessage({
                        id: 'Invoice number',
                      })}
                    >
                      <span className='fs-2x fw-bolder text-gray-800'>
                        {formatMessage({
                          id: 'Invoice #',
                        })}
                      </span>
                      <Input
                        setTouchedOnInput
                        disabled={
                          mode === 'view' ||
                          (mode === 'edit' &&
                            (invoice?.return_invoices?.length > 0 ||
                              (!invoice?.can_update &&
                                !invoice?.can_update_return &&
                                invoice?.can_delete)))
                        }
                        className={
                          mode === 'view' || mode === 'edit'
                            ? 'form-control  fw-bolder text-muted  w-125px'
                            : 'form-control form-control-flush fw-bolder text-muted  w-125px'
                        }
                        autoComplete='off'
                        formik={formik}
                        name={`invoice_number`}
                        type='text'
                        error={values?.status === '1' ? errors?.invoice_number : null}
                        touched={values?.status === '1' && values.invoice_number ? true : false}
                        id={`invoice number`}
                        placeholder={formatMessage({
                          id: 'Invoice number',
                        })}
                      />
                      <div className='d-flex flex-row'>
                        <>
                          {userCan('view-invoice') && invoice?.errors?.warning && (
                            <ErrorItemWarning
                              title={$t('Has errors')}
                              onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setErrorsConfirmation(
                                  invoice?.errors?.warning?.map((error: any) => error?.text)
                                );
                                setShowErrorsConfirmationModal(true);
                              }}
                            />
                          )}
                          {userCan('view-invoice') && invoice?.errors?.danger && (
                            <ErrorItemDanger
                              title={$t('Has errors')}
                              onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setErrorsConfirmation(
                                  invoice?.errors?.danger.map((error: any) => error?.text)
                                );
                                setShowErrorsConfirmationModal(true);
                              }}
                            />
                          )}
                        </>
                      </div>
                    </div>
                  )}
                  {/*end::Input group*/}
                  {/*begin::Input group*/}

                  {/*begin:: Date */}
                  <div
                    className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row mt-2'
                    title={formatMessage({
                      id: 'Payment date',
                    })}
                  >
                    <div className='col-md-6'>
                      <DatePicker
                        isClearable={mode !== 'view'}
                        selected={
                          formik.values.payment_date ? new Date(formik.values.payment_date) : null
                        }
                        dateFormat='dd/MM/yyyy'
                        placeholderText={formatMessage({
                          id: 'Payment date',
                        })}
                        id='payment_date_id'
                        className='form-control  fw-bolder pe-5  basic-input'
                        onChange={(date: any) =>
                          formik.setFieldValue(
                            `payment_date`,
                            date
                              ? // remove offset
                                new Date(
                                  date.getTime() - date.getTimezoneOffset() * 60000
                                ).toISOString()
                              : ''
                          )
                        }
                        minDate={
                          formik.values.invoice_date
                            ? new Date(formik.values.invoice_date)
                            : new Date()
                        }
                        maxDate={new Date()}
                        onChangeRaw={(e) => {
                          setFieldTouched(`payment_date`, true, true);
                        }}
                        // onKeyDown={(e) => {
                        //   e.preventDefault();
                        // }}
                        autoComplete='off'
                        disabled={mode === 'view'}
                      />
                      <ErrorMessage name='payment_date' formik={formik} />
                    </div>
                  </div>
                  {/*end:: Date */}

                  {/*end::Input group*/}
                </div>
                {/*end::Top*/}
                {/*begin::Separator*/}
                <div className='separator separator-dashed my-10'></div>
                {/*end::Separator*/}
                {/*begin::Wrapper*/}
                <div className='mb-0'>
                  {/*begin::Row*/}
                  <div className='row gx-10 mb-5'>
                    {/*begin::Col*/}
                    <div className='col-lg-6'>
                      {/*  --------------------- Clients select-----------------------  */}

                      <Label msg='Client' htmlFor='clients' isRequired={values?.status === '1'} />
                      <AsyncSelect
                        key={JSON.stringify(`client`)}
                        onChange={(value) =>
                          value !== null
                            ? setFieldValue('clients', [value])
                            : setFieldValue('clients', [])
                        }
                        onBlur={() => formik.setFieldTouched('clients', true)}
                        value={formik.values?.clients}
                        // isMulti
                        isDisabled={mode === 'view'}
                        // className='react-select smaller'
                        className='form-control form-control-md p-0'
                        id='invoice_client_id'
                        inputId='invoice_client_id'
                        cacheOptions
                        loadOptions={loadClientsOptions}
                        defaultOptions
                        placeholder={formatMessage({
                          id: 'Client',
                        })}
                        isClearable
                        menuPlacement='auto'
                        noOptionsMessage={() => {
                          return (
                            <span role='alert' className='text-danger'>
                              {formatMessage({
                                id: 'No options found',
                              })}
                            </span>
                          );
                        }}
                        loadingMessage={() => (
                          <div
                            className='d-flex justify-content-center'
                            id='client_loading_indicator'
                          >
                            <div className='spinner-border text-primary' role='status'>
                              <span className='visually-hidden'>Loading...</span>
                            </div>
                          </div>
                        )}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            marginBottom: 2,
                            paddingBottom: 2,
                            zIndex: 3,
                          }),
                          menuPortal: (base: any) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                          noOptionsMessage: (base: any) => ({
                            ...base,
                            color: 'red',
                          }),
                        }}
                        components={{
                          Option: ({ children, ...props }: OptionProps<any, false>) => {
                            return (
                              <components.Option {...props}>
                                <div className='d-flex justify-content-between'>
                                  <span>{props.data?.label}</span>
                                </div>
                              </components.Option>
                            );
                          },
                        }}
                      />
                      <ErrorMessage name='clients' formik={formik} />
                      {/*  --------------------- end Clients select-----------------------  */}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    {invoice?.linked_invoice && (
                      <div className='col-lg-6'>
                        {/*  ------------------------ purchases reference invoice -----------------------  */}
                        <label className='form-label fs-6 fw-bolder text-gray-700 mb-3 required'>
                          {formatMessage({
                            id: 'Reference invoice number',
                          })}
                        </label>
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadSalesOptions}
                          onChange={(value) =>
                            formik.setFieldValue(`invoice_reference`, value ? [value] : [])
                          }
                          onBlur={() => formik.setFieldTouched(`invoice_reference`, true)}
                          value={formik.values?.invoice_reference}
                          defaultOptions
                          placeholder={formatMessage({
                            id: 'Reference invoice number',
                          })}
                          isDisabled={
                            mode === 'view' || (mode === 'edit' && !!invoice?.linked_invoice?.id)
                          }
                          isClearable
                          isOptionDisabled={(option: any) =>
                            !option?.is_returnable || option?.remaining_amount_with_vat === 0
                          }
                          className='form-control form-control-md p-0'
                          menuPlacement='auto'
                          noOptionsMessage={() => {
                            return (
                              <span role='alert' className='text-danger'>
                                {formatMessage({
                                  id: 'No invoice found with this reference number',
                                })}
                              </span>
                            );
                          }}
                          loadingMessage={() => (
                            <div className='d-flex justify-content-center'>
                              <div className='spinner-border text-primary' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                              </div>
                            </div>
                          )}
                          styles={{
                            menu: (base) => ({
                              ...base,
                              marginBottom: 2,
                              paddingBottom: 2,
                              zIndex: 3,
                            }),
                            menuPortal: (base: any) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                            noOptionsMessage: (base: any) => ({
                              ...base,
                              color: 'red',
                            }),
                          }}
                          components={{
                            Option: ({ children, ...props }: OptionProps<any, false>) => {
                              return (
                                <components.Option {...props}>
                                  <div
                                    className='d-flex justify-content-between'
                                    title={
                                      props.data?.is_returnable
                                        ? ''
                                        : formatMessage({
                                            id: 'The products of this invoice, are not returnable',
                                          })
                                    }
                                  >
                                    <span>{props.data?.label}</span>
                                    <span>{props.data?.remaining_amount_with_vat}</span>
                                  </div>
                                </components.Option>
                              );
                            },

                            MenuList: ({ children, ...props }) => {
                              return (
                                <components.MenuList {...props}>
                                  <div className='d-flex justify-content-between mb-2'>
                                    <span>
                                      {formatMessage({
                                        id: 'Invoice number',
                                      })}
                                    </span>
                                    <span>
                                      {formatMessage({
                                        id: 'Remaining amount',
                                      })}
                                    </span>
                                  </div>
                                  <SeparatorLines lines={2} />

                                  {children}
                                </components.MenuList>
                              );
                            },
                          }}
                          id={`invoice_reference`}
                        />
                        {formik.errors?.invoice_reference && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              {formik.errors?.invoice_reference}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {/*end::Col*/}
                  </div>
                  {/*end::Row*/}
                  {/*begin::Row*/}
                  <div className='row gx-10 mb-7'>
                    {/*begin::Col*/}

                    {/*begin::Input group*/}
                    {['ar', 'en'].map((lang) => (
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bolder text-gray-700 mb-3'>
                          {LocaleTitle({ id: 'feedback', lang })}
                        </label>
                        <TextArea
                          placeholder={LocaleTitle({
                            id: 'feedback',
                            lang,
                          })}
                          autoComplete='off'
                          name={`description_${lang}`}
                          disabled={mode === 'view'}
                          type='text'
                          formik={formik}
                          rows='6'
                          dir={locale === 'ar' ? 'rtl' : 'ltr'}
                          error={errors?.[`description_${lang}`]}
                          touched={values[`description_${lang}`]}
                          id={`description_${lang}`}
                        />
                      </div>
                    ))}
                    {/*end::Input group*/}
                  </div>
                  {/*end::Row*/}
                  <div className='col-md-6 mt-3'>
                    <MultipleFilesUploader
                      mode={mode}
                      setFile={set_multiple_document}
                      file={multiple_document}
                      maxSize={2048}
                      supportedFiles={allowedFiles}
                      maxfileUploaded={multiple_document_maxFiles}
                      disabled={mode === 'view'}
                      urlApiDelete={`/${companyDetails?.id}/invoices/${invoice?.id}/docs`}
                    />
                  </div>{' '}
                </div>
                {/*end::Wrapper*/}
              </div>
              <div className='row p-10'>
                {/*begin::Table wrapper*/}
                <div className='table-wrapper card'>
                  <div className='card-body '>
                    <div className={`${width < 900 ? 'table-responsive' : ''} mb-10`}>
                      {/*begin::Table*/}
                      <table className='table g-5 gs-0 mb-0 fw-bolder text-gray-700 pb-5 pb-xl-8 '>
                        {/*begin::Table head*/}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bolder text-gray-700 text-uppercase'>
                            {formik.values.invoice_type !== 'cashback'
                              ? headerItems.map((item) => (
                                  <th
                                    key={item.id}
                                    className={
                                      width <= 768
                                        ? `min-w-${item?.minWidth || 100}px max-w-${
                                            item?.maxWidth || 100
                                          }px  w-${item?.width || 100}px`
                                        : ` max-w-${item?.maxWidth || 100}px  w-${
                                            item?.width || 100
                                          }px`
                                    }
                                  >
                                    {formatMessage({ id: item.id })}
                                  </th>
                                ))
                              : headerItemsForCashback.map((item) => (
                                  <th
                                    key={item.id}
                                    className={
                                      width <= 768
                                        ? `min-w-${item?.minWidth || 100}px max-w-${
                                            item?.maxWidth || 100
                                          }px  w-${item?.width || 100}px`
                                        : ` max-w-${item?.maxWidth || 100}px  w-${
                                            item?.width || 100
                                          }px`
                                    }
                                  >
                                    {formatMessage({ id: item.id })}
                                  </th>
                                ))}
                          </tr>
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}
                        <tbody>
                          {/*begin::Field array*/}
                          <FieldArray name='invoice_items'>
                            {({ push, remove }) => {
                              const error: SalesInvoiceItemsErrors[] =
                                errors?.invoice_items as SalesInvoiceItemsErrors[];
                              const itemsValues = values.invoice_items;
                              const itemsTouched: any = formik.touched
                                .invoice_items as SalesInvoiceItemsTouched[];
                              return (
                                <>
                                  {itemsValues?.map((item: any, i: any, arr: any) => {
                                    return (
                                      <tr
                                        key={JSON.stringify(i)}
                                        className='border-bottom border-bottom-dashed'
                                      >
                                        {formik.values.invoice_type !== 'cashback' && (
                                          <>
                                            <td className='pe-7'>
                                              <AsyncSelect
                                                key={JSON.stringify(
                                                  `invoice_reference_product ${
                                                    formik.values?.invoice_items?.[i]['name'] + i
                                                  }`
                                                )}
                                                cacheOptions
                                                loadOptions={loadProductsOptions}
                                                onChange={(value) => selectProduct(item, value, i)}
                                                onBlur={() =>
                                                  formik.setFieldTouched(
                                                    `invoice_items[${i}]['name']`,
                                                    true
                                                  )
                                                }
                                                onFocus={() => {
                                                  onFocusloadProductsOptions(i);
                                                }}
                                                value={formik.values?.invoice_items?.[i]['name']}
                                                defaultOptions={productOptions}
                                                placeholder={formatMessage({
                                                  id: 'Product Name',
                                                })}
                                                isDisabled={
                                                  mode === 'view' ||
                                                  (mode === 'edit' && !!invoice?.linked_invoice?.id)
                                                }
                                                isClearable
                                                className='form-control form-control-md p-0 mb-2'
                                                menuPlacement='auto'
                                                noOptionsMessage={() => {
                                                  return (
                                                    <span role='alert' className='text-danger'>
                                                      {formatMessage({
                                                        id: 'No options found',
                                                      })}
                                                    </span>
                                                  );
                                                }}
                                                loadingMessage={() => (
                                                  <div className='d-flex justify-content-center'>
                                                    <div
                                                      className='spinner-border text-primary'
                                                      role='status'
                                                    >
                                                      <span className='visually-hidden'>
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                                styles={{
                                                  menu: (base) => ({
                                                    ...base,
                                                    marginBottom: 2,
                                                    paddingBottom: 2,
                                                    zIndex: 3,
                                                  }),
                                                  menuPortal: (base: any) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                  noOptionsMessage: (base: any) => ({
                                                    ...base,
                                                    color: 'red',
                                                  }),
                                                }}
                                                components={{
                                                  Option: ({
                                                    children,
                                                    ...props
                                                  }: OptionProps<any, false>) => {
                                                    return (
                                                      <components.Option {...props}>
                                                        <div className='d-flex justify-content-between'>
                                                          <span>{props.data?.label}</span>
                                                        </div>
                                                      </components.Option>
                                                    );
                                                  },
                                                }}
                                                id={`invoice_reference_product ${
                                                  formik.values?.invoice_items?.[i]['name'] + i
                                                }`}
                                              />
                                              {itemsTouched?.[i]?.name && error?.[i]?.name && (
                                                <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                  <span role='alert' className='text-danger'>
                                                    {error?.[i]?.name}
                                                  </span>
                                                </div>
                                              )}

                                              <Input
                                                setTouchedOnInput
                                                disabled={
                                                  mode === 'view' ||
                                                  (mode === 'edit' && !!invoice?.linked_invoice?.id)
                                                }
                                                className='form-control form-control-md mb-2'
                                                autoComplete='off'
                                                formik={formik}
                                                name={`invoice_items[${i}]['description']`}
                                                type='text'
                                                error={
                                                  error?.length &&
                                                  error?.[i] &&
                                                  error?.[i].description
                                                }
                                                touched={itemsTouched?.[i]?.description}
                                                id={`Item description ${i}`}
                                                placeholder={formatMessage({
                                                  id: 'Description',
                                                })}
                                              />

                                              <AsyncSelect
                                                key={
                                                  formik.values?.invoice_date?.toString() +
                                                  formik.values?.clients?.[0]?.category +
                                                  formik.values?.clients?.[0]?.value +
                                                  formik.values?.invoice_items?.[i]['category']?.[0]
                                                    ?.value +
                                                  formik.values?.invoice_items?.[i]['name']?.[0]
                                                    ?.value +
                                                  i
                                                }
                                                onChange={(value) => {
                                                  setFieldValue(`invoice_items[${i}]['category']`, [
                                                    value,
                                                  ]);
                                                }}
                                                onBlur={() =>
                                                  formik.setFieldTouched(
                                                    `invoice_items[${i}]['category']`,
                                                    true
                                                  )
                                                }
                                                onFocus={() => {
                                                  onFocusLoadSalesCategoriesOptions(i);
                                                }}
                                                value={
                                                  formik.values?.invoice_items?.[i]['category']
                                                }
                                                placeholder={formatMessage({
                                                  id: 'Categories',
                                                })}
                                                isDisabled={
                                                  mode === 'view' ||
                                                  (mode === 'edit' && !!invoice?.linked_invoice?.id)
                                                }
                                                className='form-control form-control-md p-0 mb-2'
                                                menuPlacement='auto'
                                                id={`invoice_items[${i}]['category']`}
                                                loadOptions={loadSalesCategoriesOptions}
                                                defaultOptions={salesCategoriesOptions}
                                                isClearable
                                                noOptionsMessage={() => {
                                                  return (
                                                    <span role='alert' className='text-danger'>
                                                      {formatMessage({
                                                        id: 'No options found',
                                                      })}
                                                    </span>
                                                  );
                                                }}
                                                loadingMessage={() => (
                                                  <div className='d-flex justify-content-center'>
                                                    <div
                                                      className='spinner-border text-primary'
                                                      role='status'
                                                    >
                                                      <span className='visually-hidden'>
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                                styles={{
                                                  menu: (base) => ({
                                                    ...base,
                                                    marginBottom: 2,
                                                    paddingBottom: 2,
                                                    zIndex: 3,
                                                  }),
                                                  menuPortal: (base: any) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                  noOptionsMessage: (base: any) => ({
                                                    ...base,
                                                    color: 'red',
                                                  }),
                                                }}
                                                components={{
                                                  Option: ({
                                                    children,
                                                    ...props
                                                  }: OptionProps<any, false>) => {
                                                    return (
                                                      <components.Option {...props}>
                                                        <div
                                                          className='d-flex justify-content-between'
                                                          title={props.data?.label}
                                                        >
                                                          <span className=''>
                                                            {props.data?.label}
                                                          </span>
                                                        </div>
                                                      </components.Option>
                                                    );
                                                  },
                                                }}
                                              />

                                              {itemsTouched?.[i]?.category && error?.[i]?.category && (
                                                <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                  <span role='alert' className='text-danger'>
                                                    {error?.[i]?.category}
                                                  </span>
                                                </div>
                                              )}
                                            </td>
                                            <td className='ps-0'>
                                              <Input
                                                setTouchedOnInput
                                                disabled={mode === 'view'}
                                                className='form-control form-control-md'
                                                autoComplete='off'
                                                formik={formik}
                                                name={`invoice_items[${i}]['quantity']`}
                                                onKeyUp={(e: any) => {
                                                  calculateItemValues(
                                                    item,
                                                    i,
                                                    'quantity',
                                                    e?.target?.value
                                                  );
                                                }}
                                                type='text'
                                                error={
                                                  error?.length && error?.[i] && error?.[i].quantity
                                                }
                                                touched={itemsTouched?.[i]?.quantity}
                                                id={`Returned quantity ${i}`}
                                                placeholder={formatMessage({
                                                  id: 'Returned quantity',
                                                })}
                                              />
                                            </td>
                                          </>
                                        )}
                                        <td className='ps-0'>
                                          <Input
                                            setTouchedOnInput
                                            disabled={
                                              mode === 'view' ||
                                              (mode === 'edit' && !!invoice?.linked_invoice?.id)
                                            }
                                            className='form-control form-control-md'
                                            autoComplete='off'
                                            formik={formik}
                                            name={`invoice_items[${i}]['price']`}
                                            onKeyUp={(e: any) => {
                                              calculateItemValues(
                                                item,
                                                i,
                                                'price',
                                                e?.target?.value
                                              );
                                            }}
                                            type='text'
                                            error={error?.length && error?.[i] && error?.[i].price}
                                            touched={itemsTouched?.[i]?.price}
                                            id={`Price ${i}`}
                                            placeholder={formatMessage({
                                              id: 'Unit Price',
                                            })}
                                          />
                                        </td>
                                        {formik.values.invoice_type !== 'cashback' && (
                                          <>
                                            <td className='ps-0'>
                                              <Input
                                                setTouchedOnInput
                                                disabled={
                                                  mode === 'view' ||
                                                  (mode === 'edit' && !!invoice?.linked_invoice?.id)
                                                }
                                                className='form-control form-control-md'
                                                autoComplete='off'
                                                formik={formik}
                                                name={`invoice_items[${i}]['total_before_discount']`}
                                                onKeyUp={(e: any) => {
                                                  calculateItemValues(
                                                    item,
                                                    i,
                                                    'total_before_discount',
                                                    e?.target?.value
                                                  );
                                                }}
                                                type='text'
                                                error={
                                                  error?.length &&
                                                  error?.[i] &&
                                                  error?.[i].total_before_discount
                                                }
                                                touched={
                                                  itemsTouched?.[i]?.total_before_discount
                                                    ? true
                                                    : false
                                                }
                                                id={`Item total price ${i}`}
                                                placeholder={formatMessage({
                                                  id: 'Price before discount',
                                                })}
                                              />
                                            </td>
                                            <td className='ps-0'>
                                              <Input
                                                setTouchedOnInput
                                                disabled={mode === 'view'}
                                                className='form-control form-control-md'
                                                autoComplete='off'
                                                formik={formik}
                                                name={`invoice_items[${i}]['discount_amount']`}
                                                onKeyUp={(e: any) => {
                                                  calculateItemValues(
                                                    item,
                                                    i,
                                                    'discount_amount',
                                                    e?.target?.value
                                                  );
                                                }}
                                                type='text'
                                                error={
                                                  error?.length &&
                                                  error?.[i] &&
                                                  error?.[i].discount_amount
                                                }
                                                touched={
                                                  itemsTouched?.[i]?.discount_amount ? true : false
                                                }
                                                id={`Item total price ${i}`}
                                                placeholder={formatMessage({
                                                  id: 'Discount amount',
                                                })}
                                              />
                                            </td>
                                            <td className='ps-0'>
                                              <Input
                                                setTouchedOnInput
                                                disabled={
                                                  mode === 'view' ||
                                                  (mode === 'edit' && !!invoice?.linked_invoice?.id)
                                                }
                                                className='form-control form-control-md'
                                                autoComplete='off'
                                                formik={formik}
                                                name={`invoice_items[${i}]['price_before_vat']`}
                                                onKeyUp={(e: any) => {
                                                  calculateItemValues(
                                                    item,
                                                    i,
                                                    'price_before_vat',
                                                    e?.target?.value
                                                  );
                                                }}
                                                type='text'
                                                error={
                                                  error?.length &&
                                                  error?.[i] &&
                                                  error?.[i].price_before_vat
                                                }
                                                touched={
                                                  itemsTouched?.[i]?.price_before_vat ? true : false
                                                }
                                                id={`Item total price ${i}`}
                                                placeholder={formatMessage({
                                                  id: 'Price before VAT',
                                                })}
                                              />
                                            </td>
                                          </>
                                        )}
                                        <td className='ps-0'>
                                          <AsyncSelect
                                            key={
                                              formik.values?.invoice_date?.toString() +
                                              formik.values?.clients?.[0]?.value +
                                              formik.values?.invoice_items?.[i]['vat_rate']?.[0]
                                                ?.value +
                                              formik.values?.invoice_items?.[i]['name']?.[0]
                                                ?.value +
                                              i
                                            }
                                            onChange={(value) => {
                                              setFieldValue(
                                                `invoice_items[${i}]['vat_rate']`,
                                                value ? [value] : []
                                              );

                                              setFieldValue(
                                                `invoice_items[${i}]['tax_value']`,
                                                value
                                                  ? item.total_before_discount * (value?.rate / 100)
                                                  : 0
                                              );
                                              setFieldValue(
                                                `invoice_items[${i}]['total']`,
                                                value
                                                  ? item.total_before_discount +
                                                      item.total_before_discount *
                                                        (value?.rate / 100)
                                                  : 0
                                              );
                                            }}
                                            onBlur={() =>
                                              formik.setFieldTouched(
                                                `invoice_items[${i}]['vat_rate']`,
                                                true
                                              )
                                            }
                                            onFocus={() => {
                                              onFocusloadReturnVatRateOptions(i);
                                            }}
                                            value={formik.values?.invoice_items?.[i]['vat_rate']}
                                            placeholder={formatMessage({
                                              id: 'TAX rate',
                                            })}
                                            isDisabled={
                                              mode === 'view' ||
                                              (mode === 'edit' && !!invoice?.linked_invoice?.id) ||
                                              (mode === 'edit' &&
                                                !companyDetails?.settings?.vat_registered)
                                            }
                                            className='form-control form-control-md p-0 mb-2'
                                            menuPlacement='auto'
                                            id={`invoice_items[${i}]['vat_rate']`}
                                            loadOptions={loadReturnVatRateOptions}
                                            defaultOptions={vatRateOptions}
                                            isClearable
                                            noOptionsMessage={() => {
                                              return (
                                                <span role='alert' className='text-danger'>
                                                  {formatMessage({
                                                    id: 'No options found',
                                                  })}
                                                </span>
                                              );
                                            }}
                                            loadingMessage={() => (
                                              <div className='d-flex justify-content-center'>
                                                <div
                                                  className='spinner-border text-primary'
                                                  role='status'
                                                >
                                                  <span className='visually-hidden'>
                                                    Loading...
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                marginBottom: 2,
                                                paddingBottom: 2,
                                                zIndex: 3,
                                              }),
                                              menuPortal: (base: any) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                              noOptionsMessage: (base: any) => ({
                                                ...base,
                                                color: 'red',
                                              }),
                                            }}
                                            components={{
                                              Option: ({
                                                children,
                                                ...props
                                              }: OptionProps<any, false>) => {
                                                return (
                                                  <components.Option {...props}>
                                                    <div className='d-flex justify-content-between'>
                                                      <span
                                                        className='mw-75'
                                                        title={
                                                          props.data?.name
                                                            ? props.data?.name
                                                            : props.data?.label
                                                        }
                                                      >
                                                        {props.data?.name
                                                          ? props.data?.name
                                                          : props.data?.label}
                                                      </span>
                                                    </div>
                                                  </components.Option>
                                                );
                                              },
                                            }}
                                            formatGroupLabel={(data: any) => (
                                              <div>
                                                <h3 className='d-flex justify-content-between align-items-center '>
                                                  {data.label}
                                                </h3>
                                                <SeparatorLines lines={3} />
                                              </div>
                                            )}
                                          />

                                          {itemsTouched?.[i]?.vat_rate && error?.[i]?.vat_rate && (
                                            <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                              <span role='alert' className='text-danger'>
                                                {error?.[i]?.vat_rate}
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                        <td className='ps-0'>
                                          <Input
                                            setTouchedOnInput
                                            disabled={true}
                                            className='form-control form-control-md'
                                            autoComplete='off'
                                            formik={formik}
                                            name={`invoice_items[${i}]['tax_value']`}
                                            onKeyUp={(e: any) => {
                                              calculateItemValues(
                                                item,
                                                i,
                                                'tax_value',
                                                e?.target?.value
                                              );
                                            }}
                                            type='text'
                                            error={
                                              error?.length && error?.[i] && error?.[i].tax_value
                                            }
                                            touched={itemsTouched?.[i]?.tax_value}
                                            id={`Item tax value ${i}`}
                                            placeholder={formatMessage({
                                              id: '_VAT',
                                            })}
                                          />
                                        </td>

                                        <td className='ps-0'>
                                          <Input
                                            setTouchedOnInput
                                            disabled={true}
                                            className='form-control form-control-md'
                                            autoComplete='off'
                                            formik={formik}
                                            name={`invoice_items[${i}]['total']`}
                                            type='text'
                                            error={error?.length && error?.[i] && error?.[i].total}
                                            touched={itemsTouched?.[i]?.total}
                                            id={`Item total ${i}`}
                                            placeholder={formatMessage({
                                              id: 'Total',
                                            })}
                                          />
                                        </td>

                                        {mode === 'create' &&
                                          formik.values.invoice_type !== 'cashback' && (
                                            <td className='pt-5 text-center'>
                                              <button
                                                type='button'
                                                className='btn btn-sm btn-icon btn-active-color-primary'
                                                title={formatMessage({
                                                  id: 'Remove item',
                                                })}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  removeItem(remove, i);
                                                }}
                                              >
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                                <span className='svg-icon svg-icon-3'>
                                                  <KTSVG
                                                    path='/media/icons/duotune/general/gen027.svg'
                                                    className='svg-icon-1 svg-icon-gray-600'
                                                  />
                                                </span>
                                                {/*end::Svg Icon*/}
                                              </button>
                                            </td>
                                          )}
                                        {mode === 'edit' &&
                                          !invoice?.linked_invoice?.id &&
                                          formik.values.invoice_type !== 'cashback' && (
                                            <td className='pt-5 text-center'>
                                              <button
                                                type='button'
                                                className='btn btn-sm btn-icon  btn-bg-light btn-active-color-primary me-1'
                                                title={formatMessage({
                                                  id: 'Remove item',
                                                })}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  removeItem(remove, i);
                                                }}
                                              >
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                                <span className='svg-icon svg-icon-3'>
                                                  <KTSVG
                                                    path='/media/icons/duotune/general/gen027.svg'
                                                    className='svg-icon-1 svg-icon-gray-600'
                                                  />
                                                </span>
                                                {/*end::Svg Icon*/}
                                              </button>
                                              {userCan('view-invoice') &&
                                                invoice?.invoice_items?.[i]?.errors &&
                                                invoice?.invoice_items?.[i]?.errors.length > 0 && (
                                                  <LineItemErrorIcon
                                                    title={formatMessage({
                                                      id: 'Has Errors',
                                                    })}
                                                    color={invoice?.invoice_items?.[i].errors?.map(
                                                      (error: any) => error?.color
                                                    )}
                                                    onClick={(e: any) => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                      setErrorsConfirmation(
                                                        invoice?.invoice_items?.[i].errors?.map(
                                                          (error: any) => error?.vat_rate_id
                                                        )
                                                      );
                                                      setShowErrorsConfirmationModal(true);
                                                    }}
                                                  />
                                                )}
                                            </td>
                                          )}
                                        {mode === 'view' &&
                                          invoice?.invoice_items?.[i]?.errors &&
                                          invoice?.invoice_items?.[i]?.errors.length > 0 && (
                                            <td className='pt-5 text-center'>
                                              {userCan('view-invoice') &&
                                                invoice?.invoice_items?.[i].errors && (
                                                  <LineItemErrorIcon
                                                    title={formatMessage({
                                                      id: 'Has Errors',
                                                    })}
                                                    color={invoice?.invoice_items?.[i].errors?.map(
                                                      (error: any) => error?.color
                                                    )}
                                                    onClick={(e: any) => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                      setErrorsConfirmation(
                                                        invoice?.invoice_items?.[i].errors?.map(
                                                          (error: any) => error?.vat_rate_id
                                                        )
                                                      );
                                                      setShowErrorsConfirmationModal(true);
                                                    }}
                                                  />
                                                )}
                                            </td>
                                          )}
                                      </tr>
                                    );
                                  })}
                                  {mode === 'create' && (
                                    <tr className='border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700'>
                                      <th className='text-primary'>
                                        <button
                                          className='btn btn-primary py-1'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            addItem(push);
                                          }}
                                        >
                                          {formatMessage({
                                            id: 'Add Item',
                                          })}
                                        </button>
                                      </th>
                                    </tr>
                                  )}
                                  {mode === 'edit' &&
                                    !invoice?.linked_invoice?.id &&
                                    formik.values.invoice_type !== 'cashback' && (
                                      <tr className='border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700'>
                                        <th className='text-primary'>
                                          <button
                                            className='btn btn-primary py-1'
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              addItem(push);
                                            }}
                                          >
                                            {formatMessage({
                                              id: 'Add Item',
                                            })}
                                          </button>
                                        </th>
                                      </tr>
                                    )}
                                </>
                              );
                            }}
                          </FieldArray>
                          {typeof errors.invoice_items === 'string' && (
                            <ErrorMessage name='invoice_items' formik={formik} />
                          )}
                          {/*end::Field array*/}
                        </tbody>
                        {/*end::Table body*/}
                        {formik.values?.invoice_type !== 'cashback' && (
                          <>
                            {' '}
                            {/*begin::Table foot*/}
                            <tfoot>
                              <tr className='align-top fw-bolder text-gray-700'>
                                <th colSpan={3} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Total ',
                                  })}
                                  :
                                </th>
                                <th colSpan={1} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Price before discount',
                                  })}
                                </th>
                                <th colSpan={1} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Discount amount',
                                  })}
                                </th>
                                <th colSpan={1} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Price before VAT',
                                  })}
                                </th>
                                <th colSpan={1} className='fs-4 ps-0'></th>
                                <th colSpan={1} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: '_VAT',
                                  })}
                                </th>
                                <th colSpan={1} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Total',
                                  })}
                                </th>
                              </tr>

                              <tr className='align-top fw-bolder text-gray-700'>
                                <th colSpan={3} className='fs-4 ps-0'></th>
                                <th colSpan={1} className='fs-4 text-nowrap'>
                                  <span>
                                    {/* get sum of total in all total_before_discount */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.total_before_discount;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.total_before_discount;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + object.total_before_discount;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                                <th colSpan={1} className='fs-4 text-nowrap'>
                                  <span>
                                    {/* get sum of total in all total_before_discount */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + +object.discount_amount;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + +object.discount_amount;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + +object.discount_amount;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                                <th colSpan={2} className='fs-4 text-nowrap'>
                                  <span>
                                    {/* get sum of total in all total_before_discount */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.price_before_vat;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.price_before_vat;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + object.price_before_vat;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                                <th colSpan={1} className='fs-4 text-nowrap'>
                                  <span>
                                    {/* get sum of total in all tax_value */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.tax_value;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.tax_value;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + object.tax_value;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                                <th colSpan={1} className='fs-4 text-nowrap'>
                                  <span>
                                    {/* get sum of total in all invoice_items */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.total;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.total;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + object.total;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                              </tr>
                            </tfoot>
                            {/*end::Table foot*/}
                          </>
                        )}
                        {formik.values?.invoice_type === 'cashback' && (
                          <>
                            {' '}
                            {/*begin::Table foot*/}
                            <tfoot>
                              <tr className='align-top fw-bolder text-gray-700'>
                                <th colSpan={2} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Total ',
                                  })}{' '}
                                  :
                                </th>
                                {formik.values.invoice_type !== 'cashback' && (
                                  <th colSpan={2} className='fs-4 ps-0'>
                                    {formatMessage({
                                      id: 'Price before VAT',
                                    })}
                                  </th>
                                )}
                                <th
                                  colSpan={formik.values.invoice_type === 'cashback' ? 1 : 2}
                                  className='fs-4 ps-0'
                                >
                                  {formatMessage({
                                    id: '_VAT',
                                  })}
                                </th>
                                <th colSpan={2} className='fs-4 ps-0'>
                                  {formatMessage({
                                    id: 'Total',
                                  })}
                                </th>
                              </tr>

                              <tr className='align-top fw-bolder text-gray-700'>
                                <th colSpan={2} className='fs-4 ps-0'></th>
                                {formik.values.invoice_type !== 'cashback' && (
                                  <th colSpan={2} className='fs-4 text-nowrap'>
                                    <span>
                                      {/* get sum of total in all price_before_vat */}
                                      {Number.isInteger(
                                        formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.price_before_vat;
                                          },
                                          0
                                        )
                                      )
                                        ? formik.values?.invoice_items?.reduce(
                                            (accumulator: any, object: any) => {
                                              return accumulator + object.price_before_vat;
                                            },
                                            0
                                          )
                                        : formik.values?.invoice_items
                                            ?.reduce((accumulator: any, object: any) => {
                                              return accumulator + object.price_before_vat;
                                            }, 0)
                                            .toFixed(2)}
                                    </span>
                                  </th>
                                )}
                                <th
                                  colSpan={formik.values.invoice_type === 'cashback' ? 1 : 2}
                                  className='fs-4 text-nowrap'
                                >
                                  <span>
                                    {/* get sum of total in all tax_value */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.tax_value;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.tax_value;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + object.tax_value;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                                <th colSpan={2} className='fs-4 text-nowrap'>
                                  <span>
                                    {/* get sum of total in all invoice_items */}
                                    {Number.isInteger(
                                      formik.values?.invoice_items?.reduce(
                                        (accumulator: any, object: any) => {
                                          return accumulator + object.total;
                                        },
                                        0
                                      )
                                    )
                                      ? formik.values?.invoice_items?.reduce(
                                          (accumulator: any, object: any) => {
                                            return accumulator + object.total;
                                          },
                                          0
                                        )
                                      : formik.values?.invoice_items
                                          ?.reduce((accumulator: any, object: any) => {
                                            return accumulator + object.total;
                                          }, 0)
                                          .toFixed(2)}
                                  </span>
                                </th>
                              </tr>
                            </tfoot>
                            {/*end::Table foot*/}
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                {/*end::Table*/}
              </div>
              {/* Save and Cancel Buttons */}
              {mode !== 'view' && (
                <>
                  <SaveCancelBtnsGroup
                    saveBtnLabel='save'
                    formik={formik}
                    loading={loading}
                    cancelOnClick={() => {
                      formik.resetForm();
                      history.push(`/c/${companyDetails.slug}/financial-management/sales-invoices`);
                    }}
                  />
                </>
              )}
            </div>
          </Form>
        </FormikProvider>
      </div>

      {showConfirmationModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => {
              setUserApproved(false);
              setShowConfirmationModal(false);
            }}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                <span>
                  {companyDetails?.settings?.invoice_number_method === '2'
                    ? formatMessage({
                        id: 'Are you sure you want to issue this invoice? you will not be able to edit or delete it',
                      })
                    : formatMessage({
                        id: 'Are you sure you want to issue this invoice?',
                      })}
                </span>
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setUserApproved(true);
                        setShowConfirmationModal(false);
                        formik.submitForm();
                      }}
                      type='button'
                      id='modal_approve'
                      className='btn btn-sm btn-success btn-shadow btn-success-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Approve' />
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setUserApproved(false);
                        setShowConfirmationModal(false);
                      }}
                      type='button'
                      id='modal_cancel'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------warnings confirmation Modal-----------------------  */}

      {showErrorsConfirmationModal && errorsConfirmation && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => setShowErrorsConfirmationModal(false)}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 m-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                {errorsConfirmation.length === 1 && (
                  <span className='min-w-250px'>{errorsConfirmation[0]}</span>
                )}
                {errorsConfirmation.length > 1 && (
                  <ol>
                    {errorsConfirmation.map((warning: any, index: any) => (
                      <li className='min-w-250px' key={index}>
                        {warning}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowErrorsConfirmationModal(false);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end warnings confirmation Modal-----------------------  */}
    </div>
  );
};

const ConfigReturnSalesNewInvoiceWrapperCreate: FC = () => {
  usePermissions(['create-invoice']);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales invoices' })}</PageTitle>
      <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5 details_nav '>
        <span
          className='mx-2 cursor-pointer text-blueGrey current fs-6 '
          onClick={() => {
            history.push(`/c/${companyDetails.slug}/financial-management/sales-invoices`);
          }}
        >
          {formatMessage({ id: 'Back to invoices and cancel' })}
        </span>
      </div>
      <HorizontalConfigNewReturnSalesWrapper />
    </div>
  );
};

const ConfigReturnSalesNewInvoiceWrapperEdit: FC = () => {
  usePermissions(['update-invoice']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales invoices' })}</PageTitle>
      <ConfigReturnSalesNewInvoiceWrapper mode='edit' />
    </div>
  );
};

const ConfigReturnSalesNewInvoiceWrapperView: FC = () => {
  usePermissions(['view-invoice']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales invoices' })}</PageTitle>
      <ConfigReturnSalesNewInvoiceWrapper mode='view' />
    </div>
  );
};

export {
  ConfigReturnSalesNewInvoiceWrapperCreate,
  ConfigReturnSalesNewInvoiceWrapperEdit,
  ConfigReturnSalesNewInvoiceWrapperView,
};
