
import * as DocumentsRedux from './store/DocumentsRedux';

import { Col, Container, Row } from 'react-bootstrap-v5';
import { DOCS_STATUS, RECIPIENT_TYPES } from '../../../_metronic/constants/general';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import {
  companySignatureNeeded,
  isReviewerNeeded,
  secondPartySignaturesNeeded,
} from '../../../_metronic/helpers/functions/signatures';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { $t } from '../../../_metronic/i18n/formatMessage';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { CompanyModel } from '../companies/CompanyModel';
import DatePicker from 'react-datepicker';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { DocumentApproval } from './DocumentProcess/DocumentApproveAndSign';
import { IDocument } from './types/DocumentsModel';
import { Input } from '../../shared/Input';
import { Label } from '../../shared/Label';
import { Portal } from '../../../_metronic/partials';
import { RootState } from '../../../setup';
import Select from 'react-select';
import { SelectOption } from '../../types/SelectOptions';
import { UserModel } from '../auth/models/UserModel';
import _ from 'lodash';
import { axiosInstance } from '../../../network/apis';
import { companySlugTo } from '../../../_metronic/helpers/functions';
import { countires } from '../../../_metronic/constants/countries';
import { createOptions } from '../../../utils/createOptions';
import { pairsToObject } from './pairsToObject';
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { useFormik } from 'formik';
import { usePermissions } from '../../hooks/usePermissions';

const DocumentEditor: FC = () => {
  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  const { userCan } = usePermissions('create-document', 'Create Document');
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { formatMessage, locale } = useIntl();

  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const [countryFieldVariableName, setCountryFieldVariableName] = useState<string | undefined>();
  const [documentToApproveModal, setDocumentToApproveModal] = useState<
    null | IDocument | DocumentsRedux.UnderConstructionDocument
  >(null);

  /* ---------------------------------- store --------------------------------- */

  const underConstructionDocument: DocumentsRedux.UnderConstructionDocument =
    useSelector<RootState>(
      (state) => state.USER_documents.underConstructionDocument
    ) as DocumentsRedux.UnderConstructionDocument;

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails
  ) as CompanyModel;

  /* -------------------------------- end store ------------------------------- */

  /* --------------------------------- formik --------------------------------- */

  const formik = useFormik({
    initialValues: {
      recipients: underConstructionDocument?.recipients || [],
      second_party: underConstructionDocument?.second_party || [],
      reviewers: underConstructionDocument?.reviewers || [],
      viewers: underConstructionDocument?.viewers || [],
      signers: underConstructionDocument?.signers || [],
      variables: [
        pairsToObject(
          underConstructionDocument?.variables?.[0]?.map((variable: any) => [
            variable?.id,
            variable.value,
            variable.type,
          ])
        ),
      ],
    },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {},
  });

  useEffect(() => {
    const countryObject = underConstructionDocument?.variables?.[0].find(
      (item) => item.type === 'countries'
    );
    if (countryObject) {
      setCountryFieldVariableName(countryObject?.id);
    }
    formik.resetForm({ values: formik.values });
  }, [underConstructionDocument]);

  /* ------------------------ getting current document ------------------------ */
  const getCurrentDocument = async (id: string) => {
    if (!id) return;
    try {
      const res = await axiosInstance.get(`${companyDetails.id}/documents/${id}`);
      dispatch(
        DocumentsRedux.actions.fulfillUnderConstructionDocument({
          underConstructionDocument: res.data,
        })
      );
    } catch (error: any) {
      DisplayErrors(error);
    }
  };

  const returnToDocumentCreator = async (documentId: number) => {
    try {
      await axiosInstance.post(`/${companyDetails.id}/reviewers/documents/${documentId}`);
      getCurrentDocument(params?.documentId);
      toast.success($t('Document has been sent to creator successfully'));
    } catch (error) {}
  };

  useEffect(() => {
    if (params.documentId) {
      getCurrentDocument(params?.documentId);
    }
  }, [params]);

  /* ------------------------ end getting current document ------------------------ */

  const documentPayload = () => {
    const values: any = { ...formik.values };

    if (values?.reviewers) {
      values.reviewers = Array.isArray(values.reviewers)
        ? values.reviewers.map((member: any) => member.value)
        : [values.reviewers.value];
    }

    if (values?.signers) {
      values.signers = Array.isArray(values.signers)
        ? values.signers.map((member: any) => member.value)
        : [values.signers.value];
    }

    values.viewers = [...values.viewers].map((reviewer: any) => reviewer.value);

    values.recipients = values?.recipients.map((recipient: SelectOption) => recipient.value);

    if (values?.city) {
      values.city = [...values.cities].map((city: any) => city.value);
    }

    if (values?.country) {
      values.country = [...values.cities].map((city: any) => city.value);
    }

    return { ...values };
  };

  const saveAsDraft = async () => {

    if (!isFormDirty()) return;

    if (!underConstructionDocument.can_edit) {
      toast.error(formatMessage({ id: 'Document is not editable' }));
      return;
    }
    const payload = documentPayload();

    const payloadWithMethod = { ...payload, _method: 'PUT' };
    const formData = serialize(payloadWithMethod, { indices: true });
    try {
      const response = await axiosInstance.post(
        `${companyDetails.id}/documents/${params.documentId}/draft`,
        formData
      );
      toast.success(formatMessage({ id: 'The document has been saved as draft' }));
      // formik.resetForm({ values: formik.values });
      return response;
    } catch (error: any) {
      DisplayErrors(error);
    }
  };

  const saveAndExit = async () => {
    await saveAsDraft();
    setDisable();
    setShowExitPrompt(false);
    history.push(companySlugTo(companyDetails.slug, 'documents'));
  };

  const isFormDirty = () => {
    if (!formik.dirty) {
      toast.info(formatMessage({ id: 'No changes have been applied' }));
    }
    return formik.dirty;
  };

  const SaveAndPreview = async () => {
    // if (!isFormDirty()) return;

    try {
      const response = await saveAsDraft();
      window.open(
        response ? response?.data?.preview_link : underConstructionDocument?.preview_link,
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes'
      );
    } catch (error) {
      DisplayErrors(error);
    }
  };

  const allFieldsHaveData = (): { errors: any; isValid: boolean } => {
    const payload = formik.values;
    const errors = { signers: '', reviewers: '', recipients: '' };
    const { recipient_type } = underConstructionDocument;

    if (companySignatureNeeded(recipient_type) && payload.signers.length === 0) {
      errors.signers = formatMessage({ id: 'Please choose at least one' });
    }

    if (isReviewerNeeded(recipient_type) && payload.reviewers.length === 0) {
      formik.setFieldTouched('reviewers', true);
      errors.reviewers = formatMessage({ id: 'Please choose at least one' });
    }

    if (!payload?.recipients?.length) {
      formik.setFieldTouched('recipients', true);
      errors.recipients = formatMessage({ id: 'Please choose at least one' });
    }

    return {
      errors,
      isValid: Object.values(errors).every((value) => !value),
    };
  };

  const saveAndSendToReviewer = async () => {
    const fieldState = allFieldsHaveData();

    if (!fieldState.isValid) {
      setErrors(fieldState.errors);
      return;
    }

    const payload = documentPayload();

    setIsLoading(true);

    const payloadWithMethod = { ...payload, _method: 'PUT' };
    const formData = serialize(payloadWithMethod, { indices: true });

    try {
      await axiosInstance.post(
        `${companyDetails.id}/documents/${params.documentId}/send`,
        formData
      );
      toast.success(formatMessage({ id: 'Document has been sent successfully!' }));
      history.push(companySlugTo(companyDetails.slug, 'documents'));
    } catch (error) {
      DisplayErrors(error);
    }
    setIsLoading(false);
  };

  /* -------------------------- leaving page handlers ------------------------- */

  const shouldAsk = () => {
    return Object.keys(formik.touched).length > 0;
  };

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  const availableRecipients = [
    ...formik.values.reviewers,
    // ...formik.values.signers,
    // ...formik.values.viewers,
    { label: underConstructionDocument?.user?.name, value: underConstructionDocument?.user?.id },
    formik.values.second_party,
  ].flat();

  return (
    <div>
      {shouldAsk() && <Prompt message={$t('Are you sure you want to ignore the changes?')} />}
      <div className='d-flex align-items-stretch gap-2 flex-lg-grow-1 flex-row-reverse'>
        <button
          disabled={isLoading}
          className='btn btn-outlined-primary'
          onClick={() => saveAndSendToReviewer()}
        >
          <i className='fas fa-save' />
          <FormattedMessage
            id={
              underConstructionDocument?.recipient_type === RECIPIENT_TYPES['No signtures required']
                ? 'Save and send'
                : 'Save and send to reviewer'
            }
          />
        </button>
        <button
          disabled={isLoading || underConstructionDocument?.status !== 1}
          className='btn btn-outlined-primary'
          onClick={() => saveAndExit()}
        >
          <i className='fas fa-save' />
          <FormattedMessage id='Save and Exit' />
        </button>
        <button
          disabled={isLoading || underConstructionDocument?.status !== 1}
          className='btn btn-outlined-primary'
          onClick={() => saveAsDraft()}
        >
          <i className='fas fa-save' />
          <FormattedMessage id='Save as draft' />
        </button>
        <button
          disabled={isLoading || underConstructionDocument?.status !== 1}
          className='btn btn-outlined-primary'
          onClick={() => SaveAndPreview()}
        >
          <i className='fas fa-save' />
          <FormattedMessage id='Save and preview' />
        </button>
        {underConstructionDocument?.reviewers

          ?.map((reviewer: any) => reviewer?.value)
          .includes(user.id) && (
          <button
            disabled={isLoading}
            className='btn btn-outlined-primary'
            onClick={() => setDocumentToApproveModal(underConstructionDocument)}
          >
            <i className='fas fa-save' />
            <FormattedMessage id='taking_action' />
          </button>
        )}
      </div>

      {(underConstructionDocument?.status === DOCS_STATUS.WAITING_FOR_COMPANY ||
        underConstructionDocument?.status === DOCS_STATUS.WAITING_FOR_SECOND_PARTY) && (
        <div className='d-flex align-items-center bg-light-success rounded p-5 my-5'>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
              <FormattedMessage id='This document has been already sent to client. Making edits will terminate the previous version and create a new one' />
            </a>
          </div>
        </div>
      )}

      <span className='text-lightGreyBlue fs-5'>
        <FormattedMessage
          id='currect_version'
          values={{ version: underConstructionDocument?.version }}
        />
      </span>
      <div className='row pb-5 mt-4'>
        {underConstructionDocument?.recipient_type !== 5 && (
          <>
            {_.chunk(underConstructionDocument?.variables).length > 0 && (
              <Container className='table-wrapper p-10'>
                <p className='my-5'>{$t('Document Entry')}</p>
                {/*  -------------------------------- variables -------------------------------  */}
                {_.chunk(underConstructionDocument?.variables)?.map((variables: any) => (
                  <Row>
                    {variables.map((variable: any, variablesIndex: number | any) => (
                      <>
                        {/* eslint-disable-next-line array-callback-return */}
                        {variable.map((variable: any, index: number) => {
                          if (['text', 'number'].includes(variable.type))
                            return (
                              <Col xs='6'>
                                <Input
                                  placeholder={variable.id}
                                  autoComplete='off'
                                  labelId={variable.id}
                                  formik={formik}
                                  name={`variables[${variablesIndex}][${variable.id}]`}
                                  type={variable.type}
                                />
                              </Col>
                            );
                          if (['past_date', 'date', 'future_date'].includes(variable.type))
                            return (
                              <Col xs='6'>
                                <label
                                  className={'form-label fs-6 my-1 fw-bolder text-dark'}
                                  htmlFor={variable.id}
                                >
                                  {variable.id}
                                </label>
                                <DatePicker
                                  isClearable={true}
                                  selected={
                                    formik?.values?.variables?.[variablesIndex]?.[`${variable?.id}`]
                                  }
                                  dateFormat='dd/MM/yyyy'
                                  placeholderText={variable.id}
                                  className='form-control form-control-md b-radius-16 basic-input '
                                  maxDate={variable.type === 'past_date' ? new Date() : null}
                                  minDate={variable.type === 'future_date' ? new Date() : null}
                                  onChange={(date: any) =>
                                    formik.setFieldValue(
                                      `variables[${variablesIndex}][${variable.id}]`,
                                      date
                                    )
                                  }
                                />
                              </Col>
                            );

                          if (variable.type === 'dropdown')
                            return (
                              <Col xs='6'>
                                <label
                                  className={'form-label fs-6 my-1 fw-bolder text-dark'}
                                  htmlFor='second_party_selection'
                                >
                                  {variable.id}
                                </label>
                                <Select
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      `variables[${variablesIndex}][${variable.id}]`,
                                      value
                                    )
                                  }
                                  value={
                                    formik.values['variables'][variablesIndex][`${variable.id}`]
                                  }
                                  id='second_party_selection'
                                  placeholder={variable.id}
                                  options={variable.options}
                                  className='react-select smaller'
                                />
                              </Col>
                            );

                          if (variable.type === 'cities')
                            return (
                              <Col xs='6'>
                                <label
                                  className={'form-label fs-6 my-1 fw-bolder text-dark'}
                                  htmlFor='second_party_selection'
                                >
                                  {variable.id}
                                </label>

                                <Select
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      `variables[${variablesIndex}][${variable.id}]`,
                                      value
                                    )
                                  }
                                  value={
                                    formik.values?.['variables']?.[variablesIndex]?.[
                                      `${variable.id}`
                                    ]
                                  }
                                  id='second_party_selection'
                                  isDisabled={
                                    !formik.values?.['variables']?.[variablesIndex]?.[
                                      `${countryFieldVariableName}`
                                    ] ||
                                    countires.find(
                                      (country: any) =>
                                        country?.iso ===
                                        formik?.values?.['variables']?.[variablesIndex]?.[
                                          `${countryFieldVariableName}`
                                        ]?.value
                                    )?.cities.length === 0
                                  }
                                  placeholder={variable.id}
                                  options={countires
                                    .find(
                                      (country: any) =>
                                        country?.iso ===
                                        formik?.values?.['variables']?.[variablesIndex]?.[
                                          `${countryFieldVariableName}`
                                        ]?.value
                                    )
                                    ?.cities?.map((city: any) => ({
                                      value: city.iso.toString(),
                                      label: city?.name?.[locale],
                                    }))}
                                  className='react-select smaller'
                                />
                              </Col>
                            );

                          if (variable.type === 'countries')
                            return (
                              <Col xs='6'>
                                <label
                                  className={'form-label fs-6 my-1 fw-bolder text-dark'}
                                  htmlFor='second_party_selection'
                                >
                                  {variable.id}
                                </label>

                                <Select
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      `variables[${variablesIndex}][${variable.id}]`,
                                      value
                                    );

                                    setCountryFieldVariableName(variable.id);
                                    formik.setFieldValue(
                                      `variables[${variablesIndex}][المدينة]`,
                                      ''
                                    );
                                    formik.setFieldValue(`variables[${variablesIndex}][city]`, '');
                                    if (
                                      countires.find((country: any) => country?.iso === value.value)
                                        ?.cities.length === 0
                                    ) {
                                      /* 
                                  ! Here we set cities variable with the same country name 
                                  for countries that have no cities like جزر أولان 🥶🇦🇽
                                 */
                                      formik.setFieldValue(
                                        `variables[${variablesIndex}][المدينة]`,
                                        value
                                      );
                                      formik.setFieldValue(
                                        `variables[${variablesIndex}][city]`,
                                        value
                                      );
                                    }
                                  }}
                                  value={
                                    formik.values?.['variables']?.[variablesIndex]?.[
                                      `${variable.id}`
                                    ] || null
                                  }
                                  id='second_party_selection'
                                  placeholder={variable.id}
                                  options={countires?.map((country: any) => ({
                                    value: country.iso,
                                    label: country?.emoji + ' ' + country?.name?.[locale],
                                  }))}
                                  className='react-select smaller'
                                />
                              </Col>
                            );
                          if (variable.type === 'nationalities')
                            return (
                              <Col xs='6'>
                                <label
                                  className={'form-label fs-6 my-1 fw-bolder text-dark'}
                                  htmlFor='nationalitySelection'
                                >
                                  {variable.id}
                                </label>

                                <Select
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      `variables[${variablesIndex}][${variable.id}]`,
                                      value
                                    );
                                  }}
                                  value={
                                    formik.values?.['variables']?.[variablesIndex]?.[
                                      `${variable.id}`
                                    ] || null
                                  }
                                  id='nationalitySelection'
                                  placeholder={variable.id}
                                  options={countires?.map((country: any) => ({
                                    value: country.iso,
                                    label: country?.emoji + ' ' + country?.name?.[locale],
                                  }))}
                                  className='react-select smaller'
                                />
                              </Col>
                            );

                          if (variable.type === 'products' && !variable.has_initial)
                            return (
                              <Col xs='12'>
                                <label
                                  className={'form-label fs-6 my-1 fw-bolder text-dark'}
                                  htmlFor='products'
                                >
                                  {variable.id}
                                </label>

                                <Select
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      `variables[${variablesIndex}][${variable.id}]`,
                                      value
                                    );
                                  }}
                                  value={
                                    formik.values['variables'][variablesIndex][`${variable.id}`]
                                  }
                                  id='products_selection'
                                  placeholder={variable.id}
                                  options={createOptions(variable.options, 'name', 'value')}
                                  className='react-select smaller'
                                  isMulti
                                />
                              </Col>
                            );
                        })}
                      </>
                    ))}
                  </Row>
                ))}
              </Container>
            )}
            {secondPartySignaturesNeeded(underConstructionDocument?.recipient_type) && (
              <Container className='table-wrapper p-10 mt-5'>
                <Row>
                  <>
                    <label
                      className={'form-label fs-6 my-1 fw-bolder text-dark'}
                      htmlFor='second_party_selection'
                    >
                      {$t('Second Party')}
                    </label>
                    <Select
                      onChange={(value) => formik.setFieldValue(`second_party`, value)}
                      id='second_party_selection'
                      value={formik?.values?.second_party}
                      placeholder={$t('Second Party')}
                      options={underConstructionDocument?.second_party_list}
                      className='react-select smaller'
                    />
                  </>
                </Row>
              </Container>
            )}
            <Container className='table-wrapper p-10 mt-5'>
              {/* <p className='my-5'>{$t('Reviewers')}</p> */}

              {/* -------------------------------- Reviewer -------------------------------- */}
              {isReviewerNeeded(underConstructionDocument?.recipient_type) && (
                <Col>
                  <label
                    className={'form-label fs-6 my-1 fw-bolder text-dark required mt-2'}
                    htmlFor='reviewer'
                  >
                    {$t('Reviewers')}
                  </label>
                  <Select
                    id='reviewer'
                    className='react-select smaller'
                    placeholder={$t('Reviewer')}
                    isMulti
                    options={underConstructionDocument?.reviewers_list}
                    onChange={(value) => {
                      formik.setFieldValue('reviewers', value);
                      setErrors({ ...errors, reviewers: '' });
                    }}
                    value={formik.values.reviewers}
                  />
                  {errors.reviewers && (
                    <div className='fv-plugins-message-container mt-2 mx-3'>
                      <span role='alert' className='text-danger'>
                        {errors.reviewers}
                      </span>
                    </div>
                  )}
                </Col>

              )}
            </Container>
          </>
        )}
        {companySignatureNeeded(underConstructionDocument?.recipient_type) && (
          <Container className='table-wrapper p-10 mt-5'>
            <div className='row'>
              <div className='col-md-12'>
                <Label msg='Signers' htmlFor='signers' isRequired />
                <Select
                  onChange={(value) => {
                    formik.setFieldValue('signers', value);
                    setErrors({ ...errors, signers: '' });
                  }}
                  onBlur={() => formik.setFieldTouched('signers', true)}
                  options={underConstructionDocument?.signers_list}
                  value={formik.values.signers}
                  placeholder={$t('Signers')}
                  isMulti
                  className='react-select smaller'
                  id='signers'
                />
                {errors.signers && (
                  <div className='fv-plugins-message-container mt-2 mx-3'>
                    <span role='alert' className='text-danger'>
                      {errors.signers}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Container>
        )}

        <Container className='table-wrapper p-10 mt-5'>
          {/* ------------------------------- Recipients ------------------------------- */}
          <Col>
            <label
              className={'form-label fs-6 my-1 fw-bolder text-dark required'}
              htmlFor='second_party_selection'
            >
              {$t('Recipients')}
            </label>

            {underConstructionDocument && (
              <Select
                onChange={(value) => {
                  formik.setFieldValue('recipients', value);
                  setErrors({ ...errors, recipients: '' });
                }}
                id='recipients_list'
                isMulti
                value={formik?.values?.recipients}
                placeholder={$t('Recipients')}
                options={
                  underConstructionDocument?.is_confidential
                    ? availableRecipients
                    : Array.isArray(underConstructionDocument?.recipients_list)
                    ? [
                        ...underConstructionDocument?.recipients_list,
                        // ...underConstructionDocument?.signers_list,
                        // ...underConstructionDocument?.reviewers_list,
                        // formik?.values?.second_party,

                      ].flat()
                    : []
                }
                isDisabled={
                  underConstructionDocument?.is_confidential && availableRecipients.length === 0
                }
                className='react-select smaller'
              />
            )}
            {errors.recipients && (
              <div className='fv-plugins-message-container mt-2 mx-3'>
                <span role='alert' className='text-danger'>
                  {errors.recipients}
                </span>
              </div>
            )}
          </Col>
        </Container>

        {documentToApproveModal && userCan('sign-document') && (
          <Portal className='full_modal'>
            <AhadDialog
              titleId={$t('Document Process')}
              closeCallBack={() => setDocumentToApproveModal(null)}
            >
              <DocumentApproval
                document={documentToApproveModal as IDocument}
                returnToDocumentCreatorAction={returnToDocumentCreator}
                setDocumentToApproveModal={setDocumentToApproveModal}
              />
            </AhadDialog>
          </Portal>
        )}
      </div>
    </div>
  );
};

export { DocumentEditor };
