import React from 'react';
import { RegularRuler } from '../../../../app/pages/templates/components/Ruler';
import { useLayout } from '../../core/LayoutProvider';
import { TitleWithBreadCrumb } from './TitleWithBreadCrumb';

const Toolbar = () => {
  const { config } = useLayout();

  switch (config.toolbar.layout) {
    case 'TitleWithBreadCrumb':
      return <TitleWithBreadCrumb />;
    case 'RegularRuler':
      return <RegularRuler />;
    default:
      return <TitleWithBreadCrumb />;
  }
};

export { Toolbar };
