import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../_metronic/constants/GeneralValidations';

const useConfigureProductSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const productInitialValues: { [key: string]: any } = useMemo(
    () => ({
      name_en: '',
      name_ar: '',
      description_en: '',
      description_ar: '',
      sku: '',
      price: '',
      image: '',
      product_type: [],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const productValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name_en: Yup.string()
          .required(required)
          .min(
            commonValidations.productNameMin,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.productNameMin,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_en', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        name_ar: Yup.string()
          .required(required)
          .min(
            commonValidations.productNameMin,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.productNameMin,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_ar', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_en: Yup.string()
          // .required(required)
          .nullable()
          .min(
            commonValidations.productNameMin,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.productNameMin,
            })
          )
          .max(
            commonValidations.productDescriptionMax,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.productDescriptionMax,
            })
          )
          .test('name_ar', $t("You can't enter special character at first or last!"), (val: any) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_ar: Yup.string()
          // .required(required)
          .nullable()
          .min(
            commonValidations.productNameMin,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.productNameMin,
            })
          )
          .max(
            commonValidations.productDescriptionMax,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.productDescriptionMax,
            })
          )
          .test('name_ar', $t("You can't enter special character at first or last!"), (val: any) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        price: Yup.number()
          .typeError($t('Only number are allowed!'))
          .required(required)
          .test(
            'len',
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.productPriceMaximum }
            ),
            (val: any) =>
              !val || (val && val.toString().length <= commonValidations.productPriceMaximum)
          ),
        sku: Yup.string()
          // .required(required)
          .min(
            commonValidations.productCodeMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.productCodeMinimum }
            )
          )
          .max(
            commonValidations.productCodeMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.productCodeMaximum }
            )
          )
          .test(
            'sku',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),

        product_type: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
      }),
    [formatMessage]
  );

  return {
    productInitialValues,
    productValidationSchema,
  };
};

export { useConfigureProductSchema };
