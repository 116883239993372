import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface ITableData {
  price: string | number;
  currency?: string;
  className?: string;
  to?: string;
  isLink?: boolean;
  isExternal?: boolean;
}
export class PriceViewer {
  constructor(private fieldData: ITableData) {}

  RedirectionController({ to, isExternal, price, className }: ITableData) {
    return isExternal ? (
      <a href={to} className={clsx('fw-bolder price-hover-primary d-block mb-1 fs-6', className)}>
        {price}
      </a>
    ) : (
      <Link
        to={{ pathname: to }}
        className={clsx('fw-bolder price-hover-primary d-block mb-1 fs-6', className)}
      >
        {price}
      </Link>
    );
  }

  renderItem() {
    const { isLink, to, price, className, currency } = this.fieldData;
    return (
      <>
        {isLink && to ? (
          <>{this.RedirectionController(this.fieldData)}</>
        ) : (
          <div className={clsx('fw-bolder price-hover-primary d-block mb-1 fs-6', className)}>
            {price} {currency ? ' ' + currency : ''}
          </div>
        )}
      </>
    );
  }
}
