import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

import { ID } from '../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { ClientsDataListModel } from '../types/clientsList';
import { user } from '../../../types/user';
import { getClientList, getSingleClientRequest } from './ClientsCrud';

export enum IClientsActionTypes {
  GET_CLIENTS = 'GET_CLIENTS',
  GET_SINGLE_CLIENT = 'GET_SINGLE_CLIENT',
  FULLFULL_Clients = 'FULLFULL_Clients',
  FULLFULL_SINGLE_USER = 'FULLFULL_SINGLE_USER',
  SET_DISPLAYED_TABS = 'SET_DISPLAYED_TABS',
  FULLFILL_DISPLAYED_TABS = 'FULLFILL_DISPLAYED_TABS',
}

const initialClientsState: IClientsState = {
  Clients: undefined,
  singleUser: undefined,
  displayedTabs: undefined,
};

export interface IClientsState {
  Clients?: ClientsDataListModel;
  singleUser?: user;
  displayedTabs?: [];
}

export const reducer = persistReducer(
  { storage, key: 'Clients', whitelist: [''] },
  (state: IClientsState = initialClientsState, action: ActionWithPayload<IClientsState>) => {
    switch (action.type) {
      case IClientsActionTypes.FULLFULL_Clients:
        return { ...state, Clients: action.payload?.Clients };
      case IClientsActionTypes.FULLFULL_SINGLE_USER:
        return { ...state, singleUser: action.payload?.singleUser };
      case IClientsActionTypes.FULLFILL_DISPLAYED_TABS:
        return { ...state, displayedTabs: action.payload?.displayedTabs };
      default:
        return state;
    }
  }
);

type CompanyClientsSearch = { companyId: ID | any; params?: any };
type SingleUserBasicData = { companyId: ID | any; userId?: ID | any };

export const actions = {
  getClients: ({ companyId, params }: CompanyClientsSearch) => ({
    type: IClientsActionTypes.GET_CLIENTS,
    payload: { companyId, params },
  }),
  fulfillClients: ({ Clients }: IClientsState) => ({
    type: IClientsActionTypes.FULLFULL_Clients,
    payload: { Clients },
  }),
  getSingleClient: ({ companyId, userId }: SingleUserBasicData) => ({
    type: IClientsActionTypes.GET_SINGLE_CLIENT,
    payload: { companyId, userId },
  }),
  fulfillSingleUser: ({ singleUser }: IClientsState) => ({
    type: IClientsActionTypes.FULLFULL_SINGLE_USER,
    payload: { singleUser },
  }),
  setDisplayedTabs: ({ displayedTabs }: any) => ({
    type: IClientsActionTypes.SET_DISPLAYED_TABS,
    payload: { displayedTabs },
  }),
  fullFillDisplayedTabs: ({ displayedTabs }: any) => ({
    type: IClientsActionTypes.FULLFILL_DISPLAYED_TABS,
    payload: { displayedTabs },
  }),
};

export function* getClients(action: ActionWithPayload<CompanyClientsSearch>) {
  try {
    // Getting Clients request
    const { data } = yield getClientList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing clients in store
    yield put(actions.fulfillClients({ Clients: data }));
  } catch (error) {
    //
  }
}

export function* getSingleClient(action: ActionWithPayload<SingleUserBasicData>) {
  try {
    // Getting Clients request
    const { data } = yield getSingleClientRequest({
      companyId: action.payload?.companyId,
      userId: action.payload?.userId,
    });
    // Storing clients in store
    yield put(actions.fulfillSingleUser({ singleUser: data }));
  } catch (error) {
    //
  }
}

export function* setDisplayedTabsSaga(action: ActionWithPayload<any>) {
  try {
    // log displayed tabs array
    // console.log(action.payload?.displayedTabs);
    // const { data } = yield getSingleClientRequest({
    //   companyId: action.payload?.companyId,
    //   userId: action.payload?.userId,
    // });
    // // Storing clients in store
    yield put(actions.fullFillDisplayedTabs({ displayedTabs: action.payload?.displayedTabs }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IClientsActionTypes.GET_CLIENTS, getClients);
  yield takeLatest(IClientsActionTypes.GET_SINGLE_CLIENT, getSingleClient);
  yield takeLatest(IClientsActionTypes.SET_DISPLAYED_TABS, setDisplayedTabsSaga);
}
