import { toast } from 'react-toastify';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { messages } from '../../../../../_metronic/i18n/messages/messages';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { CompanyModel } from '../../../companies/CompanyModel';
import { getAllCompanies, updateStatus } from './CompaniesCRUD';

export enum ICompaniesActionTypes {
  GET_COMPANIES = 'GET_COMPANIES',
  FILL_FULL_COMPANIES = 'FILL_FULL_COMPANIES',
  UPDATE_STATUS = 'UPDATE_STATUS',
  REPLACE_COMPANY = 'REPLACE_COMPANY',
}

const initialCompaniesState: ICompaniesState = {
  companies: undefined,
};

export interface ICompaniesState {
  companies?: any;
  company?: any;
}

export const reducer = persistReducer(
  { storage, key: 'SYS_companies', whitelist: [''] },
  (state: ICompaniesState = initialCompaniesState, action: ActionWithPayload<ICompaniesState>) => {
    switch (action.type) {
      case ICompaniesActionTypes.FILL_FULL_COMPANIES:
        return { ...state, companies: action.payload?.companies };
      case ICompaniesActionTypes.REPLACE_COMPANY:
        return {
          ...state,
          companies: {
            ...state.companies,
            data: [
              ...state.companies?.data.map((comp: CompanyModel) =>
                comp.id === action?.payload?.company?.id
                  ? { ...action.payload.company }
                  : { ...comp }
              ),
            ],
          },
        };
      default:
        return state;
    }
  }
);
type CompaniesSearch = { params?: any };
type CompanyStatus = { id: number; status: boolean; message?: string };

export const actions = {
  getCompanies: ({ params }: CompaniesSearch) => ({
    type: ICompaniesActionTypes.GET_COMPANIES,
    payload: { params },
  }),
  updateCompanyStatus: ({ id, status, message }: CompanyStatus) => ({
    type: ICompaniesActionTypes.UPDATE_STATUS,
    payload: { id, status, message },
  }),
  fulFillCompanies: ({ companies }: ICompaniesState) => ({
    type: ICompaniesActionTypes.FILL_FULL_COMPANIES,
    payload: { companies },
  }),
  replaceCompany: ({ company }: ICompaniesState) => ({
    type: ICompaniesActionTypes.REPLACE_COMPANY,
    payload: { company },
  }),
};

export function* getCompanies(action: ActionWithPayload<CompaniesSearch>) {
  try {
    const { data } = yield getAllCompanies({
      params: action.payload?.params,
    });
    yield put(actions.fulFillCompanies({ companies: data }));
  } catch (error) {
    //
  }
}

export function* updateCompanyStatus(action: ActionWithPayload<CompanyStatus>) {
  try {
    const { data, config } = yield updateStatus({
      id: action.payload?.id,
      status: action.payload?.status,
      message: action.payload?.message,
    });
    const companyData: CompanyModel = data;
    if (action.payload?.status) {
      toast.success(
        messages[config['headers']['X-Language']]['The company has been approved successfully']
      );
    }
    yield put(actions.replaceCompany({ company: companyData }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ICompaniesActionTypes.GET_COMPANIES, getCompanies);
  yield takeLatest(ICompaniesActionTypes.UPDATE_STATUS, updateCompanyStatus);
}
