import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { getAllTransactions } from './TransactionsCRUD';

export enum ITransactionsActionTypes {
  GET_TRANSACTION = 'GET_TRANSACTION',
  FILL_FULL_TRANSACTION = 'FILL_FULL_TRANSACTION',
}

const initialTransactionsState: ITransactionsState = {
  transactions: undefined,
};

export interface ITransactionsState {
  transactions?: {};
}

export const reducer = persistReducer(
  { storage, key: 'SYS_transactions', whitelist: [''] },
  (
    state: ITransactionsState = initialTransactionsState,
    action: ActionWithPayload<ITransactionsState>
  ) => {
    switch (action.type) {
      case ITransactionsActionTypes.FILL_FULL_TRANSACTION:
        return { ...state, transactions: action.payload?.transactions };
      default:
        return state;
    }
  }
);
type TransactionsSearch = { params?: any };

export const actions = {
  getTransactions: ({ params }: TransactionsSearch) => ({
    type: ITransactionsActionTypes.GET_TRANSACTION,
    payload: { params },
  }),
  fulFillTransactions: ({ transactions }: ITransactionsState) => ({
    type: ITransactionsActionTypes.FILL_FULL_TRANSACTION,
    payload: { transactions },
  }),
};

export function* getTransactions(action: ActionWithPayload<TransactionsSearch>) {
  try {
    const { data } = yield getAllTransactions({
      params: action.payload?.params,
    });
    yield put(actions.fulFillTransactions({ transactions: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ITransactionsActionTypes.GET_TRANSACTION, getTransactions);
}
