import { Meta } from '../../../../types/Meta';

export enum CodeRuleCategoryTypes {
  'Code expiration date' = 1,
  'Transaction amount' = 2,
  'Third party nationality' = 3,
  'Third party type' = 4,
  'Transaction date' = 5,
}

export enum CodeRuleOperatorTypes {
  'Not before than' = 1,
  'Not later than' = 2,
}
export enum CodeRuleColumnTypes {
  'Report Date' = 1,
}

export interface ICodeRule {
  can_delete: boolean;
  id: number;
  name: {
    ar: string;
    en: string;
  };
  error_message: {
    ar: string;
    en: string;
  };
  category: number;
  date_range: string[];
  amount_range: string;
}

export interface CodeRulesDataListModel extends Meta {
  data: Array<ICodeRule>;
}
