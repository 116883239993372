import { useMemo, useState } from 'react';
import { $t } from '../../../../../_metronic/i18n/formatMessage';
import { Input } from '../../../../shared/Input';
import { SeparatorLines } from '../../../../shared/Lines';
import { FormattedMessage, useIntl } from 'react-intl';
import { Label } from '../../../../shared/Label';
import Select from 'react-select';
import { countires } from '../../../../../_metronic/constants/countries';
import clsx from 'clsx';
import { CompanyTypes } from '../../../../types/CompanyTypes';
import { SelectOptions } from '../../../../types/SelectOptions';
import { FileUploader } from '../../../Companies/CompanyDetails/components/FileUploader';
import { useFormikContext } from 'formik';

export default function StepCompanyProfile() {
  /* ------------------------------- Local State ------------------------------ */
  const allowedFiles = [
    'application/pdf',
    'image/jpeg',
    'jpeg',
    'png',
    'image/png',
    'image/svg+xml',
  ];
  const [certificate_number_document, setCertificate_number_document] = useState<any>('');
  const [commercial_registration_document, setCommercial_registration_document] = useState<any>('');

  /* ---------------------------------- Hooks --------------------------------- */
  const { formatMessage, locale } = useIntl();
  const CompanyTypesOptions: SelectOptions = useMemo(
    () => [
      { value: CompanyTypes.Freelancer, label: formatMessage({ id: 'Freelancer' }) },
      { value: CompanyTypes.Corporate, label: formatMessage({ id: 'Corporate' }) },
    ],
    [formatMessage]
  );
  const formik = useFormikContext<any>();
  return (
    <div className='table-wrapper card'>
      <div className='table-wrapper bg-white pb-10'>
        <h3 className='m-2 mx-5'>{$t('Company Profile')}</h3>
        <SeparatorLines lines={2} />
        <div className='p-10 ' style={{ width: '100%' }}>
          <div className='row'>
            <div className='col-md-6'>
              <Input
                placeholder={formatMessage({ id: 'Company short name / code' })}
                autoComplete='off'
                labelId={formatMessage({ id: 'Company short name / code' })}
                formik={formik}
                name='slug'
                disabled
              />
            </div>
            <div className='col-md-6'>
              <Input
                placeholder={formatMessage({ id: 'Company Full Name' })}
                autoComplete='off'
                labelId={formatMessage({ id: 'Company Full Name' })}
                formik={formik}
                required
                name='name'
                setTouchedOnInput
                disabled
              />
            </div>
            {/*  --------------------- Country -----------------------  */}
            <div className='col-md-6 mb-3'>
              <Label msg='Country' htmlFor='country-select' isRequired={false} />
              <Select
                placeholder={$t('Country')}
                className='react-select smaller'
                id='country-select'
                value={formik.values.country}
                onChange={(value) => {
                  formik.setFieldValue('country', value);
                  formik.setFieldValue('city', null);
                  formik.setFieldValue('zip', null);
                }}
                options={countires?.map((country: any) => ({
                  value: country.iso,
                  label: country?.emoji + ' ' + country?.name?.[locale],
                }))}
                isDisabled
              />
            </div>
            {/*  --------------------- end Country -----------------------  */}
            {/* ------------------------------ city options ------------------------------ */}
            <div
              className='col-md-6'
              style={{
                zIndex: 99,
              }}
            >
              <Label msg='City' htmlFor='city-select' />
              <Select
                isDisabled
                placeholder={$t('City')}
                className='react-select smaller'
                onChange={(value) => formik.setFieldValue('city', value)}
                onBlur={() => formik.setFieldTouched('city', true)}
                value={formik.values.city}
                id='city-select'
                options={countires
                  .find((country: any) => country.iso === formik.values?.country?.value)
                  ?.cities?.map((city: any) => ({
                    value: city.iso.toString(),
                    label: city?.name?.[locale],
                  }))}
              />

              {/* error message  */}
              {formik.errors?.city && formik.touched.city && (
                <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                  <span role='alert' className='text-danger'>
                    <FormattedMessage id={'This field is required'} />
                  </span>
                </div>
              )}
            </div>
            {/* ---------------------------- end city options ---------------------------- */}
            <div className='col-md-6'>
              <Input
                setTouchedOnInput
                placeholder={$t('Address Information') + $t(' (AR)')}
                labelId={$t('Address Information') + $t(' (AR)')}
                formik={formik}
                name='address_ar'
                disabled
              />
            </div>
            <div className='col-md-6'>
              <Input
                setTouchedOnInput
                placeholder={$t('Address Information') + $t(' (EN)')}
                labelId={$t('Address Information') + $t(' (EN)')}
                formik={formik}
                name='address_en'
                disabled
              />
            </div>
            <div className='col-md-6'>
              <Input
                setTouchedOnInput
                placeholder={$t('Zip Code')}
                labelId={$t('Zip Code')}
                formik={formik}
                name='zip'
                disabled
              />
            </div>
            {formik?.values?.type?.value && (
              <div className='col-md-6'>
                <label
                  className={clsx('form-label fs-6 my-1 fw-bolder text-dark required')}
                  htmlFor={'company_type'}
                >
                  <FormattedMessage id='Company type' />
                </label>
                <Select
                  onChange={(value) => {
                    formik.setFieldValue('type', value);
                    if (value?.value === CompanyTypes.Corporate) {
                      formik.setFieldValue('certificate_number', '');
                    } else {
                      formik.setTouched({ ...formik.touched, website: false });
                      // formik.setFieldValue('tax_number', '');
                      formik.setFieldValue('commercial_registration', '');
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('type', true)}
                  options={CompanyTypesOptions}
                  value={formik.values.type}
                  className='react-select smaller'
                  id='company_type'
                  isDisabled
                />
              </div>
            )}
            <div className='col-md-6'>
              <label
                className={clsx('form-label fs-6 my-1 fw-bolder text-dark')} /* , {
                required: values?.type?.['value'] === CompanyTypes.Corporate,
              } */
                htmlFor={'website'}
              >
                <FormattedMessage id='Website' />
              </label>
              <div className='website-field d-flex' dir='ltr'>
                {!formik.values?.website?.startsWith('http:') &&
                  !formik.values?.website?.startsWith('www.') && (
                    <span className='text-lightGreyBlue mt-3 mx-1'>www.</span>
                  )}
                <div className='flex-fill' dir={locale === 'ar' ? 'rtl' : 'ltr'}>
                  <Input
                    id='website'
                    // required
                    placeholder={formatMessage({ id: 'Website' })}
                    autoComplete='off'
                    formik={formik}
                    type='website'
                    name='website'
                    setTouchedOnInput
                    disabled
                  />
                </div>
              </div>
            </div>

            {formik.values?.type?.value === CompanyTypes.Freelancer && (
              <>
                <div className='col-md-6'>
                  <Input
                    placeholder={formatMessage({ id: 'Freelancer Certificate Number' })}
                    labelId={formatMessage({ id: 'Freelancer Certificate Number' })}
                    formik={formik}
                    name='certificate_number'
                    setTouchedOnInput
                    disabled
                  />
                </div>
                <div className='col-md-6'>
                  <Input
                    placeholder={formatMessage({ id: 'National ID' })}
                    labelId={formatMessage({ id: 'National ID' })}
                    formik={formik}
                    name='national_id'
                    setTouchedOnInput
                    disabled
                  />
                </div>

                <div className='col-md-6'>
                  <FileUploader
                    setFile={setCertificate_number_document}
                    file={certificate_number_document}
                    maxSize={2048}
                    supportedFiles={allowedFiles}
                    typesOnly='pdfs-images'
                    disabled
                  />
                </div>
              </>
            )}
            {formik.values?.type?.value === CompanyTypes.Corporate && (
              <>
                <div className='col-md-6'>
                  <Input
                    placeholder={formatMessage({
                      id: 'Company Commercial Registration Number',
                    })}
                    autoComplete='off'
                    labelId={formatMessage({
                      id: 'Company Commercial Registration Number',
                    })}
                    formik={formik}
                    name='commercial_registration'
                    setTouchedOnInput
                    disabled
                  />
                </div>

                <div className='col-md-6 '>
                  <Input
                    placeholder={formatMessage({ id: 'Unified Number' })}
                    autoComplete='off'
                    labelId={formatMessage({ id: 'Unified Number' })}
                    formik={formik}
                    name='unified_number'
                    setTouchedOnInput
                    disabled
                  />
                </div>

                <div className='col-md-6'>
                  <FileUploader
                    setFile={setCommercial_registration_document}
                    file={commercial_registration_document}
                    maxSize={2048}
                    supportedFiles={allowedFiles}
                    disabled
                    typesOnly='pdfs-images'
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
