import { axiosInstance } from '../../../../../network/apis';

export function getAllCodeRules({ params }: { params?: any }) {
  return axiosInstance.get<any>(`/admin/rules`, {
    params,
  });
}
export function getCodeRuleData({ codeRuleId }: { codeRuleId?: any }) {
  return axiosInstance.get<any>(`/admin/rules/${codeRuleId}`);
}
