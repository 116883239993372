import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

// import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
// import { commonValidations } from '../../../_metronic/constants/GeneralValidations';

const useConfigureEditVatRateSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const editVatRateInitialValues: { [key: string]: any } = useMemo(
    () => ({
      vat_rate: [],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const editVatRateValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        vat_rate: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
      }),
    [formatMessage]
  );

  return {
    editVatRateInitialValues,
    editVatRateValidationSchema,
  };
};

export { useConfigureEditVatRateSchema };
