import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';
/* --------------------------------- common --------------------------------- */
import * as auth from '../../app/modules/auth';
import * as modal from '../../app/store/ModalRedux';
import * as table from '../../app/store/TableRedux';
import * as CurrentCompanyRedux from '../../app/store/CurrentCompanyRedux';
/* ------------------------------- end common ------------------------------- */
/* ---------------------------------- user ---------------------------------- */
import * as userRoles from '../../app/modules/roles/store/RolesRedux';
import * as userUsers from '../../app/modules/users/store/UsersRedux';
import * as userProducts from '../../app/modules/products/store/ProductsRedux';
// import * as userInvoices from '../../app/modules/invoices/store/InvoicesRedux';
import * as userClients from '../../app/modules/clients/store/ClientsRedux';
import * as userCompanies from '../../app/modules/companies/store/UserCompaniesRedux';
import * as userUserCompanies from '../../app/modules/user-companies/store/UserCompaniesRedux';
import * as userTemplates from '../../app/modules/templates/store/TemplatesRedux';
import * as userDocuments from '../../app/modules/documents/store/DocumentsRedux';
import * as userSales from '../../app/modules/sales/store/SalesRedux';
import * as userSalesInvoices from '../../app/modules/sales-invoices/store/SalesInvoicesRedux';
import * as userSalesLineItems from '../../app/modules/sales-line-items/store/SalesLineItemsRedux';
import * as userPurchases from '../../app/modules/purchases/store/PurchasesRedux';
import * as userPurchasesInvoices from '../../app/modules/purchases-invoices/store/PurchasesInvoicesRedux';
import * as userPurchasesLineItems from '../../app/modules/purchases-line-items/store/PurchasesLineItemsRedux';
import * as userLineItems from '../../app/modules/line-items/store/LineItemsRedux';
import * as userReports from '../../app/modules/reports/store/ReportsRedux';
import * as userTransactionsDetails from '../../app/modules/transactions/store/TransactionsDetailsRedux';
import * as userWithholdingReportsRedux from '../../app/modules/withholding-reports/store/WithholdingReportsRedux';
import * as userWithholdingTransactionsDetails from '../../app/modules/withholding-transactions/store/WithholdingTransactionsDetailsRedux';
import * as userInvoicesReports from '../../app/modules/invoices-reports/store/InvoicesReportsRedux';
import * as userInvoicesReportTransactionsDetails from '../../app/modules/invoices-reports-transactions/store/InvoicesReportTransactionsDetailsRedux';
import * as userInvoicesWithholdingReportsRedux from '../../app/modules/invoices-withholding-reports/store/InvoicesWithholdingReportsRedux';
import * as userInvoicesWithholdingReportsTransactionsDetails from '../../app/modules/invoices-withholding-reports-transactions/store/InvoicesWithholdingReportTransactionsDetailsRedux';

/* --------------------------------- groups --------------------------------- */
import * as userGroups from '../../app/modules/groups/users/store/UsersGroupRedux';

/* -------------------------------- end user -------------------------------- */
import * as userLog from '../../app/modules/log/store/LogsRedux';
/* ------------------------------ system admin ------------------------------ */
import * as SYS_companies from '../../app/modules/admins/companies/store/CompaniesRedux';
import * as SYS_companies_deletion_requests from '../../app/modules/admins/companies-deletion-requests/store/CompaniesDeletionRequestsRedux';
import * as SYS_subscription_plans from '../../app/modules/admins/subscription-plans/store/SubscriptionPlansRedux';
import * as SYS_users from '../../app/modules/admins/users/store/UsersRedux';
import * as SYS_vat_rates from '../../app/modules/admins/vat-rates/store/VatRatesRedux';
import * as SYS_code_rules from '../../app/modules/admins/code_rules/store/CodeRulesRedux';
import * as SYS_transactions from '../../app/modules/admins/transactions/store/TransactionsRedux';

/* ---------------------------- end system admin ---------------------------- */

export const rootReducerBeforeAsync = combineReducers({
  /* --------------------------------- common --------------------------------- */
  auth: auth.reducer,
  modal: modal.reducer,
  table: table.reducer,
  current: CurrentCompanyRedux.reducer,
  /* ------------------------------- end common ------------------------------- */
  /* ---------------------------------- user ---------------------------------- */
  roles: userRoles.reducer,
  USER_logs: userLog.reducer,
  USER_products: userProducts.reducer,
  // USER_invoices: userInvoices.reducer,
  USER_companies: userCompanies.reducer,
  USER_User_Companies: userUserCompanies.reducer,
  USER_users: userUsers.reducer,
  USER_clients: userClients.reducer,
  USER_templates: userTemplates.reducer,
  USER_documents: userDocuments.reducer,
  USER_sales: userSales.reducer,
  USER_sales_invoices: userSalesInvoices.reducer,
  USER_purchases_invoices: userPurchasesInvoices.reducer,
  USER_sales_line_items: userSalesLineItems.reducer,
  USER_purchases: userPurchases.reducer,
  USER_purchases_line_items: userPurchasesLineItems.reducer,
  USER_line_items: userLineItems.reducer,
  USER_reports: userReports.reducer,
  USER_transaction_details: userTransactionsDetails.reducer,
  USER_withholding_reports: userWithholdingReportsRedux.reducer,
  USER_withholding_transaction_details: userWithholdingTransactionsDetails.reducer,
  USER_invoices_reports: userInvoicesReports.reducer,
  USER_invoices_report_transaction_details: userInvoicesReportTransactionsDetails.reducer,
  USER_invoices_withholding_reports: userInvoicesWithholdingReportsRedux.reducer,
  USER_invoices_withholding_reports_transaction_details:
    userInvoicesWithholdingReportsTransactionsDetails.reducer,
  USER_USERS_GROUP: userGroups.reducer,
  /* -------------------------------- end user -------------------------------- */

  /* ------------------------------ system admin ------------------------------ */
  SYS_companies: SYS_companies.reducer,
  SYS_companies_deletion_requests: SYS_companies_deletion_requests.reducer,
  SYS_subscription_plans: SYS_subscription_plans.reducer,
  SYS_users: SYS_users.reducer,
  SYS_vat_rates: SYS_vat_rates.reducer,
  SYS_code_rules: SYS_code_rules.reducer,
  SYS_transactions: SYS_transactions.reducer,
  /* ---------------------------- end system admin ---------------------------- */
});

export const rootReducer = withReduxStateSync(rootReducerBeforeAsync);

export type RootState = ReturnType<typeof rootReducerBeforeAsync>;

export function* rootSaga() {
  yield all([
    /* --------------------------------- common --------------------------------- */
    auth.saga(),
    /* ------------------------------- end common ------------------------------- */
    /* ---------------------------------- user ---------------------------------- */
    userLog.saga(),
    userRoles.saga(),
    userCompanies.saga(),
    userUserCompanies.saga(),
    userUsers.saga(),
    userClients.saga(),
    userProducts.saga(),
    // userInvoices.saga(),
    userTemplates.saga(),
    userDocuments.saga(),
    userSales.saga(),
    userSalesInvoices.saga(),
    userSalesLineItems.saga(),
    userPurchases.saga(),
    userPurchasesInvoices.saga(),
    userPurchasesLineItems.saga(),
    userLineItems.saga(),
    userReports.saga(),
    userTransactionsDetails.saga(),
    userWithholdingReportsRedux.saga(),
    userWithholdingTransactionsDetails.saga(),
    userInvoicesReports.saga(),
    userInvoicesReportTransactionsDetails.saga(),
    userInvoicesWithholdingReportsRedux.saga(),
    userInvoicesWithholdingReportsTransactionsDetails.saga(),
    userGroups.saga(),
    /* -------------------------------- end user -------------------------------- */
    /* ------------------------------ system admin ------------------------------ */
    SYS_companies.saga(),
    SYS_companies_deletion_requests.saga(),
    SYS_subscription_plans.saga(),
    SYS_users.saga(),
    SYS_vat_rates.saga(),
    SYS_code_rules.saga(),
    SYS_transactions.saga(),
    /* ---------------------------- end system admin ---------------------------- */
  ]);
}
