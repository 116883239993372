import { FC } from 'react';
import { KTSVG } from '../../_metronic/helpers';

type Props = {
  onClick: Function;
};

export const CloseButtonRedShadowed: FC<Props> = ({ onClick }) => {
  return (
    <div
      className='badge badge-danger cursor-pointer rotatable-45 shadowed'
      data-testid='cancel-btn'
      onClick={() => onClick()}
    >
      <KTSVG
        path='/media/svg/icons/cancel.svg'
        className='svg-icon-2 d-inline-block position-relative'
        svgClassName='svg-white'
      />
    </div>
  );
};
