import { FormattedMessage, useIntl } from 'react-intl';
// import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import useWindowSize from '../../../hooks/useWindowSize';

export function FancySide() {
  const { width } = useWindowSize();
  const { locale } = useIntl();
  // const history = useHistory();

  return (
    <div
      className='flex-fill d-none  d-lg-inline brs-radius-25'
      data-testid='registration-design-right-side'
      style={{
        backgroundColor: '#f5f9ff',
        width: '50%',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        background: 'linear-gradient(145deg, #a474ff, #655ce2, #565bdb, #3a5ace ,#0f52ba 50%)',

        boxShadow: '0 20px 50px 0 rgba(38, 87, 196, 0.42)',
      }}
    >
      <div
        style={{
          borderRadius: 37,
          width: '70%',
          height: '60%',
          position: 'absolute',
          backgroundImage: 'linear-gradient(204deg, #3360c9 48%, #2657c5 114%)',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          border: '1px solid #3e62d0',
          background: 'linear-gradient(204deg, #3360c9, #3360c9)',
          top: '30%',
          left: '20%',
        }}
      >
        <div className='position-relative d-flex flex-column justify-content-around text-center p-8 pt-20'>
          <img
            src={toAbsoluteUrl('/media/illustrations/dozzy-1/5.png')}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate( -50%, -50% )',
              marginTop: width > 960 ? '-150px' : '-200px',
            }}
            alt=''
            height={300}
            width={300}
          />
          <div className='mt-20 text-white'>
            <h4 className='text-white fs-4'>
              <FormattedMessage id='Welcome to AHAD!' />
            </h4>
            <p
              className='text-bolder fs-4 p-4'
              style={{ textAlign: locale === 'ar' ? 'right' : 'left' }}
            >
              <FormattedMessage id='welcomeMessage' />{' '}
            </p>
          </div>
          {/* <span
            className='underline text-lightGreyBlue cursor-pointer'
            onClick={() => {
              history.push('/about');
            }}
          >
            <FormattedMessage id='Read more' />
          </span> */}
        </div>
      </div>
    </div>
  );
}
