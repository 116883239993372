import _ from 'lodash';
import { PermissionArray } from './types/permission';

export const handleSelection = (
  permission: number,
  permissionName: string,
  parent: string,
  allPermissions: { title: string; permissions: PermissionArray }[],
  party: PermissionArray,
  selected: Array<number>
) => {
  let currentSelection = _.xor([permission], selected);
  const viewElement: any = party.find((permission) => permission.key.split('-')[0] === 'view');

  if (currentSelection.some((item: number) => party.map((p) => p.id).includes(item))) {
    currentSelection.push(viewElement?.id);
  }

  return currentSelection;
};
