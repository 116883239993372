export const commonValidations = {
  nameMinimum: 2,
  nameMaximum: 25,
  productCodeMinimum: 2,
  productCodeMaximum: 25,
  productPriceMaximum: 25,
  invoiceMinimum: 3,
  invoiceMaximum: 25,
  textMaximum: 255,
  textareaMinimum: 2,
  textareaMaximum: 300,
  passwordMinimum: 6,
  passwordMaximum: 255,
  descriptionMaximum: 2000,
  productNameMin: 3,
  productDescriptionMax: 255,
  taxNumberCharacters: 15,
  InvoiceNumberCharacters: 15,
  nationalIdCharacters: 10,
  nationalId: 10,
  commertialRegistrationCharacters: 10,
  unifiedNumberCharacters: 10,
  certificateNumber: 8,
  amountMaxmum: 15,
  reporterNotesMinimum: 2,
  reporterNotesMaximum: 2500,
};
