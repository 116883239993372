/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom'; //, useLocation
import { serialize } from 'object-to-formdata';
import { useFormik } from 'formik';
// import { Alert } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
// import OtpInput from 'react-otp-input';
// import Cookies from 'js-cookie';
// import moment from 'moment';
// import IntlTelInput from 'react-intl-tel-input';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import ar from 'react-phone-input-2/lang/ar.json';

import 'react-intl-tel-input/dist/main.css';

import * as AuthRedux from '../../modules/auth/store/AuthRedux';

import { RootState } from '../../../setup/redux/Store';
// import { PrivateRoutes } from '../../../_metronic/constants/ROUTES';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { SeparatorLines } from '../../shared/Lines';
// import { CompanyModel } from '../companies/CompanyModel';

// import { userStatusColorsMap } from '../../../_metronic/constants/colors';
import { Input } from '../../shared/Input';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
// import { formatDate } from '../../shared/tables/classes/DateViewer';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { axiosInstance } from '../../../network/apis';
import { useUpdateProfileSchema } from './schema/UpdateProfileSchema';
import { UserModel } from '../auth/models/UserModel';
import { Label } from '../../shared/Label';
import { allowedImagesTypes } from '../../../_metronic/constants/general';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
// import { Portal } from '../../../_metronic/partials';
// import AhadDialog from '../../../setup/shared/AhadDialog';
// import { delayToCookies } from '../../../utils/cookies';
// import { UserSignature } from './UserSignature';
// import { AccountHeader } from '../accounts/AccountHeader';

type Props = {
  mode: 'edit' | 'view';
};

const imageMaxSize = 2048;
export const UserProfile: FC<Props> = ({ mode }) => {
  // const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { initialValues, validationSchema } = useUpdateProfileSchema();
  const photoRef = useRef<HTMLInputElement | null>(null);
  const { formatMessage, locale } = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserModel | any>({});
  const [photo, setPhoto] = useState<string | Blob>('');
  // const [openOtp, setOpenOtp] = useState(false);
  // const [diff, setdiff] = useState(0);
  // const [sendingOtp, setSendingOtp] = useState(false);

  const storedUser: { user: UserModel; token: string } = useSelector<RootState>(
    (state) => state.auth,
    shallowEqual
  ) as { user: UserModel; token: string };

  // const getTime = () => setdiff(moment(Cookies.get('resendAfter')).diff(moment()));

  // useEffect(() => {
  //   const i = setInterval(getTime, 1000);
  //   return () => clearInterval(i);
  // }, [sendingOtp]);

  // useEffect(() => {
  //   if (!moment(Cookies.get('resendAfter')).isAfter(moment())) {
  //     Cookies.remove('resendAfter');
  //   }
  // }, [diff]);
  /* ----------------------- Getting roles for dropdown ----------------------- */
  const requestProfile = async () =>
    await axiosInstance.get('/profile').then((res) => {
      // dispatch(AuthRedux.actions.setUser(res.data));
      // console.log(res.data);

      setUser(res.data);
    });

  useEffect(() => {
    requestProfile();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const payload = {
        ...values,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
      };
      const formData = serialize(payload);
      formData.append('photo', photo);
      formData.append('_method', 'PUT');
      try {
        await axiosInstance.post(`/profile`, formData);
        toast.success(formatMessage({ id: 'The changes have been successfully made!' }));
        // if (values.phone !== user.phone && values?.phone?.length) {
        //   setOtp('');
        //   setOpenOtp(true);
        // }
        // await requestProfile();
        // reset form
        formik.resetForm();
        history.goBack();
        // history.push(`${PrivateRoutes.UserProfileView}`);
      } catch (error: any) {
        DisplayErrors(error);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  const { errors, touched, values, setFieldValue } = formik;

  useEffect(() => {
    if (user?.id) {
      setPhoto(user?.image);
      setFieldValue('first_name', user?.first_name);
      setFieldValue('last_name', user?.last_name);
      setFieldValue('phone', user?.phone);
      setFieldValue('iso', user?.iso || 'sa');
      setFieldValue('phoneValidity', user?.phone?.length > 0);
      setFieldValue('email', user?.email);

      dispatch(
        AuthRedux.actions.fulfillUser({
          ...storedUser,
          user: {
            ...storedUser.user,
            name: user.first_name.trim() + ' ' + user.last_name.trim(),
            first_name: user.first_name,
            last_name: user.last_name,
            image: user.image,
          },
        })
      );
    }
  }, [user]);

  // const companyDetails: CompanyModel = useSelector<RootState>(
  //   (state) => state?.USER_companies.companyDetails,
  //   shallowEqual
  // ) as CompanyModel;
  const shouldAsk = () => Object.keys(touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <div>
      {shouldAsk() && <Prompt message={$t('Are you sure you want to ignore the changes?')} />}

      {/* {openOtp && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId='OTP'
            closeCallBack={() => {
              setOtp('');
              setOpenOtp(false);
            }}
          >
            <div>
              <Alert variant='danger'>
                <FormattedMessage id='You can only request another OTP once every 60 seconds.' />
              </Alert>

              <div dir='ltr' id='otp'>
                <OtpInput
                  value={otp}
                  onChange={(otp: any) => {
                    setOtp(otp);
                  }}
                  numInputs={6}
                  separator={<span>-</span>}
                />
              </div>
              <button
                disabled={otp.toString().length < 6 || sendingOtp}
                onClick={async () => {
                  try {
                    setSendingOtp(true);
                    await axiosInstance.post('/verify-otp', { otp });
                    setOpenOtp(false);
                    toast.success(
                      formatMessage({ id: 'Your phone number has been successfully verified!' })
                    );
                    await axiosInstance.get('/profile').then((res) => setUser(res.data));
                    formik.resetForm();
                  } catch (err) {
                    DisplayErrors(err);
                  }
                  setSendingOtp(false);
                }}
                className='btn btn-primary mt-5'
              >
                <FormattedMessage id='Send' />
              </button>
              <button
                disabled={diff > 5}
                onClick={async () => {
                  try {
                    axiosInstance.post('/resend-otp');
                    delayToCookies(60, 'resendAfter');
                    setOtp('');
                  } catch (err) {
                    //
                  }
                }}
                className='btn btn-secondary mt-5'
              >
                <FormattedMessage id='resend_btn' />{' '}
                {Math.ceil(diff / 1000) > 1 && (
                  <>
                    {Math.ceil(diff / 1000)}
                    <FormattedMessage id='seconds' />
                  </>
                )}
              </button>
            </div>
          </AhadDialog>
        </Portal>
      )} */}

      {/* <div className='d-flex flex-row mb-4 gap-15'>
        <p>{user?.name}</p>
        <div className='pl-10 pr-10'>
          <span className='menu-bullet mx-1'>
            <span className='bullet bullet-dot'></span>
          </span>
          {$t('Status')}
          <span className={`badge ${userStatusColorsMap.get(user?.active ? 1 : 0)?.badge} mx-2`}>
            {$t(userStatusColorsMap.get(user?.active ? 1 : 0)?.labelId || 'missing')}
          </span>
        </div>
      </div> */}

      {mode === 'view' && (
        <div className='d-flex flex-row-reverse mb-10'>
          <button
            className='btn btn-primary btn-sm btn-primary-shadow'
            title={$t('Edit user profile')}
            onClick={() => history.push('/user-profile/edit')}
          >
            <i className='fa fa-edit cursor-pointer'></i>
            {$t('Edit user profile')}
          </button>
        </div>
      )}
      <div className='card mb-5 mb-xl-10 m-auto' style={{ maxWidth: '93vw' }}>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex px-5'>
            <div className='px-10 py-3 fs-6'>{$t('Personal Information')}</div>
          </div>
          <SeparatorLines lines={2} />
          <div className='d-flex px-5'>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='p-10'>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='symbol symbol-circle avatar-border symbol-60px symbol-md-100px mx-3 mb-10'>
                    {typeof photo === 'string' && (
                      <img
                        src={photo.length <= 0 ? toAbsoluteUrl('/media/avatars/blank.png') : photo}
                      />
                    )}
                    {typeof photo !== 'string' && allowedImagesTypes.includes(photo.type) && (
                      <img src={URL.createObjectURL(photo)} alt='FIle' width='100%' />
                    )}

                    {mode === 'edit' && (
                      <div className='dropdown position-relative' style={{ left: 20, top: -20 }}>
                        <button
                          className='btn'
                          type='button'
                          id={'dropdownMenuButton1'}
                          data-bs-toggle='dropdown'
                          auto-close='true'
                          data-bs-auto-close='true'
                          aria-expanded='false'
                        >
                          <i className='far fa-image fs-2' />
                        </button>
                        <ul className='dropdown-menu' aria-labelledby={'dropdownMenuButton1'}>
                          <li>
                            <a
                              className='dropdown-item'
                              href='#'
                              onClick={() => photoRef?.current?.click()}
                            >
                              <FormattedMessage id='Change Picture' />
                            </a>
                          </li>
                          {photo && (
                            <li onClick={() => setPhoto('')}>
                              <a className='dropdown-item' href='#'>
                                <FormattedMessage id='Delete Current Picture' />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}

                    <input
                      type='file'
                      style={{ visibility: 'hidden' }}
                      ref={photoRef}
                      onChange={(e: any) => {
                        const file = e?.target?.files[0];
                        if (!allowedImagesTypes.includes(file?.type)) {
                          toast.error(
                            formatMessage({
                              id: 'You can only upload JPG, SVG,PNG, JPEG files format',
                            })
                          );
                          return;
                        }
                        if (file?.size / 1024 > imageMaxSize) {
                          toast.error(
                            formatMessage(
                              { id: 'The maximum file size is {maxSize}MB' },
                              { maxSize: `${imageMaxSize / 1024}` }
                            )
                          );
                        }
                        setPhoto(e?.target?.files?.[0] || '');
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Input
                      disabled={mode === 'view'}
                      placeholder={$t('First Name')}
                      autoComplete='off'
                      labelId='First Name'
                      formik={formik}
                      name='first_name'
                      setTouchedOnInput
                    />
                  </div>
                  <div className='col-md-6'>
                    <Input
                      disabled={mode === 'view'}
                      placeholder={$t('Last Name')}
                      autoComplete='off'
                      labelId='Last Name'
                      formik={formik}
                      name='last_name'
                      setTouchedOnInput
                    />
                  </div>
                  <div className='col-md-6'>
                    <Input
                      disabled
                      setTouchedOnInput
                      placeholder={$t('Email')}
                      autoComplete='off'
                      labelId='Email'
                      formik={formik}
                      name='email'
                      type='email'
                    />
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Label msg='Phone Number' htmlFor='recipient_type' />
                      <div className='' dir='ltr'>
                        <PhoneInput
                          localization={locale === 'ar' ? ar : undefined}
                          inputClass=' b-radius-16 basic-input'
                          inputStyle={{ width: '100%' }}
                          country={'sa'}
                          placeholder={formatMessage({ id: 'Phone Number' })}
                          value={values?.phone || ''}
                          disabled={mode === 'view'}
                          // separateDialCode
                          // inputClassName='form-control form-control-lg  basic-input'
                          onChange={(phone, data: any) => {
                            const valid = isValidPhoneNumber(
                              phone.slice(data.dialCode.length),
                              data.countryCode
                                .split('')
                                .map((l: string) => l.toUpperCase())
                                .join('')
                            );
                            formik.setFieldValue(`phone`, phone);
                            formik.setTouched({ ...formik.touched, phone: true });
                            formik.setFieldValue(`phoneValidity`, valid);
                          }}
                          onBlur={() => formik.setTouched({ ...formik.touched, phone: true })}
                        />
                      </div>
                      {errors?.phone && touched?.phone && (
                        <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                          <span role='alert' className='text-danger'>
                            {errors?.phone}
                          </span>
                        </div>
                      )}
                      {touched?.phone &&
                        !values.phoneValidity &&
                        !errors?.phone &&
                        values?.phone?.length > 0 && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id='Invalid phone' />
                            </span>
                          </div>
                        )}{' '}
                    </div>
                  </div>
                </div>
                {mode === 'edit' && (
                  <>
                    <button
                      type='submit'
                      className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
                    >
                      {!loading && $t('save')}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow m-5'
                      onClick={() => history.push(`/user-profile`)}
                    >
                      {$t('Cancel')}
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {}
      {/* <div className='mt-10 m-auto' style={{ maxWidth: '93vw' }}>
        {user?.signatures && (
          <div>
            <UserSignature
              mode={mode}
              signatures={user?.signatures}
              requestProfile={requestProfile}
            />
          </div>
        )}
      </div> */}
    </div>
  );
};

const UserProfileEdit: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'User Profile' })}</PageTitle>
      <UserProfile mode='edit' />
    </div>
  );
};

const UserProfileView: FC = () => {
  const { formatMessage } = useIntl();
  // const history = useHistory();
  // const location = useLocation();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle
        // breadcrumbs={[{ title: 'User Profile', path: PrivateRoutes.UsersEdit, isActive: false }]}
        breadcrumbs={[]}
      >
        {formatMessage({ id: 'User Profile' })}
      </PageTitle>
      {/* <AccountHeader /> */}
      <UserProfile mode='view' />
    </div>
  );
};

export { UserProfileEdit, UserProfileView };
