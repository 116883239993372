import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';

import { KTSVG } from '../../../_metronic/helpers';
import { RootState } from '../../../setup/redux/Store';
import { TableData, TableSelection } from '../../shared/tables/classes'; // TableActions,
import { SerachFilters } from '../../shared/SearchFilters';
import * as TableRedux from '../../store/TableRedux';

import * as UserCompaniesRedux from './store/UserCompaniesRedux';
import { IUserCompanyModel, UserCompaniesDataListModel } from './types/UserCompanyModel';
import { CommonTable2 } from '../../shared/tables/Table2';
import { useLang } from '../../../_metronic/i18n/Metronici18n';

const TABLE_NAME = 'user-companies-list';

const headerItems = [
  // { id: 'ID', maxWidth: 150, width: 150 },
  { id: 'Company short name / code', maxWidth: 240, width: 230 },
  { id: 'Company name', maxWidth: 240, width: 230 },
  { id: 'Job Title', maxWidth: 240, width: 230 },
  // { id: 'Role', maxWidth: 150, width: 150 },
  { id: 'Roles', maxWidth: 250, width: 250 },
  { id: 'Company profile status', maxWidth: 150, width: 150 },
  { id: 'Company type', maxWidth: 150, width: 150 },
  { id: 'The last VAT processing period', maxWidth: 250, width: 250 },
  { id: 'The most recent withholding tax processing period', maxWidth: 250, width: 250 },
];

export const UserCompanies: FC = () => {
  return (
    <div>
      <UserCompaniesTable />
    </div>
  );
};

export const UserCompaniesTable: FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const userCompaniesList: UserCompaniesDataListModel = useSelector<RootState>((state) => {
    return state.USER_User_Companies.userCompanies;
  }, shallowEqual) as UserCompaniesDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(UserCompaniesRedux.actions.fullFillUserCompanies({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];

    dispatch(
      UserCompaniesRedux.actions.getUserCompanies({
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (table) {
      RequestData();
    }
  }, [table?.page, table?.pagination]);

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };
  const locale = useLang();

  const rowItems = userCompaniesList?.data.map((userCompany: IUserCompanyModel, index: any) => {
    return [
      new TableSelection({
        display: true,
        id: userCompany.id,
        tableName: TABLE_NAME,
      }),
      new TableData({
        text: `${userCompany.short_name}`,
      }),
      new TableData({
        text: userCompany?.name,
      }),
      new TableData({
        text: userCompany?.position,
      }),
      // new TableData({
      //   text: `${formatMessage({
      //     id: userCompany?.role,
      //   })}`,
      // }),
      new TableData({
        text: userCompany?.roles.map((role: any) => role.name).join(', '),
      }),
      new TableData({
        text: `${formatMessage({
          id: userCompany?.status,
        })}`,
      }),
      new TableData({
        text: `${formatMessage({
          id: userCompany?.type,
        })}`,
      }),
      new TableData({
        text: userCompany?.latest_approved_vat_report?.date_period[locale]!,
      }),
      new TableData({
        text: userCompany?.latest_approved_withholding_report?.date_period[locale]!,
      }),

      // new TableActions(<div className='d-flex flex-row'></div>),
    ];
  });

  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              // rightHandSide={[]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}

        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          initPerPage={20}
          allDisplayedIds={
            userCompaniesList?.data?.map((userCompany: IUserCompanyModel) => userCompany.id) || []
          }
          meta={userCompaniesList}
          headerItems={headerItems}
          rowItems={rowItems}
          headerWithCheckbox={true}
        />
      </div>
    </>
  );
};

const UserCompaniesWrapper: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle>{formatMessage({ id: 'User companies' })}</PageTitle>

      <UserCompanies />
    </div>
  );
};

export { UserCompaniesWrapper };
