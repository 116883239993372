import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { RencentAction } from '../types';
import { ActionCard } from './ActionCard';

type Props = {
  recentActions: RencentAction[];
};

export const RecentActions: FC<Props> = ({ recentActions }) => {
  return (
    <section>
      <div className='flex-grow-1 card-p pb-0'>
        <div className='d-flex flex-stack flex-wrap mb-3'>
          <div className='me-2'>
            <h4 className='text-dark text-hover-primary fw-bolder fs-5'>
              <FormattedMessage id='Recent actions' />
            </h4>
            <div className='text-muted fs-7 fw-bold'>
              <FormattedMessage id='Recent docs that need actions' />
            </div>
          </div>
          <div className='fw-bolder fs-3 text-primary'>
            <a
              href='/documents'
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            >
              <span className='mx-2'>
                <FormattedMessage id='Show all' />
              </span>
              <i className='fas fa-arrow-right fs-9'></i>
            </a>
          </div>
        </div>
        {recentActions.map((action) => (
          <ActionCard action={action} key={action.id} />
        ))}
      </div>
    </section>
  );
};
