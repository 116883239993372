import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { getAllUsers } from './UsersCRUD';

export enum IUsersActionTypes {
  GET_USER = 'GET_USER',
  FILL_FULL_USER = 'FILL_FULL_USER',
}

const initialUsersState: IUsersState = {
  users: undefined,
};

export interface IUsersState {
  users?: {};
}

export const reducer = persistReducer(
  { storage, key: 'SYS_users', whitelist: [''] },
  (state: IUsersState = initialUsersState, action: ActionWithPayload<IUsersState>) => {
    switch (action.type) {
      case IUsersActionTypes.FILL_FULL_USER:
        return { ...state, users: action.payload?.users };
      default:
        return state;
    }
  }
);
type UsersSearch = { params?: any };

export const actions = {
  getUsers: ({ params }: UsersSearch) => ({
    type: IUsersActionTypes.GET_USER,
    payload: { params },
  }),
  fulFillUsers: ({ users }: IUsersState) => ({
    type: IUsersActionTypes.FILL_FULL_USER,
    payload: { users },
  }),
};

export function* getUsers(action: ActionWithPayload<UsersSearch>) {
  try {
    const { data } = yield getAllUsers({
      params: action.payload?.params,
    });
    yield put(actions.fulFillUsers({ users: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IUsersActionTypes.GET_USER, getUsers);
}
