import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { Button } from '../../../shared/Button';
import { Label } from '../../../shared/Label';
import { colourStyles } from '../helpers/colorStyle';

function dataURLtoBlob(dataURL: any) {
  let array, binary, i, len;
  binary = atob(dataURL.split(',')[1]);
  array = [];
  i = 0;
  len = binary.length;
  while (i < len) {
    array.push(binary.charCodeAt(i));
    i++;
  }
  return new Blob([new Uint8Array(array)], {
    type: 'image/png',
  });
}

const fontSizes = [32, 34, 36, 38];
const fontFamilies = ['Shadows Into Light', 'Rock Salt', 'Cedarville Cursive'];

type Props = {
  uploadSignature: Function;
  name: string;
  setName: Function;
  isOneTimeSignature: boolean;
  isLoading: boolean;
};
export const TypingSignature: FC<Props> = ({
  uploadSignature,
  name,
  setName,
  isOneTimeSignature,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  const fontColorsOptions = [
    { value: '#253858', label: $t('Signature'), color: '#253858' },
    { value: '#666666', label: $t('Signature'), color: '#666666' },
    { value: '#0098dd', label: $t('Signature'), color: '#0098dd' },
    { value: '#5243AA', label: $t('Signature'), color: '#5243AA' },
    { value: '#00875A', label: $t('Signature'), color: '#00875A' },
  ];
  const [text, setText] = useState('');
  const [font, setFont] = useState('Shadows Into Light');
  const [fontSize, setFontSize] = useState(32);
  const [fontColor, setFontColor] = useState(fontColorsOptions[0]);
  const [disableInput, setDisableInput] = useState(false);

  const saveSignatureToCanvas = async () => {
    let canvas = document.createElement('canvas');
    canvas.width = 350;
    canvas.height = 100;

    let ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    ctx.fillStyle = `${fontColor.value}`;
    ctx.textBaseline = 'middle';
    ctx.font = `${fontSize}px '${font}'`;
    ctx.fillText(`${text}`, 50, 50);

    let img = document.createElement('img');
    img.src = canvas.toDataURL('image/png');
    const file = dataURLtoBlob(canvas.toDataURL());
    uploadSignature(file);
  };

  return (
    <div
      className='signature-upload-border  p-4 mt-4 d-flex flex-column justify-content-center align-items-center'
      style={{ maxWidth: '350px' }}
    >
      {!isOneTimeSignature && (
        <input
          type='text'
          className='form-control form-control-lightSkyBlue fs-4 py-1  text-gray-700 placeholder-gray-400 mw-500px my-5'
          value={name}
          onInput={(e: any) => setName(e.target.value)}
          placeholder={formatMessage({ id: 'Signature Title' })}
        />
      )}
      <div className='d-flex flex-row w-300px gap-2'>
        <div className='w-100px'>
          <Label msg='Size' htmlFor='signers' />
          <Select
            placeholder={$t('Size')}
            className='react-select smaller'
            id='size'
            defaultValue={{ value: fontSize, label: `${fontSize}` }}
            onChange={(value: any) => setFontSize(value.value)}
            options={fontSizes?.map((num: number) => ({ value: num, label: `${num}` }))}
          />
        </div>

        <div className='w-200px'>
          <Label msg='Font' htmlFor='signers' />
          <Select
            placeholder={$t('Font')}
            className='react-select smaller '
            id='signer-selection'
            defaultValue={{ value: font, label: font }}
            onChange={(value: any) => setFont(value.value)}
            options={fontFamilies?.map((font: string) => ({ value: font, label: font }))}
          />
        </div>
      </div>
      <div className='w-200px'>
        <Label msg='Font Color' htmlFor='signers' />
        <Select
          placeholder={$t('Font Color')}
          className='react-select smaller'
          id='signer-selection'
          defaultValue={fontColor}
          onChange={(value: any) => setFontColor(value)}
          options={fontColorsOptions}
          styles={colourStyles}
        />
      </div>

      <input
        type='text'
        className='form-control placeholder-gray-400 my-5'
        disabled={disableInput}
        onChange={(e) => setText(e.target.value)}
        value={text}
        style={{
          fontSize: fontSize,
          color: fontColor.value,
          fontFamily: font,
          outline: 'none',
          textAlign: 'center',
        }}
        name="Supervisor's Signature"
      />

      <Button
        fa='signature'
        nameId='Save'
        btnType='outlined'
        disabled={disableInput || isLoading}
        onClick={() => {
          setDisableInput(true);
          saveSignatureToCanvas();
        }}
      />
    </div>
  );
};
