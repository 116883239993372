/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom'; //, useLocation
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

// import { Alert } from 'react-bootstrap-v5';
import AsyncSelect from 'react-select/async';
import { components, OptionProps } from 'react-select';
import Select from 'react-select';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';
// import * as ClientsRedux from '../../modules/clients/store/ClientsRedux';

import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CompanyModel } from '../companies/CompanyModel';

import { SeparatorLines } from '../../shared/Lines';
import { Input } from '../../shared/Input';
import { Label } from '../../shared/Label';
import { TextArea } from '../../shared/textarea';
// import { FileUploader } from '../../pages/Companies/CompanyDetails/components/FileUploader';

import { axiosInstance } from '../../../network/apis';
import { useQuery } from 'react-query';
import { createOptions } from '../../../utils/createOptions';

import { ErrorMessage } from '../../shared/ErrorMessage';

import { ThirdPartyTypes } from '../../types/CompanyTypes';
// import { VatRateTypeMap, TransactionTypeMap } from '../../../_metronic/constants/general'; //DocumentDirection,
// import { toSelectOption } from '../../../_metronic/helpers/functions/Select';

import { useConfigurePurchasesReturnSchema } from './useConfigurePurchasesReturnSchema';
// import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { TableData } from '../../shared/tables/classes'; // DateViewer,
import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';

import { initialConfigPayload } from './helpers/purchasesConfigHelpers';
// import { ClientsDataListModel } from '../clients/types/clientsList'; //, IClient
import { LOCALE } from '../../../_metronic/helpers/typescript';
import * as PurchasesRedux from './store/PurchasesRedux';
import { IPurchase, VatRatesTypes, PurchasesDataListModel } from './types/PurchasesModel';

import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { debounce } from '../../../utils/debounce';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

export const ConfigNewPurchasesReturnWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { purchasesReturnInitialValues, purchasesReturnValidationSchema } =
    useConfigurePurchasesReturnSchema();
  const dispatch = useDispatch();
  // const location = useLocation();
  const history = useHistory();
  const { userCan } = usePermissions();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  // const [attachment, setAttachment] = useState<any>('');
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
  const [showWarningsConfirmationModal, setShowWarningsConfirmationModal] = useState(false);
  const [warningsConfirmation, setWarningsConfirmation] = useState<any>([]);
  const [approveWarnings, setApproveWarnings] = useState<any>(null);

  const [foundReferenceInvoiceDisableField, setFoundReferenceInvoiceDisableField] = useState(false);
  const [foundReferenceInvoiceIsLoading, setFoundReferenceInvoiceIsLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  const [editItemId, setEditItemId] = useState<any>(null);

  /* ----------------------------- Getting purchases ----------------------------- */
  const getCurrentPurchases = async (purchaseId: any) => {
    try {
      dispatch(
        PurchasesRedux.actions.getCurrentPurchase({
          companyId: companyDetails.id,
          purchaseId: purchaseId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };
  /* ----------------------------- Getting Reference purchases ----------------------------- */
  const getReferencePurchases = async (purchaseId: any) => {
    try {
      dispatch(
        PurchasesRedux.actions.getCurrentReferencePurchase({
          companyId: companyDetails.id,
          purchaseId: purchaseId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };

  const createPurchases = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    payload['description'] = { ar: '', en: '' };
    payload['description']['ar'] = values.description_ar ? values.description_ar.trim() : '';
    payload['description']['en'] = values.description_en ? values.description_en.trim() : '';
    payload['client_id'] = values.clients[0]?.value;
    delete payload['clients'];
    payload['vat_rate_id'] = values.vat_rate[0]?.value;
    delete payload['vat_rate'];
    payload['amount'] = Number(values.amount);
    if (mode === 'edit' && approveWarnings) {
      payload['approve'] = approveWarnings;
    }

    if (!values.vat_rate[0]?.is_return_code) {
      payload['invoice_reference'] = '';
    }
    // payload['quantity'] = Number(values.quantity);
    payload['is_return'] = 1;

    const formData = serialize(payload, { indices: true });
    // if (attachment) {
    //   formData.append('attachment', attachment);
    // }
    try {
      if (mode === 'create') {
        await axiosInstance.post(`${companyDetails.id}/purchases`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'Purchases has been created successfully!' }));
      } else if (mode === 'edit') {
        formData.append('_method', 'PUT');
        await axiosInstance.post(`${companyDetails.id}/purchases/${purchasesItem.id}`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'Purchases has been updated successfully!' }));
      }
      history.push(`/c/${companyDetails.slug}/tax-module/purchases`);
    } catch (err: any) {
      if (
        err &&
        err.response.status === 422 &&
        Object.keys(err.response.data.errors).includes('warning_confirmations')
      ) {
        setLoading(false);
        setShowWarningsConfirmationModal(true);
        setWarningsConfirmation(err.response.data.errors.warning_confirmations);
      } else if (err.response.data.errors.length === 0) {
        setApproveWarnings(1);
        formik.submitForm();
      } else {
        DisplayErrors(err);
      }
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: purchasesReturnInitialValues,
    validationSchema: purchasesReturnValidationSchema,
    onSubmit: (values, { resetForm }) => createPurchases(values, resetForm),
  });
  const { setFieldValue, setFieldTouched } = formik; //,values

  const resetFormExceptRefInvoice = () => {
    setFieldValue('invoice_date', '');
    setFieldValue('min_accepted_date', '');
    setFieldValue('description_ar', '');
    setFieldValue('description_en', '');
    setFieldValue('amount', '');
    setFieldValue('reference_amount', '');
    setFieldValue('clients', []);
    setFieldValue('vat_rate', []);
    setFieldValue('reference_date', '');
    setFieldValue('reference_id', '');
    formik.setTouched({});
    setFoundReferenceInvoiceIsLoading(false);
  };

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  // const clients: ClientsDataListModel = useSelector<RootState>(
  //   (state) => state.USER_clients.Clients,
  //   shallowEqual
  // ) as ClientsDataListModel;

  // const getClients = () =>
  //   dispatch(
  //     ClientsRedux.actions.getClients({
  //       companyId: companyDetails.id,
  //       params: {
  //         per_page: 0,
  //         third_party_type: `${ThirdPartyTypes.Vendor},${ThirdPartyTypes.Both}`,
  //       },
  //     })
  //   );

  // const clientsOptions = useMemo(
  //   () =>
  //     clients?.data?.map((client) => ({
  //       label: client?.name?.[locale as LOCALE],
  //       value: client.id,
  //     })),
  //   [clients]
  // );

  const loadClientsOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails.id}/clients`, {
          params: {
            name: inputValue,
            per_page: 0,
            third_party_type: `${ThirdPartyTypes.Vendor},${ThirdPartyTypes.Both}`,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((client: any) => ({
              ...client,
              label: client?.name?.[locale as LOCALE],
              value: client.id,
            }))
          );
        });
    }, 1000),
    []
  );

  const { isLoading: vatRateLoading, data: vatRateList } = useQuery(
    'VatRate',
    () =>
      axiosInstance(`/${companyDetails.id}/vat_rates`, {
        params: {
          type: `${VatRatesTypes['Expenditure return']}`,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );
  const vatRateOptions = useMemo(() => createOptions(vatRateList), [vatRateList]);

  const purchasesItem: IPurchase = useSelector<RootState>((state) => {
    return state.USER_purchases.currentPurchase;
  }, shallowEqual) as IPurchase;

  const referencePurchasesItem: IPurchase = useSelector<RootState>((state) => {
    return state.USER_purchases.currentReferencePurchase;
  }, shallowEqual) as IPurchase;

  const purchasesList: PurchasesDataListModel = useSelector<RootState>((state) => {
    return state.USER_purchases.purchases;
  }, shallowEqual) as PurchasesDataListModel;

  /* ----------------------- end getting data from store ---------------------- */
  // const accountTypesOptions = useMemo(() => {
  //   return Array.from(VatRateTypeMap.values())
  //     .flat()
  //     .map((option) => toSelectOption(option, locale));
  // }, [locale]);

  // const transactionTypesOptions = useMemo(() => {
  //   return Array.from(TransactionTypeMap.values())
  //     .flat()
  //     .map((option) => toSelectOption(option, locale));
  // }, [locale]);

  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    // if (!clients?.data) {
    //   getClients();
    // }
    if (params?.purchaseId && mode !== 'create') {
      getCurrentPurchases(params?.purchaseId);
    }
  }, [vatRateOptions, params?.purchaseId]);

  useEffect(() => {
    // getClients();

    if (
      purchasesItem?.id &&
      !purchasesItem?.is_return &&
      +purchasesItem?.remaining_amount > 0 &&
      mode === 'create'
    ) {
      setFieldValue('invoice_date', purchasesItem.invoice_date);
      // setFieldValue(
      //   'description_ar',
      //   purchasesItem.description?.ar ? purchasesItem.description?.ar : ''
      // );
      // setFieldValue(
      //   'description_en',
      //   purchasesItem.description?.en ? purchasesItem.description?.en : ''
      // );
      setFieldValue('amount', purchasesItem.remaining_amount);
      setFieldValue('reference_amount', purchasesItem.remaining_amount);
      setFieldValue('reference_date', purchasesItem.invoice_date);
      setFieldValue('reference_id', purchasesItem.id);

      setFieldValue(
        'clients',
        purchasesItem.client
          ? [
              // ...clientsOptions,
              {
                label: purchasesItem.client?.name,
                value: purchasesItem.client?.id,
              },
            ]?.filter((client) => client.value === purchasesItem.client.id)
          : []
      );

      setFieldValue(
        'vat_rate',
        purchasesItem.vat_rate
          ? vatRateOptions?.filter(
              (vat_rate) => vat_rate.label === purchasesItem.vat_rate.name + '_A'
            )
          : []
      );
    } else if (purchasesItem?.id && mode !== 'create' && !formik.touched.invoice_reference) {
      setFieldValue('invoice_reference', purchasesItem?.invoice_reference);
      setFieldValue('invoice_number', purchasesItem?.invoice_number);
      setFieldValue('invoice_date', purchasesItem?.invoice_date);
      setFieldValue('min_accepted_date', purchasesItem?.min_accepted_date);
      setFieldValue(
        'description_ar',
        purchasesItem.description?.ar ? purchasesItem.description?.ar : ''
      );
      setFieldValue(
        'description_en',
        purchasesItem.description?.en ? purchasesItem.description?.en : ''
      );
      setFieldValue('amount', +purchasesItem?.amount);
      setFieldValue('reference_amount', purchasesItem.remaining_amount + +purchasesItem?.amount);
      setFieldValue('reference_date', purchasesItem.min_accepted_date);
      setFieldValue('reference_id', purchasesItem.reference_id);

      setFieldValue(
        'clients',
        purchasesItem.client
          ? [
              // ...clientsOptions,
              {
                label: purchasesItem.client?.name,
                value: purchasesItem.client?.id,
              },
            ]?.filter((client) => client.value === purchasesItem.client.id)
          : []
      );

      setFieldValue(
        'vat_rate',
        purchasesItem.vat_rate
          ? vatRateOptions?.filter((vat_rate) => vat_rate.label === purchasesItem.vat_rate.name)
          : []
      );
    } else {
      resetFormExceptRefInvoice();
    }
  }, [purchasesItem?.id]);

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    if (
      formik.values.invoice_reference !== '' &&
      formik.values.invoice_reference?.length >= 3 &&
      mode !== 'view'
    ) {
      setFoundReferenceInvoiceIsLoading(true);
      const timer = setTimeout(() => {
        dispatch(
          PurchasesRedux.actions.getPurchases({
            companyId: companyDetails.id,
            params: {
              invoice_number: formik.values.invoice_reference,
              page: 1,
              per_page: 5,
            },
          })
        );
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [formik.values.invoice_reference]);

  useEffect(() => {
    setFoundReferenceInvoiceDisableField(false);
    if (purchasesList && purchasesList.data && formik.values.invoice_reference) {
      if (mode === 'create') {
        if (purchasesList.data[0]?.id) {
          getCurrentPurchases(purchasesList.data[0]?.id);
          setFoundReferenceInvoiceIsLoading(false);
          setFoundReferenceInvoiceDisableField(true);
        } else {
          resetFormExceptRefInvoice();
          dispatch(PurchasesRedux.actions.fullFillCurrentPurchase({}));
          dispatch(PurchasesRedux.actions.fullFillCurrentReferencePurchase({}));
        }
      } else if (mode === 'edit') {
        if (purchasesList.data[0]?.id) {
          getReferencePurchases(purchasesList.data[0]?.id);
          setFoundReferenceInvoiceIsLoading(false);
          setFoundReferenceInvoiceDisableField(true);
          formik.setFieldTouched('invoice_reference', true);
        } else {
          resetFormExceptRefInvoice();
          // dispatch(PurchasesRedux.actions.fullFillCurrentPurchase({}));
          dispatch(PurchasesRedux.actions.fullFillCurrentReferencePurchase({}));
        }
      }
    }
  }, [purchasesList]);

  useEffect(() => {
    if (!purchasesItem?.invoice_reference && mode === 'edit')
      setFieldValue('invoice_reference', purchasesItem?.invoice_reference);

    if (purchasesItem && formik.values.invoice_reference) {
      if (mode === 'edit') {
        //edit same invoice
        if (
          purchasesItem?.invoice_reference === formik.values.invoice_reference &&
          !formik.errors.invoice_reference &&
          formik.touched.invoice_reference
        ) {
          setFoundReferenceInvoiceIsLoading(false);
          // setFieldValue('invoice_reference', purchasesItem?.invoice_reference);
          setFieldValue('invoice_number', purchasesItem?.invoice_number);
          setFieldValue('invoice_date', purchasesItem?.invoice_date);
          setFieldValue('min_accepted_date', purchasesItem.min_accepted_date);
          setFieldValue(
            'description_ar',
            purchasesItem.description?.ar ? purchasesItem.description?.ar : ''
          );
          setFieldValue(
            'description_en',
            purchasesItem.description?.en ? purchasesItem.description?.en : ''
          );
          setFieldValue('amount', +purchasesItem?.amount);
          setFieldValue(
            'reference_amount',
            +referencePurchasesItem?.remaining_amount + +purchasesItem?.amount
          );
          setFieldValue('reference_date', purchasesItem.min_accepted_date);
          setFieldValue('reference_id', purchasesItem.reference_id);

          setFieldValue(
            'clients',
            purchasesItem.client
              ? [
                  // ...clientsOptions,
                  {
                    label: purchasesItem.client?.name,
                    value: purchasesItem.client?.id,
                  },
                ]?.filter((client) => client.value === purchasesItem.client.id)
              : []
          );

          setFieldValue(
            'vat_rate',
            purchasesItem.vat_rate
              ? vatRateOptions?.filter((vat_rate) => vat_rate.label === purchasesItem.vat_rate.name)
              : []
          );
        } else if (
          referencePurchasesItem?.id &&
          purchasesItem?.invoice_reference !== formik.values.invoice_reference &&
          !formik.errors.invoice_reference &&
          !referencePurchasesItem?.is_return &&
          formik.touched.invoice_reference
        ) {
          // edit different invoice
          // setFieldValue('invoice_reference', referencePurchasesItem?.invoice_reference);
          setFieldValue('invoice_date', referencePurchasesItem?.invoice_date);
          setFieldValue('min_accepted_date', referencePurchasesItem?.invoice_date);
          // setFieldValue(
          //   'description_ar',
          //   referencePurchasesItem.description?.ar ? referencePurchasesItem.description?.ar : ''
          // );
          // setFieldValue(
          //   'description_en',
          //   referencePurchasesItem.description?.en ? referencePurchasesItem.description?.en : ''
          // );
          setFieldValue('amount', referencePurchasesItem.remaining_amount);
          setFieldValue('reference_amount', referencePurchasesItem.remaining_amount);
          setFieldValue('reference_date', referencePurchasesItem.invoice_date);
          setFieldValue('reference_id', referencePurchasesItem.id);

          setFieldValue(
            'clients',
            referencePurchasesItem.client
              ? [
                  // ...clientsOptions,
                  {
                    label: referencePurchasesItem.client?.name,
                    value: referencePurchasesItem.client?.id,
                  },
                ]?.filter((client) => client.value === referencePurchasesItem.client.id)
              : []
          );

          setFieldValue(
            'vat_rate',
            referencePurchasesItem.vat_rate
              ? vatRateOptions?.filter(
                  (vat_rate) => vat_rate.label === referencePurchasesItem.vat_rate.name + '_A'
                )
              : []
          );
        } else if (
          referencePurchasesItem?.id &&
          purchasesItem?.invoice_reference !== formik.values.invoice_reference &&
          !formik.errors.invoice_reference &&
          referencePurchasesItem?.is_return &&
          formik.touched.invoice_reference
        ) {
          resetFormExceptRefInvoice();
        }
      }
    }
  }, [referencePurchasesItem?.id, formik.values.invoice_reference]);

  /* --------------------------------- Leaving -------------------------------- */

  useEffect(() => {
    if (mode === 'create') {
      formik.setFieldValue(`invoice_date`, '');
      formik.setFieldValue(`reference_amount`, '');
    }
    return () => {
      setDisable();
      setShowExitPrompt(false);
      dispatch(PurchasesRedux.actions.fullFillPurchases({}));
      dispatch(PurchasesRedux.actions.fullFillCurrentPurchase({}));
      dispatch(PurchasesRedux.actions.fullFillCurrentReferencePurchase({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && $t('Create purchase return')}
            {mode === 'view' && $t('Preview purchase return')}
            {mode === 'edit' && $t('Edit purchase return')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='row  mx-3'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex justify-content-start'>
              {/* Badges */}
              {mode !== 'create' &&
                Object.entries(purchasesItem ? purchasesItem.errors_with_color : {}).map(
                  ([key, value]) => {
                    const badge = new TableData({
                      text: ``,
                      errors_with_color: value,
                    });
                    return <div className='m-2'>{badge.renderItem()}</div>;
                  }
                )}
            </div>
            {mode === 'view' && userCan('update-sale') && (
              <div
                className='d-flex justify-content-end'
                style={{ textAlign: 'end', alignSelf: 'center' }}
              >
                <EditItemWithModal
                  id={purchasesItem?.id}
                  title={$t('Edit')}
                  isButton
                  buttonMessageId={'Edit purchases'}
                  messageId={
                    purchasesItem?.can_update
                      ? purchasesItem?.can_delete
                        ? 'This purchase return already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed the editing process?'
                        : ''
                      : "This purchase return is included in an approved report, and can't be edited"
                  }
                  editURI={`/c/${companyDetails.slug}/tax-module/purchasesreturn/${purchasesItem?.id}/edit`}
                  showButtonGroup={purchasesItem?.can_update}
                  editItemId={editItemId}
                  setEditItemId={setEditItemId}
                />
                {/* <button
                  className='btn btn-primary btn-sm btn-primary-shadow'
                  title={formatMessage({ id: 'Edit purchases' })}
                  onClick={() => history.push(location.pathname + '/edit')}
                >
                  <i className='fa fa-edit cursor-pointer'></i>
                  {formatMessage({ id: 'Edit purchases' })}
                </button> */}
              </div>
            )}
          </div>
        </div>
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_purchases' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  {/*  ------------------------ purchases reference invoice -----------------------  */}
                  <div className='col-md-6'>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Reference invoice number',
                      })}
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Reference invoice number',
                      })}
                      name={'invoice_reference'}
                      type={'text'}
                      formik={formik}
                      disabled={mode === 'view'}
                    />
                    {foundReferenceInvoiceIsLoading && !formik.errors.invoice_reference && (
                      <div className='d-flex justify-content-center'>
                        <div className='spinner-border text-primary' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    )}
                    {!foundReferenceInvoiceIsLoading && !formik.errors.invoice_reference && (
                      <div className='fv-plugins-message-container mt-2 mx-3'>
                        {!foundReferenceInvoiceDisableField && (
                          <span role='alert' className='text-info'>
                            {formatMessage({
                              id: 'No invoice found with this reference number',
                            })}
                          </span>
                        )}
                        {foundReferenceInvoiceDisableField &&
                          !purchasesItem?.is_return &&
                          formik.values.vat_rate.length === 0 &&
                          +purchasesItem?.remaining_amount === 0 && (
                            <span role='alert' className='text-danger'>
                              {formatMessage({
                                id: 'You can’t add a return invoice for this reference invoice number, because the full amount has been returned before.',
                              })}
                            </span>
                          )}
                        {foundReferenceInvoiceDisableField &&
                          !purchasesItem?.is_return &&
                          formik.values.vat_rate.length === 0 &&
                          +purchasesItem?.remaining_amount > 0 && (
                            <span role='alert' className='text-danger'>
                              {formatMessage({
                                id: 'Return process is not available for this invoice, please check the original invoice.',
                              })}
                            </span>
                          )}
                        {foundReferenceInvoiceDisableField &&
                          purchasesItem?.is_return &&
                          referencePurchasesItem?.is_return && (
                            <span role='alert' className='text-danger'>
                              {formatMessage({
                                id: 'You can’t add a returned invoice number to a return invoice.',
                              })}
                            </span>
                          )}
                      </div>
                    )}
                  </div>
                  {/*  ------------------------end purchases reference invoice -----------------------  */}
                  {/*  ------------------------ purchases Invoice number -----------------------  */}
                  <div className='col-md-6'>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Invoice number',
                      })}
                      required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Invoice number',
                      })}
                      name={'invoice_number'}
                      type={'text'}
                      formik={formik}
                      disabled={mode === 'view'}
                    />
                  </div>
                  {/*  --------------------- end purchases Invoice number-----------------------  */}
                  {/*  ---------------------  Tax rate select-----------------------  */}
                  <div className='col-6'>
                    <Label msg='Tax rate' htmlFor='vat_rate' isRequired />
                    <Select
                      onChange={(value) => setFieldValue('vat_rate', [value])}
                      onBlur={() => formik.setFieldTouched('vat_rate', true)}
                      options={vatRateOptions?.filter(
                        (option) => formik.values.vat_rate[0]?.value !== option.value
                      )}
                      value={formik.values.vat_rate}
                      isLoading={vatRateLoading}
                      placeholder={$t('Tax rate')}
                      // isMulti
                      isDisabled={mode === 'view' || foundReferenceInvoiceDisableField}
                      className='react-select smaller'
                      id='vat_rate'
                    />
                    <ErrorMessage name='vat_rate' formik={formik} />
                  </div>
                  {/*  --------------------- end Tax rate select-----------------------  */}
                  {/*  ------------------------ purchases Invoice date -----------------------  */}
                  <div className='col-md-6'>
                    <label
                      className={'form-label fs-6 my-1 fw-bolder text-dark required'}
                      htmlFor='invoice_date_id'
                    >
                      {formatMessage({
                        id: 'Invoice date',
                      })}
                    </label>
                    <DatePicker
                      isClearable={mode !== 'view'}
                      selected={
                        formik.values.invoice_date ? new Date(formik.values.invoice_date) : null
                      }
                      dateFormat='dd/MM/yyyy'
                      placeholderText={formatMessage({
                        id: 'Invoice date',
                      })}
                      id='invoice_date_id'
                      className='form-control form-control-md b-radius-16 basic-input '
                      onChange={(date: any) =>
                        formik.setFieldValue(`invoice_date`, date ? date : '')
                      }
                      minDate={
                        foundReferenceInvoiceDisableField
                          ? new Date(formik.values.min_accepted_date)
                          : null
                      }
                      maxDate={new Date()}
                      onChangeRaw={(e) => {
                        setFieldTouched(`invoice_date`, true, true);
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete='off'
                      disabled={mode === 'view'}
                    />
                    <ErrorMessage name='invoice_date' formik={formik} />
                  </div>
                  {/*  --------------------- end purchases Invoice date-----------------------  */}

                  {/*  ---------------------  purchases Clients select-----------------------  */}
                  {/* <div className='col-6'>
                    <Label msg='Vendor' htmlFor='client' isRequired />
                    <Select
                      onChange={(value) => setFieldValue('clients', [value])}
                      onBlur={() => formik.setFieldTouched('clients', true)}
                      onFocus={() => getClients()}
                      options={clientsOptions?.filter((option) => {
                        return formik.values.clients[0]?.value !== option.value;
                      })}
                      value={formik.values.clients}
                      // isLoading={creatorsLoading}
                      placeholder={$t('Vendor')}
                      // isMulti
                      isDisabled={mode === 'view' || foundReferenceInvoiceDisableField}
                      className='react-select smaller'
                      id='client'
                    />
                    <ErrorMessage name='clients' formik={formik} />
                  </div> */}

                  <div className='col-6'>
                    <Label msg='Vendor' htmlFor='clients' isRequired />
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadClientsOptions}
                      onChange={(value) => formik.setFieldValue(`clients`, value ? [value] : [])}
                      onBlur={() => formik.setFieldTouched(`clients`, true)}
                      value={formik.values?.clients}
                      defaultOptions
                      placeholder={formatMessage({
                        id: 'Vendor',
                      })}
                      isClearable
                      isDisabled={mode === 'view'}
                      className='react-select smaller'
                      menuPlacement='auto'
                      noOptionsMessage={() => {
                        return (
                          <span role='alert' className='text-danger'>
                            {formatMessage({
                              id: 'No options found',
                            })}
                          </span>
                        );
                      }}
                      loadingMessage={() => (
                        <div className='d-flex justify-content-center'>
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      )}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 2,
                          paddingBottom: 2,
                          zIndex: 3,
                        }),
                        menuPortal: (base: any) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        noOptionsMessage: (base: any) => ({
                          ...base,
                          color: 'red',
                        }),
                      }}
                      components={{
                        Option: ({ children, ...props }: OptionProps<any, false>) => {
                          return (
                            <components.Option {...props}>
                              <div className='d-flex justify-content-between'>
                                <span>{props.data?.label}</span>
                              </div>
                            </components.Option>
                          );
                        },
                      }}
                      id={`clients`}
                    />
                    <ErrorMessage name='clients' formik={formik} />
                  </div>

                  {/*  --------------------- end purchases Clients select-----------------------  */}

                  {/*  ------------------------ purchases Amount -----------------------  */}
                  <div className='col-md-6'>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Amount',
                      })}
                      required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Amount',
                      })}
                      name={'amount'}
                      type={'text'}
                      formik={formik}
                      disabled={mode === 'view'}
                    />
                    {foundReferenceInvoiceDisableField &&
                      !foundReferenceInvoiceIsLoading &&
                      (mode === 'create'
                        ? !purchasesItem?.is_return && +purchasesItem?.remaining_amount !== 0
                        : (!purchasesItem?.is_return && +purchasesItem?.remaining_amount !== 0) ||
                          (!referencePurchasesItem?.is_return &&
                            (+referencePurchasesItem?.remaining_amount !== 0 ||
                              +purchasesItem?.amount !== 0))) && (
                        <div className='fv-plugins-message-container mt-2 mx-3'>
                          <span role='alert' className='text-info'>
                            {formatMessage({
                              id: 'Amount must be less than or equal to: ',
                            })}
                            {purchasesItem?.reference_id !== referencePurchasesItem?.id &&
                              formik.values.reference_amount}
                            {purchasesItem?.reference_id === referencePurchasesItem?.id &&
                              +referencePurchasesItem?.remaining_amount + +purchasesItem?.amount}
                          </span>
                        </div>
                      )}
                  </div>
                  {/*  ------------------------end purchases Amount -----------------------  */}

                  {/*  ------------------------ purchases description en/ar -----------------------  */}
                  {locales.map((locale) => (
                    <div className='col-md-6'>
                      <Label
                        msg={formatMessage({
                          id: 'Purchase description in ' + locale.toUpperCase(),
                        })}
                        htmlFor={'Purchase description in ' + locale.toUpperCase()}
                        // isRequired
                      />

                      <TextArea
                        placeholder={formatMessage({
                          id: 'Purchase description in ' + locale.toUpperCase(),
                        })}
                        autoComplete='off'
                        // labelId={formatMessage({
                        //   id: 'Purchase description in ' + locale.toUpperCase(),
                        // })}
                        name={'description_' + locale}
                        type='text'
                        formik={formik}
                        dir={locale === 'ar' ? 'rtl' : 'ltr'}
                        rows='6'
                        id={'Purchase description in ' + locale.toUpperCase()}
                        disabled={mode === 'view'}
                      />
                    </div>
                  ))}
                  {/*  --------------------- end purchases description en/ar-----------------------  */}
                  {/*  ------------------------ purchases Quantity -----------------------  */}
                  {/* <div className='col-md-6'> */}
                  {/* <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Quantity',
                      })}
                      required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Quantity',
                      })}
                      name={'quantity'}
                      type={'text'}
                      formik={formik}
                      disabled={mode === 'view'}
                    /> */}
                  {/* </div> */}
                  {/*  ------------------------end purchases Quantity -----------------------  */}
                  {/*  ------------------------purchases attachment -----------------------  */}
                  {/* <div className='col-md-6' style={{ maxWidth: '40%' }}>
                    <FileUploader
                      setFile={setAttachment}
                      file={attachment}
                      disabled={mode === 'view'}
                      maxSize={2 * 1024}
                      supportedFiles={[
                        'application/pdf',
                        'image/jpeg',
                        'jpeg',
                        'image/png',
                        'png',
                        'image/jpg',
                        'jpg',
                        'image/svg+xml',
                        'svg+xml',
                        'image/svg',
                        'svg',
                      ]}
                      typesOnly='pdfs-images'
                    />
                  </div> */}
                  {/*  ------------------------end purchases attachment -----------------------  */}
                </div>

                {/* Save and Cancel Buttons */}
                {mode !== 'view' && (
                  <>
                    {/* <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.push(`/c/${companyDetails.slug}/tax-module/purchases`);
                      }}
                    /> */}

                    <>
                      <div className='col-md-8 my-3'>
                        <div className='row'>
                          <div className='col-md-4'>
                            <button
                              className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                              disabled={formik.isSubmitting}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (mode === 'create') {
                                  formik.submitForm();
                                } else {
                                  formik.isValid && setShowConfirmEditModal(true);
                                }
                              }}
                            >
                              {!loading && (
                                <span className='indicator-label'>
                                  <FormattedMessage id={'Save'} />
                                </span>
                              )}
                              {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  <FormattedMessage id='Please wait...' />
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className='col-md-4'>
                            <button
                              onClick={() => {
                                history.push(`/c/${companyDetails.slug}/tax-module/purchases`);
                              }}
                              type='button'
                              id='kt_login_signup_form_cancel_button'
                              className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                            >
                              <FormattedMessage id='Cancel' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
        {/*  ---------------------Confirm edit Modal-----------------------  */}

        {showConfirmEditModal && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={
                () => setShowConfirmEditModal(false)

                // history.push(location.pathname.replace('/invite-users', '/users'))
              }
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                  {formatMessage({
                    id: 'Are you sure you want to edit this purchase return invoice?',
                  })}
                </div>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowConfirmEditModal(false);
                          formik.submitForm();
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Save'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowConfirmEditModal(false);
                          history.push(`/c/${companyDetails.slug}/tax-module/purchases`);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}
        {/*  ---------------------end Confirm edit Modal-----------------------  */}
        {/*  ---------------------warnings confirmation Modal-----------------------  */}

        {showWarningsConfirmationModal && warningsConfirmation && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={
                () => setShowWarningsConfirmationModal(false)

                // history.push(location.pathname.replace('/invite-users', '/users'))
              }
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                  {warningsConfirmation.length === 1 && <span>{warningsConfirmation[0]}</span>}
                  {warningsConfirmation.length > 1 && (
                    <ol>
                      {warningsConfirmation.map((warning: any, index: any) => (
                        <li key={index}>{warning}</li>
                      ))}
                    </ol>
                  )}
                </div>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setApproveWarnings(1);
                          setShowWarningsConfirmationModal(false);
                          formik.submitForm();
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Save'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          // setApproveWarnings(0);
                          setShowWarningsConfirmationModal(false);
                          // formik.submitForm();
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}
        {/*  ---------------------end warnings confirmation Modal-----------------------  */}
      </div>
    </div>
  );
};

const ConfigNewPurchasesReturnWrapperCreate: FC = () => {
  usePermissions(['create-sale']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Purchases' })}</PageTitle>
      {/* <div> Coming Soon </div> */}
      <ConfigNewPurchasesReturnWrapper mode='create' />
    </div>
  );
};

const ConfigNewPurchasesReturnWrapperEdit: FC = () => {
  usePermissions(['update-sale']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Purchases' })}</PageTitle>
      <ConfigNewPurchasesReturnWrapper mode='edit' />
    </div>
  );
};

const ConfigNewPurchasesReturnWrapperView: FC = () => {
  usePermissions(['view-sale']);
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Purchases' })}</PageTitle>
      <ConfigNewPurchasesReturnWrapper mode='view' />
    </div>
  );
};
export {
  ConfigNewPurchasesReturnWrapperCreate,
  ConfigNewPurchasesReturnWrapperEdit,
  ConfigNewPurchasesReturnWrapperView,
};
