
export const bootstrapBreakPoints = new Map<number, string>([
  [0, 'xs'],
  [576, 'sm'],
  [768, 'md'],
  [992, 'lg'],
  [1200, 'xl'],
  [1400, 'xxl'],
])

export const bootstrapBreakInitialsValues = new Map<string, number>([
  ['xs', 0],
  ['sm', 576],
  ['md', 768],
  ['lg', 992],
  ['xl', 1200],
  ['xxl', 1400],
])
