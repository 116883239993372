import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

// import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
// import { commonValidations } from '../../../_metronic/constants/GeneralValidations';

const useConfigureEditDateSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const editDateInitialValues: { [key: string]: any } = useMemo(
    () => ({
      payment_date: '',
      // vat_rate: [],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const editDateValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        payment_date: Yup.string().required(required),
      }),
    [formatMessage]
  );

  return {
    editDateInitialValues,
    editDateValidationSchema,
  };
};

export { useConfigureEditDateSchema };
