import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
// import { adminSideMenuItems, sideMenuMainItems } from '../../../constants/AsideMenuMainItems';
import { ICompany, UserType } from '../../../../app/types/companies';
import { usePermissions } from '../../../../app/hooks/usePermissions';
import { CurrentCompanyUser } from '../../../../app/types/CompanyTypes';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup/redux/Store';
import useAsideMenuMainItems from '../../../constants/useAsideMenuMainItems';

const userRedirection = {
  ADMIN: (to: string, companySlug: string) => to,
  USER: (to: string, companySlug: string) => `/c/${companySlug}${to}`,
};

export function AsideMenuMain({ user }: { user: UserType }) {
  const currentCompanyUser: CurrentCompanyUser = useSelector<RootState>(
    (state) => state.current.current,
    shallowEqual
  ) as CurrentCompanyUser;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const { userCan } = usePermissions();
  const { formatMessage } = useIntl();
  const params: any = useParams();
  const companySlug = params.companySlug || currentCompanyUser?.company?.slug;

  const { sideMenuMainItems, adminSideMenuItems, reRenderSideMenuMainItems } =
    useAsideMenuMainItems();

  const sidemenus = {
    ADMIN: adminSideMenuItems,
    USER: sideMenuMainItems,
  };

  const menu = sidemenus[user];

  useEffect(() => {
    reRenderSideMenuMainItems();
  }, [JSON.stringify(!companyDetails?.settings?.vat_registered)]);

  return (
    <>
      {menu
        .filter((item) => item?.minimumPermissions?.some((doThis) => userCan(doThis)))
        .map((asideItem, index) =>
          !asideItem.children?.length ? (
            <AsideMenuItem
              key={JSON.stringify(index)}
              to={userRedirection[user](asideItem.to, companySlug)}
              icon={asideItem.icon}
              title={formatMessage({ id: asideItem.titleId })}
              fontIcon={asideItem.fontIcon}
              linkId={asideItem.titleId}
            />
          ) : (
            <AsideMenuItemWithSub
              key={JSON.stringify(index)}
              to={userRedirection[user](asideItem.to, companySlug)}
              icon={asideItem.icon}
              title={formatMessage({ id: asideItem.titleId })}
              fontIcon={asideItem.fontIcon}
              linkId={asideItem.titleId}
            >
              {asideItem.children
                ?.filter(
                  (item) =>
                    item?.minimumPermissions?.some((doThis) => userCan(doThis)) && !item?.disabled
                )
                .map((subItem, index) => (
                  <AsideMenuItem
                    key={JSON.stringify(index)}
                    to={userRedirection[user](subItem.to, companySlug)}
                    title={formatMessage({ id: subItem.titleId })}
                    hasBullet={subItem.hasBullet}
                    linkId={subItem.titleId}
                  />
                ))}
            </AsideMenuItemWithSub>
          )
        )}
    </>
  );
}
