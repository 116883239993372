import { Formik } from 'formik';
import { serialize } from 'object-to-formdata';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../../network/apis';
import { RootState } from '../../../../setup/redux/Store';
import AhadDialog from '../../../../setup/shared/AhadDialog';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { KTSVG } from '../../../../_metronic/helpers';
import { $t, FormatMessage } from '../../../../_metronic/i18n/formatMessage';
import { Portal } from '../../../../_metronic/partials';
import SaveCancelBtnsGroup from '../../SaveCancelBtnsGroup';

type Props = {
  title?: string;
  messageId?: string;
  message?: string;
  dialogTitleId?: string;
  deleteItemId?: any;
  id?: string | number | null;
  setDeleteItemId?: Function;
  cancelId?: string;
  onCancel?: Function;
  deleteId?: string;
  onDelete?: Function;
  afterSucessRequest?: Function;
  moduleId?: string;
  alertMessage?: string;
  deleteURI?: string;
  successMessage?: string;
  payload?: number[] | number | null;
  isButton?: boolean;
  isCompanyButton?: boolean;
  showButtonGroup?: boolean;
  TABLE_NAME?: string;
  methodType?: 'post' | 'delete';
};

export const DeleteItem: FC<Props> = ({
  title,
  messageId,
  message,
  dialogTitleId,
  deleteItemId,
  id,
  setDeleteItemId,
  moduleId,
  alertMessage,
  deleteURI,
  successMessage,
  isButton = false,
  showButtonGroup = false,
  payload = [],
  TABLE_NAME = '',
  afterSucessRequest,
  methodType = 'post',
  isCompanyButton,
}) => {
  const { formatMessage } = useIntl();
  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  return (
    <div>
      {((deleteItemId && deleteItemId === id) || (TABLE_NAME.length > 0 && payload)) && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={dialogTitleId || 'Delete'}
            closeCallBack={() => setDeleteItemId && setDeleteItemId(null)}
            specialContainerClass='dialog-content w-600px'
          >
            <Formik
              initialValues={{}}
              onSubmit={async () => {
                if (!deleteURI || !payload) return;
                try {
                  if (methodType === 'delete') {
                    await axiosInstance.delete(deleteURI);
                  } else {
                    const formData = serialize({
                      ids: [payload],
                    });
                    formData.append('_method', 'DELETE');
                    await axiosInstance.post(deleteURI, formData);
                  }
                  successMessage && toast.success(formatMessage({ id: successMessage }) || '');
                  setDeleteItemId && setDeleteItemId(null);
                  afterSucessRequest && afterSucessRequest();
                } catch (err) {
                  DisplayErrors(err);
                }
              }}
            >
              {({ ...formik }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                >
                  <div className='d-flex flex-column'>
                    {alertMessage?.length && (
                      <div className='d-flex align-items-center bg-light-danger rounded p-5'>
                        <span className='svg-icon svg-icon-danger me-5'></span>
                        <div className='flex-grow-1 me-2'>
                          <p className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                            {alertMessage}
                          </p>
                        </div>
                      </div>
                    )}
                    <div>
                      {messageId && (
                        <h2>
                          <FormattedMessage id={messageId} />
                        </h2>
                      )}
                      {message && <h2>{message}</h2>}
                    </div>
                    {showButtonGroup && (
                      <SaveCancelBtnsGroup
                        saveBtnLabel='Delete'
                        formik={formik}
                        containerClassName='col-xl-6'
                        loading={false}
                        cancelOnClick={() => {
                          setDeleteItemId && setDeleteItemId(null);
                        }}
                      />
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </AhadDialog>
        </Portal>
      )}
      {isCompanyButton === undefined ? (
        isButton ? (
          <button
            className='btn btn-outlined'
            title={$t('Delete')}
            disabled={table?.selection?.length === 0}
            onClick={() =>
              setDeleteItemId &&
              setDeleteItemId(table?.selection.includes(-1) ? table?.ids : table?.selection)
            }
          >
            <i className='far fa-trash'></i>
            <FormatMessage id='Delete' />
          </button>
        ) : (
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            title={$t('Delete')}
            onClick={() => setDeleteItemId && setDeleteItemId(id)}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </a>
        )
      ) : (
        <button
          className='btn btn-outlined'
          title={$t('DeleteRequest')}
          onClick={() => setDeleteItemId && setDeleteItemId(id)}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <i className='far fa-trash'></i>
          <span
            style={{
              fontSize: '13px',
            }}
          >
            <FormatMessage id='DeleteRequest' />
          </span>
        </button>
      )}
    </div>
  );
};
