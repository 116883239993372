import React, { FC } from 'react';
import { Route } from 'react-router-dom';

export const RouteWithLayout: FC<{
  layout: FC;
  component: FC;
  [props: string]: any;
}> = ({ layout, component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props: any) =>
        React.createElement(layout, props, React.createElement(component, props))
      }
    />
  );
};
