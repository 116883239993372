import { TableCheckbox } from '../TableCheckbox';

export interface IRowCheckbox {
  display: boolean;
  id: number;
  tableName: string;
  disabled?: boolean;
}

export class TableSelection {
  constructor(private fieldData: IRowCheckbox) {}

  renderItem() {
    return this.fieldData.display ? (
      <div className='form-check form-check-sm form-check-custom'>
        <TableCheckbox fieldData={{ ...this.fieldData }} />
      </div>
    ) : (
      <></>
    );
  }
}
