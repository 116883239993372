import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'; //, useParams
import { usePermissions } from '../../../../app/hooks/usePermissions';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import * as auth from '../../../../app/modules/auth/store/AuthRedux';
import * as companiesRedux from '../../../../app/modules/companies/store/UserCompaniesRedux';
import * as CurrentCompanyRedux from '../../../../app/store/CurrentCompanyRedux';
import { RootState } from '../../../../setup';
import { Languages } from './Languages';
import { PopupChangePassword } from '../../../layout/components/header/PopupChangePassword';
import SwitchButton from '../../../layout/components/header/switchButton';
import useWindowSize from '../../../../app/hooks/useWindowSize';

const HeaderUserMenu: FC = () => {
  // const params: any = useParams();
  const { isSuperAdmin } = usePermissions();
  const [isPopupChangePasswordOpen, setIsPopupChangePasswordOpen] = useState(false);
  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;
  const { width } = useWindowSize();

  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(auth.actions.logout());
    dispatch(companiesRedux.actions.setLastAddedCompany(null));
    dispatch(
      CurrentCompanyRedux.actions.setCurrentCompany({
        company: null,
        user: null,
      })
    );
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {isPopupChangePasswordOpen && (
        <PopupChangePassword setIsPopupChangePasswordOpen={setIsPopupChangePasswordOpen} />
      )}

      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {user?.image && <img alt='Logo' src={user?.image} />}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.first_name} {user?.last_name}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <a
          onClick={() =>
            history.push(
              params.companySlug ? `/c/${params.companySlug}/user-profile` : '/user-profile'
            )
          }
          className='menu-link px-5'
        >
          <FormattedMessage id={params.companySlug ? 'User Profile' : 'User Profile'} />
        </a>
      </div> */}

      {isSuperAdmin && (
        <div className='menu-item px-5'>
          <a onClick={() => history.push('/admin/companies')} className='menu-link px-5'>
            <FormattedMessage id='Admin Dashboard' />
          </a>
        </div>
      )}
      <div className={width < 1024 ? 'menu-item px-5' : 'd-none'}>
        <SwitchButton className='menu-link px-5' />
      </div>
      <Languages />
      <div className='menu-item px-5'>
        <a onClick={() => setIsPopupChangePasswordOpen(true)} className='menu-link px-5'>
          <FormattedMessage id='Change Password' />
        </a>
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          <FormattedMessage id='Sign Out' />
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
