import Cookies from 'js-cookie';

import { axiosInstance } from '../../network/apis';
import { defaultLocale } from '../../_metronic/constants/languages';

export default function setupAxios(axios: any, store: any) {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const {
        auth: { token },
      } = store.getState();

      const locale = Cookies.get('locale');

      config.headers['X-Language'] = locale || defaultLocale;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}
