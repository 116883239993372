import { toast } from 'react-toastify';

export function DisplayErrors(err: any) {
  const errors = err?.response?.data?.errors;

  if (errors) {
    const errorsArray = Object.values(errors).flat();
    errorsArray.map((err, x, arr) => {
      return typeof err === 'string' && toast.error(err);
    });
  }
}
