import { Component } from 'react';

import { PdfLoader, PdfHighlighter, Highlight, Popup, AreaHighlight } from 'react-pdf-highlighter';
import { Tip } from './components/Tips';

import type { IHighlight, NewHighlight } from 'react-pdf-highlighter';

import { Spinner } from './Spinner';
import { Sidebar } from './Sidebar';
import { IDocument } from '../documents/types/DocumentsModel';
import { CommentsDrawer } from '../../../_metronic/partials/layout/demos-drawer/CommentsDrawer';

interface State {
  url: string;
  highlights: Array<IHighlight>;
}

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length);

const resetHash = () => {
  document.location.hash = '';
};

const HighlightPopup = ({ comment }: { comment: { text: string; emoji: string } }) =>
  comment.text ? (
    <div className='Highlight__popup'>
      {comment.emoji} {comment.text}
    </div>
  ) : null;

export class DocumentComments extends Component<
  {
    document: IDocument;
    comments?: Array<IHighlight>;

    viewOnly?: boolean;
    sendComment: (comment: any) => void;
    refetchDoc?: Function;
    gettingDocument?: boolean;
  },
  State
> {
  state = {
    url: this.props?.document?.preview_link || '',
    highlights: this.props.comments || [],
    display: false,
  };

  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  componentDidMount() {
    window.addEventListener('hashchange', this.scrollToHighlightFromHash, false);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.scrollToHighlightFromHash, false);
  }

  componentDidUpdate() {
    if (this.props.document?.preview_link !== this.state.url) {
      this.setState({ url: this.props.document?.preview_link })
    }
  }

  getHighlightById(id: string) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight.id === id);
  }

  addHighlight(highlight: NewHighlight) {
    const { highlights } = this.state;

    this.props.sendComment(highlight);
    this.setState({
      highlights: [{ ...highlight, id: getNextId() }, ...highlights],
    });
  }

  updateHighlight(highlightId: string, position: Object, content: Object) {
    this.setState({
      highlights: this.state.highlights.map((h) => {
        const { id, position: originalPosition, content: originalContent, ...rest } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }

  removeComment = (index: string) => {
    this.setState({
      highlights: this.state.highlights.filter((_comment, i) => i !== +index),
    });
  };

  render() {
    const { url, highlights } = this.state;
    
    return (
      <>
        <Sidebar
          document={this.props.document}
          highlights={highlights}
          resetHighlights={this.resetHighlights}
          removeComment={this.removeComment}
          isViewOnly={!!this.props.viewOnly}
          refetchDoc={this.props.refetchDoc}
        />
        <div
          id='document-viewer'
          style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}
        >
          {(this.props.document?.can_add_comment || !this.props.viewOnly) && (
            <>
              <CommentsDrawer />
            </>
          )}
          {/* --------------------------------- SideBar -------------------------------- */}
          <div
            id='document-pdf'
            style={
              !this.props.viewOnly ? { height: '100vh', width: '56vw', position: 'relative' } : {}
            }
          >

            {!this.props?.gettingDocument && <PdfLoader url={this.props.document?.preview_link || url} beforeLoad={<Spinner />}>
              {(pdfDocument) => (
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  enableAreaSelection={(event) => event.altKey}
                  onScrollChange={resetHash}
                  pdfScaleValue='pdf-width'
                  scrollRef={(scrollTo) => {
                    this.scrollViewerTo = scrollTo;
                    this.scrollToHighlightFromHash();
                  }}
                  onSelectionFinished={(
                    position,
                    content,
                    hideTipAndSelection,
                    transformSelection
                  ) =>
                    this.props.document?.can_add_comment || !this.props.viewOnly ? (
                      <Tip
                        onOpen={transformSelection}
                        onConfirm={(comment) => {
                          this.addHighlight({ content, position, comment });

                          hideTipAndSelection();
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo
                  ) => {
                    const isTextHighlight = !Boolean(highlight.content && highlight.content.image);

                    const component = isTextHighlight ? (
                      <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                      />
                    ) : (
                      <AreaHighlight
                        isScrolledTo={isScrolledTo}
                        highlight={highlight}
                        onChange={(boundingRect) => {
                          this.updateHighlight(
                            highlight.id,
                            { boundingRect: viewportToScaled(boundingRect) },
                            { image: screenshot(boundingRect) }
                          );
                        }}
                      />
                    );

                    return (
                      <Popup
                        popupContent={<HighlightPopup {...highlight} />}
                        onMouseOver={(popupContent) =>
                          setTip(highlight, (highlight) => popupContent)
                        }
                        onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    );
                  }}
                  highlights={highlights}
                />
              )}

            </PdfLoader>}
          </div>
        </div>
      </>
    );
  }
}
