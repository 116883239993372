import {
  getDocumenToTakeActiontList,
  getDocumentList,
  getUnderReviewDocumentsRequest,
} from './DocumentsCrud';
import { put, takeLatest } from 'redux-saga/effects';

import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { ID } from '../../../../_metronic/constants/paths';
import { IDocument } from '../types/DocumentsModel';
import { Search } from '../../../types/types';
import { SelectOption } from '../../../types/SelectOptions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export enum IDocumentsActionTypes {
  GET_DOCUMENTS = 'GET_DOCUMENTS',
  GET_DOCUMENTS_TO_TAKE_ACTION = 'GET_DOCUMENTS_TO_TAKE_ACTION',
  GET_UNDERREVIEW_DOCUMENTS = 'GET_UNDERREVIEW_DOCUMENTS',
  GET_SINGLE_DOCUMENT = 'GET_SINGLE_DOCUMENT',
  FULLFULL_DOCUMENTS = 'FULLFULL_DOCUMENTS',
  FULLFULL_CURRENT_DOCUMENT = 'FULLFULL_CURRENT_DOCUMENT',
  ADD_SECTIONS = 'ADD_SECTIONS',
  FULFILL_DOCUMENT_TO_TAKE_ACTION = 'FULFILL_DOCUMENT_TO_TAKE_ACTION',
  CREATE_DOCUMENT_FROM_TEMPLATE_ID = 'CREATE_DOCUMENT_FROM_TEMPLATE_ID',
  FULLFULL_DOCUMENTS_COMMENTS = 'FULLFULL_DOCUMENTS_COMMENTS',
  ADD_NEW_COMMENT = 'ADD_NEW_COMMENT',
}

export type AvailableDocStatus = 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 /* | 6 */;
export interface IContentSection {
  type: string;
  value: string;
  variables: string[];
}

export type DocumentEntryVariable = {
  id: string;
  type: 'text' | 'auto' | 'past_date' | 'number' | 'countries' | 'cities' | 'nationalities';
  value: any;
  has_initial: boolean;
};
export interface UnderConstructionDocument {
  id: number;
  preview_link: string;
  title: string;
  reviewers: SelectOption[];
  viewers: SelectOption[];
  signers: SelectOption[];
  recipients: SelectOption[];
  second_party: SelectOption[];
  signers_list: SelectOption[];
  reviewers_list: SelectOption[];
  user: {
    active: boolean;
    companies_count: number;
    created_at: string;
    email: string;
    first_name: string;
    id: number;
    image: string;
    last_name: string;
    last_visit: null | string;
    name: string;
    phone: string | null;
    position: [];
    signatures: [];
    suspended_at: null | string;
  };
  recipients_list: SelectOption[];
  second_party_list: SelectOption[];
  variables: [DocumentEntryVariable[]];
  recipient_type: 1 | 2 | 3 | 4 | 5;
  type: number;
  is_confidential: boolean;
  status: AvailableDocStatus;
  can_add_comment?: boolean;
  can_edit: boolean;
  is_second_party?: boolean;
  can_sign?: boolean;
  version: number;
}

const initialDocumentsState: IDocumentsState = {
  documents: undefined,
  underConstructionDocument: undefined,
  documentToTakeAction: undefined,
  comments: undefined,
};

export interface IDocumentsState {
  documents?: any;
  underConstructionDocument?: UnderConstructionDocument;
  documentToTakeAction?: UnderConstructionDocument | IDocument;
  sections?: IContentSection[] | [];
  comments?: any;
  comment?: any;
}

export const reducer = persistReducer(
  { storage, key: 'Documents', whitelist: ['UnderConstructionDocument', 'documentToTakeAction'] },
  (state: IDocumentsState = initialDocumentsState, action: ActionWithPayload<IDocumentsState>) => {
    switch (action.type) {
      case IDocumentsActionTypes.FULLFULL_DOCUMENTS_COMMENTS:
        return { ...state, comments: action.payload?.comments };
      case IDocumentsActionTypes.ADD_NEW_COMMENT:
        return { ...state, comments: [...state?.comments, action?.payload?.comment] };
      case IDocumentsActionTypes.FULLFULL_DOCUMENTS:
        return { ...state, documents: action.payload?.documents };
      case IDocumentsActionTypes.FULLFULL_CURRENT_DOCUMENT:
        return { ...state, underConstructionDocument: action.payload?.underConstructionDocument };
      case IDocumentsActionTypes.FULFILL_DOCUMENT_TO_TAKE_ACTION:
        return { ...state, documentToTakeAction: action.payload?.documentToTakeAction };
      case IDocumentsActionTypes.ADD_SECTIONS:
        const underConstructionDocument = {
          ...state.underConstructionDocument,
          sections: action?.payload?.sections,
        } as UnderConstructionDocument;

        return {
          ...state,
          underConstructionDocument: { ...underConstructionDocument },
        };
      default:
        return state;
    }
  }
);

export const actions = {
  addNewComment: ({ comment }: any) => ({
    type: IDocumentsActionTypes.ADD_NEW_COMMENT,
    payload: { comment },
  }),
  getDocuments: ({ companyId, params }: Search) => ({
    type: IDocumentsActionTypes.GET_DOCUMENTS,
    payload: { companyId, params },
  }),
  getDocumentsToTakeAction: ({ params }: { params: any }) => ({
    type: IDocumentsActionTypes.GET_DOCUMENTS_TO_TAKE_ACTION,
    payload: { params },
  }),
  getUnderReviewDocuments: ({ companyId, params }: Search) => ({
    type: IDocumentsActionTypes.GET_UNDERREVIEW_DOCUMENTS,
    payload: { companyId, params },
  }),
  fulfillDocuments: ({ documents }: IDocumentsState) => ({
    type: IDocumentsActionTypes.FULLFULL_DOCUMENTS,
    payload: { documents },
  }),
  fulfillDocumentsComments: ({ comments }: any) => ({
    type: IDocumentsActionTypes.FULLFULL_DOCUMENTS_COMMENTS,
    payload: { comments },
  }),
  fulfillUnderConstructionDocument: ({ underConstructionDocument }: IDocumentsState) => ({
    type: IDocumentsActionTypes.FULLFULL_CURRENT_DOCUMENT,
    payload: { underConstructionDocument },
  }),
  fulfillDocumentToTakeAction: ({ documentToTakeAction }: IDocumentsState) => {
    return {
      type: IDocumentsActionTypes.FULFILL_DOCUMENT_TO_TAKE_ACTION,
      payload: { documentToTakeAction },
    };
  },
  addSectionsToUnderConstructionDocument: ({ sections }: IDocumentsState) => ({
    type: IDocumentsActionTypes.ADD_SECTIONS,
    payload: { sections },
  }),
  createDocumentFromTemplateId: ({
    templateId,
    companyId,
    title,
  }: {
    templateId: ID;
    companyId: ID;
    title: string;
  }) => ({
    type: IDocumentsActionTypes.CREATE_DOCUMENT_FROM_TEMPLATE_ID,
    payload: { templateId, companyId, title },
  }),
};

export function* getDocuments(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getDocumentList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fulfillDocuments({ documents: data }));
  } catch (error) {
    //
  }
}

export function* getDocumentsToTakeActionRequest(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getDocumenToTakeActiontList({
      params: action.payload?.params,
    });
    yield put(actions.fulfillDocuments({ documents: data }));
  } catch (error) {
    //
  }
}

export function* getUnderReviewDocuments(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getUnderReviewDocumentsRequest({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fulfillDocuments({ documents: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IDocumentsActionTypes.GET_DOCUMENTS, getDocuments);
  yield takeLatest(
    IDocumentsActionTypes.GET_DOCUMENTS_TO_TAKE_ACTION,
    getDocumentsToTakeActionRequest
  );
  yield takeLatest(IDocumentsActionTypes.GET_UNDERREVIEW_DOCUMENTS, getUnderReviewDocuments);
}
