/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'; // shallowEqual,
// import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../setup';
import { $t } from '../../../../_metronic/i18n/formatMessage'; //, FormatMessage
import { PageTitle } from '../../../../_metronic/layout/core';
import { SerachFilters } from '../../../shared/SearchFilters';
// import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
// import { EditItem } from '../../../shared/tables/actions/EditItem';
// import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import * as CompaniesDeletionRequestsRedux from '../../../modules/admins/companies-deletion-requests/store/CompaniesDeletionRequestsRedux';
import * as TableRedux from '../../../store/TableRedux';
// import { TableStatus } from '../../../shared/tables/classes';
import { TableData } from '../../../shared/tables/classes/TableData';
import { TableActions } from '../../../shared/tables/classes/TableActions';
import { TableSelection } from '../../../shared/tables/classes/TableSelection';
// import { DateViewer } from '../../../shared/tables/classes/DateViewer';
import {
  CompaniesDeletionRequestDataListModel,
  CompaniesDeletionRequestModel,
} from '../../../modules/admins/companies-deletion-requests/CompaniesDeletionRequestModel';
// import { companyStatusColorsMap } from '../../../../_metronic/constants/colors';
import { KTSVG } from '../../../../_metronic/helpers';
// import { ModalName } from '../../../types/types';
import { ApproveCompaniesDeletionRequests } from '../../../shared/tables/actions/ApproveCompaniesDeletionRequests';
import { CommonTable2 } from '../../../shared/tables/Table2';
import { RejectCompaniesDeletionRequests } from '../../../shared/tables/actions/RejectCompaniesDeletionRequests';

const headerItems = [
  { id: 'Company name', maxWidth: 280 },
  // { id: 'Registration date', maxWidth: 280 },
  // { id: 'Company profile status', maxWidth: 280 },
  { id: 'Number of users', maxWidth: 140 },
  { id: 'Number of invoices', maxWidth: 140 },
  { id: 'Number of approved reports', maxWidth: 140 },
  { id: 'Actions', maxWidth: 280 },
];
const TABLE_NAME = 'sys_companies_deletion_requests_list';

const CompaniesDeletionRequestsContent: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  // const history = useHistory();

  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [approveItemObject, setApproveItemObject] = useState<any>(null);
  const [rejectItemObject, setRejectItemObject] = useState<any>(null);

  const companies_deletion_requests: CompaniesDeletionRequestDataListModel = useSelector<RootState>(
    (state) => state.SYS_companies_deletion_requests.companies_deletion_requests
  ) as CompaniesDeletionRequestDataListModel;

  // const modal: ModalName = useSelector<RootState>(
  //   (state) => state.modal.activeModal,
  //   shallowEqual
  // ) as ModalName;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  function RequestData() {
    dispatch(
      CompaniesDeletionRequestsRedux.actions.getCompaniesDeletionRequests({
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    RequestData();
  }, [table?.page, table?.pagination]);

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    dispatch(CompaniesDeletionRequestsRedux.actions.fulFillCompaniesDeletionRequests({}));
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  const rowItems = companies_deletion_requests?.data?.map(
    (deletionRequest: CompaniesDeletionRequestModel, index) => {
      if (index === 0) {
      }
      return [
        new TableSelection({ display: true, id: deletionRequest.id, tableName: TABLE_NAME }),
        new TableData({ text: deletionRequest.company.name || formatMessage({ id: 'No name' }) }),
        // new DateViewer({ date: deletionRequest.company.created_at }),
        // new TableStatus({
        //   statusId: deletionRequest.company.status,
        //   badge: companyStatusColorsMap?.get(deletionRequest.company?.status),
        // }),
        new TableData({ text: deletionRequest.company?.users_count?.toString() }),
        new TableData({ text: deletionRequest.company?.invoices_count?.toString() }),
        new TableData({ text: deletionRequest.company?.approved_report_count?.toString() }),
        new TableActions(
          (
            <div className='d-flex flex-row'>
              <ApproveCompaniesDeletionRequests
                id={deletionRequest.id}
                title={$t('Approve')}
                itemData={deletionRequest}
                requestURI={`/admin/company_deletion_requests/${deletionRequest.id}/approve`}
                showButtonGroup={true}
                messageId={'Are you sure you want to accept this deletion request?'}
                setApproveItemObject={setApproveItemObject}
                approveItemObject={approveItemObject}
                successMessage={'Company deletion request has been approved successfully!'}
                afterSucessRequest={() => {
                  RequestData();
                }}
              />
              <RejectCompaniesDeletionRequests
                id={deletionRequest.id}
                title={$t('Reject')}
                itemData={deletionRequest}
                requestURI={`/admin/company_deletion_requests/${deletionRequest.id}/reject`}
                showButtonGroup={true}
                setRejectItemObject={setRejectItemObject}
                rejectItemObject={rejectItemObject}
                successMessage={'Company deletion request has been rejected successfully!'}
                afterSucessRequest={() => {
                  RequestData();
                }}
              />

              {/* Hide the unnecessary */}
              {/* <ViewItem
              title={$t('View')}
              onClick={() => history.push(`/admin/companies/${deletionRequest.id}`)}
            />
            <EditItem
              title={$t('Edit')}
              onClick={() => history.push(`/admin/companies/${deletionRequest.id}/edit`)}
            />
            <DeleteItem title={$t('Delete')} /> */}
            </div>
          )
        ),
      ];
    }
  );

  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        {companies_deletion_requests?.data && (
          <CommonTable2
            className='pb-5 pb-xl-8'
            tableName={TABLE_NAME}
            headerWithCheckbox
            allDisplayedIds={companies_deletion_requests?.data?.map((company) => company.id) || []}
            meta={companies_deletion_requests}
            headerItems={headerItems}
            rowItems={rowItems}
            initPerPage={20}
          />
        )}
      </div>
    </>
  );
};

const CompaniesDeletionRequestsWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Companies deletion requests' })}</PageTitle>

      <CompaniesDeletionRequestsContent />
    </div>
  );
};

export { CompaniesDeletionRequestsWrapper };
