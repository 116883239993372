import * as Yup from 'yup';

type step2Selection = 'sign_and_send' | 'select_a_signer' | 'send_to_client' | '';

export interface IApproveSignOrReturnDocument {
  approvement: 'approve' | 'returnToDocumentCreator';
  step2Selection: step2Selection;
  signature: any;
}

const ApproveSignOrReturnDocumentStepperSchemas = [
  Yup.object({
    approvement: Yup.string().required(),
  }),
  Yup.object({
    step2Selection: Yup.string().required(),
  }),
];

const inits: IApproveSignOrReturnDocument = {
  approvement: 'approve',
  step2Selection: '',
  signature: '',
};

export { ApproveSignOrReturnDocumentStepperSchemas, inits };
