import { SelectOption } from '../../../types/SelectOptions';

type IInitialConfigPayload = {
  title: {
    en: string;
    ar: string;
  };
  type: number;
  recipient_type: number;
  users: number[];
  clients?: number[] | any;
  variable_users?: number[] | any;
  variable_clients?: number[] | any;
  is_confidential?: 0 | 1;
  creators: number[];
  reviewers: number[] | any;
  signers: number[] | any;
};

export function initialConfigPayload(values: any): IInitialConfigPayload {
  const payload: IInitialConfigPayload = {
    ...values,
    title: values.title,
    type: values.type,
    recipient_type: values.recipient_type.value,
    users: values.users,
    clients: values.clients,
    variable_users: values.variable_users,
    variable_clients: values.variable_clients,
    is_confidential: values.is_confidential,
  };
  return payload;
}

export function initialBasicsConfigPayload(values: any): IInitialConfigPayload {
  const payload: any = {
    creators: values.creators.map((creator: SelectOption) => creator.value),
    reviewers: values.reviewers.map((creator: SelectOption) => creator.value),
    signers: values.signers.map((creator: SelectOption) => creator.value),
    is_confidential: values.is_confidential,
  };
  return payload;
}

export function initialAllConfigPayload(values: any): IInitialConfigPayload {
  const payload: IInitialConfigPayload = {
    ...values,
    title: values.title,
    type: values.type,
    recipient_type: values.recipient_type.value,
    users: values.users,
    clients: values.clients,
    creators: values.creators,
    reviewers: values.reviewers,
    signers: values.signers,
  };
  return payload;
}

export function initialUsersConfigPayload(values: any): IInitialConfigPayload {
  const payload: any = {
    variable_users: values.variable_users,
  };
  return payload;
}

export function initialClientsConfigPayload(values: any): IInitialConfigPayload {
  const payload: any = {
    variable_clients: values.variable_clients,
  };
  return payload;
}
