import { FC } from 'react';

type Props = any;

export const LineItemErrorIcon: FC<Props> = ({ ...props }) => {
  return (
    <a className={`btn btn-icon btn-bg-light btn-color-${props.color} btn-sm me-1`} {...props}>
      <i className='fas fa-info-circle mx-2'></i>
    </a>
  );
};
