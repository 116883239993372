import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { serialize } from 'object-to-formdata';

import { RootState } from '../../../setup/redux/Store';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import { CompanyModel } from '../companies/CompanyModel';
import { ClientsDataListModel } from './types/clientsList';
import { SerachFilters } from '../../shared/SearchFilters';

import * as TableRedux from '../../store/TableRedux';
import * as ClientsRedux from '../clients/store/ClientsRedux';
// import * as RolesRedux from '../roles/store/RolesRedux';

import { TableData } from '../../shared/tables/classes/TableData';
import { TableSelection } from '../../shared/tables/classes/TableSelection';
import {
  // clientsTypesMap,
  ThirdPartyTypesMap,
  ThirdPartyTypeMap,
  AccountTypesMap,
  AccountTypeMap,
} from '../../../_metronic/constants/general';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { countires } from '../../../_metronic/constants/countries';
import { TableActions } from '../../shared/tables/classes';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';
import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import { CancelItem } from '../../shared/tables/actions/CancelItem';
import { ApproveItem } from '../../shared/tables/actions/ApproveItem';
import { Button } from '../../shared/Button';
import { usePermissions } from '../../hooks/usePermissions';
// import { LOCALE } from '../../../_metronic/helpers/typescript';

import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';

import { axiosInstance } from '../../../network/apis';
import { toast } from 'react-toastify';
import { CommonTable2 } from '../../shared/tables/Table2';
import { ErrorItemWarning } from '../../shared/tables/actions/ErrorItemWarning';
import { ErrorItemDanger } from '../../shared/tables/actions/ErrorItemDanger';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { TableDataPrimaryAndSecondary } from '../../shared/tables/classes/TableDataPrimaryAndSecondary';

const headerItems = [
  { id: 'Code', maxWidth: 80, width: 80 },
  { id: 'Name', maxWidth: 230, width: 230 },
  // { id: 'Name in AR', maxWidth: 230, width: 230 },
  { id: 'Third party type', maxWidth: 230, width: 230 },
  { id: 'Account type', maxWidth: 230, width: 230 },
  // { id: 'Type of Client', maxWidth: 240 },
  { id: 'Tax number', maxWidth: 230, width: 230 },
  { id: 'Country', maxWidth: 230, width: 230 },
  { id: 'Actions', maxWidth: 230, width: 230 },
];

const TABLE_NAME = 'clients_list';

export const Clients: FC = () => {
  return (
    <div>
      <ClientsTable />
    </div>
  );
};

export const ClientsTable: FC = () => {
  const { userCan } = usePermissions();
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  const [editItemId, setEditItemId] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [thirdParty, setThirdParty] = useState<any>(null);
  const [modalMessageID, setModalMessageID] = useState<any>('');
  const [showErrorsConfirmationModal, setShowErrorsConfirmationModal] = useState(false);
  const [showWarningsConfirmationModal, setShowWarningsConfirmationModal] = useState(false);
  const [warningsConfirmation, setWarningsConfirmation] = useState<any>([]);
  const [showModalButton, setShowModalButton] = useState(false);
  const [errorsConfirmation, setErrorsConfirmation] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const clients: ClientsDataListModel = useSelector<RootState>(
    (state) => state.USER_clients.Clients,
    shallowEqual
  ) as ClientsDataListModel;

  const ThirdPartyTypeOptions = useMemo(
    () =>
      Array.from(ThirdPartyTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );
  const AccountTypeOptions = useMemo(
    () =>
      Array.from(AccountTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );
  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* ----------------------- Getting roles for dropdown ----------------------- */
  // useEffect(() => {
  //   if (companyDetails?.id && params.companySlug) {
  //     dispatch(
  //       RolesRedux.actions.getRoles({ companyId: companyDetails.id, params: { per_page: 0 } })
  //     );
  //   }
  // }, [dispatch, companyDetails?.id, params.companySlug]);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(ClientsRedux.actions.fulfillClients({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      ClientsRedux.actions.getClients({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  }, [table?.page, table?.pagination, params.companySlug, companyDetails.id]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  const toggleThirdParty = async (thirdParty: any) => {
    if (!thirdParty?.is_disabled) {
      await axiosInstance.post(`${companyDetails.id}/clients/${thirdParty.id}/disable`);
      toast.success(formatMessage({ id: 'Third party has been disabled successfully!' }));
    } else {
      await axiosInstance.post(`${companyDetails.id}/clients/${thirdParty.id}/enable`);
      toast.success(formatMessage({ id: 'Third party has been enabled successfully!' }));
    }
    RequestData();
    setShowModal(false);
  };

  const deleteThirdParty = async (values: { selectedIds: number[]; approve: number }) => {
    setLoading(true);
    try {
      let payload = {
        ids: values.selectedIds,
        approve: values.approve,
      };

      if (values.approve) {
        payload['approve'] = values.approve;
      }
      const formData = serialize(payload);
      formData.append('_method', 'DELETE');
      await axiosInstance.post(`/${companyDetails.id}/clients`, formData);
      toast.success(formatMessage({ id: 'Third party has been deleted successfully!' }) || '');
      setDeleteItemId && setDeleteItemId(null);
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      RequestData();
    } catch (err: any) {
      if (err && err.response.status === 422 && err.response.data.messages.length > 0) {
        setLoading(false);
        setShowWarningsConfirmationModal(true);
        setWarningsConfirmation(err.response.data.messages);
        setShowModalButton(err.response.data.can_approve);
      } else {
        DisplayErrors(err);
      }
    }
    setLoading(false);
  };

  const rowItems = clients?.data.map((client: any) => {
    // const clientsTypesMapTranslated = {
    //   text: formatMessage({ id: `${clientsTypesMap.get(client.type)}` }) || '',
    // };
    const ThirdPartyTypesMapTranslated = {
      text: formatMessage({ id: `${ThirdPartyTypesMap.get(client.third_party_type)}` }) || '',
      // errors_with_color: client?.errors_with_color?.third_party_type,
    };
    const AccountTypesMapTranslated = {
      text: formatMessage({ id: `${AccountTypesMap.get(client.account_type)}` }) || '',
      // errors_with_color: client?.errors_with_color?.account_type,
    };

    const country = countires?.find((country: any) => client?.country?.value === country?.iso);
    return [
      new TableSelection({
        display: userCan('delete-client') || userCan('delete-third_party'),
        id: client.id,
        tableName: TABLE_NAME,
      }),
      new TableData({
        text: client.reference_number,
        // errors_with_color: client?.errors_with_color?.reference_number,
      }),
      new TableDataPrimaryAndSecondary({
        primaryText: `${client.short_name ?? ''}`,
        secondaryText: `${client.name[locale] ?? ''}`,
        // errors_with_color: client?.errors_with_color?.short_name,\n ${client.name[locale] ?? ''
      }),
      // text: `${sale.invoice_number}`, error: sale?.errors?.invoice_number
      // new TableData(clientsTypesMapTranslated),
      new TableData(ThirdPartyTypesMapTranslated),
      new TableData(AccountTypesMapTranslated),
      new TableData({
        text: client.tax_number,
        // errors_with_color: client?.errors_with_color?.tax_number,
      }),

      new TableData({
        text: country ? `${country?.emoji} ${country?.name?.[locale]}` : '',
      }),
      new TableActions(
        (
          /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
          <div className='d-flex flex-row'>
            {userCan('view-third_party') && (
              <ViewItem
                title={$t('View')}
                onClick={() =>
                  history.push(
                    `/c/${companyDetails.slug}/company-management/clients-and-vendors/${client.id}`
                  )
                }
              />
            )}
            {/* {userCan('update-third_party') && (
              <EditItem
                title={$t('Edit')}
                onClick={() =>
                  history.push(`/c/${companyDetails.slug}/company-management/clients-and-vendors/${client.id}/edit`)
                }
              />
            )} */}
            {userCan('update-third_party') &&
              (client?.can_update && !client?.show_warning ? (
                <EditItem
                  title={$t('Edit')}
                  onClick={() =>
                    history.push(
                      `/c/${companyDetails.slug}/company-management/clients-and-vendors/${client.id}/edit`
                    )
                  }
                />
              ) : (
                <EditItemWithModal
                  id={client.id}
                  title={$t('Edit')}
                  messageId={
                    client?.can_update
                      ? client?.show_warning
                        ? 'This third party has been selected in created sale/purchase, are you sure you want to proceed the editing process?'
                        : ''
                      : 'This third party is included in a approved or draft report, are you sure you want to proceed the editing process?'
                  }
                  editURI={`/c/${companyDetails.slug}/company-management/clients-and-vendors/${client.id}/edit`}
                  showButtonGroup={true}
                  editItemId={editItemId}
                  setEditItemId={setEditItemId}
                />
              ))}
            {userCan('delete-third_party') && (
              <DeleteItem
                id={client.id}
                title={$t('Delete')}
                messageId={
                  client?.can_update
                    ? client?.show_warning
                      ? client?.is_disabled
                        ? "This third party has been selected in created sale/purchase, and can't be deleted."
                        : "This third party has been selected in created sale/purchase, and can't be deleted, you can disable him/her."
                      : 'Are you sure you want to delete this third party?'
                    : "This third party is included in a approved or draft report, and can't be deleted."
                }
                showButtonGroup={client?.can_update && !client?.show_warning}
                setDeleteItemId={setDeleteItemId}
                deleteItemId={deleteItemId}
                deleteURI={`/${companyDetails.id}/clients`}
                payload={client.id}
                successMessage={'Third party has been deleted successfully!'}
                afterSucessRequest={() => RequestData()}
              />
            )}
            {userCan('disable-third_party') && !client?.is_disabled && (
              <CancelItem
                title={$t('Disable third party')}
                onClick={() => {
                  setThirdParty(client);
                  setModalMessageID(
                    "If you disabled this third party, other users won't be able to select it in sales/purchases records anymore as client/supplier"
                  );
                  setShowModal(true);
                }}
              />
            )}
            {userCan('disable-third_party') && client?.is_disabled && (
              <ApproveItem
                title={$t('Enable third party')}
                onClick={() => {
                  setThirdParty(client);
                  setModalMessageID(
                    'If you enabled this third party, other users will be able to select it in sales/purchases records as client/supplier'
                  );
                  setShowModal(true);
                }}
              />
            )}
            {userCan('view-third_party') && client?.errors?.warning && (
              <ErrorItemWarning
                title={$t('Has errors')}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setErrorsConfirmation(client?.errors?.warning?.map((error: any) => error?.text));
                  setShowErrorsConfirmationModal(true);
                }}
              />
            )}
            {userCan('view-third_party') && client?.errors?.danger && (
              <ErrorItemDanger
                title={$t('Has errors')}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setErrorsConfirmation(client?.errors?.danger.map((error: any) => error?.text));
                  setShowErrorsConfirmationModal(true);
                }}
              />
            )}
          </div>
        )
      ),
    ];
  });

  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}

          {/*  ------------------------ start Action button ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
            </button>
            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                  {$t('Actions')}{' '}
                </div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}

              {/* begin::Menu item */}
              {(userCan('create-client') || userCan('create-third_party')) && (
                <div className='menu-item px-3'>
                  <Button
                    className='menu-link px-3 btn w-md-190px'
                    faIconMenu={true}
                    fa='mail-bulk'
                    onClick={() => {
                      if (clients?.can_create) {
                        history.push(
                          `/c/${params.companySlug}/company-management/clients-and-vendors/add`
                        );
                      } else {
                        setErrorsConfirmation([
                          formatMessage({
                            id: 'You have reached the maximum allowed for your current subscription. Contact support to upgrade.',
                          }),
                        ]);
                        setShowErrorsConfirmationModal(true);
                      }
                    }}
                    nameId='Add third party'
                  />
                </div>
              )}
              {/* end::Menu item */}

              {/* begin::Menu item */}
              {(userCan('create-client') || userCan('create-third_party')) && (
                <div className='menu-item px-3'>
                  <Button
                    className='menu-link px-3 btn w-md-190px'
                    faIconMenu={true}
                    fa='plus'
                    onClick={() => {
                      if (clients?.can_create) {
                        history.push(
                          `/c/${params.companySlug}/company-management/clients-and-vendors/import`
                        );
                      } else {
                        setErrorsConfirmation([
                          formatMessage({
                            id: 'You have reached the maximum allowed for your current subscription. Contact support to upgrade.',
                          }),
                        ]);
                        setShowErrorsConfirmationModal(true);
                      }
                    }}
                    nameId='Import third_party'
                  />
                </div>
              )}
              {/* end::Menu item */}

              {/* begin::Menu item */}
              {(userCan('view-client') || userCan('view-third_party')) && (
                <div className='menu-item px-3 '>
                  <Button
                    className='menu-link px-3 btn w-md-190px'
                    faIconMenu={true}
                    fa='arrow-down'
                    onClick={() =>
                      axiosInstance
                        .get(`${companyDetails.id}/clients/export`, { responseType: 'blob' })
                        .then((response) => {
                          let file = new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          });
                          let fileURL = URL.createObjectURL(file);

                          // create <a> tag dinamically
                          let fileLink = document.createElement('a');
                          fileLink.href = fileURL;

                          // it forces the name of the downloaded file
                          fileLink.download = 'Ahad_export-third-parties';

                          // triggers the click event
                          fileLink.click();
                        })
                    }
                    nameId='Export third_party'
                  />
                </div>
              )}
              {/* end::Menu item */}

              {/* begin::Menu item */}
              {(userCan('delete-client') || userCan('delete-third_party')) && (
                <div className='menu-item px-3 pb-3'>
                  <button
                    className='menu-link px-3 btn w-md-190px'
                    title={$t('Delete')}
                    disabled={table?.selection?.length === 0}
                    onClick={() => {
                      // setDeleteItemId &&
                      // setDeleteItemId(table?.selection.includes(-1) ? table?.ids : table?.selection)
                      deleteThirdParty({
                        selectedIds: table?.selection.includes(-1) ? table?.ids : table?.selection,
                        approve: 0,
                      });
                    }}
                  >
                    <span className='menu-icon'>
                      <i className='far fa-trash'></i> &nbsp;
                    </span>

                    {formatMessage({
                      id: 'Delete',
                    })}
                  </button>
                </div>
              )}
              {/* end::Menu item */}
            </div>
            {/* end::Menu 2 */}
            {/* end::Menu */}
          </div>

          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'country',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'country',
                      })
                    ),
                  placeholderId: 'Country',
                  options: countires?.map((country: any) => ({
                    value: country.iso,
                    label: country?.emoji + ' ' + country?.name?.[locale],
                  })),
                },
                // {
                //   type: 'dropdown',
                //   queryTerm: 'type',
                //   onChange: (value: any) =>
                //     dispatch(
                //       TableRedux.actions.updateQuery({
                //         value: value,
                //         tableName: TABLE_NAME,
                //         queryTerm: 'type',
                //       })
                //     ),
                //   placeholderId: 'Type of Client',
                //   options: [
                //     { value: '1', label: formatMessage({ id: 'Company' }) },
                //     { value: '2', label: formatMessage({ id: 'Individual' }) },
                //   ],
                // },
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'third_party_type',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'third_party_type',
                      })
                    ),
                  placeholderId: 'Third party type',
                  options: ThirdPartyTypeOptions,
                },
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'account_type',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'account_type',
                      })
                    ),
                  placeholderId: 'Account type',
                  options: AccountTypeOptions,
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          initPerPage={20}
          headerWithCheckbox={userCan('delete-client') || userCan('delete-third_party')}
          allDisplayedIds={clients?.data?.map((client) => client.id) || []}
          meta={clients}
          headerItems={headerItems}
          rowItems={rowItems}
        />
      </div>
      {showModal && modalMessageID && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                <FormattedMessage id={modalMessageID} />
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleThirdParty(thirdParty);
                      }}
                    >
                      <span className='indicator-label'>
                        <FormattedMessage id={'Confirm'} />
                      </span>
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------errors confirmation Modal-----------------------  */}

      {showErrorsConfirmationModal && errorsConfirmation && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => setShowErrorsConfirmationModal(false)}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 m-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                {errorsConfirmation.length === 1 && (
                  <span className='min-w-250px'>{errorsConfirmation[0]}</span>
                )}
                {errorsConfirmation.length > 1 && (
                  <ol>
                    {errorsConfirmation.map((warning: any, index: any) => (
                      <li className='min-w-250px' key={index}>
                        {warning}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
              {/* <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                <span>{errorsConfirmation}</span>
              </div> */}
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // setApproveWarnings(0);
                        setShowErrorsConfirmationModal(false);
                        // formik.submitForm();
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end errors confirmation Modal-----------------------  */}

      {/*  ---------------------warnings confirmation Modal-----------------------  */}

      {showWarningsConfirmationModal && warningsConfirmation && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowWarningsConfirmationModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                {warningsConfirmation.length === 1 && <span>{warningsConfirmation[0]}</span>}
                {warningsConfirmation.length > 1 && (
                  <ol>
                    {warningsConfirmation.map((warning: any, index: any) => (
                      <li key={index}>{warning}</li>
                    ))}
                  </ol>
                )}
              </div>
              {showModalButton && (
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        // disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowWarningsConfirmationModal(false);
                          deleteThirdParty({
                            selectedIds: table?.selection.includes(-1)
                              ? table?.ids
                              : table?.selection,
                            approve: 1,
                          });
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Approve'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowWarningsConfirmationModal(false);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end warnings confirmation Modal-----------------------  */}
    </>
  );
};

const ClientsWrapper: FC = () => {
  usePermissions(['view-client', 'view-third_party']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Third party' })}</PageTitle>
      <Clients />
    </div>
  );
};

export { ClientsWrapper };
