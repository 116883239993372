import clsx from 'clsx';
import { useFormik } from 'formik';
import { FC, useMemo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';
import _ from 'lodash';

import * as Yup from 'yup';
import * as TableRedux from '../../../store/TableRedux';

import * as RolesRedux from '../../roles/store/RolesRedux';
import * as UsersRedux from './store/UsersGroupRedux';

import { RolesDataListModel } from '../../roles/types/Roles';
import { user } from '../../../types/user';

import { RootState } from '../../../../setup';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';
import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { CompanyModel, CompaniesDataListModel } from '../../../modules/companies/CompanyModel';
import { addPermissions } from '../../../modules/roles/store/RolesCrud';
import { Input } from '../../../shared/Input';
import { SeparatorLines } from '../../../shared/Lines';
import { LocaleTitle } from '../../../shared/LocaleTitle';
import { TextArea } from '../../../shared/textarea';
import { Users } from '../../../modules/users/UsersWrapper';
import { usePermissions } from '../../../hooks/usePermissions';
// type Props = {
//   mode: 'edit' | 'view';
// };
const tableName = 'users_list';

export interface IUserGroupsInitialValues {
  name_en: string;
  name_ar: string;
  description_en: string;
  description_ar: string;
  permissions: Array<number>;
}

export const initialValues: IUserGroupsInitialValues = {
  name_en: '',
  name_ar: '',
  description_en: '',
  description_ar: '',
  permissions: [],
};

export const AddEditUserGroupsForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  // TODO: handle permissions
  usePermissions('create-role', 'Add role');
  // for directions in ui
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const companyId =
    companies?.data?.find((company) => company.slug === params.companySlug)?.id || null;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[tableName];

  const AddEditRoleSchema = useMemo(
    () =>
      Yup.object().shape({
        roles: Yup.array().min(1, $t('Please choose at least one')),
        name_en: Yup.string()
          .required($t('This field is required'))
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_en', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        name_ar: Yup.string()
          .required($t('This field is required'))
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_ar', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_ar: Yup.string()
          .trim()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_ar',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_en: Yup.string()
          .trim()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_en',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        permissions: Yup.array()
          .min(1, $t('You need to select at least one permission'))
          .required($t('You need to select at least one permission')),
      }),
    []
  );
  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const roles: RolesDataListModel = useSelector<RootState>(
    (state) => state.roles.roles,
    shallowEqual
  ) as RolesDataListModel;

  const user: user = useSelector<RootState>(
    (state) => state.USER_users.singleUser,
    shallowEqual
  ) as user;

  /* ----------------------- Getting roles for dropdown ----------------------- */
  useEffect(() => {
    if (companyDetails?.id && params.companySlug) {
      dispatch(
        RolesRedux.actions.getRoles({ companyId: companyDetails.id, params: { per_page: 0 } })
      );
    }
    if (companyDetails?.id && params.companySlug) {
      dispatch(
        UsersRedux.actions.getSingleUser({ companyId: companyDetails.id, userId: params.userId })
      );
    }
  }, [dispatch, companyDetails?.id, params.companySlug]);
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      roles: user?.roles?.map((role) => ({
        value: role?.id.toString(),
        label: role?.name,
      })),
    },
    validationSchema: AddEditRoleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        if (typeof companyId !== 'number') {
          toast.error(formatMessage({ id: 'Error! You will need to refresh the page' }));
          return;
        }
        const payload: any = {};

        table.selection.includes(-1) ? (payload.users = [-1]) : (payload.users = table.selection);

        dispatch(TableRedux.actions.clearSelection({ tableName }));
        payload.description = { ar: values.description_ar, en: values.description_en };
        payload.name = { ar: values.name_ar, en: values.name_en };
        payload.permissions = _.uniq(values.permissions);
        const formData = serialize(payload);
        await addPermissions(companyId, formData);
        // TODO: change success message
        toast.success(formatMessage({ id: 'The role has been created successfully!' }));
        history.push(`/c/${params.companySlug}/groups/users/users-groups`);
      } catch (error: any) {
        DisplayErrors(error);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  return (
    <form
      className={clsx('p-5')}
      noValidate
      autoComplete='none'
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='table-wrapper bg-white pb-10'>
        <h3 className='m-2 mx-5'>{$t('Role Settings')}</h3>
        <SeparatorLines lines={2} />
        <div className='m-10'>
          <div className='row fv-row'>
            {locales.map((lang) => (
              <div className='col-xl-6'>
                <Input
                  placeholder={LocaleTitle({ id: 'Role Name', lang })}
                  autoComplete='off'
                  labelId={LocaleTitle({ id: 'Role Name', lang })}
                  name={`name_${lang}`}
                  type='text'
                  formik={formik}
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                />
              </div>
            ))}
          </div>
          <div className='row fv-row mt-10'>
            {locales.map((lang) => (
              <div className='col-xl-6'>
                <TextArea
                  placeholder={LocaleTitle({ id: 'Role Description', lang })}
                  autoComplete='off'
                  labelId={LocaleTitle({ id: 'Role Description', lang })}
                  name={`description_${lang}`}
                  type='text'
                  formik={formik}
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  rows='6'
                />
              </div>
            ))}
          </div>
          <div className='row fv-row mt-10'>
            <div className='col-xl-12'>
              <label className='form-label fs-6 my-1 fw-bolder text-dark' htmlFor={'roles'}>
                <FormattedMessage id={'Roles'} />
              </label>
              <Select
                id='roles'
                // isDisabled={mode === 'view'}
                placeholder={$t('Roles')}
                className='react-select smaller'
                options={roles?.data?.map((role) => ({
                  value: role.id.toString(),
                  label: role.name,
                }))}
                value={formik.values.roles}
                isMulti
                onChange={(selection) => formik.setFieldValue('roles', selection)}
              />
              {formik?.errors?.roles && formik.touched.roles && (
                <div className='fv-plugins-message-container mt-2 mx-3'>
                  <span role='alert' className='text-danger'>
                    {formik?.errors?.roles}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='table-wrapper bg-white mt-10 mb-10'>
        <Users />
      </div>
      <div className='text-center col-xl-3 d-flex gap-3 m-5'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-sm w-100 mb-5 btn-primary btn-shadow btn-primary-shadow mt-4'
          disabled={formik.isSubmitting}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='save' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Please wait...' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          onClick={() => {
            formik.resetForm();
            history.push(`/c/${params.companySlug}/groups/users/users-groups`);
          }}
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 mb-5  shadowed mt-4'
        >
          <FormattedMessage id='Cancel' />
        </button>
      </div>
    </form>
  );
};
