/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl'; //FormattedMessage,
import { Prompt, useHistory, useParams } from 'react-router-dom'; //useLocation,
import { serialize } from 'object-to-formdata';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { KTSVG } from '../../../../_metronic/helpers';

// import { Alert } from 'react-bootstrap-v5';
import { components, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import 'react-intl-tel-input/dist/main.css';

import {
  BasicCategoriesItemsErrors,
  BasicCategoriesItemsTouched,
} from './BasicCategoriesItemsErrors';

import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { createSelectOptions } from '../../../../utils/createSelectOptions';
import { createOptions } from '../../../../utils/createOptions';
import { debounce } from '../../../../utils/debounce';

import { $t } from '../../../../_metronic/i18n/formatMessage';
// import { PageTitle } from '../../../../_metronic/layout/core';
import { SeparatorLines } from '../../../shared/Lines';

import {
  VatRateTypeMap,
  TransactionTypeMap,
  TaxAbilityTypeMap,
  CategoryTypeMap,
  IncomeCodeTypeMap,
  ExpenditureCodeTypeMap,
  FilterVatRateTypeMap,
} from '../../../../_metronic/constants/general'; //DocumentDirection,
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';

import { axiosInstance } from '../../../../network/apis';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import { countires } from '../../../../_metronic/constants/countries';

import { initialConfigPayload } from '../../../modules/admins/vat-rates/helpers/vatRatesConfigHelpers';
import { useConfigureVatRatesSchema } from './useConfigureVatRatesSchema';

import { RootState } from '../../../../setup/redux/Store';
import * as VatRateRedux from '../../../modules/admins/vat-rates/store/VatRatesRedux';
import { IVatRate } from '../../../modules/admins/vat-rates/types/VatRatesModel';

import { Input } from '../../../shared/Input';
import { Label } from '../../../shared/Label';
import { TextArea } from '../../../shared/textarea';
import { ErrorMessage } from '../../../shared/ErrorMessage';

import SaveCancelBtnsGroup from '../../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../../hooks/useExitPrompt/useExitPrompt';
import useWindowSize from '../../../hooks/useWindowSize';

type Props = {
  mode: 'create' | 'edit' | 'view';
  displayRequiredSection?: number | string;
};
const headerItems = [
  { id: 'Categories', minWidth: 250, width: 250, maxWidth: 250 },
  { id: 'Error message settings', minWidth: 250, width: 250, maxWidth: 250 },
  { id: 'Actions', minWidth: 75, width: 75, maxWidth: 75 },
];

export const ConfigNewVatRate: FC<Props> = ({ mode, displayRequiredSection }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);

  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const params: any = useParams();
  const { width } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  const { vatRatesInitialValues, vatRatesValidationSchema } = useConfigureVatRatesSchema();
  const [categoriesOptions, setCategoriesOptions] = useState<any>(null);

  const createVatRate = async (values: any, resetForm: any) => {
    setLoading(true);
    const payload: { [key: string]: any } = initialConfigPayload(
      mode === 'create'
        ? {
            ...values,
            type: values.type.value,
          }
        : {}
    );

    // console.log(values);

    if (mode === 'create') {
      payload['name[ar]'] = values.name_ar;
      payload['name[en]'] = values.name_en;
      payload['description'] = { ar: '', en: '' };
      payload['description']['ar'] = values.description_ar.trim();
      payload['description']['en'] = values.description_en.trim();
      payload['type'] = values.type.value;
      payload['code_type'] = values.code_type.value;
      payload['transaction_type'] = values.transaction_type.value;
      payload['tax_ability'] = values.tax_ability.value;
      payload['country'] = values.country.value ? values.country.value.toLowerCase() : '';
      let rulesArray = [];
      if (values?.code_expiration_date && values?.code_expiration_date[0]) {
        rulesArray.push(values?.code_expiration_date?.[0].value);
      }
      if (values?.code_amount_range && values?.code_amount_range[0]) {
        rulesArray.push(values?.code_amount_range?.[0].value);
      }
      if (values?.third_party_nationalities && values?.third_party_nationalities[0]) {
        rulesArray.push(values?.third_party_nationalities?.[0].value);
      }
      if (values?.third_party_type && values?.third_party_type[0]) {
        rulesArray.push(values?.third_party_type?.[0].value);
      }
      if (values?.transaction_date && values?.transaction_date[0]) {
        rulesArray.push(values?.transaction_date?.[0].value);
      }
      if (values?.tax_registration && values?.tax_registration[0]) {
        rulesArray.push(values?.tax_registration?.[0].value);
      }

      payload['rules'] = rulesArray;

      let categories = values.basic_categories.map((basic_category: any) => {
        if (basic_category?.category_type?.[0]?.value === 1) {
          return {
            id: basic_category?.category?.[0]?.value,
            is_basic: basic_category?.category_type?.[0]?.value === 1 ? 1 : 0,
            info_msg: {
              ar: basic_category?.category_error_message_ar
                ? basic_category?.category_error_message_ar
                : '',
              en: basic_category?.category_error_message_en
                ? basic_category?.category_error_message_en
                : '',
            },
          };
        } else {
          return {
            id: basic_category?.category?.[0]?.value,
            is_basic: basic_category?.category_type?.[0]?.value === 1 ? 1 : 0,
            warning_msg: {
              ar: basic_category?.category_error_message_ar
                ? basic_category?.category_error_message_ar
                : '',
              en: basic_category?.category_error_message_en
                ? basic_category?.category_error_message_en
                : '',
            },
          };
        }
      });

      payload['categories'] = categories;

      delete payload['basic_categories'];
      delete payload['code_expiration_date'];
      delete payload['code_amount_range'];
      delete payload['third_party_nationalities'];
      delete payload['third_party_type'];
      delete payload['transaction_date'];
      delete payload['tax_registration'];
    } else {
      let rulesArray = [];
      if (values.code_expiration_date && values.code_expiration_date[0]) {
        rulesArray.push(values.code_expiration_date?.[0].value);
      }
      if (values.code_amount_range && values.code_amount_range[0]) {
        rulesArray.push(values.code_amount_range?.[0].value);
      }
      if (values.third_party_nationalities && values.third_party_nationalities[0]) {
        rulesArray.push(values.third_party_nationalities?.[0].value);
      }
      if (values.third_party_type && values.third_party_type[0]) {
        rulesArray.push(values.third_party_type?.[0].value);
      }
      if (values.transaction_date && values.transaction_date[0]) {
        rulesArray.push(values.transaction_date?.[0].value);
      }
      if (values.tax_registration && values.tax_registration[0]) {
        rulesArray.push(values.tax_registration?.[0].value);
      }

      payload['rules'] = rulesArray;

      let categories = values.basic_categories.map((basic_category: any) => {
        if (basic_category?.category_type?.[0]?.value === 1) {
          return {
            id: basic_category?.category?.[0]?.value,
            is_basic: basic_category?.category_type?.[0]?.value === 1 ? 1 : 0,
            info_msg: {
              ar: basic_category?.category_error_message_ar
                ? basic_category?.category_error_message_ar
                : '',
              en: basic_category?.category_error_message_en
                ? basic_category?.category_error_message_en
                : '',
            },
          };
        } else {
          return {
            id: basic_category?.category?.[0]?.value,
            is_basic: basic_category?.category_type?.[0]?.value === 1 ? 1 : 0,
            warning_msg: {
              ar: basic_category?.category_error_message_ar
                ? basic_category?.category_error_message_ar
                : '',
              en: basic_category?.category_error_message_en
                ? basic_category?.category_error_message_en
                : '',
            },
          };
        }
      });

      payload['categories'] = categories;

      delete payload['basic_categories'];
      delete payload['code_expiration_date'];
      delete payload['code_amount_range'];
      delete payload['third_party_nationalities'];
      delete payload['third_party_type'];
      delete payload['transaction_date'];
      delete payload['tax_registration'];
      payload['name[ar]'] = values.name_ar;
      payload['name[en]'] = values.name_en;
    }

    const formData = serialize(payload, { indices: true });

    try {
      if (mode === 'create') {
        await axiosInstance.post(`/admin/vat_rates`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'Vat Rate has been created successfully!' }));
      } else if (mode === 'edit') {
        formData.append('_method', 'PUT');
        await axiosInstance.post(`/admin/vat_rates/${vatRateItem.id}`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'Vat Rate has been updated successfully!' }));
      }
      history.push(`/admin/vat-rates`);
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  /* ----------------------------- remove item from invoice ----------------------------- */
  const removeItem = (remove: any, i: any) => {
    return remove(i);

    // formik.setFieldValue('items', [...formik.values.items, item]);
  };
  /* ----------------------------- add item to invoice ----------------------------- */
  const addItem = (push: any) => {
    return push({
      // name: [],
      // description: '',
      category: [],
      category_type: [categoryTypesOptions[0]],
      category_error_message_ar: '',
      category_error_message_en: '',
    });
    // formik.setFieldValue('items', [...formik.values.items, item]);
  };
  const formik = useFormik({
    initialValues: vatRatesInitialValues,
    validationSchema:
      mode === 'create'
        ? vatRatesValidationSchema
        : Yup.object().shape({
            name_ar: Yup.string().required($t('This field is required')),
            name_en: Yup.string().required($t('This field is required')),
          }),
    onSubmit: (values, { resetForm }) => createVatRate(values, resetForm),
  });
  const { setFieldValue } = formik; //, values

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  /* ----------------------------- Getting current Vat Rates ----------------------------- */
  const getCurrentVatRate = async (vatRateId: any) => {
    try {
      dispatch(
        VatRateRedux.actions.getVatRate({
          vatRateId: vatRateId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };

  /* ------------------------- get categories data ------------------------ */
  // const { isLoading: CategoriesLoading, data: CategoriesList } = useQuery(
  //   'SalesCategories',
  //   () =>
  //     axiosInstance(`/categories`, {
  //       params: {
  //         type: `1`,
  //         per_page: 0,
  //       },
  //     }).then((res) => {
  //       return res.data.sales;
  //     }),
  //   { retryDelay: 300, retry: 3 }
  // );
  // const salesCategoriesOptions = useMemo(() => createOptions(CategoriesList), [CategoriesList]);

  const loadSalesCategoriesOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`/categories`, {
          params: {
            name: inputValue,
            type: `1`,
            per_page: 0,
          },
        })
        .then((res) => {
          callback(createOptions(res.data.sales));
        });
    }, 1000),
    [formik?.values]
  );

  const onFocusLoadSalesCategoriesOptions = async (i: any) => {
    await axiosInstance
      .get(`/categories`, {
        params: {
          type: `1`,
          per_page: 0,
        },
      })
      .then((res) => {
        setCategoriesOptions(createOptions(res.data.sales));
      });
  };

  const loadPurchasesCategoriesOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`/categories`, {
          params: {
            name: inputValue,
            type: `2`,
            per_page: 0,
          },
        })
        .then((res) => {
          callback(createOptions(res.data.purchases));
        });
    }, 1000),
    [formik?.values]
  );

  const onFocusLoadPurchasesCategoriesOptions = async (i: any) => {
    await axiosInstance
      .get(`/categories`, {
        params: {
          type: `2`,
          per_page: 0,
        },
      })
      .then((res) => {
        setCategoriesOptions(createOptions(res.data.purchases));
      });
  };

  /* -----------------------------end Getting current Vat Rates ----------------------------- */
  /* ----------------------- end getting data from store ---------------------- */
  const accountTypesOptions = useMemo(() => {
    return Array.from(VatRateTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const accountTypesOptionsfullTypes = useMemo(
    () =>
      Array.from(FilterVatRateTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  const incomeCodeTypesOptions = useMemo(() => {
    return Array.from(IncomeCodeTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const expenditureCodeTypesOptions = useMemo(() => {
    return Array.from(ExpenditureCodeTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const transactionTypesOptions = useMemo(() => {
    return Array.from(TransactionTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const taxAbilityTypesOptions = useMemo(() => {
    return Array.from(TaxAbilityTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const categoryTypesOptions = useMemo(() => {
    return Array.from(CategoryTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  // code expiration category
  const { isLoading: codeExpirationLoading, data: codeExpirationList } = useQuery(
    'CodeExpiration',
    () =>
      axiosInstance(`/admin/rules`, {
        params: {
          category: 1,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name[locale],
          };
        });
      }),
    { retryDelay: 300, retry: 3 }
  );

  const codeExpirationOptions = useMemo(
    () => createSelectOptions(codeExpirationList),
    [codeExpirationList]
  );

  // code amount category
  const { isLoading: amountRangeLoading, data: amountRangeList } = useQuery(
    'CodeAmount',
    () =>
      axiosInstance(`/admin/rules`, {
        params: {
          category: 2,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name[locale],
          };
        });
      }),
    { retryDelay: 300, retry: 3 }
  );

  const amountRangeOptions = useMemo(() => createSelectOptions(amountRangeList), [amountRangeList]);

  // code nationality category
  const { isLoading: thirdPartyNationalitiesLoading, data: thirdPartyNationalitiesList } = useQuery(
    'ThirdPartyNationalities',
    () =>
      axiosInstance(`/admin/rules`, {
        params: {
          category: 3,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name[locale],
          };
        });
      }),
    { retryDelay: 300, retry: 3 }
  );

  const thirdPartyNationalitiesOptions = useMemo(
    () => createSelectOptions(thirdPartyNationalitiesList),
    [thirdPartyNationalitiesList]
  );

  // code account type category
  const { isLoading: thirdPartyTypesLoading, data: thirdPartyTypesList } = useQuery(
    'ThirdPartyTypes',
    () =>
      axiosInstance(`/admin/rules`, {
        params: {
          category: 4,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name[locale],
          };
        });
      }),
    { retryDelay: 300, retry: 3 }
  );

  const thirdPartyTypesOptions = useMemo(
    () => createSelectOptions(thirdPartyTypesList),
    [thirdPartyTypesList]
  );

  // code transaction date category
  const { isLoading: transactionDateLoading, data: transactiondatesList } = useQuery(
    'TransactionDate',
    () =>
      axiosInstance(`/admin/rules`, {
        params: {
          category: 5,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name[locale],
          };
        });
      }),
    { retryDelay: 300, retry: 3 }
  );

  const transactionDateOptions = useMemo(
    () => createSelectOptions(transactiondatesList),
    [transactiondatesList]
  );

  /* ---------------------------- Tax Registration ---------------------------- */
  const { isLoading: TaxRegistrationLoading, data: TaxRegistrationsList } = useQuery(
    'TaxRegistration',
    () =>
      axiosInstance(`/admin/rules`, {
        params: {
          category: 6,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name[locale],
          };
        });
      }),
    { retryDelay: 300, retry: 3 }
  );

  const TaxRegistrationOptions = useMemo(
    () => createSelectOptions(TaxRegistrationsList),
    [TaxRegistrationsList]
  );

  const vatRateItem: IVatRate = useSelector<RootState>((state) => {
    return state.SYS_vat_rates.vat_rate;
  }, shallowEqual) as IVatRate;
  /* -------------------------------- On Init -------------------------------- */

  useEffect(() => {
    if (vatRateItem?.id && mode !== 'create') {
      setFieldValue(
        'country',
        vatRateItem.country
          ? countires
              ?.filter(
                (countryItem: any) =>
                  countryItem.iso.toLowerCase() === vatRateItem.country.toLowerCase()
              )
              .map((countryItem: any) => ({
                value: countryItem.iso,
                label: countryItem?.emoji + ' ' + countryItem?.name?.[locale],
              }))
          : []
      );
      setFieldValue('code', vatRateItem?.code);
      setFieldValue('name_ar', vatRateItem?.name['ar'] ? vatRateItem?.name['ar'] : '');
      setFieldValue('name_en', vatRateItem?.name['en'] ? vatRateItem?.name['en'] : '');
      setFieldValue(
        'description_ar',
        vatRateItem.description?.ar ? vatRateItem.description?.ar : ''
      );
      setFieldValue(
        'description_en',
        vatRateItem.description?.en ? vatRateItem.description?.en : ''
      );
      setFieldValue(
        'type',
        vatRateItem.type
          ? accountTypesOptionsfullTypes?.filter(
              (accountType) => accountType.value === vatRateItem.type
            )[0]
          : {}
      );

      if (vatRateItem.type === 1 || vatRateItem.type === 3) {
        setFieldValue(
          'code_type',
          vatRateItem.code_type_index || vatRateItem.code_type_index === 0
            ? incomeCodeTypesOptions?.filter(
                (codeTypeItem) => codeTypeItem.value === +vatRateItem.code_type_index
              )[0]
            : {}
        );
      } else if (vatRateItem.type === 2 || vatRateItem.type === 4) {
        setFieldValue(
          'code_type',
          vatRateItem.code_type_index || vatRateItem.code_type_index === 0
            ? expenditureCodeTypesOptions?.filter(
                (codeTypeItem) => codeTypeItem.value === +vatRateItem.code_type_index
              )[0]
            : {}
        );
      }
      setFieldValue(
        'transaction_type',
        vatRateItem.transaction_type
          ? transactionTypesOptions?.filter(
              (transactionTypeItem) => transactionTypeItem.value === vatRateItem.transaction_type
            )[0]
          : {}
      );

      setFieldValue(
        'tax_ability',
        vatRateItem.tax_ability
          ? taxAbilityTypesOptions?.filter(
              (taxAbilityItem) => taxAbilityItem.value === vatRateItem.tax_ability
            )[0]
          : {}
      );

      const basic_categories_mapped = vatRateItem?.categories?.map((categoryItem: any) => {
        return {
          ...categoryItem,
          id: categoryItem?.id,
          category_type:
            categoryItem?.is_basic === 1 ? [categoryTypesOptions[0]] : [categoryTypesOptions[1]],
          category_error_message_ar:
            categoryItem?.is_basic === 1
              ? categoryItem?.info_msg?.ar
              : categoryItem?.warning_msg?.ar,
          category_error_message_en:
            categoryItem?.is_basic === 1
              ? categoryItem?.info_msg?.en
              : categoryItem?.warning_msg?.en,
          category: [
            {
              value: categoryItem?.id,
              label: categoryItem?.name,
            },
          ],
        };
      });

      setFieldValue('basic_categories', basic_categories_mapped);

      setFieldValue('tax_rate', vatRateItem.tax_rate.replace(/,/g, ''));

      if (vatRateItem.rules.length > 0) {
        vatRateItem.rules.forEach((ruleItem: any) => {
          if (ruleItem.category === 1) {
            setFieldValue(
              'code_expiration_date',
              codeExpirationOptions?.filter(
                (codeExpirationItem) => codeExpirationItem.value === ruleItem.id
              )
            );
          }
          if (ruleItem.category === 2) {
            setFieldValue(
              'code_amount_range',
              amountRangeOptions?.filter((amountRangeItem) => amountRangeItem.value === ruleItem.id)
            );
          }
          if (ruleItem.category === 3) {
            setFieldValue(
              'third_party_nationalities',
              thirdPartyNationalitiesOptions?.filter(
                (thirdPartyNationality) => thirdPartyNationality.value === ruleItem.id
              )
            );
          }
          if (ruleItem.category === 4) {
            setFieldValue(
              'third_party_type',
              thirdPartyTypesOptions?.filter(
                (thirdPartyType) => thirdPartyType.value === ruleItem.id
              )
            );
          }
          if (ruleItem.category === 5) {
            setFieldValue(
              'transaction_date',
              transactionDateOptions?.filter(
                (transactionDateItem) => transactionDateItem.value === ruleItem.id
              )
            );
          }
          if (ruleItem.category === 6) {
            setFieldValue(
              'tax_registration',
              TaxRegistrationOptions?.filter(
                (TaxRegistrationItem) => TaxRegistrationItem.value === ruleItem.id
              )
            );
          }
        });
      }
    }
  }, [
    params?.vatRateId,
    vatRateItem?.id,
    expenditureCodeTypesOptions,
    incomeCodeTypesOptions,
    accountTypesOptionsfullTypes,
    codeExpirationOptions,
    amountRangeOptions,
    thirdPartyNationalitiesOptions,
    thirdPartyTypesOptions,
    transactionDateOptions,
    TaxRegistrationOptions,
  ]);

  useEffect(() => {
    if (params?.vatRateId && mode !== 'create') {
      getCurrentVatRate(params?.vatRateId);
    }
  }, [params?.vatRateId]);

  useEffect(() => {
    if (formik.values.type && mode === 'create') {
      if (formik.values.type.value === 1) {
        setFieldValue('code_type', incomeCodeTypesOptions[0]);
      } else {
        setFieldValue('code_type', expenditureCodeTypesOptions[0]);
      }
    }
  }, [formik.values.type]);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
      dispatch(VatRateRedux.actions.fullFillVatRate({}));
    };
  }, []);

  return (
    <div>
      <div className='content-wrapper bg-white  brbs-radius-20 blbs-radius-20 '>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5'>
          <FormikProvider value={formik}>
            <Form id='create_template' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              {displayRequiredSection === 1 && (
                <div className='p-10 ' style={{ width: '100%' }}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id: 'Code name in AR',
                        })}
                        required
                        autoComplete='off'
                        labelId={formatMessage({
                          id: 'Code name in AR',
                        })}
                        name={'name_ar'}
                        type={'text'}
                        formik={formik}
                        disabled={mode === 'view'}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id: 'Code name in EN',
                        })}
                        required
                        autoComplete='off'
                        labelId={formatMessage({
                          id: 'Code name in EN',
                        })}
                        name={'name_en'}
                        type={'text'}
                        formik={formik}
                        disabled={mode === 'view'}
                      />
                    </div>
                    {/*  ------------------------ Vat rate country -----------------------  */}
                    <div className='col-md-6'>
                      <label
                        className={clsx('form-label fs-6 my-1 fw-bolder text-dark required')}
                        htmlFor='country-select'
                      >
                        {$t('Country')}
                      </label>
                      <Select
                        placeholder={$t('Country')}
                        className='react-select smaller'
                        id='country-select'
                        onChange={(value) => {
                          formik.setFieldValue('country', value);
                        }}
                        options={countires?.map((country: any) => ({
                          value: country.iso,
                          label: country?.emoji + ' ' + country?.name?.[locale],
                        }))}
                        value={formik.values.country.value === '' ? null : formik.values.country}
                        isDisabled={mode === 'view' || mode === 'edit'}
                      />
                    </div>
                    {/*  --------------------- end Vat rate country-----------------------  */}
                    {/*  ------------------------ Vat rate code -----------------------  */}
                    <div className='col-md-6'>
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id: 'Code',
                        })}
                        required
                        autoComplete='off'
                        labelId={formatMessage({
                          id: 'Code',
                        })}
                        name={'code'}
                        type={'text'}
                        formik={formik}
                        disabled={mode === 'view' || mode === 'edit'}
                      />
                    </div>
                    {/*  --------------------- end Vat rate code-----------------------  */}
                    {/*  ------------------------ Vat rate account type -----------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Account type'
                        htmlFor='account_type'
                        isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view' || mode === 'edit'}
                        onChange={(value) => setFieldValue('type', value)}
                        onBlur={() => formik.setFieldTouched('type', true)}
                        options={
                          mode === 'create' ? accountTypesOptions : accountTypesOptionsfullTypes
                        }
                        defaultValue={formik.values.type}
                        value={formik.values.type}
                        className='react-select smaller'
                        id='account_type'
                      />
                    </div>
                    {/*  --------------------- end Vat rate account type -----------------------  */}
                    {/*  ------------------------ Vat rate account type -----------------------  */}
                    <div className='col-md-6 align-self-center mt-6'>
                      {mode === 'create' && (
                        <>
                          {' '}
                          <Input
                            // disabled={mode === 'view'}
                            className={'form-check-input'}
                            type={'checkbox'}
                            name={'return_value'}
                            id='return_value'
                            defaultChecked={false}
                            checked={formik.values.return_value === 0 ? false : true}
                            onChange={(value: any) => {
                              return formik.setFieldValue(
                                `return_value`,
                                value.target.checked ? 1 : 0
                              );
                            }}
                            onBlur={() => formik.setFieldTouched(`return_value`, true)}
                          />{' '}
                          {formik.values.type.value === 1 && (
                            <Label
                              msg='Do you want to add income return code to this code?'
                              htmlFor='return_value'
                            />
                          )}
                          {formik.values.type.value === 2 && (
                            <Label
                              msg='Do you want to add expenditure return code to this code?'
                              htmlFor='return_value'
                            />
                          )}
                        </>
                      )}
                    </div>
                    {/*  --------------------- end Vat rate account type -----------------------  */}

                    {/*  ------------------------ Vat rate code type -----------------------  */}
                    <div className='col-6'>
                      <Label msg='Code type' htmlFor='code_type' isRequired={mode === 'create'} />
                      {(formik.values.type.value === 1 || formik.values.type.value === 3) && (
                        <Select
                          placeholder={$t('Code type')}
                          isDisabled={mode === 'view' || mode === 'edit'}
                          onChange={(value) => setFieldValue('code_type', value)}
                          onBlur={() => formik.setFieldTouched('code_type', true)}
                          options={incomeCodeTypesOptions}
                          defaultValue={
                            formik.values.code_type
                              ? formik.values.code_type
                              : incomeCodeTypesOptions[0]
                          }
                          value={formik.values.code_type}
                          className='react-select smaller'
                          id='code_type'
                        />
                      )}
                      {(formik.values.type.value === 2 || formik.values.type.value === 4) && (
                        <Select
                          placeholder={$t('Code type')}
                          isDisabled={mode === 'view' || mode === 'edit'}
                          onChange={(value) => setFieldValue('code_type', value)}
                          onBlur={() => formik.setFieldTouched('code_type', true)}
                          options={expenditureCodeTypesOptions}
                          defaultValue={
                            formik.values.code_type
                              ? formik.values.code_type
                              : expenditureCodeTypesOptions[0]
                          }
                          value={formik.values.code_type}
                          className='react-select smaller'
                          id='code_type'
                        />
                      )}
                    </div>
                    {/*  --------------------- end Vat rate code type -----------------------  */}
                    {/*  ---------------------  Vat rate Transaction type -----------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Transaction type'
                        htmlFor='transaction_type'
                        isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view' || mode === 'edit'}
                        onChange={(value) => setFieldValue('transaction_type', value)}
                        onBlur={() => formik.setFieldTouched('transaction_type', true)}
                        options={transactionTypesOptions}
                        defaultValue={formik.values.transaction_type}
                        value={formik.values.transaction_type}
                        className='react-select smaller'
                        id='transaction_type'
                      />
                    </div>
                    {/*  --------------------- end Vat rate Transaction type -----------------------  */}
                    {/*  ------------------------ Vat rate tax ability -----------------------  */}
                    <div className='col-md-6'>
                      <Label
                        msg='Tax ability'
                        htmlFor='tax_ability_id'
                        isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view' || mode === 'edit'}
                        onChange={(value) => setFieldValue('tax_ability', value)}
                        onBlur={() => formik.setFieldTouched('tax_ability', true)}
                        options={taxAbilityTypesOptions}
                        defaultValue={formik.values.tax_ability}
                        value={formik.values.tax_ability}
                        className='react-select smaller'
                        id='tax_ability_id'
                      />
                    </div>
                    {/*  --------------------- end Vat rate ability-----------------------  */}
                    {/*  ------------------------  Vat tax rate  -----------------------  */}
                    <div className='col-md-6'>
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id: 'Tax rate',
                        })}
                        required
                        autoComplete='off'
                        labelId={formatMessage({
                          id: 'Tax rate',
                        })}
                        name={'tax_rate'}
                        type={'text'}
                        formik={formik}
                        disabled={mode === 'view' || mode === 'edit'}
                      />
                    </div>
                    {/*  ------------------------  Vat tax rate  -----------------------  */}

                    {/*  ------------------------ Vat rate description en/ar -----------------------  */}
                    {locales.map((locale) => (
                      <div className='col-md-6'>
                        <Label
                          msg={formatMessage({
                            id: 'Vat rate description in ' + locale.toUpperCase(),
                          })}
                          htmlFor={'Vat rate description in ' + locale.toUpperCase()}
                          isRequired
                        />
                        <TextArea
                          placeholder={formatMessage({
                            id: 'Vat rate description in ' + locale.toUpperCase(),
                          })}
                          autoComplete='off'
                          // labelId={formatMessage({
                          //   id: 'Vat rate description in ' + locale.toUpperCase(),
                          // })}
                          disabled={mode === 'view' || mode === 'edit'}
                          name={'description_' + locale}
                          type='text'
                          formik={formik}
                          dir={locale === 'ar' ? 'rtl' : 'ltr'}
                          rows='6'
                        />
                      </div>
                    ))}
                    {/*  --------------------- end Vat rate description en/ar-----------------------  */}
                  </div>
                </div>
              )}
              {displayRequiredSection === 2 && (
                <div className='p-10 ' style={{ width: '100%' }}>
                  <div className='row'>
                    {/*  ------------------------ Vat rate Code expiration date -----------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Code expiration date'
                        htmlFor='code_expiration_date'
                        // isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view'}
                        onChange={(value) => setFieldValue('code_expiration_date', [value])}
                        onBlur={() => formik.setFieldTouched('code_expiration_date', true)}
                        placeholder={formatMessage({
                          id: 'Code expiration date',
                        })}
                        defaultValue={formik.values.code_expiration_date}
                        className='react-select smaller'
                        id='code_expiration_date'
                        options={codeExpirationOptions.filter(
                          (option) =>
                            formik.values.code_expiration_date?.[0]?.value !== option?.value
                        )}
                        value={formik.values.code_expiration_date}
                        isLoading={codeExpirationLoading}
                        // isMulti
                        isClearable
                      />
                    </div>
                    {/*  --------------------- end Vat rate Code expiration date -----------------------  */}
                    {/*  ------------------------ Vat rate Code amount range -----------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Code amount range'
                        htmlFor='code_amount_range'
                        // isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view'}
                        onChange={(value) => setFieldValue('code_amount_range', [value])}
                        onBlur={() => formik.setFieldTouched('code_amount_range', true)}
                        placeholder={formatMessage({
                          id: 'Code amount range',
                        })}
                        defaultValue={formik.values.code_amount_range}
                        className='react-select smaller'
                        id='code_amount_range'
                        options={amountRangeOptions.filter(
                          (option) => formik.values.code_amount_range?.[0]?.value !== option.value
                        )}
                        value={formik.values.code_amount_range}
                        isLoading={amountRangeLoading}
                        // isMulti
                        isClearable
                      />
                    </div>
                    {/*  --------------------- end Vat rate Code amount range -----------------------  */}
                    {/*  ------------------------ Vat rate Third party nationality -----------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Third party nationality'
                        htmlFor='third_party_nationalities'
                        // isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view'}
                        onChange={(value) => setFieldValue('third_party_nationalities', [value])}
                        onBlur={() => formik.setFieldTouched('third_party_nationalities', true)}
                        placeholder={formatMessage({
                          id: 'Third party nationality',
                        })}
                        defaultValue={formik.values.third_party_nationalities}
                        className='react-select smaller'
                        id='third_party_nationalities'
                        options={thirdPartyNationalitiesOptions.filter(
                          (option) =>
                            formik.values.third_party_nationalities?.[0]?.value !== option.value
                        )}
                        value={formik.values.third_party_nationalities}
                        isLoading={thirdPartyNationalitiesLoading}
                        // isMulti
                        isClearable
                      />
                    </div>
                    {/*  --------------------- end Vat rate Third party nationality -----------------------  */}
                    {/*  ------------------------ Vat rate Third party type -----------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Third party type'
                        htmlFor='third_party_type'
                        // isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view'}
                        onChange={(value) => setFieldValue('third_party_type', [value])}
                        onBlur={() => formik.setFieldTouched('third_party_type', true)}
                        placeholder={formatMessage({
                          id: 'Third party type',
                        })}
                        defaultValue={formik.values.third_party_type}
                        className='react-select smaller'
                        id='third_party_type'
                        options={thirdPartyTypesOptions.filter(
                          (option) => formik.values.third_party_type?.[0]?.value !== option.value
                        )}
                        value={formik.values.third_party_type}
                        isLoading={thirdPartyTypesLoading}
                        // isMulti
                        isClearable
                      />
                    </div>
                    {/*  --------------------- end Vat rate Third party type -----------------------  */}
                    {/*  ------------------------ Vat rate Transaction date type -----------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Transaction date'
                        htmlFor='transaction_date'
                        // isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view'}
                        onChange={(value) => setFieldValue('transaction_date', [value])}
                        onBlur={() => formik.setFieldTouched('transaction_date', true)}
                        placeholder={formatMessage({
                          id: 'Transaction date',
                        })}
                        defaultValue={formik.values.transaction_date}
                        className='react-select smaller'
                        id='transaction_date'
                        options={transactionDateOptions.filter(
                          (option) => formik.values.transaction_date?.[0]?.value !== option.value
                        )}
                        value={formik.values.transaction_date}
                        isLoading={transactionDateLoading}
                        // isMulti
                        isClearable
                      />
                    </div>
                    {/*  --------------------- end Vat rate Transaction date type -----------------------  */}
                    {/*  ---------------------------- Tax Registration ---------------------------  */}
                    <div className='col-6'>
                      <Label
                        msg='Tax Registration'
                        htmlFor='tax_registration'
                        // isRequired={mode === 'create'}
                      />
                      <Select
                        isDisabled={mode === 'view'}
                        onChange={(value) => setFieldValue('tax_registration', [value])}
                        onBlur={() => formik.setFieldTouched('tax_registration', true)}
                        placeholder={formatMessage({
                          id: 'Tax Registration',
                        })}
                        defaultValue={formik.values.tax_registration}
                        className='react-select smaller'
                        id='tax_registration'
                        options={TaxRegistrationOptions.filter(
                          (option) => formik.values.tax_registration?.[0]?.value !== option.value
                        )}
                        value={formik.values.tax_registration}
                        isLoading={TaxRegistrationLoading}
                        // isMulti
                        isClearable
                      />
                    </div>
                    {/*  ---------------------------- Tax Registration ---------------------------  */}
                  </div>
                </div>
              )}
              {displayRequiredSection === 3 && (
                <>
                  <div className='row p-10'>
                    {/*begin::Table wrapper*/}
                    <div className='table-wrapper card'>
                      <div className='card-body '>
                        <div className={`${width < 900 ? 'table-responsive' : ''} mb-10`}>
                          {/*begin::Table*/}
                          <table className='table g-5 gs-0 mb-0 fw-bolder text-gray-700 pb-5 pb-xl-8 '>
                            {/*begin::Table head*/}
                            <thead>
                              <tr className='border-bottom fs-7 fw-bolder text-gray-700 text-uppercase'>
                                {headerItems.map((item) => (
                                  <th
                                    key={item.id}
                                    className={
                                      width <= 768
                                        ? `min-w-${item?.minWidth || 100}px max-w-${
                                            item?.maxWidth || 100
                                          }px  w-${item?.width || 100}px`
                                        : ` max-w-${item?.maxWidth || 100}px  w-${
                                            item?.width || 100
                                          }px`
                                    }
                                    // style={{
                                    //   minWidth: `${item?.minWidth}px`,
                                    //   maxWidth: `${item?.maxWidth}px`,
                                    //   width: `${item?.width || 100}px`,
                                    // }}
                                  >
                                    {formatMessage({ id: item.id })}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                              {/*begin::Field array*/}
                              <FieldArray name='basic_categories'>
                                {({ push, remove }) => {
                                  const error: BasicCategoriesItemsErrors[] = formik?.errors
                                    ?.basic_categories as BasicCategoriesItemsErrors[];
                                  const itemsValues = formik?.values?.basic_categories;
                                  const itemsTouched: any = formik.touched
                                    .basic_categories as BasicCategoriesItemsTouched[];
                                  return (
                                    <>
                                      {itemsValues?.map((item: any, i: any, arr: any) => {
                                        return (
                                          <tr
                                            key={JSON.stringify(i)}
                                            className='border-bottom border-bottom-dashed'
                                          >
                                            <td className='pe-7'>
                                              {/* <Select
                                                onChange={(value) =>
                                                  setFieldValue(
                                                    `basic_categories[${i}]['category']`,
                                                    [value]
                                                  )
                                                }
                                                onBlur={() =>
                                                  formik.setFieldTouched(
                                                    `basic_categories[${i}]['category']`,
                                                    true
                                                  )
                                                }
                                                options={CategoriesOptions?.filter(
                                                  (option) =>
                                                    formik.values?.basic_categories?.[i][
                                                      'category'
                                                    ]?.[0]?.value !== option.value
                                                )}
                                                value={
                                                  formik.values?.basic_categories?.[i]['category']
                                                }
                                                isLoading={CategoriesLoading}
                                                placeholder={formatMessage({
                                                  id: 'Categories',
                                                })}
                                                // isMulti
                                                isDisabled={mode === 'view'}
                                                // className='react-select smaller'
                                                className='form-control form-control-md p-0 mb-2'
                                                 
                                                menuPlacement='auto'
                                                styles={{
                                                  menu: (base) => ({
                                                    ...base,
                                                    marginBottom: 2,
                                                    paddingBottom: 2,
                                                    zIndex: 3,
                                                  }),
                                                  menuPortal: (base: any) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                }}
                                                id={`basic_categories[${i}]['category']`}
                                              />
                                              {itemsTouched?.[i]?.category && error?.[i]?.category && (
                                                <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                  <span role='alert' className='text-danger'>
                                                    {error?.[i]?.category}
                                                  </span>
                                                </div>
                                              )} */}
                                              <AsyncSelect
                                                key={`basic_categories[${i}]['category']` + i}
                                                id={`basic_categories[${i}]['category']`}
                                                onChange={(value) =>
                                                  formik.setFieldValue(
                                                    `basic_categories[${i}]['category']`,
                                                    [value]
                                                  )
                                                }
                                                onBlur={() =>
                                                  formik.setFieldTouched(
                                                    `basic_categories[${i}]['category']`,
                                                    true
                                                  )
                                                }
                                                onFocus={() => {
                                                  if (formik.values?.type?.value === 1) {
                                                    onFocusLoadSalesCategoriesOptions(i);
                                                  } else {
                                                    onFocusLoadPurchasesCategoriesOptions(i);
                                                  }
                                                }}
                                                value={
                                                  formik.values?.basic_categories?.[i]['category']
                                                }
                                                placeholder={formatMessage({
                                                  id: 'Categories',
                                                })}
                                                isDisabled={mode === 'view'}
                                                className='form-control form-control-md p-0 mb-2'
                                                menuPlacement='auto'
                                                loadOptions={
                                                  formik.values?.type?.value === 1
                                                    ? loadSalesCategoriesOptions
                                                    : loadPurchasesCategoriesOptions
                                                }
                                                defaultOptions={categoriesOptions}
                                                isClearable
                                                noOptionsMessage={() => {
                                                  return (
                                                    <span role='alert' className='text-danger'>
                                                      {formatMessage({
                                                        id: 'No options found',
                                                      })}
                                                    </span>
                                                  );
                                                }}
                                                loadingMessage={() => (
                                                  <div className='d-flex justify-content-center'>
                                                    <div
                                                      className='spinner-border text-primary'
                                                      role='status'
                                                    >
                                                      <span className='visually-hidden'>
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                                styles={{
                                                  menu: (base) => ({
                                                    ...base,
                                                    marginBottom: 2,
                                                    paddingBottom: 2,
                                                    zIndex: 3,
                                                  }),
                                                  menuPortal: (base: any) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                  noOptionsMessage: (base: any) => ({
                                                    ...base,
                                                    color: 'red',
                                                  }),
                                                }}
                                                components={{
                                                  Option: ({
                                                    children,
                                                    ...props
                                                  }: OptionProps<any, false>) => {
                                                    return (
                                                      <components.Option {...props}>
                                                        <div
                                                          className='d-flex justify-content-between'
                                                          title={props.data?.label}
                                                        >
                                                          <span className=''>
                                                            {props.data?.label}
                                                          </span>
                                                        </div>
                                                      </components.Option>
                                                    );
                                                  },
                                                }}
                                              />

                                              {itemsTouched?.[i]?.category && error?.[i]?.category && (
                                                <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                  <span role='alert' className='text-danger'>
                                                    {error?.[i]?.category}
                                                  </span>
                                                </div>
                                              )}
                                            </td>
                                            <td className='ps-0'>
                                              <Select
                                                onChange={(value) =>
                                                  setFieldValue(
                                                    `basic_categories[${i}]['category_type']`,
                                                    [value]
                                                  )
                                                }
                                                onBlur={() =>
                                                  formik.setFieldTouched(
                                                    `basic_categories[${i}]['category_type']`,
                                                    true
                                                  )
                                                }
                                                options={categoryTypesOptions?.filter(
                                                  (option) =>
                                                    formik.values?.basic_categories?.[i][
                                                      'category_type'
                                                    ]?.[0]?.value !== option.value
                                                )}
                                                value={
                                                  formik.values?.basic_categories?.[i][
                                                    'category_type'
                                                  ]
                                                }
                                                placeholder={formatMessage({
                                                  id: 'Categories type',
                                                })}
                                                // isMulti
                                                isDisabled={mode === 'view'}
                                                // className='react-select smaller'
                                                className='form-control form-control-md p-0 mb-2'
                                                menuPlacement='auto'
                                                styles={{
                                                  menu: (base) => ({
                                                    ...base,
                                                    marginBottom: 2,
                                                    paddingBottom: 2,
                                                    zIndex: 3,
                                                  }),
                                                  menuPortal: (base: any) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                }}
                                                id={`basic_categories[${i}]['category_type']`}
                                              />
                                              {itemsTouched?.[i]?.category_type &&
                                                error?.[i]?.category_type && (
                                                  <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                    <span role='alert' className='text-danger'>
                                                      {error?.[i]?.category_type}
                                                    </span>
                                                  </div>
                                                )}

                                              {formik.values?.basic_categories?.[i][
                                                'category_type'
                                              ]?.[0]?.value === 1 && (
                                                <>
                                                  <Input
                                                    autoComplete='off'
                                                    dir={locale === 'ar' ? 'rtl' : 'ltr'}
                                                    setTouchedOnInput
                                                    disabled={mode === 'view'}
                                                    className='form-control form-control-md mb-2'
                                                    formik={formik}
                                                    name={`basic_categories[${i}]['category_error_message_ar']`}
                                                    type='text'
                                                    error={
                                                      error?.length &&
                                                      error?.[i] &&
                                                      error?.[i]?.category_error_message_ar
                                                    }
                                                    touched={
                                                      itemsTouched?.[i]?.[
                                                        `category_error_message_ar`
                                                      ]
                                                    }
                                                    id={`category_error_message_ar ${i}`}
                                                    placeholder={formatMessage({
                                                      id: 'Info message in AR',
                                                    })}
                                                  />

                                                  <Input
                                                    autoComplete='off'
                                                    dir={locale === 'ar' ? 'rtl' : 'ltr'}
                                                    setTouchedOnInput
                                                    disabled={mode === 'view'}
                                                    className='form-control form-control-md mb-2'
                                                    formik={formik}
                                                    name={`basic_categories[${i}]['category_error_message_en']`}
                                                    type='text'
                                                    error={
                                                      error?.length &&
                                                      error?.[i] &&
                                                      error?.[i]?.category_error_message_en
                                                    }
                                                    touched={
                                                      itemsTouched?.[i]?.[
                                                        `category_error_message_en`
                                                      ]
                                                    }
                                                    id={`category_error_message_en ${i}`}
                                                    placeholder={formatMessage({
                                                      id: 'Info message in EN',
                                                    })}
                                                  />
                                                </>
                                              )}
                                              {formik.values?.basic_categories?.[i][
                                                'category_type'
                                              ]?.[0]?.value === 2 && (
                                                <>
                                                  <Input
                                                    autoComplete='off'
                                                    dir={locale === 'ar' ? 'rtl' : 'ltr'}
                                                    setTouchedOnInput
                                                    disabled={mode === 'view'}
                                                    className='form-control form-control-md mb-2'
                                                    formik={formik}
                                                    name={`basic_categories[${i}]['category_error_message_ar']`}
                                                    type='text'
                                                    error={
                                                      error?.length &&
                                                      error?.[i] &&
                                                      error?.[i]?.category_error_message_ar
                                                    }
                                                    touched={
                                                      itemsTouched?.[i]?.[
                                                        `category_error_message_ar`
                                                      ]
                                                    }
                                                    id={`category_error_message_ar ${i}`}
                                                    placeholder={formatMessage({
                                                      id: 'Error message in AR',
                                                    })}
                                                  />

                                                  <Input
                                                    autoComplete='off'
                                                    dir={locale === 'ar' ? 'rtl' : 'ltr'}
                                                    setTouchedOnInput
                                                    disabled={mode === 'view'}
                                                    className='form-control form-control-md mb-2'
                                                    formik={formik}
                                                    name={`basic_categories[${i}]['category_error_message_en']`}
                                                    type='text'
                                                    error={
                                                      error?.length &&
                                                      error?.[i] &&
                                                      error?.[i]?.category_error_message_en
                                                    }
                                                    touched={
                                                      itemsTouched?.[i]?.[
                                                        `category_error_message_en`
                                                      ]
                                                    }
                                                    id={`category_error_message_en ${i}`}
                                                    placeholder={formatMessage({
                                                      id: 'Error message in EN',
                                                    })}
                                                  />
                                                </>
                                              )}
                                            </td>

                                            {mode !== 'view' && i > 0 && (
                                              <td className='pt-5 text-center'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm btn-icon btn-active-color-primary'
                                                  title={formatMessage({
                                                    id: 'Remove item',
                                                  })}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    removeItem(remove, i);
                                                  }}
                                                >
                                                  {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                                  <span className='svg-icon svg-icon-3'>
                                                    <KTSVG
                                                      path='/media/icons/duotune/general/gen027.svg'
                                                      className='svg-icon-1 svg-icon-gray-600'
                                                    />
                                                  </span>
                                                  {/*end::Svg Icon*/}
                                                </button>
                                              </td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                      {mode !== 'view' && (
                                        <tr className='border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700'>
                                          <th className='text-primary'>
                                            <button
                                              className='btn btn-primary py-1'
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                addItem(push);
                                              }}
                                            >
                                              {formatMessage({
                                                id: 'Add Item',
                                              })}
                                            </button>
                                          </th>
                                        </tr>
                                      )}
                                    </>
                                  );
                                }}
                              </FieldArray>
                              {typeof formik?.errors?.basic_categories === 'string' && (
                                <ErrorMessage name='basic_categories' formik={formik} />
                              )}
                              {/*end::Field array*/}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className='p-10  mt-0' style={{ width: '100%' }}>
                <div className='row'>
                  {mode === 'create' && (
                    <div className='row'>
                      <SeparatorLines lines={3} />
                      <div className='col-md-6 mt-5  my-5'>
                        <Label
                          msg={formatMessage({
                            id: 'Vat rate/s which will be created.',
                          })}
                          htmlFor='created_vat_rate'
                        />
                      </div>
                      {formik.values.country.value &&
                        formik.values.type.value &&
                        formik.values.code && (
                          <div className='col-md-6  mt-5' id='created_vat_rate'>
                            {`${formik.values.country.value}_${
                              formik.values.type.value === 1 ? 'SA' : 'PU'
                            }_${formik.values.code}`}
                            {formik.values.return_value === 1 &&
                              ` / ${formik.values.country.value}_${
                                formik.values.type.value === 1 ? 'SA' : 'PU'
                              }_${formik.values.code}${
                                formik.values.return_value === 0 ? '' : '_A'
                              }`}
                          </div>
                        )}
                    </div>
                  )}

                  {/* Save and Cancel Buttons */}
                  {mode !== 'view' && (
                    <>
                      <SaveCancelBtnsGroup
                        saveBtnLabel='save'
                        formik={formik}
                        loading={loading}
                        cancelOnClick={() => {
                          history.push(`/admin/vat-rates`);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const ConfigNewVatRateCreate: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <ConfigNewVatRate mode='create' />
    </div>
  );
};

const ConfigNewVatRateEdit: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <ConfigNewVatRate mode='edit' />
    </div>
  );
};

const ConfigNewVatRateView: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <ConfigNewVatRate mode='view' />
    </div>
  );
};

export { ConfigNewVatRateCreate, ConfigNewVatRateEdit, ConfigNewVatRateView };
