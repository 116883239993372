/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo, Fragment } from 'react'; //useRef,
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams, useLocation } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

import { toast } from 'react-toastify';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';

import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { GAEvents, PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CompanyModel } from '../companies/CompanyModel';

import { SeparatorLines } from '../../shared/Lines';
import { Input } from '../../shared/Input';
import { Label } from '../../shared/Label';
// import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { CommonTable } from '../../shared/tables/Table';
import { formatDate } from '../../shared/tables/classes/DateViewer';
import { ReportsTypes } from './types/InvoicesReportsModel'; //DocumentDirection,

import * as Yup from 'yup';

import { TableData, TableSelection, TableStatus } from '../../shared/tables/classes'; // DateViewer, TableActions,
import { reportStatusColorsMap } from '../../../_metronic/constants/colors';

import { axiosInstance } from '../../../network/apis';

// import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';

import { initialConfigPayload } from './helpers/reportsConfigHelpers';
import * as InvoicesReportsRedux from './store/InvoicesReportsRedux';

import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { TextArea } from '../../shared/textarea';
import { commonValidations } from '../../../_metronic/constants/GeneralValidations';
import { MultipleFilesUploader } from '../../pages/Companies/CompanyDetails/components/multipleFilesUploader';
import { PreviewPDF } from '../../shared/tables/actions/PreviewPDF';
import { backendBaseUrl } from '../../../_metronic/constants/paths';

type Props = {
  mode: 'edit' | 'view';
};

const TABLE_NAME = 'transactons_list';

const headerItems = [
  // { id: 'Code', maxWidth: 240, width: 230 },
  { id: 'Code type', maxWidth: 240, width: 230 },
  { id: 'Amount', maxWidth: 240, width: 230 },
  { id: 'Edited amount', maxWidth: 240, width: 230 },
  { id: 'Tax rate', maxWidth: 150, width: 150 },
  { id: 'Tax amount', maxWidth: 240, width: 230 },
];

const allowedFiles = [
  'application/pdf',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
export const TransactionsTable: FC<any> = ({ transactions, title }) => {
  const { locale } = useIntl();

  /* -------------------------- Bring Data from store ------------------------- */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const currentReport: any = useSelector<RootState>((state) => {
    return state.USER_invoices_reports.currentInvoicesReport;
  }, shallowEqual) as any;

  const transactionsList: any = transactions;

  const rowItems = transactionsList?.map((transaction: any, index: any) => {
    return [
      new TableSelection({
        display: false,
        id: index,
        tableName: TABLE_NAME,
      }),
      // new TableData({
      //   text: `${transaction?.code}`,
      //   // error: sale?.errors?.vat_rate,
      // }),
      new TableData({
        text: `${transaction?.description ? transaction?.description[locale] : null}`,
        isLink:
          `${
            currentReport.status === 'Canceled' || currentReport.status === 'ملغي' ? false : true
          }` === 'true'
            ? true
            : false,
        to: `/c/${companyDetails.slug}/invoices-tax-module/reports/${currentReport?.id}/invoicestype/${title}/transactions/${transaction?.code_type}`,
        show_errors_icon: transaction?.has_errors,
        // error: sale?.errors?.vat_rate,
      }),
      new TableData({
        text: `${parseFloat(transaction?.amount).toFixed(2)}`,
      }),
      new TableData({
        text: `${parseFloat(transaction?.vat_calculations).toFixed(2)}`,
        // error: sale?.errors?.vat_rate,
      }),
      // new TableInput({
      //   id: index,
      //   tableName: TABLE_NAME,
      //   text: `${transaction?.adjustment_amount ? transaction?.adjustment_amount : 0}`,
      // }),
      new TableData({
        text: `${transaction.tax_rate + ' %'}`,
      }),
      new TableData({
        text: `${parseFloat(transaction.calculated_vat).toFixed(2)}`,
      }),
    ];
  });

  return (
    <>
      <div className='table-wrapper'>
        {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5'> */}
        {/* {userCan('delete-template') && (
          <DeleteItem
            isButton
            messageId={'Are you sure you want to delete this template?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/templates`}
            payload={deleteItemId}
            successMessage={'Template has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )} */}
        {/* </div> */}
        <CommonTable
          tableName={`${title}_${TABLE_NAME}`}
          initPerPage={100}
          headerWithCheckbox={false}
          allDisplayedIds={transactionsList?.map((transaction: any, index: any) => index) || []} //transactionsList?.data?.map((sales) => sales.id) ||
          meta={transactionsList}
          headerItems={headerItems}
          rowItems={rowItems}
          hideFooter={true}
          showTotal={true}
          totalIndexArray={[1, 2, 4]}
        />
      </div>
    </>
  );
};

export const ApproveInvoicesReportWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [approved_multiple_document, set_approved_multiple_document] = useState<File[]>([]);
  const [approved_multiple_document_maxFiles] = useState<number>(2);
  const [multiple_draft_document, set_multiple_draft_document] = useState<File[]>([]);
  const [multiple_draft_document_maxFiles] = useState<number>(5);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { userCan } = usePermissions();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  // const [photo, setPhoto] = useState<string | Blob>('');

  // const [attachment, setAttachment] = useState<any>('');

  const [loading, setLoading] = useState(false);
  const [isUploadDone, setIsUploadDone] = useState(false);
  // const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];
  const [showModal, setShowModal] = useState(false);

  /* ----------------------------- Getting Reports ----------------------------- */

  const createReports = async (values: any, resetForm: any) => {
    setLoading(true);
    setIsUploadDone(false);
    delete values['current_total_tax_amount'];
    delete values['report_name_ar'];
    delete values['report_name_en'];
    delete values['total_tax_amount'];

    // const table = tables[TABLE_NAME];
    // let requestObject = {
    //   ...values,
    //   data: {
    //     period_calculations: {
    //       less_than_5000: 0,
    //       additional_vat: 0,
    //     },
    //   },
    // };

    // requestObject.data.period_calculations.less_than_5000 = values.less_than_5000;
    // requestObject.data.period_calculations.additional_vat = values.additional_vat;

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    if (formik.values?.custom_rules_errors_approve === 1) {
      payload['approve'] = '1';
    }

    // delete payload['additional_vat'];
    // delete payload['less_than_5000'];
    delete payload['custom_rules_errors_approve'];

    const formData = serialize(payload, { indices: true });

    try {
      if (mode === 'edit') {
        formData.append('_method', 'PUT');
        if (multiple_draft_document.length > 0) {
          const payload: any = {};
          setIsUploadFile(true);
          payload['draft_documents'] = multiple_draft_document.filter((item: any) => !item?.url);
          const formData = serialize(payload);
          axiosInstance
            .post(`${companyDetails.id}/reports/${currentReport.id}/docs`, formData)
            .then((response) => {
              console.log(response.data);
              setIsUploadFile(false);
              setIsUploadDone(true);
            })
            .catch((error) => {
              setIsUploadFile(false);

              const data = error.response.data.errors;

              const merged: any = Object.values(data).reduce(
                (accumulator: any, currentValue) => accumulator.concat(currentValue),
                []
              );
              setIsUploadDone(false);
              merged.map((item: any) =>
                toast.error(item, {
                  toastId: 'uploadDraftDocuments',
                })
              );
            });
        }

        if (
          (isUploadDone && approved_multiple_document.length > 0) ||
          approved_multiple_document.length === 0
        ) {
          await axiosInstance.post(
            `${companyDetails.id}/reports/vat/${currentReport.id}`,
            formData
          );
          resetForm();
          if (values.status === 3) {
            toast.success(formatMessage({ id: 'Report has been canceled successfully!' }));
          } else if (values.status === 1) {
            toast.success(formatMessage({ id: 'Report has been saved successfully!' }));
          } else {
            toast.success(formatMessage({ id: 'Report has been approved successfully!' }));
            // add GA custom event
            GAEvents.approveReport();
          }
          history.push(`/c/${companyDetails.slug}/invoices-tax-module/reports`);
        }
      }
    } catch (err: any) {
      DisplayErrors(err);
      if (err && err?.response?.status === 422) {
        getCurrentReport(params?.reportsId).then(() => {
          formik.setTouched({});
        });
      }
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      status: ReportsTypes.Approved,
      less_than_5000: '',
      additional_vat: '',
      total_tax_amount: '',
      custom_rules_errors_approve: 0,
    },
    validationSchema: useMemo(
      () =>
        Yup.object().shape({
          less_than_5000: Yup.number()
            .typeError($t('Only number are allowed!'))
            .min(
              -5000,
              formatMessage({ id: 'The Minimum number is {minNumber}' }, { minNumber: -5000 })
            )
            .max(
              +5000,
              formatMessage({ id: 'The Maximum number is {maxNumber}' }, { maxNumber: 5000 })
            )
            .test(
              'len',
              formatMessage(
                { id: 'The Maximum characters is {maxCharacters}' },
                { maxCharacters: 8 }
              ),
              (val: any) => !val || (val && val.toString().length <= 8)
            ),
          additional_vat: Yup.number().typeError($t('Only number are allowed!')),
          notes: Yup.string()
            .min(
              commonValidations.reporterNotesMinimum,
              $t('Please enter at least {minCharacters} characters', {
                minCharacters: commonValidations.reporterNotesMinimum,
              })
            )
            .max(
              commonValidations.reporterNotesMaximum,
              $t('The Maximum characters is {maxCharacters}', {
                maxCharacters: commonValidations.reporterNotesMaximum,
              })
            )
            .nullable(),
        }),
      [formatMessage]
    ),
    onSubmit: (values, { resetForm }) => createReports(values, resetForm),
  });
  const { setFieldValue } = formik; //,values

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  // const uploadDraftDocuments = useCallback(() => {
  //   console.log('draft_documents', multiple_draft_document);
  //   const payload: any = {};
  //   setIsUploadFile(true);
  //   payload['draft_documents'] = multiple_draft_document.filter((item: any) => !item?.url);
  //   const formData = serialize(payload);
  //   axiosInstance
  //     .post(`${companyDetails.id}/reports/${currentReport.id}/docs`, formData)
  //     .then((response) => {
  //       console.log(response.data);
  //       // toast.success(response.data, {
  //       //   toastId: 'uploadDraftDocuments',
  //       // });
  //       setIsUploadFile(false);
  //     })
  //     .catch((error) => {
  //       setIsUploadFile(false);

  //       const data = error.response.data.errors;

  //       const merged: any = Object.values(data).reduce(
  //         (accumulator: any, currentValue) => accumulator.concat(currentValue),
  //         []
  //       );

  //       merged.map((item: any) => {
  //         toast.error(item, {
  //           toastId: 'uploadDraftDocuments',
  //         });
  //         return false;
  //       });
  //     });
  // }, [multiple_draft_document]);

  const uploadApprovedDocuments = () => {
    console.log('approved_documents', approved_multiple_document);
    const payload: any = {};
    setIsUploadFile(true);
    payload['approved_documents'] = approved_multiple_document.filter((item: any) => !item?.url);
    const formData = serialize(payload);
    axiosInstance
      .post(`${companyDetails.id}/reports/${currentReport.id}/docs`, formData)
      .then((response) => {
        console.log(response?.data?.errors?.approved_documents);
        console.log('approved_documents______1', response?.data);
        toast.success(formatMessage({ id: 'Report has been saved successfully!' }), {
          toastId: 'uploadApprovedDocuments',
        });

        // window.location.reload();
        history.push(`/c/${companyDetails.slug}/invoices-tax-module/reports`);
        setIsUploadFile(false);
      })
      .catch((error) => {
        const data = error.response.data.errors;

        const merged: any = Object.values(data).reduce(
          (accumulator: any, currentValue) => accumulator.concat(currentValue),
          []
        );
        setIsUploadDone(false);
        merged.map((item: any) =>
          toast.error(item, {
            toastId: 'uploadDraftDocuments',
          })
        );

        setIsUploadFile(false);
      });
  };
  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const currentReport: any = useSelector<RootState>((state) => {
    return state.USER_invoices_reports.currentInvoicesReport;
  }, shallowEqual) as any;

  // const badge = new TableData({
  //   text: ``,
  //   error: `${currentReport?.status ? currentReport?.status : ''}`,
  // });

  /* ----------------------- end getting data from store ---------------------- */
  /* -------------------------------- On Init -------------------------------- */
  const getCurrentReport = async (reportsId: any) => {
    try {
      dispatch(
        InvoicesReportsRedux.actions.getCurrentInvoicesReport({
          companyId: companyDetails.id,
          reportId: reportsId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };
  useEffect(() => {
    if (params?.reportsId) {
      getCurrentReport(params?.reportsId);
    }
  }, [params?.reportsId]);

  useEffect(() => {
    if (currentReport?.approved_documents.length > 0) {
      set_approved_multiple_document(currentReport?.approved_documents);
    }
    if (currentReport?.draft_documents.length > 0) {
      set_multiple_draft_document(currentReport?.draft_documents);
    }
    if (currentReport?.id) {
      setFieldValue('report_name_ar', currentReport.name?.ar ? currentReport.name?.ar : '');
      setFieldValue('report_name_en', currentReport.name?.en ? currentReport.name?.en : '');
      setFieldValue(
        'current_total_tax_amount',
        `${parseFloat(currentReport.total_tax).toFixed(2)}`
      );
      setFieldValue('less_than_5000', currentReport.data.period_calculations.less_than_5000);
      setFieldValue('additional_vat', currentReport.data.period_calculations.additional_vat);
      setFieldValue(
        'total_tax_amount',
        `${
          parseFloat(
            currentReport.total_tax +
              currentReport.data.period_calculations.less_than_5000 +
              currentReport.data.period_calculations.additional_vat
          ).toFixed(2)
          // parseFloat(currentReport.data.period_calculations.less_than_5000).toFixed(2) +
          // parseFloat(currentReport.data.period_calculations.additional_vat).toFixed(2)
        }`
      );
      setFieldValue('notes', currentReport.notes);
      // setPhoto(companyDetails?.branding.logo.light);
    }
  }, [currentReport]);

  useEffect(() => {
    if (currentReport?.id) {
      setFieldValue(
        'total_tax_amount',
        `${
          Number.isNaN(Number(formik.values.less_than_5000)) ||
          Number.isNaN(Number(formik.values.additional_vat))
            ? 0
            : (
                parseFloat(currentReport.total_tax) +
                parseFloat(formik.values.less_than_5000 ? formik.values.less_than_5000 : '0') +
                parseFloat(formik.values.additional_vat ? formik.values.additional_vat : '0')
              ).toFixed(2)
        }`
      );

      // console.log(formik.values.total_tax_amount);
    }
  }, [formik.values.less_than_5000, formik.values.additional_vat]);

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);
  const badge = new TableStatus({
    statusId: currentReport?.status,
    badge: reportStatusColorsMap?.get(currentReport?.status),
  });

  const customCodeBadge = new TableStatus({
    statusId: 'This report violates custom code validation rule/s',
    badge: 'badge-warning',
  });

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(InvoicesReportsRedux.actions.fullFillCurrentInvoicesReport({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */
  /* -------------------------------- request transactions -------------------------------- */
  useEffect(() => {
    const oldFiles = multiple_draft_document.filter((item: any) => item?.url);
    const newFiles = multiple_draft_document.filter((item: any) => !item?.url);
    if (oldFiles.length + newFiles.length > multiple_draft_document_maxFiles) {
      const difference = oldFiles.length + newFiles.length - multiple_draft_document_maxFiles;
      const res = multiple_draft_document.slice(0, -difference);
      set_multiple_draft_document(res);
      toast.error(
        formatMessage(
          { id: 'The maximum number of files is {maxfileUploaded}' },
          { maxfileUploaded: multiple_draft_document_maxFiles }
        ),
        { toastId: 'maximumFilesError' }
      );
    }
  }, [multiple_draft_document]);

  useEffect(() => {
    const oldFiles = approved_multiple_document.filter((item: any) => item?.url);
    const newFiles = approved_multiple_document.filter((item: any) => !item?.url);
    if (oldFiles.length + newFiles.length > approved_multiple_document_maxFiles) {
      const difference = oldFiles.length + newFiles.length - approved_multiple_document_maxFiles;
      const res = approved_multiple_document.slice(0, -difference);
      set_approved_multiple_document(res);
      toast.error(
        formatMessage(
          { id: 'The maximum number of files is {maxfileUploaded}' },
          { maxfileUploaded: approved_multiple_document_maxFiles }
        ),
        { toastId: 'maximumFilesError' }
      );
    }
  }, [approved_multiple_document]);

  useEffect(() => {
    const oldFiles = approved_multiple_document.filter((item: any) => item?.url);
    const newFiles = approved_multiple_document.filter((item: any) => !item?.url);
    if (oldFiles.length + newFiles.length > approved_multiple_document_maxFiles) {
      const difference = oldFiles.length + newFiles.length - approved_multiple_document_maxFiles;
      const res = approved_multiple_document.slice(0, -difference);
      set_approved_multiple_document(res);
    }
  }, [multiple_draft_document]);
  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'view' && $t('Preview report')}
            {mode === 'edit' && $t('Edit report')}
          </div>
        </div>
        <SeparatorLines lines={2} />

        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_reports' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}

              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-start'>
                      {/* Badges */}
                      {currentReport?.status && <div className='m-2'>{badge.renderItem()}</div>}
                      {(currentReport?.status === 'مسودة' || currentReport?.status === 'Draft') &&
                        currentReport?.has_custom_rules_errors && (
                          <div className='m-2'>{customCodeBadge.renderItem()}</div>
                        )}
                    </div>

                    <div
                      className='d-flex justify-content-end'
                      style={{ textAlign: 'end', alignSelf: 'center' }}
                    >
                      {mode === 'view' &&
                        userCan('view-report') &&
                        (currentReport?.status === 'مسودة' ||
                          currentReport?.status === 'Draft') && (
                          <PreviewPDF
                            id='preview_draft_report_PDF'
                            title={$t('Download report file')}
                            onClick={() =>
                              window.open(
                                `${backendBaseUrl}/reports/${currentReport?.id}/preview?lang=${locale}`,
                                '_blank',
                                'toolbar=yes,scrollbars=yes,resizable=yes'
                              )
                            }
                            isButton
                          />
                        )}
                      {mode === 'view' &&
                        userCan('update-report') &&
                        (currentReport?.status === 'مسودة' ||
                          currentReport?.status === 'Draft' ||
                          currentReport?.status === 'مقبول' ||
                          currentReport?.status === 'Approved') && (
                          <button
                            className='btn btn-primary btn-sm btn-primary-shadow '
                            title={formatMessage({ id: 'Edit report' })}
                            onClick={() => history.push(location.pathname + '/edit')}
                          >
                            <i className='fa fa-edit cursor-pointer'></i>
                            {formatMessage({ id: 'Edit report' })}
                          </button>
                        )}
                    </div>
                  </div>
                </div>
                <div className='row my-3'>
                  <div className='' style={{ width: '35%' }}>
                    {/* Company name */}
                    <div className='my-3'>
                      <span className='bullet bullet-dot'></span>
                      <span> {$t('Campany name')}: </span>
                      <span className='text-blueGrey'>{companyDetails?.name}</span>
                    </div>
                    <SeparatorLines lines={1} />
                    {/* Report name */}
                    <div className='my-3'>
                      <span className='bullet bullet-dot'></span>
                      <span> {$t('Report name')}: </span>
                      <span className='text-blueGrey'>{currentReport?.name?.[locale]}</span>
                    </div>
                    <SeparatorLines lines={1} />
                    {/* Start Date */}
                    <div className='my-3'>
                      <span className='bullet bullet-dot'></span>
                      <span> {$t('Start Date')}: </span>
                      <span className='text-blueGrey'>{formatDate(currentReport?.start_date)}</span>
                    </div>
                    <SeparatorLines lines={1} />
                    {/*  End Date */}
                    <div className='my-3'>
                      <span className='bullet bullet-dot'></span>
                      <span> {$t('End Date')}: </span>
                      <span className='text-blueGrey'>{formatDate(currentReport?.end_date)}</span>
                    </div>
                  </div>
                </div>
                {currentReport &&
                  Object.entries(currentReport?.transactions)?.map(([key, transaction]) => (
                    <>
                      <div className='row my-3'>
                        <div className='d-flex px-5  m-auto'>
                          <div
                            className='d-flex justify-content-center px-10 py-3 fs-6 '
                            style={{ width: '100%' }}
                          >
                            {key === 'sale' && $t('Sales tax')}
                            {key === 'purchase' && $t('Purchases tax')}
                          </div>
                        </div>
                      </div>
                      <div className='row my-3'>
                        <div className='m-auto' style={{ maxWidth: '73vw' }}>
                          <TransactionsTable title={key} transactions={transaction} />
                        </div>
                      </div>
                    </>
                  ))}
                {/*  ------------------------ Current total VAT (sales vat-purchases vat) -----------------------  */}
                <div className='row'>
                  <div className='col-md-6'>
                    <Label
                      msg={formatMessage({
                        id: 'Current total VAT (sales vat-purchases vat)',
                      })}
                      htmlFor={'Current total VAT (sales vat-purchases vat)'}
                      // isRequired
                    />
                    {/* <div className='my-3'>{`${parseFloat(currentReport?.total_tax).toFixed(
                      2
                    )}`}</div> */}
                  </div>
                  <div className='col-md-6'>
                    <Input
                      placeholder={formatMessage({
                        id: 'Current total VAT (sales vat-purchases vat)',
                      })}
                      autoComplete='off'
                      // labelId={formatMessage({
                      //   id: 'Current total VAT (sales vat-purchases vat)',
                      // })}
                      // required
                      name={'current_total_tax_amount'}
                      type='text'
                      formik={formik}
                      // rows='6'
                      id={'Current total VAT (sales vat-purchases vat)'}
                      disabled={true}
                    />
                  </div>
                </div>
                {/*  --------------------- end Current total VAT (sales vat-purchases vat)-----------------------  */}
                {/*  ------------------------ Correction from previous period between (±5000) -----------------------  */}
                <div className='row my-3'>
                  <div className='col-md-6'>
                    <Label
                      msg={formatMessage({
                        id: 'Correction from previous period between (±5000)',
                      })}
                      htmlFor={'Correction from previous period between (±5000)'}
                      // isRequired
                    />
                  </div>
                  <div className='col-md-6'>
                    <Input
                      placeholder={formatMessage({
                        id: 'Correction from previous period between (±5000)',
                      })}
                      autoComplete='off'
                      // labelId={formatMessage({
                      //   id: 'Correction from previous period between (±5000)',
                      // })}
                      // required
                      name={'less_than_5000'}
                      type='text'
                      formik={formik}
                      // rows='6'
                      id={'Correction from previous period between (±5000)'}
                      disabled={
                        mode === 'view' ||
                        currentReport?.status === 'مقبول' ||
                        currentReport?.status === 'Approved' ||
                        currentReport?.status === 'ملغي' ||
                        currentReport?.status === 'Canceled'
                      }
                    />
                  </div>
                </div>
                {/*  --------------------- end Correction from previous period between (±5000)-----------------------  */}
                {/*  ------------------------ VAT carried forward from the previous period(s) -----------------------  */}
                <div className='row my-3 mb-5'>
                  <div className='col-md-6'>
                    <Label
                      msg={formatMessage({
                        id: 'VAT carried forward from the previous period(s)',
                      })}
                      htmlFor={'VAT carried forward from the previous period(s)'}
                      // isRequired
                    />
                  </div>
                  <div className='col-md-6'>
                    <Input
                      placeholder={formatMessage({
                        id: 'VAT carried forward from the previous period(s)',
                      })}
                      autoComplete='off'
                      // labelId={formatMessage({
                      //   id: 'VAT carried forward from the previous period(s)',
                      // })}
                      // required
                      name={'additional_vat'}
                      type='text'
                      formik={formik}
                      // rows='6'
                      id={'VAT carried forward from the previous period(s)'}
                      disabled={
                        mode === 'view' ||
                        currentReport?.status === 'مقبول' ||
                        currentReport?.status === 'Approved' ||
                        currentReport?.status === 'ملغي' ||
                        currentReport?.status === 'Canceled'
                      }
                    />
                  </div>
                </div>
                {/*  --------------------- end VAT carried forward from the previous period(s)-----------------------  */}
                {/*  ------------------------ Net tax payable amount -----------------------  */}
                <SeparatorLines lines={3} />
                <div className='row my-3'>
                  <div className='col-md-6'>
                    <Label
                      msg={formatMessage({
                        id: 'Net tax payable amount',
                      })}
                      htmlFor={'Net tax payable amount'}
                      // isRequired
                    />
                    {/* <div className='my-3'>{`${parseFloat(currentReport?.total_tax).toFixed(
                      2
                    )}`}</div> */}
                  </div>
                  <div className='col-md-6'>
                    {parseFloat(formik.values?.total_tax_amount).toFixed(2)}
                    {/* <Input
                      placeholder={formatMessage({
                        id: 'Net tax payable amount',
                      })}
                      autoComplete='off'
                      // labelId={formatMessage({
                      //   id: 'Net tax payable amount',
                      // })}
                      // required
                      name={'total_tax_amount'}
                      type='text'
                      formik={formik}
                      // rows='6'
                      id={'Net tax payable amount'}
                      disabled={true}
                    /> */}
                  </div>
                </div>
                {/*  --------------------- end Net tax payable amount-----------------------  */}
                {/*  ------------------------ Reporter Notes -----------------------  */}
                <SeparatorLines lines={3} />
                <div className='row my-3'>
                  <div className='col-md-6'>
                    <Label
                      msg={formatMessage({
                        id: 'Reporter Notes',
                      })}
                      htmlFor={'notes'}
                      // isRequired
                    />
                    {/* <div className='my-3'>{`${parseFloat(currentReport?.total_tax).toFixed(
                      2
                    )}`}</div> */}
                  </div>
                  <div className='col-md-6'>
                    {/* {parseFloat(formik.values?.total_tax_amount).toFixed(2)} */}
                    <TextArea
                      autoComplete='off'
                      // labelId={LocaleTitle({ id: 'Product Description', lang })}
                      rows='6'
                      dir={locale === 'ar' ? 'rtl' : 'ltr'}
                      placeholder={formatMessage({
                        id: 'Reporter Notes',
                      })}
                      name={'notes'}
                      type='text'
                      formik={formik}
                      id={'notes'}
                      disabled={
                        mode === 'view' ||
                        currentReport?.status === 'مقبول' ||
                        currentReport?.status === 'Approved' ||
                        currentReport?.status === 'ملغي' ||
                        currentReport?.status === 'Canceled'
                      }
                    />
                  </div>
                </div>
                {/*  --------------------- end Reporter Notes-----------------------  */}
                {/*  ------------------------ Reporter Notes -----------------------  */}
                <SeparatorLines lines={3} />
                <Label
                  msg={formatMessage({
                    id: 'Attachments before the declaration is issued',
                  })}
                />
                <div className='row my-3'>
                  <div className='col-md-12'>
                    <MultipleFilesUploader
                      mode={mode}
                      setFile={set_multiple_draft_document}
                      file={multiple_draft_document}
                      maxSize={2048}
                      supportedFiles={allowedFiles}
                      maxfileUploaded={multiple_draft_document_maxFiles}
                      disabled={
                        mode === 'view' ||
                        currentReport?.status === 'مقبول' ||
                        currentReport?.status === 'Approved'
                      }
                      urlApiDelete={`/${companyDetails?.id}/reports/${currentReport?.id}/docs`}
                    />
                  </div>
                  {/* {(currentReport?.status === 'مسودة' || currentReport?.status === 'Draft') && (
                    <div className='col-md-3'>
                      <button
                        disabled={isUploadFile}
                        onClick={() => uploadDraftDocuments()}
                        type='button'
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      >
                        {isUploadFile ? (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span className='indicator-label'>{formatMessage({ id: 'Save' })}</span>
                        )}
                      </button>
                    </div>
                  )} */}
                </div>
                {(currentReport?.status === 'مقبول' ||
                  currentReport?.status === 'Approved' ||
                  currentReport?.status === 'ملغي' ||
                  currentReport?.status === 'Canceled') && (
                  <Fragment>
                    <SeparatorLines lines={3} />
                    <Label
                      msg={formatMessage({
                        id: 'Attachments after the declaration is issued',
                      })}
                    />
                    <div className='row my-3'>
                      <div className='col-md-12'>
                        <MultipleFilesUploader
                          mode={mode}
                          setFile={set_approved_multiple_document}
                          file={approved_multiple_document}
                          maxSize={2048}
                          supportedFiles={allowedFiles}
                          maxfileUploaded={approved_multiple_document_maxFiles}
                          disabled={
                            mode === 'view' ||
                            currentReport?.status === 'مسودة' ||
                            currentReport?.status === 'Draft'
                          }
                          urlApiDelete={`/${companyDetails?.id}/reports/${currentReport?.id}/docs`}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                {/*  --------------------- end Reporter Notes-----------------------  */}
                <div className='row  mt-8'>
                  {/* Save and Cancel Buttons */}
                  {mode === 'edit' &&
                    (currentReport?.status === 'مسودة' || currentReport?.status === 'Draft') && (
                      <>
                        {currentReport?.has_custom_rules_errors && (
                          <div className='col-md-8 my-5'>
                            <Input
                              className={'form-check-input'}
                              type={'checkbox'}
                              name={'custom_rules_errors_approve'}
                              id='custom_rules_errors_approve'
                              defaultChecked={false}
                              checked={
                                formik.values.custom_rules_errors_approve === 0 ? false : true
                              }
                              onChange={(value: any) => {
                                return formik.setFieldValue(
                                  `custom_rules_errors_approve`,
                                  value.target.checked ? 1 : 0
                                );
                              }}
                              onBlur={() =>
                                formik.setFieldTouched(`custom_rules_errors_approve`, true)
                              }
                            />{' '}
                            {
                              <Label
                                msg='Ignore custom code validation errors.'
                                htmlFor='custom_rules_errors_approve'
                              />
                            }
                          </div>
                        )}
                        <div className='col-md-8'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <button
                                // type='submit'
                                // id={'submit'}
                                className='btn btn-sm w-100 btn-success btn-shadow btn-success-shadow'
                                disabled={
                                  formik.isSubmitting ||
                                  (currentReport?.has_custom_rules_errors &&
                                    formik.values?.custom_rules_errors_approve === 0)
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  formik.setFieldValue('status', 2);

                                  formik.submitForm();
                                }}
                              >
                                {!loading && (
                                  <span className='indicator-label'>
                                    <FormattedMessage id={'Approve'} />
                                  </span>
                                )}
                                {loading && (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    <FormattedMessage id='Please wait...' />
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                            </div>
                            <div className='col-md-4'>
                              <button
                                className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                                disabled={formik.isSubmitting}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  formik.setFieldValue('status', 1);

                                  formik.submitForm();
                                }}
                              >
                                {!loading && (
                                  <span className='indicator-label'>
                                    <FormattedMessage id={'Save'} />
                                  </span>
                                )}
                                {loading && (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    <FormattedMessage id='Please wait...' />
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                            </div>
                            <div className='col-md-4'>
                              <button
                                onClick={() => {
                                  history.push(
                                    `/c/${companyDetails.slug}/invoices-tax-module/reports`
                                  );
                                }}
                                type='button'
                                id='kt_login_signup_form_cancel_button'
                                className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                              >
                                <FormattedMessage id='Cancel' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {mode === 'edit' &&
                    (currentReport?.status === 'مقبول' || currentReport?.status === 'Approved') && (
                      <>
                        <div className='col-md-8'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <button
                                disabled={isUploadFile}
                                onClick={() => uploadApprovedDocuments()}
                                type='button'
                                className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                              >
                                {isUploadFile ? (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    <FormattedMessage id='Please wait...' />
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                ) : (
                                  <span className='indicator-label'>
                                    {formatMessage({ id: 'Save' })}
                                  </span>
                                )}
                              </button>
                            </div>
                            {currentReport?.can_cancel && (
                              <div className='col-md-4'>
                                <button
                                  // type='submit'
                                  // id={'submit'}
                                  id='kt_login_signup_form_cancel_button'
                                  className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                                  disabled={formik.isSubmitting}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowModal(true);
                                    // formik.setFieldValue('status', 3);
                                    // formik.submitForm();
                                  }}
                                >
                                  {!loading && (
                                    <span className='indicator-label'>
                                      <FormattedMessage id={'Cancel report'} />
                                    </span>
                                  )}
                                  {loading && (
                                    <span
                                      className='indicator-progress'
                                      style={{ display: 'block' }}
                                    >
                                      <FormattedMessage id='Please wait...' />
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
      {showModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                <FormattedMessage id='Are you sure you want to cancel this report?' />
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      disabled={formik.isSubmitting}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        formik.setFieldValue('status', 3);
                        formik.submitForm();
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Confirm'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
    </div>
  );
};

const ApproveInvoicesReportWrapperEdit: FC = () => {
  usePermissions(['update-report']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'VAT' })}</PageTitle>
      {/* <div>coming soon</div> */}
      <ApproveInvoicesReportWrapper mode='edit' />
    </div>
  );
};

const ApproveInvoicesReportWrapperView: FC = () => {
  usePermissions(['view-report']);
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'VAT' })}</PageTitle>
      {/* <div>ApproveInvoicesReportWrapper mode='view'</div> */}
      <ApproveInvoicesReportWrapper mode='view' />
    </div>
  );
};
export { ApproveInvoicesReportWrapperEdit, ApproveInvoicesReportWrapperView };
