import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyTemplates = endpoints.companyTemplates;
export const customvariables = endpoints.customvariables;

export function getTemplateList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyTemplates(companyId), { params });
}

export function getTemplateCustomVariableList({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(customvariables(companyId), { params });
}
