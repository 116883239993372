import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generalNumber } from './statistic';
import { Currency } from '../../../shared/Currency';
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG';

type Props = {
  data: generalNumber;
  className: string;
  description: string;
};

export const GeneralNumbersCard: FC<Props> = ({ data, className, description }) => {
  const { color, svgIcon, value, valueType, title } = data; //faIcon, iconClasses, icon,
  return (
    <div
      className={`card hoverable ${className}`}
      style={{
        backgroundColor: `#6365A4`,
        // backgroundColor: `#5A9AD2`,
        // backgroundColor: `#5A5EA8`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* bg-${color}  */}
      {/* begin::Body */}
      <div className='card-body'>
        <KTSVG path={svgIcon} className={`svg-icon-white svg-icon-3x ms-n1`} />

        <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>
          {valueType === 'currency' && <Currency value={value} currency='USD' />}
          {valueType === 'number' && <>{value ? value : 0}</>}
        </div>

        <div className={`fw-bold text-inverse-${color} fs-1`}>
          {' '}
          <FormattedMessage id={title} />
        </div>
      </div>
      {/* end::Body */}
    </div>
    // <div className='general-numbers-card d-flex flex-row gap-3'>
    //   <div className='general-numbers-card-icon-container text-center'>
    //     {faIcon && <i className={`${faIcon} text-greyBlue`}></i>}
    //     {icon && <img src={icon} className={iconClasses} alt='' />}
    //   </div>
    //   <div className='d-flex flex-column'>
    //     <div className='text-metallicBlue'>
    //       <FormattedMessage id={title} />
    //     </div>
    //     <div className='fw-bolder'>
    //       {valueType === 'currency' && <Currency value={value} currency='USD' />}
    //       {valueType === 'number' && <>{value}</>}
    //     </div>
    //   </div>
    // </div>
  );
};
