import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyInvoicesReports = endpoints.companyInvoicesReports;
export const companySingleInvoiceReport = endpoints.companySingleInvoiceReport;
export const companyInvoicesReportTransactions = endpoints.companyInvoicesReportTransactions;

export function getAllInvoicesReports({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyInvoicesReports(companyId), { params });
}

export function getInvoicesReport({ companyId, reportId }: { companyId: ID; reportId: ID }) {
  return axiosInstance.get(companySingleInvoiceReport(companyId, reportId));
}

export function getAllInvoicesReportTransactions({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyInvoicesReportTransactions(companyId), { params });
}
