import moment from 'moment';

export function formatDate(date: string) {
  return moment(date).utc(false).format('DD-MM-YYYY');
}

export function formatDateTime(date: string) {
  return moment(date).utc(false).format('DD-MM-YYYY HH:mm');
}

export class DateViewer {
  constructor(
    private fieldData: {
      date: string;
      errors_with_color?: {
        color: string;
        text: string;
      };
    }
  ) {}

  renderItem() {
    return (
      <>
        {!this.fieldData.errors_with_color && (
          <span className='text-lightGreyBlue fs-5'>
            {this.fieldData.date ? formatDate(this.fieldData.date) : ''}
          </span>
        )}
        {this.fieldData.errors_with_color && (
          <span className={'badge badge-md badge-danger'}>
            {this.fieldData.errors_with_color.text}
          </span>
        )}
      </>
    );
  }
}
