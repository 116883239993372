/* eslint-disable no-useless-escape */
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const numbersPattern = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;

export const ddMMYYYPattern = /[0-9]{1,2}([./-])[0-9]{1,2}([./-])[0-9]{4}/;

export const specialCharacters = /[!@#$%^&*€¹²³¾½¾£()_+\-=\[\]{};':"\\|,.<>\/?]/;

export const strongPasswordRegexWithSpecialChar =
  /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*~` €¹²³¾½¾()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,15}$)/;

export const sprcialCharactesOrNumbersRegex =
  /([!@#$%^&*€¹²³¾½¾£()_+\-=\[\]{};':"\\|,.<>\/?123456789۰۱۲۳۴۵۶۷۸۹٠١٢٣٤٥٦٧٨٩])/g;

export const uriStrictPatterns =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const uriOptionalPattern =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
