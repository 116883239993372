import { FC } from 'react';

type Props = {
  value: number;
  currency?: 'USD' | 'SAR';
  withFractionDigits?: boolean;
};

export const Currency: FC<Props> = ({
  value,
  currency,
  withFractionDigits,
}): React.ReactElement<any, string | React.JSXElementConstructor<any>> => {
  const amount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  }).format(value);

  const fromDotToTheEndOfAmount = 3;
  
  return (
    <>
      {withFractionDigits
        ? amount
        : [...amount.split('')].splice(0, amount.length - fromDotToTheEndOfAmount).join('')}
    </>
  );
};
