/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'; // shallowEqual,
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../setup';
import { $t } from '../../../../_metronic/i18n/formatMessage'; //, FormatMessage
import { PageTitle } from '../../../../_metronic/layout/core';
import { SerachFilters } from '../../../shared/SearchFilters';
import { TableData } from '../../../shared/tables/classes/TableData';
import { TableActions } from '../../../shared/tables/classes/TableActions';
import { TableSelection } from '../../../shared/tables/classes/TableSelection';
import * as TableRedux from '../../../store/TableRedux';
import { KTSVG } from '../../../../_metronic/helpers';
import { CommonTable2 } from '../../../shared/tables/Table2';
import * as SubscriptionPlansRedux from '../../../modules/admins/subscription-plans/store/SubscriptionPlansRedux';
import {
  SubscriptionPlansDataListModel,
  SubscriptionPlansModel,
} from '../../../modules/admins/subscription-plans/SubscriptionPlansModel';
import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import { Button } from '../../../shared/Button';
import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
import { axiosInstance } from '../../../../network/apis';
import { toast } from 'react-toastify';
import { Portal } from '../../../../_metronic/partials';
import AhadDialog from '../../../../setup/shared/AhadDialog';
import { PlanTypeMap } from '../../../../_metronic/constants/general';
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';
import { EditItem } from '../../../shared/tables/actions/EditItem';
// import { ViewItem } from '../../../shared/tables/actions/ViewItem';
// import { TableStatus } from '../../../shared/tables/classes';
// import { DateViewer } from '../../../shared/tables/classes/DateViewer';
// import { subscriptionPlanStatusColorsMap } from '../../../../_metronic/constants/colors';
// import { ModalName } from '../../../types/types';

const headerItems = [
  { id: 'Name', maxWidth: 280 },

  { id: 'Description', maxWidth: 140 },
  { id: 'Number of companies', maxWidth: 140 },
  { id: 'Prices', maxWidth: 140 },
  { id: 'Actions', maxWidth: 280 },
];
const TABLE_NAME = 'sys_subscription_plans_list';

const SubscriptionPlansContent: FC = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteSubscriptionPlan, setDeleteSubscriptionPlan] = useState<number | null>(null);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [confirmationEdit, setConfirmationEdit] = useState<{
    show: boolean;
    id: number | null;
    message: string;
    status?: boolean | null;
  }>({
    show: false,
    id: null,
    message: '',
    status: null,
  });

  const subscription_plans: SubscriptionPlansDataListModel = useSelector<RootState>(
    (state) => state.SYS_subscription_plans.subscription_plans
  ) as SubscriptionPlansDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  function RequestData() {
    dispatch(
      SubscriptionPlansRedux.actions.getSubscriptionPlans({
        params: { ...table?.query, page: table?.page, per_page: table?.pagination },
      })
    );
  }

  useEffect(() => {
    RequestData();
  }, []);

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };
  useEffect(() => {
    RequestData();
  }, [table?.page, table?.pagination]);

  const toggleStatus = (id: number, status: boolean) => {
    const formData = new FormData();
    formData.append('is_active', status ? '1' : '0');
    formData.append('_method', 'PUT');

    axiosInstance
      .post(`/admin/plans/${id}/set-active`, formData)
      .then((response) => {
        toast.success(
          formatMessage({ id: 'subscription has plan status been updated successfully' }),
          {
            toastId: 'subscription has plan status been updated successfully',
          }
        );
        RequestData();
      })
      .catch((err) => {
        const data = err?.response?.data?.errors;
        const mergedErrors: any = Object.values(data ?? {}).reduce(
          (accumulator: any, currentValue) => accumulator.concat(currentValue),
          []
        );
        mergedErrors.map((error: string, index: number) => toast.error(error, { toastId: index }));
      });
  };

  /* ------------------------- get plan type data ------------------------ */

  const planTypesOptions = useMemo(
    () =>
      Array.from(PlanTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  const rowItems = subscription_plans?.data?.map(
    (subscriptionPlan: SubscriptionPlansModel, index) => {
      if (index === 0) {
      }
      return [
        new TableSelection({ display: true, id: subscriptionPlan.id, tableName: TABLE_NAME }),
        new TableData({
          text: subscriptionPlan?.name[locale as 'ar' | 'en'] || formatMessage({ id: 'No name' }),
        }),
        new TableData({ text: subscriptionPlan?.description[locale as 'ar' | 'en'] }),
        new TableData({
          text: `${
            subscriptionPlan?.assigned_companies_count
              ? subscriptionPlan?.assigned_companies_count?.toString()
              : ''
          }`,
        }),
        new TableData({
          text: (
            <div>
              {subscriptionPlan?.prices.map((price) => (
                <div key={price.id}>
                  <div>
                    {formatMessage({ id: price.period_type === 1 ? 'Month' : 'Year' })} :{' '}
                    {price.price} {formatMessage({ id: price.currency })}
                  </div>
                </div>
              ))}
            </div>
          ),
        }),

        new TableActions(
          (
            <div className='d-flex flex-row'>
              <ViewItem
                title={$t('View')}
                onClick={() => history.push(`/admin/subscription-plans/${subscriptionPlan.id}`)}
              />
              <EditItem
                title={$t('Edit')}
                onClick={() =>
                  history.push(`/admin/subscription-plans/${subscriptionPlan.id}/edit`)
                }
              />
              {subscriptionPlan?.can_delete && (
                <DeleteItem
                  methodType='post'
                  id={subscriptionPlan.id}
                  title={$t('Delete')}
                  messageId={'delete_subscription_plan'}
                  showButtonGroup={true}
                  setDeleteItemId={setDeleteSubscriptionPlan}
                  deleteItemId={deleteSubscriptionPlan}
                  deleteURI={`/admin/plans`}
                  payload={subscriptionPlan.id}
                  successMessage={'subscription plan deleted successfully!'}
                  afterSucessRequest={() => {
                    dispatch(
                      SubscriptionPlansRedux.actions.getSubscriptionPlans({
                        params: { ...table?.query, page: table?.page, per_page: table?.pagination },
                      })
                    );
                  }}
                />
              )}
              {subscriptionPlan.type === 1 && (
                <>
                  {subscriptionPlan?.is_active && (
                    <div>
                      <a
                        className='btn btn-icon btn-bg-light btn-color-primary btn-sm me-1'
                        title={$t('Enabled')}
                        onClick={() => {
                          setConfirmationEdit({
                            show: true,
                            id: subscriptionPlan.id as number | null,
                            message: 'Are you sure you want to hide this plan?',
                            status: false,
                          });
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen037.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  )}

                  {!subscriptionPlan?.is_active && (
                    <div>
                      <a
                        className='btn btn-icon btn-bg-light btn-color-danger btn-sm me-1'
                        title={$t('Hidden')}
                        onClick={() => {
                          // toggleStatus(subscriptionPlan.id, true);
                          setConfirmationEdit({
                            show: true,
                            id: subscriptionPlan.id as number | null,
                            message: 'Are you sure you want to enable this plan?',
                            status: true,
                          });
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen034.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          )
        ),
      ];
    }
  );

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    dispatch(SubscriptionPlansRedux.actions.fulFillSubscriptionPlans({}));
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  return (
    <>
      {confirmationEdit.show && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={'Confirm'}
            closeCallBack={() => {
              setConfirmationEdit({ show: false, id: null, message: '' });
            }}
            specialContainerClass='dialog-content w-600px'
          >
            <div className='d-flex flex-column'>
              {
                <div className='d-flex align-items-center  rounded p-5'>
                  <span className='svg-icon svg-icon-danger me-5'></span>
                  <div className='flex-grow-1 me-2'>
                    <p className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                      <FormattedMessage id={confirmationEdit.message} />
                    </p>
                  </div>
                </div>
              }

              <>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        type='button'
                        // disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleStatus(
                            confirmationEdit.id as number,
                            confirmationEdit.status as boolean
                          );
                          setConfirmationEdit({ show: false, id: null, message: '' });
                        }}
                      >
                        <span className='indicator-label'>
                          <FormattedMessage id={'Confirm'} />
                        </span>
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={() => {
                          setConfirmationEdit({ show: false, id: null, message: '' });
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </AhadDialog>
        </Portal>
      )}

      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}
          {/*  ------------------------ start Action button ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            {/* begin::Menu */}

            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
            </button>

            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                  {$t('Actions')}{' '}
                </div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}
              {/* begin::Menu item */}
              <div className='menu-item px-3 py-3'>
                <Button
                  fa='fa-solid fa-plus'
                  className='menu-link px-3 btn w-md-190px'
                  onClick={() => {
                    history.push(`/admin/subscription-plans/create`);
                  }}
                  nameId='create new subscription'
                  testid='create-new-subscription'
                  faIconMenu={true}
                />
              </div>{' '}
              {/* end::Menu item */}
            </div>
            {/* end::Menu 2 */}
            {/* end::Menu */}
          </div>

          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'type',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'type',
                      })
                    ),
                  placeholderId: 'Plan type',
                  options: planTypesOptions,
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        {subscription_plans?.data && (
          <CommonTable2
            className='pb-5 pb-xl-8'
            tableName={TABLE_NAME}
            headerWithCheckbox
            allDisplayedIds={
              subscription_plans?.data?.map((subscription_plan) => subscription_plan.id) || []
            }
            meta={subscription_plans}
            headerItems={headerItems}
            rowItems={rowItems}
            initPerPage={20}
          />
        )}
      </div>
    </>
  );
};

const SubscriptionPlansWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Subscription plans' })}</PageTitle>

      <SubscriptionPlansContent />
    </div>
  );
};

export { SubscriptionPlansWrapper };
