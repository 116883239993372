import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { commonValidations } from '../../../_metronic/constants/GeneralValidations';
import { uriOptionalPattern } from '../../../_metronic/constants/patterns';
import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { $t } from '../../../_metronic/i18n/formatMessage';
// import { CompanyTypes } from '../../../../types/CompanyTypes';

export const useEditViewCompanyDetailsWrapperSchema = () => {
  const { formatMessage } = useIntl();

  const initialValues: { [key: string]: any } = {
    slug: '',
    name: '',
    type: { value: 1, label: '' },
    website: '',
    tax_document: '',
    // tax_number: '',
    commercial_registration: '',
    certificate_number: '',
    // certificate_number_document: '',
    unified_number: '',
    report_period: [],
    tax_number: '',
    // tax_number_document: '',
    tax_registration_date: '',
    company_activities: [],
    sales_categories: [],
    purchases_categories: [],
    zip: '',
    country: null,
    city: null,
    address_en: '',
    address_ar: '',
    invoice_number_method: '1',
    sales_invoice_number_prefix: '',
    sales_invoice_number_start: '',
    return_sales_invoice_number_prefix: '',
    return_sales_invoice_number_start: '',
    account_name: '',
    bank_name: '',
    account_number: '',
    iban: '',
  };

  const required = $t('This field is required');

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required(formatMessage({ id: 'This field is required' }))
          .min(
            2,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: 2 }
            )
          )
          .max(
            100,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: 100 }
            )
          )
          .test(
            'name',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        type: Yup.object().required(formatMessage({ id: 'This field is required' })),
        website: Yup.string()
          .matches(uriOptionalPattern, formatMessage({ id: 'Website is invalid' }))
          .when('type', (type, schema) => {
            // if (type?.value === CompanyTypes?.Corporate)
            //   return schema.required(formatMessage({ id: 'Website is invalid' }));
            // else return schema.notRequired();
            return schema.notRequired();
          })
          .nullable(),
        // tax_number: Yup.number()
        //   .typeError(formatMessage({ id: 'Only number are allowed!' }))
        //   .test(
        //     'len',
        //     formatMessage(
        //       { id: 'You need to enter {count} numbers' },
        //       { count: commonValidations.taxNumberCharacters }
        //     ),
        //     (val: any) =>
        //       !val?.toString()?.length ||
        //       val?.toString()?.length === commonValidations.taxNumberCharacters
        //   )
        //   .nullable(),
        national_id: Yup.number()
          .typeError(formatMessage({ id: 'Only number are allowed!' }))
          .test(
            'len',
            formatMessage(
              { id: 'You need to enter {count} numbers' },
              { count: commonValidations.nationalId }
            ),
            (val: any, context: any) =>
              !val?.toString()?.length ||
              context?.originalValue?.length === commonValidations.nationalId
          )
          .nullable(),
        commercial_registration: Yup.number()
          .typeError(formatMessage({ id: 'Only number are allowed!' }))
          .test(
            'len',
            formatMessage(
              { id: 'You need to enter {count} numbers' },
              { count: commonValidations.commertialRegistrationCharacters }
            ),
            (val: any, context: any) =>
              !val?.toString()?.length ||
              context?.originalValue?.length === commonValidations.commertialRegistrationCharacters
          )
          .nullable(),
        certificate_number: Yup.number()
          .typeError(formatMessage({ id: 'Only number are allowed!' }))
          .test(
            'len',
            formatMessage(
              { id: 'You need to enter {count} numbers' },
              { count: commonValidations.certificateNumber }
            ),
            (val: any, context: any) =>
              !val?.toString()?.length ||
              context?.originalValue?.length === commonValidations.certificateNumber
          )
          .nullable(),
        unified_number: Yup.number()
          .typeError(formatMessage({ id: 'Only number are allowed!' }))
          .test(
            'len',
            formatMessage(
              { id: 'You need to enter {count} numbers' },
              { count: commonValidations.unifiedNumberCharacters }
            ),
            (val: any, context: any) =>
              !val?.toString()?.length ||
              context?.originalValue?.length === commonValidations.unifiedNumberCharacters
          )
          .nullable(),
        report_period: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
        tax_number: Yup.number()
          // .required(required)
          .typeError(formatMessage({ id: 'Only number are allowed!' }))
          .test(
            'len',
            formatMessage(
              { id: 'You need to enter {count} numbers' },
              { count: commonValidations.taxNumberCharacters }
            ),
            (val: any) =>
              !val?.toString()?.length ||
              val?.toString()?.length === commonValidations.taxNumberCharacters
          )
          .nullable(),
        tax_registration_date: Yup.string(),
        country: Yup.object().nullable(),
        city: Yup.object().nullable(),
        address_en: Yup.string().nullable(),
        address_ar: Yup.string().nullable(),
        zip: Yup.number().typeError($t('Only number are allowed!')).nullable(),
        company_activities: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
        sales_categories: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
        purchases_categories: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),

        invoice_number_method: Yup.string().required(required),
        sales_invoice_number_prefix: Yup.string().when('invoice_number_method', {
          is: '2',
          then: Yup.string()
            .max(
              commonValidations.nameMaximum,
              formatMessage(
                { id: 'The Maximum characters is {maxCharacters}' },
                {
                  maxCharacters: commonValidations.nameMaximum,
                }
              )
            )
            .required(required),
        }),
        sales_invoice_number_start: Yup.number().when('invoice_number_method', {
          is: '2',
          then: Yup.number()
            .typeError(formatMessage({ id: 'Only number are allowed!' }))
            .test(
              'len',
              formatMessage(
                { id: 'The Maximum characters is {maxCharacters}' },
                { maxCharacters: commonValidations.InvoiceNumberCharacters }
              ),
              (val: any) => {
                return (
                  !val?.toString()?.length ||
                  val?.toString()?.length <= commonValidations.InvoiceNumberCharacters
                );
              }
            )
            .required(required),
        }),
        return_sales_invoice_number_prefix: Yup.string()
          .max(
            commonValidations.nameMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              {
                maxCharacters: commonValidations.nameMaximum,
              }
            )
          )
          .when('invoice_number_method', {
            is: '2',
            then: Yup.string().required(required),
          }),
        return_sales_invoice_number_start: Yup.number().when('invoice_number_method', {
          is: '2',
          then: Yup.number()
            .typeError(formatMessage({ id: 'Only number are allowed!' }))
            .test(
              'len',
              formatMessage(
                { id: 'The Maximum characters is {maxCharacters}' },
                { maxCharacters: commonValidations.InvoiceNumberCharacters }
              ),
              (val: any) => {
                return (
                  !val?.toString()?.length ||
                  val?.toString()?.length <= commonValidations.InvoiceNumberCharacters
                );
              }
            )
            .required(required),
        }),
      }),
    [formatMessage]
  );

  return { schema, initialValues };
};
