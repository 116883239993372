import { SelectOptions } from '../app/types/SelectOptions';

export function createDescreptionOptions(
  array: any[],
  label = 'description',
  value = 'id',
  is_return_code = 'is_return_code'
): SelectOptions {
  return (
    array?.map((item) => ({
      label: item[label],
      value: item[value],
      is_return_code: item[is_return_code],
    })) || []
  );
}
