import { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../../_metronic/helpers';
import { $t, FormatMessage } from '../../../../../_metronic/i18n/formatMessage';
import { usePermissions } from '../../../../hooks/usePermissions';

type Props = {
  title: string;
  width?: number;
  setFunction: Function;
  maxSizeInBytes: number;
  image: Blob | MediaSource | string | any;
  isDisabled: boolean;
};

export const LogoUploader: FC<Props> = ({
  title,
  width,
  setFunction,
  maxSizeInBytes,
  image,
  isDisabled,
}) => {
  const { userCan } = usePermissions();
  const uploadRef = useRef<HTMLDivElement | null>();
  const { formatMessage } = useIntl();

  const handleUpload = (e: any) => {
    // const sizeInMB = maxSizeInBytes / 1024;
    const file = e.target.files[0];
    // const img = new Image();

    if (!['image/jpeg', 'jpeg', 'png', 'image/png', 'image/svg+xml'].includes(file?.type)) {
      toast.error(formatMessage({ id: 'You can only upload JPG, SVG,PNG, JPEG files format' }));
      return;
    }

    if (file?.size / 1024 > maxSizeInBytes) {
      toast.error(
        formatMessage(
          { id: 'The maximum file size is {maxSize}MB' },
          { maxSize: `${maxSizeInBytes / 1024}` }
        )
      );
    }
    setFunction(e?.target?.files?.[0] || '');
  };

  return (
    <div className='uploader-border text-center p-3'>
      <h4>{$t(title)}</h4>
      <div
        className='logo-container position-relative'
        style={{
          width: `${width || 300}px`,
          backgroundImage:
            typeof image === 'string' ? `url("${image}")` : `urtl("${URL.createObjectURL(image)}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        {['image/jpeg', 'jpeg', 'png', 'image/png', 'image/svg+xml'].includes(image.type) && (
          <img src={URL.createObjectURL(image)} alt='FIle' width='100%' height='100%' />
        )}
        {userCan('update-company') && isDisabled && (
          <div
            className='badge badge-danger cursor-pointer rotatable-45 shadowed position-absolute'
            style={{ left: '17px', top: '17px' }}
            data-testid='cancel-btn'
            onClick={() => setFunction('')}
          >
            <KTSVG
              path='/media/svg/icons/cancel.svg'
              className='svg-icon-2 d-inline-block position-relative'
              svgClassName='svg-white'
            />
          </div>
        )}

        {userCan('update-company') && isDisabled && (
          <label htmlFor={title}>
            <div
              className='btn btn-outlined position-absolute'
              style={{ bottom: '17px', left: '17px' }}
              onClick={() => uploadRef?.current?.click()}
            >
              <i className='fas fa-upload text-lightGrayBlue'></i>
              <FormatMessage id='Upload logo' />
            </div>
            <input
              type='file'
              id={title}
              style={{ display: 'none' }}
              onChange={(e) => handleUpload(e)}
            />
          </label>
        )}
      </div>
    </div>
  );
};
