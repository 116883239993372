/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom'; //, useLocation
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

// import { Alert } from 'react-bootstrap-v5';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { components, OptionProps } from 'react-select';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';
// import * as ClientsRedux from '../../modules/clients/store/ClientsRedux';

import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CompanyModel } from '../companies/CompanyModel';

import { SeparatorLines } from '../../shared/Lines';
import { Input } from '../../shared/Input';
import { Label } from '../../shared/Label';
import { TextArea } from '../../shared/textarea';
// import { FileUploader } from '../../pages/Companies/CompanyDetails/components/FileUploader';

import { axiosInstance } from '../../../network/apis';
import { useQuery } from 'react-query';
import { createOptions } from '../../../utils/createOptions';
import { createSelectOptions } from '../../../utils/createSelectOptions';

import { ErrorMessage } from '../../shared/ErrorMessage';

import { VatRatesTypes } from './types/SalesModel'; //DocumentDirection,
import { ThirdPartyTypes } from '../../types/CompanyTypes';
// import { VatRateTypeMap, TransactionTypeMap } from '../../../_metronic/constants/general'; //DocumentDirection,
// import { toSelectOption } from '../../../_metronic/helpers/functions/Select';

import { useConfigureSalesSchema } from './useConfigureSalesSchema';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { TableData } from '../../shared/tables/classes'; // DateViewer,
import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';
import { SalesWrapper } from '../sales/SalesWrapper';

import { initialConfigPayload } from './helpers/salesConfigHelpers';
// import { ClientsDataListModel } from '../clients/types/clientsList'; //, IClient
import { LOCALE } from '../../../_metronic/helpers/typescript';
import * as SalesRedux from './store/SalesRedux';
import { ISale } from './types/SalesModel';

import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { debounce } from '../../../utils/debounce';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

export const ConfigNewSalesWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { salesInitialValues, salesValidationSchema } = useConfigureSalesSchema();
  const dispatch = useDispatch();
  // const location = useLocation();
  const history = useHistory();
  const { userCan } = usePermissions();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  // const [attachment, setAttachment] = useState<any>('');
  const [showWarningsConfirmationModal, setShowWarningsConfirmationModal] = useState(false);
  const [warningsConfirmation, setWarningsConfirmation] = useState<any>([]);
  const [approveWarnings, setApproveWarnings] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  const [editItemId, setEditItemId] = useState<any>(null);

  /* ----------------------------- Getting Sales ----------------------------- */
  const getCurrentSales = async (salesId: any) => {
    try {
      dispatch(
        SalesRedux.actions.getCurrentSale({
          companyId: companyDetails.id,
          saleId: salesId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };

  const createSales = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    payload['description'] = { ar: '', en: '' };
    payload['description']['ar'] = values.description_ar ? values.description_ar.trim() : '';
    payload['description']['en'] = values.description_en ? values.description_en.trim() : '';
    payload['client_id'] = values.clients[0]?.value;
    delete payload['clients'];
    payload['vat_rate_id'] = values.vat_rate[0]?.value;
    delete payload['vat_rate'];
    payload['amount'] = Number(values.amount);
    if (mode === 'edit' && approveWarnings) {
      payload['approve'] = approveWarnings;
    }

    if (!values.vat_rate[0]?.is_return_code) {
      payload['invoice_reference'] = '';
    }
    payload['is_return'] = 0;

    if (values.categories.length > 0) {
      payload['category_id'] = values.categories?.[0].value;
    }
    delete payload['categories'];
    // payload['quantity'] = Number(values.quantity);
    const formData = serialize(payload, { indices: true });
    // if (attachment) {
    //   formData.append('attachment', attachment);
    // }
    try {
      if (mode === 'create') {
        await axiosInstance.post(`${companyDetails.id}/sales`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'Sales has been created successfully!' }));
      } else if (mode === 'edit') {
        formData.append('_method', 'PUT');
        await axiosInstance.post(`${companyDetails.id}/sales/${salesItem.id}`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'Sales has been updated successfully!' }));
      }
      history.push(`/c/${companyDetails.slug}/tax-module/sales`);
    } catch (err: any) {
      if (
        err &&
        err.response.status === 422 &&
        Object.keys(err.response.data.errors).includes('warning_confirmations')
      ) {
        setLoading(false);
        setShowWarningsConfirmationModal(true);
        setWarningsConfirmation(err.response.data.errors.warning_confirmations);
      } else if (err.response.data.errors.length === 0) {
        setApproveWarnings(1);
        formik.submitForm();
      } else {
        DisplayErrors(err);
      }
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: salesInitialValues,
    validationSchema: salesValidationSchema,
    onSubmit: (values, { resetForm }) => createSales(values, resetForm),
  });
  const { setFieldValue, setFieldTouched } = formik; //,values

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  // const clients: ClientsDataListModel = useSelector<RootState>(
  //   (state) => state.USER_clients.Clients,
  //   shallowEqual
  // ) as ClientsDataListModel;

  // const getClients = () =>
  //   dispatch(
  //     ClientsRedux.actions.getClients({
  //       companyId: companyDetails.id,
  //       params: {
  //         per_page: 0,
  //         third_party_type: `${ThirdPartyTypes.Client},${ThirdPartyTypes.Both}`,
  //       },
  //     })
  //   );

  // const clientsOptions = useMemo(
  //   () =>
  //     clients?.data?.map((client) => ({
  //       label: client?.name?.[locale as LOCALE],
  //       value: client.id,
  //     })),
  //   [clients]
  // );

  const loadClientsOptions = useCallback(
    debounce((inputValue: any, callback: (options: any) => void) => {
      axiosInstance
        .get(`${companyDetails.id}/clients`, {
          params: {
            name: inputValue,
            per_page: 0,
            third_party_type: `${ThirdPartyTypes.Client},${ThirdPartyTypes.Both}`,
          },
        })
        .then((res) => {
          callback(
            res.data.data.map((client: any) => ({
              ...client,
              label: client?.name?.[locale as LOCALE],
              value: client.id,
            }))
          );
        });
    }, 1000),
    []
  );

  const { isLoading: vatRateLoading, data: vatRateList } = useQuery(
    'VatRate',
    () =>
      axiosInstance(`/${companyDetails.id}/vat_rates`, {
        params: {
          type: `${VatRatesTypes.Income}`,
          per_page: 0,
        },
      }).then((res) => {
        return res.data.data;
      }),
    { retryDelay: 300, retry: 3 }
  );
  const vatRateOptions = useMemo(() => createOptions(vatRateList), [vatRateList]);

  const salesItem: ISale = useSelector<RootState>((state) => {
    return state.USER_sales.currentSale;
  }, shallowEqual) as ISale;

  const linkedInvoices = useMemo(() => ({ invoice_reference_id: salesItem?.id }), [salesItem?.id]);

  const {
    isLoading: categoriesLoading,
    data: categoriesList,
    refetch: refetchCategories,
  } = useQuery(
    'categories',
    () =>
      axiosInstance(`/categories`, {
        params: {
          type: `1`,
          per_page: 0,
          third_party_id: `${formik.values?.clients?.[0].value}`,
        },
      }).then((res) => {
        return res.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  const categoriesOptions = useMemo(() => {
    if (categoriesList?.sales?.length > 0 && categoriesList?.purchases?.length > 0)
      return createSelectOptions([...categoriesList?.sales, ...categoriesList?.purchases]);
    else if (categoriesList?.sales?.length > 0) return createSelectOptions(categoriesList?.sales);
    else if (categoriesList?.purchases?.length > 0)
      return createSelectOptions(categoriesList?.purchases);
    else return [];
  }, [categoriesList, formik.values?.clients]);

  useEffect(() => {
    refetchCategories();
  }, [formik.values?.clients?.[0]?.value, salesItem?.category]);

  useEffect(() => {
    if (mode === 'create') setFieldValue('categories', []);
    else {
      setFieldValue(
        'categories',
        salesItem?.category
          ? categoriesOptions?.filter((category) => category.value === salesItem.category.id)
          : []
      );
    }
  }, [salesItem?.category, categoriesList]);

  /* ----------------------- end getting data from store ---------------------- */
  // const accountTypesOptions = useMemo(() => {
  //   return Array.from(VatRateTypeMap.values())
  //     .flat()
  //     .map((option) => toSelectOption(option, locale));
  // }, [locale]);

  // const transactionTypesOptions = useMemo(() => {
  //   return Array.from(TransactionTypeMap.values())
  //     .flat()
  //     .map((option) => toSelectOption(option, locale));
  // }, [locale]);

  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    // if (!clients?.data) {
    //   getClients();
    // }
    if (params?.salesId && mode !== 'create') {
      getCurrentSales(params?.salesId);
    }
  }, [vatRateOptions, params?.salesId, salesItem?.id]);

  useEffect(() => {
    // getClients();

    if (salesItem?.id && mode !== 'create') {
      setFieldValue('invoice_date', salesItem.invoice_date);
      setFieldValue('invoice_number', salesItem.invoice_number);
      setFieldValue(
        'invoice_reference',
        salesItem.invoice_reference ? salesItem.invoice_reference : ''
      );
      setFieldValue('description_ar', salesItem.description?.ar ? salesItem.description?.ar : '');
      setFieldValue('description_en', salesItem.description?.en ? salesItem.description?.en : '');
      setFieldValue('amount', salesItem.amount);

      setFieldValue(
        'clients',
        salesItem.client
          ? [
              // ...clientsOptions,
              {
                label: salesItem.client?.name,
                value: salesItem.client?.id,
              },
            ]?.filter((client) => client.value === salesItem.client.id)
          : []
      );
      setFieldValue(
        'vat_rate',
        salesItem.vat_rate
          ? vatRateOptions?.filter((vat_rate) => vat_rate.value === salesItem.vat_rate.id)
          : []
      );
    }
  }, [params?.salesId, salesItem?.id]);

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);
  /* --------------------------------- Leaving -------------------------------- */

  useEffect(() => {
    if (mode === 'create') {
      formik.setFieldValue(`invoice_date`, '');
    }
    return () => {
      setDisable();
      setShowExitPrompt(false);
      dispatch(SalesRedux.actions.fullFillCurrentSale({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && $t('Create sales')}
            {mode === 'view' && $t('Preview sales')}
            {mode === 'edit' && $t('Edit sales')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='row  mx-3'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex justify-content-start'>
              {/* Badges */}
              {mode !== 'create' &&
                Object.entries(salesItem ? salesItem.errors_with_color : {}).map(([key, value]) => {
                  const badge = new TableData({
                    text: ``,
                    errors_with_color: value,
                  });

                  return <div className='m-2'>{badge.renderItem()}</div>;
                })}
            </div>
            {mode === 'view' && userCan('update-sale') && (
              <div
                className='d-flex justify-content-end'
                style={{ textAlign: 'end', alignSelf: 'center' }}
              >
                <EditItemWithModal
                  id={salesItem?.id}
                  title={$t('Edit')}
                  isButton
                  buttonMessageId={'Edit sales'}
                  messageId={
                    salesItem?.can_update
                      ? salesItem?.can_delete
                        ? 'This sale already exist in a previous report, and editing it will cause changes to the report, are you sure you want to proceed the editing process?'
                        : ''
                      : "This sale is included in an approved report, and can't be edited"
                  }
                  editURI={`/c/${companyDetails.slug}/tax-module/sales/${salesItem?.id}/edit`}
                  showButtonGroup={salesItem?.can_update}
                  editItemId={editItemId}
                  setEditItemId={setEditItemId}
                />
                {/* <button
                  className='btn btn-primary btn-sm btn-primary-shadow'
                  title={formatMessage({ id: 'Edit sales' })}
                  onClick={() => history.push(location.pathname + '/edit')}
                >
                  <i className='fa fa-edit cursor-pointer'></i>
                  {formatMessage({ id: 'Edit sales' })}
                </button> */}
              </div>
            )}
          </div>
        </div>
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_sales' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  {/*  ------------------------ Sales Invoice date -----------------------  */}
                  <div className='col-md-6'>
                    <label
                      className={'form-label fs-6 my-1 fw-bolder text-dark required'}
                      htmlFor='invoice_date_id'
                    >
                      {formatMessage({
                        id: 'Invoice date',
                      })}
                    </label>
                    <DatePicker
                      isClearable={mode !== 'view'}
                      selected={
                        formik.values.invoice_date ? new Date(formik.values.invoice_date) : null
                      }
                      dateFormat='dd/MM/yyyy'
                      placeholderText={formatMessage({
                        id: 'Invoice date',
                      })}
                      id='invoice_date_id'
                      className='form-control form-control-md b-radius-16 basic-input '
                      onChange={(date: any) =>
                        formik.setFieldValue(`invoice_date`, date ? date : '')
                      }
                      maxDate={new Date()}
                      onChangeRaw={(e) => {
                        setFieldTouched(`invoice_date`, true, true);
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete='off'
                      disabled={mode === 'view'}
                    />
                    <ErrorMessage name='invoice_date' formik={formik} />
                  </div>
                  {/*  --------------------- end Sales Invoice date-----------------------  */}
                  {/*  ------------------------ Sales Invoice number -----------------------  */}
                  <div className='col-md-6'>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Invoice number',
                      })}
                      required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Invoice number',
                      })}
                      name={'invoice_number'}
                      type={'text'}
                      formik={formik}
                      disabled={
                        mode === 'view' || (mode === 'edit' && salesItem?.linked_invoices! > 0)
                      }
                    />
                  </div>
                  {/*  --------------------- end Sales Invoice number-----------------------  */}

                  {/*  ------------------------ Sales description en/ar -----------------------  */}
                  {locales.map((locale) => (
                    <div className='col-md-6'>
                      <Label
                        msg={formatMessage({
                          id: 'Sale description in ' + locale.toUpperCase(),
                        })}
                        htmlFor={'Sale description in ' + locale.toUpperCase()}
                        // isRequired
                      />

                      <TextArea
                        placeholder={formatMessage({
                          id: 'Sale description in ' + locale.toUpperCase(),
                        })}
                        autoComplete='off'
                        // labelId={formatMessage({
                        //   id: 'Sale description in ' + locale.toUpperCase(),
                        // })}
                        name={'description_' + locale}
                        type='text'
                        formik={formik}
                        dir={locale === 'ar' ? 'rtl' : 'ltr'}
                        rows='6'
                        id={'Sale description in ' + locale.toUpperCase()}
                        disabled={mode === 'view'}
                      />
                    </div>
                  ))}
                  {/*  --------------------- end Sales description en/ar-----------------------  */}
                  {/*  ---------------------  Sales Clients select-----------------------  */}
                  <div className='col-6'>
                    <Label msg='Client' htmlFor='clients' isRequired />
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadClientsOptions}
                      onChange={(value) => formik.setFieldValue(`clients`, value ? [value] : [])}
                      onBlur={() => formik.setFieldTouched(`clients`, true)}
                      value={formik.values?.clients}
                      defaultOptions
                      placeholder={formatMessage({
                        id: 'Client',
                      })}
                      isClearable
                      isDisabled={mode === 'view'}
                      className='react-select smaller'
                      menuPlacement='auto'
                      noOptionsMessage={() => {
                        return (
                          <span role='alert' className='text-danger'>
                            {formatMessage({
                              id: 'No options found',
                            })}
                          </span>
                        );
                      }}
                      loadingMessage={() => (
                        <div className='d-flex justify-content-center'>
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      )}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginBottom: 2,
                          paddingBottom: 2,
                          zIndex: 3,
                        }),
                        menuPortal: (base: any) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        noOptionsMessage: (base: any) => ({
                          ...base,
                          color: 'red',
                        }),
                      }}
                      components={{
                        Option: ({ children, ...props }: OptionProps<any, false>) => {
                          return (
                            <components.Option {...props}>
                              <div className='d-flex justify-content-between'>
                                <span>{props.data?.label}</span>
                              </div>
                            </components.Option>
                          );
                        },
                      }}
                      id={`clients`}
                    />
                    <ErrorMessage name='clients' formik={formik} />
                  </div>
                  {/*  --------------------- end Sales Clients select-----------------------  */}
                  {/*  ---------------------  Tax rate select-----------------------  */}
                  <div className='col-6'>
                    <Label msg='Tax rate' htmlFor='vat_rate' isRequired />
                    <Select
                      onChange={(value) => setFieldValue('vat_rate', [value])}
                      onBlur={() => formik.setFieldTouched('vat_rate', true)}
                      options={vatRateOptions?.filter(
                        (option) => formik.values.vat_rate[0]?.value !== option.value
                      )}
                      value={formik.values.vat_rate}
                      isLoading={vatRateLoading}
                      placeholder={$t('Tax rate')}
                      // isMulti
                      isDisabled={mode === 'view'}
                      className='react-select smaller'
                      id='vat_rate'
                    />
                    <ErrorMessage name='vat_rate' formik={formik} />
                  </div>
                  {/*  --------------------- end Tax rate select-----------------------  */}
                  {/*  ------------------------ sales Amount -----------------------  */}
                  <div className='col-md-6'>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Amount',
                      })}
                      required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Amount',
                      })}
                      name={'amount'}
                      type={'text'}
                      formik={formik}
                      disabled={mode === 'view'}
                    />
                  </div>
                  {/*  ------------------------end sales Amount -----------------------  */}
                  {/*  ---------------------  Select Categories-----------------------  */}
                  {formik.values?.clients.length > 0 && (
                    <div className='col-6'>
                      <Label msg='Categories' htmlFor='categories' />
                      <Select
                        onChange={(value) => setFieldValue('categories', [value])}
                        onBlur={() => formik.setFieldTouched('categories', true)}
                        options={categoriesOptions?.filter(
                          (option) => formik.values.categories[0]?.value !== option.value
                        )}
                        value={formik.values.categories}
                        isLoading={categoriesLoading}
                        placeholder={$t('Categories')}
                        // isMulti
                        isDisabled={mode === 'view'}
                        className='react-select smaller'
                        id='categories'
                      />
                      <ErrorMessage name='categories' formik={formik} />
                    </div>
                  )}
                  {/*  --------------------- end Select Categories-----------------------  */}
                  {/*  ------------------------ sales reference invoice -----------------------  */}
                  <div className='col-md-6'>
                    {(formik.values.vat_rate[0]?.is_return_code ||
                      (mode === 'view' && formik.values.invoice_reference)) && (
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id: 'Reference invoice number',
                        })}
                        autoComplete='off'
                        labelId={formatMessage({
                          id: 'Reference invoice number',
                        })}
                        name={'invoice_reference'}
                        type={'text'}
                        formik={formik}
                        disabled={mode === 'view'}
                      />
                    )}
                  </div>
                  {/*  ------------------------end sales reference invoice -----------------------  */}
                  {/*  ------------------------ sales Quantity -----------------------  */}
                  {/* <div className='col-md-6'> */}
                  {/* <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Quantity',
                      })}
                      required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Quantity',
                      })}
                      name={'quantity'}
                      type={'text'}
                      formik={formik}
                      disabled={mode === 'view'}
                    /> */}
                  {/* </div> */}
                  {/*  ------------------------end sales Quantity -----------------------  */}
                  {/*  ------------------------sales attachment -----------------------  */}
                  {/* <div className='col-md-6' style={{ maxWidth: '40%' }}>
                    <FileUploader
                      setFile={setAttachment}
                      file={attachment}
                      disabled={mode === 'view'}
                      maxSize={2 * 1024}
                      supportedFiles={[
                        'application/pdf',
                        'image/jpeg',
                        'jpeg',
                        'image/png',
                        'png',
                        'image/jpg',
                        'jpg',
                        'image/svg+xml',
                        'svg+xml',
                        'image/svg',
                        'svg',
                      ]}
                      typesOnly='pdfs-images'
                    />
                  </div> */}
                  {/*  ------------------------end sales attachment -----------------------  */}
                </div>

                {/* Save and Cancel Buttons */}
                {mode !== 'view' && (
                  <>
                    <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.push(`/c/${companyDetails.slug}/tax-module/sales`);
                      }}
                    />
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>

        {/*  ---------------------warnings confirmation Modal-----------------------  */}

        {showWarningsConfirmationModal && warningsConfirmation && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={
                () => setShowWarningsConfirmationModal(false)

                // history.push(location.pathname.replace('/invite-users', '/users'))
              }
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                  {warningsConfirmation.length === 1 && <span>{warningsConfirmation[0]}</span>}
                  {warningsConfirmation.length > 1 && (
                    <ol>
                      {warningsConfirmation.map((warning: any, index: any) => (
                        <li key={index}>{warning}</li>
                      ))}
                    </ol>
                  )}
                </div>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setApproveWarnings(1);
                          setShowWarningsConfirmationModal(false);
                          formik.submitForm();
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Save'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          // setApproveWarnings(0);
                          setShowWarningsConfirmationModal(false);
                          // formik.submitForm();
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}
        {/*  ---------------------end warnings confirmation Modal-----------------------  */}
      </div>

      {/*  ---------------------linked Invoices-----------------------  */}

      {salesItem?.linked_invoices! > 0 && (
        <div className='content-wrapper bg-white b-radius-25 mt-3' style={{ width: '100%' }}>
          <div className=' px-5 '>
            <div className='d-flex justify-content-center px-10 py-3 fs-6 '>
              {$t('Linked invoices')}
            </div>
          </div>
          <SeparatorLines lines={2} />
          <div className=' px-5 '>
            <div className='row my-3'>
              <SalesWrapper linkedInvoices={linkedInvoices} />
            </div>
          </div>
          {/*  ------------------------ Remaining amount -----------------------  */}
          <SeparatorLines lines={3} />

          <div className='row m-3'>
            <div className='col-md-6'>
              <Label
                msg={formatMessage({
                  id: 'Remaining amount',
                })}
                htmlFor={'Remaining amount'}
                // isRequired
              />
            </div>
            <div className='col-md-6'>{salesItem?.remaining_amount}</div>
          </div>

          {/*  --------------------- end Remaining amount-----------------------  */}
        </div>
      )}

      {/*  --------------------- end linked Invoices-----------------------  */}
    </div>
  );
};

const ConfigNewSalesWrapperCreate: FC = () => {
  usePermissions(['create-sale']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales' })}</PageTitle>
      {/* <div> Coming Soon </div> */}
      <ConfigNewSalesWrapper mode='create' />
    </div>
  );
};

const ConfigNewSalesWrapperEdit: FC = () => {
  usePermissions(['update-sale']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales' })}</PageTitle>
      <ConfigNewSalesWrapper mode='edit' />
    </div>
  );
};

const ConfigNewSalesWrapperView: FC = () => {
  usePermissions(['view-sale']);
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Sales' })}</PageTitle>
      <ConfigNewSalesWrapper mode='view' />
    </div>
  );
};
export { ConfigNewSalesWrapperCreate, ConfigNewSalesWrapperEdit, ConfigNewSalesWrapperView };
