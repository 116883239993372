/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, ErrorMessage } from 'formik';

import { KTSVG } from '../../../../../_metronic/helpers';

const Step1ApproveOrReturn: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          <FormattedMessage id='Document Process' />
        </h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='approvement'
              value='approve'
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr085.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  <FormattedMessage id='Approve document and continue' />
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='approvement'
              value='returnToDocumentCreator'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr029.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  <FormattedMessage id='Return to document creator' />
                </span>
                {/* <span className='text-gray-400 fw-bold fs-6'>
                </span> */}
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='approvement' />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1ApproveOrReturn };
