import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { getAllCompanies } from './CompaniesDeletionRequestsCRUD';

export enum ICompaniesDeletionRequestsActionTypes {
  GET_COMPANIES_DELETION_REQUESTS = 'GET_COMPANIES_DELETION_REQUESTS',
  FILL_FULL_COMPANIES_DELETION_REQUESTS = 'FILL_FULL_COMPANIES_DELETION_REQUESTS',
}

const initialCompaniesDeletionRequestsState: ICompaniesDeletionRequestsState = {
  companies_deletion_requests: undefined,
};

export interface ICompaniesDeletionRequestsState {
  companies_deletion_requests?: any;
}

export const reducer = persistReducer(
  { storage, key: 'SYS_companies_deletion_requests', whitelist: [''] },
  (
    state: ICompaniesDeletionRequestsState = initialCompaniesDeletionRequestsState,
    action: ActionWithPayload<ICompaniesDeletionRequestsState>
  ) => {
    switch (action.type) {
      case ICompaniesDeletionRequestsActionTypes.FILL_FULL_COMPANIES_DELETION_REQUESTS:
        return {
          ...state,
          companies_deletion_requests: action.payload?.companies_deletion_requests,
        };

      default:
        return state;
    }
  }
);
type CompaniesSearch = { params?: any };

export const actions = {
  getCompaniesDeletionRequests: ({ params }: CompaniesSearch) => ({
    type: ICompaniesDeletionRequestsActionTypes.GET_COMPANIES_DELETION_REQUESTS,
    payload: { params },
  }),
  fulFillCompaniesDeletionRequests: ({
    companies_deletion_requests,
  }: ICompaniesDeletionRequestsState) => ({
    type: ICompaniesDeletionRequestsActionTypes.FILL_FULL_COMPANIES_DELETION_REQUESTS,
    payload: { companies_deletion_requests },
  }),
};

export function* getCompaniesDeletionRequests(action: ActionWithPayload<CompaniesSearch>) {
  try {
    const { data } = yield getAllCompanies({
      params: action.payload?.params,
    });
    yield put(actions.fulFillCompaniesDeletionRequests({ companies_deletion_requests: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(
    ICompaniesDeletionRequestsActionTypes.GET_COMPANIES_DELETION_REQUESTS,
    getCompaniesDeletionRequests
  );
}
