import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ID } from '../../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { UsersDataListModel } from '../types/usersList';
import { user } from '../../../../types/user';
import { getUserGroupsList, getSingleUserGroupRequest } from './UsersGroupCrud';

export enum IUsersGroupsActionTypes {
  GET_USER_GROUPS = 'GET_USER_GROUPS',
  GET_SINGLE_USER_GROUP = 'GET_SINGLE_USER_GROUP',
  FULLFULL_USER_GROUPS = 'FULLFULL_USER_GROUPS',
  FULLFULL_SINGLE_USER_GROUP = 'FULLFULL_SINGLE_USER_GROUP',
}

const initialUsersState: IUsersState = {
  Users: undefined,
  singleUser: undefined,
  reviewers: undefined,
};

export interface IUsersState {
  Users?: UsersDataListModel;
  singleUser?: user;
  reviewers?: { id: number; name: string }[];
}

export const reducer = persistReducer(
  { storage, key: 'Users-Group', whitelist: [''] },
  (state: IUsersState = initialUsersState, action: ActionWithPayload<IUsersState>) => {
    switch (action.type) {
      case IUsersGroupsActionTypes.FULLFULL_USER_GROUPS:
        return { ...state, Users: action.payload?.Users };
      case IUsersGroupsActionTypes.FULLFULL_SINGLE_USER_GROUP:
        return { ...state, singleUser: action.payload?.singleUser };
      default:
        return state;
    }
  }
);

type CompanyUsersSearch = { companyId: ID | any; params?: any };
type SingleUserBasicData = { companyId: ID | any; userId?: ID | any };

export const actions = {
  getUsers: ({ companyId, params }: CompanyUsersSearch) => ({
    type: IUsersGroupsActionTypes.GET_USER_GROUPS,
    payload: { companyId, params },
  }),
  fulfillUsers: ({ Users }: IUsersState) => ({
    type: IUsersGroupsActionTypes.FULLFULL_USER_GROUPS,
    payload: { Users },
  }),
  getSingleUser: ({ companyId, userId }: SingleUserBasicData) => ({
    type: IUsersGroupsActionTypes.GET_SINGLE_USER_GROUP,
    payload: { companyId, userId },
  }),
  fulfillSingleUser: ({ singleUser }: IUsersState) => ({
    type: IUsersGroupsActionTypes.FULLFULL_SINGLE_USER_GROUP,
    payload: { singleUser },
  }),
};

export function* getUsers(action: ActionWithPayload<CompanyUsersSearch>) {
  try {
    // Getting Users request
    const { data } = yield getUserGroupsList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing users to store
    yield put(actions.fulfillUsers({ Users: data }));
  } catch (error) {
    //
  }
}

export function* getSingleUserGroup(action: ActionWithPayload<SingleUserBasicData>) {
  try {
    // Getting Users request
    const { data } = yield getSingleUserGroupRequest({
      companyId: action.payload?.companyId,
      userId: action.payload?.userId,
    });
    // Storing users to store
    yield put(actions.fulfillSingleUser({ singleUser: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IUsersGroupsActionTypes.GET_USER_GROUPS, getUsers);
  yield takeLatest(IUsersGroupsActionTypes.GET_SINGLE_USER_GROUP, getSingleUserGroup);
}
