import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyTransactionsDetails = endpoints.companyTransactionsDetails;

export function getAllTransactionsDetails({
  companyId,
  reportId,
  params,
}: {
  companyId: ID;
  reportId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyTransactionsDetails(companyId, reportId), { params });
}
