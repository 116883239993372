import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyProducts = endpoints.companyProducts;
export const companySingleProduct = endpoints.companySingleProduct;

export function getProductList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyProducts(companyId), { params });
}

export function getCurrentProductRequest({
  companyId,
  productId,
}: {
  companyId: ID;
  productId: ID;
}) {
  return axiosInstance.get(companySingleProduct(companyId, productId));
}
