import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import _ from 'lodash';

import { ActionWithPayload } from '../interface/ActionWithPayload';
import { Permission } from '../pages/roles/types/permission';
import { CurrentCompanyUser } from '../types/CompanyTypes';

export enum ICurrentCompanyTypes {
  SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY',
  CLOSE_CURRENT_COMPANY = 'CLOSE_CURRENT_COMPANY',
}

interface ICurrentCompanyState {
  current?: CurrentCompanyUser;
  permissions?: Permission[];
}

const initialModalState: ICurrentCompanyState = {
  current: undefined,
  permissions: [],
};

export const reducer = persistReducer(
  { storage, key: 'currentCompany', whitelist: ['current', 'permissions'] },
  (
    state: ICurrentCompanyState = initialModalState,
    action: ActionWithPayload<ICurrentCompanyState>
  ) => {
    switch (action.type) {
      case ICurrentCompanyTypes.SET_CURRENT_COMPANY:
        return {
          ...state,
          current: action.payload?.current,
          permissions: action.payload?.permissions,
        };
      default:
        return state;
    }
  }
);

export const actions = {
  setCurrentCompany: (current: CurrentCompanyUser) => {
    return {
      type: ICurrentCompanyTypes.SET_CURRENT_COMPANY,
      payload: {
        current: current,
        permissions: _.uniq(
          current?.user?.roles
            .map((role) => role.permissions.map((permission) => permission.name))
            .flat()
        ),
      },
    };
  },
};
