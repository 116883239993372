import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { ICompany } from '../../../types/companies';
import {
  getCurrentCompanyById,
  getUsersCompaniesRequest,
  getAccountOwnerInfoRequest,
} from './UserCompaniesCRUD';

export enum IUserCompaniesActionTypes {
  GET_USERS_COMPANIES = 'GET_USERS_COMPANIES',
  FULLFILL_COMPANIES = 'FULLFILL_COMPANIES',
  FULLFILL_SINGLE_COMPANY = 'FULLFILL_SINGLE_COMPANY',
  SET_LAST_ADDED_COMPANY = 'SET_LAST_ADDED_COMPANY',
  GET_CURRENT_BY_ID = 'GET_CURRENT_BY_ID',
  GET_ACCOUNT_OWNER_iNFO = 'GET_ACCOUNT_OWNER_iNFO',
  FULL_FILL_ACCOUNT_OWNER_iNFO = 'FULL_FILL_ACCOUNT_OWNER_iNFO',
}

const initialCompaniesState: ICompaniesState = {
  companies: undefined,
  lastAddedCompany: undefined,
  accountOwnerInfo: undefined,
  companyDetails: {},
};

export interface ICompaniesState {
  companies?: {};
  lastAddedCompany?: null;
  accountOwnerInfo?: null;
  companyDetails?: {};
}

export const reducer = persistReducer(
  { storage, key: 'companies', whitelist: ['lastAddedCompany', 'companies', 'companyDetails'] },
  (state: ICompaniesState = initialCompaniesState, action: ActionWithPayload<ICompaniesState>) => {
    switch (action.type) {
      case IUserCompaniesActionTypes.FULLFILL_COMPANIES:
        return { ...state, companies: action.payload?.companies };

      case IUserCompaniesActionTypes.SET_LAST_ADDED_COMPANY:
        const lastAddedCompany = action.payload?.lastAddedCompany;
        return { ...state, lastAddedCompany };

      case IUserCompaniesActionTypes.FULLFILL_SINGLE_COMPANY:
        const companyDetails = action.payload?.companyDetails;
        return { ...state, companyDetails };

      case IUserCompaniesActionTypes.FULL_FILL_ACCOUNT_OWNER_iNFO:
        const accountOwnerInfo = action.payload?.accountOwnerInfo;
        return { ...state, accountOwnerInfo };

      default:
        return state;
    }
  }
);

type CompaniesSearch = { params?: any };

export const actions = {
  getUserCompanies: ({ params }: CompaniesSearch) => ({
    type: IUserCompaniesActionTypes.GET_USERS_COMPANIES,
    payload: { params },
  }),
  fulFillCompanies: ({ companies }: ICompaniesState) => ({
    type: IUserCompaniesActionTypes.FULLFILL_COMPANIES,
    payload: { companies },
  }),
  getCurrentCompanyById: ({ id }: { id: number | undefined }) => ({
    type: IUserCompaniesActionTypes.GET_CURRENT_BY_ID,
    payload: { id },
  }),
  fulfillSingleComany: ({ companyDetails }: ICompaniesState) => ({
    type: IUserCompaniesActionTypes.FULLFILL_SINGLE_COMPANY,
    payload: { companyDetails },
  }),
  setLastAddedCompany: (lastAddedCompany: ICompany | null) => ({
    type: IUserCompaniesActionTypes.SET_LAST_ADDED_COMPANY,
    payload: { lastAddedCompany },
  }),
  getAccountOwnerInfo: () => ({
    type: IUserCompaniesActionTypes.GET_ACCOUNT_OWNER_iNFO,
  }),
  fulfillAccountOwnerInfo: ({ accountOwnerInfo }: ICompaniesState) => ({
    type: IUserCompaniesActionTypes.FULL_FILL_ACCOUNT_OWNER_iNFO,
    payload: { accountOwnerInfo },
  }),
};

export function* getUsersCompaniesSaga(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getUsersCompaniesRequest({
      params: action.payload?.params,
    });
    yield put(actions.fulFillCompanies({ companies: data }));
  } catch (error) {
    //
  }
}

export function* getCompanyByIdSaga(action: ActionWithPayload<{ id: any }>) {
  try {
    const { data } = yield getCurrentCompanyById({ id: action?.payload?.id });
    yield put(actions.fulfillSingleComany({ companyDetails: data }));
  } catch (error) {
    //
  }
}

export function* getAccountOwnerInfo() {
  try {
    const { data } = yield getAccountOwnerInfoRequest();
    yield put(actions.fulfillAccountOwnerInfo({ accountOwnerInfo: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IUserCompaniesActionTypes.GET_USERS_COMPANIES, getUsersCompaniesSaga);
  yield takeLatest(IUserCompaniesActionTypes.GET_CURRENT_BY_ID, getCompanyByIdSaga);
  yield takeLatest(IUserCompaniesActionTypes.GET_ACCOUNT_OWNER_iNFO, getAccountOwnerInfo);
}
