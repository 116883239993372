import { FC } from 'react';
import { Content } from './components/Content';
import { DocumentCrudHeader } from './components/header/DocumentCrudHeader';
import { PageDataProvider } from './core';

const DocumentBuilderLayout: FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className='d-flex flex-row flex-column-fluid'>
        <div className='builder-wrapper d-flex flex-column flex-row-fluid' id='template_layout'>
          <DocumentCrudHeader />
          <div id='template_layout_content' className='content d-flex flex-row'>
            <div className='flex-fill pt-3'>
              <div className='post d-flex flex-column-fluid' id='content'>
                <Content>{children}</Content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageDataProvider>
  );
};

export { DocumentBuilderLayout };
