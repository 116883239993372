import * as Yup from 'yup';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
// import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
// import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';

export interface ICreateReport {
  report_name_ar: string;
  report_name_en: string;
  report_period: [];
  monthly_period: [];
  year_period: [];
}

const useConfigureReportsSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const reportsInitialValues: ICreateReport = useMemo(
    () => ({
      report_name_ar: '',
      report_name_en: '',
      report_period: [],
      monthly_period: [],
      year_period: [],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const reportsValidationSchema = useMemo(
    () => [
      Yup.object({
        report_period: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
        // report_name_en: Yup.string()
        //   .required(required)
        //   .min(
        //     commonValidations.nameMinimum,
        //     $t('Please enter at least {minCharacters} characters', {
        //       minCharacters: commonValidations.nameMinimum,
        //     })
        //   )
        //   .max(
        //     commonValidations.nameMaximum,
        //     $t('The Maximum characters is {maxCharacters}', {
        //       maxCharacters: commonValidations.nameMaximum,
        //     })
        //   )
        //   .test(
        //     'report_name_en',
        //     $t("You can't enter special character at first or last!"),
        //     (val) => nameWithoutSpcialCharsStartOrEnd(val)
        //   ),
        // report_name_ar: Yup.string()
        //   .required(required)
        //   .min(
        //     commonValidations.nameMinimum,
        //     $t('Please enter at least {minCharacters} characters', {
        //       minCharacters: commonValidations.nameMinimum,
        //     })
        //   )
        //   .max(
        //     commonValidations.nameMaximum,
        //     $t('The Maximum characters is {maxCharacters}', {
        //       maxCharacters: commonValidations.nameMaximum,
        //     })
        //   )
        //   .test(
        //     'report_name_ar',
        //     $t("You can't enter special character at first or last!"),
        //     (val) => nameWithoutSpcialCharsStartOrEnd(val)
        //   ),
        monthly_period: Yup.array()
          // .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
        year_period: Yup.array()
          // .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
      }),
      Yup.object({}),
    ],
    [formatMessage]
  );

  return { reportsValidationSchema, reportsInitialValues };
};
// export { createReportSchemas, inits };
export { useConfigureReportsSchema };
