/* eslint-disable import/first */
import { useEffect, useState } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
// Import bootstrap(v3 or v4) dependencies
import 'bootstrap/js/dist/modal';
// import $ from 'jquery';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../network/apis';
import { allowedImagesTypes } from '../../_metronic/constants/general';

function RichText({ name, setText, text }) {
  const [html, setHtml] = useState('');
  const [display, setDipslay] = useState(false);

  useEffect(() => {
    if (html && html !== text) {
      setText(html);
    }
  }, [html]);

  useEffect(() => {
    if (text && text !== html) {
      setHtml(text);
    }
  }, []);

  useEffect(() => {
    if (display) {
      $(`#${name} .dropdown-toggle`).dropdown();
    }
  }, [display]);

  useEffect(() => {
    setTimeout(() => {
      setDipslay(true);
    }, 1000);
  });

  async function onImageUpload(fileList, addToHtml) {
    for (let file of fileList) {
      if (!allowedImagesTypes.includes(file?.type)) {
        toast.error('');
        return;
      } else {
        const formData = new FormData();
        formData.append('file', fileList[0]);
        await axiosInstance.post('/media', formData).then((res) => {
          addToHtml(res.data.data.path);
        });
      }
    }
  }

  return (
    <div id={name}>
      {display && (
        <ReactSummernote
          // insertText={name}
          options={{
            height: 350,
            dialogsInBody: true,
            toolbar: [
              ['style', ['style']],
              ['font', ['bold', 'underline', 'clear']],
              ['fontname', ['fontname']],
              ['color', ['color']],
              ['para', ['ul', 'ol', 'paragraph']],
              ['table', ['table']],
              ['insert', ['link' /* 'picture' */]],
              ['view', ['fullscreen', 'codeview']],
            ],
          }}
          onChange={(content) => setHtml(content)}
          // This solution is by meetalodariya in the issues on github
          // To Set Default value on load because value="Default value" didn't work
          // https://github.com/summernote/react-summernote/issues/17
          onImageUpload={() => onImageUpload()}
          onInit={() => {
            const editArea = document.querySelector(`#${name}`).querySelector('.note-editable');
            editArea.innerHTML = text;
          }}
          value={html}
        />
      )}
    </div>
  );
}

export default RichText;
