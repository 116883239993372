import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';

import { RootState } from '../../../setup/redux/Store';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { SeparatorLines } from '../../shared/Lines';
import { CompanyModel } from '../companies/CompanyModel';

import * as RolesRedux from '../roles/store/RolesRedux';
import * as UsersRedux from './store/UsersRedux';

import { Role, RolesDataListModel } from '../roles/types/Roles';
import { user } from '../../types/user';
import { userStatusColorsMap } from '../../../_metronic/constants/colors';
import { Input } from '../../shared/Input';
import { useFormik } from 'formik';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { formatDate } from '../../shared/tables/classes/DateViewer';
import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { commonValidations } from '../../../_metronic/constants/GeneralValidations';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../network/apis';
import { usePermissions } from '../../hooks/usePermissions';

type Props = {
  mode: 'edit' | 'view';
};

export const UsersEditByCompanyOwner: FC<Props> = ({ mode }) => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];
  const [filteredOption, setSilteredOption] = useState<Role[]>([]);
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        roles: Yup.array().min(1, $t('Please choose at least one')),
        position_ar: Yup.string()
          .min(
            commonValidations.nameMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.nameMinimum }
            )
          )
          .max(
            commonValidations.nameMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.nameMaximum }
            )
          )
          .test(
            'position_ar',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        position_en: Yup.string()
          .min(
            commonValidations.nameMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.nameMinimum }
            )
          )
          .max(
            commonValidations.nameMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.nameMaximum }
            )
          )
          .test(
            'position_en',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
      }),
    [formatMessage]
  );

  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const roles: RolesDataListModel = useSelector<RootState>(
    (state) => state.roles.roles,
    shallowEqual
  ) as RolesDataListModel;

  const user: user = useSelector<RootState>(
    (state) => state.USER_users.singleUser,
    shallowEqual
  ) as user;

  /* ----------------------- Getting roles for dropdown ----------------------- */
  useEffect(() => {
    if (companyDetails?.id && params.companySlug) {
      dispatch(
        RolesRedux.actions.getRoles({ companyId: companyDetails.id, params: { per_page: 0 } })
      );
    }
    if (companyDetails?.id && params.companySlug) {
      dispatch(
        UsersRedux.actions.getSingleUser({ companyId: companyDetails.id, userId: params.userId })
      );
    }
  }, [dispatch, companyDetails?.id, params.companySlug]);

  const formik = useFormik({
    initialValues: {
      ...user,
      position_ar: user?.position?.ar,
      position_en: user?.position?.en,
      roles: user?.roles?.map((role) => ({
        value: role?.id,
        label: role?.name,
        is_company_admin: role?.is_company_admin,
      })),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        await axiosInstance.put(`/${companyDetails.id}/users/${user.id}`, {
          position: {
            en: values.position_en,
            ar: values.position_ar,
          },
          roles: values.roles.map((role) => role?.value),
        });
        toast.success(formatMessage({ id: 'The changes have been successfully made!' }));
      } catch (error: any) {
        DisplayErrors(error);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    formik.setFieldValue('position_ar', user?.position?.ar);
    formik.setFieldValue('position_en', user?.position?.en);
    formik.setFieldValue(
      'roles',
      user?.roles?.map((role) => ({
        value: role?.id,
        label: role?.name,
        is_company_admin: role?.is_company_admin,
      }))
    );
    return () => {
      formik.resetForm();
    };
  }, [user]);

  useEffect(() => {
    return () => {
      formik.resetForm();
      dispatch(UsersRedux.actions.fulfillSingleUser({}));
    };
  }, []);
  useEffect(() => {
    const filteredArray = roles?.data?.filter(
      (object: any) =>
        user?.roles.findIndex((matchedObject) => matchedObject.id === object.id) === -1
    );
    setSilteredOption(filteredArray);
  }, [roles, user]);
  /* ----------------------------------- styles ---------------------------------- */

  const styles = {
    multiValueRemove: (base: any, state: any) => {
      return state.data.is_company_admin && user?.isCompanyAdmin
        ? { ...base, display: 'none' }
        : base;
    },
  };
  return (
    <div>
      <div className='d-flex flex-row mb-4 gap-15'>
        <p>{user?.name}</p>
        <div className='pl-10 pr-10'>
          <span className='menu-bullet mx-1'>
            <span className='bullet bullet-dot'></span>
          </span>
          {$t('Status')}
          <span className={`badge ${userStatusColorsMap.get(user?.active ? 1 : 0)?.badge} mx-2`}>
            {$t(userStatusColorsMap.get(user?.active ? 1 : 0)?.labelId || 'missing')}
          </span>
        </div>
      </div>
      <div className='content-wrapper bg-white b-radius-25'>
        <div className='d-flex px-5'>
          <div className='px-10 py-3 fs-6' style={{ width: '35%' }}>
            {$t('User Track')}
          </div>
          <div
            className='px-10 py-3 fs-6'
            style={{ width: '65%', borderRight: '1px solid #e1eaf5' }}
          >
            {$t('Personal Information')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5'>
          <div className='p-10' style={{ width: '35%' }}>
            {/* Date Of Profile Creation */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span>{' '}
              <span>{$t('Date Of Profile Creation')}:</span>{' '}
              <span className='text-blueGrey'>{formatDate(user?.created_at)}</span>
            </div>
            <SeparatorLines lines={1} />
            {/*  Date Of Last Seen */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span> <span>{$t('Date Of Last Seen')}:</span>{' '}
              <span className='text-blueGrey'>
                {formatDate(user?.last_seen || user?.created_at)}
              </span>
            </div>
            <SeparatorLines lines={1} />
            {/*  Number Of Created Docs */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span>{' '}
              <span>{$t('Number Of Created Docs')}:</span>{' '}
              <span className='text-blueGrey'>{user?.docs_count}</span>
            </div>
            <SeparatorLines lines={1} />
            {/*  Number Of Completed Docs */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span>{' '}
              <span>{$t('Number Of Completed Docs')}:</span>{' '}
              <span className='text-blueGrey'>{user?.completed_docs_count}</span>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate style={{ width: '65%' }}>
            <div className='p-10' style={{ width: '100%', borderRight: '1px solid #e1eaf5' }}>
              <div className='symbol symbol-circle avatar-border symbol-60px symbol-md-100px mx-3 mb-10'>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={user?.image || toAbsoluteUrl('/media/avatars/blank.png')} />
              </div>
              <div className='row'>
                <div className='col-6'>
                  <Input
                    disabled
                    placeholder={$t('Full name')}
                    autoComplete='off'
                    labelId='Full name'
                    formik={formik}
                    name='name'
                    type='name'
                  />
                </div>
                <div className='col-6'>
                  <Input
                    disabled
                    placeholder={$t('Email')}
                    autoComplete='off'
                    labelId='Email'
                    formik={formik}
                    name='email'
                    type='email'
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-6'>
                  <Input
                    disabled
                    placeholder={$t('Mobile Number')}
                    autoComplete='off'
                    labelId='Mobile Number'
                    formik={formik}
                    name='mobile'
                    type='mobile'
                  />
                </div>
              </div>
              <div className='row mt-3'>
                {locales.map((locale) => (
                  <div className='col-6'>
                    <Input
                      disabled={mode === 'view'}
                      placeholder={$t('Position Name in ' + locale.toUpperCase())}
                      autoComplete='off'
                      labelId={'Position Name in ' + locale.toUpperCase()}
                      formik={formik}
                      name={`position_${locale}`}
                      type={`position_${locale}`}
                    />
                  </div>
                ))}
              </div>
              <div className='row mt-3'>
                <label className='form-label fs-6 my-1 fw-bolder text-dark' htmlFor={'roles'}>
                  <FormattedMessage id={'Roles'} />
                </label>
                <Select
                  isSearchable={false}
                  isClearable={false}
                  id='roles'
                  isDisabled={mode === 'view'}
                  placeholder={$t('Roles')}
                  className='react-select smaller'
                  options={filteredOption?.map((role: any) => ({
                    value: role.id.toString(),
                    label: role.name,
                    is_company_admin: role?.is_company_admin,
                  }))}
                  value={formik.values.roles}
                  isMulti
                  onChange={(selection) => formik.setFieldValue('roles', selection)}
                  styles={styles}
                />
                {formik?.errors?.roles && formik.touched.roles && (
                  <div className='fv-plugins-message-container mt-2 mx-3'>
                    <span role='alert' className='text-danger'>
                      {formik?.errors?.roles}
                    </span>
                  </div>
                )}
              </div>
              {mode === 'edit' && (
                <>
                  <button type='submit' className='btn btn-primary btn-sm'>
                    {!loading && $t('save')}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    className='btn btn-sm btn-danger m-5'
                    onClick={() =>
                      history.push(`/c/${companyDetails.slug}/company-management/users`)
                    }
                  >
                    {$t('Cancel')}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const UsersEditWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'User Profile' })}</PageTitle>
      <UsersEditByCompanyOwner mode='edit' />
    </div>
  );
};

const UsersViewWrapper: FC = () => {
  const { formatMessage } = useIntl();
  const { userCan } = usePermissions();
  const history = useHistory();
  const location = useLocation();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'User Profile' })}</PageTitle>
      <UsersEditByCompanyOwner mode='view' />
      {userCan('edit-user') && (
        <div className='mt-7'>
          <button
            className='btn btn-sm btn-lightGreyBlue'
            onClick={() => history.push(location.pathname + '/edit')}
          >
            <FormattedMessage id='Edit user profile' />
          </button>
        </div>
      )}
    </div>
  );
};

export { UsersEditWrapper, UsersViewWrapper };
