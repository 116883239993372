import { FC } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { capitalize } from '../../../../../utils/strings';
import { actionStatusColor } from '../../../../assets/ts/_utils/models/DocsActions';
import { RencentAction } from '../types';

type Props = {
  action: RencentAction;
};

export const ActionCard: FC<Props> = ({ action }) => {
  const { formatDate, formatMessage } = useIntl();
  const { actionTitle, by, price, status, viewedBy, image, id } = action;

  return (
    <div className='d-flex flex-column rounded px-5 my-4 doctype-card'>
      <div className='d-flex justify-content-start mb-3 pt-3'>
        <div className='symbol symbol-50px me-5'>
          <span className='symbol-label'>
            <div className='cursor-pointer symbol symbol-circle avatar-border d-flex flex-row symbol-md-50px'>
              <img
                className='circular-light-border'
                src={image || './media/avatars/blank.png'}
                alt='avatar'
              />
            </div>
          </span>
        </div>
        <div className='d-flex align-items-top flex-row-fluid flex-wrap'>
          <div className='flex-grow-1  me-2 flex-fill'>
            <div
              style={{ maxWidth: '160px' }}
              className='text-gray-800 text-hover-primary fs-6 fw-bolder line-clamp-1'
            >
              {actionTitle}
            </div>
            <div className='text-muted fw-bold d-block fs-7 mw-190px'>
              <strong>
                <FormattedMessage id='By: ' />
              </strong>
              <FormattedMessage id='values' values={{ values: by }} />
            </div>
            <div className='text-muted fw-bold d-block fs-7'>
              <strong>
                <FormattedMessage id='Price: ' />
              </strong>
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber value={Number(price) || 0} style='currency' currency='USD' />
            </div>
          </div>
          <div className={`badge text-${actionStatusColor[status]} fw-bolder`}>
            <span
              className={`bullet bullet-dot bg-${actionStatusColor[status]} h-6px w-6px translate-middle`}
            ></span>{' '}
            {capitalize(status)}
          </div>
        </div>
      </div>
      {status === 'closed' && (
        <div className='badge badge-success w-100 text-start mb-3'>
          <FormattedMessage id='This document was completed by all recipients' />
        </div>
      )}
      {viewedBy && status === 'waitingforcompanysignature' && (
        <div className='badge badge-primary w-100 text-start mb-3'>
          <FormattedMessage
            id='somone.viewed.on.data'
            values={{ viewer: <u>{viewedBy.viewer}</u>, date: formatDate(viewedBy.date) }}
          />
          -
          <FormattedMessage
            id='write.some.feedback'
            values={{
              feedback: (
                <a href={`action/${id}/feedback/${viewedBy.id}`} className='text-white underline'>
                  {formatMessage({ id: 'feedback' })}
                </a>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};
