import clsx from 'clsx';
import { $t } from '../../../../../_metronic/i18n/formatMessage';
import { SeparatorLines } from '../../../../shared/Lines';
import { useEffect, useState } from 'react';
import { LogoUploader } from '../../../Companies/CompanyDetails/components/LogoUploader';
import { useFormikContext } from 'formik';

interface IBranding {
  logo: {
    light: string;
    dark: string;
  };
  mark: {
    light: string;
    dark: string;
  };
}
export default function StepBranding() {
  /* ------------------------------- Local State ------------------------------ */
  const maxFileSizeInMegaBytes = 2;
  const [mainLightLogo, setmainLightLogo] = useState<Blob | string>('');
  const [mainDarkLogo, setMainDarkLogo] = useState<Blob | string>('');
  const [mainLightMarkLogo, setMainLogoMarkLight] = useState<Blob | string>('');
  const [mainDarkMarkLogo, setMainDarkMarkLogo] = useState<Blob | string>('');
  const images = [
    {
      image: mainLightLogo,
      title: 'Upload a Company Logo- Light Style',
      setFunction: (f: Blob) => setmainLightLogo(f),
    },
    {
      image: mainDarkLogo,
      title: 'Upload a Company Logo- Dark Style',
      setFunction: (f: Blob) => setMainDarkLogo(f),
    },
    {
      image: mainLightMarkLogo,
      title: 'Upload a Company Logo- Light Mark',
      setFunction: (f: Blob) => setMainLogoMarkLight(f),
    },
    {
      image: mainDarkMarkLogo,
      title: 'Upload a Company Logo- Dark Mark',
      setFunction: (f: Blob) => setMainDarkMarkLogo(f),
    },
  ];
  /* ---------------------------------- Hooks --------------------------------- */
  const formik = useFormikContext<any>();

  useEffect(() => {
    if (formik.values?.branding) {
      const branding: IBranding = formik.values.branding;
      setmainLightLogo(branding.logo.light);
      setMainDarkLogo(branding.logo.dark);
      setMainLogoMarkLight(branding.mark.light);
      setMainDarkMarkLogo(branding.mark.dark);
    }
  }, [formik]);
  return (
    <div className='table-wrapper card mt-10'>
      <div className='table-wrapper bg-white pb-10'>
        <h3 className='m-2 mx-5'>{$t('Branding')}</h3>
        <SeparatorLines lines={2} />
        <div className='p-10 ' style={{ width: '100%' }}>
          <div className={clsx('contianer gap-10 jusify-content-center')}>
            <div className='row'>
              {images.map((image, i) => (
                <div className='col-xs-12 col-md-8 col-lg-6 col-xl-5 pt-10'>
                  <LogoUploader
                    key={image.title}
                    title={image.title}
                    width={300}
                    setFunction={(file: any) => image.setFunction(file)}
                    maxSizeInBytes={maxFileSizeInMegaBytes * 1024}
                    image={image.image}
                    isDisabled
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
