/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom'; //,  useParams useLocation,
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

import { toast } from 'react-toastify';

import 'react-intl-tel-input/dist/main.css';

import { RootState } from '../../../setup/redux/Store';

import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePermissions } from '../../hooks/usePermissions';
import { CompanyModel } from '../companies/CompanyModel';

import { SeparatorLines } from '../../shared/Lines';
import { DownloadButton } from '../../shared/DownloadButton';
// import { Button } from '../../shared/Button';

import { FileUploader } from '../../pages/Companies/CompanyDetails/components/FileUploader';

import { axiosInstance } from '../../../network/apis';

import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { ImportNewClientsDisclamer } from './ImportNewClientsDisclamer';
// import { useQuerySearch } from '../../hooks/useQuerySearch/useQuerySearch';

type Props = {
  mode: 'create';
};
export const ImportNewClientsWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [file, setFile] = useState<any>('');
  const [loading, setLoading] = useState(false);
  // const { pathname } = useLocation();
  // let query = useQuerySearch();
  // console.log('pathname', pathname);

  /* ----------------------------- Getting Templates ----------------------------- */

  const importThirdParty = async () => {
    setLoading(true);

    const formData = serialize({}, { indices: true });
    if (file) {
      formData.append('file', file);
    }
    await axiosInstance
      .post(`${companyDetails.id}/third_parties/import`, formData)
      .then((res) => {
        toast.success(
          formatMessage({
            id: 'File is uploaded successfully. Data will be shown once the processing is done',
          })
        );
        history.push(`/c/${companyDetails.slug}/company-management/clients-and-vendors`);
        return res;
      })
      .catch((err) => {
        formData.delete('file');
        setFile('');
        DisplayErrors(err);
        return err;
      });

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => importThirdParty(),
  });

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  /* ----------------------- end getting data from store ---------------------- */

  /* -------------------------------- On Init -------------------------------- */

  // useEffect(() => {
  //   // add api to link zoho account to company
  //   if (query.get('zoho_hash')) {
  //     console.log('query', query.get('zoho_hash'));
  //     axiosInstance.put(`${companyDetails.id}/zoho_accounts/${query.get('zoho_hash')}/link`);
  //   }
  // }, [query.get('zoho_hash')]);

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && $t('Import third_party')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex'>
          <DownloadButton
            fa='arrow-down'
            onClick={
              () =>
                window.open(
                  `${process.env.REACT_APP_BACKEND_BASE_URL?.replace(
                    /\/api$/,
                    ``
                  )}/Ahad_import-third-parties-template.xlsx`
                )
              // window.open('https://api.ahadtest.com/Ahad_import-third-parties-template.xlsx')
            }
            nameId='Template'
          />
          {/* <div className='d-flex justify-content-end px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            <Button
              btnType='outlined'
              fa='arrow-down'
              onClick={() =>
                window.open(
                  process.env.REACT_APP_BACKEND_BASE_URL?.replace(
                    /\/api$/,
                    `/zoho/redirect?redirect_path=${pathname}`
                  )
                )
              }
              nameId='ZOHO Import'
            />
          </div> */}
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_template' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  {/*  ------------------------third_party file -----------------------  */}
                  <div className='col-md-6' style={{ maxWidth: '40%' }}>
                    <FileUploader
                      setFile={setFile}
                      file={file}
                      maxSize={2 * 1024}
                      supportedFiles={[
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                        '.csv',
                        'text/csv',
                      ]}
                      typesOnly='xlsx'
                    />
                  </div>
                  {/*  ------------------------end third_party file -----------------------  */}
                </div>

                {/* Save and Cancel Buttons */}
                {mode === 'create' && (
                  <>
                    <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.push(
                          `/c/${companyDetails.slug}/company-management/clients-and-vendors`
                        );
                      }}
                    />
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
      <ImportNewClientsDisclamer />
    </div>
  );
};

const ImportNewClientsWrapperCreate: FC = () => {
  usePermissions(['create-third_party']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Third party' })}</PageTitle>
      {/* <div> Coming Soon </div> */}
      <ImportNewClientsWrapper mode='create' />
    </div>
  );
};

export { ImportNewClientsWrapperCreate };
