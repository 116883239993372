import { Meta } from '../../../types/Meta';

export enum VatRatesTypes {
  'Income' = 1,
  'Expenditure' = 2,
  'Income return' = 3,
  'Expenditure return' = 4,
}
// export enum TransactionTypes {
//   'Domestic' = 1,
//   'International' = 2,
//   'GCC' = 3,
// }

export interface IPurchase {
  id: number;
  reference_id?: number;
  invoice_date: string;
  min_accepted_date: string;
  invoice_number: string;
  invoice_reference: string;
  description: {
    ar: string;
    en: string;
  };
  quantity: string;
  amount: string;
  remaining_amount: string;
  can_update: boolean;
  can_delete: boolean;
  is_return: boolean;
  return_invoice: boolean;
  review_status: number;
  created_at: string;
  attachment: string;
  client: {
    id: number;
    reference_number: string;
    name: string;
  };
  category: {
    id: number;
    reference_number: number;
    type: number;
    name: string;
  };
  vat_rate: {
    id: number;
    name: string;
  };
  errors: {
    [key: string]: string;
  };
  errors_with_color: {
    [key: string]: {
      color: string;
      text: string;
    };
  };
  linked_invoices?: number;
}

export interface PurchasesDataListModel extends Meta {
  data: Array<IPurchase>;
}
