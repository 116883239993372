import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clientSideSignatureOnlyOrFirst } from '../../../../../_metronic/helpers/functions/signatures';
import { $t, FormatMessage } from '../../../../../_metronic/i18n/formatMessage';
import { axiosInstance } from '../../../../../network/apis';
import { RootState } from '../../../../../setup/redux/Store';
import { DisplayErrors } from '../../../../../utils/DisplayErrors';
import { ICompany } from '../../../../types/companies';
import { IDocument } from '../../types/DocumentsModel';
import { IApproveSignOrReturnDocument } from '../ApproveSignOrReturnDocumentStepper';
import { DOCS_STATUS, RECIPIENT_TYPES } from '../../../../../_metronic/constants/general';
import { Field } from 'formik';
import { UserModel } from '../../../auth/models/UserModel';

type Props = {
  values: IApproveSignOrReturnDocument;
  document: IDocument;
  setDocumentToApproveModal: Function;
  postApprove?: Function;
};

const casesSuccessMessages: { [key: string]: any } = {
  1: 'Document will be sent to the second party to sign',
  2: 'This document is sent to second party',
  3: 'Document will be sent to the recipients',
  4: 'This document is sent to second party',
  5: 'Document will be sent to the recipients',
};

const Step3TakingAction: FC<Props> = ({
  values,
  document,
  setDocumentToApproveModal,
  postApprove,
}) => {
  const { locale } = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (
      clientSideSignatureOnlyOrFirst(document.recipient_type) &&
      document.status !== DOCS_STATUS.WAITING_FOR_COMPANY
    ) {
      handleSuccess();
    }
  }, [document]);

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const [signature, setSignature] = useState<null | number>(null);
  const [success, setSuccess] = useState(false);

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  const signatures = user?.signatures ? Object.values(user?.signatures).flat() : [];

  const handleSignAndSend = async () => {
    if (
      document.recipient_type ===
        RECIPIENT_TYPES['Need signture from 2 side- Seconed party side first'] &&
      document.status !== DOCS_STATUS.WAITING_FOR_COMPANY
    ) {
      handleSuccess();
      return;
    }

    try {
      await axiosInstance.post(`${companyDetails.id}/signers/documents/${document.id}/attach`, {
        signature_id: signature,
      });
      postApprove && postApprove();
      handleSuccess();
    } catch (error) {
      DisplayErrors(error);
    }
  };

  const handleSuccess = () => {
    setSuccess(true);
    setSignature(null);
  };

  return (
    <div className='w-100'>
      {!success && values.step2Selection === 'sign_and_send' && (
        <div className='container'>
          <div className='row'>
            {Object.keys(user.signatures)?.length === 0 && (
              <>
                {$t('Please add a signature to your profile')}
                <div className='mt-5'>
                  <button
                    className='btn btn-sm btn-primary btn-shadow btn-primary-shadow me-5'
                    onClick={() => history.push('/user-profile/edit')}
                  >
                    {$t('Visit Profile')}
                  </button>
                  <button
                    className='btn btn-sm btn-danger btn-shadow btn-danger-shadow'
                    onClick={() => setDocumentToApproveModal(null)}
                  >
                    {$t('Cancel')}
                  </button>
                </div>
              </>
            )}
            {signatures?.map((sig: any, index: number) => (
              <div className='col-lg-4'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='signature'
                  value={`${sig.id}`} // change sig.id to id when it's ready from backend
                  id={`signatureSelection-${sig.id}`}
                  onClick={() => setSignature(sig.id)} // change index to id when it's ready from backend
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                  style={{ width: 'fit-content' }}
                  htmlFor={`signatureSelection-${sig.id}`}
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                      <img
                        src={sig.url}
                        alt={sig.name}
                        style={{
                          height: '100px',
                          width: '100px',
                        }}
                      />
                    </span>
                    <span className='text-gray-400 fw-bold fs-6'></span>
                  </span>
                </label>
              </div>
            ))}
          </div>
          {signature !== null && (
            <div className='d-flex flex-stack pt-10' dir={locale === 'ar' ? 'rtl' : 'ltr'}>
              <div className='mr-2'></div>
              <button
                className='btn btn-primary btn-sm btn-primary-shadow'
                onClick={(e) => {
                  e.preventDefault();
                  handleSignAndSend();
                }}
              >
                <span className='indicator-label'>
                  <i className='fa fa-signature'></i>
                  <FormatMessage id='Sign and Send' />
                </span>
              </button>
            </div>
          )}
        </div>
      )}

      {success && (
        <>
          <div
            className='b-radius-20 d-flex justify-content-center align-items-center position-relative'
            style={{ width: '100%' }}
          >
            <div className='b-radius-30  d-flex flex-column gap-3 justify-content-center align-items-center'>
              <i className='fas fa-check-circle text-success' style={{ fontSize: '100px' }}></i>

              <h5 className='text-metallicBlue'>
                {document.status === DOCS_STATUS.WAITING_FOR_COMPANY ? (
                  <FormatMessage id='Your signature has been saved successfully' />
                ) : (
                  <>
                    {$t(casesSuccessMessages[document.recipient_type]) ||
                      $t('Proccess has been completed')}
                  </>
                )}
              </h5>
              <button
                className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
                onClick={(e) => {
                  e.preventDefault();
                  setDocumentToApproveModal(null);
                }}
              >
                <FormattedMessage id='Close' />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { Step3TakingAction };
