import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RightToolbar } from '../partials/layout/RightToolbar';
import { Content } from './components/Content';
import { PublicHeader } from './components/header/PublicHeader';
import { RightSection } from './components/RightSection/RightSection';
import { Toolbar } from './components/toolbar/Toolbar';
import { PageDataProvider } from './core';

const PublicLayout: React.FC = ({ children }) => {
  useEffect(() => {
    document.body.classList.remove('bg-main');
    return () => {
      document.body.classList.add('bg-main');
    };
  }, []);

  const params: any = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params?.companySlug && params.companySlug === 'undefined') {
      history.push('/');
    }
  }, [params]);

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        {/* <AsideDefault user='USER' /> */}
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <PublicHeader />
          <div id='kt_content' className='content d-flex flex-row'>
            <div className='flex-fill pt-3'>
              {/* Announcement */}
              {/* <Announcement type='BillingOrderAnnouncement' /> */}
              {/* Announcement */}
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='content'>
                <Content>{children}</Content>
              </div>
            </div>
            {/* Right Section */}
            <div className='right-aside mw-auto'>
              <RightSection />
            </div>
            <RightToolbar />
            {/* End Right Section */}
          </div>
        </div>
      </div>
    </PageDataProvider>
  );
};

export { PublicLayout };
