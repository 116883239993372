import { FC } from 'react';

type Props = {
  className?: string;
};

export const ArrowAfterDash: FC<Props> = ({ className }) => (
  <span className={className + ''}>
    <i className='fas fa-minus fs-10'></i>
    <i className='fas fa-arrow-right fs-10' style={{ margin: '0 3px' }}></i>
  </span>
);
