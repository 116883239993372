import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import 'react-phone-input-2/lib/style.css';

import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import {
  ClientTypes,
  PersonDecisionTypes,
  ThirdPartyTypes,
  AccountTypes,
} from '../../types/CompanyTypes';
import { emailPattern, uriOptionalPattern } from '../../../_metronic/constants/patterns';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../_metronic/constants/GeneralValidations';

const useAddClientSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */
  const initialValues: { [key: string]: any } = useMemo(
    () => ({
      status: 0,
      tax_registered: 0,
      third_party_type: { value: ThirdPartyTypes.Client, label: formatMessage({ id: 'Client' }) },
      account_type: { value: AccountTypes.Account, label: formatMessage({ id: 'Account' }) },
      // type: { value: ClientTypes.Entity, label: formatMessage({ id: 'Entity' }) },
      type: null,
      reference_number: '',
      name_en: '',
      name_ar: '',
      short_name: '',
      phone: '966',
      phoneValidity: true,
      slug: '',
      email: '',
      registration_number: '',
      license_number: '',
      tax_number: '',
      id_number: '',
      website: '',
      country: { value: 'SA', label: '🇸🇦 المملكة العربية السعودية' },
      city: null,
      address_en: '',
      address_ar: '',
      district: '',
      zip: '',
      image: '',
      sales_categories: [],
      purchases_categories: [],
      contacts: [
        {
          name: { en: '', ar: '' },
          title: { en: '', ar: '' },
          email: '',
          status: { value: PersonDecisionTypes.Approver, label: formatMessage({ id: 'Approver' }) },
          is_receiver: 0,
          phone: '',
          phoneValidity: false,
        },
      ],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const createNewCompaySchema = useMemo(
    () =>
      Yup.object().shape({
        third_party_type: Yup.object().required(required),
        account_type: Yup.object().required(required),

        type: Yup.object()
          .nullable()
          .when(['account_type'], {
            is: (account_type: any) => {
              // console.log(account_type?.value === AccountTypes.Account);
              return account_type?.value === AccountTypes.Account;
            },
            then: (schema) => schema.required(required),
          }),
        reference_number: Yup.string()
          // .required(required)
          .min(
            commonValidations.nameMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.nameMinimum }
            )
          )
          .max(
            commonValidations.nameMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.nameMaximum }
            )
          )
          .test(
            'reference_number',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        short_name: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('name_en', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        name_en: Yup.string()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.textMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.textMaximum,
            })
          )
          .test('name_en', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        name_ar: Yup.string()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.textMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.textMaximum,
            })
          )
          .test('name_ar', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        license_number: Yup.number().typeError($t('Only number are allowed!')).nullable(),
        registration_number: Yup.number().typeError($t('Only number are allowed!')).nullable(),
        tax_number: Yup.number()
          .typeError($t('Only number are allowed!'))
          .nullable()
          .when(['tax_registered', 'account_type'], {
            is: (tax_registered: any, account_type: any) => {
              return tax_registered === 1 && account_type.value === AccountTypes.Account;
            },
            then: (schema) => schema.required(required),
          }),
        id_number: Yup.number().typeError($t('Only number are allowed!')).nullable(),
        website: Yup.string().matches(uriOptionalPattern, $t('Website is invalid')).nullable(),
        email: Yup.string().nullable().email($t('Email is invalid')),
        phone: Yup.string().nullable(),
        phoneValidity: Yup.boolean().when('phone', (type, schema) => {
          if (type?.length > 3) return schema.nullable();
        }),

        country: Yup.object().required(required),
        city: Yup.object().nullable(),
        address_en: Yup.string().nullable(),
        address_ar: Yup.string().nullable(),
        district: Yup.string().nullable(),
        zip: Yup.number().typeError($t('Only number are allowed!')).nullable(),
        sales_categories: Yup.array().when(['third_party_type'], {
          is: (third_party_type: any) => {
            return (
              third_party_type.value === ThirdPartyTypes.Client ||
              third_party_type.value === ThirdPartyTypes.Both
            );
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
        purchases_categories: Yup.array().when(['third_party_type'], {
          is: (third_party_type: any) => {
            return (
              third_party_type.value === ThirdPartyTypes.Vendor ||
              third_party_type.value === ThirdPartyTypes.Both
            );
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),

        contacts: Yup.array().when(['type', 'account_type'], {
          is: (type: any, account_type: any) => {
            return (
              type &&
              type.value === ClientTypes.Entity &&
              account_type.value === AccountTypes.Account
            );
          },
          then: (schema) =>
            schema.of(
              Yup.object().shape({
                email: Yup.string()
                  .matches(emailPattern, formatMessage({ id: 'Invalid email' }))
                  .nullable()
                  .email(formatMessage({ id: 'Invalid email' })),
                /* .required(required) */ name: Yup.object().shape({
                  ar: Yup.string()
                    // .required(required)
                    .min(
                      commonValidations.nameMinimum,
                      formatMessage(
                        { id: 'Please enter at least {minCharacters} characters' },
                        {
                          minCharacters: commonValidations.nameMinimum,
                        }
                      )
                    )
                    .max(
                      commonValidations.nameMaximum,
                      formatMessage(
                        { id: 'The Maximum characters is {maxCharacters}' },
                        {
                          maxCharacters: commonValidations.nameMaximum,
                        }
                      )
                    )
                    .test(
                      'name',
                      formatMessage({
                        id: "You can't enter special character at first or last!",
                      }),
                      (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
                    ),
                  en: Yup.string()
                    // .required(required)
                    .min(
                      commonValidations.nameMinimum,
                      formatMessage(
                        { id: 'Please enter at least {minCharacters} characters' },
                        {
                          minCharacters: commonValidations.nameMinimum,
                        }
                      )
                    )
                    .max(
                      commonValidations.nameMaximum,
                      formatMessage(
                        { id: 'The Maximum characters is {maxCharacters}' },
                        {
                          maxCharacters: commonValidations.nameMaximum,
                        }
                      )
                    )
                    .test(
                      'name',
                      formatMessage({
                        id: "You can't enter special character at first or last!",
                      }),
                      (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
                    ),
                }),
                title: Yup.object().shape({
                  ar: Yup.string()
                    .min(
                      commonValidations.nameMinimum,
                      formatMessage(
                        { id: 'Please enter at least {minCharacters} characters' },
                        {
                          minCharacters: commonValidations.nameMinimum,
                        }
                      )
                    )
                    .max(
                      commonValidations.nameMaximum,
                      formatMessage(
                        { id: 'The Maximum characters is {maxCharacters}' },
                        {
                          maxCharacters: commonValidations.nameMaximum,
                        }
                      )
                    )
                    .test(
                      'name',
                      formatMessage({
                        id: "You can't enter special character at first or last!",
                      }),
                      (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
                    ),
                  en: Yup.string()
                    .min(
                      commonValidations.nameMinimum,
                      formatMessage(
                        { id: 'Please enter at least {minCharacters} characters' },
                        {
                          minCharacters: commonValidations.nameMinimum,
                        }
                      )
                    )
                    .max(
                      commonValidations.nameMaximum,
                      formatMessage(
                        { id: 'The Maximum characters is {maxCharacters}' },
                        {
                          maxCharacters: commonValidations.nameMaximum,
                        }
                      )
                    )
                    .test(
                      'name',
                      formatMessage({
                        id: "You can't enter special character at first or last!",
                      }),
                      (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
                    ),
                }),
                phone: Yup.string(),
                phoneValidity: Yup.boolean(),
              })
            ),
        }),

        // .when('type', (type: any, schema: any) => {
        //   if (type.value === ClientTypes.Entity)
        //     return schema
        //       .of(
        //         Yup.object().shape({
        //           email: Yup.string()
        //             .matches(emailPattern, formatMessage({ id: 'Invalid email' }))
        //             .email(formatMessage({ id: 'Invalid email' }))
        //             .required(required),
        //           name: Yup.object().shape({
        //             ar: Yup.string()
        //               .required(required)
        //               .min(
        //                 commonValidations.nameMinimum,
        //                 formatMessage(
        //                   { id: 'Please enter at least {minCharacters} characters' },
        //                   {
        //                     minCharacters: commonValidations.nameMinimum,
        //                   }
        //                 )
        //               )
        //               .max(
        //                 commonValidations.nameMaximum,
        //                 formatMessage(
        //                   { id: 'The Maximum characters is {maxCharacters}' },
        //                   {
        //                     maxCharacters: commonValidations.nameMaximum,
        //                   }
        //                 )
        //               )
        //               .test(
        //                 'name',
        //                 formatMessage({
        //                   id: "You can't enter special character at first or last!",
        //                 }),
        //                 (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
        //               ),
        //             en: Yup.string()
        //               .required(required)
        //               .min(
        //                 commonValidations.nameMinimum,
        //                 formatMessage(
        //                   { id: 'Please enter at least {minCharacters} characters' },
        //                   {
        //                     minCharacters: commonValidations.nameMinimum,
        //                   }
        //                 )
        //               )
        //               .max(
        //                 commonValidations.nameMaximum,
        //                 formatMessage(
        //                   { id: 'The Maximum characters is {maxCharacters}' },
        //                   {
        //                     maxCharacters: commonValidations.nameMaximum,
        //                   }
        //                 )
        //               )
        //               .test(
        //                 'name',
        //                 formatMessage({
        //                   id: "You can't enter special character at first or last!",
        //                 }),
        //                 (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
        //               ),
        //           }),
        //           title: Yup.object().shape({
        //             ar: Yup.string()
        //               .min(
        //                 commonValidations.nameMinimum,
        //                 formatMessage(
        //                   { id: 'Please enter at least {minCharacters} characters' },
        //                   {
        //                     minCharacters: commonValidations.nameMinimum,
        //                   }
        //                 )
        //               )
        //               .max(
        //                 commonValidations.nameMaximum,
        //                 formatMessage(
        //                   { id: 'The Maximum characters is {maxCharacters}' },
        //                   {
        //                     maxCharacters: commonValidations.nameMaximum,
        //                   }
        //                 )
        //               )
        //               .test(
        //                 'name',
        //                 formatMessage({
        //                   id: "You can't enter special character at first or last!",
        //                 }),
        //                 (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
        //               ),
        //             en: Yup.string()
        //               .min(
        //                 commonValidations.nameMinimum,
        //                 formatMessage(
        //                   { id: 'Please enter at least {minCharacters} characters' },
        //                   {
        //                     minCharacters: commonValidations.nameMinimum,
        //                   }
        //                 )
        //               )
        //               .max(
        //                 commonValidations.nameMaximum,
        //                 formatMessage(
        //                   { id: 'The Maximum characters is {maxCharacters}' },
        //                   {
        //                     maxCharacters: commonValidations.nameMaximum,
        //                   }
        //                 )
        //               )
        //               .test(
        //                 'name',
        //                 formatMessage({
        //                   id: "You can't enter special character at first or last!",
        //                 }),
        //                 (val: any) => nameWithoutSpcialCharsStartOrEnd(val)
        //               ),
        //           }),
        //           phone: Yup.string(),
        //           phoneValidity: Yup.boolean(),
        //         })
        //       )
        //       .min(1, formatMessage({ id: 'Please choose at least one' }));
        // }),
      }),
    [formatMessage]
  );
  /* --------------------------- end formik starter --------------------------- */

  return { initialValues, createNewCompaySchema };
};

export { useAddClientSchema };
