/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, { FC } from 'react';
import { useLayout } from '../../core';
import { DefaultTitle } from '../header/page-title/DefaultTitle';

const TitleWithBreadCrumb: FC = () => {
  const { classes } = useLayout();

  return (
    <div className='page-title' id='kt_toolbar'>
      <span className='blueLine'></span>
      {/* begin::Container */}
      <div id='kt_toolbar_container' className={clsx(classes.toolbarContainer.join(' '), 'd-flex')}>
        <DefaultTitle />
        {/* begin::Actions */}
        <div className='d-flex align-items-center py-1'></div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { TitleWithBreadCrumb };
