import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useState, useMemo, useEffect } from 'react';
import { serialize } from 'object-to-formdata';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import * as TemplatesRedux from '../templates/store/TemplatesRedux';
import * as UserRedux from '../../modules/users/store/UsersRedux';
import * as ClientsRedux from '../../modules/clients/store/ClientsRedux';

import { axiosInstance } from '../../../network/apis';
import { RootState } from '../../../setup/redux/Store';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { DocumentDirection, RecipientTypeMap } from '../../../_metronic/constants/general';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { usePermissions } from '../../hooks/usePermissions';
import { CloseButtonRedShadowed } from '../../shared/CloseButtonRedShadowed';
import { Input } from '../../shared/Input';
import { Label } from '../../shared/Label';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import { ICompany } from '../../types/companies';
import { useConfigureTemplateSchema } from './useConfigureTemplateSchema';
import { UsersDataListModel } from '../users/types/usersList';
import { ClientsDataListModel, IClient } from '../clients/types/clientsList';
import { UserModel } from '../auth/models/UserModel';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { endpoints, ID } from '../../../_metronic/constants/paths';
import { ErrorMessage } from '../../shared/ErrorMessage';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { initialConfigPayload } from './helpers/templateConfigHelpers';
import { boxStyle } from './boxStyle';
import { LOCALE } from '../../../_metronic/helpers/typescript';

type Props = {
  setCurrentState: Function;
};

export const ConfigureNewTemplateForm: FC<Props> = ({ setCurrentState }) => {
  usePermissions('create-template', 'Create Template');
  const { validationSchema, initialValues } = useConfigureTemplateSchema();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params: any = useParams();

  const { formatMessage, locale } = useIntl();

  const [loading, setLoading] = useState(false);
  // display and Hide the unused form depending on the template type INTERNAL OR EXTERNAL
  const [templateTypeNumber, setTemplateTypeNumber] = useState(1);
  // display and Hide the unused form depending on the reciepent type SIGNATURES NEEDED
  const [reciepentTypeNumber, setReciepentTypeNumber] = useState(1);

  const { setShowExitPrompt } = useExitPrompt(false);

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const users: UsersDataListModel = useSelector<RootState>(
    (state) => state.USER_users.Users,
    shallowEqual
  ) as UsersDataListModel;

  const clients: ClientsDataListModel = useSelector<RootState>(
    (state) => state.USER_clients.Clients,
    shallowEqual
  ) as ClientsDataListModel;

  const typesOptions = useMemo(
    () =>
      Array.from(RecipientTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );

  const userOptions = useMemo(
    () => users?.data?.map((user) => ({ label: user.name, value: user.id })),
    [users]
  );

  const clientsOptions = useMemo(
    () =>
      clients?.data?.map((client) => ({
        label: client?.name?.[locale as LOCALE],
        value: client.id,
      })),
    [clients]
  );

  const createTemplate = async (values: any, resetForm: any) => {
    setLoading(true);

    // TODO: make new object with document recipent types in general.ts file to be more redable
    if (templateTypeNumber === 1 || templateTypeNumber === 2 || templateTypeNumber === 4) {
      if (reciepentTypeNumber === 1) {
        values = { ...values, clients: [] };
      } else if (reciepentTypeNumber === 2) {
        values = { ...values, users: [] };
      }
    } else if (templateTypeNumber === 3 || templateTypeNumber === 5) {
      values = { ...values, users: [], clients: [] };
    }

    const payload: { [key: string]: any } = initialConfigPayload(values);

    const formData = serialize(payload, { indices: true });
    // TODO: make new object with document recipent types in general.ts file to be more redable
    if (templateTypeNumber === 1 || templateTypeNumber === 2 || templateTypeNumber === 4) {
      if (reciepentTypeNumber === 1) {
        formData.append('clients', '');
      } else if (reciepentTypeNumber === 2) {
        formData.append('users', '');
      }
    } else if (templateTypeNumber === 3 || templateTypeNumber === 5) {
      formData.append('users', '');
      formData.append('clients', '');
    }

    try {
      let response: AxiosResponse;
      if (params.templateId) {
        formData.append('_method', 'PUT');
        response = await axiosInstance.post(
          endpoints.updateCompanyTemplates(companyDetails.id, underConstructionTemplate?.id),
          formData
        );
      } else {
        response = await axiosInstance.post(
          endpoints.companyTemplates(companyDetails.id),
          formData
        );
      }
      const { id } = response.data;

      const newUnderConstruction: TemplatesRedux.UnderConstructionTemplate = {
        ...payload,
        id: values?.id || id,
        values,
      } as TemplatesRedux.UnderConstructionTemplate;

      dispatch(
        TemplatesRedux.actions.fulfillUnderConstructionTemplate({
          underConstructionTemplate: newUnderConstruction,
        })
      );
      resetForm();
      toast.success(formatMessage({ id: 'Template has been created successfully!' }));
      history.push(location.pathname.replace('/create', `/update/${id}`));
      setCurrentState('select_basics');
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const getUsers = () =>
    dispatch(UserRedux.actions.getUsers({ companyId: companyDetails.id, params: { per_page: 0 } }));

  const getClients = () =>
    dispatch(
      ClientsRedux.actions.getClients({ companyId: companyDetails.id, params: { per_page: 0 } })
    );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => createTemplate(values, resetForm),
  });
  const { setFieldValue, values } = formik;

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    if (Number(params?.templateId) === underConstructionTemplate?.id) {
      setFieldValue('id', underConstructionTemplate.id);
      setFieldValue('title', underConstructionTemplate.title);
      setFieldValue('type', underConstructionTemplate.type);
      setFieldValue('users', underConstructionTemplate.users);
      setFieldValue('clients', underConstructionTemplate.clients);
      setFieldValue(
        'recipient_type',
        typesOptions.find((item) => item.value === underConstructionTemplate.recipient_type)
      );
    }
  }, [underConstructionTemplate]);

  useEffect(() => {
    if (params?.templateId) {
      if (!users?.data?.length) getUsers();
      if (!clients?.data?.length) getClients();
    }
  }, [params?.templateId]);

  return (
    <FormikProvider value={formik}>
      <Form id='create_template' noValidate autoComplete='none'>
        {Object.keys(formik.touched).length > 0 && (
          <Prompt message={$t('Are you sure you want to ignore the changes?')} />
        )}
        <div
          className='p-5 dialog-defaults b-radius-20'
          style={{ maxHeight: 'fit-content', width: '50vw' }}
        >
          <div className='row'>
            <div className='col-12' style={{ maxWidth: '97%' }}>
              <Input
                placeholder={$t('Template Title')}
                labelId={$t('Template Title')}
                autoComplete='off'
                formik={formik}
                name={`title`}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <Label
                msg='Choose how many signatures do you need?'
                htmlFor='recipient_type'
                isRequired
              />
              <Select
                onChange={(value) => {
                  setTemplateTypeNumber(value.value);
                  return setFieldValue('recipient_type', value);
                }}
                onBlur={() => formik.setFieldTouched('recipient_type', true)}
                options={typesOptions}
                value={formik.values.recipient_type}
                className='react-select smaller'
                id='recipient_type'
              />
            </div>

            <div className='col-md-6'>
              <Label msg='Recipient type' isRequired />
              <div className='d-flex gap-3 mt-1'>
                {Array.from(DocumentDirection.values())
                  .flat()
                  .map((type) => (
                    <span className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input mx-2'
                        type='radio'
                        name='appStorage'
                        id={type.label}
                        checked={values.type === type.value}
                        value={`${type.value}`}
                        onChange={(e) => {
                          setReciepentTypeNumber(type.value);
                          return setFieldValue('type', type.value);
                        }}
                      />
                      <Label msg={type.label} htmlFor={type.label} />
                    </span>
                  ))}
              </div>
            </div>
            {(templateTypeNumber === 1 || templateTypeNumber === 2 || templateTypeNumber === 4) && (
              <>
                <div className='mt-2 row'>
                  {reciepentTypeNumber === 1 && (
                    <div className='col-12'>
                      <Select
                        onChange={(value) =>
                          setFieldValue('users', [...values?.users, value?.value])
                        }
                        onFocus={() => getUsers()}
                        placeholder={$t('Users')}
                        onBlur={() => formik.setFieldTouched('users', true)}
                        options={userOptions?.filter(
                          (option) => !values?.users?.includes(option?.value)
                        )}
                        value={null}
                        className='react-select smaller'
                        id='users'
                      />
                      <ErrorMessage name='users' formik={formik} />
                    </div>
                  )}
                  {reciepentTypeNumber === 2 && (
                    <div className='col-12'>
                      <Select
                        onChange={(value) =>
                          setFieldValue('clients', [...values.clients, value?.value])
                        }
                        placeholder={$t('Clients')}
                        onFocus={() => getClients()}
                        onBlur={() => formik.setFieldTouched('clients', true)}
                        options={clientsOptions?.filter(
                          (option) => !values?.clients?.includes(option?.value)
                        )}
                        value={null}
                        className='react-select smaller'
                        id='clients'
                      />
                      <ErrorMessage name='clients' formik={formik} />
                    </div>
                  )}
                </div>
                <div className='row'>
                  {reciepentTypeNumber === 1 && (
                    <div className='col-12'>
                      <div className='mt-2' style={{ ...boxStyle, overflowY: 'auto' }}>
                        <div className='row p-5'>
                          {values?.users?.map((id: number) => {
                            const user: UserModel = users?.data?.find(
                              (user) => user?.id === id
                            ) as UserModel;

                            return (
                              <div className='col-6'>
                                <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                                  <div>
                                    <div className='symbol symbol-circle avatar-border symbol-20px symbol-md-30px me-1'>
                                      <img
                                        alt=''
                                        src={
                                          user?.image || toAbsoluteUrl('/media/avatars/blank.png')
                                        }
                                      />
                                    </div>
                                    <p className='text-dark fw-bolder fs-8 d-inline line-clamp-1 mw-50px w-50'>
                                      {user?.name}
                                    </p>
                                  </div>
                                  <CloseButtonRedShadowed
                                    onClick={() =>
                                      setFieldValue(
                                        'users',
                                        values.users.filter((item: ID) => item !== id)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  {reciepentTypeNumber === 2 && (
                    <div className='col-12'>
                      <div className='mt-2' style={{ ...boxStyle, overflowY: 'auto' }}>
                        <div className='row p-5'>
                          {values?.clients?.map((id: number) => {
                            const client: IClient = clients.data.find(
                              (user) => user.id === id
                            ) as IClient;
                            return (
                              <div className='col-6'>
                                <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                                  <a className='text-dark fw-bolder fs-6'>
                                    {client?.name?.[locale as LOCALE]}
                                  </a>
                                  <CloseButtonRedShadowed
                                    onClick={() => {
                                      setFieldValue(
                                        'clients',
                                        [...values.clients].filter((item: ID) => item !== id)
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <SaveCancelBtnsGroup
            saveBtnLabel='save'
            formik={formik}
            loading={loading}
            cancelOnClick={() => {
              history.push(`/c/${companyDetails.slug}/templates`);
            }}
          />
        </div>
      </Form>
    </FormikProvider>
  );
};
