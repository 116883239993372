import { FC, useEffect, useRef, useState } from 'react'; // useMemo,
import { FormattedMessage, useIntl } from 'react-intl'; // FormattedMessage,
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom'; // useParams,, useLocationPrompt,

import { Form, useFormik, FormikProvider } from 'formik'; //Formik,FormikValues,

import { RootState } from '../../../../setup/redux/Store';

import { CompanyModel } from '../../companies/CompanyModel';

import { KTSVG } from '../../../../_metronic/helpers';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import {
  ICreateNewSalesReturn,
  useConfigureReportsSchema,
} from './CreateNewSalesInvoiceWizardHelper';

import { $t } from '../../../../_metronic/i18n/formatMessage';

import * as SalesInvoicesRedux from '../store/SalesInvoicesRedux';

import useExitPrompt from '../../../hooks/useExitPrompt/useExitPrompt';

import { SeparatorLines } from '../../../shared/Lines';

// import * as TableRedux from '../../../store/TableRedux';
// import * as WithholdingReportsRedux from '../store/WithholdingReportsRedux';
import { axiosInstance } from '../../../../network/apis';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
// import AhadDialog from '../../../../setup/shared/AhadDialog';
// import { Portal } from '../../../../_metronic/partials';
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';

import { Step1 } from './steps/Step1';
import { Step2 } from './steps/Step2';
import { Step3 } from './steps/Step3';
import { Portal } from '../../../../_metronic/partials';
import AhadDialog from '../../../../setup/shared/AhadDialog';

const HorizontalConfigNewReturnSalesWrapper: FC = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userApproved, setUserApproved] = useState(false);
  const [multiple_document, set_multiple_document] = useState<File[]>([]);
  const { formatMessage, locale } = useIntl(); //
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const dispatch = useDispatch();
  const history = useHistory();
  // const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const [loading, setLoading] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const { returnSalesInitialValues, returnSalesValidationSchema } = useConfigureReportsSchema();
  const [currentSchema, setCurrentSchema] = useState(returnSalesValidationSchema[0]);
  const [initValues] = useState<ICreateNewSalesReturn>(returnSalesInitialValues);
  const [isSubmitButton, setSubmitButton] = useState(false);

  const createReturnInvoice = async (values: any, setSubmitting: any, resetForm: any) => {
    setLoading(true);
    setSubmitting(true);

    // console.log('createReturnInvoice');
    // console.log(formik);
    // console.log(values);
    // console.log('Create return sales');
    if (values.invoice_items.reduce((a: any, b: any) => a + b.total, 0) === 0) {
      toast.error(formatMessage({ id: 'invoice total cant be zero' }));
      setSubmitting(false);
      setLoading(false);
      return;
    }
    if (!userApproved && values.status !== '0') {
      setShowConfirmationModal(true);
      setSubmitting(false);
      setLoading(false);
      return;
    }
    const payload: any = {};
    if (values.status === '1') {
      payload['invoice_number'] = values.invoice_number;
      payload['status'] = 1;
    } else {
      payload['status'] = 0;
    }
    payload['invoice_date'] = values.invoice_date;
    payload['reference_id'] = values.invoice_reference?.[0]?.id;
    payload['payment_date'] = values.payment_date;
    payload['client_id'] = values.clients[0]?.value;
    payload['description'] = { ar: '', en: '' };
    payload['description']['ar'] = values.description_ar ? values.description_ar.trim() : '';
    payload['description']['en'] = values.description_en ? values.description_en.trim() : '';
    payload['is_return'] = 0;
    payload['is_cashback'] = formik.values.invoice_type === 'cashback' ? 1 : 0;
    const invoice_items_mapped = values.invoice_items
      .map((item: any) => {
        return {
          // description: { ar: item.description, en: item.description },
          reference_id: item.reference_id ? item.reference_id : '',
          description: item.description,
          amount: item.total_price,
          quantity: item.quantity,
          vat_rate_id:
            formik.values?.invoice_type === 'hasReference'
              ? item.reverse_vat_rate?.[0]?.value
                ? item.reverse_vat_rate?.[0]?.value
                : null
              : item.vat_rate?.[0]?.value,
          category_id: item.category?.[0]?.value,
          product_id: item.name?.[0]?.value,
          product_price: item.price,
          discount: item?.discount_amount,
          // tax_value: item.tax_value,
          // type: '1',
        };
      })
      .filter((item: any) => item.vat_rate_id && +item.quantity > 0);

    payload['invoice_items'] = invoice_items_mapped;
    payload['documents'] = multiple_document;
    const formData = serialize(payload, { indices: true });

    try {
      await axiosInstance
        .post(`${companyDetails?.id}/invoices/return/sales`, formData)
        .then((res) => {
          if (values.invoice_number_method === '2' && res.data) {
            toast.success(
              formatMessage(
                { id: 'The invoice with number {invoiceNumber} has been created successfully!' },
                { invoiceNumber: `${res.data}` }
              ),
              {
                toastId: 'addSuccessMessage',
              }
            );
          } else {
            toast.success(formatMessage({ id: 'The invoice has been created successfully!' }), {
              toastId: 'addSuccessMessage',
            });
          }
        });
      resetForm();
      history.push(`/c/${companyDetails?.slug}/financial-management/sales-invoices`);
    } catch (err: any) {
      DisplayErrors(err);
      setSubmitting(false);
    }
    setLoading(false);
  };

  const submitStep = (values: any, setSubmitting: any, resetForm: any) => {
    if (!stepper.current) {
      return;
    }

    // setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1);

    if (formik.values?.invoice_type === 'hasReference') {
      setCurrentSchema(returnSalesValidationSchema[stepper.current.currentStepIndex]);
      if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
        stepper.current.goNext();
        // reset all touched
        formik.setTouched(
          {
            invoice_reference: false,
            invoice_date: false,
            invoice_number: false,
            payment_date: false,
            clients: false,
            description_ar: false,
            description_en: false,
          },
          false
        );
      } else {
        createReturnInvoice(values, setSubmitting, resetForm);
      }
    } else {
      setCurrentSchema(returnSalesValidationSchema[stepper.current.currentStepIndex + 1]);
      if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber - 1) {
        stepper.current.goNext();
        // reset all touched
        formik.setTouched(
          {
            invoice_reference: false,
            invoice_date: false,
            invoice_number: false,
            payment_date: false,
            clients: false,
            description_ar: false,
            description_en: false,
          },
          false
        );
      } else {
        createReturnInvoice(values, setSubmitting, resetForm);
      }
    }
  };
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    // setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1);
    if (formik.values?.invoice_type === 'hasReference') {
      stepper.current.goPrev();
      setCurrentSchema(returnSalesValidationSchema[stepper.current.currentStepIndex - 1]);
    } else {
      stepper.current.goPrev();
      setCurrentSchema(returnSalesValidationSchema[stepper.current.currentStepIndex - 2]);
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  useEffect(() => {
    if (
      formik.values?.invoice_type === 'hasReference' &&
      stepper.current?.getCurrentStepIndex() === 3
    ) {
      setSubmitButton(true);
    } else if (
      (formik.values?.invoice_type === 'hasNoReference' ||
        formik.values?.invoice_type === 'cashback') &&
      stepper.current?.getCurrentStepIndex() === 2
    ) {
      setSubmitButton(true);
    } else {
      setSubmitButton(false);
    }
  }, [stepper.current?.getCurrentStepIndex()]);

  const formik = useFormik<any>({
    initialValues: initValues,
    validationSchema: currentSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) =>
      submitStep(values, setSubmitting, resetForm),
  });

  const { values, handleSubmit } = formik; // setFieldValue,

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    /* ----------------------------- invoice serial configuration ----------------------------- */
    // sequential invoice status
    formik.setFieldValue('invoice_number_method', companyDetails?.settings?.invoice_number_method);
  }, [formik.values]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(SalesInvoicesRedux.actions.fulfillCurrentSaleInvoice({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */
  /* --------------------------------- Leaving -------------------------------- */

  useEffect(() => {
    if (stepper.current?.getCurrentStepIndex() === 1) {
      /* ---------------------------------step1 invoice type changed -------------------------------- */
      formik.setFieldValue('invoice_reference', []);
      // reset all values except invoice_type
      formik.setValues({
        ...formik.values,
        invoice_date: '',
        payment_date: '',
        invoice_number: '',
        clients: [],
        description_ar: '',
        description_en: '',
        invoice_reference: [],
        status: '0',
        // invoice_items: [],
      });

      dispatch(SalesInvoicesRedux.actions.fulfillCurrentSaleInvoice({}));
    }
  }, [formik.values.invoice_type]);

  useEffect(() => {
    if (
      stepper.current?.getCurrentStepIndex() === 2 &&
      formik.values?.invoice_type === 'hasReference'
    ) {
      // reset all values except invoice_type, invoice_reference and invoice_items
      formik.setValues({
        ...formik.values,
        invoice_date: '',
        payment_date: '',
        invoice_number: '',
        clients: [],
        description_ar: '',
        description_en: '',
        status: '0',
      });
      /* ---------------------------------step2 invoice reference changed, getting new invoice into redux -------------------------------- */
      if (formik.values.invoice_reference?.[0]?.id)
        dispatch(
          SalesInvoicesRedux.actions.getCurrentSaleInvoice({
            companyId: companyDetails?.id,
            invoiceId: formik.values.invoice_reference?.[0]?.id,
          })
        );
    }
  }, [formik.values.invoice_reference]);

  return (
    <div className='bg-white '>
      <div className='d-flex px-5 '>
        <div className='d-flex justify-content-center px-10 py-3 fs-1 ' style={{ width: '100%' }}>
          {$t('Add sales return invoice')}
        </div>
      </div>
      <SeparatorLines lines={2} />
      <div
        ref={stepperRef}
        className='stepper stepper-links d-flex flex-column pt-15'
        id='kt_create_withholding_report_stepper'
      >
        <div className='stepper-nav mb-5'>
          <div className='stepper-item current' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{formatMessage({ id: 'Invoice type' })}</h3>
          </div>

          {values.invoice_type && values.invoice_type === 'hasReference' && (
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{formatMessage({ id: 'Invoice number' })}</h3>
            </div>
          )}

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{formatMessage({ id: 'Invoice details' })}</h3>
          </div>
        </div>

        <FormikProvider value={formik}>
          {/* <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}> {() => (*/}
          <Form
            noValidate
            autoComplete='none'
            style={{ width: '100%' }}
            className='mx-auto  w-100 pt-15 pb-10'
            id='kt_create_withholding_report_form'
          >
            {Object.keys(formik.touched).length > 0 && (
              <Prompt message={$t('Are you sure you want to ignore the changes?')} />
            )}

            <div className='current justify-content-center p-15' data-kt-stepper-element='content'>
              <Step1 />
              {/* formik={formik} */}
            </div>

            {values.invoice_type && values.invoice_type === 'hasReference' && (
              <div className='justify-content-center' data-kt-stepper-element='content'>
                <Step2 formik={formik} />
              </div>
            )}

            <div className='justify-content-center' data-kt-stepper-element='content'>
              <Step3
                formik={formik}
                multiple_document={multiple_document}
                set_multiple_document={set_multiple_document}
                multiple_document_maxFiles={2}
              />
              {/* paramsObj={paramsObj} onTransactionSelection={onTransactionSelectionHandler} */}
            </div>

            <div className='d-flex flex-stack pt-15'>
              <div className='mx-2'>
                <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-sm btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                >
                  {locale === 'ar' && (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-4 me-1'
                    />
                  )}
                  {locale === 'en' && (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                  )}

                  {formatMessage({ id: 'Back' })}
                </button>
              </div>

              <div>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary me-3'
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                  }}
                >
                  <span className='indicator-label'>
                    {!isSubmitButton && formatMessage({ id: 'Continue' })}
                    {isSubmitButton && formatMessage({ id: 'Submit' })}
                    {locale === 'ar' && (
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    )}
                    {locale === 'en' && (
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    )}
                  </span>
                </button>
              </div>
            </div>
          </Form>
          {/*  )}</Formik> */}
        </FormikProvider>
      </div>

      {showConfirmationModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => {
              setUserApproved(false);
              setShowConfirmationModal(false);
            }}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                <span>
                  {companyDetails?.settings?.invoice_number_method === '2'
                    ? formatMessage({
                        id: 'Are you sure you want to issue this invoice? you will not be able to edit or delete it',
                      })
                    : formatMessage({
                        id: 'Are you sure you want to issue this invoice?',
                      })}
                </span>
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setUserApproved(true);
                        setShowConfirmationModal(false);
                        formik.submitForm();
                      }}
                      type='button'
                      id='modal_approve'
                      className='btn btn-sm btn-success btn-shadow btn-success-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Approve' />
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setUserApproved(false);
                        setShowConfirmationModal(false);
                      }}
                      type='button'
                      id='modal_cancel'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
    </div>
  );
};

export { HorizontalConfigNewReturnSalesWrapper };
