import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../../network/apis';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../../_metronic/layout/core';
import RichText from '../../../shared/RichText';

const AboutUsContent: FC = () => {
  const [textEn, setTextEn] = useState('<br>');
  const [textAr, setTextAr] = useState('<br>');
  const [loading, setLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [originalArText, setOriginalArText] = useState('<br>');
  const [originalEnText, setOriginalEnText] = useState('<br>');
  const [originalContent, setOriginalContent] = useState({});
  const { formatMessage, locale } = useIntl();
  const someClickableSpace = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axiosInstance
      .get('/pages/about')
      .then((res) => {
        setTextAr(res.data.body.ar);
        setOriginalArText(res.data.body.ar);

        setTextEn(res.data.body.en);
        setOriginalEnText(res.data.body.en);

        setOriginalContent(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const saveNewContent = async () => {
    try {
      setIsSending(true);

      await axiosInstance.put('/admin/pages/about', {
        ...originalContent,
        body: { ar: textAr, en: textEn },
      });
      toast.success(formatMessage({ id: 'The changes have been successfully made!' }));
      setOriginalArText(textAr);
      setOriginalEnText(textEn);
    } catch (error: any) {
      DisplayErrors(error);
    }
    setIsSending(false);
  };

  return (
    <div ref={someClickableSpace}>
      {!loading && (
        <>
          <div className={clsx('row d-flex', { 'flex-row-reverse': locale === 'en' })}>
            <div className='col-md-6'>
              <p>
                <FormattedMessage id='About us' />
                <FormattedMessage id=' (AR)' />
              </p>
              <div dir='rtl'>
                <RichText name={'about-us-ar'} text={textAr} setText={setTextAr} />
              </div>
            </div>
            <div className='col-md-6'>
              <p>
                <FormattedMessage id='About us' />
                <FormattedMessage id=' (EN)' />
              </p>
              <div dir='ltr'>
                <RichText name={'about-us-en'} text={textEn} setText={setTextEn} />
              </div>
            </div>
          </div>
          <div>
            <button
              className='btn btn-primary mt-5'
              disabled={(originalArText === textAr && originalEnText === textEn) || isSending}
              onClick={() => saveNewContent()}
            >
              {$t('Save')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const AdminAboutUsWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle>{formatMessage({ id: 'About us' })}</PageTitle>

      <AboutUsContent />
    </div>
  );
};

export { AdminAboutUsWrapper };
