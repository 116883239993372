import clsx from 'clsx';
import { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom'; // useHistory, useLocation,
import Select from 'react-select';
import { shallowEqual, useSelector } from 'react-redux';

import { axiosInstance } from '../../../../network/apis';
import { ICompany } from '../../../types/companies';
import { RootState } from '../../../../setup/redux/Store';
import { useQuery } from 'react-query';

import { $t } from '../../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../../_metronic/layout/core';
import { usePermissions } from '../../../hooks/usePermissions';
import { EditItemWithModal } from '../../../shared/tables/actions/EditItemWithModal';
import useWindowSize from '../../../hooks/useWindowSize';
import { SeparatorLines } from '../../../shared/Lines';
import { THIRD_PARTY_TABS, ThirdPartyDetailsHandler } from './components/TabSelection';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

export const AddClientTabsWrapper: FC<Props> = ({ mode }) => {
  // const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const { width } = useWindowSize();

  // const { formatMessage } = useIntl();
  // const location = useLocation();
  const displayedTabs: any = useSelector<RootState>(
    (state) => state?.USER_clients.displayedTabs,
    shallowEqual
  ) as any;
  // console.log('displayedTabs', displayedTabs);

  const tabOptions = THIRD_PARTY_TABS.filter((tab) => displayedTabs?.includes(tab.id)).map(
    (tab) => ({
      value: tab.id,
      label: $t(tab.tabId),
    })
  );

  // console.log('tabOptions', tabOptions);

  return (
    <>
      {width > 1156 ? (
        <div className='brts-radius-20 blts-radius-20 bg-white h-40px pt-3 px-2 details_nav'>
          {THIRD_PARTY_TABS.filter((tab) => displayedTabs?.includes(tab.id)).map((tab) => (
            <span
              className={clsx(
                `mx-2 cursor-pointer text-blueGrey fs-6 ${selectedTab === tab.id ? 'current' : ''}`
              )}
              onClick={() => {
                setSelectedTab(tab.id);
              }}
            >
              <i className={clsx(`far ${tab.tabIcon} mx-1`)}></i>
              {$t(tab.tabId)}
            </span>
          ))}
        </div>
      ) : (
        <Select
          onChange={(value) => setSelectedTab(value?.value || 1)}
          options={tabOptions}
          value={tabOptions.find((itm) => selectedTab === itm.value)}
          className='react-select smaller'
          id='third_party_info_type'
        />
      )}

      <section className='brbs-radius-20 blbs-radius-20 bg-white p-10 '>
        <SeparatorLines lines={1} />
        <ThirdPartyDetailsHandler currentView={selectedTab} mode={mode} />
      </section>
    </>
  );
};

const AddClientTabsWrapperWrapperCreate: FC = () => {
  usePermissions(['create-third_party']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Add third party' })}</PageTitle>
      <AddClientTabsWrapper mode='create' />
    </div>
  );
};

const AddClientTabsWrapperWrapperView: FC = () => {
  usePermissions(['view-third_party']);
  const [editItemId, setEditItemId] = useState<any>(null);
  // const history = useHistory();
  // const location = useLocation();
  const { userCan } = usePermissions();
  const params: any = useParams();

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const { data: client, refetch: getClientDetails } = useQuery(
    'Client',
    () =>
      axiosInstance(`${companyDetails.id}/clients/${params.clientId}`).then((res) => {
        return res.data;
      }),
    { enabled: false, keepPreviousData: false, cacheTime: 0 }
  );

  useEffect(() => {
    if (params?.clientId) {
      getClientDetails();
    }
  }, [params]);

  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Preview third party' })}</PageTitle>
      <div className='m-3'>
        <div className='d-flex justify-content-end'>
          {userCan('update-third_party') && (
            <div
              className='d-flex justify-content-end'
              style={{ textAlign: 'end', alignSelf: 'center' }}
            >
              <EditItemWithModal
                id={client?.id}
                title={$t('Edit')}
                isButton
                buttonMessageId={'Edit third party'}
                messageId={
                  client?.can_update
                    ? client?.show_warning
                      ? 'This third party has been selected in created sale/purchase, are you sure you want to proceed the editing process?'
                      : ''
                    : 'This third party is included in a approved or draft report, are you sure you want to proceed the editing process?'
                }
                editURI={`/c/${companyDetails.slug}/company-management/clients-and-vendors/${client?.id}/edit`}
                showButtonGroup={true}
                editItemId={editItemId}
                setEditItemId={setEditItemId}
              />
            </div>
          )}
        </div>
      </div>

      <AddClientTabsWrapper mode='view' />
    </div>
  );
};
const AddClientTabsWrapperWrapperEdit: FC = () => {
  usePermissions(['update-third_party']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Edit third party' })}</PageTitle>
      <AddClientTabsWrapper mode='edit' />
    </div>
  );
};

export {
  AddClientTabsWrapperWrapperCreate,
  AddClientTabsWrapperWrapperView,
  AddClientTabsWrapperWrapperEdit,
};
