import { FormattedMessage } from 'react-intl';
import { Registration } from '../components/Registration';
import { LinksUnderRegisterForm } from '../components/LinksUnderRegisterForm';
import { LogoAndLoginLink } from '../components/LogoAndRedirectionLink';
import clsx from 'clsx';
import useWindowSize from '../../../hooks/useWindowSize';
import { RegisterPageFancySide } from '../components/RegisterPageFancySide';
import { LocaleToggler } from '../../../shared/LocaleToggler';

export const WHY_AHAD = [
  // 'You can embed your brand to you account and documents!',
  // 'E-Signature, and managing the workflow from designing contracts to signing it!',
  // 'The ability to add all company employees and assigning roles to them to get the work done!',
  // 'Your ability to enter multiple companies from one account!',
  'Ease of entering transactions',
  'A constantly updated system according to the latest tax laws',
  'Archive transactions and reports and return to them at any time',
];

export const RegistrationContainer = () => {
  const { width } = useWindowSize();
  return (
    <div
      className={clsx('d-flex flex-row p-10 rounded', {
        'mh-90': width > 1440,
      })}
    >
      <div
        className={clsx('bg-gray-100  shadow-sm p-4 p-lg-10 flex-fill bg-white bls-radius-25', {
          'b-radius-25': width < 768,
          'py-12': width < 768,
          'px-8': width < 768,
        })}
        style={{ width: width < 768 ? 'auto' : '50%' }}
      >
        <LogoAndLoginLink direction='login' />
        <div className='mb-5 mt-2 d-flex justify-content-between align-items-center'>
          <h1 className='text-dark mb-1'>
            <FormattedMessage id='Sign up to Ahad' />
          </h1>
          <LocaleToggler />
        </div>

        <Registration />
        <LinksUnderRegisterForm />
      </div>
      <RegisterPageFancySide />
    </div>
  );
};
