/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { AddEditRolesForm } from './AddEditForm/AddEditRoleForm';
import { usePermissions } from '../../hooks/usePermissions';

const RolesAddWrapper: FC = () => {
  usePermissions('create-role');

  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='add-edit-roles'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'create-role' })}</PageTitle>
      <AddEditRolesForm mode='create' />
    </div>
  );
};
const RolesViewWrapper: FC = () => {
  usePermissions('view-role');

  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='add-edit-roles'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'view-role' })}</PageTitle>
      <AddEditRolesForm mode='view' />
    </div>
  );
};
const RolesEditWrapper: FC = () => {
  usePermissions('update-role');

  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='add-edit-roles'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'update-role' })}</PageTitle>
      <AddEditRolesForm mode='edit' />
    </div>
  );
};

export { RolesAddWrapper, RolesViewWrapper, RolesEditWrapper };
