/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import Cookies from 'js-cookie';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import * as auth from '../store/AuthRedux';

import { login as loginRequest, authUser } from '../store/AuthCRUD';
import { Input } from '../../../shared/Input';
import { useHistory } from 'react-router-dom';
import { PublicRoutes } from '../../../../_metronic/constants/ROUTES';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';
import { emailPattern } from '../../../../_metronic/constants/patterns';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import * as CurrentCompanyRedux from '../../../../app/store/CurrentCompanyRedux';

const initialValues = {
  email: '',
  password: '',
  rememberMe: false,
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const loginSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .matches(emailPattern, formatMessage({ id: 'Invalid email' }))
          .email(formatMessage({ id: 'Invalid email' }))
          .required(formatMessage({ id: 'This field is required' })),
        password: Yup.string()
          .min(
            commonValidations.passwordMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.passwordMinimum }
            )
          )
          .max(
            commonValidations.passwordMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.passwordMaximum }
            )
          )
          .required(formatMessage({ id: 'This field is required' })),
      }),
    [formatMessage]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        // get user token
        const loginResponse = await loginRequest(values.email, values.password);
        const { access_token: token } = loginResponse.data;
        // Cookies.set(token, token);
        localStorage.setItem('rememberUser', values.rememberMe.toString());
        // get user data
        const userData = await authUser(values.email, values.password, token);
        const user = userData.data;

        console.log('login');
        dispatch(
          CurrentCompanyRedux.actions.setCurrentCompany({
            company: null,
            user: null,
          })
        );
        dispatch(auth.actions.login({ token, user: { ...user, rememebrMe: values.rememberMe } }));

        toast.success(formatMessage({ id: 'Welcome back' }));
      } catch (error: any) {
        DisplayErrors(error);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  return (
    <form
      noValidate
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-lg-16 pb-lg-10 p-md-10'
      style={{
        backgroundImage: 'linear-gradient(to bottom, #fafbfd, #fff)',
        borderRadius: '30px',
        minHeight: '450px',
      }}
      autoComplete='off'
      onSubmit={formik.handleSubmit}
      id='kt_login_signin_form'
      data-testid='sign-in-form'
    >
      <div className='fv-row mb-10'>
        <Input
          placeholder={formatMessage({ id: 'Email' })}
          autoComplete='off'
          labelId='Email'
          formik={formik}
          name='email'
          type='email'
        />
        <Input
          placeholder={formatMessage({ id: 'Password' })}
          autoComplete='off'
          labelId='Password'
          formik={formik}
          name='password'
          type='password'
        />
      </div>
      <div className='d-flex align-items-center mb-8'>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-5 flex-grow-1'>
          <input
            className='form-check-input'
            type='checkbox'
            defaultChecked={!!formik.values.rememberMe}
            onClick={() => formik.setFieldValue('rememberMe', !formik.values.rememberMe)}
          />
          <span className='form-check-label'>
            <FormattedMessage id='Remember Me' />
          </span>
        </label>
        <a
          className='fs-8 fw-bolder cursor-pointer'
          onClick={() => {
            history.push(PublicRoutes.forgetPassword);
          }}
        >
          <FormattedMessage id='Forget Password?' />
        </a>
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm w-100 mb-5 btn-primary btn-shadow btn-primary-shadow'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='Sign In' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Please wait...' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {/* <Or /> */}
        {/* <SocialMediaLogin /> */}
      </div>
    </form>
  );
}
