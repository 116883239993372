import Echo from 'laravel-echo';
// import io from 'socket.io-client';
import { FC, useEffect } from 'react'; //, useEffect
import { useIntl } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ScrollTop } from '../_metronic/layout/components/ScrollTop';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { RootState } from '../setup/redux/Store';
import { UserModel } from './modules/auth/models/UserModel';
import { Routes } from './routing/Routes';
import LogRocket from 'logrocket';
// import ReactGA from 'react-ga4';
import { FeedBackButton } from '../_metronic/layout/components/FeedBackButton';
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';

const queryClient = new QueryClient();

/* 
  ?why App looks like this
  It's all about unit test and making this App a unit test center to test behaviour
  from start to finish
*/

// declare global {
//   interface Window {
//     io: any;
//     Echo: any;
//   }
// }
declare global {
  interface Window {
    io: any;
    Pusher: any;
    Echo: any;
    ATL_JQ_PAGE_PROPS: any;
  }
}
interface AppProps {
  appUpdatePending: boolean;
  updateAction: () => void;
}

const App: FC<AppProps> = (props: AppProps) => {
  const { locale, formatMessage } = useIntl();
  const { appUpdatePending, updateAction } = props;

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  useEffect(() => {
    // disable on local(development) environmentand enable on production
    if (
      process.env.NODE_ENV !== 'development' &&
      process.env.REACT_APP_ENV === 'production' &&
      process.env.REACT_APP_LOG_ROCKET_ID
    ) {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID as string);
      console.log('LogRocket', process.env.REACT_APP_LOG_ROCKET_ID);

      if (user) {
        LogRocket.identify(`${user.id}`, {
          name: user.name,
          email: user.email,
          // Add your own custom user variables here, ie:
          subscriptionType: 'pro',
        });
      }
    }
  }, []);

  window.io = require('socket.io-client');

  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.REACT_APP_BACKEND_PUSHER_URL,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    timeout: 120000,
  });

  window.Echo.connector.socket.on('connect', () => {
    console.log('connected');
  });

  window.Echo.connector.socket.on('disconnect', () => {
    console.log('disconnected');
  });

  useEffect(() => {
    if (appUpdatePending) {
      enqueueSnackbar(
        formatMessage({
          id: 'App update is available. Refresh to update.',
        }),
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 10000,
          action: (key) => (
            <>
              <Button
                action={() => {
                  console.log('Refresh action');
                  closeSnackbar(key);
                  updateAction();
                }}
              />
            </>
          ),
          onClose: () => {
            console.log('onClose');
            updateAction();
          },
        }
      );
    }
  }, [appUpdatePending]);

  function Button({ action = () => {} }) {
    const onClick = () => action();
    return (
      <button
        style={{
          border: '0',
          color: 'white',
          // fontSize: '18px',
          marginInlineStart: '5rem',
          background: 'transparent',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {formatMessage({ id: 'Refresh' })}
      </button>
    );
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes />
        <ToastContainer position={locale === 'en' ? 'top-right' : 'top-left'} />
        <SnackbarProvider maxSnack={3} />
        <MasterInit />
        <FeedBackButton />
        <ScrollTop />
      </QueryClientProvider>
    </>
  );
};

export { App };
