import { Suspense, lazy } from 'react';
// import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom'; // Redirect,
// import { RootState } from '../../setup';
import {
  PrivateRoutes as R,
  PublicRoutes,
  AdminRoutes as SYS,
} from '../../_metronic/constants/ROUTES';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { BasicLayout } from '../../_metronic/layout/BaiscLayout';
import { FallbackView } from '../../_metronic/partials';
// import { UserModel } from '../modules/auth/models/UserModel';

import { DashboardWrapper as Dashboard } from '../pages/dashboard/DashboardWrapper';
import { CompaniesWrapper as Companies } from '../pages/Companies/CompaniesWrapper';
import { RolesWrapper as Roles } from '../pages/roles/Roles.wrapper';
import { UsersWrapper as AdminUsersWrapper } from '../pages/admins/users/UsersWrapper';
import { UsersWrapper as UserUsersWrapper } from '../modules/users/UsersWrapper';
import { UsersGroupsWrapper } from '../modules/groups/users/UsersGroupsWrapper';
import { ClientsGroupsWrapper } from '../modules/groups/clients/ClientsGroupsWrapper';
import { ClientsWrapper as UserClientsWrapper } from '../modules/clients/ClientsWrapper';
import { TemplatesWrapper as UserTemplatesWrapper } from '../modules/templates/TemplatesWrapper';
import { DocumentsWrapper as UserDocumentsWrapper } from '../modules/documents/DocumentsWrapper';
import { DocumentsToReviewWrapper } from '../modules/documents/DocumentsToReviewWrapper';

import { VerificationPage } from '../pages/verification/VerificationPage';
import { RouteWithLayout } from './RouteWithLayout';
import { NewCompanyAddedWrapper } from '../pages/Companies/NewCompanyAdded';
import { CompaniesWrapper } from '../pages/admins/companies/CompaniesWrapper';
import { Verify } from '../modules/auth/containers/Verify';
import {
  RolesAddWrapper,
  RolesViewWrapper,
  RolesEditWrapper,
} from '../pages/roles/RolesAddWrapper';
import { AdminDashboardLayout } from '../../_metronic/layout/AdminDashboardLayout';
import {
  CompanyDetailsWrapperEdit,
  CompanyDetailsWrapperView,
} from '../pages/Companies/CompanyDetails/CompanyDetailsWrapper';
import { UsersAddWrapper } from '../modules/users/AddUsersWrapper';
import { AdminAboutUsWrapper } from '../pages/admins/about-us/AboutUs';
import { AdminTermsConditionsWrapper } from '../pages/admins/terms-conditions/terms-conditions';
import { VatRatesWrapper } from '../pages/admins/vat-rates/VatRatesWrapper';
// import { ConfigNewVatRateCreate } from '../pages/admins/vat-rates/ConfigNewVatRate';
import {
  VatRatesDetailsWrapperCreate,
  VatRatesDetailsWrapperView,
  VatRatesDetailsWrapperEdit,
} from '../pages/admins/vat-rates/VatRateDetails/VatRatesDetailsWrapper';
import { CodeRulesWrapper } from '../pages/admins/code-rules/CodeRulesWrapper';
import { ConfigNewCodeRuleCreate } from '../pages/admins/code-rules/ConfigNewCodeRule';
import { UsersEditWrapper, UsersViewWrapper } from '../modules/users/UsersEditView';
import { UserCompaniesWrapper } from '../modules/user-companies/UserCompaniesWrapper';
import { CompaniesSelectionLayout } from '../../_metronic/layout/CompanySelectionLayout';
import { CompaniesSelectionWrapper } from '../modules/companies/CompaniesSelection';
// import {
//   AddClientsWrapper,
//   ClientsViewWrapper,
//   ClientsEditWrapper,
// } from '../modules/clients/AddClientsWrapper';
import {
  AddClientTabsWrapperWrapperCreate,
  AddClientTabsWrapperWrapperEdit,
  AddClientTabsWrapperWrapperView,
} from '../modules/clients/AddClientTabs/AddClientTabsWrapper';
import { ImportNewClientsWrapperCreate } from '../modules/clients/ImportNewClientsWrapper';
import {
  ConfigNewProductWrapperCreate,
  ConfigNewProductWrapperEdit,
  ConfigNewProductWrapperView,
} from '../modules/products/ConfigNewProductWrapper';
import { ProductsWrapper } from '../modules/products/ProductsWrapper';
// import {
//   ConfigSalesNewInvoiceWrapperCreate,
//   ConfigSalesNewInvoiceWrapperView,
//   ConfigSalesNewInvoiceWrapperEdit,
// } from '../modules/invoices/ConfigNewSalesInvoiceWrapper';
// import {
//   ConfigNewPurchasesInvoiceWrapperCreate,
//   ConfigNewPurchasesInvoiceWrapperView,
//   ConfigNewPurchasesInvoiceWrapperEdit,
// } from '../modules/invoices/ConfigNewPurchasesInvoiceWrapper';
// import {
//   ConfigReturnSalesNewInvoiceWrapperCreate,
//   ConfigReturnSalesNewInvoiceWrapperView,
//   ConfigReturnSalesNewInvoiceWrapperEdit,
// } from '../modules/invoices/ConfigNewReturnSalesInvoiceWrapper';
// import {
//   ConfigReturnPurchasesNewInvoiceWrapperCreate,
//   ConfigReturnPurchasesNewInvoiceWrapperView,
//   ConfigReturnPurchasesNewInvoiceWrapperEdit,
// } from '../modules/invoices/ConfigNewReturnPurchasesInvoiceWrapper';
// import { InvoicesWrapper } from '../modules/invoices/InvoicesWrapper';
// import { ImportNewInvoicesWrapperCreate } from '../modules/invoices/ImportNewInvoicesWrapper';
import { SalesWrapper } from '../modules/sales/SalesWrapper';
import {
  ConfigNewSalesWrapperCreate,
  ConfigNewSalesWrapperEdit,
  ConfigNewSalesWrapperView,
} from '../modules/sales/ConfigNewSalesWrapper';
import {
  ConfigNewSalesReturnWrapperCreate,
  ConfigNewSalesReturnWrapperEdit,
  ConfigNewSalesReturnWrapperView,
} from '../modules/sales/ConfigNewSalesReturnWrapper';
import { ImportNewSalesWrapperCreate } from '../modules/sales/ImportNewSalesWrapper';
import { PurchasesWrapper } from '../modules/purchases/PurchasesWrapper';
import {
  ConfigNewPurchasesWrapperCreate,
  ConfigNewPurchasesWrapperEdit,
  ConfigNewPurchasesWrapperView,
} from '../modules/purchases/ConfigNewPurchasesWrapper';
import {
  ConfigNewPurchasesReturnWrapperCreate,
  ConfigNewPurchasesReturnWrapperEdit,
  ConfigNewPurchasesReturnWrapperView,
} from '../modules/purchases/ConfigNewPurchasesReturnWrapper';
import { ImportNewPurchasesWrapperCreate } from '../modules/purchases/ImportNewPurchasesWrapper';
import { ReportsWrapper } from '../modules/reports/ReportsWrapper';
import {
  ConfigNewReportsWrapperCreate,
  // ConfigNewReportsWrapperEdit,
  // ConfigNewReportsWrapperView,
} from '../modules/reports/ConfigNewReportsWrapper';
import {
  ApproveReportWrapperView,
  ApproveReportWrapperEdit,
} from '../modules/reports/ApproveReportWrapper';
import { TransactionsDetailsWrapper } from '../modules/transactions/TransactionsDetailsWrapper';

import { WithholdingReportsWrapper } from '../modules/withholding-reports/WithholdingReportsWrapper';
import {
  ConfigNewWithholdingReportsWrapperCreate,
  // ConfigNewReportsWrapperEdit,
  // ConfigNewReportsWrapperView,
} from '../modules/withholding-reports/ConfigNewWithholdingReportsWrapper';
import {
  ApproveWithholdingReportWrapperView,
  ApproveWithholdingReportWrapperEdit,
} from '../modules/withholding-reports/ApproveWithholdingReportWrapper';
import { WithholdingTransactionsDetailsWrapper } from '../modules/withholding-transactions/WithholdingTransactionsDetailsWrapper';

import { InvoicesReportsWrapper } from '../modules/invoices-reports/InvoicesReportsWrapper';
import {
  ConfigNewInvoicesReportsWrapperCreate,
  // ConfigNewReportsWrapperEdit,
  // ConfigNewReportsWrapperView,
} from '../modules/invoices-reports/ConfigNewInvoicesReportsWrapper';
import {
  ApproveInvoicesReportWrapperView,
  ApproveInvoicesReportWrapperEdit,
} from '../modules/invoices-reports/ApproveInvoicesReportWrapper';

import { InvoicesWithholdingReportsWrapper } from '../modules/invoices-withholding-reports/InvoicesWithholdingReportsWrapper';
import {
  ConfigNewInvoicesWithholdingReportsWrapperCreate,
  // ConfigNewReportsWrapperEdit,
  // ConfigNewReportsWrapperView,
} from '../modules/invoices-withholding-reports/ConfigNewInvoicesWithholdingReportsWrapper';
import {
  ApproveInvoicesWithholdingReportWrapperView,
  ApproveInvoicesWithholdingReportWrapperEdit,
} from '../modules/invoices-withholding-reports/ApproveInvoicesWithholdingReportWrapper';
import { InvoicesWithholdingReportTransactionsDetailsWrapper } from '../modules/invoices-withholding-reports-transactions/InvoicesWithholdingReportTransactionsDetailsWrapper';

import { InvoicesReportTransactionsDetailsWrapper } from '../modules/invoices-reports-transactions/InvoicesReportTransactionsDetailsWrapper';
import { TemplateBuilderLayout } from '../../_metronic/layout/TemplateBuilderLayout';
import { ConfigTemplateWrapper } from '../modules/templates/ConfigNewTemplateWrapper';
import { UserProfileView, UserProfileEdit } from '../modules/user-profile/UserProfile';
import { UserLinkedProfileView } from '../modules/user-profile/UserLinkedProfile';
import { LogsWrapper } from '../modules/log/LogsWrapper';
import { DocumentEditor } from '../modules/documents/DocumentEditor';
import { DocumentBuilderLayout } from '../../_metronic/layout/DocumentBuilderLayout';
import { DocumentsToTakeActionWrapper } from '../modules/documents/DocumentsToTakeActionWrapper';
import { SingleDocumentToTakeAction } from '../modules/documents/SingleDocumentToTakeAction';

import { AddEditUserGroupsForm } from '../modules/groups/users/AddEditUsersGroups';
import { AddEditClientGroupsForm } from '../modules/groups/clients/AddEditClientGroups';
import { PublicLayout } from '../../_metronic/layout/PublicLayout';
import { NonRegisteredClientDocument } from '../modules/documents/NonRegisteredClientDocument';
import {
  TemplateConfigurationView,
  TemplateConfigurationEdit,
} from '../modules/templates/TemplateConfiguration';
import {
  ConfigNewCustomVariablesCreate,
  // ConfigNewCustomVariablesView,
  // ConfigNewCustomVariablesEdit,
} from '../modules/templates/CustomVariables/ConfigNewCustomVariables';
import { CustomVariablesWrapper } from '../modules/templates/CustomVariables/CustomVariablesWrapper';
// import { SalesLineItemsWrapper } from '../modules/sales-line-items/SalesLineItemsWrapper';
// import {
// ConfigNewSalesLineItemsWrapperView,
// ConfigNewSalesWrapperCreate,
// ConfigNewSalesWrapperEdit,
// } from '../modules/sales-line-items/ConfigNewSalesLineItemsWrapper';
// import { PurchasesLineItemsWrapper } from '../modules/purchases-line-items/PurchasesLineItemsWrapper';
// import {
// ConfigNewPurchasesLineItemsWrapperView,
// ConfigNewSalesWrapperCreate,
// ConfigNewSalesWrapperEdit,
// } from '../modules/purchases-line-items/ConfigNewPurchasesLineItemsWrapper';

import { SalesInvoicesWrapper } from '../modules/sales-invoices/SalesInvoicesWrapper';
import { ImportNewSalesInvoicesWrapperCreate } from '../modules/sales-invoices/ImportNewSalesInvoicesWrapper';
import {
  ConfigSalesNewInvoiceWrapperCreate,
  ConfigSalesNewInvoiceWrapperView,
  ConfigSalesNewInvoiceWrapperEdit,
} from '../modules/sales-invoices/ConfigNewSalesInvoiceWrapper';
import {
  ConfigReturnSalesNewInvoiceWrapperCreate,
  ConfigReturnSalesNewInvoiceWrapperView,
  ConfigReturnSalesNewInvoiceWrapperEdit,
} from '../modules/sales-invoices/ConfigNewReturnSalesInvoiceWrapper';

import { PurchasesInvoicesWrapper } from '../modules/purchases-invoices/PurchasesInvoicesWrapper';
import { ImportNewPurchasesInvoicesWrapperCreate } from '../modules/purchases-invoices/ImportNewPurchasesInvoicesWrapper';
import {
  ConfigNewPurchasesInvoiceWrapperCreate,
  ConfigNewPurchasesInvoiceWrapperView,
  ConfigNewPurchasesInvoiceWrapperEdit,
} from '../modules/purchases-invoices/ConfigNewPurchasesInvoiceWrapper';
import {
  ConfigReturnPurchasesNewInvoiceWrapperCreate,
  ConfigReturnPurchasesNewInvoiceWrapperView,
  ConfigReturnPurchasesNewInvoiceWrapperEdit,
} from '../modules/purchases-invoices/ConfigNewReturnPurchasesInvoiceWrapper';
import { LineItemsWrapper } from '../modules/line-items/LineItemsWrapper';
import { ConfigNewLineItemsWrapperView } from '../modules/line-items/ConfigNewLineItemsWrapper';
import { CompaniesDeletionRequestsWrapper } from '../pages/admins/companies-deletion-requests/CompaniesDeletionRequestsWrapper';

import { SubscriptionPlansWrapper } from '../pages/admins/subscription-plans/SubscriptionPlansWrapper';
import {
  CreateSubscriptionPlans,
  ViewSubscriptionPlans,
  EditSubscriptionPlans,
} from '../pages/admins/subscription-plans/ViewAndEditSubscriptionPlans';
import UserInfo from '../pages/admins/users/UserInfo';
import UserEdit from '../pages/admins/users/UserEdit';

import { ViewCodeRules, EditCodeRules } from '../pages/admins/code-rules/ViewCodeRules';

import { AdminCompanyDetailsWrapper } from '../pages/admins/companies/AdminCompanyDetails';

import { TransactionsWrapper as AdminTransactionsWrapper } from '../pages/admins/transactions/TransactionsWrapper';
import { TransactionInfo as AdminTransactionInfo } from '../pages/admins/transactions/TransactionInfo';
import { TransactionEdit as AdminTransactionEdit } from '../pages/admins/transactions/TransactionEdit';

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));

  // const user: UserModel = useSelector<RootState>(
  //   (state) => state.auth.user,
  //   shallowEqual
  // ) as UserModel;

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <RouteWithLayout
          exact
          layout={CompaniesSelectionLayout}
          path={R.Homepage}
          component={CompaniesSelectionWrapper}
        />
        <RouteWithLayout
          exact
          layout={CompaniesSelectionLayout}
          path={R.UserProfileEdit}
          component={UserProfileEdit}
        />
        <RouteWithLayout
          exact
          layout={CompaniesSelectionLayout}
          path={R.UserProfileView}
          component={UserProfileView}
        />
        {/* ---------------------------------- Users Companies --------------------------------- */}
        <RouteWithLayout
          exact
          path={R.UserCompaniesView}
          layout={CompaniesSelectionLayout}
          component={UserCompaniesWrapper}
        />
        <RouteWithLayout
          exact
          layout={CompaniesSelectionLayout}
          path={R.UserLinkedProfile}
          component={UserLinkedProfileView}
        />
        <RouteWithLayout
          exact
          layout={MasterLayout}
          path={R.companyHomePage}
          component={Dashboard}
        />
        <RouteWithLayout exact layout={MasterLayout} path={R.Companies} component={Companies} />
        <RouteWithLayout
          exact
          layout={MasterLayout}
          path={R.companyDetailsView}
          component={CompanyDetailsWrapperView}
        />
        <RouteWithLayout
          exact
          layout={MasterLayout}
          path={R.companyDetailsEdit}
          component={CompanyDetailsWrapperEdit}
        />
        <RouteWithLayout exact layout={MasterLayout} path={R.companyRoles} component={Roles} />
        <RouteWithLayout
          exact
          layout={MasterLayout}
          path={R.companyRoleAdd}
          component={RolesAddWrapper}
        />
        <RouteWithLayout
          exact
          layout={MasterLayout}
          path={R.companyRoleView}
          component={RolesViewWrapper}
        />
        <RouteWithLayout
          exact
          layout={MasterLayout}
          path={R.companyRoleEdit}
          component={RolesEditWrapper}
        />
        <RouteWithLayout
          exact
          layout={MasterLayout}
          path={R.NewCompanyAdded}
          component={NewCompanyAddedWrapper}
        />
        <RouteWithLayout exact path={R.Users} layout={BasicLayout} component={UserUsersWrapper} />
        {/* --------------------------------- Groups --------------------------------- */}
        {/* //Users */}
        <RouteWithLayout
          exact
          path={R.UsersGroups}
          layout={BasicLayout}
          component={UsersGroupsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.UsersGroupAddEdit}
          layout={BasicLayout}
          component={AddEditUserGroupsForm}
        />
        {/* // Clients */}
        <RouteWithLayout
          exact
          path={R.ClientsGroups}
          layout={BasicLayout}
          component={ClientsGroupsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.ClientsGroupAddEdit}
          layout={BasicLayout}
          component={AddEditClientGroupsForm}
        />
        {/* --------------------------------- LOG -------------------------------- */}
        <RouteWithLayout exact path={R.AuditLog} layout={BasicLayout} component={LogsWrapper} />
        {/* --------------------------------- Clients Third  Party -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.Clients}
          layout={BasicLayout}
          component={UserClientsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.ClientsImport}
          layout={BasicLayout}
          component={ImportNewClientsWrapperCreate}
        />
        {/* <RouteWithLayout
          exact
          path={R.ClientsAdd}
          layout={BasicLayout}
          component={AddClientsWrapper}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.ClientsView}
          layout={BasicLayout}
          component={ClientsViewWrapper}
        />
        <RouteWithLayout
          exact
          path={R.ClientsEdit}
          layout={BasicLayout}
          component={ClientsEditWrapper}
        /> */}
        <RouteWithLayout
          exact
          path={R.ClientsAdd}
          layout={BasicLayout}
          component={AddClientTabsWrapperWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.ClientsView}
          layout={BasicLayout}
          component={AddClientTabsWrapperWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.ClientsEdit}
          layout={BasicLayout}
          component={AddClientTabsWrapperWrapperEdit}
        />
        {/* -------------------------------- Products -------------------------------- */}
        <RouteWithLayout exact path={R.Products} layout={BasicLayout} component={ProductsWrapper} />
        <RouteWithLayout
          exact
          path={R.ProductsAdd}
          layout={BasicLayout}
          component={ConfigNewProductWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.ProductsEdit}
          layout={BasicLayout}
          component={ConfigNewProductWrapperEdit}
        />
        <RouteWithLayout
          exact
          path={R.ProductsView}
          layout={BasicLayout}
          component={ConfigNewProductWrapperView}
        />
        {/* -------------------------------- Invoices -------------------------------- */}
        {/* <RouteWithLayout exact path={R.Invoices} layout={BasicLayout} component={InvoicesWrapper} />
        <RouteWithLayout
          exact
          path={R.InvoicesImport}
          layout={BasicLayout}
          component={ImportNewInvoicesWrapperCreate}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.SalesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigSalesNewInvoiceWrapperCreate}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.PurchasesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigNewPurchasesInvoiceWrapperCreate}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.ReturnSalesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigReturnSalesNewInvoiceWrapperCreate}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.ReturnPurchasesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigReturnPurchasesNewInvoiceWrapperCreate}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.SalesInvoicesView}
          layout={BasicLayout}
          component={ConfigSalesNewInvoiceWrapperView}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.PurchasesInvoicesView}
          layout={BasicLayout}
          component={ConfigNewPurchasesInvoiceWrapperView}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.ReturnSalesInvoicesView}
          layout={BasicLayout}
          component={ConfigReturnSalesNewInvoiceWrapperView}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.ReturnPurchasesInvoicesView}
          layout={BasicLayout}
          component={ConfigReturnPurchasesNewInvoiceWrapperView}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.SalesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigSalesNewInvoiceWrapperEdit}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.PurchasesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigNewPurchasesInvoiceWrapperEdit}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.ReturnSalesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigReturnSalesNewInvoiceWrapperEdit}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.ReturnPurchasesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigReturnPurchasesNewInvoiceWrapperEdit}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.InvoicesEdit}
          layout={BasicLayout}
          component={ConfigSalesNewInvoiceWrapperEdit}
        /> */}
        {/* -------------------------------- Sales invoices -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.SalesInvoices}
          layout={BasicLayout}
          component={SalesInvoicesWrapper}
        />
        <RouteWithLayout
          exact
          path={R.SalesInvoicesImport}
          layout={BasicLayout}
          component={ImportNewSalesInvoicesWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.SalesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigSalesNewInvoiceWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.ReturnSalesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigReturnSalesNewInvoiceWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.SalesInvoicesView}
          layout={BasicLayout}
          component={ConfigSalesNewInvoiceWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.ReturnSalesInvoicesView}
          layout={BasicLayout}
          component={ConfigReturnSalesNewInvoiceWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.SalesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigSalesNewInvoiceWrapperEdit}
        />
        <RouteWithLayout
          exact
          path={R.ReturnSalesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigReturnSalesNewInvoiceWrapperEdit}
        />
        {/* -------------------------------- Purchases invoices -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.PurchasesInvoices}
          layout={BasicLayout}
          component={PurchasesInvoicesWrapper}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesInvoicesImport}
          layout={BasicLayout}
          component={ImportNewPurchasesInvoicesWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigNewPurchasesInvoiceWrapperCreate}
        />

        <RouteWithLayout
          exact
          path={R.ReturnPurchasesInvoicesAdd}
          layout={BasicLayout}
          component={ConfigReturnPurchasesNewInvoiceWrapperCreate}
        />

        <RouteWithLayout
          exact
          path={R.PurchasesInvoicesView}
          layout={BasicLayout}
          component={ConfigNewPurchasesInvoiceWrapperView}
        />

        <RouteWithLayout
          exact
          path={R.ReturnPurchasesInvoicesView}
          layout={BasicLayout}
          component={ConfigReturnPurchasesNewInvoiceWrapperView}
        />

        <RouteWithLayout
          exact
          path={R.PurchasesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigNewPurchasesInvoiceWrapperEdit}
        />

        <RouteWithLayout
          exact
          path={R.ReturnPurchasesInvoicesEdit}
          layout={BasicLayout}
          component={ConfigReturnPurchasesNewInvoiceWrapperEdit}
        />

        {/* --------------------------------  invoice line items-------------------------------- */}
        <RouteWithLayout
          exact
          path={R.LineItems}
          layout={BasicLayout}
          component={LineItemsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.LineItemsView}
          layout={BasicLayout}
          component={ConfigNewLineItemsWrapperView}
        />

        {/* -------------------------------- Sales -------------------------------- */}
        <RouteWithLayout exact path={R.Sales} layout={BasicLayout} component={SalesWrapper} />
        <RouteWithLayout
          exact
          path={R.SalesCreate}
          layout={BasicLayout}
          component={ConfigNewSalesWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.SalesImport}
          layout={BasicLayout}
          component={ImportNewSalesWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.SalesEdit}
          layout={BasicLayout}
          component={ConfigNewSalesWrapperEdit}
        />
        <RouteWithLayout
          exact
          path={R.SalesView}
          layout={BasicLayout}
          component={ConfigNewSalesWrapperView}
        />
        {/* -------------------------------- Sales Return -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.SalesReturn}
          layout={BasicLayout}
          component={ConfigNewSalesReturnWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.SalesReturnView}
          layout={BasicLayout}
          component={ConfigNewSalesReturnWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.SalesReturnEdit}
          layout={BasicLayout}
          component={ConfigNewSalesReturnWrapperEdit}
        />
        {/* -------------------------------- Sales invoice line items-------------------------------- */}
        {/* <RouteWithLayout
          exact
          path={R.SalesLineItems}
          layout={BasicLayout}
          component={SalesLineItemsWrapper}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.SalesLineItemsView}
          layout={BasicLayout}
          component={ConfigNewSalesLineItemsWrapperView}
        /> */}
        {/* -------------------------------- Purchases -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.Purchases}
          layout={BasicLayout}
          component={PurchasesWrapper}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesCreate}
          layout={BasicLayout}
          component={ConfigNewPurchasesWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesImport}
          layout={BasicLayout}
          component={ImportNewPurchasesWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesEdit}
          layout={BasicLayout}
          component={ConfigNewPurchasesWrapperEdit}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesView}
          layout={BasicLayout}
          component={ConfigNewPurchasesWrapperView}
        />
        {/* -------------------------------- Purchases Return -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.PurchasesReturn}
          layout={BasicLayout}
          component={ConfigNewPurchasesReturnWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesReturnView}
          layout={BasicLayout}
          component={ConfigNewPurchasesReturnWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.PurchasesReturnEdit}
          layout={BasicLayout}
          component={ConfigNewPurchasesReturnWrapperEdit}
        />
        {/* -------------------------------- Sales invoice line items-------------------------------- */}
        {/* <RouteWithLayout
          exact
          path={R.PurchasesLineItems}
          layout={BasicLayout}
          component={PurchasesLineItemsWrapper}
        /> */}
        {/* <RouteWithLayout
          exact
          path={R.PurchasesLineItemsView}
          layout={BasicLayout}
          component={ConfigNewPurchasesLineItemsWrapperView}
        /> */}
        {/* -------------------------------- Reports -------------------------------- */}
        <RouteWithLayout exact path={R.Reports} layout={BasicLayout} component={ReportsWrapper} />
        <RouteWithLayout
          exact
          path={R.ReportsCreate}
          layout={BasicLayout}
          component={ConfigNewReportsWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.ReportsView}
          layout={BasicLayout}
          component={ApproveReportWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.ReportsEdit}
          layout={BasicLayout}
          component={ApproveReportWrapperEdit}
        />
        {/* -------------------------------- Transactions -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.Transactions}
          layout={BasicLayout}
          component={TransactionsDetailsWrapper}
        />

        {/* --------------------------------Withholding Reports -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.WithholdingReports}
          layout={BasicLayout}
          component={WithholdingReportsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.WithholdingReportsCreate}
          layout={BasicLayout}
          component={ConfigNewWithholdingReportsWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.WithholdingReportsView}
          layout={BasicLayout}
          component={ApproveWithholdingReportWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.WithholdingReportsEdit}
          layout={BasicLayout}
          component={ApproveWithholdingReportWrapperEdit}
        />

        {/* --------------------------------Withholding Transactions -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.WithholdingTransactions}
          layout={BasicLayout}
          component={WithholdingTransactionsDetailsWrapper}
        />

        {/* -------------------------------- Invoices Reports -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.InvoicesReports}
          layout={BasicLayout}
          component={InvoicesReportsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.InvoicesReportsCreate}
          layout={BasicLayout}
          component={ConfigNewInvoicesReportsWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.InvoicesReportsView}
          layout={BasicLayout}
          component={ApproveInvoicesReportWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.InvoicesReportsEdit}
          layout={BasicLayout}
          component={ApproveInvoicesReportWrapperEdit}
        />
        {/* -------------------------------- Invoices reports Transactions -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.InvoicesReportsTransactions}
          layout={BasicLayout}
          component={InvoicesReportTransactionsDetailsWrapper}
        />

        {/* -------------------------------- Invoices Withholding Reports -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.InvoicesWithholdingReports}
          layout={BasicLayout}
          component={InvoicesWithholdingReportsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.InvoicesWithholdingReportsCreate}
          layout={BasicLayout}
          component={ConfigNewInvoicesWithholdingReportsWrapperCreate}
        />
        <RouteWithLayout
          exact
          path={R.InvoicesWithholdingReportsView}
          layout={BasicLayout}
          component={ApproveInvoicesWithholdingReportWrapperView}
        />
        <RouteWithLayout
          exact
          path={R.InvoicesWithholdingReportsEdit}
          layout={BasicLayout}
          component={ApproveInvoicesWithholdingReportWrapperEdit}
        />

        {/* -------------------------------- Invoices Withholding Reports Transactions -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.InvoicesWithholdingReportsTransactions}
          layout={BasicLayout}
          component={InvoicesWithholdingReportTransactionsDetailsWrapper}
        />

        {/* -------------------------------- Templates -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.Templates}
          layout={BasicLayout}
          component={UserTemplatesWrapper}
        />
        <RouteWithLayout
          exact
          path={R.TemplateCreate}
          layout={TemplateBuilderLayout}
          component={ConfigTemplateWrapper}
        />
        <RouteWithLayout
          exact
          path={R.TemplateUpdate}
          layout={TemplateBuilderLayout}
          component={ConfigTemplateWrapper}
        />
        <RouteWithLayout
          exact
          path={R.Templates}
          layout={TemplateBuilderLayout}
          component={UserTemplatesWrapper}
        />
        <RouteWithLayout
          exact
          path={R.TemplatePreviewConfigurations}
          layout={MasterLayout}
          component={TemplateConfigurationView}
        />
        <RouteWithLayout
          exact
          path={R.TemplateEditConfigurations}
          layout={MasterLayout}
          component={TemplateConfigurationEdit}
        />
        <RouteWithLayout
          exact
          path={R.CustomVariablesWrapper}
          layout={MasterLayout}
          component={CustomVariablesWrapper}
        />
        <RouteWithLayout
          exact
          path={R.CustomVariablesWrapperCreate}
          layout={MasterLayout}
          component={ConfigNewCustomVariablesCreate}
        />
        {/* -------------------------------- Documents -------------------------------- */}
        <RouteWithLayout
          exact
          path={R.Documents}
          layout={MasterLayout}
          component={UserDocumentsWrapper}
        />
        <RouteWithLayout
          exact
          path={R.DocumentsReview}
          layout={MasterLayout}
          component={DocumentsToReviewWrapper}
        />
        <RouteWithLayout
          exact
          path={R.DocumentUpdate}
          layout={DocumentBuilderLayout}
          component={DocumentEditor}
        />
        <RouteWithLayout
          exact
          path={R.DocumentsToSign}
          layout={MasterLayout}
          component={DocumentsToTakeActionWrapper}
        />
        <RouteWithLayout
          exact
          path={R.TakingAction}
          layout={MasterLayout}
          component={SingleDocumentToTakeAction}
        />
        {/* ---------------------------------- Users --------------------------------- */}
        <RouteWithLayout exact path={R.UsersAdd} layout={BasicLayout} component={UsersAddWrapper} />
        <RouteWithLayout
          exact
          path={R.UsersEdit}
          layout={BasicLayout}
          component={UsersEditWrapper}
        />
        <RouteWithLayout
          exact
          path={R.UsersView}
          layout={BasicLayout}
          component={UsersViewWrapper}
        />
        {/* --------------------------------- verify --------------------------------- */}
        <Route exact path={PublicRoutes.verify} component={Verify} />
        <Route exact path={R.verification} component={VerificationPage} />
        <Route path='/builder' component={BuilderPageWrapper} />

        {/* ---------------------------------- Admin --------------------------------- */}
        {/* <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.AdminDashboard}
          component={CompaniesWrapper}
        /> */}
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.Companies}
          component={CompaniesWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.AdminCompanyDetailsView}
          component={AdminCompanyDetailsWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.CompaniesDeletionRequests}
          component={CompaniesDeletionRequestsWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.SubscriptionPlans}
          component={SubscriptionPlansWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.createSubscriptionPlans}
          component={CreateSubscriptionPlans}
        />

        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.ViewSubscriptionPlans}
          component={ViewSubscriptionPlans}
        />

        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.EditSubscriptionPlans}
          component={EditSubscriptionPlans}
        />

        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.Users}
          component={AdminUsersWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.UserInfo}
          component={UserInfo}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.UserEdit}
          component={UserEdit}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.VatRates}
          component={VatRatesWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.VatRatesCreate}
          component={VatRatesDetailsWrapperCreate}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.VatRatesView}
          component={VatRatesDetailsWrapperView}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.VatRatesEdit}
          component={VatRatesDetailsWrapperEdit}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.CodeRules}
          component={CodeRulesWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.CodeRulesCreate}
          component={ConfigNewCodeRuleCreate}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.ViewCodeRules}
          component={ViewCodeRules}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.EditCodeRules}
          component={EditCodeRules}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.AboutUs}
          component={AdminAboutUsWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.TermsConditions}
          component={AdminTermsConditionsWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.Transactions}
          component={AdminTransactionsWrapper}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.TransactionInfo}
          component={AdminTransactionInfo}
        />
        <RouteWithLayout
          exact
          layout={AdminDashboardLayout}
          path={SYS.TransactionEdit}
          component={AdminTransactionEdit}
        />
        <RouteWithLayout
          exact
          path={PublicRoutes.NonRegisteredClientDocument}
          layout={PublicLayout}
          component={NonRegisteredClientDocument}
        />
        {/* <Redirect from={R.companyDetailsView} to={`/c/:companySlug/details/profile`} /> */}
      </Switch>
    </Suspense>
  );
}
