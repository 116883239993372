/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl'; //, useIntl
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

import * as TableRedux from '../../store/TableRedux';

import { RootState } from '../../../setup/redux/Store';
import { perpage } from '../../../_metronic/constants/general';
import { ID } from '../../../_metronic/constants/paths';
import { Meta } from '../../types/Meta';

import { CompanyCard } from '../../modules/companies/components/CompanyCard';

// interface cardItem {
//   [key: string]: any;
// }

type Props = {
  className?: string;
  meta: Meta;
  cardItems?: Array<any>;
  hideFooter?: boolean;
  tableName: string;
  initPerPage?: number;
  allDisplayedIds: Array<number | ID>;
};

const CommonCardListTable: React.FC<Props> = ({
  className,
  cardItems,
  hideFooter = false,
  tableName,
  allDisplayedIds,
  meta,
  initPerPage,
}) => {
  // const { formatMessage } = useIntl();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TableRedux.actions.initTable({ tableName, allDisplayedIds, initPerPage }));
    return () => {
      dispatch(TableRedux.actions.terminateTable({ tableName }));
    };
  }, [tableName]);

  useEffect(() => {
    dispatch(
      TableRedux.actions.changeTablePagination({
        tableName,
        pagination: initPerPage || 20,
      })
    );
  }, []);

  useEffect(() => {
    if (allDisplayedIds?.length && meta.current_page !== page) {
      setPage(meta.current_page);
      dispatch(TableRedux.actions.updateIds({ tableName, allDisplayedIds }));
    }
  }, [allDisplayedIds, meta]);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[tableName];

  return (
    <div className={`${className}`} id={tableName} data-testid={`${tableName}_table`}>
      {/* begin::Body */}

      {/* begin::Table container */}
      <div className='contianer gap-5 jusify-content-center'>
        <div className=' b-radius-20 m-2 row'>
          {/* begin::Table */}
          {cardItems?.map(
            (company) =>
              company?.slug?.length && (
                <div className='col-xs-12 col-md-6 col-xl-4 col-xxl-3 my-3' key={company.slug}>
                  <CompanyCard company={company}></CompanyCard>
                </div>
              )
          )}
          {cardItems?.length === 0 && (
            <div className='col-12'>
              <div className='text-center'>
                <div className='text-center my-5'>
                  <img
                    src={toAbsoluteUrl('/media/404/no-companies-yet.jpg')}
                    alt=''
                    className='mw-300px'
                  />
                </div>{' '}
                <h3 className='text-center'>
                  <FormattedMessage id='You are not a member of any company yet, If you would like to create a company, please contact us on the following Number:' />{' '}
                  <a href='tel:+966554655881' dir='ltr'>
                    +(966)-554-655-881
                  </a>
                </h3>
              </div>
            </div>
          )}
          {/* end::Table */}
        </div>
      </div>
      {/* end::Table container */}

      {!hideFooter && (
        <div className='row justify-content-between mx-5 align-items-baseline'>
          <div className=' col-xs-12 col-md-6 col-xl-4 col-xxl-3 my-3 text-lightGreyBlue fs-6'>
            <FormattedMessage
              id='PaginationStatistics'
              values={{ from: meta?.from, to: meta?.to, total: meta?.total }}
            />
          </div>
          <div className='col-xs-12 col-md-6 col-xl-4 col-xxl-3 my-3'>
            <Pagination
              activePage={meta?.current_page}
              itemsCountPerPage={meta?.per_page}
              totalItemsCount={meta?.total || 0}
              pageRangeDisplayed={5}
              onChange={(page: number) =>
                dispatch(TableRedux.actions.changePage({ tableName, page }))
              }
              itemClass='page-item'
              linkClass='page-link'
            />
          </div>
          <div className='d-flex align-items-baseline text-lightGreyBlue col-xs-12 col-md-6 col-xl-4 col-xxl-3 my-3'>
            <p className='w-200px fs-6'>
              <FormattedMessage id='Items per page' />
            </p>
            <select
              className='form-select form-select-solid'
              name='table-pagination'
              defaultValue={table?.pagination || initPerPage}
              onChange={(e) =>
                dispatch(
                  TableRedux.actions.changeTablePagination({
                    tableName,
                    pagination: Number(e.target.value),
                  })
                )
              }
            >
              {perpage?.map((num: any) => (
                <option value={num} key={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* begin::Body */}
    </div>
  );
};
export { CommonCardListTable };
