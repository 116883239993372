import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import { getAllReports, getReport, getAllTransactions } from './ReportsCRUD';

export enum IReportsActionTypes {
  GET_REPORTS = 'GET_REPORTS',
  FULL_FILL_REPORTS = 'FULL_FILL_REPORTS',
  GET_CURRENT_REPORT = 'GET_CURRENT_REPORT',
  FULL_FILL_CURRENT_REPORT = 'FULL_FILL_CURRENT_REPORT',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  FULL_FILL_TRANSACTIONS = 'FULL_FILL_TRANSACTIONS',
}

const initialReports: IReportsState = {
  reports: undefined,
  currentReport: undefined,
  transactions: undefined,
};

export interface IReportsState {
  reports?: any;
  currentReport?: any;
  transactions?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_reports', whitelist: [''] },
  (state: IReportsState = initialReports, action: ActionWithPayload<IReportsState>) => {
    switch (action.type) {
      case IReportsActionTypes.FULL_FILL_REPORTS:
        return { ...state, reports: action.payload?.reports };
      case IReportsActionTypes.FULL_FILL_CURRENT_REPORT:
        return { ...state, currentReport: action.payload?.currentReport };
      case IReportsActionTypes.FULL_FILL_TRANSACTIONS:
        return { ...state, transactions: action.payload?.transactions };
      default:
        return state;
    }
  }
);

export const actions = {
  getReports: ({ companyId, params }: Search) => ({
    type: IReportsActionTypes.GET_REPORTS,
    payload: { companyId, params },
  }),
  fullFillReports: ({ reports }: IReportsState) => ({
    type: IReportsActionTypes.FULL_FILL_REPORTS,
    payload: { reports },
  }),
  getCurrentReport: ({ companyId, reportId }: any) => ({
    type: IReportsActionTypes.GET_CURRENT_REPORT,
    payload: { companyId, reportId },
  }),
  fullFillCurrentReport: ({ currentReport }: IReportsState) => ({
    type: IReportsActionTypes.FULL_FILL_CURRENT_REPORT,
    payload: { currentReport },
  }),
  getTransactions: ({ companyId, params }: Search) => ({
    type: IReportsActionTypes.GET_TRANSACTIONS,
    payload: { companyId, params },
  }),
  fullFillTransactions: ({ transactions }: IReportsState) => ({
    type: IReportsActionTypes.FULL_FILL_TRANSACTIONS,
    payload: { transactions },
  }),
};

export function* getReports(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllReports({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillReports({ reports: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentReport(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getReport({
      companyId: action.payload?.companyId,
      reportId: action.payload?.reportId,
    });
    yield put(actions.fullFillCurrentReport({ currentReport: data }));
  } catch (error) {
    //
  }
}

export function* getTransactions(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllTransactions({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillTransactions({ transactions: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IReportsActionTypes.GET_REPORTS, getReports);
  yield takeLatest(IReportsActionTypes.GET_TRANSACTIONS, getTransactions);
  yield takeLatest(IReportsActionTypes.GET_CURRENT_REPORT, getCurrentReport);
}
