import { FC } from 'react';

import { statisticVAT } from './statisticVAT';
import { StatisticVATCard } from './StatisticVATCard';

const statisticsVAT: statisticVAT[] = [
  {
    title: 'Sales for current month',
    type: 'sales',
    faIcon: 'fas fa-dollar-sign fs-2',
    total: 0,
    amount: 0,
    return: 0,
    rightSideTitle: 'Invoices amount',
    leftSideTitle: 'Return invoices',
  },
  {
    title: 'Purchases for current month',
    type: 'purchases',
    faIcon: 'fas fa-shopping-cart fs-2',
    total: 0,
    amount: 0,
    return: 0,
    rightSideTitle: 'Invoices amount',
    leftSideTitle: 'Return invoices',
  },
  {
    title: 'Unprocessed transactions',
    type: 'All transactions',
    faIcon: 'fas fa-exchange fs-2',
    total: 0,
    amount: 0,
    return: 0,
    rightSideTitle: 'Total sales tax',
    leftSideTitle: 'Total purchases tax',
  },
];

export const HomePageVatStatistics: FC<any> = ({ statisticsVATData }) => {
  statisticsVAT.map((statistic) => {
    if (statistic.type === 'sales') {
      statistic.amount = +statisticsVATData?.sales?.total_sales_amount;
      statistic.return = +statisticsVATData?.sales?.total_sales_return_amount;
      statistic.total = +statisticsVATData?.sales?.total_count;
    }
    if (statistic.type === 'purchases') {
      statistic.amount = +statisticsVATData?.purchases?.total_purchases_amount;
      statistic.return = +statisticsVATData?.purchases?.total_purchases_return_amount;
      statistic.total = +statisticsVATData?.purchases?.total_count;
    }
    if (statistic.type === 'All transactions') {
      statistic.amount = +statisticsVATData?.unprocessed_invoices?.total_sales_vat;
      statistic.return = +statisticsVATData?.unprocessed_invoices?.total_purchases_vat;
      statistic.total = +statisticsVATData?.unprocessed_invoices?.total_count;
    }

    return statistic;
  });
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        {statisticsVAT.map((stat, index) => (
          <div className='col-xl-4' key={index}>
            <StatisticVATCard
              statisticVAT={stat}
              key={stat.type}
              className='card-xl-stretch mb-xl-8'
            />
            {/* <MixedWidget1 className='card-xl-stretch mb-xl-8' color='primary' /> */}
          </div>
        ))}
      </div>{' '}
    </>
  );
};
