import { FieldArray, Formik } from 'formik';
import { serialize } from 'object-to-formdata';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Select from 'react-select';

import { axiosInstance } from '../../../../network/apis';
import { RootState } from '../../../../setup/redux/Store';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { emailPattern } from '../../../../_metronic/constants/patterns';
import { $t, FormatMessage } from '../../../../_metronic/i18n/formatMessage';
import { usePermissions } from '../../../hooks/usePermissions';
import { CloseButtonRedShadowed } from '../../../shared/CloseButtonRedShadowed';
import { Input } from '../../../shared/Input';
import { ICompany } from '../../../types/companies';
import { RolesDataListModel } from '../../roles/types/Roles';

type emailName = { email: string; roles: any[] };

export const InviteUsersForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();
  usePermissions('invite-user', 'Invite users');

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const roles: RolesDataListModel = useSelector<RootState>(
    (state) => state.roles.roles,
    shallowEqual
  ) as RolesDataListModel;

  const rolesOptions = useMemo(
    () =>
      roles?.data?.map((role) => ({
        label: role.name,
        value: role.id.toString(),
      })),
    [roles]
  );

  const validationSchema = Yup.object().shape({
    users: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .matches(emailPattern, $t('Invalid email'))
            .email($t('Invalid email'))
            .required($t('This field is required')),
          roles: Yup.array().min(1, $t('This field is required')),
        })
      )
      .required('Must have invitees')
      .min(1, 'Minimum of 1 invitees'),
  });

  const inviteUsers = async (values: any, resetForm: any) => {
    setLoading(true);
    const email = values?.users?.map((user: emailName) => user.email);
    const roles = values?.users?.map((user: emailName) => user.roles.map((role) => role.value));
    const formData = serialize({ email, roles }, { indices: true });
    try {
      await axiosInstance.post(`/${companyDetails.id}/users`, formData);
      toast.success(formatMessage({ id: 'Invitation has been sent successfully!' }));
      resetForm();
      history.push(
        location.pathname.replace(
          '/company-management/users/invite-users',
          '/company-management/users'
        )
      );
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={{
        users: [
          {
            email: '',
            roles: roles?.data
              ?.filter((role) => role.name === 'موظف' || role.name === 'Employee')
              .map((role) => ({
                value: role.id.toString(),
                label: role.name,
              })),
          },
        ],
      }}
      onSubmit={(values, { resetForm }) => inviteUsers(values, resetForm)}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, errors, ...formik }) => {
        return (
          <FieldArray name='users'>
            {({ push, remove }) => {
              return (
                <div
                  className='d-flex flex-column justify-content-between bg-white px-3 pt-2'
                  style={{
                    borderRadius: '20px',
                    minWidth: '50vw',
                    maxWidth: '50vw',
                    height: '300px',
                    width: '100%',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    position: 'relative',
                  }}
                >
                  <div className='row px-5 py-1'>
                    <div className='col-6'>{formatMessage({ id: 'Email' })}</div>
                    <div className='col-6'>{formatMessage({ id: 'Roles' })}</div>
                  </div>
                  <div
                    className='bg-white'
                    style={{ border: '1px solid #dee8f3', borderRadius: '20px' }}
                  >
                    <div>
                      {values?.users?.map((user, i, arr) => {
                        const error: emailName[] = errors?.users as emailName[];
                        const touched = formik.touched.users;
                        const currentEmail = values?.users?.[i]['email'];
                        const allEmails = values?.users?.map((user) => user.email);
                        const occurences = allEmails.filter(
                          (email) => currentEmail.length > 0 && email === currentEmail
                        );
                        const err =
                          occurences.length > 1
                            ? formatMessage({ id: 'This email is duplicate' })
                            : '';
                        return (
                          <div
                            key={JSON.stringify(i)}
                            className='row d-flex flex-row px-5 py-2'
                            style={{
                              borderBottom: i !== arr.length - 1 ? '1px solid #dee8f3' : '',
                            }}
                          >
                            <div className={arr.length < 2 ? 'col-6' : 'col-5'}>
                              <Input
                                placeholder={formatMessage({ id: 'Email' })}
                                autoComplete='off'
                                withValidation
                                formik={formik}
                                name={`users[${i}][email]`}
                                type='email'
                                error={(error?.length && error?.[i] && error?.[i].email) || err}
                                touched={touched?.[i]?.email}
                              />
                            </div>
                            <div className={arr.length < 2 ? 'col-6' : 'col-5'}>
                              <Select
                                key={JSON.stringify(values?.users?.[i].roles)}
                                id={`users[${i}][roles]`}
                                placeholder={formatMessage({ id: 'Roles' })}
                                className='react-select smaller'
                                value={values?.users?.[i]?.roles}
                                options={rolesOptions}
                                styles={{
                                  valueContainer: (base) => ({
                                    ...base,
                                    padding: '0.1rem 0.5rem',
                                    // width: '100%',
                                    // minWidth: '20rem',
                                    maxWidth: '40rem',
                                  }),
                                }}
                                // name={`users[${i}][roles]`}
                                // defaultValue={roles?.data
                                //   ?.filter(
                                //     (role) => role.name === 'موظف' || role.name === 'Employee'
                                //   )
                                //   .map((role) => ({
                                //     value: role.id.toString(),
                                //     label: role.name,
                                //   }))}
                                isMulti
                                onChange={(selection: any) => {
                                  setFieldValue(`users[${i}][roles]`, selection);
                                }}
                              />
                              {error?.length &&
                                error?.[i] &&
                                error?.[i].roles &&
                                touched?.[i]?.roles &&
                                user.roles.length === 0 && (
                                  <div className='fv-plugins-message-container mt-2 mx-3'>
                                    <span role='alert' className='text-danger'>
                                      {error?.[i].roles}
                                    </span>
                                  </div>
                                )}
                            </div>
                            {arr.length > 1 && (
                              <div className='col-1 mt-3'>
                                <CloseButtonRedShadowed onClick={() => remove(i)} />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    className='btn btn-outlined w-100 mt-2'
                    disabled={loading}
                    onClick={() =>
                      push({
                        email: '',
                        roles: roles?.data
                          ?.filter((role) => role.name === 'موظف' || role.name === 'Employee')
                          .map((role) => ({
                            value: role.id.toString(),
                            label: role.name,
                          })),
                      })
                    }
                  >
                    <i className='far fa-user'></i>
                    <FormatMessage id='Add User' />
                  </button>
                  <button
                    className='btn btn-sm btn-primary my-2 btn-shadow btn-primary-shadow'
                    disabled={loading}
                    style={{ width: 'fit-content' }}
                    onClick={() => formik.submitForm()}
                  >
                    <FormatMessage id='Invite Users' />
                  </button>
                </div>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};
