import { FC } from 'react';

export const RegularRuler: FC = () => {
  return (
    <div className='ruler'>
      {Array.from(Array(15).keys()).map((cm: any) => (
        <div className='cm' key={cm}>
          {Array.from(Array(10).keys()).map((mm: any) => (
            <div className='mm' key={mm}></div>
          ))}
        </div>
      ))}
    </div>
  );
};
