import { AsideDefault } from './components/aside/AsideDefault';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Toolbar } from './components/toolbar/Toolbar';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { usePermissions } from '../../app/hooks/usePermissions';
import { Footer } from './components/Footer';

const AdminDashboardLayout: React.FC = ({ children }) => {
  usePermissions('super_admin', 'visit this page');

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault user='ADMIN' />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-row flex-grow-1 mb-3'>
            <div className='flex-fill pt-3'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='content'>
                <Content>{children}</Content>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </PageDataProvider>
  );
};

export { AdminDashboardLayout };
