import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';

import {
  VatRatesTypes,
  TransactionTypes,
  TaxAbilityTypes,
  CategoryTypes,
} from '../../../modules/admins/vat-rates/types/VatRatesModel';

const useConfigureVatRatesSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const vatRatesInitialValues: { [key: string]: any } = useMemo(
    () => ({
      type: {
        value: VatRatesTypes.Income,
        label: formatMessage({ id: 'Income' }),
      },
      name: { ar: '', en: '' },
      name_ar: '',
      name_en: '',
      code: '',
      code_type: null,
      description_en: '',
      description_ar: '',
      transaction_type: {
        value: TransactionTypes.Domestic,
        label: formatMessage({ id: 'Domestic' }),
      },
      country: { value: 'SA', label: '🇸🇦 المملكة العربية السعودية' },
      tax_ability: {
        value: TaxAbilityTypes.Taxable,
        label: formatMessage({ id: 'Taxable' }),
      },
      return_value: 0,
      tax_rate: '',
      basic_categories: [
        {
          category_error_message_ar: '',
          category_error_message_en: '',
          category: [],
          category_type: [
            {
              value: CategoryTypes.Basic,
              label: formatMessage({ id: 'Basic' }),
            },
          ],
        },
      ],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const vatRatesValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name_ar: Yup.string().required(required),
        name_en: Yup.string().required(required),
        code: Yup.string().required(required),
        code_type: Yup.object().required(required),
        country: Yup.object().required(required),
        type: Yup.object().required(required),
        transaction_type: Yup.object().required(required),
        description_en: Yup.string()
          .required(required)
          .trim()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_en',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_ar: Yup.string()
          .required(required)
          .trim()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_ar',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        tax_ability: Yup.object().required(required),
        tax_rate: Yup.number()
          .typeError($t('Only number are allowed!'))
          .required(required)
          .test('tax_rate', $t('You must choose a number from 0 to 100'), (val: any) => {
            return val >= 0 && val <= 100;
          })
          .test(
            'len',
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: 8 }
            ),
            (val: any) => !val || (val && val.toString().length <= 8)
          ),

        basic_categories: Yup.array()
          .of(
            Yup.object().shape({
              category: Yup.array()
                .required(required)
                .min(1, formatMessage({ id: 'Please choose at least one' })),
              category_type: Yup.array()
                .required(required)
                .min(1, formatMessage({ id: 'Please choose at least one' })),
              category_error_message_ar: Yup.string().when('category_type', {
                is: (val: any) => {
                  return val && val.length > 0 && val[0].value === 2;
                },
                then: Yup.string()
                  .required(required)
                  .trim()
                  .min(
                    commonValidations.nameMinimum,
                    $t('Please enter at least {minCharacters} characters', {
                      minCharacters: commonValidations.nameMinimum,
                    })
                  )
                  .max(
                    commonValidations.descriptionMaximum,
                    $t('The Maximum characters is {maxCharacters}', {
                      maxCharacters: commonValidations.descriptionMaximum,
                    })
                  )
                  .test(
                    'category_error_message',
                    $t("You can't enter special character at first or last!"),
                    (val) => nameWithoutSpcialCharsStartOrEnd(val)
                  ),
                otherwise: Yup.string().nullable(),
              }),
              category_error_message_en: Yup.string().when('category_type', {
                is: (val: any) => {
                  return val && val.length > 0 && val[0].value === 2;
                },
                then: Yup.string()
                  .required(required)
                  .trim()
                  .min(
                    commonValidations.nameMinimum,
                    $t('Please enter at least {minCharacters} characters', {
                      minCharacters: commonValidations.nameMinimum,
                    })
                  )
                  .max(
                    commonValidations.descriptionMaximum,
                    $t('The Maximum characters is {maxCharacters}', {
                      maxCharacters: commonValidations.descriptionMaximum,
                    })
                  )
                  .test(
                    'category_error_message',
                    $t("You can't enter special character at first or last!"),
                    (val) => nameWithoutSpcialCharsStartOrEnd(val)
                  ),
                otherwise: Yup.string().nullable(),
              }),
            })
          )
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
      }),
    [formatMessage]
  );

  return {
    vatRatesInitialValues,
    vatRatesValidationSchema,
  };
};

export { useConfigureVatRatesSchema };
