import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IHighlight } from 'react-pdf-highlighter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ID } from '../../../_metronic/constants/paths';
import { $t, FormatMessage } from '../../../_metronic/i18n/formatMessage';
import { Portal } from '../../../_metronic/partials';
import { axiosInstance } from '../../../network/apis';
import { RootState } from '../../../setup/redux/Store';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { UserModel } from '../auth/models/UserModel';
import * as DocumentsRedux from '../documents/store/DocumentsRedux';
import { IDocument } from '../documents/types/DocumentsModel';
import { AddSignatureModal } from '../user-profile/AddSignatureModal';
import { DOCS_STATUS, DocumentStatuses } from '../../../_metronic/constants/general';
import { Badge } from '../../../_metronic/helpers/typescript/csssClasses';
import { TableStatus } from '../../shared/tables/classes';

import * as AuthRedux from '../auth/store/AuthRedux';

import {
  NonRegisteredClientDocumentTypes,
  paramsType,
} from '../documents/types/NonRegisteredClientDocumentTypes';
import { DocumentApproval } from '../documents/DocumentProcess/DocumentApproveAndSign';
import { toast } from 'react-toastify';
import { CompanyModel } from '../companies/CompanyModel';

import { DocumentReviewOnly } from '../documents/DocumentProcess/DocumentReviewOnly';
import { usePermissions } from '../../hooks/usePermissions';
import { useQuerySearch } from '../../hooks/useQuerySearch/useQuerySearch';
import * as CurrentCompanyRedux from '../../../app/store/CurrentCompanyRedux';

interface Props {
  highlights: Array<IHighlight>;
  resetHighlights: () => void;
  removeComment: Function;
  document: IDocument;
  isViewOnly: boolean;
  refetchDoc?: Function;
}

export function Sidebar({
  highlights,
  resetHighlights,
  removeComment,
  document,
  isViewOnly,
  refetchDoc,
}: Props) {
  const params: any = useParams();

  const { locale, formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { userCan, userCanNot } = usePermissions();
  const query = useQuerySearch();
  let slug = query.get('slug');

  const signatureImageRef = useRef<HTMLInputElement | null>(null);

  const [openAddSignatureModal, setOpenAddSignatureModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [image, setImage] = useState<string | Blob>('');
  const [name, setName] = useState('');
  const [openSelectSignatureModal, setOpenSelectSignatureModal] = useState(false);
  const [signature, setSignature] = useState<null | ID>(null);
  const [success, setSuccess] = useState(false);
  const [documentToApproveModal, setDocumentToApproveModal] = useState<null | IDocument>(null);

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;


  const switchRequest = async (slug: string) => {
    await axiosInstance.post(`/${slug}/switch`).then((res) => {
      dispatch(CurrentCompanyRedux.actions.setCurrentCompany({ ...res.data }));
    });
  };

  useEffect(() => {
    if (slug) {
      switchRequest(slug);
    }
  }, [slug]);

  const badge = new TableStatus({
    statusId: formatMessage({ id: `${DocumentStatuses.get(document?.status)?.label}` }),
    badge: `${DocumentStatuses.get(document?.status)?.badge}` as Badge,
  });


  const requestProfile = async () =>
    await axiosInstance.get('/profile').then((res) => {
      dispatch(AuthRedux.actions.setUser(res.data));
    });

  const uploadSignature = async (img: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('image', img);
    formData.append('name', name);
    try {
      await axiosInstance.post('/signatures/image', formData);

      toast.success(formatMessage({ id: 'The signature has been added successfully!' }));
      setImage('');
      setName('');
      setOpenAddSignatureModal(false);

      // update user state with new signature
      requestProfile();

      setOpenSelectSignatureModal(true);
    } catch (error) {
      DisplayErrors(error);
    }
    setLoading(false);
  };

  const handleSignAndSend = async (sign = null) => {
    setLoading(true);
    const formData = new FormData();
    if (sign !== null) {
      formData.append('signature', sign as any);
    }
    try {
      if (sign) {

        await axiosInstance.post(
          document?.is_second_party
            ? `documents/${params.documentId}/attach`
            : `${companyDetails.id}/signers/documents/${document.id}/attach`,
          formData
        );
      } else {
        await axiosInstance.post(
          document?.is_second_party
            ? `documents/${params.documentId}/attach`
            : `${companyDetails.id}/signers/documents/${document.id}/attach`,
          { signature_id: signature }
        );
      }

      toast.success(formatMessage({ id: 'The signature has been added successfully!' }));
      handleSuccess();
      refetchDoc && refetchDoc();
    } catch (error) {
      DisplayErrors(error);
    }
    setLoading(false);
  };

  const handleSuccess = () => {

    setOpenAddSignatureModal(false);
    setOpenSelectSignatureModal(false);
    setSuccess(true);
    setSignature(null);
    setLoading(false);
  };

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  const totalSignatures = useMemo(
    () =>
      user
        ? Object.values(user?.signatures)
            ?.flat()
            ?.filter((sig: any) => !!sig)?.length
        : 0,
    [user]
  );

  const signatures = useMemo(() => (user ? Object.values(user.signatures).flat() : []), [user]);

  useEffect(() => {
    return () => {
      dispatch(DocumentsRedux.actions.fulfillDocumentsComments({ comments: null }));
    };
  }, []);

  const returnToDocumentCreator = async (documentId: number) => {
    try {
      await axiosInstance.post(`/${companyDetails.id}/reviewers/documents/${documentId}`);
      document.status = DOCS_STATUS.ADJUSTMENT_REQUESTED; // Optimistic change
      refetchDoc && (await refetchDoc());
      toast.success($t('Document has been sent to creator successfully'));
    } catch (error) {}
  };

  return (
    <div className='sidebar' style={{ width: '25vw' }}>
      <div className='description' style={{ padding: '1rem' }}>
        <h2 style={{ marginBottom: '1rem' }}>{document.title}</h2>
        <div className='d-flex'>
          {(document.can_sign || !isViewOnly) &&
            ((document.status === DOCS_STATUS.APPROVE && document.can_sign) ||

              (document.status === DOCS_STATUS.WAITING_FOR_COMPANY && document.can_sign) || //MDawood
              (document.status === DOCS_STATUS.WAITING_FOR_SECOND_PARTY &&
                NonRegisteredClientDocumentTypes[params?.type as paramsType] === 'sign') ||
              (document.status === DOCS_STATUS.WAITING_FOR_SECOND_PARTY &&
                document.can_sign &&
                document.is_second_party)) && (
              <div className='d-flex flex-row gap-2 mb-4'>
                <button
                  className='btn btn-outlined'

                  onClick={() => {
                    setOpenSelectSignatureModal(!openAddSignatureModal);
                  }}
                >
                  <i className='fa fa-signature'></i>
                  {$t('Signature')}
                </button>
              </div>
            )}
        </div>
        <div className='d-flex flex-row gap-2 mb-4 align-items-baseline'>
          <h3>{badge.renderItem()}</h3>

          {document.can_review && (
            <div>
              {document.status === DOCS_STATUS.UNDER_REVIEW && (
                <button
                  className='btn btn-outlined'
                  onClick={() => setDocumentToApproveModal(document)}
                >
                  <i className='fa fa-signature'></i>
                  {$t('taking_action')}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {document?.second_party?.label && (
        <div className='mb-3'>
          <span>
            <FormattedMessage id='Second Party' />:
          </span>
          <span>{document?.second_party?.label}</span>
        </div>
      )}

      {documentToApproveModal && userCan('sign-document') && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={$t('Document Process')}
            closeCallBack={() => setDocumentToApproveModal(null)}
          >
            <DocumentApproval
              document={documentToApproveModal}
              returnToDocumentCreatorAction={returnToDocumentCreator}
              setDocumentToApproveModal={setDocumentToApproveModal}
              postApprove={refetchDoc}
            />
          </AhadDialog>
        </Portal>
      )}


      {documentToApproveModal && userCan('review-document') && userCanNot('sign-document') && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={$t('Document Process')}
            closeCallBack={() => setDocumentToApproveModal(null)}
          >
            <DocumentReviewOnly
              postApprove={refetchDoc}
              document={documentToApproveModal}
              returnToDocumentCreator={returnToDocumentCreator}
              setDocumentToApproveModal={setDocumentToApproveModal}
            />
          </AhadDialog>
        </Portal>
      )}

      {openAddSignatureModal && (
        <AddSignatureModal
          isLoading={loading}
          totalSignatures={totalSignatures || 0}
          setOpenAddSignatureModal={setOpenAddSignatureModal}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          name={name}
          setName={setName}
          image={image}
          setImage={setImage}
          uploadSignature={async (img) => {
            if (user?.id) {
              await uploadSignature(img);

              return;
            }
            await handleSignAndSend(img);
          }}
          signatureImageRef={signatureImageRef}
          isOneTimeSignature={!user?.id}
          displayWarning
        />
      )}
      {openSelectSignatureModal && (
        <Portal className='main_modal'>
          <AhadDialog
            titleId={$t('Select Signature')}
            closeCallBack={() => setOpenSelectSignatureModal(false)}
          >
            <div className='w-100' style={{ minWidth: '500px' }}>
              {signatures.length < 3 && !success && (
                <button
                  className='btn btn-outlined mb-4 ms-8'
                  onClick={(e) => {
                    setOpenSelectSignatureModal(false);
                    setOpenAddSignatureModal(true);
                  }}
                >
                  <span className='indicator-label'>
                    <i className='fa fa-plus'></i>
                    <FormatMessage id='Add new and send' />
                  </span>
                </button>
              )}
              {!success && (
                <div className='container'>
                  <div className='row'>
                    {signatures?.length === 0 && (
                      <>
                        {$t('Please add a signature to your profile')}
                        <div className='mt-5'>
                          <button
                            className='btn btn-sm btn-primary btn-shadow btn-primary-shadow me-5'
                            onClick={() =>
                              history.push(user ? '/user-profile/edit' : '/auth/signup')
                            }
                          >
                            {$t(user ? 'Visit Profile' : 'Sign up')}
                          </button>
                          {user && (
                            <button
                              className='btn btn-sm btn-primary btn-shadow btn-primary-shadow me-5'
                              onClick={() => {
                                setOpenSelectSignatureModal(false);
                                setOpenAddSignatureModal(true);
                              }}
                            >
                              {$t('Add New')}
                            </button>
                          )}
                          <button
                            className='btn btn-sm btn-danger btn-shadow btn-danger-shadow'
                            onClick={() => setOpenSelectSignatureModal(false) /* Closes Modal */}
                          >
                            {$t('Cancel')}
                          </button>
                        </div>
                      </>
                    )}

                    {Object.values(signatures)
                      ?.flat()
                      ?.map((signature, index: number) => (
                        <div className='col-lg-4'>
                          <input
                            type='radio'
                            className='btn-check'
                            name='signature'

                            value={`${signature.id}`} // change sig.id to id when it's ready from backend
                            id={`signatureSelection-${signature.id}`}
                            onClick={() => {
                              setSignature(signature.id);
                            }} // change index to id when it's ready from backend
                          />
                          <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                            style={{ width: 'fit-content' }}

                            htmlFor={`signatureSelection-${signature.id}`}
                          >
                            <span className='d-block fw-bold text-start'>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                <img
                                  src={signature.url}
                                  alt={signature.name || ''}
                                  style={{
                                    height: '100px',
                                    width: '100px',
                                  }}
                                />
                              </span>
                              <span className='text-gray-400 fw-bold fs-6'></span>
                            </span>
                          </label>
                        </div>
                      ))}
                  </div>
                  {signature !== null && (
                    <div className='d-flex flex-stack pt-10' dir={locale === 'ar' ? 'rtl' : 'ltr'}>
                      <div className='mr-2'></div>
                      <button
                        className='btn btn-primary btn-sm btn-primary-shadow'
                        onClick={(e) => {
                          e.preventDefault();
                          handleSignAndSend();
                        }}
                      >
                        <span className='indicator-label'>
                          <i className='fa fa-signature'></i>
                          <FormatMessage id='Sign and Send' />
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {success && (
                <>
                  <div
                    className='b-radius-20 d-flex justify-content-center align-items-center position-relative'
                    style={{ width: '100%' }}
                  >
                    <div className='b-radius-30  d-flex flex-column gap-3 justify-content-center align-items-center'>
                      <i
                        className='fas fa-check-circle text-success'
                        style={{ fontSize: '100px' }}
                      ></i>

                      <h5 className='text-metallicBlue'>{$t('Proccess has been completed')}</h5>
                      <button
                        className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenSelectSignatureModal(false);
                        }}
                      >
                        <FormattedMessage id='Close' />
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </AhadDialog>
        </Portal>
      )}
    </div>
  );
}
