import React from 'react';
import { Route, Switch } from 'react-router-dom'; //useParams,
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { CompaniesHeaderWrapper } from './components/header/CompaniesHeaderWrapper';
import { Footer } from './components/Footer';
import { ProfileHeader } from '../../app/modules/profile/ProfileHeader';
// import { Toolbar } from './components/toolbar/Toolbar';

const CompaniesSelectionLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className='d-flex flex-row flex-column-fluid'>
        <div className='builder-wrapper d-flex flex-column flex-row-fluid' id='template_layout'>
          <CompaniesHeaderWrapper />
          <div id='template_layout_content' className='content d-flex flex-row flex-grow-1 mb-3'>
            <div className='flex-fill pt-3'>
              {/* <Toolbar /> */}
              <div className='d-flex flex-column flex-root' id='content'>
                <div className='px-5'>
                  <ProfileHeader />
                </div>
                <Switch>
                  <Route>
                    <Content>{children}</Content>
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </PageDataProvider>
  );
};

export { CompaniesSelectionLayout };
