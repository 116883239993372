import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../_metronic/constants/GeneralValidations';
import { DocuemntTypes, DocumentDirectionTypes } from '../../../_metronic/constants/general';
import {
  companySignatureNeeded,
  isReviewerNeeded,
  secondPartySignaturesNeeded,
} from '../../../_metronic/helpers/functions/signatures';

const useConfigureTemplateSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */
  const initialValues: { [key: string]: any } = useMemo(
    () => ({
      recipient_type: {
        value: DocuemntTypes['Agreement Type A'],
        label: formatMessage({ id: 'Need signtures from 2 side company side first' }),
      },
      title: '',
      type: 1,
      users: [],
      clients: [],
      is_confidential: 0,
    }),
    [formatMessage]
  );

  const basicsInitialValues: { [key: string]: any } = useMemo(
    () => ({
      recipient_type: 1,
      is_confidential: 0,
      creators: [],
      reviewers: [],
      signers: [],
    }),
    [formatMessage]
  );

  const allConfigInitialValues: { [key: string]: any } = useMemo(
    () => ({
      recipient_type: {
        value: DocuemntTypes['Agreement Type A'],
        label: formatMessage({ id: 'Need signtures from 2 side company side first' }),
      },
      title: '',
      type: 1,
      users: [],
      clients: [],
      creators: [],
      reviewers: [],
      signers: [],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        recipient_type: Yup.object().required(required),
        type: Yup.number().required(required),
        title: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('title', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        users: Yup.array().when(['recipient_type', 'type'], {
          is: (recipient_type: any, type: any) => {
            return (
              secondPartySignaturesNeeded(recipient_type.value) &&
              type === DocumentDirectionTypes.Internal
            );
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
        clients: Yup.array().when(['recipient_type', 'type'], {
          is: (recipient_type: any, type: any) => {
            return (
              secondPartySignaturesNeeded(recipient_type.value) &&
              type === DocumentDirectionTypes.External
            );
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
      }),
    [formatMessage]
  );

  const basicsValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        is_confidential: Yup.number().required(required),
        creators: Yup.array().min(1, formatMessage({ id: 'Please choose at least one' })),
        reviewers: Yup.array().when('recipient_type', (recipient_type, schema) => {
          if (isReviewerNeeded(recipient_type))
            return schema
              .required(required)
              .min(1, formatMessage({ id: 'Please choose at least one' }));
        }),
        signers: Yup.array().when(['recipient_type', 'type'], {
          is: (recipient_type: any, type: any) => {
            return companySignatureNeeded(
              recipient_type
            ) /* && type === DocumentDirectionTypes.Internal */;
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
      }),
    [formatMessage]
  );

  const allConfigValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        recipient_type: Yup.object().required(required),
        type: Yup.number().required(required),
        title: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.nameMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.nameMaximum,
            })
          )
          .test('title', $t("You can't enter special character at first or last!"), (val) =>
            nameWithoutSpcialCharsStartOrEnd(val)
          ),
        users: Yup.array().when(['recipient_type', 'type'], {
          is: (recipient_type: any, type: any) => {
            return (
              secondPartySignaturesNeeded(recipient_type.value) &&
              type === DocumentDirectionTypes.Internal
            );
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
        clients: Yup.array().when(['recipient_type', 'type'], {
          is: (recipient_type: any, type: any) => {
            return (
              secondPartySignaturesNeeded(recipient_type.value) &&
              type === DocumentDirectionTypes.External
            );
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
        creators: Yup.array().min(1, formatMessage({ id: 'Please choose at least one' })),
        reviewers: Yup.array().when('recipient_type', {
          is: (recipient_type: any, type: any) => {
            return isReviewerNeeded(recipient_type.value);
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
        signers: Yup.array().when(['recipient_type', 'type'], {
          is: (recipient_type: any, type: any) => {
            return companySignatureNeeded(recipient_type.value);
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
      }),
    [formatMessage]
  );

  return {
    initialValues,
    validationSchema,
    basicsInitialValues,
    basicsValidationSchema,
    allConfigInitialValues,
    allConfigValidationSchema,
  };
};

export { useConfigureTemplateSchema };
