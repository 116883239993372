import React, { useEffect } from 'react';

import { AsideDefault } from './components/aside/AsideDefault';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Toolbar } from './components/toolbar/Toolbar';
import { RightSection } from './components/RightSection/RightSection';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { useHistory, useParams } from 'react-router-dom';
import { RightToolbar } from '../partials/layout/RightToolbar';
import { Footer } from './components/Footer';

const MasterLayout: React.FC = ({ children }) => {
  const params: any = useParams();
  const history = useHistory();

  useEffect(() => {
    // To prevent undefined issues
    if (params?.companySlug && params.companySlug === 'undefined') {
      history.push('/');
    }
  }, [params]);

  return (
    <>
      <PageDataProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault user='USER' />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='flex-fill pt-3'>
                {/* Announcement */}
                {/* <Announcement type='BillingOrderAnnouncement' /> */}
                {/* Announcement */}
                <Toolbar />
                <div className='post d-flex flex-column-fluid' id='content'>
                  <Content>{children}</Content>
                </div>
              </div>
              {/* Right Section */}
              <div className='right-aside mw-auto'>
                <RightSection />
              </div>
              <RightToolbar />
              {/* End Right Section */}
            </div>
            <Footer />
          </div>
        </div>
      </PageDataProvider>
    </>
  );
};

export { MasterLayout };
