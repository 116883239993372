import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'; //Prompt,

import { Form, FormikProvider, useFormik } from 'formik';

import { PageTitle } from '../../../_metronic/layout/core';
import { serialize } from 'object-to-formdata';
// import { Button } from '../../shared/Button';
import { usePermissions } from '../../hooks/usePermissions';
import { RootState } from '../../../setup/redux/Store';
import { TableActions, TableData, TableSelection } from '../../shared/tables/classes'; // DateViewer,
import { $t } from '../../../_metronic/i18n/formatMessage';
import { SerachFilters } from '../../shared/SearchFilters';
import * as TableRedux from '../../store/TableRedux';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { Label } from '../../shared/Label';
import Select from 'react-select';
import { KTSVG } from '../../../_metronic/helpers';

// import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import { EditItem } from '../../shared/tables/actions/EditItem';
// import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { ExcludeItem } from '../../shared/tables/actions/ExcludeItem';
import { IgnoreItem } from '../../shared/tables/actions/IgnoreItem';
// import { LOCALE } from '../../../_metronic/helpers/typescript';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { ErrorMessage } from '../../shared/ErrorMessage';
import { axiosInstance } from '../../../network/apis';
import { useQuery } from 'react-query';
import { createOptions } from '../../../utils/createOptions';
import { toast } from 'react-toastify';
import { useConfigureEditVatRateSchema } from './useConfigureEditVatRateSchema';

import { CompanyModel } from '../companies/CompanyModel';
// import { UserModel } from '../auth/models/UserModel';
import { TransactionDetailsErrorStatusTypeMap } from '../../../_metronic/constants/general';
import { initialConfigPayload } from './helpers/transactionsDetailsConfigHelpers';

import * as TransactionsDetailsRedux from './store/TransactionsDetailsRedux';
import * as ReportsRedux from '../reports/store/ReportsRedux';

import {
  ITransactionsDetails,
  TransactionsDetailsDataListModel,
} from './types/TransactionsDetailsModel';
import { VatRatesTypes } from './types/TransactionsDetailsModel'; //DocumentDirection,

import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { CommonTable2 } from '../../shared/tables/Table2';

const TABLE_NAME = 'transactions_details_list';

const headerItemsSales = [
  // { id: 'ID', maxWidth: 150, width: 150 },
  { id: 'Invoice number', maxWidth: 150, width: 150 },
  { id: 'Client Name', maxWidth: 240, width: 230 },
  // { id: 'Client Code', maxWidth: 150, width: 150 },
  { id: 'Tax rate', maxWidth: 240, width: 230 },
  { id: 'Error message', maxWidth: 380, width: 380 },
  { id: 'Actions', maxWidth: 150, width: 150 },
];
const headerItemsPurchases = [
  // { id: 'ID', maxWidth: 150, width: 150 },
  { id: 'Invoice number', maxWidth: 150, width: 150 },
  { id: 'Vendor name', maxWidth: 240, width: 230 },
  // { id: 'Vendor Code', maxWidth: 150, width: 150 },
  { id: 'Tax rate', maxWidth: 240, width: 230 },
  { id: 'Error message', maxWidth: 380, width: 380 },
  { id: 'Actions', maxWidth: 150, width: 150 },
];

export const TransactionsDetails: FC<any> = ({ reportStatus }) => {
  // const { userCan } = usePermissions();
  // const dispatch = useDispatch();
  // const { locale } = useIntl(); //formatMessage,
  // const history = useHistory();
  // const params: any = useParams();

  // const typesOptions = useMemo(
  //   () =>
  //     Array.from(VatRateTypeMap.values())
  //       .flat()
  //       .map((option) => toSelectOption(option, locale)),
  //   [locale]
  // );

  return (
    <div>
      <TransactionsDetailsTable reportStatus={reportStatus} />
    </div>
  );
};

export const TransactionsDetailsTable: FC<any> = ({ reportStatus }) => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage, locale } = useIntl(); //
  const params: any = useParams();
  // const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  // const [editItemId, setEditItemId] = useState<any>(null);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [selectedTransactionID, setSelectedTransactionID] = useState<any>([]);
  const [actionType, setActionType] = useState<any>('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showChangeVatRateModal, setShowChangeVatRateModal] = useState(false);
  const { editVatRateInitialValues, editVatRateValidationSchema } = useConfigureEditVatRateSchema();
  const [loading, setLoading] = useState(false);
  const [paramsObj, setParamsObj] = useState<any>({});
  const [paramsObjReturn, setParamsObjReturn] = useState<any>({});

  /* -------------------------- Bring Data from store ------------------------- */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const transactionDetailsList: TransactionsDetailsDataListModel = useSelector<RootState>(
    (state) => {
      return state.USER_transaction_details.transaction_details;
    },
    shallowEqual
  ) as TransactionsDetailsDataListModel;

  // get vat rates from store
  const {
    isLoading: vatRateLoading,
    data: vatRateList,
    refetch: refetchVatRate,
  } = useQuery(
    'VatRate',
    () =>
      axiosInstance(`/${companyDetails.id}/vat_rates`, {
        params: paramsObj,
      }).then((res) => {
        return res.data.data;
      }),
    {
      retryDelay: 300,
      retry: 3,
      // refetchOnWindowFocus: false,
      // enabled: false, // disable this query from automatically running
    }
  );

  const vatRateOptions = useMemo(
    () => createOptions(vatRateList),
    [vatRateList, params?.invoicesType]
  );

  // get vat rates from store
  const {
    isLoading: vatRateReturnLoading,
    data: vatRateReturnList,
    refetch: refetchReturnVatRate,
  } = useQuery(
    'VatRateReturn',
    () =>
      axiosInstance(`/${companyDetails.id}/vat_rates`, {
        params: paramsObjReturn,
      }).then((res) => {
        return res.data.data;
      }),
    {
      retryDelay: 300,
      retry: 3,
      // refetchOnWindowFocus: false,
      // enabled: false, // disable this query from automatically running
    }
  );
  const vatRateReturnOptions = useMemo(
    () => createOptions(vatRateReturnList),
    [vatRateReturnList, params?.invoicesType]
  );

  const TransactionDetailsErrorStatusTypeOptions = useMemo(
    () =>
      Array.from(TransactionDetailsErrorStatusTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );
  useEffect(() => {
    refetchVatRate();
    refetchReturnVatRate();
    if (params?.invoicesType === 'sale') {
      setParamsObj({
        type: `${VatRatesTypes['Income']}`,
        per_page: 0,
      });
      setParamsObjReturn({
        type: `${VatRatesTypes['Income return']}`,
        per_page: 0,
      });
    } else {
      setParamsObj({
        type: `${VatRatesTypes['Expenditure']}`,
        per_page: 0,
      });
      setParamsObjReturn({
        type: `${VatRatesTypes['Expenditure return']}`,
        per_page: 0,
      });
    }
    // console.log('paramsObj', paramsObj);
    // console.log('paramsObjReturn', paramsObjReturn);
    // console.log('vatRateOptions', vatRateOptions);
    // console.log('vatRateReturnOptions', vatRateReturnOptions);
  }, [params?.invoicesType, transactionDetailsList, vatRateOptions]);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */

  useEffect(() => {
    return () => {
      dispatch(TransactionsDetailsRedux.actions.fullFillTransactionsDetails({}));
      dispatch(ReportsRedux.actions.fullFillReports({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];

    dispatch(
      TransactionsDetailsRedux.actions.getTransactionsDetails({
        companyId: companyDetails.id,
        reportId: params?.reportsId,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
          // tax_return: params?.reportsId,
          code_type: params?.transactionCodeType,
          transaction_type: params?.invoicesType,
        },
      })
    );
  }

  useEffect(() => {
    if (params?.reportsId && params?.transactionCodeType && params?.invoicesType) {
      RequestData();
      // clear selection
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      setSelectedTransactionID([]);
    }
  }, [
    table?.page,
    table?.pagination,
    params?.reportsId,
    params?.transactionCodeType,
    params?.invoicesType,
  ]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
      // clear selection
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      setSelectedTransactionID([]);
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
      // clear selection
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      setSelectedTransactionID([]);
    }
  };

  const excludeTransaction = async () => {
    setLoading(true);
    const payload: { [key: string]: any } = initialConfigPayload({});
    if (selectedTransactionID.length > 0) {
      payload['excluded_transactions'] = selectedTransactionID;
    } else {
      payload['excluded_transactions'] = table?.selection.includes(-1)
        ? Array.from(new Set(table?.selection.concat(table?.ids))).filter((id) => id !== -1)
        : table?.selection;
    }
    const formData = serialize(payload, { indices: true });
    try {
      formData.append('_method', 'PUT');
      await axiosInstance.post(
        `${companyDetails.id}/tax_return/${params?.reportsId}/transactions/exclude`,
        formData
      );
      toast.success(formatMessage({ id: 'Transaction has been excluded successfully!' }));
      setShowConfirmationModal(false);
      RequestData();
      // clear selection
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      setSelectedTransactionID([]);
      setActionType('');
    } catch (err: any) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const ignoreTransaction = async () => {
    setLoading(true);
    const payload: { [key: string]: any } = initialConfigPayload({});
    if (selectedTransactionID.length > 0) {
      payload['ignored_transactions'] = selectedTransactionID;
    } else {
      payload['ignored_transactions'] = table?.selection.includes(-1)
        ? Array.from(new Set(table?.selection.concat(table?.ids))).filter((id) => id !== -1)
        : table?.selection;
    }
    const formData = serialize(payload, { indices: true });
    try {
      formData.append('_method', 'PUT');
      await axiosInstance.post(
        `${companyDetails.id}/tax_return/${params?.reportsId}/transactions/ignore`,
        formData
      );
      toast.success(formatMessage({ id: 'Transaction has been ignored successfully!' }));
      setShowConfirmationModal(false);
      RequestData();
      // clear selection
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      setSelectedTransactionID([]);
      setActionType('');
    } catch (err: any) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const editVatRate = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    payload['vat_rate_id'] = values.vat_rate[0]?.value;
    delete payload['vat_rate'];

    const formData = serialize(payload, { indices: true });

    try {
      formData.append('_method', 'PUT');
      await axiosInstance.post(
        `${companyDetails.id}/${params?.invoicesType}s/${selectedTransactionID[0]}/vate_rate/change`,
        formData
      );
      resetForm();
      toast.success(
        formatMessage({
          id: `${
            params?.invoicesType.charAt(0).toUpperCase() + params?.invoicesType.slice(1)
          }s has been updated successfully!`,
        })
      );
      setShowChangeVatRateModal(false);
      setShowConfirmationModal(false);
      RequestData();
      setSelectedTransactionID([]);
      setActionType('');
    } catch (err: any) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: editVatRateInitialValues,
    validationSchema: editVatRateValidationSchema,
    onSubmit: (values, { resetForm }) => editVatRate(values, resetForm),
  });
  const { setFieldValue } = formik; //,values, setFieldTouched

  // const shouldAsk = () => Object.keys(formik.touched).length > 0;

  const rowItems = transactionDetailsList?.data.map(
    (transactionDetail: ITransactionsDetails, index) => {
      return [
        new TableSelection({
          display:
            (reportStatus === 'مسودة' || reportStatus === 'Draft') &&
            (userCan('delete-tax_return') || userCan('create-tax_return')),
          id: transactionDetail.id,
          tableName: TABLE_NAME,
        }),

        new TableData({
          text: `${transactionDetail.invoice_number}`,
          errors_with_color: transactionDetail?.errors_with_color?.invoice_number,
        }),
        new TableData({
          text: transactionDetail?.client?.name,
          errors_with_color: transactionDetail?.errors_with_color?.client,
        }),
        // new TableData({
        //   text: `${transactionDetail?.client?.reference_number}`,
        //   errors_with_color: transactionDetail?.errors_with_color?.client,
        // }),
        new TableData({
          text: `${transactionDetail.vat_rate?.name}`,
          errors_with_color: transactionDetail?.errors_with_color?.vat_rate,
        }),

        new TableData({
          text: `${
            transactionDetail.errors.length > 0 && !transactionDetail.are_errors_ignored
              ? transactionDetail.errors.map((error) => error.rule).join(' / ')
              : formatMessage({ id: 'Has no errors' })
          }`,
        }),

        //   new TableData({ text: sale?.vat_rate?. }),
        new TableActions(
          (
            <div className='d-flex flex-row'>
              {/* original invoice actions */}
              {!transactionDetail?.is_return &&
                (userCan('view-sale') || userCan('view-purchase')) && (
                  <ViewItem
                    title={$t('View')}
                    onClick={() =>
                      history.push(
                        `/c/${companyDetails.slug}/tax-module/${transactionDetail.transaction_type}s/${transactionDetail.id}`
                      )
                    }
                  />
                )}

              {/* return invoice actions */}
              {transactionDetail?.is_return &&
                (userCan('view-sale') || userCan('view-purchase')) && (
                  <ViewItem
                    title={$t('View')}
                    onClick={() =>
                      history.push(
                        `/c/${companyDetails.slug}/tax-module/${transactionDetail.transaction_type}sreturn/${transactionDetail.id}`
                      )
                    }
                  />
                )}

              {(userCan('create-tax_return') || userCan('update-tax_return')) &&
                (reportStatus === 'مسودة' || reportStatus === 'Draft') && (
                  <IgnoreItem
                    title={$t('Ignore')}
                    onClick={() => {
                      setSelectedTransactionID([transactionDetail.id]);
                      setActionType('Ignore');
                      setShowConfirmationModal(true);
                    }}
                    disabled={
                      transactionDetail.errors_count === 0 || transactionDetail.are_errors_ignored
                    }
                  />
                )}

              {(userCan('create-tax_return') || userCan('update-tax_return')) &&
                (reportStatus === 'مسودة' || reportStatus === 'Draft') && (
                  <ExcludeItem
                    title={$t('Exclude')}
                    onClick={() => {
                      setSelectedTransactionID([transactionDetail.id]);
                      setActionType('Exclude');
                      setShowConfirmationModal(true);
                    }}
                  />
                )}

              {(userCan('create-tax_return') || userCan('update-tax_return')) &&
                (reportStatus === 'مسودة' || reportStatus === 'Draft') && (
                  <EditItem
                    title={$t('Edit')}
                    disabled={transactionDetail.is_return}
                    onClick={() => {
                      setSelectedTransactionID([transactionDetail.id]);
                      if (transactionDetail.is_return) {
                        setFieldValue(
                          'vat_rate',
                          transactionDetail.vat_rate
                            ? vatRateReturnOptions?.filter(
                                (vat_rate) => vat_rate.value === transactionDetail.vat_rate.id
                              )
                            : []
                        );
                      } else {
                        setFieldValue(
                          'vat_rate',
                          transactionDetail.vat_rate
                            ? vatRateOptions?.filter(
                                (vat_rate) => vat_rate.value === transactionDetail.vat_rate.id
                              )
                            : []
                        );
                      }

                      setActionType('Edit');
                      setShowChangeVatRateModal(true);
                    }}
                  />
                )}
            </div>
          )
        ),
      ];
    }
  );

  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}

          {/*  ------------------------ start Action button ------------------------  */}
          {(userCan('create-tax_return') || userCan('update-tax_return')) && (
            <div className='card-toolbar align-items-end flex-row'>
              {/* begin::Menu */}
              <button
                type='button'
                className={
                  reportStatus === 'مسودة' || reportStatus === 'Draft'
                    ? 'btn btn-sm btn-icon btn-color-primary btn-active-light-primary '
                    : 'd-none'
                }
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
              </button>
              {/* begin::Menu 2 */}
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
                data-kt-menu='true'
              >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                    {$t('Actions')}{' '}
                  </div>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu separator */}
                <div className='separator mb-3 opacity-75'></div>
                {/* end::Menu separator */}

                {/* begin::Menu item */}

                {(reportStatus === 'مسودة' || reportStatus === 'Draft') && (
                  <div className='menu-item px-3'>
                    <button
                      className='menu-link px-3 btn w-md-190px'
                      title={$t('Exclude')}
                      disabled={table?.selection?.length === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActionType('Exclude');
                        setShowConfirmationModal(true);
                      }}
                    >
                      <span className='menu-icon'>
                        <i className='far fa-times'></i> &nbsp;
                      </span>

                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Exclude'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                )}

                {/* end::Menu item */}
                {/* begin::Menu item */}

                {(reportStatus === 'مسودة' || reportStatus === 'Draft') && (
                  <div className='menu-item px-3 py-3'>
                    <button
                      className='menu-link px-3 btn w-md-190px'
                      title={$t('Ignore')}
                      disabled={table?.selection?.length === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActionType('Ignore');
                        setShowConfirmationModal(true);
                      }}
                    >
                      <span className='menu-icon'>
                        <i className='far fa-ban'></i> &nbsp;
                      </span>

                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Ignore'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                )}

                {/* end::Menu item */}
              </div>
              {/* end::Menu 2 */}
              {/* end::Menu */}
            </div>
          )}
          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}
        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'has_errors',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'has_errors',
                      })
                    ),
                  placeholderId: 'Transaction status',
                  options: TransactionDetailsErrorStatusTypeOptions,
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          initPerPage={20}
          hidePagination={false}
          headerWithCheckbox={
            (userCan('update-tax_return') || userCan('create-tax_return')) &&
            (reportStatus === 'مسودة' || reportStatus === 'Draft')
          }
          allDisplayedIds={
            transactionDetailsList?.data?.map((transactionDetail) => transactionDetail.id) || []
          }
          meta={transactionDetailsList}
          headerItems={params?.invoicesType === 'sale' ? headerItemsSales : headerItemsPurchases}
          rowItems={rowItems}
        />
      </div>
      {/*  ---------------------confirmation Modal-----------------------  */}
      {showConfirmationModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => setShowConfirmationModal(false)}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                {actionType === 'Exclude' &&
                  formatMessage({
                    id: 'Are you sure you want to exclude the selected transaction(s) from the report?',
                  })}
                {actionType === 'Ignore' &&
                  formatMessage({
                    id: 'Are you sure you want to ignore the error of the selected transaction(s)?',
                  })}
                {actionType === 'Edit' &&
                  formatMessage({
                    id: 'Are you sure you want to edit the selected transaction(s) from the report?',
                  })}
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (actionType === 'Exclude') {
                          excludeTransaction();
                        } else if (actionType === 'Ignore') {
                          ignoreTransaction();
                        } else {
                          formik.submitForm();
                        }
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          {actionType === 'Exclude' && <FormattedMessage id={'Exclude'} />}
                          {actionType === 'Ignore' && <FormattedMessage id={'Ignore'} />}
                          {actionType === 'Edit' && <FormattedMessage id={'Edit'} />}
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowConfirmationModal(false);
                        setSelectedTransactionID([]);
                        setActionType('');
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end confirmation Modal-----------------------  */}
      {/*  ---------------------confirmation Modal-----------------------  */}
      {showChangeVatRateModal && selectedTransactionID.length > 0 && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => {
              setSelectedTransactionID([]);
              setActionType('');
              setShowChangeVatRateModal(false);
              formik.resetForm();
            }}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row' style={{ fontSize: '1.5rem', width: '100%', minWidth: '300px' }}>
                <div className=' my-5'>
                  <FormikProvider value={formik}>
                    <Form
                      id='edit_tax_rate'
                      noValidate
                      autoComplete='none'
                      style={{ width: '100%' }}
                    >
                      {/* <div className='p-10 ' style={{ width: '100%' }}> */}
                      {/*  --------------------- Tax rate select-----------------------  */}
                      {!transactionDetailsList?.data.filter((transactionDetail) => {
                        return transactionDetail.id === selectedTransactionID[0];
                      })[0].is_return && (
                        <div className='col-12'>
                          <Label msg='Tax rate' htmlFor='vat_rate' isRequired />
                          <Select
                            onChange={(value) => setFieldValue('vat_rate', [value])}
                            onBlur={() => formik.setFieldTouched('vat_rate', true)}
                            options={vatRateOptions?.filter(
                              (option) => formik.values.vat_rate[0]?.value !== option.value
                            )}
                            value={formik.values.vat_rate}
                            isLoading={vatRateLoading}
                            placeholder={$t('Tax rate')}
                            // isMulti
                            // isDisabled={mode === 'view'}
                            className='react-select smaller'
                            id='vat_rate'
                          />
                          <ErrorMessage name='vat_rate' formik={formik} />
                        </div>
                      )}
                      {transactionDetailsList?.data.filter((transactionDetail) => {
                        return transactionDetail.id === selectedTransactionID[0];
                      })[0].is_return && (
                        <div className='col-12'>
                          <Label msg='Tax rate' htmlFor='vat_rate' isRequired />
                          <Select
                            onChange={(value) => setFieldValue('vat_rate', [value])}
                            onBlur={() => formik.setFieldTouched('vat_rate', true)}
                            options={vatRateReturnOptions?.filter(
                              (option) => formik.values.vat_rate[0]?.value !== option.value
                            )}
                            value={formik.values.vat_rate}
                            isLoading={vatRateReturnLoading}
                            placeholder={$t('Tax rate')}
                            // isMulti
                            // isDisabled={mode === 'view'}
                            className='react-select smaller'
                            id='vat_rate'
                          />
                          <ErrorMessage name='vat_rate' formik={formik} />
                        </div>
                      )}
                      {/*  --------------------- end Tax rate select-----------------------  */}
                      {/* </div> */}
                    </Form>
                  </FormikProvider>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowChangeVatRateModal(false);
                        setActionType('Edit');
                        setShowConfirmationModal(true);
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Edit'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowChangeVatRateModal(false);
                        setSelectedTransactionID([]);
                        setActionType('');
                        formik.resetForm();
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end confirmation Modal-----------------------  */}
    </>
  );
};

const TransactionsDetailsWrapper: FC = () => {
  usePermissions(['create-tax_return']); //'view-sale',
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const currentReport: any = useSelector<RootState>((state) => {
    return state.USER_reports.currentReport;
  }, shallowEqual) as any;

  const getCurrentReport = async (reportsId: any) => {
    try {
      dispatch(
        ReportsRedux.actions.getCurrentReport({
          companyId: companyDetails.id,
          reportId: reportsId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };
  useEffect(() => {
    if (params?.reportsId) {
      getCurrentReport(params?.reportsId);
    }
  }, [params?.reportsId]);

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Invoices' })}</PageTitle>
      {/* <PageTitle breadcrumbs={[{ title: 'Invoices', path: '/admin/vat-rates', isActive: true }]}>
        {formatMessage({ id: 'Invoices' })}
      </PageTitle> */}
      {/* add back buttton */}

      <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5 details_nav '>
        <span
          className='mx-2 cursor-pointer text-blueGrey current fs-6 '
          onClick={() => {
            history.goBack();
          }}
        >
          {formatMessage({ id: 'Back to report' })}
        </span>
      </div>
      <TransactionsDetails reportStatus={currentReport?.status} />
    </div>
  );
};

export { TransactionsDetailsWrapper };
