import { $t } from '../../../../../_metronic/i18n/formatMessage';
import { SeparatorLines } from '../../../../shared/Lines';
import { Field, useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { axiosInstance } from '../../../../../network/apis';
import { Label } from '../../../../shared/Label';
import { useEffect, useState } from 'react';

export default function StepCompanyActivitiesCategories() {
  /* ------------------------------- Local State ------------------------------ */
  const [categoriesByActivitiesList, setCategoriesByActivitiesList] = useState<any>(null);

  /* ---------------------------------- Hooks --------------------------------- */
  const formik = useFormikContext<any>();
  const { isLoading: categoriesByActivitiesLoading, data: categoriesByActivitiesListData } =
    useQuery(
      'companyDetailsCategoriesByActivitiesList',
      () =>
        axiosInstance(`/categories/by-activities`, {
          params: {
            company_activities: formik.values?.company_activities?.map((activity: any) =>
              parseInt(activity)
            ),
            per_page: 0,
          },
        }).then((res) => {
          return res.data;
        }),
      {
        retryDelay: 300,
        retry: 3,
        enabled: formik.values?.company_activities?.length > 0,
      }
    );
  const { isLoading: categoriesActivitiesLoading, data: categoriesActivitiesList } = useQuery(
    'companyDetailsActivities',
    () =>
      axiosInstance(`/activities`, {
        params: {
          per_page: 0,
        },
      }).then((res) => {
        return res.data;
      }),
    { retryDelay: 300, retry: 3 }
  );

  useEffect(() => {
    setCategoriesByActivitiesList(categoriesByActivitiesListData);
  }, [categoriesByActivitiesListData]);
  return (
    <div className='table-wrapper card mt-10'>
      <div className='table-wrapper bg-white pb-10'>
        <h3 className='m-2 mx-5'>{$t('Company Activities & Categories')}</h3>
        <SeparatorLines lines={2} />
        <div className='p-10 ' style={{ width: '100%' }}>
          <div className='row mb-10'>
            {/*  ---------------------  select activities-----------------------  */}

            <div className='col-12'>
              <Label msg='Company activities' htmlFor='company_activity' isRequired />
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='my-3'
                id='company_activity'
              >
                {!categoriesActivitiesLoading &&
                  categoriesActivitiesList &&
                  categoriesActivitiesList?.length > 0 &&
                  categoriesActivitiesList?.map((activity: any) => (
                    <>
                      <div className='form-check form-check-custom form-check-solid '>
                        <label className='form-check form-switch form-check-custom form-check-solid'>
                          {/*  tax registered */}
                          <Field
                            key={activity.id}
                            type='checkbox'
                            className='form-check-box'
                            title={activity.name}
                            value={`${activity.id}`}
                            name='company_activities'
                            checked={formik.values.company_activities?.includes(`${activity.id}`)}
                            disabled
                            id={activity.id}
                          />
                          <span className='form-check-label fw-bold text-black-400'>
                            {activity.name}
                          </span>
                        </label>
                      </div>
                    </>
                  ))}
              </div>
            </div>

            {/*  ---------------------  end select activities-----------------------  */}
            {/*  ---------------------  select categories-----------------------  */}

            {formik?.values?.company_activities && categoriesByActivitiesList?.sales && (
              <div className='col-6'>
                <Label msg='Sales categories' htmlFor='sales_category' isRequired />
                <div role='group' aria-labelledby='checkbox-group' className='mt-3'>
                  {!categoriesByActivitiesLoading &&
                    categoriesByActivitiesList?.sales.length > 0 &&
                    categoriesByActivitiesList?.sales?.map((category: any) => (
                      <>
                        <div className='form-check form-check-custom form-check-solid '>
                          <label className='form-check form-switch form-check-custom form-check-solid'>
                            <Field
                              key={category.id}
                              type='checkbox'
                              className='form-check-box'
                              title={category.name}
                              name='sales_categories'
                              checked={
                                formik.values.sales_categories?.includes(`${category.id}`) ||
                                category?.is_mandatory
                              }
                              disabled
                              id={category.id}
                            />
                            <span className='form-check-label fw-bold text-black-400'>
                              {category.name}
                            </span>
                          </label>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            )}
            {formik?.values?.company_activities && categoriesByActivitiesList?.purchases && (
              <div className='col-6'>
                <Label msg='Purchases categories' htmlFor='purchases_category' isRequired />
                <div role='group' aria-labelledby='checkbox-group' className='mt-3'>
                  {!categoriesByActivitiesLoading &&
                    categoriesByActivitiesList?.purchases.length > 0 &&
                    categoriesByActivitiesList?.purchases?.map((category: any) => (
                      <>
                        <div className='form-check form-check-custom form-check-solid '>
                          <label className='form-check form-switch form-check-custom form-check-solid'>
                            {/*  tax registered */}
                            <Field
                              key={category.id}
                              type='checkbox'
                              className='form-check-box'
                              title={category.name}
                              value={`${category.id}`}
                              name='purchases_categories'
                              checked={
                                formik.values.purchases_categories?.includes(`${category.id}`) ||
                                category?.is_mandatory
                              }
                              disabled
                              id={category.id}
                            />
                            <span className='form-check-label fw-bold text-black-400'>
                              {category.name}
                            </span>
                          </label>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            )}

            {/*  --------------------- end select categories-----------------------  */}
          </div>
        </div>
      </div>
    </div>
  );
}
