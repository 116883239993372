import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { WHY_AHAD } from '../containers/RegistrationContainer';

export function RegisterPageFancySide() {
  return (
    <div
      className='flex-fill d-none  d-md-inline brs-radius-25'
      data-testid='registration-design-right-side'
      style={{
        backgroundColor: '#f5f9ff',
        width: '50%',
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/dozzy-1/6-dark.png')})`,
        backgroundSize: '300px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      <div className='d-flex flex-column justify-content-center pt-20'>
        <aside className='pt-20 mb-3 d-flex flex-column align-items-center'>
          <div>
            <h3>
              <FormattedMessage id='Why AHAD?' />
            </h3>
            <ul>
              {WHY_AHAD.map((point) => (
                <li className='menu-item fs-3' key={point}>
                  <i className='fas fa-check-circle text-success mx-2'></i>
                  <FormattedMessage id={point} />
                </li>
              ))}
            </ul>
          </div>
        </aside>
        {/* TODO: FAQ and More Features */}
        {/* NOTE: This part overlaps image */}
        {/* <div className='d-flex flex-lg-row flex-md-column gap-4 w-100 justify-content-center'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-sm btn-darkishBlue mb-5 badge-gray shadowed w-30'
          >
            <FormattedMessage id='More Features' />
          </button>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-sm btn-bordered-darkishBlue w-30 mb-5 badge-gray'
          >
            <FormattedMessage id='FAQ' />
          </button>
        </div> */}
      </div>
    </div>
  );
}
