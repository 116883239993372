import { SelectOptions } from '../app/types/SelectOptions';

export function createSelectOptions(array: any[], label = 'name', value = 'id'): SelectOptions {
  return (
    array?.map((item) => ({
      label: item[label],
      value: item[value],
    })) || []
  );
}
