import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import { Label } from '../../../shared/Label';
import { axiosInstance } from '../../../../network/apis';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import * as Yup from 'yup';
import { Input } from '../../../shared/Input';

interface IOptions {
  value: number;
  label: string;
}
interface IResponseApi {
  id: number;
  name: {
    ar: string;
    en: string;
  };
  description: {
    ar: string;
    en: string;
  };
  assigned_companies_count: number;
  price: string;
}
interface IselectedPlan {
  value: number;
  label: string;
  isDisabled?: boolean;
}
export const RequestChangePlanForm = ({ companyDetails, refetchData }: any) => {
  const { formatMessage, locale } = useIntl();
  const [loading, setLoading] = useState(false);
  const [planOptions, setPlanOptions] = useState<any[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<IselectedPlan>();
  const [confirmSelectPlan, setConfirmSelectPlan] = useState(false);
  const getPlanOptions = useCallback(() => {
    axiosInstance
      .get('admin/plans', {
        params: {
          type: '1,2',
        },
      })
      .then((response) => {
        const res: IResponseApi[] = response.data.data;
        const enhanceResponse: IOptions[] = res.map((plan) => ({
          value: plan.id,
          label: plan?.name?.[locale as 'en' | 'ar'],
        }));

        const selectedOption = enhanceResponse.filter(
          (item) => item.value === companyDetails.plan_id
        )[0];

        const removeOldSelectObject = enhanceResponse.filter(
          (item) => item.value !== selectedOption?.value
        );

        const disabelSelectedOption = { ...selectedOption, isDisabled: true };

        const newArray = [...removeOldSelectObject, disabelSelectedOption];

        setPlanOptions(newArray);
      })
      .catch((error) => {
        console.log('plans', error);
      });
  }, []);

  /* --------------------------------- Formik --------------------------------- */
  const initialValues = useMemo(
    () => ({
      plan_id: { value: null, label: '' },
      start_date: '',
      end_date: '',
      price: 0,
    }),
    [formatMessage]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        plan_id: Yup.object()
          .shape({
            value: Yup.number().required(formatMessage({ id: 'This field is required' })),
            label: Yup.string().required(formatMessage({ id: 'This field is required' })),
          })
          .required(formatMessage({ id: 'This field is required' })),
        start_date: Yup.string().required(formatMessage({ id: 'This field is required' })),
        end_date: Yup.string().required(formatMessage({ id: 'This field is required' })),
        price: Yup.number()
          .integer(formatMessage({ id: 'Only positive integer numbers are allowed!' }))
          .positive(formatMessage({ id: 'Only positive integer numbers are allowed!' }))
          .typeError(formatMessage({ id: 'Only positive integer numbers are allowed!' }))
          .min(0, formatMessage({ id: 'Only positive integer numbers are allowed!' }))
          .nullable(),
      }),
    [formatMessage]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: (values) => {
      if (values.plan_id?.value === companyDetails.plan_id) {
        toast.warning(formatMessage({ id: 'this is your current plan' }), {
          toastId: 'current_plan',
        });
      } else {
        setLoading(true);
        axiosInstance
          .post(`admin/companies/${companyDetails.id}/assign-plan`, {
            plan_id: values.plan_id.value,
            start_date: values.start_date,
            end_date: values.end_date,
            price: values.price ?? 0,
          })
          .then((response) => {
            setLoading(false);
            toast.success(formatMessage({ id: 'changed plan successfully' }));
            // window.location.reload();
            setConfirmSelectPlan(false);
            refetchData();
          })
          .catch((error) => {
            toast.error(formatMessage({ id: error?.response?.data?.message }), {
              toastId: 'changedPlan',
            });
            setLoading(false);
          });
      }
    },
  });
  const { setFieldValue, setFieldTouched, values } = formik;
  /* --------------------------------- Formik --------------------------------- */

  useEffect(() => {
    getPlanOptions();
  }, [getPlanOptions]);

  useEffect(() => {
    if (!planOptions) return;
    const selectedOption = planOptions.filter((item) => item.value === companyDetails.plan_id)[0];
    setFieldValue('plan_id', selectedOption);
    setSelectedPlan(selectedOption);
    setFieldValue('start_date', companyDetails.start_date);
  }, [planOptions]);

  useEffect(() => {
    if (!values?.end_date) return;
    // check if the end date is less than the start date
    if (
      formik.values.end_date &&
      new Date(formik.values.end_date) < new Date(formik.values.start_date)
    )
      formik.setFieldValue(`end_date`, '');
  }, [values?.end_date, values?.start_date]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      id='my-form'
      style={{
        width: '600px',
        paddingRight: locale === 'en' ? '4em' : '',
        paddingLeft: locale === 'ar' ? '4em' : '',
      }}
    >
      <Fragment>
        {!confirmSelectPlan ? (
          <Fragment>
            <div>
              <Label msg='current plan' htmlFor='plan_id' />

              <p className={'form-label fs-6 my-1 fw-bolder text-dark'}>{selectedPlan?.label}</p>
            </div>
            <hr />
            <div>
              <Label msg='plan type' htmlFor='plan_id' isRequired={true} />
              <Select
                className='react-select smaller'
                name='plan_id'
                options={planOptions}
                onChange={(value) => setFieldValue('plan_id', value)}
                value={values.plan_id}
              />
            </div>

            <hr />
            {values.plan_id?.value !== companyDetails.plan_id && (
              <div>
                <div className=''>
                  {/* start date selection */}
                  <Label msg='Start Date' htmlFor='start_date' isRequired={true} />
                  <DatePicker
                    isClearable
                    selected={formik.values.start_date ? new Date(formik.values.start_date) : null}
                    dateFormat='dd/MM/yyyy'
                    placeholderText={formatMessage({
                      id: 'Start Date',
                    })}
                    id='start_date_id'
                    className='form-control fw-bolder pe-5  basic-input'
                    onChange={(date: any) =>
                      formik.setFieldValue(
                        `start_date`,
                        date
                          ? // remove offset
                            new Date(
                              date.getTime() - date.getTimezoneOffset() * 60000
                            ).toISOString()
                          : ''
                      )
                    }
                    onChangeRaw={(e) => {
                      setFieldTouched(`start_date`, true, true);
                    }}
                    // onKeyDown={(e) => {
                    //   e.preventDefault();
                    // }}
                    autoComplete='off'
                    // disabled={mode === 'view'}
                  />
                  <ErrorMessage name='start_date' formik={formik} />
                </div>
                {/* end date selection */}
                {formik.values?.start_date && (
                  <div className=''>
                    <Label msg='End Date' htmlFor='end_date' isRequired={true} />
                    <DatePicker
                      isClearable
                      selected={formik.values.end_date ? new Date(formik.values.end_date) : null}
                      dateFormat='dd/MM/yyyy'
                      placeholderText={formatMessage({
                        id: 'End Date',
                      })}
                      id='end_date_id'
                      className='form-control fw-bolder pe-5  basic-input'
                      onChange={(date: any) =>
                        formik.setFieldValue(
                          `end_date`,
                          date
                            ? // remove offset
                              new Date(
                                date.getTime() - date.getTimezoneOffset() * 60000
                              ).toISOString()
                            : ''
                        )
                      }
                      onChangeRaw={(e) => {
                        setFieldTouched(`end_date`, true, true);
                      }}
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      autoComplete='off'
                      // disabled={mode === 'view'}
                      minDate={new Date(formik.values.start_date)}
                    />
                    <ErrorMessage name='end_date' formik={formik} />
                  </div>
                )}
                <div>
                  <Input
                    placeholder={formatMessage({ id: 'Price' })}
                    autoComplete='off'
                    labelId={formatMessage({ id: 'Price' })}
                    name={`price`}
                    type='text'
                    formik={formik}
                  />
                </div>
              </div>
            )}
            {values.plan_id?.value === companyDetails.plan_id && (
              <div>
                {/*  remianing subscribtion days */}
                <Label msg='Remaining subscribtion days' htmlFor='remaining_days' />

                <p className={'form-label fs-6 my-1 fw-bolder text-dark'}>
                  {companyDetails?.remaining_subscription_days === null
                    ? formatMessage({ id: 'Unlimited' })
                    : companyDetails?.remaining_subscription_days?.toString()}
                </p>
              </div>
            )}
            <div className='col-xl-4 mt-5'>
              <button
                className='btn btn-sm btn-blue w-100 mb-5  shadowed mt-4'
                type='button'
                disabled={
                  values.plan_id?.value === companyDetails.plan_id ||
                  !values?.start_date ||
                  !values?.end_date
                }
                onClick={() => {
                  values.plan_id?.value !== companyDetails.plan_id &&
                    !!values?.start_date &&
                    !!values?.end_date &&
                    setConfirmSelectPlan(true);
                }}
              >
                <span className='indicator-label'>
                  <FormattedMessage id='Edit' />
                </span>
              </button>
            </div>
          </Fragment>
        ) : (
          <div>
            <p>
              <FormattedMessage
                id={`Are you sure you want to assign company: {companyName} to this plan?`}
                values={{ companyName: `"${companyDetails.name}"` }}
              />
              <div className='col-xl-4 mt-5 w-100 d-flex flex-row justify-content-between'>
                <button
                  type='submit'
                  className='btn btn-sm btn-blue w-100 mb-5  shadowed m-4 '
                  id='my-form'
                  disabled={loading}
                >
                  {!loading && (
                    <span className='indicator-label'>
                      <FormattedMessage id='Confirm' />
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <FormattedMessage id='Please wait...' />
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setConfirmSelectPlan(false);
                  }}
                  className='btn btn-danger fw-bold  w-100 mb-5  shadowed mt-4'
                >
                  <FormattedMessage id='Cancel' />
                </button>
              </div>
            </p>
          </div>
        )}
      </Fragment>
    </form>
  );
};
