import { FC } from 'react';

type Props = {
  lines: number;
};

export const SeparatorLines: FC<Props> = ({ lines }) => {
  return (
    <>
      {Array.from(Array(lines).keys()).map((line) => (
        <div key={line} className='table-separator'></div>
      ))}
    </>
  );
};
