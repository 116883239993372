import { toAbsoluteUrl } from '../../../../_metronic/helpers';

export class ItemWithImage {
  constructor(private fieldData: { name: string; image: string | null; subtext?: string }) {}

  renderItem() {
    return (
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-circle avatar-border symbol-30px symbol-md-40px mx-3'>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img src={this.fieldData.image || toAbsoluteUrl('/media/avatars/blank.png')} />
        </div>
        <div className='d-flex justify-content-start flex-column'>
          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
            {this.fieldData.name}
          </a>
          <span className='text-muted fw-bold text-muted d-block fs-7'>
            {this.fieldData.subtext}
          </span>
        </div>
      </div>
    );
  }
}
