import { ICompany } from '../../../../types/companies';
import { axiosInstance } from '../../../../../network/apis';

export function getAllCompaniesSubscriptionPlans({ params }: { params?: any }) {
  return axiosInstance.get<Array<ICompany>>(`/admin/plans`, {
    params,
  });
}
export function getAllCompaniesViewSubscriptionPlans() {
  return axiosInstance.get<Array<ICompany>>(`admin/permissions`);
}
