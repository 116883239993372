import { $t } from '../../../../../_metronic/i18n/formatMessage';
import { SeparatorLines } from '../../../../shared/Lines';
import { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Label } from '../../../../shared/Label';
import Select from 'react-select';
import { ReportsPeriodTypeMap } from '../../../../../_metronic/constants/general';
import { toSelectOption } from '../../../../../_metronic/helpers/functions/Select';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from '../../../../shared/Input';
import DatePicker from 'react-datepicker';
import { FileUploader } from '../../../Companies/CompanyDetails/components/FileUploader';

export default function StepTaxSettings() {
  /* ------------------------------- Local State ------------------------------ */
  const [tax_number_document, setTax_number_document] = useState<any>('');
  const allowedFiles = [
    'application/pdf',
    'image/jpeg',
    'jpeg',
    'png',
    'image/png',
    'image/svg+xml',
  ];

  /* ---------------------------------- Hooks --------------------------------- */
  const formik = useFormikContext<any>();
  const { locale, formatMessage } = useIntl();
  const reportPeriodOptions = useMemo(() => {
    return Array.from(ReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  useEffect(() => {
    if (formik) {
      setTax_number_document(formik.values.tax_number_document);
    }
  }, [formik]);
  return (
    <div className='table-wrapper card mt-10'>
      <div className='table-wrapper bg-white pb-10'>
        <h3 className='m-2 mx-5'>{$t('Tax settings')}</h3>
        <SeparatorLines lines={2} />
        <div className='p-10 ' style={{ width: '100%' }}>
          <div className='row mb-10'>
            {/*  ---------------------  Report type select-----------------------  */}
            <div className='col-6'>
              <Label msg='Report type' htmlFor='report_period' isRequired />
              <Select
                onChange={(value) => formik.setFieldValue('report_period', [value])}
                onBlur={() => formik.setFieldTouched('report_period', true)}
                options={reportPeriodOptions?.filter(
                  (option) => formik.values.report_period[0]?.value !== option.value
                )}
                value={formik.values.report_period}
                placeholder={$t('Report type')}
                isDisabled
                className='react-select smaller'
                id='report_period'
              />
            </div>
            {/*  --------------------- end Report type select-----------------------  */}
            <div className='col-md-6'>
              <Input
                placeholder={formatMessage({ id: 'Company Tax Number' })}
                autoComplete='off'
                labelId={formatMessage({ id: 'Company Tax Number' })}
                formik={formik}
                name={'tax_number'}
                disabled
                setTouchedOnInput
                type={'text'}
              />
            </div>
            {/*  ------------------------ Tax Registration Date -----------------------  */}
            <div className='col-md-6'>
              <div className='row'>
                <label
                  className={'form-label fs-6 my-1 fw-bolder text-dark'} // required
                  htmlFor='tax_registration_date_id'
                >
                  {formatMessage({
                    id: 'Tax registration date',
                  })}
                </label>
                <DatePicker
                  isClearable={false}
                  selected={
                    formik.values.tax_registration_date
                      ? new Date(formik.values.tax_registration_date)
                      : null
                  }
                  dateFormat='dd/MM/yyyy'
                  placeholderText={formatMessage({
                    id: 'Tax registration date',
                  })}
                  id='tax_registration_date_id'
                  className='form-control form-control-md b-radius-16 basic-input '
                  onChange={(date: any) =>
                    formik.setFieldValue(`tax_registration_date`, date ? date : '')
                  }
                  maxDate={new Date()}
                  onChangeRaw={(e) => {
                    formik.setFieldTouched(`tax_registration_date`, true, true);
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  autoComplete='off'
                  disabled
                />
              </div>
              {/*  --------------------- end Tax Registration Date-----------------------  */}
              <div className='row mt-8'>
                <label
                  className='d-flex flex-column mb-0 cursor-pointer'
                  title={formatMessage({
                    id: 'TAX rate auto assign in case of invoice import',
                  })}
                >
                  <span className='d-flex align-items-center m-2'>
                    <span className='form-switch form-switch-custom form-switch-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        name='default_vat_enabled'
                        checked={formik.values?.default_vat_enabled === '1' ? true : false}
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'default_vat_enabled',
                            event.target.checked ? '1' : '0'
                          );
                        }}
                        disabled
                        id='default_vat_enabled'
                      />
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                        <FormattedMessage id='TAX rates auto assign' />
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>

            <div className='col-md-6 mt-5'>
              {tax_number_document && (
                <FileUploader
                  setFile={setTax_number_document}
                  file={tax_number_document}
                  maxSize={2048}
                  supportedFiles={allowedFiles}
                  disabled
                  typesOnly='pdfs-images'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
