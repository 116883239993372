import { useIntl } from 'react-intl';

export function FeedBackButton() {
  const { formatMessage } = useIntl();

  // Requires jQuery!
  jQuery.ajax({
    url: 'https://ahad-platform.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1abpwv/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=432090a1',
    type: 'get',
    cache: true,
    dataType: 'script',
  });

  window.ATL_JQ_PAGE_PROPS = {
    triggerFunction: function (showCollectorDialog: any) {
      //Requires that jQuery is available!
      jQuery('#feedback-button').click(function (e) {
        e.preventDefault();
        showCollectorDialog();
      });
    },
  };

  // Requires jQuery!
  // jQuery.ajax({
  //   url: 'https://ahad-platform.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1abpwv/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=424558ec',
  //   type: 'get',
  //   cache: true,
  //   dataType: 'script',
  // });

  // window.ATL_JQ_PAGE_PROPS = {
  //   triggerFunction: function (showCollectorDialog: any) {
  //     //Requires that jQuery is available!
  //     jQuery('#feedback-button').click(function (e) {
  //       e.preventDefault();
  //       showCollectorDialog();
  //     });
  //   },
  // };

  return (
    <div className='feedbackbtn' id='feedback-button'>
      <div className='gf-btn-container'>
        <div className='gf-btn-button'>
          <span className='gf-btn-text'>{formatMessage({ id: 'Technical support' })}</span>
        </div>
      </div>
    </div>
  );
}
