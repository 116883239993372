import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../../../setup/redux/Store';
import * as TableRedux from '../../store/TableRedux';
import * as LogsRedux from './store/LogsRedux';
import { $t } from '../../../_metronic/i18n/formatMessage';

import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { CompanyModel } from '../companies/CompanyModel';
import { SerachFilters } from '../../shared/SearchFilters';
import { DateViewer, TableData, TableSelection } from '../../shared/tables/classes';
import { usePermissions } from '../../hooks/usePermissions';
import { LogItemModel, UsersDataListModel } from '../../types/Logs';
import { companySlugTo } from '../../../_metronic/helpers/functions';
import { CommonTable2 } from '../../shared/tables/Table2';

const TABLE_NAME = 'logs_list';

const headerItems = [
  { id: 'Name', maxWidth: 240 },
  { id: 'Actoion', width: 240 },
  { id: 'Create at', maxWidth: 230, width: 230 },
];

export const Logs: FC = () => {
  const { isCompanyOwner } = usePermissions();

  return <div>{isCompanyOwner && <LogsTable />}</div>;
};

export const LogsTable: FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const params: any = useParams();
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const logActionsOptions = [
    { value: 'updated', label: formatMessage({ id: 'Update Action' }) },
    { value: 'deleted', label: formatMessage({ id: 'Delete Action' }) },
    { value: 'created', label: formatMessage({ id: 'Create Action' }) },
    { value: 'signed', label: formatMessage({ id: 'Sign Action' }) },
  ];
  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const logs: UsersDataListModel = useSelector<RootState>(
    (state) => state.USER_logs.Logs,
    shallowEqual
  ) as UsersDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* ----------------------- Getting roles for dropdown ----------------------- */

  function RequestData() {
    dispatch(
      LogsRedux.actions.getLogs({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if ((params.companySlug && companyDetails.id) || table?.page || table?.query) {
      RequestData();
    }
  }, [table?.page, table?.pagination, params.companySlug, companyDetails.id]);
  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };
  const rowItems = logs?.data.map((log: LogItemModel) => [
    new TableSelection({ display: false, id: 0, tableName: TABLE_NAME }),
    new TableData({
      text: log?.user?.name,
      isLink: true,
      to: companySlugTo(params.companySlug, `users/${log.user?.id}`),
    }),
    new TableData({ text: log.subject }),
    new DateViewer({ date: log.created_at }),
  ]);

  return (
    <div className='table-wrapper card'>
      {/* begin::Header */}
      <div className='card-header border-0 '>
        {/*  ------------------------ start search and filters ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => {
              setShowSearchFilters(!showSearchFilters);
            }}
          >
            {!showSearchFilters && (
              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
            )}
            {showSearchFilters && (
              <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
            )}
          </button>
        </div>

        {/*  ------------------------- End Search and filters -------------------------  */}
      </div>
      {/* end::Header */}

      {showSearchFilters && (
        <div className='fw-bold m-auto' style={{ width: '95%' }}>
          <div className='px-3'>
            <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
          </div>
          <div className='separator mb-3 opacity-75'></div>

          <SerachFilters
            leftHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'search',
                queryTerm: 'name',
                onChange: (value: string) => {
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'name',
                    })
                  );
                },
                placeholderId: 'Search anything',
              },
            ]}
            rightHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'dropdown',
                queryTerm: 'action',
                onChange: (value: any) =>
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'action',
                    })
                  ),
                placeholderId: 'Search by action',
                options: logActionsOptions,
              },
            ]}
            searchFilterHandler={searchFilterHandler}
            clearSearchFilterHandler={clearSearchFilterHandler}
          />
        </div>
      )}
      <CommonTable2
        className='pb-5 pb-xl-8'
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={false}
        allDisplayedIds={logs?.data?.map((log) => log.id) || []}
        meta={logs}
        headerItems={headerItems}
        rowItems={rowItems}
      />
    </div>
  );
};

const LogsWrapper: FC = () => {
  usePermissions('is_owner');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Audit Log' })}</PageTitle>
      <Logs />
    </div>
  );
};

export { LogsWrapper };
