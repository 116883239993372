import { Meta } from '../../../types/Meta';

export enum VatRatesTypes {
  'Income' = 1,
  'Expenditure' = 2,
  'Income return' = 3,
  'Expenditure return' = 4,
}
// export enum TransactionTypes {
//   'Domestic' = 1,
//   'International' = 2,
//   'GCC' = 3,
// }

export interface ITransactionsDetails {
  id: number;
  uuid: string;
  reference_id?: number;
  invoice_date: string;
  min_accepted_date: string;
  invoice_number: string;
  invoice_reference: string;
  transaction_type: string;
  description: {
    ar: string;
    en: string;
  };
  quantity: string;
  amount: string;
  remaining_amount: string;
  can_update: boolean;
  can_delete: boolean;
  are_errors_ignored: boolean;
  is_return: boolean;
  review_status: number;
  errors_count: number;
  created_at: string;
  attachment: string;
  client: {
    id: number;
    reference_number: string;
    name: string;
  };
  vat_rate: {
    id: number;
    name: string;
  };
  withholding_vat_rate: {
    [key: string]: any;
  };
  errors: [
    {
      [key: string]: string;
    }
  ];
  errors_with_color: {
    [key: string]: {
      color: string;
      text: string;
    };
  };
  linked_invoices?: number;
}

export interface TransactionsDetailsDataListModel extends Meta {
  data: Array<ITransactionsDetails>;
}
