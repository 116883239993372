import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyClients = endpoints.companyClients;
export const companySingleClient = endpoints.companySingleClient;

export function getClientList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyClients(companyId), { params });
}

export function getSingleClientRequest({ companyId, userId }: { companyId: ID; userId: ID }) {
  return axiosInstance.get(companySingleClient(companyId, userId));
}
