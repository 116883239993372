import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { PageTitle } from '../../../../_metronic/layout/core';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { axiosInstance } from '../../../../network/apis';
import { SelectOptions } from '../../../types/SelectOptions';
import { CompanyTypes } from '../../../types/CompanyTypes';
import { useEditViewCompanyDetailsWrapperSchema } from '../../Companies/useEditViewCompanyDetailsWrapperSchema';
import { countires } from '../../../../_metronic/constants/countries';
import StepCompanyProfile from './components/stepCompanyProfile';
import StepBranding from './components/stepBranding';
import StepTaxSettings from './components/stepTaxSettings';
import { ReportsPeriodTypeMap } from '../../../../_metronic/constants/general';
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';
import StepInvoicesConfigurations from './components/stepInvoicesConfigurations';
import StepCompanyActivitiesCategories from './components/stepCompanyActivitiesCategories';

interface IParams {
  companyId: string;
}

const AdminCompanyDetails: FC = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const { formatMessage, locale } = useIntl();
  const { initialValues } = useEditViewCompanyDetailsWrapperSchema();
  const params: IParams = useParams();
  const reportPeriodOptions = useMemo(() => {
    return Array.from(ReportsPeriodTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);
  /* ------------------------------- Local State ------------------------------ */
  const CompanyTypesOptions: SelectOptions = useMemo(
    () => [
      { value: CompanyTypes.Freelancer, label: formatMessage({ id: 'Freelancer' }) },
      { value: CompanyTypes.Corporate, label: formatMessage({ id: 'Corporate' }) },
    ],
    [formatMessage]
  );

  const formik = useFormik({
    initialValues: { ...initialValues },
    onSubmit: async (values: any) => {
      console.log(values);
    },
  });

  const getCompanyData = useCallback(
    (id: string) => {
      axiosInstance
        .get(`admin/companies/${id}`)
        .then((response) => {
          const companyDetails = response?.data;
          formik.setFieldValue(
            'type',
            CompanyTypesOptions.find((type) => type.value === CompanyTypes[companyDetails.type])
          );
          formik.setFieldValue('slug', companyDetails.slug);
          formik.setFieldValue('name', companyDetails.name || '');
          formik.setFieldValue('website', companyDetails.website || '');
          formik.setFieldValue(
            'commercial_registration',
            companyDetails.commercial_registration || ''
          );
          formik.setFieldValue('certificate_number', companyDetails.certificate_number || '');
          formik.setFieldValue('unified_number', companyDetails.unified_number || '');
          formik.setFieldValue('national_id', companyDetails.national_id || '');
          formik.setFieldValue(
            'address_ar',
            companyDetails?.address?.ar ? companyDetails.address.ar : ''
          );
          formik.setFieldValue(
            'address_en',
            companyDetails?.address?.en ? companyDetails.address.en : ''
          );
          formik.setFieldValue('zip', companyDetails?.zip ? companyDetails.zip : initialValues.zip);
          formik.setFieldValue(
            'city',
            companyDetails.city ? companyDetails.city : initialValues.city
          );
          const country = countires?.find(
            (country: any) => companyDetails?.country?.value === country?.iso
          );
          formik.setFieldValue(
            'country',
            companyDetails.country
              ? country
                ? { label: country.emoji + ' ' + country.name[locale], value: country.iso }
                : null
              : initialValues.country
          );
          formik.setFieldValue('branding', companyDetails.branding);
          formik.setFieldValue(
            `tax_registration_date`,
            companyDetails.settings?.tax_registration_date
              ? companyDetails.settings?.tax_registration_date
              : ''
          );
          formik.setFieldValue(
            `tax_number`,
            companyDetails.settings?.tax_number ? companyDetails.settings?.tax_number : ''
          );
          formik.setFieldValue(
            `tax_number_document`,
            companyDetails.tax_number_document ? companyDetails.tax_number_document : ''
          );
          formik.setFieldValue(
            `company_activities`,
            companyDetails.company_activities?.map((activity: any) => {
              return `${activity.id}`;
            })
          );
          formik.setFieldValue(
            `sales_categories`,
            companyDetails.categories?.sales?.map((category: any) => {
              return `${category.id}`;
            })
          );
          formik.setFieldValue(
            `purchases_categories`,
            companyDetails.categories?.purchases?.map((category: any) => {
              return `${category.id}`;
            })
          );
          formik.setFieldValue(
            'return_sales_invoice_number_prefix',
            companyDetails?.settings?.return_sales_invoice_number_prefix
              ? companyDetails?.settings?.return_sales_invoice_number_prefix
              : ''
          );
          formik.setFieldValue(
            'return_sales_invoice_number_start',
            companyDetails?.settings?.return_sales_invoice_number_start
              ? companyDetails?.settings?.return_sales_invoice_number_start
              : ''
          );
          formik.setFieldValue(
            'sales_invoice_number_start',
            companyDetails?.settings?.sales_invoice_number_start
              ? companyDetails?.settings?.sales_invoice_number_start
              : ''
          );
          formik.setFieldValue(
            'sales_invoice_number_prefix',
            companyDetails?.settings?.sales_invoice_number_prefix
              ? companyDetails?.settings?.sales_invoice_number_prefix
              : ''
          );
          formik.setFieldValue(
            `report_period`,
            reportPeriodOptions?.filter((option) => {
              if (companyDetails.settings?.period_type) {
                return option.value === companyDetails.settings?.period_type;
              } else {
                return option.value === 3;
              }
            })
          );
          formik.setFieldValue(
            'default_vat_enabled',
            companyDetails?.settings?.default_vat_enabled ? '1' : '0'
          );
          formik.setFieldValue(
            'invoice_number_method',
            companyDetails?.settings?.invoice_number_method?.toString()
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [formik]
  );

  useEffect(() => {
    getCompanyData(params.companyId);
  }, []);

  return (
    <>
      <FormikProvider value={formik}>
        <form
          className={clsx('p-5')}
          noValidate
          autoComplete='none'
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <StepCompanyProfile />
          <StepBranding />
          <StepTaxSettings />
          <StepCompanyActivitiesCategories />
          <StepInvoicesConfigurations />
        </form>
      </FormikProvider>
    </>
  );
};

const AdminCompanyDetailsWrapper: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Company Details' })}</PageTitle>
      <AdminCompanyDetails />
    </div>
  );
};

export { AdminCompanyDetailsWrapper };
