import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { Label } from '../../../shared/Label';
import { Input } from '../../../shared/Input';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { isValidPhoneNumber } from 'libphonenumber-js/min';
import { axiosInstance } from '../../../../network/apis';
import { useHistory, useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
interface IParams {
  transactionId: string;
}
export function TransactionInfo() {
  /* ------------------------------- Local State ------------------------------ */
  const { formatMessage, locale } = useIntl();
  /* ---------------------------------- Hooks --------------------------------- */
  const params: IParams = useParams();
  const history = useHistory();
  /* --------------------------------- Formik --------------------------------- */
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      image: '',
      available_companies_count: '',
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const { setFieldValue, values } = formik;
  /* --------------------------------- Formik --------------------------------- */
  const getTransactionInfo = useCallback(() => {
    if (!params.transactionId) return;
    axiosInstance
      .get(`admin/transactions/${params.transactionId}`)
      .then((response) => {
        const res = response.data;
        setFieldValue('first_name', res?.first_name);
        setFieldValue('last_name', res?.last_name);
        setFieldValue('email', res?.email);
        setFieldValue('phone', res?.phone);
        setFieldValue('image', res?.image);
        setFieldValue('available_companies_count', res?.available_companies_count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getTransactionInfo();
  }, []);
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Personal Information' })}</PageTitle>

      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
          paddingRight: locale === 'en' ? '4em' : '',
          paddingLeft: locale === 'ar' ? '4em' : '',
        }}
      >
        <div className='row '>
          <div className='col-6'>
            <img
              src={values.image === '' ? toAbsoluteUrl('/media/avatars/blank.png') : values.image}
              className='rounded-circle w-150px me-3'
              alt='thumb'
            />
          </div>
          <div className='col-6'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <button
                className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
                onClick={() => history.push(`/admin/transactions/${params.transactionId}/edit`)}
              >
                {formatMessage({ id: 'Edit' })}
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'First Name' })}
              autoComplete='off'
              labelId='First Name'
              formik={formik}
              name='first_name'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'Last Name' })}
              autoComplete='off'
              labelId='Last Name'
              formik={formik}
              name='last_name'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'Email' })}
              autoComplete='off'
              labelId='Email'
              formik={formik}
              name='email'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'number of available companies' })}
              autoComplete='off'
              labelId='number of available companies'
              formik={formik}
              name='available_companies_count'
              type='text'
              disabled={true}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Label msg='Phone Number' htmlFor='recipient_type' />
            <div style={{ maxWidth: 'fit-content' }} dir='ltr'>
              <PhoneInput
                disabled={true}
                localization={locale === 'ar' ? ar : undefined}
                inputClass='form-control form-control-sm b-radius-16 basic-input'
                country={'sa'}
                placeholder={formatMessage({ id: 'Phone' })}
                value={formik.values.phone}
                onChange={(phone, data: any) => {
                  const valid = isValidPhoneNumber(
                    phone.slice(data.dialCode.length),
                    data.countryCode
                      .split('')
                      .map((l: string) => l.toUpperCase())
                      .join('')
                  );
                  formik.setFieldValue(`phone`, phone);
                  formik.setTouched({ ...formik.touched, phone: true });
                  formik.setFieldValue(`phoneValidity`, valid);
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
