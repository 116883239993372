import { FC } from 'react';
import { useIntl } from 'react-intl';

const Error500: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
        {formatMessage({ id: 'System Error' })}
      </h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        {formatMessage({ id: 'Something went wrong!' })}
        <br /> {formatMessage({ id: 'Please try again later.' })}
      </div>
    </>
  );
};

export { Error500 };
