import { Meta } from '../../../types/Meta';

export enum CustomVariableTypes {
  'Text' = 1,
  'Number' = 2,
  'Date' = 3,
}

export interface ICustomVariable {
  id: number;
  name: {
    ar: string;
    en: string;
  };
  type: string;
  created_at: string;
}

export interface CustomVariablesDataListModel extends Meta {
  data: Array<ICustomVariable>;
}
