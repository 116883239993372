import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { getAllUserCompanies } from './UserCompaniesCRUD';

export enum IUserCompaniesActionTypes {
  GET_USER_COMPANIES = 'GET_USER_COMPANIES',
  FULL_FILL_USER_COMPANIES = 'FULL_FILL_USER_COMPANIES',
}

const initialUserCompanies: IUserCompaniesState = {
  userCompanies: undefined,
};

export interface IUserCompaniesState {
  userCompanies?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_User_Companies', whitelist: [''] },
  (
    state: IUserCompaniesState = initialUserCompanies,
    action: ActionWithPayload<IUserCompaniesState>
  ) => {
    switch (action.type) {
      case IUserCompaniesActionTypes.FULL_FILL_USER_COMPANIES:
        return { ...state, userCompanies: action.payload?.userCompanies };
      default:
        return state;
    }
  }
);

export const actions = {
  getUserCompanies: ({ params }: any) => ({
    type: IUserCompaniesActionTypes.GET_USER_COMPANIES,
    payload: { params },
  }),
  fullFillUserCompanies: ({ userCompanies }: IUserCompaniesState) => ({
    type: IUserCompaniesActionTypes.FULL_FILL_USER_COMPANIES,
    payload: { userCompanies },
  }),
};

export function* getUserCompanies(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getAllUserCompanies({
      params: action.payload?.params,
    });

    yield put(actions.fullFillUserCompanies({ userCompanies: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IUserCompaniesActionTypes.GET_USER_COMPANIES, getUserCompanies);
}
