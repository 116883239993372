import { useIntl } from 'react-intl';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { SeparatorLines } from '../../shared/Lines';
import { axiosInstance } from '../../../network/apis';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { AddSignatureModal } from './AddSignatureModal';

type SignatureTab = {
  id: number;
  tabId: string;
  tabIcon: string;
  component: any;
};

export const TABS: SignatureTab[] = [
  {
    id: 1,
    tabId: 'Image',
    tabIcon: 'fa-image',
    component: <></>,
  },
  {
    id: 2,
    tabId: 'Hand writing',
    tabIcon: 'fa-hand',
    component: <></>,
  },
  {
    id: 3,
    tabId: 'Text',
    tabIcon: 'fa-envelope',
    component: <></>,
  },
];

export let maxSignatures = 3;

type UserSignatureProps = {
  mode: string;
  signatures: number;
  requestProfile: Function;
};

export const UserSignature = ({ mode, signatures, requestProfile }: UserSignatureProps) => {
  const totalSignatures = Object.values(signatures)
    .flat()
    .filter((sig: string) => !!sig)?.length;

  const { formatMessage } = useIntl();
  const signatureImageRef = useRef<HTMLInputElement | null>(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const [image, setImage] = useState<Blob | string>('');
  const [openAddSignatureModal, setOpenAddSignatureModal] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const uploadSignature = async (img: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('image', img);
    formData.append('name', name);
    try {
      await axiosInstance.post('/signatures/image', formData);
      toast.success(formatMessage({ id: 'The signature has been added successfully!' }));
      setImage('');
      setName('');
      requestProfile();
      setOpenAddSignatureModal(false);
    } catch (error) {
      DisplayErrors(error);
    }
    setLoading(false);
  };

  return (
    <div className='content-wrapper bg-white b-radius-25 mb-10'>
      <h3 className='p-10 '>{$t('User Signature')}</h3>
      <SeparatorLines lines={2} />

      <div className='d-flex flex-row'>
        {Object.values(signatures)
          .flat()
          .map((sig: any) => {
            return (
              <div className='userBox w-200px m-5 p-3 d-flex flex-column'>
                <p style={{ minHeight: '30px' }}>{sig?.name}</p>
                <img src={sig?.url} alt='' height={50} />
              </div>
            );
          })}
      </div>

      {mode === 'edit' && (
        <div>
          {totalSignatures >= maxSignatures ? (
            <Alert variant='danger' className='mx-5'>
              <FormattedMessage
                id='You have reached the maximum number of signatures {max}'
                values={{ max: maxSignatures }}
              />
            </Alert>
          ) : (
            <button
              className='btn btn-outlined m-10'
              disabled={totalSignatures === maxSignatures}
              onClick={() => setOpenAddSignatureModal(true)}
            >
              {$t('Add Signature')}
            </button>
          )}
        </div>
      )}

      {openAddSignatureModal && (
        <AddSignatureModal
          isLoading={loading}
          totalSignatures={totalSignatures}
          setOpenAddSignatureModal={setOpenAddSignatureModal}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          name={name}
          setName={setName}
          image={image}
          setImage={setImage}
          uploadSignature={uploadSignature}
          signatureImageRef={signatureImageRef}
          withoutClose={totalSignatures >= maxSignatures}
        />
      )}
    </div>
  );
};
