import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../_metronic/constants/GeneralValidations';

const useConfigureSalesSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const salesInitialValues: { [key: string]: any } = useMemo(
    () => ({
      // invoice_date: '',
      invoice_number: '',
      invoice_reference: '',
      description_en: '',
      description_ar: '',
      // quantity: '',
      amount: '',
      clients: [],
      vat_rate: [],
      categories: [],
      client_id: '',
      vat_rate_id: '',
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const salesValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        invoice_date: Yup.string().required(required),
        invoice_number: Yup.string()
          .required(required)
          .min(
            commonValidations.invoiceMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.invoiceMinimum }
            )
          )
          .max(
            commonValidations.invoiceMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.invoiceMaximum }
            )
          )
          .test(
            'invoice_number',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        invoice_reference: Yup.string()
          .min(
            commonValidations.invoiceMinimum,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: commonValidations.invoiceMinimum }
            )
          )
          .max(
            commonValidations.invoiceMaximum,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.invoiceMaximum }
            )
          )
          .test(
            'invoice_reference',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          )
          .notOneOf(
            [Yup.ref('invoice_number'), null],
            formatMessage({
              id: "You can't add a reference invoice number with the same invoice number",
            })
          ),

        description_en: Yup.string()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_en',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        description_ar: Yup.string()
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_ar',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        clients: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),
        vat_rate: Yup.array()
          .required(required)
          .min(1, formatMessage({ id: 'Please choose at least one' })),

        amount: Yup.number()
          .typeError($t('Only number are allowed!'))
          .required(required)
          .test(
            'len',
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: commonValidations.amountMaxmum }
            ),
            (val: any) => !val || (val && val.toString().length <= commonValidations.amountMaxmum)
          ),
      }),
    [formatMessage]
  );

  return {
    salesInitialValues,
    salesValidationSchema,
  };
};

export { useConfigureSalesSchema };
