import { FormattedMessage } from 'react-intl';
// import { useHistory } from 'react-router-dom';
// import { PublicRoutes } from '../../../../_metronic/constants/ROUTES';

export const LinksUnderRegisterForm = () => {
  // const history = useHistory();

  return (
    <>
      <div className='separator border-gray-200'></div>
      <div className='fs-7 text-center mt-3'>
        {/* <span className='text-dustyBlue mx-1 cursor-pointer'>
        <FormattedMessage id='Privacy policy' />
      </span>
      <span className='text-lightGreyBlue mx-1'>
        <FormattedMessage id='and' />
      </span> */}
        <span
          className='text-dustyBlue mx-1 cursor-pointer'
          onClick={() => window.open('https://ahad.ws/about-us/terms_and_conditions/')}
        >
          <FormattedMessage id='Terms and Conditions' />
        </span>
      </div>
    </>
  );
};
