import { FC, useMemo, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import { $t } from '../../../../../_metronic/i18n/formatMessage';

// import { usePermissions } from '../../../../hooks/usePermissions';

import { InfoItem } from '../../../../shared/tables/actions/InfoItem';
import { EditVATRateItem } from '../../../../shared/tables/actions/EditVATRateItem';
import { EditDateItem } from '../../../../shared/tables/actions/EditDateItem';
import { ErrorMessage } from '../../../../shared/ErrorMessage';
import { Label } from '../../../../shared/Label';
import { axiosInstance } from '../../../../../network/apis';

// import { ViewSalesItem } from '../../../../shared/tables/actions/ViewSalesItem';
// import { ViewPurchasesItem } from '../../../../shared/tables/actions/ViewPurchasesItem';
import {
  TableActions,
  TableData,
  TableSelection,
  DateViewer,
} from '../../../../shared/tables/classes'; // DateViewer,
import { LOCALE } from '../../../../../_metronic/helpers/typescript';

import { CompanyModel } from '../../../companies/CompanyModel';
import { initialConfigPayload } from '../../helpers/reportsConfigHelpers';

import { createDescreptionOptions } from '../../../../../utils/createDescreptionOptions';
import { DisplayErrors } from '../../../../../utils/DisplayErrors';

import { RootState } from '../../../../../setup/redux/Store';
import * as InvoicesWithholdingReportsRedux from '../../store/InvoicesWithholdingReportsRedux';
import { CommonTable2 } from '../../../../shared/tables/Table2';
import { useConfigureEditVatRateSchema } from '../../types/useConfigureEditVatRateSchema';
import { useConfigureEditDateSchema } from '../../types/useConfigureEditDateSchema';
import { VatRatesTypes } from '../../types/InvoicesWithholdingReportsModel';

import AhadDialog from '../../../../../setup/shared/AhadDialog';
import { Portal } from '../../../../../_metronic/partials';
import { PurchasesInvoiceTypes } from '../../../purchases-invoices/types/purchasesInvoiceList';
// import moment from 'moment';

const TABLE_NAME = 'withholding_purchases_transactons_list';

const headerItemsWithoutActions = [
  // { id: 'Select', maxWidth: 150, width: 150 },
  { id: 'Tax rate', maxWidth: 240, width: 230 },
  { id: 'Categories', maxWidth: 230, width: 230 },
  { id: 'Vendor Code', maxWidth: 240, width: 230 },
  { id: 'Vendor number', maxWidth: 150, width: 150 },
  { id: 'Invoice date', maxWidth: 250, width: 250 },
  { id: 'Payment date', maxWidth: 250, width: 250 },
  { id: 'Invoice number', maxWidth: 150, width: 150 },
  { id: 'Amount', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];

export const WithholdingPurchasesTransactionsTable: FC<any> = ({
  selectedtransactionsList,
  excludeIDs,
}) => {
  // const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();

  const [selectedTransactionID, setSelectedTransactionID] = useState<any>([]);
  const [actionType, setActionType] = useState<any>('');
  const [minDate, setMinDate] = useState<any>('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showChangeVatRateModal, setShowChangeVatRateModal] = useState(false);
  const [showChangeDateModal, setShowChangeDateModal] = useState(false);
  const { editVatRateInitialValues, editVatRateValidationSchema } = useConfigureEditVatRateSchema();
  const { editDateInitialValues, editDateValidationSchema } = useConfigureEditDateSchema();
  const [loading, setLoading] = useState(false);
  const [paramsObj, setParamsObj] = useState<any>({});
  // console.log('selectedtransactionsList', selectedtransactionsList);
  // console.log('excludeIDs', excludeIDs);

  /* -------------------------- Bring Data from store ------------------------- */
  const withholdingPurchasesTransactionsList: any = useSelector<RootState>((state) => {
    return state.USER_invoices_withholding_reports.invoicesWithholdingReportPurchasesTransactions;
  }, shallowEqual) as any;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;
  const table = tables[TABLE_NAME];

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  // get vat rates from store
  const {
    isLoading: vatRateLoading,
    data: vatRateList,
    refetch: refetchVatRate,
  } = useQuery(
    'VatRate',
    () =>
      axiosInstance(`/${companyDetails.id}/vat_rates`, {
        params: paramsObj,
      }).then((res) => {
        return res.data.data;
      }),
    {
      retryDelay: 300,
      retry: 3,
      // refetchOnWindowFocus: false,
      // enabled: false, // disable this query from automatically running
    }
  );

  const vatRateOptions = useMemo(
    () => createDescreptionOptions(vatRateList),
    [vatRateList, params?.invoicesType]
  );

  useEffect(() => {
    refetchVatRate();

    setParamsObj({
      type: `${VatRatesTypes['Expenditure']}`,
      per_page: 0,
      is_withholding: 1,
    });
  }, [
    params?.invoicesType,
    selectedtransactionsList,
    vatRateOptions,
    withholdingPurchasesTransactionsList,
  ]);
  /* ------------------------- End Bring Data from store ---------------------- */
  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(
        InvoicesWithholdingReportsRedux.actions.fullFillInvoicesWithholdingPurchasesTransactions({})
      );
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      InvoicesWithholdingReportsRedux.actions.getInvoicesWithholdingPurchasesTransactions({
        companyId: companyDetails.id,
        params: {
          // ...paramsObj,
          type: PurchasesInvoiceTypes.Purchase,
          is_withholding: 1,
          exclude: excludeIDs,
          invoice_dates: selectedtransactionsList,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    // throttle request
    if (selectedtransactionsList.length > 0) {
      // console.log('selectedtransactionsList', selectedtransactionsList);
      // console.log('excludeIDs', excludeIDs);
      RequestData();
    }
  }, [table?.page, table?.pagination, excludeIDs, selectedtransactionsList]);

  const editVatRate = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    payload['withholding_vat_rate_id'] = values.vat_rate[0]?.value;
    delete payload['vat_rate'];

    const formData = serialize(payload, { indices: true });

    try {
      formData.append('_method', 'PUT');
      await axiosInstance.post(
        `${companyDetails.id}/reports/withholding/${selectedTransactionID[0]}/vat_rate/change`,
        formData
      );
      resetForm();

      toast.success(
        formatMessage({
          id: `Purchases has been updated successfully!`,
        })
      );

      setShowConfirmationModal(false);
      setShowChangeVatRateModal(false);
      RequestData();
      setSelectedTransactionID([]);
      setActionType('');
    } catch (err: any) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const editPaymentDate = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });

    const formData = serialize(payload, { indices: true });

    try {
      formData.append('_method', 'PUT');
      await axiosInstance.post(
        `${companyDetails.id}/reports/withholding/${selectedTransactionID[0]}/payment_date/change`,
        formData
      );
      resetForm();

      toast.success(
        formatMessage({
          id: `Purchases has been updated successfully!`,
        })
      );

      setShowConfirmationModal(false);
      setShowChangeDateModal(false);
      RequestData();
      setSelectedTransactionID([]);
      setActionType('');
    } catch (err: any) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: editVatRateInitialValues,
    validationSchema: editVatRateValidationSchema,
    onSubmit: (values, { resetForm }) => editVatRate(values, resetForm),
  });
  const { setFieldValue } = formik; //,values, setFieldTouched

  const formikForDate = useFormik({
    initialValues: editDateInitialValues,
    validationSchema: editDateValidationSchema,
    onSubmit: (values, { resetForm }) => editPaymentDate(values, resetForm),
  });

  const rowItems = withholdingPurchasesTransactionsList?.data?.map(
    (purchaseTransaction: any, index: any) => {
      return [
        new TableSelection({
          display: false,
          id: purchaseTransaction.id,
          tableName: TABLE_NAME,
          disabled: purchaseTransaction.selected, //auto select
        }),

        new TableData({
          text: `${
            purchaseTransaction.withholding_vat_rate?.code
              ? purchaseTransaction.withholding_vat_rate?.code
              : ''
          }`,
          errors_with_color: purchaseTransaction?.errors_with_color?.withholding_vat_rate,
        }),
        new TableData({
          text: `${
            purchaseTransaction.withholding_vat_rate?.description[locale as LOCALE]
              ? purchaseTransaction.withholding_vat_rate?.description[locale as LOCALE]
              : ''
          }`,
        }),
        new TableData({
          text: purchaseTransaction?.client?.name,
          errors_with_color: purchaseTransaction?.errors_with_color?.client,
        }),
        new TableData({
          text: `${purchaseTransaction?.client?.reference_number}`,
          errors_with_color: purchaseTransaction?.errors_with_color?.client,
        }),
        new DateViewer({
          date: purchaseTransaction.invoice_date,
          errors_with_color: purchaseTransaction?.errors_with_color?.invoice_date,
        }),
        new DateViewer({
          date: purchaseTransaction.payment_date,
          errors_with_color: purchaseTransaction?.errors_with_color?.payment_date,
        }),
        new TableData({
          text: `${purchaseTransaction.invoice_number}`,
          errors_with_color: purchaseTransaction?.errors_with_color?.invoice_number,
        }),
        new TableData({
          text: `${purchaseTransaction.amount}`,
          errors_with_color: purchaseTransaction?.errors_with_color?.amount,
        }),

        new TableActions(
          (
            <div className='d-flex flex-row'>
              {/* {userCan('view-tax_return') && transaction.selected && (
              <ViewSalesItem
                title={$t('Preview sales')}
                onClick={() => {
                  //  pass transaction object to parent component and call open modal function.
                  onTransactionSelection({ ...transaction, type: 'sales' });
                }}
              />
            )} */}
              {/* {userCan('view-tax_return') && (
              <ViewPurchasesItem
                title={$t('Preview purchases')}
                onClick={() => {
                  //  pass transaction object to parent component and call open modal function.
                  onTransactionSelection({ ...transaction, type: 'purchases' });
                }}
              />
            )} */}
              <EditVATRateItem
                title={$t('Edit categories')}
                // disabled={purchaseTransaction.is_return}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedTransactionID([purchaseTransaction.id]);
                  setFieldValue(
                    'vat_rate',
                    purchaseTransaction.withholding_vat_rate?.code
                      ? vatRateOptions?.filter(
                          (vat_rate) =>
                            vat_rate.label ===
                            purchaseTransaction.withholding_vat_rate.description[locale]
                        )
                      : []
                  );

                  setActionType('Edit categories');
                  setShowChangeVatRateModal(true);
                }}
              />
              <EditDateItem
                title={$t('Edit date')}
                // disabled={purchaseTransaction.is_return}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedTransactionID([purchaseTransaction.id]);
                  formikForDate.setFieldValue(
                    'payment_date',
                    purchaseTransaction.payment_date
                      ? purchaseTransaction.payment_date /*  moment(purchaseTransaction.invoice_date).format('YYYY-MM-DD') */
                      : ''
                  );
                  setMinDate(purchaseTransaction.invoice_date);
                  setActionType('Edit date');
                  setShowChangeDateModal(true);
                }}
              />
              {!purchaseTransaction.selected &&
                purchaseTransaction.used_in_previous_report &&
                purchaseTransaction.purchases > 0 && (
                  <InfoItem title={$t('There is unprocessed purchases for this period.')} />
                )}
              {!purchaseTransaction.selected && !purchaseTransaction.used_in_previous_report && (
                <InfoItem title={$t('This period didn’t entered any approved report before.')} />
              )}
            </div>
          )
        ),
      ];
    }
  );

  return (
    <>
      <div className='table-wrapper card'>
        {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline m-5'> */}
        {/* {userCan('delete-template') && (
          <DeleteItem
            isButton
            messageId={'Are you sure you want to delete this template?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/templates`}
            payload={deleteItemId}
            successMessage={'Template has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )} */}
        {/* </div> */}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          initPerPage={20}
          headerWithCheckbox={false}
          allDisplayedIds={
            withholdingPurchasesTransactionsList?.data?.map(
              (purchaseTransaction: any, index: any) => purchaseTransaction.id
            ) || []
          } //withholdingPurchasesTransactionsList?.data?.map((sales) => sales.id) ||
          meta={withholdingPurchasesTransactionsList}
          headerItems={headerItemsWithoutActions}
          rowItems={rowItems}
        />
      </div>
      {/*  ---------------------confirmation Modal-----------------------  */}
      {showConfirmationModal && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => setShowConfirmationModal(false)}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                {actionType === 'Edit date' &&
                  formatMessage({
                    id: 'Are you sure you want to edit the selected transaction(s) from the report?',
                  })}
                {actionType === 'Edit vat rate' &&
                  formatMessage({
                    id: 'Are you sure you want to edit the selected transaction(s) from the report?',
                  })}
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      type='submit'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (actionType === 'Edit date') {
                          formikForDate.submitForm();
                        } else if (actionType === 'Edit vat rate') {
                          formik.submitForm();
                        }
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          {actionType === 'Edit vat rate' && <FormattedMessage id={'Edit'} />}
                          {actionType === 'Edit date' && <FormattedMessage id={'Edit'} />}
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowConfirmationModal(false);
                        setSelectedTransactionID([]);
                        setActionType('');
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end confirmation Modal-----------------------  */}
      {/*  ---------------------Change vat rate Modal-----------------------  */}
      {showChangeVatRateModal && selectedTransactionID.length > 0 && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => {
              setSelectedTransactionID([]);
              setActionType('');
              setShowChangeVatRateModal(false);
              formik.resetForm();
            }}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row' style={{ fontSize: '1.5rem', width: '100%', minWidth: '300px' }}>
                <div className=' my-5'>
                  <FormikProvider value={formik}>
                    <Form
                      id='edit_tax_rate'
                      noValidate
                      autoComplete='none'
                      style={{ width: '100%' }}
                    >
                      {/* <div className='p-10 ' style={{ width: '100%' }}> */}
                      {/*  --------------------- Tax rate select-----------------------  */}
                      {
                        <div className='col-12'>
                          <Label msg='Categories' htmlFor='vat_rate' isRequired />
                          <Select
                            onChange={(value) => setFieldValue('vat_rate', [value])}
                            onBlur={() => formik.setFieldTouched('vat_rate', true)}
                            options={vatRateOptions?.filter(
                              (option) => formik.values.vat_rate[0]?.value !== option.value
                            )}
                            value={formik.values.vat_rate}
                            isLoading={vatRateLoading}
                            placeholder={$t('Categories')}
                            // isMulti
                            // isDisabled={mode === 'view'}
                            className='react-select smaller'
                            id='vat_rate'
                          />
                          <ErrorMessage name='vat_rate' formik={formik} />
                        </div>
                      }

                      {/* </div> */}
                    </Form>
                  </FormikProvider>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (formikForDate.errors.vat_rate) {
                          formikForDate.setFieldTouched(`vat_rate`, true, true);
                        } else {
                          setShowChangeVatRateModal(false);
                          setActionType('Edit vat rate');
                          setShowConfirmationModal(true);
                        }
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Edit'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowChangeVatRateModal(false);
                        setSelectedTransactionID([]);
                        setActionType('');
                        formik.resetForm();
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end Change vat rate Modal-----------------------  */}
      {/*  ---------------------Change date Modal-----------------------  */}
      {showChangeDateModal && selectedTransactionID.length > 0 && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={() => {
              setSelectedTransactionID([]);
              setActionType('');
              setShowChangeDateModal(false);
              formikForDate.resetForm();
            }}
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row' style={{ fontSize: '1.5rem', width: '100%', minWidth: '300px' }}>
                <div className=' my-5'>
                  <FormikProvider value={formikForDate}>
                    <Form
                      id='edit_payment_date'
                      noValidate
                      autoComplete='none'
                      style={{ width: '100%' }}
                    >
                      {/* <div className='p-10 ' style={{ width: '100%' }}> */}
                      {/*  --------------------- Date Picker-----------------------  */}
                      {
                        <div className='col-12'>
                          <label
                            className={'form-label fs-6 my-1 fw-bolder text-dark required'}
                            htmlFor='payment_date_id'
                          >
                            {formatMessage({
                              id: 'Payment date',
                            })}
                          </label>
                          <DatePicker
                            isClearable
                            selected={
                              formikForDate.values.payment_date
                                ? new Date(formikForDate.values.payment_date)
                                : null
                            }
                            onBlur={() => formikForDate.setFieldTouched(`payment_date`, true, true)}
                            dateFormat='dd/MM/yyyy'
                            placeholderText={formatMessage({
                              id: 'Payment date',
                            })}
                            id='payment_date_id'
                            className='form-control form-control-md b-radius-16 basic-input '
                            onChange={(date: any) =>
                              formikForDate.setFieldValue(
                                `payment_date`,
                                date
                                  ? // remove offset
                                    new Date(
                                      date.getTime() - date.getTimezoneOffset() * 60000
                                    ).toISOString()
                                  : ''
                              )
                            }
                            maxDate={new Date()}
                            minDate={new Date(minDate)}
                            onChangeRaw={(e) => {
                              formikForDate.setFieldTouched(`payment_date`, true, true);
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            autoComplete='off'
                            // disabled={mode === 'view'}
                          />
                          <ErrorMessage name='payment_date' formik={formikForDate} />
                        </div>
                      }

                      {/* </div> */}
                    </Form>
                  </FormikProvider>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (formikForDate.errors.payment_date) {
                          formikForDate.setFieldTouched(`payment_date`, true, true);
                        } else {
                          setShowChangeDateModal(false);
                          setActionType('Edit date');
                          setShowConfirmationModal(true);
                        }
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Edit'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowChangeDateModal(false);
                        setSelectedTransactionID([]);
                        setActionType('');
                        formikForDate.resetForm();
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end Change date Modal-----------------------  */}
    </>
  );
};

const Step3: FC<any> = ({ selectedtransactionsList, excludeIDs }) => {
  return (
    <div>
      <WithholdingPurchasesTransactionsTable
        selectedtransactionsList={selectedtransactionsList}
        excludeIDs={excludeIDs}
      />
    </div>
  );
};

export { Step3 };
