import { FC, useEffect, useRef, useState } from 'react';
import { Formik, Form, FormikValues } from 'formik';
import { shallowEqual, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { Step1ApproveOrReturn } from './steps/Step1ApproveOrReturn';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { IDocument } from '../types/DocumentsModel';
import { axiosInstance } from '../../../../network/apis';
import { CompanyModel } from '../../companies/CompanyModel';
import { RootState } from '../../../../setup';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import {
  IApproveSignOrReturnDocument,
  ApproveSignOrReturnDocumentStepperSchemas,
  inits,
} from './ApproveSignOrReturnDocumentStepper';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { useQuerySearch } from '../../../hooks/useQuerySearch/useQuerySearch';

type Props = {
  returnToDocumentCreator: Function;
  setDocumentToApproveModal: Function;
  document: IDocument;
  postApprove?: Function;
};

const DocumentReviewOnly: FC<Props> = ({
  returnToDocumentCreator,
  document,
  setDocumentToApproveModal,
  postApprove,
}) => {
  const { locale } = useIntl();
  const query: any = useQuerySearch();
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;
  let slug = query.get('slug');

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(ApproveSignOrReturnDocumentStepperSchemas[0]);
  const [initValues] = useState<IApproveSignOrReturnDocument>(inits);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
    if (document.status === 5) {
      stepper?.current?.goNext();
      setCurrentSchema(ApproveSignOrReturnDocumentStepperSchemas[1]);
    }
  };

  const handleApproveOrReturn = (approvement: 'returnToDocumentCreator' | 'approve') => {
    const approveOrReturn = {
      returnToDocumentCreator: () => {
        returnToDocumentCreator(document.id);
        setDocumentToApproveModal(null);
        postApprove && postApprove();
      },
      approve: async () => {
        try {
          await axiosInstance.post(
            slug
              ? `/${slug}/reviewers/documents/${document.id}/approve`
              : `/${companyDetails.id}/reviewers/documents/${document.id}/approve`
            // `/${companyDetails.id}/reviewers/documents/${document.id}/approve`
          );
          postApprove && postApprove();
        } catch (error) {
          DisplayErrors(error);
        }
      },
    };
    return approveOrReturn[approvement]();
  };

  const submitStep = async (values: IApproveSignOrReturnDocument, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(ApproveSignOrReturnDocumentStepperSchemas[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      if (stepper.current.currentStepIndex === 1) {
        const approve = await handleApproveOrReturn(values.approvement);
        if (approve === null) return;
      }

      stepper.current.goNext();
    } else {
      stepper.current.goto(1);
      actions.resetForm();
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto  w-100 w-xl-250px w-xxl-300px me-9'>
        <div className='px-6 px-lg-10 px-xxl-15 py-20'>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>1</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>
                  <FormattedMessage id='Approve/Return' />
                </h3>
              </div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>2</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>
                  <FormattedMessage id='Finalize' />
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {(formik) => {
            return (
              <Form className='py-20 w-100 w-xl-600px px-9' noValidate id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  {/* Step1 */}
                  <Step1ApproveOrReturn />
                </div>

                <div data-kt-stepper-element='content'>
                  <>
                    <div
                      className='b-radius-20 d-flex justify-content-center align-items-center position-relative'
                      style={{ width: '100%' }}
                    >
                      <div className='b-radius-30  d-flex flex-column gap-3 justify-content-center align-items-center'>
                        <i
                          className='fas fa-check-circle text-success'
                          style={{ fontSize: '100px' }}
                        ></i>
                        <h5 className='text-metallicBlue'>{$t('Proccess has been completed')}</h5>
                        <button
                          className='btn btn-sm btn-primary btn-shadow btn-primary-shadow'
                          onClick={(e) => {
                            e.preventDefault();
                            setDocumentToApproveModal(null);
                          }}
                        >
                          <FormattedMessage id='Close' />
                        </button>
                      </div>
                    </div>
                  </>
                </div>

                <div className='d-flex flex-stack pt-10' dir={locale === 'ar' ? 'rtl' : 'ltr'}>
                  <div className='mr-2'></div>

                  <div>
                    {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber && (
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary me-3 btn btn-primary btn-shadow btn-lg btn-primary-shadow'
                      >
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && (
                            <FormattedMessage id='Confirm' />
                          )}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 1 && (
                            <FormattedMessage id='Continue' />
                          )}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export { DocumentReviewOnly };
