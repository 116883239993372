/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { register } from '../store/AuthCRUD';
import { Input } from '../../../shared/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { emailPattern } from '../../../../_metronic/constants/patterns';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';
import clsx from 'clsx';
import useWindowSize from '../../../hooks/useWindowSize';
import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
import { DisplayErrors } from '../../../../utils/DisplayErrors';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirmPassword: '',
};

export function Registration() {
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required(formatMessage({ id: 'This field is required' }))
      .min(
        commonValidations.nameMinimum,
        formatMessage(
          { id: 'Please enter at least {minCharacters} characters' },
          { minCharacters: commonValidations.nameMinimum }
        )
      )
      .max(
        commonValidations.nameMaximum,
        formatMessage(
          { id: 'The Maximum characters is {maxCharacters}' },
          { maxCharacters: commonValidations.nameMaximum }
        )
      )
      .test(
        'firstname',
        formatMessage({ id: "You can't enter special character at first or last!" }),
        (val) => nameWithoutSpcialCharsStartOrEnd(val?.trim())
      ),
    lastname: Yup.string()
      .required(formatMessage({ id: 'This field is required' }))
      .min(
        commonValidations.nameMinimum,
        formatMessage(
          { id: 'Please enter at least {minCharacters} characters' },
          { minCharacters: commonValidations.nameMinimum }
        )
      )
      .max(
        commonValidations.nameMaximum,
        formatMessage(
          { id: 'The Maximum characters is {maxCharacters}' },
          { maxCharacters: commonValidations.nameMaximum }
        )
      )
      .test(
        'lastname',
        formatMessage({ id: "You can't enter special character at first or last!" }),
        (val) => nameWithoutSpcialCharsStartOrEnd(val?.trim())
      ),
    email: Yup.string()
      .matches(emailPattern, formatMessage({ id: 'Invalid email' }))
      .email(formatMessage({ id: 'Invalid email' }))
      .required(formatMessage({ id: 'This field is required' })),
    password: Yup.string()
      .min(
        commonValidations.passwordMinimum,
        formatMessage(
          { id: 'Please enter at least {minCharacters} characters' },
          { minCharacters: commonValidations.passwordMinimum }
        )
      )
      .max(
        commonValidations.passwordMaximum,
        formatMessage(
          { id: 'The Maximum characters is {maxCharacters}' },
          { maxCharacters: commonValidations.passwordMaximum }
        )
      )
      .required(formatMessage({ id: 'This field is required' })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], formatMessage({ id: 'Passwords should be matched' }))
      .required(formatMessage({ id: 'This field is required' })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        await register({ ...values });
        toast.success(formatMessage({ id: 'A verification link has been sent to your Email' }));
        formik.resetForm();
      } catch (err: any) {
        DisplayErrors(err);
      }
      setLoading(false);
    },
  });

  return (
    <form
      className={clsx('form w-100 p-lg-5', {
        'p-lg-10 pb-lg-10 p-md-10': width > 1440,
        ' pb-lg-5 p-md-5': width < 1440,
      })}
      style={{ backgroundImage: 'linear-gradient(to bottom, #fafbfd, #fff)', borderRadius: '30px' }}
      noValidate
      autoComplete='none'
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Form group Firstname */}
      <div className='row fv-row'>
        <div className='col-xl-6'>
          <Input
            placeholder={formatMessage({ id: 'First Name' })}
            autoComplete='off'
            labelId={formatMessage({ id: 'First Name' })}
            name='firstname'
            type='firstname'
            formik={formik}
          />
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <Input
            placeholder={formatMessage({ id: 'Last Name' })}
            autoComplete='off'
            labelId={formatMessage({ id: 'Last Name' })}
            name='lastname'
            type='lastname'
            formik={formik}
          />
        </div>
      </div>
      {/* begin::Form group Email */}
      <Input
        placeholder={formatMessage({ id: 'Email' })}
        autoComplete='off'
        labelId={formatMessage({ id: 'Email' })}
        name='email'
        type='email'
        formik={formik}
      />
      {/* begin::Form group Password */}
      <Input
        placeholder={formatMessage({ id: 'Password' })}
        autoComplete='off'
        labelId={formatMessage({ id: 'Password' })}
        formik={formik}
        name='password'
        type='password'
      />
      {/* end::Form group */}
      {/* begin::Form group confirm password */}
      <Input
        placeholder={formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
        autoComplete='off'
        labelId={formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
        formik={formik}
        name='confirmPassword'
        type='password'
      />
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-sm w-100 mb-5  btn-primary btn-shadow btn-primary-shadow mt-4'
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            formik.values.confirmPassword !== formik.values.password
          }
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='save' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Please wait...' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          onClick={() => formik.resetForm()}
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
        >
          <FormattedMessage id='Cancel' />
        </button>
      </div>
      {/* end::Form group */}
    </form>
  );
}
