import { FC } from 'react';
// import { useIntl } from 'react-intl';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../setup/redux/Store';
import { shallowEqual, useSelector } from 'react-redux';

// import { CurrentCompanyUser } from '../types/CompanyTypes';
import { ICompany } from '../../app/types/companies';

export const CompanyLogo: FC = () => {
  // const { formatMessage } = useIntl(); //locale,
  // const currentCompanyUser: CurrentCompanyUser = useSelector<RootState>(
  //   (state) => state.current.current
  // ) as CurrentCompanyUser;
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  return (
    <div>
      <div className='logo-image'>
        <Link to={`/c/${companyDetails?.slug}/dashboard`}>
          {companyDetails?.branding?.logo?.light && (
            <>
              <img
                alt='Company Logo'
                className='h-25px w-25px logo rounded-circle'
                src={companyDetails?.branding?.logo?.light || ''}
              />
              &nbsp;
            </>
          )}
          {companyDetails?.name && <span id='company_name'> {companyDetails?.name}</span>}
        </Link>
      </div>
    </div>
  );
};
