// import { DuoLingo } from '../../../types/DuoLingo';
import { Meta } from '../../../types/Meta';

export enum PurchasesVatRatesTypes {
  'Expenditure' = 2,
  'Expenditure return' = 4,
}

export enum PurchasesInvoiceTypes {
  'Purchase' = 2,
  'Purchase Debit Note' = 4,
}

export enum ImportInvoiceTypes {
  'Purchase' = 2,
}

export interface PurchasesInvoice {
  reference_id: string | null;
  processed: boolean | undefined;
  is_cashback: boolean;
  can_update_return: boolean;
  can_update: boolean;
  can_delete: boolean;
  linked_invoice: {
    [key: string]: string;
  };
  return_invoices: [
    {
      [key: string]: string;
    }
  ];
  invoice_items: [
    {
      [key: string]: string;
      errors: any;
    }
  ];
  has_draft_return_invoice: boolean;
  errors: any;
  id: number;
  status: any;
  invoice_number: string;
  invoice_date: string;
  payment_date: string;
  oldest_return_invoice_date: string;
  invoice_type: string;
  type: number;
  description: {
    ar: string;
    en: string;
  };
  is_return: boolean;
  amount: number;
  invoice_items_count: number;
  remaining_amount: number;
  calculated_vat: number;
  client: {
    id: number;
    name: string;
    reference_number: string;
  };
  documents: any[];
}

export interface PurchasesInvoiceListModel extends Meta {
  data: Array<PurchasesInvoice>;
}
