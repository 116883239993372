import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import * as CompaniesRedux from '../../../../app/modules/companies/store/UserCompaniesRedux';
import * as TemplatesRedux from '../../../../app/modules/templates/store/TemplatesRedux';

import { MenuComponent } from '../../../assets/ts/components';
import { useLayout } from '../../core';
import { CompaniesDataListModel } from '../../../../app/modules/companies/CompanyModel';
import { RootState } from '../../../../setup/redux/Store';
import { ICompany } from '../../../../app/types/companies';
import { UserMenuAndAlerts } from './UserMenuAndAlerts';

export function DocumentCrudHeader() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const params: any = useParams();
  const { classes, attributes } = useLayout();
  /* -------------------------- bring data from store ------------------------- */
  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;
  /* --------------------------- end data from store -------------------------- */
  useEffect(() => {
    if (
      (!companyDetails?.slug || companyDetails?.slug !== params?.companySlug) &&
      companies?.data.length
    ) {
      const id = companies.data.find((company) => company.slug === params.companySlug)
        ?.id as number;
      if (id) {
        dispatch(
          CompaniesRedux.actions.getCurrentCompanyById({
            id: id,
          })
        );
      }
    }
  }, [companyDetails, companies, params]);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [pathname]);

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          <div className='d-flex align-items-stretch flex-shrink-0'>
            <UserMenuAndAlerts withoutAlert />
          </div>
          <div className='d-flex align-items-stretch flex-shrink-0'>
            <div className='d-flex flex-col flex-lg-row-fluid ms-lg-7 ms-xl-10 jusify-content-center'>
              <div className='symbol-group symbol-hover'>
                {underConstructionTemplate?.values?.users?.map(
                  (user: any, index: number, arr: []) => {
                    if (index === 5)
                      return (
                        <div key={user.id} className='symbol symbol-35px symbol-circle'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <span
                              className='symbol-label bg-light-primary text-primary 40px mt-2'
                              title={formatMessage({ id: 'x_others' }, { x: arr.length - 5 })}
                            >
                              {arr.length - 5}+
                            </span>
                          </div>
                        </div>
                      );
                    return (
                      index <= 4 && (
                        <div key={user.id} className='symbol symbol-35px symbol-circle'>
                          <div className='symbol symbol-35px symbol-circle'>
                            <>
                              {user?.image ? (
                                <img alt='' src={user?.image} title={user.name} />
                              ) : (
                                <span
                                  className='symbol-label bg-light-primary text-primary 40px mt-2'
                                  title={user.name}
                                >
                                  {user?.name?.charAt(0)}
                                </span>
                              )}
                            </>
                          </div>
                        </div>
                      )
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className='d-flex align-items-stretch flex-shrink-0'>
            <div className='d-flex flex-col flex-lg-row-fluid ms-lg-7 ms-xl-10 jusify-content-center py-1 gap-3'></div>
          </div>
        </div>
      </div>
    </div>
  );
}
