import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PublicRoutes } from '../../../../_metronic/constants/ROUTES';
import { Logo } from '../../../shared/LogoWithCompany';

type Props = {
  direction: 'login' | 'signup';
};

export const LogoAndLoginLink: FC<Props> = ({ direction }) => {
  return (
    <div className='d-flex flex-row'>
      <Logo />
      <div className='flex-fill'>
        <div className='separator border-gray-300 flex-fill mx-3 mt-3'></div>
        <div className='fw-bold d-flex flex-row-reverse'>
          <p className='fs-7 mt-2'>
            <FormattedMessage
              id={direction === 'signup' ? "Don't have an account?" : 'Already have an account?'}
            />
            <Link
              to={PublicRoutes[direction]}
              className='link-primary fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              <FormattedMessage id={direction} />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
