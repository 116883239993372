import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

// fa='plus'
// btnType='outlined'
// onClick={() => history.push(`/c/${params.companySlug}/roles/add`)}
// nameId='Add New Role'

// onClick?: Function;
// btnType: 'outlined' | 'success';
// fa: string;
// nameId: string;
// disabled?: boolean;
type Props = {
  fa: string;
  faIconMenu?: boolean;
  nameId?: string;
  onClick?: any;
  id?: string;
  containerClassName?: string;
  disabled?: boolean;
};
export const DownloadButton: FC<Props> = ({
  fa,
  faIconMenu,
  nameId,
  onClick,
  id,
  containerClassName,
  disabled,
}) => {
  return (
    <div
      className={
        faIconMenu ? '' : clsx('m-4', containerClassName, { 'col-xl-4': !containerClassName })
      }
    >
      <div className={faIconMenu ? '' : 'row'}>
        <div className={faIconMenu ? '' : 'col-md-6'}>
          <button
            type='button'
            onClick={() => onClick && onClick()}
            id={id || 'download'}
            className='btn btn-sm w-100 btn-success '
            disabled={disabled}
          >
            <span className='indicator-label'>
              <i className={`far fa-${fa}`}></i>
              <FormattedMessage id={nameId || 'save'} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadButton;
