import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyInvoicesWithholdingReportTransactionsDetails =
  endpoints.companyInvoicesWithholdingReportTransactionsDetails;

export function getAllInvoicesWithholdingReportTransactionsDetails({
  companyId,
  reportId,
  params,
}: {
  companyId: ID;
  reportId: ID;
  params?: any;
}) {
  return axiosInstance.get(
    companyInvoicesWithholdingReportTransactionsDetails(companyId, reportId),
    { params }
  );
}
