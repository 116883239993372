/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useIntl } from 'react-intl'; //FormattedMessage,
import { Field, ErrorMessage } from 'formik';

const Step1: FC<any> = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='w-100'>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6' id='hasReference'>
            <Field
              type='radio'
              className='btn-check'
              name='invoice_type'
              value='hasReference'
              id='kt_create_invoice_form_invoice_type_hasReference'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_invoice_form_invoice_type_hasReference'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com005.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  {formatMessage({
                    id: 'Has Reference Invoice',
                  })}
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {formatMessage({
                    id: 'If your invoice has reference invoice, select this.',
                  })}
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6' id='hasNoReference'>
            <Field
              type='radio'
              className='btn-check'
              name='invoice_type'
              value='hasNoReference'
              id='kt_create_invoice_form_invoice_type_hasNoReference'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_invoice_form_invoice_type_hasNoReference'
            >
              <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  {formatMessage({
                    id: 'Has No Reference Invoice',
                  })}
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {formatMessage({
                    id: 'If your invoice has no reference invoice, select this.',
                  })}
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6' id='cashback'>
            <Field
              type='radio'
              className='btn-check'
              name='invoice_type'
              value='cashback'
              id='kt_create_invoice_form_invoice_type_cashback'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_invoice_form_invoice_type_cashback'
            >
              <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' />
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  {formatMessage({
                    id: 'Cashback',
                  })}
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {formatMessage({
                    id: 'If your bill is a cash back, select this.',
                  })}
                </span>
              </span>
            </label>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='invoice_type' />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
