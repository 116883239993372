import { AxiosError, AxiosResponse } from 'axios';
import { AxiosConfig } from '../apis';
// import { toast } from 'react-toastify';
// import { useIntl } from 'react-intl';
// import { useHistory } from 'react-router-dom';

export const isHandlerEnabled = (config: AxiosConfig) => {
  return Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request: AxiosConfig) => {
  return request;
};

export const successHandler = (response: AxiosResponse) => {
  if (isHandlerEnabled(response)) {
    // Do what ever is needed
  }
  return response;
};

export const errorHandler = (error: AxiosError) => {
  // console.log(error.response);

  if (isHandlerEnabled(error.config)) {
    // Do what ever is needed
    if (error.response?.status === 401 || error.response?.status === 403) {
      if (error.response.status === 401) {
        // Logout if unauthorized
        // localStorage.clear();
        localStorage.removeItem('persist:auth');
        localStorage.removeItem('presist:user');
        localStorage.removeItem('rememberUser');
        window.location.reload();
        // console.log('Unauthorized');
      } else if (error.response.status === 403) {
        // console.log(error.response.data.message);
        // const { formatMessage } = useIntl();
        // const history = useHistory();

        // go to home page if forbidden
        window.location.replace('/');

        // toast.error(
        //   formatMessage({
        //     id: `${
        //       error.response?.data?.message
        //         ? error.response?.data?.message
        //         : 'Either you are not authorized to view this page or this document has a newer version'
        //     }`,
        //   })
        // );
        // history.push('/');
        // document.location.reload();
        // console.log('Forbidden');
      }
    } else if (error.response?.status === 500) {
      // console.log('Internal Server Error');
      window.location.replace('error/500');
    } else if (error.response?.status === 404) {
      // console.log('Not Found');
      window.location.replace('error/404');
    }
  }
  return Promise.reject({ ...error });
};
