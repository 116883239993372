import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyUsers = endpoints.companyUsers;
export const companySingleUser = endpoints.companySingleUser;
export const companyDocumentReviewers = endpoints.companyDocumentReviewers;

export function getUsersList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyUsers(companyId), { params });
}

export function getSingleUserRequest({ companyId, userId }: { companyId: ID; userId: ID }) {
  return axiosInstance.get(companySingleUser(companyId, userId));
}

export function getDocumentReviewers({ companyId }: { companyId: ID }) {
  return axiosInstance.get(companyDocumentReviewers(companyId));
}
