import clsx from 'clsx';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  formik?: any;
  labelId?: string;
  withValidation?: boolean;
  name: string;
  type?: string;
  error?: any;
  touched?: any;
  setTouchedOnInput?: boolean;
  [something: string]: any;
};

export const Input: FC<Props> = ({
  labelId,
  formik,
  withValidation,
  name,
  type,
  setTouchedOnInput,
  ...props
}) => {
  const [currentType, setCurrentType] = useState(type || 'text');

  const toggleState = () => setCurrentType(currentType === 'password' ? 'text' : 'password');
  const { locale } = useIntl();

  return (
    <>
      {labelId && (
        <label
          className={clsx('form-label fs-6 my-1 fw-bolder text-dark', {
            required: !!props.required,
          })}
          htmlFor={name + '_input'}
        >
          <FormattedMessage id={labelId} />
        </label>
      )}
      <input
        {...formik?.getFieldProps(name)}
        id={name + '_input'}
        className={clsx(
          'form-control form-control-sm b-radius-16 basic-input my-1',
          {
            'is-invalid':
              withValidation &&
              ((formik?.touched?.[name] && formik?.errors?.[name]) ||
                (props.error && props.touched)),
          },
          {
            'is-valid': withValidation && formik?.touched?.[name] && !formik?.errors?.[name],
          }
        )}
        type={currentType}
        name={name}
        autoComplete='off'
        style={
          locale === 'ar'
            ? { unicodeBidi: 'plaintext', textAlign: 'right' }
            : {
                unicodeBidi: 'plaintext',
                textAlign: 'left',
              }
        }
        {...props}
        onInput={() => (setTouchedOnInput ? formik.setFieldTouched(name, true, true) : null)}
      />
      {type === 'password' && (
        <i
          id='toggle-password-visibility'
          data-testid='toggle-password-visibility'
          className={clsx('cursor-pointer far', {
            'fa-eye-slash': currentType === 'text',
            'fa-eye': currentType === 'password',
          })}
          onClick={() => toggleState()}
        ></i>
      )}

      {((formik?.touched?.[name] && formik?.errors?.[name]) || (props.error && props.touched)) && (
        <div className='fv-plugins-message-container mt-2 mx-3'>
          <span role='alert' className='text-danger'>
            {props.error || formik?.errors?.[name]}
          </span>
        </div>
      )}
    </>
  );
};
