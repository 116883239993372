import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyReports = endpoints.companyReports;
export const companyTransactions = endpoints.companyTransactions;
export const companySingleReport = endpoints.companySingleReport;
export const companyPurchases = endpoints.companyPurchases;

export function getAllWithholdingReports({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyReports(companyId), { params });
}

export function getAllWithholdingTransactions({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyTransactions(companyId), { params });
}

export function getWithholdingReport({ companyId, reportId }: { companyId: ID; reportId: ID }) {
  return axiosInstance.get(companySingleReport(companyId, reportId));
}

export function getAllPurchasesWithholdingTransactions({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyPurchases(companyId), { params });
}
