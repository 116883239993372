import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { nameWithoutSpcialCharsStartOrEnd } from '../../../../_metronic/helpers/functions';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { commonValidations } from '../../../../_metronic/constants/GeneralValidations';

import {
  CodeRuleCategoryTypes,
  CodeRuleOperatorTypes,
  CodeRuleColumnTypes,
} from '../../../modules/admins/code_rules/types/CodeRulesModel';

const useConfigureCodeRulesSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */

  const codeRulesInitialValues: { [key: string]: any } = useMemo(
    () => ({
      name_en: '',
      name_ar: '',
      error_message_en: 'There are transactions violating tax code rules',
      error_message_ar: 'يوجد معاملات القواعد الضريبية بها لم تطبق بشكل صحيح',
      category: {
        value: CodeRuleCategoryTypes['Code expiration date'],
        label: formatMessage({ id: 'Code expiration date' }),
      },
      operator: {
        value: CodeRuleOperatorTypes['Not before than'],
        label: formatMessage({ id: 'Not before than' }),
      },
      column: {
        value: CodeRuleColumnTypes['Report Date'],
        label: formatMessage({ id: 'Report Date' }),
      },
      nationalities: [],
      third_party_types: [],
      start_date: '',
      end_date: '',
      present: 0,
      is_not: 0,
      period: 1,
      min_amount: '0',
      max_amount: '',
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const codeRulesValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name_en: Yup.string()
          .required(required)
          .min(
            3,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: 3 }
            )
          )
          .max(
            15,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: 15 }
            )
          )
          .test(
            'invoice_number',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        name_ar: Yup.string()
          .required(required)
          .min(
            3,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: 3 }
            )
          )
          .max(
            15,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: 15 }
            )
          )
          .test(
            'invoice_number',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        error_message_en: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_en',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        error_message_ar: Yup.string()
          .required(required)
          .min(
            commonValidations.nameMinimum,
            $t('Please enter at least {minCharacters} characters', {
              minCharacters: commonValidations.nameMinimum,
            })
          )
          .max(
            commonValidations.descriptionMaximum,
            $t('The Maximum characters is {maxCharacters}', {
              maxCharacters: commonValidations.descriptionMaximum,
            })
          )
          .test(
            'description_en',
            $t("You can't enter special character at first or last!"),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        category: Yup.object().shape({
          value: Yup.string().required(required),
          label: Yup.string().required(required),
        }),
        start_date: Yup.string()
          .test('end_date', $t('Start date must be less than end date'), function (val: any) {
            const { end_date } = this.parent;
            if (end_date) {
              return new Date(val) < new Date(end_date);
            }
            return true;
          })
          // make date required if category === "1"
          .when('category', {
            is: (val: any) => val?.value === '1',
            then: Yup.string().required(required),
            otherwise: Yup.string(),
          }),

        end_date: Yup.string()
          // make date required if category === "1" || present === 0
          .when(['category', 'present'], {
            is: (category: any, present: any) => category?.value === '1' && present === 0,
            then: Yup.string().required(required),
            otherwise: Yup.string(),
          }),

        present: Yup.number().when('category', {
          is: (val: any) => val?.value === '1',
          then: Yup.number().required(required),
          otherwise: Yup.number(),
        }),
        is_not: Yup.number().when('category', {
          is: (val: any) => val?.value === '3',
          then: Yup.number().required(required),
          otherwise: Yup.number(),
        }),

        min_amount: Yup.number()
          .test('min_amount', $t('Min amount must be less than max amount'), function (val: any) {
            const { max_amount } = this.parent;
            if (max_amount) {
              return val < max_amount;
            }
            return true;
          })
          .when('category', {
            is: (val: any) => val?.value === '2',
            then: Yup.number()
              .required(required)
              .typeError($t('Only number are allowed!'))
              .test(
                'len',
                formatMessage(
                  { id: 'The Maximum characters is {maxCharacters}' },
                  { maxCharacters: 8 }
                ),
                (val: any) => !val || (val && val.toString().length <= 8)
              ),
            otherwise: Yup.number()
              .typeError($t('Only number are allowed!'))
              .test(
                'len',
                formatMessage(
                  { id: 'The Maximum characters is {maxCharacters}' },
                  { maxCharacters: 8 }
                ),
                (val: any) => !val || (val && val.toString().length <= 8)
              ),
          }),

        max_amount: Yup.number()
          .typeError($t('Only number are allowed!'))
          .test(
            'len',
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: 8 }
            ),
            (val: any) => !val || (val && val.toString().length <= 8)
          ),
        period: Yup.number().when('category', {
          is: (val: any) => val?.value === '5',
          then: Yup.number()
            .min(1, $t('Period must be greater than 0'))
            .required(required)
            .typeError($t('Only number are allowed!'))
            .test(
              'len',
              formatMessage(
                { id: 'The Maximum characters is {maxCharacters}' },
                { maxCharacters: 8 }
              ),
              (val: any) => !val || (val && val.toString().length <= 8)
            ),
          otherwise: Yup.number()
            .typeError($t('Only number are allowed!'))
            .test(
              'len',
              formatMessage(
                { id: 'The Maximum characters is {maxCharacters}' },
                { maxCharacters: 8 }
              ),
              (val: any) => !val || (val && val.toString().length <= 8)
            ),
        }),
        nationalities: Yup.array()
          // make date required if category === "3"
          .when('category', {
            is: (val: any) => val?.value === '3',
            then: Yup.array().min(1),
            otherwise: Yup.array(),
          }),
        third_party_types: Yup.array()
          // make date required if category === "3"
          .when('category', {
            is: (val: any) => val?.value === '4',
            then: Yup.array().min(1),
            otherwise: Yup.array(),
          }),
      }),

    [formatMessage]
  );

  return {
    codeRulesInitialValues,
    codeRulesValidationSchema,
  };
};

export { useConfigureCodeRulesSchema };
