import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as CurrentCompany from '../../app/store/CurrentCompanyRedux';
import { axiosInstance } from '../../network/apis';

import { RootState } from '../../setup/redux/Store';
import { UserModel } from '../modules/auth/models/UserModel';
import { CurrentCompanyUser } from '../types/CompanyTypes';
import { Permission } from '../types/types';

export const usePermissions = (
  doThis?: Permission | Permission[],
  doWhat: string = 'visit this page',
  path: string = '/',
  redirectionId: string = 'companyPage'
) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();

  const currentCompanyUser: CurrentCompanyUser = useSelector<RootState>(
    (state) => state.current.current,
    shallowEqual
  ) as CurrentCompanyUser;


  useEffect(() => {
    if (params?.companySlug && params?.companySlug !== currentCompanyUser?.company?.slug) {
      const getData = async () => {
        const response = await axiosInstance.post(`${params?.companySlug}/switch`);
        await dispatch(CurrentCompany.actions.setCurrentCompany({ ...response.data }));
      };

      getData();
    }
  }, []);
  /* ------------------------- getting data from store ------------------------ */
  const user: UserModel = useSelector<RootState>((state) => state.auth.user) as UserModel;

  const permissions: Permission[] = useSelector<RootState>(
    (state) => state.current.permissions
  ) as Permission[];

  /* ----------------------- end getting data from store ---------------------- */

  const userCan = (permission: Permission): boolean => {
    return (
      permissions.includes(permission) ||
      (permission === 'super_admin' && !!user?.isSuperAdmin) ||
      (permission === 'is_owner' &&
        !!(currentCompanyUser?.user?.id === currentCompanyUser?.company?.owner?.id)) ||
      permission === 'all_users'
    );
  };

  const userCanNot = (permissions: Permission[] | Permission) => {
    if (Array.isArray(permissions)) {
      return permissions.every((permission) => !userCan(permission));
    }
    if (typeof permissions === 'string') {
      return !userCan(permissions);
    }
  };

  const userOrOwnerCan = (permission: Permission) => {
    return (
      userCan(permission) ||
      permission === 'all_users' ||
      currentCompanyUser?.user?.id === currentCompanyUser?.company?.owner?.id
    );
  };

  /* ----------------- redirect user if we provide a condition ---------------- */
  useEffect(() => {
    if (doThis && userCanNot(doThis)) {
      toast.info(
        formatMessage({ id: 'not_allowed_to_do' }) +
          formatMessage({ id: doWhat }).toLowerCase() +
          '. ' +
          formatMessage({ id: "you'llBeReidrectedTo" }) +
          ' ' +
          formatMessage({ id: redirectionId }),
        {
          position: 'top-center',
          theme: 'light',
        }
      );
      history.push(path);
    }
  }, [doThis]);
  /* --------------- end redirect user if we provide a condition ---------------- */

  return {
    permissions,
    isCompanyOwner: currentCompanyUser?.user?.id === currentCompanyUser?.company?.owner?.id,
    isSuperAdmin: user?.isSuperAdmin,
    userCan,
    userCanNot,
    userOrOwnerCan,
  };
};
