import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Select from 'react-select';
import * as companiesRedux from '../../../../app/modules/companies/store/UserCompaniesRedux';
import { RootState } from '../../../../setup/redux/Store';

import { $t } from '../../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../../_metronic/layout/core';
import { usePermissions } from '../../../hooks/usePermissions';
import useWindowSize from '../../../hooks/useWindowSize';
import { CompanyModel, CompaniesDataListModel } from '../../../modules/companies/CompanyModel';
import { ICompany } from '../../../../app/types/companies';
import { SeparatorLines } from '../../../shared/Lines';
import { CurrentCompanyUser } from '../../../types/CompanyTypes';
import { COMPANY_DETAILS_TABS, CompanyDetailsHandler } from './components/TabSelection';
type Props = {
  mode: 'edit' | 'view';
};
export const CompanyDetailsTabsWrapper: FC<Props> = ({ mode }) => {
  // const history = useHistory();
  const [isCalled, setIsCalled] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const params: any = useParams();
  // const { formatMessage } = useIntl();
  // const location = useLocation();

  // useEffect(() => {
  //   if (params.detailTab) {
  //     if (mode === 'edit') {
  //       setSelectedTab(companyDetailsParamsMapEdit.get(`${params?.detailTab}/edit`)?.id || 1);
  //     } else {
  //       setSelectedTab(companyDetailsParamsMap.get(`${params?.detailTab}`)?.id || 1);
  //     }
  //   }
  // }, [params.detailTab, mode]);

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const currentCompanyUser: CurrentCompanyUser = useSelector<RootState>(
    (state) => state.current.current
  ) as CurrentCompanyUser;

  const company = companyDetails || ({} as CompanyModel);

  useEffect(() => {
    if (companies?.data?.length && currentCompanyUser?.user?.roles && !isCalled) {
      setIsCalled(true);
      dispatch(
        companiesRedux.actions.getCurrentCompanyById({
          id: companyDetails?.id,
        })
      );
    }
  }, [companies, params, currentCompanyUser]);

  const tabOptions = COMPANY_DETAILS_TABS.filter((tab) => {
    return tab.display;
  }).map((tab) => {
    return {
      label: $t(tab.tabId),
      value: tab.id,
    };
  });

  return (
    <>
      <div className='d-flex justify-content-between flex-column flex-md-row'>
        <div className='d-flex align-items-center gap-3 mb-3'>
          <div>{company?.name || company?.slug}</div>
        </div>
        <div className='d-flex flex-row gap-4 align-items-top pt-3 mb-3'>
          <p className='fs-6 text-lightGreyBlue mt-3'>{$t('Status')}:</p>
          <p className='bg-semiBlue b-radius-10 fs-5 p-2 mw-120px w-200px d-flex mh-30px'>
            <div
              className='bg-statusOrange rounded-circle border border-5 h-20px w-20px border-semiBlue'
              style={{ minWidth: 'fit-content' }}
            ></div>
            <span>{company?.status && $t(company?.status)}</span>
          </p>
        </div>
      </div>

      {width > 1156 ? (
        <div className='brts-radius-20 blts-radius-20 bg-white h-40px pt-3 px-2 details_nav'>
          {COMPANY_DETAILS_TABS.filter((tab) => {
            return tab.display;
          }).map((tab, index) => {
            return (
              <span
                key={index}
                className={clsx(
                  `mx-2 cursor-pointer text-blueGrey fs-6 ${
                    selectedTab === tab.id ? 'current' : ''
                  }`
                )}
                onClick={() => {
                  setSelectedTab(tab.id);
                }}
              >
                <i className={clsx(`far ${tab.tabIcon} mx-1`)}></i>
                {$t(tab.tabId)}
              </span>
            );
          })}
        </div>
      ) : (
        <Select
          onChange={(value) => setSelectedTab(value?.value || 1)}
          options={tabOptions}
          value={tabOptions.find((itm) => selectedTab === itm.value)}
          className='react-select smaller'
          id='company_type'
        />
      )}

      <section className='company_profile_handler'>
        <SeparatorLines lines={1} />
        <CompanyDetailsHandler currentView={selectedTab} mode={mode} />
      </section>
    </>
  );
};

const CompanyDetailsWrapperView: FC = () => {
  usePermissions('view-company');
  const history = useHistory();
  const location = useLocation();
  const { userCan } = usePermissions();

  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Company Details' })}</PageTitle>
      <div>
        {userCan('update-company') && (
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-primary btn-sm btn-primary-shadow'
              title={formatMessage({ id: 'Edit Company Details' })}
              onClick={() => {
                history.push(location.pathname + '/edit');
              }}
            >
              <i className='fa fa-edit cursor-pointer'></i>
              {formatMessage({ id: 'Edit Company Details' })}
            </button>
          </div>
        )}
      </div>

      <CompanyDetailsTabsWrapper mode='view' />
    </div>
  );
};
const CompanyDetailsWrapperEdit: FC = () => {
  usePermissions('update-company');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Edit Company Details' })}</PageTitle>
      <CompanyDetailsTabsWrapper mode='edit' />
    </div>
  );
};

export { CompanyDetailsWrapperView, CompanyDetailsWrapperEdit };
