const formatDateIfNotNull = (date: any) => {
  return typeof date === 'string' ? new Date(date) : date;
};

export function pairsToObject(array: any[]) {
  var ret: any = {};
  if (array) {
    array?.forEach(function (p: [x: string, y: any, type: any]) {
      ret[p[0]] = ['past_date', 'date', 'future_date'].includes(p[2])
        ? formatDateIfNotNull(p[1])
        : p[1];
    });
  }
  return ret;
}
