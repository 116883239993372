import { FC, useEffect, useState } from 'react'; //useMemo,
import { FormattedMessage, useIntl } from 'react-intl'; //
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'; //
import { serialize } from 'object-to-formdata';

import { PageTitle } from '../../../_metronic/layout/core';
import { Button } from '../../shared/Button';
import { usePermissions } from '../../hooks/usePermissions';
import { RootState } from '../../../setup/redux/Store';
import { axiosInstance } from '../../../network/apis';
import { TableActions, TableData, TableSelection, DateViewer } from '../../shared/tables/classes'; // DateViewer,
import { $t } from '../../../_metronic/i18n/formatMessage';
import { SerachFilters } from '../../shared/SearchFilters';
import * as TableRedux from '../../store/TableRedux';
// import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { KTSVG } from '../../../_metronic/helpers';

import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
// import { EditItem } from '../../shared/tables/actions/EditItem';
// import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { DownloadButton } from '../../shared/DownloadButton';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { toast } from 'react-toastify';
import { DisplayErrors } from '../../../utils/DisplayErrors';

import { LOCALE } from '../../../_metronic/helpers/typescript';

import { CompanyModel } from '../companies/CompanyModel';
// import { UserModel } from '../auth/models/UserModel';

import * as LineItemsRedux from './store/LineItemsRedux';
import { ILineItem, ILineItemsDataListModel } from './types/LineItemsModel';
// import { TablesWidget13 } from '../../../_metronic/partials/widgets';
// import { CommonTable } from '../../shared/tables/Table';
import { CommonTable2 } from '../../shared/tables/Table2';
// import {
// TransactionTypesMap,
// TransactionTypeMap,
// VatRateTypesMap,
//   VatRateTypeMap,
// } from '../../../_metronic/constants/general';

const TABLE_NAME = 'line_items_list';

const headerItems = [
  //   { id: 'ID', maxWidth: 150, width: 150 },
  { id: 'Tax rate', maxWidth: 240, width: 230 },
  { id: 'Client Name', maxWidth: 240, width: 230 },
  { id: 'Client Code', maxWidth: 150, width: 150 },
  { id: 'Invoice date', maxWidth: 250, width: 250 },
  { id: 'Invoice number', maxWidth: 150, width: 150 },
  { id: 'Name/description', maxWidth: 230, width: 230 },
  { id: 'Amount', maxWidth: 150, width: 150 },
  { id: 'Actions', maxWidth: 150, width: 150 },
];
const headerItemsWithoutActions = [
  { id: 'Select', maxWidth: 150, width: 150 },
  { id: 'Tax rate', maxWidth: 240, width: 230 },
  { id: 'Client Name', maxWidth: 240, width: 230 },
  { id: 'Client Code', maxWidth: 150, width: 150 },
  { id: 'Invoice date', maxWidth: 250, width: 250 },
  { id: 'Invoice number', maxWidth: 150, width: 150 },
  { id: 'Name/description', maxWidth: 230, width: 230 },
  { id: 'Amount', maxWidth: 150, width: 150 },
];

export const LineItems: FC<any> = ({ transactionObj, paramsObj, linkedInvoices }) => {
  // const { userCan } = usePermissions();
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const params: any = useParams();
  // const { locale } = useIntl(); //formatMessage,
  // const [dateFrom, setDateFrom] = useState<string>('');
  // const [dateTo, setDateTo] = useState<string>('');

  // const companyDetails: CompanyModel = useSelector<RootState>(
  //   (state) => state?.USER_companies.companyDetails,
  //   shallowEqual
  // ) as CompanyModel;

  return (
    <div>
      <LineItemsTable
        transactionObj={transactionObj}
        paramsObj={paramsObj}
        linkedInvoices={linkedInvoices}
      />
    </div>
  );
};

export const LineItemsTable: FC<any> = ({ transactionObj, paramsObj, linkedInvoices }) => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const params: any = useParams();
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  // const [editItemId, setEditItemId] = useState<any>(null);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [showWarningsConfirmationModal, setShowWarningsConfirmationModal] = useState(false);
  const [warningsConfirmation, setWarningsConfirmation] = useState<any>([]);
  const [showModalButton, setShowModalButton] = useState(false);
  const [loading, setLoading] = useState(false);
  /* -------------------------- Bring Data from store ------------------------- */
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const LineItemsList: ILineItemsDataListModel = useSelector<RootState>((state) => {
    return state.USER_line_items.LineItems;
  }, shallowEqual) as ILineItemsDataListModel;

  // const user: UserModel = useSelector<RootState>(
  //   (state) => state.auth.user,
  //   shallowEqual
  // ) as UserModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(LineItemsRedux.actions.fullFillLineItems({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  function RequestData() {
    const table = tables[TABLE_NAME];

    dispatch(
      LineItemsRedux.actions.getLineItems({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
          invoice_date: transactionObj?.id,
          period: paramsObj?.period,
          invoice_reference: linkedInvoices?.invoice_reference_id,
        },
      })
    );
  }

  useEffect(() => {
    if (table) {
      RequestData();
    }
  }, [table?.page, table?.pagination, transactionObj, paramsObj, linkedInvoices]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  const deleteSales = async (values: { selectedIds: number[]; approve: number }) => {
    setLoading(true);
    try {
      let payload = {
        ids: values.selectedIds,
        approve: values.approve,
      };

      if (values.approve) {
        payload['approve'] = values.approve;
      }
      const formData = serialize(payload);
      formData.append('_method', 'DELETE');
      await axiosInstance.post(`/${companyDetails.id}/sales/bulk`, formData);
      toast.success(formatMessage({ id: 'Sale has been deleted successfully!' }) || '');
      setDeleteItemId && setDeleteItemId(null);
      dispatch(TableRedux.actions.clearSelection({ tableName: TABLE_NAME }));
      RequestData();
    } catch (err: any) {
      if (err && err.response.status === 422 && err.response.data.messages.length > 0) {
        setLoading(false);
        setShowWarningsConfirmationModal(true);
        setWarningsConfirmation(err.response.data.messages);
        setShowModalButton(err.response.data.can_approve);
      } else {
        DisplayErrors(err);
      }
    }
    setLoading(false);
  };

  const rowItems = LineItemsList?.data.map((lineItem: ILineItem, index) => {
    // const VatRateTypesMapTranslated = {
    //   text: formatMessage({ id: `${VatRateTypesMap.get(vatRate.type)}` }) || '',
    // };
    // const TransactionTypesMapTranslated = {
    //   text: formatMessage({ id: `${TransactionTypesMap.get(vatRate.transaction_type)}` }) || '',
    // };
    // remove action coulumns when view on report

    if (transactionObj?.date) {
      return [
        new TableSelection({
          display: (userCan('delete-invoice') || userCan('create-report')) && !linkedInvoices,
          id: lineItem.id,
          tableName: TABLE_NAME,
        }),
        new TableData({
          text: `${lineItem.vat_rate?.name ?? ''}`,
          errors_with_color: lineItem?.errors_with_color?.vat_rate,
        }),
        new TableData({
          text: lineItem?.client?.name,
          errors_with_color: lineItem?.errors_with_color?.client,
        }),
        new TableData({
          text: `${lineItem?.client?.reference_number ?? ''}`,
          errors_with_color: lineItem?.errors_with_color?.client,
        }),
        new DateViewer({
          date: lineItem.invoice_date,
          errors_with_color: lineItem?.errors_with_color?.invoice_date,
        }),
        new TableData({
          text: `${
            lineItem.invoice_status === 1
              ? lineItem.invoice_number
              : formatMessage({ id: 'Report Draft' })
          }`,
          errors_with_color: lineItem?.errors_with_color?.invoice_number,
        }),
        new TableData({
          text: `${
            lineItem.product ? lineItem.product.name[locale as LOCALE] : lineItem.description ?? ''
          }`,
        }),
        new TableData({
          text: `${lineItem.amount}`,
          errors_with_color: lineItem?.errors_with_color?.amount,
        }),
        //   new TableData({ text: lineItem?.vat_rate?. }),
      ];
    } else {
      return [
        new TableSelection({
          display: (userCan('delete-invoice') || userCan('create-report')) && !linkedInvoices,
          id: lineItem.id,
          tableName: TABLE_NAME,
        }),
        new TableData({
          text: `${lineItem.vat_rate?.name ?? ''}`,
          errors_with_color: lineItem?.errors_with_color?.vat_rate,
        }),
        new TableData({
          text: lineItem?.client?.name,
          errors_with_color: lineItem?.errors_with_color?.client,
        }),
        new TableData({
          text: `${lineItem?.client?.reference_number ?? ''}`,
          errors_with_color: lineItem?.errors_with_color?.client,
        }),
        new DateViewer({
          date: lineItem.invoice_date,
          errors_with_color: lineItem?.errors_with_color?.invoice_date,
        }),
        new TableData({
          text: `${
            lineItem.invoice_status === 1
              ? lineItem.invoice_number
              : formatMessage({ id: 'Report Draft' })
          }`,
          errors_with_color: lineItem?.errors_with_color?.invoice_number,
        }),
        new TableData({
          text: `${
            lineItem.product ? lineItem.product.name[locale as LOCALE] : lineItem.description ?? ''
          }`,
        }),
        new TableData({
          text: `${lineItem.amount}`,
          errors_with_color: lineItem?.errors_with_color?.amount,
        }),
        //   new TableData({ text: lineItem?.vat_rate?. }),
        new TableActions(
          (
            <div className='d-flex flex-row'>
              {/* original invoice actions */}
              {userCan('view-invoice') && !transactionObj?.date && (
                <ViewItem
                  title={$t('View')}
                  onClick={() =>
                    history.push(
                      `/c/${companyDetails.slug}/financial-management/lineitems/${lineItem.id}`
                    )
                  }
                />
              )}

              {userCan('delete-invoice') && !transactionObj?.date && (
                <div className='d-none'>
                  <DeleteItem
                    id={lineItem.id}
                    title={$t('Delete')}
                    messageId={
                      lineItem?.can_update
                        ? lineItem?.can_delete
                          ? 'This lineItem already exist in a previous report, and deleting it will cause changes to the report, are you sure you want to proceed the deletion process?'
                          : lineItem?.linked_invoices! > 0
                          ? 'This invoice has returns invoices linked with it. Are you sure you want to proceed with the deletion process?'
                          : 'Are you sure you want to delete this lineItem?'
                        : "This lineItem is included in an approved report, and can't be deleted"
                    }
                    showButtonGroup={lineItem?.can_update}
                    setDeleteItemId={setDeleteItemId}
                    deleteItemId={deleteItemId}
                    deleteURI={`/${companyDetails.id}/lineItems`}
                    payload={lineItem.id}
                    successMessage={'lineItem has been deleted successfully!'}
                    afterSucessRequest={() => RequestData()}
                  />
                </div>
              )}
            </div>
          )
        ),
      ];
    }
  });
  return (
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}

          {/*  ------------------------ start Action button ------------------------  */}
          {!linkedInvoices && !transactionObj?.date && (
            <div className='card-toolbar align-items-end flex-row d-none'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
              </button>
              {/* begin::Menu 2 */}
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
                data-kt-menu='true'
              >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                    {$t('Actions')}{' '}
                  </div>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu separator */}
                <div className='separator mb-3 opacity-75'></div>
                {/* end::Menu separator */}

                {/* begin::Menu item */}
                {!linkedInvoices && userCan('create-invoice') && !transactionObj?.date && (
                  <div className='menu-item px-3'>
                    <Button
                      className='menu-link px-3 btn w-md-190px'
                      faIconMenu={true}
                      fa='undo'
                      onClick={() => {
                        history.push(`/c/${params.companySlug}/tax-module/salesreturn`);
                      }}
                      nameId='Add sales return'
                      testid='add-sale-return-button'
                    />
                  </div>
                )}
                {/* end::Menu item */}
                {/* begin::Menu item */}
                {!linkedInvoices && userCan('create-invoice') && !transactionObj?.date && (
                  <div className='menu-item px-3'>
                    <Button
                      fa='mail-bulk'
                      className='menu-link px-3 btn w-md-190px'
                      onClick={() => {
                        history.push(`/c/${params.companySlug}/tax-module/sales/create`);
                      }}
                      nameId='Add sales'
                      testid='add-sale-button'
                      faIconMenu={true}
                    />
                  </div>
                )}
                {/* end::Menu item */}
                {/* begin::Menu item */}
                {!linkedInvoices && userCan('view-invoice') && !transactionObj?.date && (
                  <div className='menu-item px-3'>
                    <Button
                      fa='arrow-down'
                      faIconMenu={true}
                      className='menu-link px-3 btn w-md-190px'
                      nameId='Export sales'
                      onClick={() => {
                        let paramsObj = {};
                        if (dateFrom.length > 0) {
                          paramsObj = {
                            ...paramsObj,
                            date_from: dateFrom,
                          };
                        }
                        if (dateTo.length > 0) {
                          paramsObj = {
                            ...paramsObj,
                            date_to: dateTo,
                          };
                        }

                        return axiosInstance
                          .get(`${companyDetails.id}/sales/export`, {
                            responseType: 'blob',
                            params: paramsObj,
                          })
                          .then((response) => {
                            let file = new Blob([response.data], {
                              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            });
                            let fileURL = URL.createObjectURL(file);

                            // create <a> tag dinamically
                            let fileLink = document.createElement('a');
                            fileLink.href = fileURL;

                            // it forces the name of the downloaded file
                            fileLink.download = 'Ahad_export-sales';

                            // triggers the click event
                            fileLink.click();
                          });
                      }}
                    />
                  </div>
                )}
                {/* end::Menu item */}
                {/* begin::Menu item */}
                {!linkedInvoices && userCan('create-invoice') && !transactionObj?.date && (
                  <div className='menu-item px-3'>
                    <Button
                      fa='plus'
                      className='menu-link px-3 btn w-md-190px'
                      onClick={() =>
                        history.push(`/c/${params.companySlug}/tax-module/sales/import`)
                      }
                      nameId='Import sales'
                      faIconMenu={true}
                    />
                  </div>
                )}
                {/* end::Menu item */}

                {/* begin::Menu item */}
                {!transactionObj?.date && userCan('delete-invoice') && (
                  <div className='menu-item px-3'>
                    <button
                      className='menu-link px-3 btn w-md-190px'
                      title={$t('Delete')}
                      disabled={table?.selection?.length === 0}
                      onClick={() => {
                        // setDeleteItemId &&
                        // setDeleteItemId(table?.selection.includes(-1) ? table?.ids : table?.selection)
                        deleteSales({
                          selectedIds: table?.selection.includes(-1)
                            ? table?.ids
                            : table?.selection,
                          approve: 0,
                        });
                      }}
                    >
                      <span className='menu-icon'>
                        <i className='far fa-trash'></i> &nbsp;
                      </span>

                      {formatMessage({
                        id: 'Delete',
                      })}
                    </button>
                  </div>
                )}
                {/* end::Menu item */}
                {/* begin::Menu separator */}
                <div className='separator mt-3 opacity-75'></div>
                {/* end::Menu separator */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <div className='menu-content px-3 py-3'>
                    <DownloadButton
                      fa='arrow-down'
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_BACKEND_BASE_URL?.replace(
                            /\/api$/,
                            ``
                          )}/Ahad_sales_vat_rates_codes_${locale}.pdf`
                        )
                      }
                      nameId='VAT rates guide'
                      faIconMenu={true}
                    />
                  </div>
                </div>
                {/* end::Menu item */}
              </div>
              {/* end::Menu 2 */}
              {/* end::Menu */}
            </div>
          )}
          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dateRange',
                  queryTerm: 'dateRange',
                  isClearable: true,
                  onChange: (valueFrom: string, valueTo: string) => {
                    setDateTo(valueTo);
                    setDateFrom(valueFrom);
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: valueFrom,
                        tableName: TABLE_NAME,
                        queryTerm: 'date_from',
                      })
                    );
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: valueTo,
                        tableName: TABLE_NAME,
                        queryTerm: 'date_to',
                      })
                    );
                  },
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          initPerPage={transactionObj?.date ? 10 : 20}
          hidePagination={transactionObj?.date ? true : false}
          scrollable={transactionObj?.date && LineItemsList?.data?.length > 5 ? true : false}
          headerWithCheckbox={userCan('delete-invoice') && !transactionObj?.date && !linkedInvoices}
          allDisplayedIds={LineItemsList?.data?.map((sales) => sales.id) || []}
          meta={LineItemsList}
          headerItems={!transactionObj?.date ? headerItems : headerItemsWithoutActions}
          rowItems={rowItems}
        />
      </div>

      {/*  ---------------------warnings confirmation Modal-----------------------  */}

      {showWarningsConfirmationModal && warningsConfirmation && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowWarningsConfirmationModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                {warningsConfirmation.length === 1 && <span>{warningsConfirmation[0]}</span>}
                {warningsConfirmation.length > 1 && (
                  <ol>
                    {warningsConfirmation.map((warning: any, index: any) => (
                      <li key={index}>{warning}</li>
                    ))}
                  </ol>
                )}
              </div>
              {showModalButton && (
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        // disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowWarningsConfirmationModal(false);
                          deleteSales({
                            selectedIds: table?.selection.includes(-1)
                              ? table?.ids
                              : table?.selection,
                            approve: 1,
                          });
                        }}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Approve'} />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowWarningsConfirmationModal(false);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end warnings confirmation Modal-----------------------  */}
    </>
  );
};

const LineItemsWrapper: FC<any> = ({ transactionObj, paramsObj, linkedInvoices }) => {
  usePermissions(['view-invoice', 'create-report']);
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Line items' })}</PageTitle>
      {/* <PageTitle breadcrumbs={[{ title: 'Vat Rates', path: '/admin/vat-rates', isActive: true }]}>
        {formatMessage({ id: 'Vat Rates' })}
      </PageTitle> */}

      <LineItems
        transactionObj={transactionObj}
        paramsObj={paramsObj}
        linkedInvoices={linkedInvoices}
      />
    </div>
  );
};

export { LineItemsWrapper };
