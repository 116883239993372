import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { getAllCodeRules, getCodeRuleData } from './CodeRulesCRUD';

export enum ICodeRulesActionTypes {
  GET_CODE_RULES = 'GET_CODE_RULES',
  FULL_FILL_CODE_RULES = 'FULL_FILL_CODE_RULES',
  GET_CODE_RULE = 'GET_CODE_RULE',
  FULL_FILL_CODE_RULE = 'FULL_FILL_CODE_RULE',
}

const initialCodeRules: ICodeRulestate = {
  code_rules: undefined,
  code_rule: undefined,
};

export interface ICodeRulestate {
  code_rules?: any;
  code_rule?: any;
}

export const reducer = persistReducer(
  { storage, key: 'SYS_code_rules', whitelist: [''] },
  (state: ICodeRulestate = initialCodeRules, action: ActionWithPayload<ICodeRulestate>) => {
    switch (action.type) {
      case ICodeRulesActionTypes.FULL_FILL_CODE_RULES:
        return { ...state, code_rules: action.payload?.code_rules };
      case ICodeRulesActionTypes.FULL_FILL_CODE_RULE:
        return { ...state, code_rule: action.payload?.code_rule };
      default:
        return state;
    }
  }
);

type Search = { params?: any };

export const actions = {
  getCodeRules: ({ params }: Search) => ({
    type: ICodeRulesActionTypes.GET_CODE_RULES,
    payload: { params },
  }),
  fullFillCodeRules: ({ code_rules }: ICodeRulestate) => ({
    type: ICodeRulesActionTypes.FULL_FILL_CODE_RULES,
    payload: { code_rules },
  }),
  getCodeRule: ({ codeRuleId }: any) => ({
    type: ICodeRulesActionTypes.GET_CODE_RULE,
    payload: { codeRuleId },
  }),
  fullFillCodeRule: ({ code_rule }: ICodeRulestate) => ({
    type: ICodeRulesActionTypes.FULL_FILL_CODE_RULE,
    payload: { code_rule },
  }),
};

export function* getCodeRules(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllCodeRules({
      params: action.payload?.params,
    });
    yield put(actions.fullFillCodeRules({ code_rules: data }));
  } catch (error) {
    //
  }
}
export function* getCodeRule(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getCodeRuleData({
      codeRuleId: action.payload?.codeRuleId,
    });

    yield put(actions.fullFillCodeRule({ code_rule: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ICodeRulesActionTypes.GET_CODE_RULES, getCodeRules);
  yield takeLatest(ICodeRulesActionTypes.GET_CODE_RULE, getCodeRule);
}
