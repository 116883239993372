import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyLineItems = endpoints.companyLineItems;
export const companySingleLineItems = endpoints.companySingleLineItems;

export function getAllInvoiceItems({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyLineItems(companyId), { params });
}

export function getLineItem({ companyId, lineItemId }: { companyId: ID; lineItemId: ID }) {
  return axiosInstance.get(companySingleLineItems(companyId, lineItemId));
}
