import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import { getAllPurchases, getPurchase } from './PurchasesCRUD';

export enum IPurchasesActionTypes {
  GET_PURCHASES = 'GET_PURCHASES',
  FULL_FILL_PURCHASES = 'FULL_FILL_PURCHASES',
  GET_CURRENT_PURCHASE = 'GET_CURRENT_PURCHASE',
  FULL_FILL_CURRENT_PURCHASE = 'FULL_FILL_CURRENT_PURCHASE',
  GET_CURRENT_REFERENCE_PURCHASE = 'GET_CURRENT_REFERENCE_PURCHASE',
  FULL_FILL_CURRENT_REFERENCE_PURCHASE = 'FULL_FILL_CURRENT_REFERENCE_PURCHASE',
}

const initialPurchases: IPurchasesState = {
  purchases: undefined,
  currentPurchase: undefined,
  currentReferencePurchase: undefined,
};

export interface IPurchasesState {
  purchases?: any;
  currentPurchase?: any;
  currentReferencePurchase?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_purchases', whitelist: [''] },
  (state: IPurchasesState = initialPurchases, action: ActionWithPayload<IPurchasesState>) => {
    switch (action.type) {
      case IPurchasesActionTypes.FULL_FILL_PURCHASES:
        return { ...state, purchases: action.payload?.purchases };
      case IPurchasesActionTypes.FULL_FILL_CURRENT_PURCHASE:
        return { ...state, currentPurchase: action.payload?.currentPurchase };
      case IPurchasesActionTypes.FULL_FILL_CURRENT_REFERENCE_PURCHASE:
        return { ...state, currentReferencePurchase: action.payload?.currentReferencePurchase };
      default:
        return state;
    }
  }
);

export const actions = {
  getPurchases: ({ companyId, params }: Search) => ({
    type: IPurchasesActionTypes.GET_PURCHASES,
    payload: { companyId, params },
  }),
  fullFillPurchases: ({ purchases }: IPurchasesState) => ({
    type: IPurchasesActionTypes.FULL_FILL_PURCHASES,
    payload: { purchases },
  }),
  getCurrentPurchase: ({ companyId, purchaseId }: any) => ({
    type: IPurchasesActionTypes.GET_CURRENT_PURCHASE,
    payload: { companyId, purchaseId },
  }),
  fullFillCurrentPurchase: ({ currentPurchase }: IPurchasesState) => ({
    type: IPurchasesActionTypes.FULL_FILL_CURRENT_PURCHASE,
    payload: { currentPurchase },
  }),
  getCurrentReferencePurchase: ({ companyId, purchaseId }: any) => ({
    type: IPurchasesActionTypes.GET_CURRENT_REFERENCE_PURCHASE,
    payload: { companyId, purchaseId },
  }),
  fullFillCurrentReferencePurchase: ({ currentReferencePurchase }: IPurchasesState) => ({
    type: IPurchasesActionTypes.FULL_FILL_CURRENT_REFERENCE_PURCHASE,
    payload: { currentReferencePurchase },
  }),
};

export function* getPurchases(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllPurchases({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillPurchases({ purchases: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentPurchase(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getPurchase({
      companyId: action.payload?.companyId,
      purchaseId: action.payload?.purchaseId,
    });
    yield put(actions.fullFillCurrentPurchase({ currentPurchase: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentReferencePurchase(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getPurchase({
      companyId: action.payload?.companyId,
      purchaseId: action.payload?.purchaseId,
    });
    yield put(actions.fullFillCurrentReferencePurchase({ currentReferencePurchase: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IPurchasesActionTypes.GET_PURCHASES, getPurchases);
  yield takeLatest(IPurchasesActionTypes.GET_CURRENT_PURCHASE, getCurrentPurchase);
  yield takeLatest(
    IPurchasesActionTypes.GET_CURRENT_REFERENCE_PURCHASE,
    getCurrentReferencePurchase
  );
}
