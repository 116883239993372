import _ from 'lodash';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { defaultPagination } from '../../_metronic/constants/general';
import { ID } from '../../_metronic/constants/paths';
import { ActionWithPayload } from '../interface/ActionWithPayload';

export enum ITableActionTypes {
  INIT_TABLE = 'INIT_TABLE',
  TERMINATE_TABLE = 'TERMINATE_TABLE',
  TOGGLE_SELECTION = 'TOGGLE_SELECTION',
  BULK_TOGGLE_SELECTION = 'BULK_TOGGLE_SELECTION',
  SELECT_ALL = 'SELECT_ALL',
  CLEAR_SELECTION = 'CLEAR_SELECTION',
  CHANGE_PAGINATION = 'CHANGE_PAGINATION',
  CHANGE_PAGE = 'CHANGE_PAGE',
  UPDATE_QUERY = 'UPDATE_QUERY',
  CLEAR_QUERY = 'CLEAR_QUERY',
  UPDATE_IDS = 'UPDATE_IDS',
}

const initialAuthState: ITableState = {
  tables: {},
};

export interface ITableState {
  tables?: any;
}

export const reducer = persistReducer(
  { storage, key: 'table', whitelist: [''] },
  (state: ITableState = initialAuthState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case ITableActionTypes.INIT_TABLE:
      case ITableActionTypes.CLEAR_SELECTION:
        let tablesInitialization = { ...state.tables };
        tablesInitialization[action.payload.tableName] = {
          ...tablesInitialization[action.payload.tableName],
          selection: [],
          // pagination: action.payload.initPerPage || defaultPagination,
          // page: 1,
          // ids: action.payload.allDisplayedIds,
        };
        return { ...state, tables: tablesInitialization };
      case ITableActionTypes.UPDATE_IDS:
        let tableData = { ...state.tables };
        tableData[action.payload.tableName] = {
          ...tableData[action.payload.tableName],
          ids: action.payload.allDisplayedIds,
        };
        return { ...state, tables: tableData };

      case ITableActionTypes.CHANGE_PAGINATION:
        let allTables = { ...state.tables };
        allTables[action.payload.tableName].pagination = action.payload.pagination;
        allTables[action.payload.tableName].page = 1;
        return { ...state, tables: allTables };

      case ITableActionTypes.CHANGE_PAGE:
        let currentTables = { ...state.tables };
        currentTables[action.payload.tableName].page = action.payload.page;
        return { ...state, tables: currentTables };

      case ITableActionTypes.UPDATE_QUERY:
        let tableQueryUpdate = { ...state.tables };
        let QTable = tableQueryUpdate?.[action.payload.tableName];
        if (QTable?.query) {
          QTable.query = { ...QTable?.query, [action.payload.queryTerm]: action.payload.value };
        } else {
          QTable.query = { [action.payload.queryTerm]: action.payload.value };
        }

        return { ...state, tables: tableQueryUpdate };

      case ITableActionTypes.CLEAR_QUERY:
        let tableQueryClear = { ...state.tables };
        let CTable = tableQueryClear?.[action.payload.tableName];
        delete CTable.query;
        // CTable.query = {};

        return { ...state, tables: tableQueryClear };

      case ITableActionTypes.TOGGLE_SELECTION:
        // NOTE: -1 means select all
        let tableSelections = { ...state.tables };
        let currentSelection = [...tableSelections?.[action?.payload?.tableName]?.selection];
        let allIds = [...tableSelections?.[action?.payload?.tableName]?.ids];
        let clickedId = action?.payload?.id;
        let tableName = action?.payload?.tableName;

        let newSelection: Array<number> = [];

        if (currentSelection.includes(-1) && clickedId !== -1) {
          newSelection = [...allIds.filter((x) => x !== clickedId && x !== -1)];
        } else if (currentSelection.includes(-1) && clickedId === -1) {
          newSelection = [];
        } else if (!currentSelection.includes(-1) && clickedId === -1) {
          newSelection = _.xor([-1], currentSelection);
        } else {
          newSelection = _.xor([clickedId], currentSelection);
        }

        // if (allIds.length === newSelection.filter((x) => x !== -1).length) {
        //   console.log('5');

        //   newSelection = [...newSelection, -1];
        // }

        tableSelections[tableName] = { ...tableSelections[tableName], selection: newSelection };

        return { ...state, tables: tableSelections };

      case ITableActionTypes.BULK_TOGGLE_SELECTION:
        let tableBulkSelections = { ...state.tables };
        let idList = action?.payload?.idList;
        let tableBulkName = action?.payload?.tableName;
        let newBulkSelection: Array<number> = [];

        if (idList.length > 0) {
          newBulkSelection = [...idList];
        }

        tableBulkSelections[tableBulkName] = {
          ...tableBulkSelections[tableBulkName],
          selection: newBulkSelection,
        };
        return { ...state, tables: tableBulkSelections };

      case ITableActionTypes.TERMINATE_TABLE:
        let selectedTables = { ...state.tables };
        delete selectedTables?.[action.payload.tableName];
        return { ...state, tables: selectedTables };

      default:
        return state;
    }
  }
);

export const actions = {
  initTable: ({
    tableName,
    allDisplayedIds,
    initPerPage,
  }: {
    tableName: string;
    allDisplayedIds: Array<number | ID>;
    initPerPage: number | undefined;
  }) => ({
    type: ITableActionTypes.INIT_TABLE,
    payload: { tableName, allDisplayedIds, initPerPage },
  }),
  updateIds: ({
    tableName,
    allDisplayedIds,
  }: {
    tableName: string;
    allDisplayedIds: Array<number | ID>;
  }) => ({
    type: ITableActionTypes.UPDATE_IDS,
    payload: { tableName, allDisplayedIds },
  }),
  changeTablePagination: ({
    tableName,
    pagination,
  }: {
    tableName: string;
    pagination: number;
  }) => ({
    type: ITableActionTypes.CHANGE_PAGINATION,
    payload: { tableName, pagination },
  }),
  changePage: ({ tableName, page }: { tableName: string; page: number }) => ({
    type: ITableActionTypes.CHANGE_PAGE,
    payload: { tableName, page },
  }),
  updateQuery: ({
    tableName,
    queryTerm,
    value,
  }: {
    tableName: string;
    queryTerm: string;
    value: any;
  }) => ({
    type: ITableActionTypes.UPDATE_QUERY,
    payload: { tableName, queryTerm, value },
  }),
  clearQuery: ({ tableName }: { tableName: string }) => ({
    type: ITableActionTypes.CLEAR_QUERY,
    payload: { tableName },
  }),

  terminateTable: ({ tableName }: { tableName: string }) => ({
    type: ITableActionTypes.TERMINATE_TABLE,
    payload: { tableName },
  }),
  toggleSelection: ({ id, tableName }: { id: number; tableName: string }) => ({
    type: ITableActionTypes.TOGGLE_SELECTION,
    payload: { id, tableName },
  }),
  clearSelection: ({ tableName }: { tableName: string }) => ({
    type: ITableActionTypes.CLEAR_SELECTION,
    payload: { tableName },
  }),
  bulkToggleSelection: ({ idList, tableName }: { idList: Array<number>; tableName: string }) => ({
    type: ITableActionTypes.BULK_TOGGLE_SELECTION,
    payload: { idList, tableName },
  }),
};
