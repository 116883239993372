/**
 * @name PrivateRoutes
 * @exports
 * @description
 * @type {*}
 *
 */
export const PrivateRoutes = {
  /* -------------------------------------------------------------------------- */
  /* NOTE: (IN A COMPANY) ROUTES                                                */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- USER PRFOILE --------------------------- */
  UserLinkedProfile: `/c/:companySlug/user-profile`,
  /* --------------------------------- Company -------------------------------- */
  companyHomePage: `/c/:companySlug/dashboard`,
  Companies: '/c/:companySlug/companies',
  companyDetailsView: '/c/:companySlug/settings/companydetails',
  companyDetailsEdit: '/c/:companySlug/settings/companydetails/edit',
  // companyDetailsProfile: '/c/:companySlug/details/:detailTab',
  // companyDetailsProfileEdit: '/c/:companySlug/details/:detailTab/edit',
  // companyDetailsBranding: '/c/:companySlug/details/branding',
  /* -------------------------------- Roles -------------------------------- */
  companyRoles: '/c/:companySlug/settings/roles',
  companyRoleAdd: '/c/:companySlug/settings/roles/add',
  companyRoleView: `/c/:companySlug/settings/roles/:roleId`,
  companyRoleEdit: `/c/:companySlug/settings/roles/:roleId/edit`,
  /* -------------------------------- Company Added --------------------------- */
  NewCompanyAdded: '/c/:companySlug/settings/company-added',
  /* ---------------------------------- users --------------------------------- */
  Users: '/c/:companySlug/company-management/users',
  UsersEdit: '/c/:companySlug/company-management/users/:userId/edit',
  UsersView: '/c/:companySlug/company-management/users/:userId',
  UsersAdd: '/c/:companySlug/company-management/users/invite-users',
  /* --------------------------------- clients third_party -------------------------------- */
  Clients: '/c/:companySlug/company-management/clients-and-vendors',
  ClientsEdit: '/c/:companySlug/company-management/clients-and-vendors/:clientId/edit',
  ClientsView: '/c/:companySlug/company-management/clients-and-vendors/:clientId',
  ClientsAdd: '/c/:companySlug/company-management/clients-and-vendors/add',
  ClientsImport: '/c/:companySlug/company-management/clients-and-vendors/import',
  /* --------------------------------- Groups --------------------------------- */
  UsersGroups: '/c/:companySlug/groups/users/users-groups',
  UsersGroupAddEdit: '/c/:companySlug/groups/users/cu-users-group',
  ClientsGroups: '/c/:companySlug/groups/clients/clients-groups',
  ClientsGroupAddEdit: '/c/:companySlug/groups/clients/cu-clients-group',
  /* -------------------------------- products -------------------------------- */
  Products: '/c/:companySlug/company-management/products',
  ProductsEdit: '/c/:companySlug/company-management/products/:productId/edit',
  ProductsView: '/c/:companySlug/company-management/products/:productId',
  ProductsAdd: '/c/:companySlug/company-management/products/create',
  /* -------------------------------- invoices -------------------------------- */
  // Invoices: '/c/:companySlug/invoices-tax-module/invoices',
  // InvoicesImport: '/c/:companySlug/invoices-tax-module/invoices/import',
  // InvoicesEdit: '/c/:companySlug/invoices-tax-module/invoices/:invoiceId/edit',
  // SalesInvoicesAdd: '/c/:companySlug/invoices-tax-module/invoices/sale/create',
  // PurchasesInvoicesAdd: '/c/:companySlug/invoices-tax-module/invoices/purchase/create',
  // ReturnSalesInvoicesAdd: '/c/:companySlug/invoices-tax-module/invoices/createreturnsale',
  // ReturnPurchasesInvoicesAdd: '/c/:companySlug/invoices-tax-module/invoices/createreturnpurchase',
  // SalesInvoicesView: '/c/:companySlug/invoices-tax-module/invoices/sale/:invoiceId',
  // PurchasesInvoicesView: '/c/:companySlug/invoices-tax-module/invoices/purchase/:invoiceId',
  // ReturnSalesInvoicesView: '/c/:companySlug/invoices-tax-module/invoices/returnsale/:invoiceId',
  // ReturnPurchasesInvoicesView:
  // '/c/:companySlug/invoices-tax-module/invoices/returnpurchase/:invoiceId',
  // SalesInvoicesEdit: '/c/:companySlug/invoices-tax-module/invoices/sale/:invoiceId/edit',
  // PurchasesInvoicesEdit: '/c/:companySlug/invoices-tax-module/invoices/purchase/:invoiceId/edit',
  // ReturnSalesInvoicesEdit:
  //   '/c/:companySlug/invoices-tax-module/invoices/returnsale/:invoiceId/edit',
  // ReturnPurchasesInvoicesEdit:
  // '/c/:companySlug/invoices-tax-module/invoices/returnpurchase/:invoiceId/edit',
  /* -------------------------------- Sales invoices -------------------------------- */
  SalesInvoices: '/c/:companySlug/financial-management/sales-invoices',
  SalesInvoicesImport: '/c/:companySlug/financial-management/sales-invoices/import',
  SalesInvoicesAdd: '/c/:companySlug/financial-management/sales-invoices/sale/create',
  ReturnSalesInvoicesAdd: '/c/:companySlug/financial-management/sales-invoices/createreturnsale',
  SalesInvoicesView: '/c/:companySlug/financial-management/sales-invoices/sale/:invoiceId',
  ReturnSalesInvoicesView:
    '/c/:companySlug/financial-management/sales-invoices/returnsale/:invoiceId',
  SalesInvoicesEdit: '/c/:companySlug/financial-management/sales-invoices/sale/:invoiceId/edit',
  ReturnSalesInvoicesEdit:
    '/c/:companySlug/financial-management/sales-invoices/returnsale/:invoiceId/edit',
  /* -------------------------------- Purchases invoices -------------------------------- */
  PurchasesInvoices: '/c/:companySlug/financial-management/purchases-invoices',
  PurchasesInvoicesImport: '/c/:companySlug/financial-management/purchases-invoices/import',
  PurchasesInvoicesAdd: '/c/:companySlug/financial-management/purchases-invoices/purchase/create',
  ReturnPurchasesInvoicesAdd:
    '/c/:companySlug/financial-management/purchases-invoices/createreturnpurchase',
  PurchasesInvoicesView:
    '/c/:companySlug/financial-management/purchases-invoices/purchase/:invoiceId',
  ReturnPurchasesInvoicesView:
    '/c/:companySlug/financial-management/purchases-invoices/returnpurchase/:invoiceId',
  PurchasesInvoicesEdit:
    '/c/:companySlug/financial-management/purchases-invoices/purchase/:invoiceId/edit',
  ReturnPurchasesInvoicesEdit:
    '/c/:companySlug/financial-management/purchases-invoices/returnpurchase/:invoiceId/edit',
  /* --------------------------------invoice line items -------------------------------- */
  LineItems: '/c/:companySlug/financial-management/lineitems',
  LineItemsView: '/c/:companySlug/financial-management/lineitems/:lineItemId',
  /* -------------------------------- templates -------------------------------- */
  Templates: '/c/:companySlug/templates',
  TemplateCreate: '/c/:companySlug/templates/create',
  TemplateUpdate: '/c/:companySlug/templates/update/:templateId',
  TemplatesEdit: '/c/:companySlug/templates/:productId/edit',
  TemplatesView: '/c/:companySlug/templates/:productId',
  TemplatesAdd: '/c/:companySlug/templates/add',
  TemplatePreviewConfigurations: '/c/:companySlug/templates/preview-configurations/:templateId',
  TemplateEditConfigurations: '/c/:companySlug/templates/preview-configurations/:templateId/edit',
  CustomVariablesWrapper: '/c/:companySlug/custom-variables',
  CustomVariablesWrapperCreate: '/c/:companySlug/custom-variables/create',
  /* -------------------------------- documents -------------------------------- */
  Documents: '/c/:companySlug/documents',
  DocumentCreate: '/c/:companySlug/documents/create',
  DocumentUpdate: '/c/:companySlug/documents/update/:documentId',
  DocumentsEdit: '/c/:companySlug/documents/:productId/edit',
  DocumentsView: '/c/:companySlug/documents/:productId',
  DocumentsAdd: '/c/:companySlug/documents/add',
  DocumentsReview: '/c/:companySlug/doc-review',
  /* -------------------------------- sales -------------------------------- */
  Sales: '/c/:companySlug/tax-module/sales',
  SalesEdit: '/c/:companySlug/tax-module/sales/:salesId/edit',
  SalesView: '/c/:companySlug/tax-module/sales/:salesId',
  SalesCreate: '/c/:companySlug/tax-module/sales/create',
  SalesImport: '/c/:companySlug/tax-module/sales/import',
  /* -------------------------------- sales return -------------------------------- */
  SalesReturn: '/c/:companySlug/tax-module/salesreturn',
  SalesReturnView: '/c/:companySlug/tax-module/salesreturn/:salesId',
  SalesReturnEdit: '/c/:companySlug/tax-module/salesreturn/:salesId/edit',
  /* -------------------------------- sales invoice line items -------------------------------- */
  // SalesLineItems: '/c/:companySlug/financial-management/lineitems/sales',
  // SalesLineItemsView: '/c/:companySlug/financial-management/lineitems/sales/:salesId',
  /* -------------------------------- purchases -------------------------------- */
  Purchases: '/c/:companySlug/tax-module/purchases',
  PurchasesEdit: '/c/:companySlug/tax-module/purchases/:purchaseId/edit',
  PurchasesView: '/c/:companySlug/tax-module/purchases/:purchaseId',
  PurchasesCreate: '/c/:companySlug/tax-module/purchases/create',
  PurchasesImport: '/c/:companySlug/tax-module/purchases/import',
  /* -------------------------------- purchases return -------------------------------- */
  PurchasesReturn: '/c/:companySlug/tax-module/purchasesreturn',
  PurchasesReturnView: '/c/:companySlug/tax-module/purchasesreturn/:purchaseId',
  PurchasesReturnEdit: '/c/:companySlug/tax-module/purchasesreturn/:purchaseId/edit',
  /* -------------------------------- sales invoice line items -------------------------------- */
  // PurchasesLineItems: '/c/:companySlug/financial-management/lineitems/purchases',
  // PurchasesLineItemsView: '/c/:companySlug/financial-management/lineitems/purchases/:purchaseId',
  /* -------------------------------- Reports -------------------------------- */
  Reports: '/c/:companySlug/tax-module/reports',
  ReportsEdit: '/c/:companySlug/tax-module/reports/:reportsId/edit',
  ReportsView: '/c/:companySlug/tax-module/reports/:reportsId',
  ReportsCreate: '/c/:companySlug/tax-module/reports/create',
  /* -------------------------------- Invoices Reports -------------------------------- */
  InvoicesReports: '/c/:companySlug/invoices-tax-module/reports',
  InvoicesReportsEdit: '/c/:companySlug/invoices-tax-module/reports/:reportsId/edit',
  InvoicesReportsView: '/c/:companySlug/invoices-tax-module/reports/:reportsId',
  InvoicesReportsCreate: '/c/:companySlug/invoices-tax-module/reports/create',
  /* -------------------------------- Withholding Reports -------------------------------- */
  WithholdingReports: '/c/:companySlug/tax-module/withholdingreports',
  WithholdingReportsEdit: '/c/:companySlug/tax-module/withholdingreports/:reportsId/edit',
  WithholdingReportsView: '/c/:companySlug/tax-module/withholdingreports/:reportsId',
  WithholdingReportsCreate: '/c/:companySlug/tax-module/withholdingreports/create',
  /* -------------------------------- Invoices Withholding Reports -------------------------------- */
  InvoicesWithholdingReports: '/c/:companySlug/invoices-tax-module/withholdingreports',
  InvoicesWithholdingReportsCreate: '/c/:companySlug/invoices-tax-module/withholdingreports/create',
  InvoicesWithholdingReportsView:
    '/c/:companySlug/invoices-tax-module/withholdingreports/:reportsId',
  InvoicesWithholdingReportsEdit:
    '/c/:companySlug/invoices-tax-module/withholdingreports/:reportsId/edit',
  /* -------------------------------- Transactions -------------------------------- */
  Transactions:
    '/c/:companySlug/tax-module/reports/:reportsId/invoicestype/:invoicesType/transactions/:transactionCodeType',
  InvoicesReportsTransactions:
    '/c/:companySlug/invoices-tax-module/reports/:reportsId/invoicestype/:invoicesType/transactions/:transactionCodeType',
  WithholdingTransactions:
    '/c/:companySlug/tax-module/withholdingreports/:reportsId/invoicestype/:invoicesType/transactions/:transactionCodeType',
  InvoicesWithholdingReportsTransactions:
    '/c/:companySlug/invoices-tax-module/withholdingreports/:reportsId/invoicestype/:invoicesType/transactions/:transactionCodeType',
  /* ----------------------------------- Log ---------------------------------- */
  AuditLog: '/c/:companySlug/settings/audit-log',
  /* -------------------------------------------------------------------------- */
  /* NOTE: (Out of a company) ROUTES                                            */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- documents to sign --------------------------- */
  DocumentToViewViaCompany: '/c/:companySlug/d/documents',
  DocumentsToSign: '/d/documents',
  TakingAction: '/d/documents/:documentId',
  /* --------------------------------- General -------------------------------- */
  Homepage: '/',
  companiesList: '/companies',
  UserProfileView: '/user-profile',
  UserProfileEdit: '/user-profile/edit',
  UserCompaniesView: '/user-companies',
  // Menus Reidrections and others
  Builder: '/builder',
  verification: '/verification',
};

export const AdminRoutes = {
  // Menu Items
  AdminDashboard: '/admin/dashboard',
  Companies: '/admin/companies',
  AdminCompanyDetailsView: '/admin/companies/:companyId',
  CompaniesDeletionRequests: '/admin/deletion-requests',
  SubscriptionPlans: '/admin/subscription-plans',
  createSubscriptionPlans: '/admin/subscription-plans/create',
  ViewSubscriptionPlans: '/admin/subscription-plans/:subscriptionPlanId',
  EditSubscriptionPlans: '/admin/subscription-plans/:subscriptionPlanId/edit',
  Users: '/admin/users',
  UserInfo: '/admin/users/:userId',
  UserEdit: '/admin/users/:userId/edit',
  AboutUs: '/admin/about-us',
  TermsConditions: '/admin/terms-conditions',
  VatRates: '/admin/vat-rates',
  VatRatesCreate: '/admin/vat-rates/create',
  VatRatesView: '/admin/vat-rates/:vatRateId',
  VatRatesEdit: '/admin/vat-rates/:vatRateId/edit',
  // VatRateDetailsCreate: '/admin/vat-rates/:detailTab/create',
  // VatRatesView: '/admin/vat-rates/:vatRateId',
  CodeRules: '/admin/code-rules',
  ViewCodeRules: '/admin/code-rules/:codeRuleId',
  EditCodeRules: '/admin/code-rules/:codeRuleId/edit',
  CodeRulesCreate: '/admin/code-rule/create',
  Transactions: '/admin/transactions',
  TransactionInfo: '/admin/transactions/:transactionId',
  TransactionEdit: '/admin/transactions/:transactionId/edit',
};

export const PublicRoutes = {
  login: '/auth/login',
  opt: '/auth/opt',
  signup: '/auth/signup',
  verify: '/verify',
  forgetPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  signDocument: '/document',
  about: '/about',
  TermsAndConditions: '/terms-conditions',
  /* ----------------------------- Client document ---------------------------- */
  NonRegisteredClientDocument: '/p/:type/documents/:documentId',
};
