import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img src={toAbsoluteUrl('/media/logos/logo-compact.svg')} alt='AHDH' />
      <span>Loading ...</span>
    </div>
  )
}
