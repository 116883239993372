import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SignaturePad from 'react-signature-canvas';

export class HandWritingSignature extends Component {
  state = { trimmedDataURL: null, color: 'black' };

  sigPad = {};

  clear = () => {
    this.sigPad.clear();
  };

  trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png') });
  };

  convertBase64ToBlob(base64Image) {
    // Split into two parts
    const parts = base64Image.split(';base64,');

    // Hold the content type
    const imageType = parts[0].split(':')[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  render() {
    const { color, trimmedDataURL } = this.state;

    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <div className='mb-3'>
          {!this.props.isOneTimeSignature && (
            <input
              type='text'
              className='form-control form-control-lightSkyBlue fs-4 py-1  text-gray-700 placeholder-gray-400 mw-500px my-5'
              value={this.props.name}
              onInput={(e) => this.props.setName(e.target.value)}
              placeholder={this.props.formatMessage({ id: 'Signature Title' })}
            />
          )}
          <div className='btn btn-outlined-danger w-25 p-0  mx-1'>
            <input type='color' onChange={(e) => this.setState({ color: e.target.value })} />
          </div>
          <button className='btn btn-outlined-danger w-25 mx-1' onClick={this.clear}>
            <FormattedMessage id='Clear' />
          </button>
          <button className='btn btn-outlined-primary w-25 mx-1' onClick={this.trim}>
            <FormattedMessage id='Confirm' />
          </button>
        </div>
        <div className='sigCanvas'>
          <SignaturePad
            penColor={color}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </div>

        <div className='mt-2'>{trimmedDataURL ? <img alt='' src={trimmedDataURL} /> : null}</div>

        {trimmedDataURL && (
          <button
            className='btn btn-outlined-primary w-25 mt-3'
            onClick={() => this.props.uploadSignature(this.convertBase64ToBlob(trimmedDataURL))}
            disabled={!trimmedDataURL || this.props.isLoading}
          >
            <FormattedMessage id='Save' />
          </button>
        )}
      </div>
    );
  }
}
