import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ID } from '../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { SalesInvoiceListModel } from '../types/salesInvoiceList';
// import { user } from '../../../types/user';
import { getSalesInvoiceList, getCurrentSalesInvoiceRequest } from './SalesInvoicesCrud';

export enum ISalesInvoicesActionTypes {
  GET_SALES_INVOICES = 'GET_SALES_INVOICES',
  FULLFULL_SALES_INVOICES = 'FULLFULL_SALES_INVOICES',
  GET_CURRENT_SALE_INVOICE = 'GET_CURRENT_SALE_INVOICE',
  FULLFULL_CURRENT_SALE_INVOICE = 'FULLFULL_CURRENT_SALE_INVOICE ',
}

const initialInvoicesState: ISalesInvoicesState = {
  SalesInvoices: undefined,
  currentSaleInvoice: undefined,
};

export interface ISalesInvoicesState {
  SalesInvoices?: SalesInvoiceListModel;
  currentSaleInvoice?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_sales_invoices', whitelist: [''] },
  (
    state: ISalesInvoicesState = initialInvoicesState,
    action: ActionWithPayload<ISalesInvoicesState>
  ) => {
    switch (action.type) {
      case ISalesInvoicesActionTypes.FULLFULL_SALES_INVOICES:
        return { ...state, SalesInvoices: action.payload?.SalesInvoices };
      case ISalesInvoicesActionTypes.FULLFULL_CURRENT_SALE_INVOICE:
        return { ...state, currentSaleInvoice: action.payload?.currentSaleInvoice };
      default:
        return state;
    }
  }
);

type CompanyInvoicesSearch = { companyId: ID | any; params?: any };
type CurrentInvoiceBasicData = { companyId: ID | any; invoiceId?: ID | any };

export const actions = {
  getSalesInvoices: ({ companyId, params }: CompanyInvoicesSearch) => ({
    type: ISalesInvoicesActionTypes.GET_SALES_INVOICES,
    payload: { companyId, params },
  }),
  fulfillSalesInvoices: ({ SalesInvoices }: ISalesInvoicesState) => ({
    type: ISalesInvoicesActionTypes.FULLFULL_SALES_INVOICES,
    payload: { SalesInvoices },
  }),
  getCurrentSaleInvoice: ({ companyId, invoiceId }: CurrentInvoiceBasicData) => ({
    type: ISalesInvoicesActionTypes.GET_CURRENT_SALE_INVOICE,
    payload: { companyId, invoiceId },
  }),
  fulfillCurrentSaleInvoice: ({ currentSaleInvoice }: ISalesInvoicesState) => ({
    type: ISalesInvoicesActionTypes.FULLFULL_CURRENT_SALE_INVOICE,
    payload: { currentSaleInvoice },
  }),
};

export function* getSalesInvoicesSaga(action: ActionWithPayload<CompanyInvoicesSearch>) {
  try {
    // Getting SalesInvoices request
    const { data } = yield getSalesInvoiceList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing invoices to store
    yield put(actions.fulfillSalesInvoices({ SalesInvoices: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentSaleInvoice(action: ActionWithPayload<CurrentInvoiceBasicData>) {
  try {
    // Getting SalesInvoices request
    const { data } = yield getCurrentSalesInvoiceRequest({
      companyId: action.payload?.companyId,
      invoiceId: action.payload?.invoiceId,
    });
    // Storing invoices to store
    yield put(actions.fulfillCurrentSaleInvoice({ currentSaleInvoice: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ISalesInvoicesActionTypes.GET_SALES_INVOICES, getSalesInvoicesSaga);
  yield takeLatest(ISalesInvoicesActionTypes.GET_CURRENT_SALE_INVOICE, getCurrentSaleInvoice);
}
