import React, { FC } from 'react';
import { FormatMessage } from '../../../i18n/formatMessage';

const DemosToggleDrawer: FC = () => (
  <button
    id='kt_engage_demos_toggle'
    className='engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0'
    data-bs-toggle='tooltip'
    data-bs-placement='left'
    data-bs-dismiss='click'
    data-bs-trigger='hover'
  >
    <span id='kt_engage_demos_label'>
      <FormatMessage id='Comments' />
    </span>
  </button>
);

export { DemosToggleDrawer };
