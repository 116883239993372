import { FC, useEffect, useMemo, useState } from 'react'; //useMemo,
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'; //, useParams

// import * as TableRedux from '../../../store/TableRedux';
import * as VatRatesRedux from '../../../modules/admins/vat-rates/store/VatRatesRedux';

import { PageTitle } from '../../../../_metronic/layout/core';
import { usePermissions } from '../../../hooks/usePermissions';
import { RootState } from '../../../../setup/redux/Store';
import { TableActions, TableData, TableSelection } from '../../../shared/tables/classes'; // DateViewer,
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { LOCALE } from '../../../../_metronic/helpers/typescript';

import {
  IVatRate,
  VatRatesDataListModel,
} from '../../../modules/admins/vat-rates/types/VatRatesModel';
import {
  // TransactionTypeMap,
  VatRateTypesMap,
  FilterVatRateTypeMap,
  // TaxAbilityTypesMap,
} from '../../../../_metronic/constants/general';
import { SerachFilters } from '../../../shared/SearchFilters';
import * as TableRedux from '../../../store/TableRedux';
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';

import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import { EditItem } from '../../../shared/tables/actions/EditItem';
import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
import { CommonTable2 } from '../../../shared/tables/Table2';
import { KTSVG } from '../../../../_metronic/helpers';
import { Button } from '../../../shared/Button';
import { Portal } from '../../../../_metronic/partials';
import AhadDialog from '../../../../setup/shared/AhadDialog';
import { axiosInstance } from '../../../../network/apis';
import { toast } from 'react-toastify';

const TABLE_NAME = 'sys_vat_rates_list';

const headerItems = [
  { id: 'ID', maxWidth: 150, width: 150 },
  { id: 'Code', maxWidth: 240, width: 230 },
  { id: 'Code type', maxWidth: 240, width: 230 },
  { id: 'Account type', maxWidth: 240, width: 230 },
  { id: 'Code name', maxWidth: 240, width: 230 },
  // { id: 'Tax ability', maxWidth: 230, width: 230 },
  { id: 'Tax rate', maxWidth: 230, width: 230 },
  // { id: 'Type of Template', maxWidth: 400, width: 400 },
  // { id: 'Creation Date', maxWidth: 200, width: 200 },
  { id: 'Actions', maxWidth: 150, width: 150 },
];

export const VatRates: FC = () => {
  return (
    <div>
      <VatRatesTable />
    </div>
  );
};

export const VatRatesTable: FC = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage, locale } = useIntl(); //
  // const params: any = useParams();
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [confirmationEdit, setConfirmationEdit] = useState<{
    show: boolean;
    id: number | null;
    message: string;
    status?: boolean | null;
  }>({
    show: false,
    id: null,
    message: '',
    status: null,
  });
  /* -------------------------- Bring Data from store ------------------------- */

  const vatRatesList: VatRatesDataListModel = useSelector<RootState>((state) => {
    return state.SYS_vat_rates.vat_rates;
  }, shallowEqual) as VatRatesDataListModel;

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  const typesOptions = useMemo(
    () =>
      Array.from(FilterVatRateTypeMap.values())
        .flat()
        .map((option) => toSelectOption(option, locale)),
    [locale]
  );
  // const TransactionTypesOptions = useMemo(
  //   () =>
  //     Array.from(TransactionTypeMap.values())
  //       .flat()
  //       .map((option) => toSelectOption(option, locale)),
  //   [locale]
  // );

  function RequestData() {
    const table = tables[TABLE_NAME];
    dispatch(
      VatRatesRedux.actions.getVatRates({
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  useEffect(() => {
    RequestData();
  }, [table?.page, table?.pagination]);

  const toggleStatus = (id: number, status: boolean) => {
    const formData = new FormData();
    formData.append('is_active', status ? '1' : '0');
    formData.append('_method', 'PUT');

    axiosInstance
      .post(`/admin/vat_rates/${id}/set-active`, formData)
      .then((response) => {
        toast.success(formatMessage({ id: 'Vat Rate has been updated successfully!' }), {
          toastId: 'Vat Rate has been updated successfully!',
        });
        RequestData();
      })
      .catch((err) => {
        const data = err?.response?.data?.errors;
        const mergedErrors: any = Object.values(data ?? {}).reduce(
          (accumulator: any, currentValue) => accumulator.concat(currentValue),
          []
        );
        mergedErrors.map((error: string, index: number) => toast.error(error, { toastId: index }));
      });
  };

  const rowItems = vatRatesList?.data.map((vatRate: IVatRate, index) => {
    const VatRateTypesMapTranslated = {
      text: formatMessage({ id: `${VatRateTypesMap.get(vatRate.type)}` }) || '',
    };
    const TransactionTypesMapTranslated = {
      text: vatRate.name ? vatRate.name[locale as LOCALE] : '',
    };
    // const TaxAbilityTypesMapTranslated = {
    //   text: formatMessage({ id: `${TaxAbilityTypesMap.get(vatRate.tax_ability)}` }) || '',
    // };
    return [
      new TableSelection({
        display: userCan('delete-template'),
        id: vatRate.id,
        tableName: TABLE_NAME,
      }),
      new TableData({ text: `${vatRate.id}` }),
      new TableData({ text: vatRate?.code }),
      new TableData({
        text: `${vatRate?.code_type[locale as LOCALE] ? vatRate?.code_type[locale as LOCALE] : ''}`,
      }),
      new TableData(VatRateTypesMapTranslated),
      new TableData(TransactionTypesMapTranslated),
      // new TableData(TaxAbilityTypesMapTranslated),
      new TableData({ text: vatRate?.tax_rate }),
      // new TableData({ text: vatRate?.description[locale as LOCALE] }),
      new TableActions(
        (
          <div className='d-flex flex-row'>
            <ViewItem
              title={$t('View')}
              onClick={() => history.push(`/admin/vat-rates/${vatRate.id}`)}
            />
            {(vatRate.type === 1 || vatRate.type === 2) && (
              <EditItem
                title={$t('Edit')}
                onClick={() => history.push(`/admin/vat-rates/${vatRate.id}/edit`)}
              />
            )}
            {/* <DeleteItem title={$t('Delete')} /> */}
            <DeleteItem
              id={vatRate.id}
              title={$t('Delete')}
              messageId={
                vatRate?.can_delete
                  ? 'Are you sure you want to delete this vat rate?'
                  : vatRate?.type === 2 || vatRate?.type === 4
                  ? "This VAT rate has already been used in a purchase before, and can't be deleted."
                  : "This VAT rate has already been used in a sale before, and can't be deleted."
              }
              showButtonGroup={vatRate?.can_delete}
              setDeleteItemId={setDeleteItemId}
              deleteItemId={deleteItemId}
              deleteURI={`admin/vat_rates/${vatRate.id}`}
              payload={vatRate.id}
              successMessage={'Vat rate has been deleted successfully!'}
              afterSucessRequest={() => RequestData()}
            />

            {vatRate?.is_active === 1 && (
              <div>
                <a
                  className='btn btn-icon btn-bg-light btn-color-primary btn-sm me-1'
                  title={$t('Enabled')}
                  onClick={() => {
                    setConfirmationEdit({
                      show: true,
                      id: vatRate.id as number | null,
                      message:
                        'When this code is disabled, users will not be able to use it to create their invoices, are you sure you want to continue?',
                      status: false,
                    });
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-3' />
                </a>
              </div>
            )}

            {vatRate?.is_active === 0 && (
              <div>
                <a
                  className='btn btn-icon btn-bg-light btn-color-danger btn-sm me-1'
                  title={$t('Hidden')}
                  onClick={() => {
                    // toggleStatus(vatRate.id, true);
                    setConfirmationEdit({
                      show: true,
                      id: vatRate.id as number | null,
                      message:
                        'When this code is enabled, users will be able to use it to create their invoices, are you sure you want to continue?',
                      status: true,
                    });
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen034.svg' className='svg-icon-3' />
                </a>
              </div>
            )}
          </div>
        )
      ),
    ];
  });

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(VatRatesRedux.actions.fullFillVatRates({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */

  return (
    <>
      {' '}
      {confirmationEdit.show && (
        <Portal className='full_modal'>
          <AhadDialog
            titleId={'Confirm'}
            closeCallBack={() => {
              setConfirmationEdit({ show: false, id: null, message: '' });
            }}
            specialContainerClass='dialog-content w-600px'
          >
            <div className='d-flex flex-column'>
              {
                <div className='d-flex align-items-center  rounded p-5'>
                  <span className='svg-icon svg-icon-danger me-5'></span>
                  <div className='flex-grow-1 me-2'>
                    <p className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                      <FormattedMessage id={confirmationEdit.message} />
                    </p>
                  </div>
                </div>
              }

              <>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        type='button'
                        // disabled={formik.isSubmitting}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleStatus(
                            confirmationEdit.id as number,
                            confirmationEdit.status as boolean
                          );
                          setConfirmationEdit({ show: false, id: null, message: '' });
                        }}
                      >
                        <span className='indicator-label'>
                          <FormattedMessage id={'Confirm'} />
                        </span>
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={() => {
                          setConfirmationEdit({ show: false, id: null, message: '' });
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </AhadDialog>
        </Portal>
      )}
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}

          {/*  ------------------------ start Action button ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            {/* begin::Menu */}

            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
            </button>

            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                  {$t('Actions')}{' '}
                </div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}
              {/* begin::Menu item */}
              <div className='menu-item px-3 py-3'>
                <Button
                  fa='mail-bulk'
                  className='menu-link px-3 btn w-md-190px'
                  onClick={() => {
                    history.push(`/admin/vat-rates/create`);
                  }}
                  nameId='Create Vat Rate'
                  testid='create-vat-code-button'
                  faIconMenu={true}
                />
              </div>{' '}
              {/* end::Menu item */}
            </div>
            {/* end::Menu 2 */}
            {/* end::Menu */}
          </div>

          {/*  ------------------------ End Action button ------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'type',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'type',
                      })
                    ),
                  placeholderId: 'Account type',
                  options: typesOptions,
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}

        <CommonTable2
          className='pb-5 pb-xl-8'
          headerWithCheckbox={false}
          headerItems={headerItems}
          rowItems={rowItems}
          tableName={TABLE_NAME}
          initPerPage={20}
          allDisplayedIds={vatRatesList?.data?.map((vatRate) => vatRate.id) || []}
          meta={vatRatesList}
        />
      </div>
    </>
  );
};

const VatRatesWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Vat Rates' })}</PageTitle>
      {/* <PageTitle breadcrumbs={[{ title: 'Vat Rates', path: '/admin/vat-rates', isActive: true }]}>
        {formatMessage({ id: 'Vat Rates' })}
      </PageTitle> */}
      <VatRates />
    </div>
  );
};

export { VatRatesWrapper };
