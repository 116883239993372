import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyPurchasesInvoices = endpoints.companyPurchasesInvoices;
export const companySinglePurchasesInvoice = endpoints.companySinglePurchasesInvoice;

export function getPurchasesInvoiceList({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyPurchasesInvoices(companyId), { params });
}

export function getCurrentPurchasesInvoiceRequest({
  companyId,
  invoiceId,
}: {
  companyId: ID;
  invoiceId: ID;
}) {
  return axiosInstance.get(companySinglePurchasesInvoice(companyId, invoiceId));
}
