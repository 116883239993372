import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ID } from '../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { getLogList } from './LogsCrud';

export enum LogsActionTypes {
  GET_LOGS = 'GET_LOGS',
  GET_SINGL_LOG = 'GET_SINGL_LOG',
  FULLFULL_Logs = 'FULLFULL_Logs',
  FULLFILL_LOGS = 'FULLFILL_LOGS',
}

const initialLogsState: ILogsState = {
  Logs: undefined,
};

export interface ILogsState {
  Logs?: [];
}

export const reducer = persistReducer(
  { storage, key: 'Logs', whitelist: [''] },
  (state: ILogsState = initialLogsState, action: ActionWithPayload<ILogsState>) => {
    switch (action.type) {
      case LogsActionTypes.FULLFULL_Logs:
        return { ...state, Logs: action.payload?.Logs };
      default:
        return state;
    }
  }
);

type CompanyLogsSearch = { companyId: ID | any; params?: any };

export const actions = {
  getLogs: ({ companyId, params }: CompanyLogsSearch) => ({
    type: LogsActionTypes.GET_LOGS,
    payload: { companyId, params },
  }),
  fulfillLogs: ({ Logs }: ILogsState) => ({
    type: LogsActionTypes.FULLFULL_Logs,
    payload: { Logs },
  }),
};

export function* getLogsSaga(action: ActionWithPayload<CompanyLogsSearch>) {
  try {
    // Getting Logs request
    const { data } = yield getLogList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing logs to store
    yield put(actions.fulfillLogs({ Logs: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(LogsActionTypes.GET_LOGS, getLogsSaga);
}
