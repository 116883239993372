import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyInvoicesReportTransactionsDetails =
  endpoints.companyInvoicesReportTransactionsDetails;

export function getAllInvoicesReportTransactionsDetails({
  companyId,
  reportId,
  params,
}: {
  companyId: ID;
  reportId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyInvoicesReportTransactionsDetails(companyId, reportId), {
    params,
  });
}
