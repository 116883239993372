import clsx from 'clsx';
import { useLayout } from '../../core';
import { Logo } from '../../../../app/shared/LogoWithCompany';
import { HeaderUserMenuContainer } from './HeaderUserMenuContainer';
import SwitchButton from './switchButton';
import useWindowSize from '../../../../app/hooks/useWindowSize';

export function CompaniesHeaderWrapper() {
  const { classes, attributes } = useLayout();
  const { width } = useWindowSize();

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div className='logo-and-toggle m-5 m-lg-5' id='kt_aside_logo'>
        {/* begin::Logo */}
        <Logo />
        {/* begin::Aside toggler */}
      </div>
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-end align-items-center'
        )}
      >
        <div className='d-flex align-items-stretch justify-content-end align-items-center flex-lg-grow-1 '>
          <div
            className={
              width >= 1024 ? 'd-flex align-items-center justify-content-end me-3' : 'd-none'
            }
          >
            <SwitchButton />
          </div>
          <HeaderUserMenuContainer />
        </div>
      </div>
    </div>
  );
}
