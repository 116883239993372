import Cookies from 'js-cookie';
import React, { FC, createContext, useContext } from 'react';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

type Props = {
  selectedLang: 'ar' | 'en';
};
const initialState: Props = {
  selectedLang: 'ar',
};

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls) as Props;
    } catch (err) {
      console.error(err);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang: string) {
  Cookies.set('locale', lang);
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext<Props>(initialState);

const useLang = () => {
  return useContext(I18nContext).selectedLang;
};

const MetronicI18nProvider: FC = ({ children }) => {
  const lang = getConfig();
  Cookies.set('locale', lang.selectedLang);
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { MetronicI18nProvider, useLang };
