import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { RootState } from '../../../setup/redux/Store';
import * as TableRedux from '../../store/TableRedux';
import * as ProductsRedux from '../products/store/ProductsRedux';

import { KTSVG } from '../../../_metronic/helpers';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { CompanyModel } from '../companies/CompanyModel';
import { ProductListModel } from './types/productList';
import { ProductTypeMap } from '../../../_metronic/constants/general'; //DocumentDirection,
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';

import { SerachFilters } from '../../shared/SearchFilters';
import { TableSelection } from '../../shared/tables/classes/TableSelection';
import { DateViewer, TableActions, TableData } from '../../shared/tables/classes';
import { ViewItem } from '../../shared/tables/actions/ViewItem';
import { EditItem } from '../../shared/tables/actions/EditItem';
import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';
// Hide the unnecessary
// import { DeleteItem } from '../../shared/tables/actions/DeleteItem';
import { usePermissions } from '../../hooks/usePermissions';
import { Button } from '../../shared/Button';
import { PriceViewer } from '../../shared/tables/classes/PriceViewer';
import { TableImage } from '../../shared/tables/classes/Image';
import { LOCALE } from '../../../_metronic/helpers/typescript';
import { CommonTable2 } from '../../shared/tables/Table2';
import { DeleteItem } from '../../shared/tables/actions/DeleteItem';

const headerItems = [
  { id: 'Product Name', width: 240 },
  { id: 'Product Code', width: 240 },
  { id: 'Price', maxWidth: 240 },
  { id: 'Image', maxWidth: 230, width: 230 },
  { id: 'Creation Date', maxWidth: 230, width: 230 },
  { id: 'Product Type', maxWidth: 230, width: 230 },
  { id: 'Actions', maxWidth: 100, width: 100 },
];

const TABLE_NAME = 'products_list';

export const Products: FC = () => {
  return (
    <>
      <ProductsTable />
    </>
  );
};

export const ProductsTable: FC = () => {
  // Hide the unnecessary
  const [deleteItemId, setDeleteItemId] = useState<number[] | null>(null);
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { locale } = useIntl();
  const params: any = useParams();

  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [editItemId, setEditItemId] = useState<any>(null);
  /* -------------------------- Bring Data from store ------------------------- */

  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const products: ProductListModel = useSelector<RootState>(
    (state) => state.USER_products.Products,
    shallowEqual
  ) as ProductListModel;

  const productTypesOptions = useMemo(() => {
    return Array.from(ProductTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const tables: any = useSelector<RootState>((state) => state.table.tables) as any;

  const table = tables[TABLE_NAME];

  /* ----------------------- Getting roles for dropdown ----------------------- */

  function RequestData() {
    dispatch(
      ProductsRedux.actions.getProducts({
        companyId: companyDetails.id,
        params: {
          ...table?.query,
          page: table?.page,
          per_page: table?.pagination,
        },
      })
    );
  }

  useEffect(() => {
    if (table) {
      RequestData();
    }
  }, [table?.page, table?.pagination, params.companySlug, companyDetails.id]);

  const searchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (params.companySlug && companyDetails.id && table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  const rowItems = products?.data.map((product) => [
    new TableSelection({
      display: userCan('delete-product'),
      id: product.id,
      tableName: TABLE_NAME,
    }),
    new TableData({ text: product.name[locale as LOCALE] }),
    new TableData({ text: product.sku }),
    new PriceViewer({ price: product.price }),
    new TableImage({ image: product.image }),
    new DateViewer({ date: product.created_at }),
    new TableData({
      text: productTypesOptions.filter((option) => product.type === option.value)[0]?.label,
    }),
    new TableActions(
      (
        /* ---------- Don't Display actions if user is the current app user or is companyAdmin --------- */
        <div className='d-flex flex-row'>
          <>
            {userCan('view-product') && (
              <ViewItem
                title={$t('View')}
                onClick={() =>
                  history.push(
                    `/c/${companyDetails.slug}/company-management/products/${product.id}`
                  )
                }
              />
            )}
            {/* {userCan('update-product') && (
              <EditItem
                title={$t('Edit')}
                onClick={() =>
                  history.push(`/c/${companyDetails.slug}/company-management/products/${product.id}/edit`)
                }
              />
            )} */}
            {userCan('update-product') &&
              (product?.can_update ? (
                <EditItem
                  title={$t('Edit')}
                  onClick={() =>
                    history.push(
                      `/c/${companyDetails.slug}/company-management/products/${product.id}/edit`
                    )
                  }
                />
              ) : (
                <EditItemWithModal
                  id={product.id}
                  title={$t('Edit')}
                  messageId={
                    'This product has been selected in sale/purchase invoice, are you sure you want to proceed the editing process?'
                  }
                  editURI={`/c/${companyDetails.slug}/company-management/products/${product.id}/edit`}
                  showButtonGroup
                  editItemId={editItemId}
                  setEditItemId={setEditItemId}
                  // payload={product.id}
                  // onClick={() => history.push(`/c/${companyDetails.slug}/products/${product.id}/edit`)}
                />
              ))}
            {/* Hide the unnecessary */}
            {userCan('delete-product') && (
              <DeleteItem
                showButtonGroup={product.can_update}
                id={product.id}
                messageId={
                  !product.can_update
                    ? 'can not delete this product'
                    : 'Are you sure you want to delete this product?'
                }
                setDeleteItemId={setDeleteItemId}
                deleteItemId={deleteItemId}
                deleteURI={`/${companyDetails.id}/products/${product.id}`}
                payload={[product.id]}
                successMessage={'Product has been deleted successfully!'}
                afterSucessRequest={() => RequestData()}
              />
            )}
          </>
        </div>
      )
    ),
  ]);
  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      dispatch(ProductsRedux.actions.fulfillProducts({}));
    };
  }, []);
  /* ------------------------------- End Leaving ------------------------------ */
  return (
    <div className='table-wrapper card'>
      {/* begin::Header */}
      <div className='card-header border-0 '>
        {/*  ------------------------ start search and filters ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => {
              setShowSearchFilters(!showSearchFilters);
            }}
          >
            {!showSearchFilters && (
              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
            )}
            {showSearchFilters && (
              <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
            )}
          </button>
        </div>

        {/*  ------------------------- End Search and filters -------------------------  */}

        {/*  ------------------------ start Action button ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 2 */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                {$t('Actions')}{' '}
              </div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mb-3 opacity-75'></div>
            {/* end::Menu separator */}

            {/* begin::Menu item */}
            {(userCan('create-client') || userCan('create-third_party')) && (
              <div className='menu-item px-3 pb-3'>
                <Button
                  className='menu-link px-3 btn w-md-190px'
                  faIconMenu={true}
                  fa='plus'
                  btnType='outlined'
                  onClick={() =>
                    history.push(`/c/${params.companySlug}/company-management/products/create`)
                  }
                  nameId='Add product'
                />
              </div>
            )}
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>

        {/*  ------------------------ End Action button ------------------------  */}
      </div>
      {/* end::Header */}

      {showSearchFilters && (
        <div className='fw-bold m-auto' style={{ width: '95%' }}>
          <div className='px-3'>
            <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
          </div>
          <div className='separator mb-3 opacity-75'></div>
          <SerachFilters
            leftHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'search',
                queryTerm: 'name',
                onChange: (value: string) => {
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'name',
                    })
                  );
                },
                placeholderId: 'Search anything',
              },
            ]}
            rightHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'dropdown',
                queryTerm: 'type',
                onChange: (value: any) =>
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'type',
                    })
                  ),
                placeholderId: 'Product Type',
                options: productTypesOptions,
              },
              // {
              //   TABLE_NAME: `${TABLE_NAME}`,
              //   type: 'dateRange',
              //   queryTerm: 'dateRange',
              //   isClearable: true,
              //   onChange: (valueFrom: string, valueTo: string) => {
              //     dispatch(
              //       TableRedux.actions.updateQuery({
              //         value: valueFrom,
              //         tableName: TABLE_NAME,
              //         queryTerm: 'date_from',
              //       })
              //     );
              //     dispatch(
              //       TableRedux.actions.updateQuery({
              //         value: valueTo,
              //         tableName: TABLE_NAME,
              //         queryTerm: 'date_to',
              //       })
              //     );
              //   },
              // },
            ]}
            searchFilterHandler={searchFilterHandler}
            clearSearchFilterHandler={clearSearchFilterHandler}
          />
        </div>
      )}
      {/* Hide the unnecessary */}
      {/* <div className='d-flex justify-content-between  flex-row-reverse align-items-baseline mx-5'>
        {userCan('delete-user') && (
          <DeleteItem
            showButtonGroup={true}
            isButton
            messageId={'Are you sure you want to delete this product?'}
            setDeleteItemId={setDeleteItemId}
            deleteItemId={deleteItemId}
            deleteURI={`/${companyDetails.id}/products`}
            payload={deleteItemId}
            successMessage={'Product has been deleted successfully!'}
            TABLE_NAME={TABLE_NAME}
            afterSucessRequest={() => RequestData()}
          />
        )}
      </div> */}
      <CommonTable2
        className='pb-5 pb-xl-8'
        tableName={TABLE_NAME}
        initPerPage={20}
        headerWithCheckbox={userCan('delete-product')}
        allDisplayedIds={products?.data?.map((product) => product.id) || []}
        meta={products}
        headerItems={headerItems}
        rowItems={rowItems}
      />
    </div>
  );
};

const ProductsWrapper: FC = () => {
  usePermissions('view-product');
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Products' })}</PageTitle>
      <Products />
    </div>
  );
};

export { ProductsWrapper };
