import { FC } from 'react';
// import { KTSVG } from '../../../../_metronic/helpers';

type Props = any;

export const ApproveItem: FC<Props> = ({ ...props }) => {
  return (
    <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' {...props}>
      <i className='fas fa-check-circle mx-2'></i>
    </a>
  );
};
