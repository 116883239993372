import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import AhadDialog from '../../../../setup/shared/AhadDialog';
import { Portal } from '../../../partials';

import { Input } from '../../../../app/shared/Input';
import clsx from 'clsx';
import { axiosInstance } from '../../../../network/apis';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type Props = {
  setIsPopupChangePasswordOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PopupChangePassword: FC<Props> = ({ setIsPopupChangePasswordOpen }) => {
  /* ------------------------------- Local State ------------------------------ */
  const [loading, setLoading] = useState(false);
  /* ------------------------------- Local State ------------------------------ */
  const { formatMessage } = useIntl();
  /* -------------------------- Bring Data from store ------------------------- */
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required(formatMessage({ id: 'This field is required' })),
      password: Yup.string()
        .min(6, formatMessage({ id: 'The minimum characters is {number}' }, { number: 6 }))
        .max(255, formatMessage({ id: 'The Maximum characters is {number}' }, { number: 255 }))
        .required(formatMessage({ id: 'This field is required' })),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], formatMessage({ id: 'Passwords not match' }))
        .min(6, formatMessage({ id: 'The minimum characters is {number}' }, { number: 6 }))
        .max(255, formatMessage({ id: 'The Maximum characters is {number}' }, { number: 255 }))
        .required(formatMessage({ id: 'This field is required' })),
    }),

    onSubmit: (values) => {
      setLoading(true);
      axiosInstance
        .post('/change-password', values)
        .then((response) => {
          setLoading(false);
          history.push('/auth/login');
          console.log(response);
          const data = response.data;
          const merged: any = Object.values(data).reduce(
            (accumulator: any, currentValue) => accumulator.concat(currentValue),
            []
          );
          merged.map((item: any) =>
            toast.success(item, {
              toastId: 'changePasswordsuccess',
            })
          );
        })
        .catch((error) => {
          setLoading(false);
          const data = error?.response?.data?.errors;
          console.log(data);
          if (data) {
            const merged: any = Object?.values(data)?.reduce(
              (accumulator: any, currentValue) => accumulator.concat(currentValue),
              []
            );
            merged.map((item: any) =>
              toast.error(item, {
                toastId: 'changePasswordError',
              })
            );
          }
        });
    },
  });

  return (
    <div>
      <Portal className='full_modal'>
        <AhadDialog
          titleId={'Change Password'}
          closeCallBack={() => {
            setIsPopupChangePasswordOpen(false);
          }}
          specialContainerClass='dialog-content w-600px'
        >
          <FormikProvider value={formik}>
            <Form id='create_template' noValidate autoComplete='none'>
              <div className='d-flex flex-column'>
                <div>
                  <div className='col-12'>
                    <Input
                      placeholder={formatMessage({ id: 'old password' })}
                      autoComplete='off'
                      labelId='old password'
                      formik={formik}
                      name='old_password'
                      type='password'
                    />
                  </div>
                  <div className='col-12'>
                    <Input
                      placeholder={formatMessage({ id: 'New password' })}
                      autoComplete='off'
                      labelId='New password'
                      formik={formik}
                      name='password'
                      type='password'
                    />
                  </div>
                  <div className='col-12'>
                    <Input
                      placeholder={formatMessage({ id: 'Confirm Password' })}
                      autoComplete='off'
                      labelId='Confirm Password'
                      formik={formik}
                      name='password_confirmation'
                      type='password'
                    />
                  </div>
                </div>
                <div className={clsx('mt-4', 'col-xl-6', { 'col-xl-4': !'col-xl-6' })}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        type='submit'
                        id={'submit'}
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            <FormattedMessage id={'Change Password'} />
                          </span>
                        )}

                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id='Please wait...' />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={() => setIsPopupChangePasswordOpen(false)}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </AhadDialog>
      </Portal>
    </div>
  );
};
