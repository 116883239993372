import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomPlural } from '../../../../../utils/CustomPlural';
import { DoctypeType } from '../types';
import { ArrowAfterDash } from '../../../../../app/shared/ArrowAfterDash';

export type Props = {
  docType?: DoctypeType;
};

export const DocTypeCard: FC<Props> = ({ docType }) => {
  const { iconBGColor, titleId, descriptionId, amount, faIcon } = docType || {};

  return (
    <div className='d-flex align-items-center rounded px-5 mb-7 doctype-card'>
      <span className={`'min-w-50px min-h-50px icon text-center ${iconBGColor}`}>
        <i className={`fas ${faIcon}`}></i>
      </span>
      <div className='flex-grow-1 me-2'>
        <h4 className='fw-bold text-gray-800 fs-6'>
          {titleId && <FormattedMessage id={titleId} />}
        </h4>
        <span className='text-muted fw-bold d-block'>
          {descriptionId && <FormattedMessage id={descriptionId} />}
        </span>
      </div>
      <div className='border border-gray-300 border-dashed rounded min-w-50px min-h-50px py-3 mt-3 px-4 mb-3'>
        {amount === undefined && <div className='mt-4 dot-pulse'></div>}
        {amount && (
          <div>
            <div className='d-flex align-items-center'>
              <span className='svg-icon svg-icon-3 svg-icon-success me-2'></span>
              <div className='fs-3 fw-bolder text-center'>{amount}</div>
            </div>
            <div className='fs-7 text-gray-400 w-50px mw-50px'>
              <ArrowAfterDash /> {amount && <CustomPlural amount={amount} wordId='Doc' />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
