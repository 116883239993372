export type actionStatus =
  | 'adjneeded'
  | 'approved'
  | 'waitingforsecondpartysignature'
  | 'waitingforcompanysignature'
  | 'closed';

export enum actionStatusColor {
  adjneeded = 'blueGrey',
  approved = 'info', // purple
  waitingforsecondpartysignature = 'danger',
  waitingforcompanysignature = 'primary',
  closed = 'success',
}
