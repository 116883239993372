import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { UserModel } from '../models/UserModel';

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      id: 1,
      first_name: 'Omar',
      last_name: 'Ali',
      name: 'Omar Ali',
      email: 'abdurrahman.hafez@squadio.com',
      image: toAbsoluteUrl('/media/avatars/150-2.jpg'),
      active: true,
      roles: [],
      signatures: {
        images: [],
      },
    },
    {
      id: 2,
      first_name: 'Nour',
      last_name: 'Ahmed',
      name: 'Nour Ahmed',
      email: 'user@demo.com',
      image: null,
      active: true,
      roles: [],
      signatures: {
        images: [],
      },
    },
    {
      id: 3,
      first_name: 'Reda',
      last_name: 'Amin',
      name: 'Reda Amin',
      email: 'guest@demo.com',
      image: toAbsoluteUrl('/media/avatars/150-5.jpg'),
      active: true,
      roles: [],
      signatures: {
        images: [],
      },
    },
  ];
}
