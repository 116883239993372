import { FC } from 'react';
import { useIntl } from 'react-intl';

export const FormatMessage: FC<{ id: string }> = ({ id }) => {
  const { formatMessage } = useIntl();

  return <>{formatMessage({ id })}</>;
};

export const $t = (id: string, values?: any): string => {
  const { formatMessage } = useIntl();

  return formatMessage({ id }, { ...values });
};
