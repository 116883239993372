import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomPlural } from '../../../../utils/CustomPlural';
import { capitalize } from '../../../../utils/strings';
// import { actionVATStatusColor } from '../../../../_metronic/assets/ts/_utils/models/VATActions';
import { statisticVAT } from './statisticVAT';
// import { Dropdown1 } from '../../../../_metronic/partials/content/dropdown/Dropdown1';
import { KTSVG } from '../../../../_metronic/helpers';

type Props = {
  statisticVAT: statisticVAT;
  className: string;
};

export const StatisticVATCard: FC<Props> = ({ statisticVAT, className }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div
          className={`px-9 pt-7 card-rounded h-275px w-100 `}
          //bg-${
          //   actionVATStatusColor[statisticVAT.type]
          // }

          style={{
            // backgroundColor: `#6365A4`,
            // backgroundColor: `#5A9AD2`,
            backgroundColor: `#5A5EA8`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>
              <FormattedMessage id={capitalize(statisticVAT.title)} />
            </h3>
            <div className='ms-1'>
              {/* begin::Menu */}
              {/* <button
              type='button'
              className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button> */}
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-row text-white pt-8'>
            <span className='fw-bold fs-7 icon text-center light-pale-grey col-4'>
              {statisticVAT.faIcon && <i className={`${statisticVAT.faIcon}`}></i>}
              {statisticVAT.icon && (
                <img src={statisticVAT.icon} className={statisticVAT.iconClasses} alt='' />
              )}
            </span>
            <span className='text-center text-white row col-8'>
              <span className='fw-bold fs-1 '>
                <CustomPlural amount={statisticVAT.total} wordId='Transaction' />
              </span>
              <span className='fw-bolder fs-2x '>
                {statisticVAT.total ? statisticVAT.total : 0}
              </span>
            </span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
          style={{ marginTop: '-100px' }}
        >
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                  <FormattedMessage id={statisticVAT.rightSideTitle} />
                </span>
                {/* <div className='text-gray-400 fw-bold fs-7'>100 Regions</div> */}
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                  {formatMessage({ id: 'SAR' })} {statisticVAT.amount ? statisticVAT.amount : 0}
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className='d-flex align-items-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                  <FormattedMessage id={statisticVAT.leftSideTitle} />
                </span>
                {/* <div className='text-gray-400 fw-bold fs-7'>3090 Refunds</div> */}
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                  {formatMessage({ id: 'SAR' })} {statisticVAT.return ? statisticVAT.return : 0}
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr065.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  );
};
