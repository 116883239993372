import { FC, useRef, memo } from 'react';
import JoditEditor from 'jodit-react';

type Props = {
  onChange: FunctionStringCallback;
  onBlur?: FunctionStringCallback;
  config: Object;
  value: string;
};
const Editor: FC<Props> = ({ onChange, onBlur, config, value }) => {
  const editor = useRef(null);

  return <JoditEditor ref={editor} value={value} config={config} onChange={onChange} />;
};

export default memo(Editor);
