import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyWithholdingInvoicesReports = endpoints.companyWithholdingInvoicesReports;
export const companyWithholdingSingleInvoiceReport =
  endpoints.companyWithholdingSingleInvoiceReport;
export const companyInvoicesWithholdingReportTransactions =
  endpoints.companyInvoicesWithholdingReportTransactions;
export const companyInvoicesWithholdingReportPurchases =
  endpoints.companyInvoicesWithholdingReportPurchases;

export function getAllInvoicesWithholdingReports({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyWithholdingInvoicesReports(companyId), { params });
}

export function getInvoicesWithholdingReport({
  companyId,
  reportId,
}: {
  companyId: ID;
  reportId: ID;
}) {
  return axiosInstance.get(companyWithholdingSingleInvoiceReport(companyId, reportId));
}

export function getAllInvoicesWithholdingTransactions({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyInvoicesWithholdingReportTransactions(companyId), { params });
}

export function getAllInvoicesWithholdingPurchasesTransactions({
  companyId,
  params,
}: {
  companyId: ID;
  params?: any;
}) {
  return axiosInstance.get(companyInvoicesWithholdingReportPurchases(companyId), { params });
}
