// Axios
// TEMP FOR REBUILD.
import { FC, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { PersistGate } from 'redux-persist/integration/react';

import ReactGA from 'react-ga4';

import { I18nProvider } from './_metronic/i18n/i18nProvider';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import { LayoutProvider, LayoutSplashScreen } from './_metronic/layout/core';
// Apps
import { App } from './app/App';
import * as _redux from './setup';
import { persistor, store } from './setup/redux/Store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios);
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

Chart.register(...registerables);

ReactGA.initialize(`${process.env.REACT_APP_GTMID}`);

// wrapper component to add in service worker details.
interface WrappedCompProps {
  appUpdatePending: boolean;
  updateAction: () => void;
}
const withSwRegistration = (WrappedComp: FC<WrappedCompProps>) => {
  return () => {
    // holds all the SW registration setup
    const [appUpdatePending, setAppUpdatePending] = useState(false);
    const location = useLocation();
    // updates the state when a new update is pending.
    const onSWUpdate = () => {
      setAppUpdatePending(!appUpdatePending);
    };
    // action for updating the service worker.
    const updateAction = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.waiting) {
            // send the skip message to kick off the service worker install.
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // add an listener to reload page when the new service worker is ready.
            registration.waiting.addEventListener('statechange', (event: Event) => {
              const { state = '' } = (event.target as unknown as { state: string }) || {};
              if (state === 'activated') {
                window.location.reload();
              }
            });
          }
        });
      }
    };
    // effect added from router location to check for a new service worker on every
    // page transition (change of route).
    useEffect(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.update();
        });
      }
    }, [location]);

    // registers the service worker based on config setting.

    if (process.env.REACT_APP_REGISTER_SW === 'true') {
      serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    } else {
      serviceWorkerRegistration.unregister();
    }
    return <WrappedComp updateAction={updateAction} appUpdatePending={appUpdatePending} />;
  };
};

const AppWithSwRegistration = withSwRegistration(App);

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={PUBLIC_URL}>
            <I18nProvider>
              <LayoutProvider>
                {/* <App /> */}
                <AppWithSwRegistration />
              </LayoutProvider>
            </I18nProvider>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
);

// sw();
