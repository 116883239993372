/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../setup';
// import { FormatMessage } from '../../../../_metronic/i18n/formatMessage'; //$t,
import { PageTitle } from '../../../../_metronic/layout/core';
import { SerachFilters } from '../../../shared/SearchFilters';
// import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
// import { EditItem } from '../../../shared/tables/actions/EditItem';
// import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import * as UserRedux from '../../../modules/admins/users/store/UsersRedux';
import * as CompanyRedux from '../../../modules/admins/companies/store/CompaniesRedux';
import * as TableRedux from '../../../store/TableRedux';
import { TableStatus } from '../../../shared/tables/classes';
import { TableData } from '../../../shared/tables/classes/TableData';
import { TableActions } from '../../../shared/tables/classes/TableActions';
import { TableSelection } from '../../../shared/tables/classes/TableSelection';
import { DateViewer } from '../../../shared/tables/classes/DateViewer';
// import { CommonTable } from '../../../shared/tables/Table';
import { UsersDataListModel } from '../../../modules/auth/models/UserModel';
import { CompaniesDataListModel } from '../../../modules/companies/CompanyModel';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { useHistory } from 'react-router-dom';
import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import { EditItem } from '../../../shared/tables/actions/EditItem';
import { CommonTable2 } from '../../../shared/tables/Table2';
// import { Button } from '../../../shared/Button';
import { KTSVG } from '../../../../_metronic/helpers';

const headerItems = [
  { id: 'Name', maxWidth: 280 },
  { id: 'Email', maxWidth: 280 },
  { id: 'Date of joining', maxWidth: 300 },
  { id: 'Status', maxWidth: 140 },
  { id: 'Number of companies', maxWidth: 80, width: 80 },
  { id: 'number of available companies', maxWidth: 80, width: 80 },
  { id: 'Actions', maxWidth: 280 },
];

const UsersContent: FC = () => {
  const TABLE_NAME = 'sys_users_list';
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const users: UsersDataListModel = useSelector<RootState>(
    (state) => state.SYS_users.users,
    shallowEqual
  ) as UsersDataListModel;
  const companies: CompaniesDataListModel = useSelector<RootState>(
    ({ SYS_companies }) => SYS_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const tables: any = useSelector<RootState>((store) => store.table.tables) as any;

  const table = tables[TABLE_NAME];

  useEffect(() => {
    dispatch(UserRedux.actions.getUsers({ params: {} }));
    dispatch(CompanyRedux.actions.getCompanies({ params: {} }));
  }, []);

  function RequestData() {
    dispatch(
      UserRedux.actions.getUsers({
        params: { ...table?.query, page: table?.page, per_page: table?.pagination },
      })
    );
  }

  useEffect(() => {
    RequestData();
  }, []);

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  useEffect(() => {
    RequestData();
  }, [table?.page, table?.pagination]);
  // useEffect(() => {
  //   dispatch(
  //     UserRedux.actions.getUsers({
  //       params: { ...table?.query, page: table?.page, per_page: table?.pagination },
  //     })
  //   );
  // }, [tables]);

  const rowItems = users?.data.map((user) => [
    new TableSelection({ display: true, id: user.id, tableName: TABLE_NAME }),
    new TableData({ text: user.name }),
    new TableData({ text: user.email }),
    new DateViewer({ date: user.created_at || 'Undefined' }),
    new TableStatus({
      statusId: user.active ? 'Active' : 'Non active',
      badge: user.active ? 'badge-success' : 'badge-lightGreyBlue',
    }),
    new TableData({ text: user.companies_count?.toString() || '0' }),
    new TableData({ text: user.available_companies_count?.toString() || '0' }),
    new TableActions(
      (
        <div className='d-flex flex-row'>
          <ViewItem title={$t('View')} onClick={() => history.push(`/admin/users/${user.id}`)} />
          <EditItem
            title={$t('View')}
            onClick={() => history.push(`/admin/users/${user.id}/edit`)}
          />
        </div>
      )
    ),
  ]);

  return (
    // <div>
    //   <SerachFilters
    //     leftHandSide={[
    //       {
    //         type: 'search',
    //         queryTerm: 'name',
    //         onChange: (value: string) => {
    //           dispatch(
    //             TableRedux.actions.updateQuery({
    //               value: value,
    //               tableName: TABLE_NAME,
    //               queryTerm: 'name',
    //             })
    //           );
    //         },
    //         placeholderId: 'Search anything',
    //       },
    //     ]}
    //     rightHandSide={[
    // {
    //   type: 'dropdown',
    //   queryTerm: 'company',
    //   onChange: (value: any) =>
    //     dispatch(
    //       TableRedux.actions.updateQuery({
    //         value: value,
    //         tableName: TABLE_NAME,
    //         queryTerm: 'company',
    //       })
    //     ),
    //   placeholderId: 'Company',
    //   options: companies?.data.map((company) => {
    //     return {
    //       value: company.id,
    //       label: company.name || company.slug,
    //     };
    //   }),
    // },
    // {
    //   type: 'dropdown',
    //   queryTerm: 'status',
    //   onChange: (value: any) =>
    //     dispatch(
    //       TableRedux.actions.updateQuery({
    //         value: value,
    //         tableName: TABLE_NAME,
    //         queryTerm: 'status',
    //       })
    //     ),
    //   placeholderId: 'Status',
    //   options: [
    //     { value: '1', label: formatMessage({ id: 'Active' }) },
    //     { value: '0', label: formatMessage({ id: 'Non active' }) },
    //   ],
    // },
    //     ]}
    //   />
    //   <div className='table-wrapper'>
    //     {/* <h3 className='m-2 mx-5'>
    //       <FormatMessage id='Users' />
    //     </h3>
    //     <div className='table-separator'></div>
    //     <div className='table-separator'></div> */}
    //     {/* Hide the unnecessary */}
    //     {/* <div className='d-flex justify-content-between flex-row-reverse align-items-baseline m-5'>
    //       <button className='btn btn-outlined'>
    //         <i className='far fa-trash'></i>
    //         <FormatMessage id='Delete' />
    //       </button>
    //     </div> */}
    //     {users?.data && (
    //       <CommonTable
    //         tableName={TABLE_NAME}
    //         headerWithCheckbox
    //         allDisplayedIds={users?.data?.map((user) => user.id) || []}
    //         meta={users}
    //         headerItems={headerItems}
    //         rowItems={rowItems}
    //       />
    //     )}
    //   </div>
    // </div>
    <>
      <div className='table-wrapper card'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          {/*  ------------------------ start search and filters ------------------------  */}

          <div className='card-toolbar align-items-end flex-row'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              onClick={() => {
                setShowSearchFilters(!showSearchFilters);
              }}
            >
              {!showSearchFilters && (
                <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
              )}
              {showSearchFilters && (
                <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              )}
            </button>
          </div>

          {/*  ------------------------- End Search and filters -------------------------  */}
        </div>
        {/* end::Header */}

        {showSearchFilters && (
          <div className='fw-bold m-auto' style={{ width: '95%' }}>
            <div className='px-3'>
              <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <SerachFilters
              leftHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'search',
                  queryTerm: 'name',
                  onChange: (value: string) => {
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'name',
                      })
                    );
                  },
                  placeholderId: 'Search anything',
                },
              ]}
              rightHandSide={[
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'company',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'company',
                      })
                    ),
                  placeholderId: 'Company',
                  options: companies?.data.map((company) => {
                    return {
                      value: company.id,
                      label: company.name || company.slug,
                    };
                  }),
                },
                {
                  TABLE_NAME: `${TABLE_NAME}`,
                  type: 'dropdown',
                  queryTerm: 'status',
                  onChange: (value: any) =>
                    dispatch(
                      TableRedux.actions.updateQuery({
                        value: value,
                        tableName: TABLE_NAME,
                        queryTerm: 'status',
                      })
                    ),
                  placeholderId: 'Status',
                  options: [
                    { value: '1', label: formatMessage({ id: 'Active' }) },
                    { value: '0', label: formatMessage({ id: 'Non active' }) },
                  ],
                },
              ]}
              searchFilterHandler={searchFilterHandler}
              clearSearchFilterHandler={clearSearchFilterHandler}
            />
          </div>
        )}
        {users?.data && (
          <CommonTable2
            className='pb-5 pb-xl-8'
            tableName={TABLE_NAME}
            headerWithCheckbox
            allDisplayedIds={users?.data?.map((user) => user.id) || []}
            meta={users}
            headerItems={headerItems}
            rowItems={rowItems}
            initPerPage={20}
          />
        )}
      </div>
    </>
  );
};

const UsersWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Users' })}</PageTitle>

      {/* <PageTitle breadcrumbs={[{ title: 'Users', path: '/admin/users', isActive: true }]}>
        {formatMessage({ id: 'Users' })}
      </PageTitle> */}

      <UsersContent />
    </div>
  );
};

export { UsersWrapper };
