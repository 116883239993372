import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useState, useMemo, useEffect } from 'react';
import { serialize } from 'object-to-formdata';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom'; // useHistory, useLocation,
import Select from 'react-select';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import * as TemplatesRedux from '../templates/store/TemplatesRedux';
import * as ClientsRedux from '../../modules/clients/store/ClientsRedux';

import { axiosInstance } from '../../../network/apis';
import { RootState } from '../../../setup/redux/Store';
import { DisplayErrors } from '../../../utils/DisplayErrors';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { usePermissions } from '../../hooks/usePermissions';
import { CloseButtonRedShadowed } from '../../shared/CloseButtonRedShadowed';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import { ICompany } from '../../types/companies';
import { useConfigureTemplateClientSchema } from './useConfigureTemplateClientSchema';
import { ClientsDataListModel, IClient } from '../clients/types/clientsList';
import { endpoints, ID } from '../../../_metronic/constants/paths';
import { ErrorMessage } from '../../shared/ErrorMessage';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { initialClientsConfigPayload } from './helpers/templateConfigHelpers';
import { boxStyle } from './boxStyle';
import { LOCALE } from '../../../_metronic/helpers/typescript';

type Props = {
  setShowClientModal: Function;
};

export const ConfigureTemplateClientName: FC<Props> = ({ setShowClientModal }) => {
  usePermissions('create-template', 'Create Template');
  const { validationSchema, initialValues } = useConfigureTemplateClientSchema();
  const dispatch = useDispatch();
  // const location = useLocation();
  // const history = useHistory();
  const params: any = useParams();

  const { formatMessage, locale } = useIntl();

  const [loading, setLoading] = useState(false);

  const { setShowExitPrompt } = useExitPrompt(false);

  const underConstructionTemplate: TemplatesRedux.UnderConstructionTemplate =
    useSelector<RootState>(
      (state) => state.USER_templates.underConstructionTemplate
    ) as TemplatesRedux.UnderConstructionTemplate;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const clients: ClientsDataListModel = useSelector<RootState>((state) => {
    return state.USER_clients.Clients;
  }, shallowEqual) as ClientsDataListModel;

  const clientsOptions = useMemo(
    () =>
      clients?.data?.map((client) => ({
        label: client?.name?.[locale as LOCALE],
        value: client.id,
      })),
    [clients]
  );

  const createTemplate = async (values: any, resetForm: any) => {
    setLoading(true);

    const payload: { [key: string]: any } = initialClientsConfigPayload(values);
    const formData = serialize(payload, { indices: true });

    try {
      let response: AxiosResponse;
      if (params.templateId) {
        if (payload.variable_clients.length === 0) formData.append('variable_clients', '');
        formData.append('_method', 'PUT');
        response = await axiosInstance.post(
          endpoints.updateCompanyTemplates(companyDetails.id, underConstructionTemplate?.id),
          formData
        );
      } else {
        response = await axiosInstance.post(
          endpoints.companyTemplates(companyDetails.id),
          formData
        );
      }
      // const { id } = response.data;

      const newUnderConstruction: TemplatesRedux.UnderConstructionTemplate = {
        // ...payload,
        // id: values?.id || id,
        // values,
        ...underConstructionTemplate,
        ...response.data,
      } as TemplatesRedux.UnderConstructionTemplate;

      dispatch(
        TemplatesRedux.actions.fulfillUnderConstructionTemplate({
          underConstructionTemplate: newUnderConstruction,
        })
      );
      resetForm();
      toast.success(formatMessage({ id: 'The list of clients names added successfully' }));
      //   history.push(location.pathname.replace('/create', `/update/${id}`));
      setShowClientModal(false);
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const getClients = () =>
    dispatch(
      ClientsRedux.actions.getClients({ companyId: companyDetails.id, params: { per_page: 0 } })
    );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => createTemplate(values, resetForm),
  });
  const { setFieldValue, values } = formik;

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    if (Number(params?.templateId) === underConstructionTemplate?.id) {
      setFieldValue(
        'variable_clients',
        underConstructionTemplate.variable_clients.map((client: any) => client.id)
      );
    }
  }, [underConstructionTemplate]);

  useEffect(() => {
    if (params?.templateId) {
      if (!clients?.data?.length) getClients();
    }
  }, [params?.templateId]);

  return (
    <FormikProvider value={formik}>
      <Form id='create_template_client' noValidate autoComplete='none'>
        {Object.keys(formik.touched).length > 0 && (
          <Prompt message={$t('Are you sure you want to ignore the changes?')} />
        )}
        <div
          className='p-5 dialog-defaults b-radius-20'
          style={{ maxHeight: 'fit-content', width: '35vw' }}
        >
          <div className='row'>
            <>
              <div className='mt-2 row'>
                <div className='col-12'>
                  <Select
                    onChange={(value) => {
                      setFieldValue('variable_clients', [...values.variable_clients, value?.value]);
                    }}
                    placeholder={$t('Clients')}
                    onFocus={() => getClients()}
                    onBlur={() => formik.setFieldTouched('variable_clients', true)}
                    options={clientsOptions?.filter(
                      (option) => !values?.variable_clients?.includes(option?.value)
                    )}
                    value={null}
                    className='react-select smaller'
                    id='variable_clients'
                  />
                  <ErrorMessage name='variable_clients' formik={formik} />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='mt-2' style={{ ...boxStyle, overflowY: 'auto' }}>
                    <div className='row p-5'>
                      {values?.variable_clients?.map((id: any) => {
                        const client: IClient = clients.data.find(
                          (user) => user.id === id
                        ) as IClient;

                        return (
                          <div className='col-6'>
                            <div className=' userBox p-2 mt-1 d-flex justify-content-between align-items-center'>
                              <a className='text-dark fw-bolder fs-6'>
                                {client?.name?.[locale as LOCALE]}
                              </a>
                              <CloseButtonRedShadowed
                                onClick={() => {
                                  setFieldValue(
                                    'variable_clients',
                                    [...values.variable_clients].filter((item: ID) => item !== id)
                                  );
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
          <SaveCancelBtnsGroup
            saveBtnLabel='save'
            formik={formik}
            loading={loading}
            cancelOnClick={() => {
              setShowClientModal(false);
            }}
          />
        </div>
      </Form>
    </FormikProvider>
  );
};
