import { useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
// import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MenuComponent } from '../../../assets/ts/components';
import { UserMenuAndAlerts } from './UserMenuAndAlerts';
// import { CompaniesMenu } from '../../../partials/layout/menus/CompaniesMenu';
import * as CompaniesRedux from '../../../../app/modules/companies/store/UserCompaniesRedux';
import { CompaniesDataListModel } from '../../../../app/modules/companies/CompanyModel';
import { RootState } from '../../../../setup/redux/Store';
import { ICompany } from '../../../../app/types/companies';
import { Logo } from '../../../../app/shared/LogoWithCompany';
import { KTSVG } from '../../../helpers';

import { useLayout } from '../../core';
import { CompanyLogo } from '../../../../app/shared/CompanyLogo';
import SwitchButton from './switchButton';
import useWindowSize from '../../../../app/hooks/useWindowSize';

export function HeaderWrapper() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params: any = useParams();
  // const { formatMessage } = useIntl(); //locale,
  const { config, classes, attributes } = useLayout();
  const { width } = useWindowSize();
  const { aside } = config; //header,

  const companies: CompaniesDataListModel = useSelector<RootState>(
    (state) => state?.USER_companies.companies,
    shallowEqual
  ) as CompaniesDataListModel;

  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  useEffect(() => {
    if (
      (!companyDetails?.slug || companyDetails?.slug !== params?.companySlug) &&
      companies?.data?.length
    ) {
      const id = companies?.data?.find((company) => company.slug === params.companySlug)
        ?.id as number;
      if (id) {
        dispatch(
          CompaniesRedux.actions.getCurrentCompanyById({
            id: id,
          })
        );
      }
    }
  }, [companyDetails, companies, params]);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [pathname]);

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div className='logo-and-toggle' id='kt_aside_logo'>
        {/* begin::Aside mobile toggle */}

        {/* end::Aside mobile toggle */}

        {/* begin::Logo */}
        <Logo withCompanyName />
        {/* begin::Aside toggler */}
        {/* {aside.minimize && (
          <span
            className='resize-btn'
            id='kt_aside_toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </span>
        )} */}
        {/* end::Aside toggler */}
      </div>

      <div className={clsx('d-flex align-items-stretch justify-content-end')}>
        {/* style={{ width: '95%' }} */}
        <div
          className={
            width >= 1024 ? 'd-flex align-items-center justify-content-end me-3' : 'd-none'
          }
        >
          <SwitchButton />
        </div>
        <div className='d-flex align-items-stretch justify-content-between flex-grow-1'>
          <div className={`d-flex align-items-center justify-content-between fs-2 m-auto p-auto `}>
            <CompanyLogo />
          </div>
          <div className='d-flex align-items-stretch flex-shrink-0 m-3'>
            <UserMenuAndAlerts />
          </div>
          {aside.display && (
            <div
              className='d-flex align-items-center d-lg-none me-5 m-lg-5'
              title='Show aside menu'
            >
              <div
                className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                id='kt_aside_mobile_toggle'
              >
                <KTSVG
                  path='/media/icons/duotune/abstract/abs015.svg'
                  className='svg-icon-2x mt-1'
                />
              </div>
            </div>
          )}
          {/* {companies?.data?.length > 1 && (
            <div className='d-flex flex-column justify-content-center align-items-stretch flex-shrink-0'>
              <CompaniesMenu />
              <div
                className={clsx(
                  'cursor-pointer symbol symbol-circle avatar-border d-flex flex-row'
                )}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
                data-kt-menu-flip='bottom'
              >
                <div className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle d-none d-lg-flex'>
                  <KTSVG
                    path={'/media/icons/duotune/abstract/abs015.svg'}
                    className={'svg-icon-1 rotate-180'}
                  />
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
