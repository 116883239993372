import { FormattedMessage, useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Label } from '../../../shared/Label';
import { Input } from '../../../shared/Input';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { isValidPhoneNumber } from 'libphonenumber-js/min';
import { axiosInstance } from '../../../../network/apis';
import { useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

interface IParams {
  transactionId: string;
}

interface ITransactionData {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone: string;
  image: string;
  active: boolean;
  companies_count: number;
  owned_companies_count: number;
  available_companies_count: number;
  created_at: string;
  last_visit: string | null;
}
export function TransactionEdit() {
  const { formatMessage, locale } = useIntl();
  const params: IParams = useParams();
  const [loading, setLoading] = useState(false);
  const [allTransactionData, setAllTransactionData] = useState<ITransactionData>();
  /* --------------------------------- Formik --------------------------------- */

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      image: '',
      available_companies_count: 0,
    },
    validationSchema: Yup.object().shape({
      available_companies_count: Yup.number()
        .typeError(formatMessage({ id: 'Only number are allowed!' }))
        .min(0, formatMessage({ id: 'The Minimum number is {minNumber}' }, { minNumber: 0 }))
        .max(999, formatMessage({ id: 'The Maximum number is {maxNumber}' }, { maxNumber: 999 }))
        .required(formatMessage({ id: 'This field is required' })),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const data = {
        available_companies_count: values.available_companies_count,
      };
      if (
        allTransactionData?.available_companies_count.toString() ===
        data?.available_companies_count.toString()
      ) {
        toast.success(formatMessage({ id: 'transaction profile has been updated successfully' }));
        setLoading(false);
      } else {
        axiosInstance
          .put(`admin/transactions/${params.transactionId}`, data)
          .then((response) => {
            toast.success(
              formatMessage({ id: 'transaction profile has been updated successfully' })
            );
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            const data = error.response.data.errors;
            const merged: any = Object.values(data).reduce(
              (accumulator: any, currentValue) => accumulator.concat(currentValue),
              []
            );
            merged.map((item: any) =>
              toast.error(item, {
                toastId: 'available_companies_count',
              })
            );
          });
      }
    },
  });
  const { setFieldValue, values } = formik;
  /* --------------------------------- Formik --------------------------------- */
  const getTransactionInfo = useCallback(() => {
    if (!params.transactionId) return;
    axiosInstance
      .get(`admin/transactions/${params.transactionId}`)
      .then((response) => {
        const res = response.data;
        setAllTransactionData(res);
        setFieldValue('first_name', res?.first_name);
        setFieldValue('last_name', res?.last_name);
        setFieldValue('email', res?.email);
        setFieldValue('phone', res?.phone);
        setFieldValue('image', res?.image);
        setFieldValue('available_companies_count', res?.available_companies_count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getTransactionInfo();
  }, []);
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Personal Information' })}</PageTitle>

      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
          paddingRight: locale === 'en' ? '4em' : '',
          paddingLeft: locale === 'ar' ? '4em' : '',
        }}
      >
        <div className='row'>
          <div className='col-6'>
            <img
              src={values.image === '' ? toAbsoluteUrl('/media/avatars/blank.png') : values.image}
              className='rounded-circle w-150px me-3'
              alt='thumb'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'First Name' })}
              autoComplete='off'
              labelId='First Name'
              formik={formik}
              name='first_name'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'Last Name' })}
              autoComplete='off'
              labelId='Last Name'
              formik={formik}
              name='last_name'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'Email' })}
              autoComplete='off'
              labelId='Email'
              formik={formik}
              name='email'
              type='text'
              disabled={true}
            />
          </div>
          <div className='col-6'>
            <Input
              placeholder={formatMessage({ id: 'number of available companies' })}
              autoComplete='off'
              labelId='number of available companies'
              formik={formik}
              name='available_companies_count'
              type='text'
              disabled={false}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Label msg='Phone Number' htmlFor='recipient_type' />
            <div style={{ maxWidth: 'fit-content' }} dir='ltr'>
              <PhoneInput
                disabled={true}
                localization={locale === 'ar' ? ar : undefined}
                inputClass='form-control form-control-sm b-radius-16 basic-input'
                country={'sa'}
                placeholder={formatMessage({ id: 'Phone' })}
                value={formik.values.phone}
                onChange={(phone, data: any) => {
                  const valid = isValidPhoneNumber(
                    phone.slice(data.dialCode.length),
                    data.countryCode
                      .split('')
                      .map((l: string) => l.toUpperCase())
                      .join('')
                  );
                  formik.setFieldValue(`phone`, phone);
                  formik.setTouched({ ...formik.touched, phone: true });
                  formik.setFieldValue(`phoneValidity`, valid);
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='mt-4 col-xl-4'>
            <div className='col-md-6'>
              <button
                type='submit'
                className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow mt-5'
                id='my-form'
                disabled={loading}
              >
                {!loading && (
                  <span className='indicator-label'>
                    <FormattedMessage id='Confirm' />
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <FormattedMessage id='Please wait...' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
