import { useState, useMemo, FC, useEffect } from 'react';
import { Prompt, useHistory, useParams, useLocation } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import Select from 'react-select';

import 'react-phone-input-2/lib/style.css';

import { Label } from '../../shared/Label';
import { ErrorMessage } from '../../shared/ErrorMessage';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import * as ProductsRedux from '../products/store/ProductsRedux';

import { axiosInstance } from '../../../network/apis';
import { PageTitle } from '../../../_metronic/layout/core';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { ICompany } from '../../types/companies';
import { RootState } from '../../../setup/redux/Store';
import { serialize } from 'object-to-formdata';
import { SeparatorLines } from '../../shared/Lines';
import { Input } from '../../shared/Input';
import { LocaleTitle } from '../../shared/LocaleTitle';
import { TextArea } from '../../shared/textarea';
import { ProductTypeMap } from '../../../_metronic/constants/general'; //DocumentDirection,
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';

// import { TableData } from '../../shared/tables/classes'; // DateViewer,
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { useConfigureProductSchema } from './useConfigureProductSchema';
import { FileUploader } from '../../pages/Companies/CompanyDetails/components/FileUploader';
import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import { usePermissions } from '../../hooks/usePermissions';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

const ConfigNewProductWrapper: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const history = useHistory();
  const params: any = useParams();
  const { formatMessage, locale } = useIntl();
  const { userCan } = usePermissions();
  const location = useLocation();
  const dispatch = useDispatch();
  const { productInitialValues, productValidationSchema } = useConfigureProductSchema();

  const [image, setImage] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [showWarningsConfirmationModal, setShowWarningsConfirmationModal] = useState(false);
  const [warningsConfirmation, setWarningsConfirmation] = useState<any>([]);
  const [approveWarnings, setApproveWarnings] = useState<any>(null);
  const [editItemId, setEditItemId] = useState<any>(null);

  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const productTypesOptions = useMemo(() => {
    return Array.from(ProductTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const product: any = useSelector<RootState>((state) => {
    return state.USER_products.currentProduct;
  }, shallowEqual) as any;
  /* ----------------------- end getting data from store ---------------------- */
  /* ----------------------------- Getting Products ----------------------------- */
  const getCurrentProduct = async (productId: any) => {
    try {
      dispatch(
        ProductsRedux.actions.getCurrentProduct({
          companyId: companyDetails.id,
          productId: productId,
        })
      );
    } catch (err) {
      toast.error(formatMessage({ id: 'Invalid url, you will be redirected to home page' }));
      history.push('/');
    }
  };
  /* -------------------------------- On Init -------------------------------- */
  useEffect(() => {
    // if (!clients?.data) {
    //   getClients();
    // }
    if (params?.productId && mode !== 'create') {
      getCurrentProduct(params?.productId);
    }
  }, [params?.productId]);

  useEffect(() => {
    if (product?.id) {
      formik.setFieldValue('name_en', product?.name?.en);
      formik.setFieldValue('name_ar', product?.name?.ar);
      formik.setFieldValue(
        'description_en',
        product.description?.en ? product.description?.en : ''
      );
      formik.setFieldValue(
        'description_ar',
        product.description?.ar ? product.description?.ar : ''
      );
      formik.setFieldValue('sku', product.sku);
      formik.setFieldValue('price', product.price);
      formik.setFieldValue(
        'product_type',
        Number.isInteger(product.type)
          ? productTypesOptions?.filter((product_type) => product_type.value === product?.type)
          : []
      );
      setImage(product.image);
    }
  }, [product]);

  /* ----------------------------- create product ----------------------------- */
  const createProduct = async (values: any, setSubmitting: any, resetForm: any) => {
    const payload: any = {};
    payload['name'] = { ar: values.name_ar.trim(), en: values.name_en.trim() };
    payload['description'] = {
      ar: values.description_ar.trim(),
      en: values.description_en.trim(),
    };
    payload['sku'] = values.sku.trim();
    payload['price'] = Number(values.price);
    payload['type'] = values.product_type[0]?.value;
    if (mode === 'edit' && approveWarnings) {
      payload['approve'] = approveWarnings;
    }
    delete payload['product_type'];
    const formData = serialize(payload, { indices: true });
    if (image) {
      formData.append('image', image);
    }
    try {
      if (mode === 'create') {
        await axiosInstance.post(`${companyDetails.id}/products`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'The product have been added successfully' }));
      } else if (mode === 'edit') {
        formData.append('_method', 'PUT');
        await axiosInstance.post(`${companyDetails.id}/products/${product.id}`, formData);
        resetForm();
        toast.success(formatMessage({ id: 'The product have been edited successfully' }));
      }
      history.push(`/c/${companyDetails.slug}/company-management/products`);
    } catch (err: any) {
      if (
        err &&
        err.response.status === 422 &&
        Object.keys(err.response.data).includes('messages')
      ) {
        setLoading(false);
        setShowWarningsConfirmationModal(true);
        setWarningsConfirmation(err.response.data.messages);
      } else if (!Object.keys(err.response.data).includes('errors')) {
        setApproveWarnings(1);
        formik.submitForm();
      } else {
        DisplayErrors(err);
        setSubmitting(false);
      }
    }
    setLoading(false);
  };
  /* ----------------------------- formik starter ----------------------------- */
  const formik = useFormik({
    initialValues: productInitialValues,
    validationSchema: productValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) =>
      createProduct(values, setSubmitting, resetForm),
  });
  // const { setFieldValue, setFieldTouched } = formik; //,values

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  /* --------------------------------- Leaving -------------------------------- */
  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
      dispatch(ProductsRedux.actions.fulfillCurrentProduct({}));
    };
  }, []);

  /* ------------------------------- End Leaving ------------------------------ */
  return (
    <div className='content-wrapper bg-white b-radius-25 '>
      <div className='d-flex px-5 '>
        <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
          {mode === 'create' && $t('Add product')}
          {mode === 'view' && $t('Preview product')}
          {mode === 'edit' && $t('Edit product')}
        </div>
      </div>
      <SeparatorLines lines={2} />
      <div className='row  mx-3'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex justify-content-start'>
            {/* Badges */}
            {/* {mode !== 'create' &&
              Object.entries(product ? product.errors_with_color : {}).map(([key, value]) => {
                const badge = new TableData({
                  text: ``,
                  errors_with_color: value,
                });
                return <div className='m-2'>{badge.renderItem()}</div>;
                // return <h3>{'knskksk'}</h3>;
              })} */}
          </div>
          {mode === 'view' && userCan('update-product') && (
            <div
              className='d-flex justify-content-end'
              style={{ textAlign: 'end', alignSelf: 'center' }}
            >
              {!product?.can_update ? (
                <EditItemWithModal
                  id={product?.id}
                  title={$t('Edit')}
                  isButton
                  buttonMessageId={'Edit product'}
                  messageId={
                    'This product has been selected in sale/purchase invoice, are you sure you want to proceed the editing process?'
                  }
                  editURI={`/c/${companyDetails.slug}/company-management/products/${product?.id}/edit`}
                  showButtonGroup={!product?.can_update}
                  editItemId={editItemId}
                  setEditItemId={setEditItemId}
                />
              ) : (
                <button
                  className='btn btn-primary btn-sm btn-primary-shadow'
                  title={formatMessage({ id: 'Edit product' })}
                  onClick={() => history.push(location.pathname + '/edit')}
                >
                  <i className='fa fa-edit cursor-pointer'></i>
                  {formatMessage({ id: 'Edit product' })}
                </button>
              )}
              {/* <button
                className='btn btn-primary btn-sm btn-primary-shadow'
                title={formatMessage({ id: 'Edit product' })}
                onClick={() => history.push(location.pathname + '/edit')}
              >
                <i className='fa fa-edit cursor-pointer'></i>
                {formatMessage({ id: 'Edit product' })}
              </button> */}
            </div>
          )}
        </div>
      </div>
      <div className='d-flex px-5 '>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete='none' id='create_product' style={{ width: '100%' }}>
            {Object.keys(formik.touched).length > 0 && (
              <Prompt message={$t('Are you sure you want to ignore the changes?')} />
            )}
            <div className='p-10 ' style={{ width: '100%' }}>
              <div className='row'>
                <div className='m-10'>
                  <div className='row fv-row'>
                    {['ar', 'en'].map((lang) => (
                      <div className='col-xl-6'>
                        <Input
                          placeholder={LocaleTitle({ id: 'Product Name', lang })}
                          required
                          autoComplete='off'
                          disabled={mode === 'view'}
                          labelId={LocaleTitle({ id: 'Product Name', lang })}
                          name={`name_${lang}`}
                          type='text'
                          formik={formik}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='row fv-row mt-10'>
                    {['ar', 'en'].map((lang) => (
                      <div className='col-xl-6'>
                        <TextArea
                          placeholder={LocaleTitle({ id: 'Product Description', lang })}
                          autoComplete='off'
                          labelId={LocaleTitle({ id: 'Product Description', lang })}
                          name={`description_${lang}`}
                          disabled={mode === 'view'}
                          type='text'
                          formik={formik}
                          rows='6'
                        />
                      </div>
                    ))}
                  </div>
                  <div className='row mt-4'>
                    <div className='col-md-6'>
                      <Input
                        placeholder={$t('Add Product Price')}
                        autoComplete='off'
                        labelId={$t('Add Product Price')}
                        name={`price`}
                        disabled={mode === 'view'}
                        type='text'
                        formik={formik}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <Input
                        placeholder={$t('Add Product Code')}
                        autoComplete='off'
                        labelId={$t('Add Product Code')}
                        name={`sku`}
                        disabled={mode === 'view' || (mode === 'edit' && !product?.can_update)}
                        type='text'
                        formik={formik}
                      />
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-md-6'>
                      <Label msg='Product Type' htmlFor='product_type' isRequired />
                      <Select
                        onChange={(value) => formik.setFieldValue('product_type', [value])}
                        onBlur={() => formik.setFieldTouched('product_type', true)}
                        options={productTypesOptions.filter(
                          (option) => formik.values.product_type[0]?.value !== option.value
                        )}
                        // options={productTypesOptions}
                        value={formik.values.product_type}
                        // isLoading={vatRateLoading}
                        placeholder={$t('Product Type')}
                        // isMulti
                        isDisabled={mode === 'view' || (mode === 'edit' && !product?.can_update)}
                        className='react-select smaller'
                        id='product_type'
                      />
                      <ErrorMessage name='product_type' formik={formik} />
                    </div>
                    <div className='col-md-6'>
                      <div style={{ maxWidth: '100%' }}>
                        <FileUploader
                          setFile={setImage}
                          file={image}
                          disabled={mode === 'view'}
                          maxSize={5 * 1025}
                          supportedFiles={[
                            'image/jpeg',
                            'jpeg',
                            'image/png',
                            'png',
                            'image/jpg',
                            'jpg',
                            'image/svg+xml',
                            'svg+xml',
                            'image/svg',
                            'svg',
                          ]}
                          typesOnly='images'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Save and Cancel Buttons */}
              {mode !== 'view' && (
                <>
                  <SaveCancelBtnsGroup
                    saveBtnLabel='save'
                    formik={formik}
                    loading={loading}
                    cancelOnClick={() => {
                      formik.resetForm();
                      history.push(`/c/${companyDetails.slug}/company-management/products`);
                    }}
                  />
                </>
              )}
            </div>
          </Form>
        </FormikProvider>
      </div>
      {/*  ---------------------warnings confirmation Modal-----------------------  */}

      {showWarningsConfirmationModal && warningsConfirmation && (
        <Portal className='full_modal'>
          <AhadDialog
            closeCallBack={
              () => setShowWarningsConfirmationModal(false)

              // history.push(location.pathname.replace('/invite-users', '/users'))
            }
            specialContainerClass='dialog-content-container'
            specialTitleFormat={<></>}
          >
            <>
              <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                <span>{warningsConfirmation}</span>
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <button
                      className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                      disabled={formik.isSubmitting}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setApproveWarnings(1);
                        setShowWarningsConfirmationModal(false);
                        formik.submitForm();
                      }}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          <FormattedMessage id={'Save'} />
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <FormattedMessage id='Please wait...' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='col-md-6'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // setApproveWarnings(0);
                        setShowWarningsConfirmationModal(false);
                        // formik.submitForm();
                      }}
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                    >
                      <FormattedMessage id='Cancel' />
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AhadDialog>
        </Portal>
      )}
      {/*  ---------------------end warnings confirmation Modal-----------------------  */}
    </div>
  );
};

const ConfigNewProductWrapperCreate: FC = () => {
  usePermissions(['create-product']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Products' })}</PageTitle>
      <ConfigNewProductWrapper mode='create' />
    </div>
  );
};

const ConfigNewProductWrapperEdit: FC = () => {
  usePermissions(['update-product']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Products' })}</PageTitle>
      <ConfigNewProductWrapper mode='edit' />
    </div>
  );
};

const ConfigNewProductWrapperView: FC = () => {
  usePermissions(['view-product']);
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Products' })}</PageTitle>
      <ConfigNewProductWrapper mode='view' />
    </div>
  );
};

export { ConfigNewProductWrapperCreate, ConfigNewProductWrapperEdit, ConfigNewProductWrapperView };
