import { useState, useEffect } from 'react'
import { bootstrapBreakPoints } from './../../_metronic/constants/bootstrapBreakPoints'

function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [breakPointValue, setBreakPointValue] = useState()

  useEffect(() => {
    if (width >= 0 && width < 576) {
      setBreakPointValue(0)
    } else if (width >= 576 && width < 768) {
      setBreakPointValue(576)
    } else if (width >= 768 && width < 992) {
      setBreakPointValue(768)
    } else if (width >= 992 && width < 1200) {
      setBreakPointValue(992)
    } else if (width >= 1200 && width < 1400) {
      setBreakPointValue(1200)
    } else {
      setBreakPointValue(1400)
    }
  }, [width])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return {
    width,
    height,
    breakPoint: bootstrapBreakPoints.get(breakPointValue),
  }
}

export default useWindowSize
