import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import Select from 'react-select';

import * as Yup from 'yup';
import * as CurrentCompany from '../../store/CurrentCompanyRedux';

import { actions as companiesActions } from '../../modules/companies/store/UserCompaniesRedux';
import { actions as modalActions } from '../../store/ModalRedux';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { Input } from '../../shared/Input';
import { nameWithoutSpcialCharsStartOrEnd } from '../../../_metronic/helpers/functions';
import { CompanyTypes } from '../../types/CompanyTypes';
import { SelectOptions } from '../../types/SelectOptions';
import { axiosInstance } from '../../../network/apis';
import { endpoints } from '../../../_metronic/constants/paths';

export const CreateCompanyForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      slug: '',
      type: { value: CompanyTypes.Freelancer, label: formatMessage({ id: 'Freelancer' }) },
    }),
    [formatMessage]
  );

  const createNewCompaySchema = useMemo(
    () =>
      Yup.object().shape({
        slug: Yup.string()
          .required(formatMessage({ id: 'This field is required' }))
          .min(
            3,
            formatMessage(
              { id: 'Please enter at least {minCharacters} characters' },
              { minCharacters: 3 }
            )
          )
          .max(
            15,
            formatMessage(
              { id: 'The Maximum characters is {maxCharacters}' },
              { maxCharacters: 15 }
            )
          )
          .test(
            'name',
            formatMessage({ id: "You can't enter special character at first or last!" }),
            (val) => nameWithoutSpcialCharsStartOrEnd(val)
          ),
        type: Yup.object().required(formatMessage({ id: 'This field is required' })),
      }),
    [formatMessage]
  );

  const CompanyTypesOptions: SelectOptions = useMemo(
    () => [
      { value: CompanyTypes.Freelancer, label: formatMessage({ id: 'Freelancer' }) },
      { value: CompanyTypes.Corporate, label: formatMessage({ id: 'Corporate' }) },
    ],
    [formatMessage]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: createNewCompaySchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await axiosInstance.post(endpoints.companies, {
          ...values,
          type: values.type.value,
        });

        if (response.data.id) {
          dispatch(companiesActions.setLastAddedCompany(response.data));
          dispatch(companiesActions.getCurrentCompanyById({ id: response.data.id }));
          dispatch(
            companiesActions.getUserCompanies({
              params: {
                per_page: 100,
              },
            })
          );
          dispatch(modalActions.closeActiveModal());
        }

        toast.success(formatMessage({ id: 'Your company profile has been created successfully!' }));
        axiosInstance.post(`${response.data.id}/switch`).then((res) => {
          dispatch(CurrentCompany.actions.setCurrentCompany({ ...res.data }));
        });
        history.push(`/c/${response.data.slug}/settings/company-added`);
        formik.resetForm();
      } catch (err: any) {
        DisplayErrors(err);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  return (
    <form
      noValidate
      autoComplete='none'
      id='create_company'
      onSubmit={formik.handleSubmit}
      className='p-4 create-company-modal'
      // style={{ width: '80vw' }}
    >
      <div>
        <Input
          placeholder={formatMessage({ id: 'Company short name / code' })}
          autoComplete='off'
          labelId={formatMessage({ id: 'Company short name / code' })}
          name='slug'
          type='slug'
          formik={formik}
        />
        <label className='form-label fs-6 my-1 fw-bolder text-dark' htmlFor={'company_type'}>
          <FormattedMessage id='Company type' />
        </label>
        <Select
          onChange={(value) => formik.setFieldValue('type', value)}
          onBlur={() => formik.setFieldTouched('type', true)}
          options={CompanyTypesOptions}
          defaultValue={CompanyTypesOptions[0]}
          className='react-select smaller'
          id='company_type'
        />
      </div>

      {/* begin::Form group */}
      <div className='col-xl-4 mt-5'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-sm btn-blue w-100 mb-5 btn-shadow btn-primary-shadow mt-4'
          disabled={formik.isSubmitting}
        >
          {!loading && (
            <span className='indicator-label'>
              <i className='far fa-building mx-2 text-white'></i>
              <FormattedMessage id='Save Company' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Please wait...' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};
