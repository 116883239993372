import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { $t } from '../../../_metronic/i18n/formatMessage';
import { DocumentDirectionTypes } from '../../../_metronic/constants/general';

const useConfigureTemplateUserSchema = () => {
  const { formatMessage } = useIntl();

  /* ----------------------------- formik starter ----------------------------- */
  const initialValues: { [key: string]: any } = useMemo(
    () => ({
      variable_users: [],
    }),
    [formatMessage]
  );

  const required = $t('This field is required');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        variable_users: Yup.array().when(['type'], {
          is: (type: any) => {
            return type === DocumentDirectionTypes.Internal;
          },
          then: (schema) =>
            schema.required(required).min(1, formatMessage({ id: 'Please choose at least one' })),
        }),
      }),
    [formatMessage]
  );

  return {
    initialValues,
    validationSchema,
  };
};

export { useConfigureTemplateUserSchema };
