import clsx from 'clsx';
import { FC } from 'react';

type Props = {
  type: 'bullet' | 'number';
  bulletShow?: boolean;
  count?: number | string;
  faIcon: string;
};

export const Notifications: FC<Props> = ({ type, bulletShow, count, faIcon }) => {
  return (
    <div className={clsx('d-flex align-items-center ms-1 ms-lg-3')}>
      <div className='badge badge-lg badge-circle circular-light-border w-40px h-40px'>
        <i className={faIcon}></i>
      </div>
      {typeof count === 'number' && count > 0 && (
        <span className='pt-1 badge badge-md badge-circle badge-danger notification-number'>
          {count}
        </span>
      )}
      {typeof count === 'string' && (
        <span
          className='pt-1 badge badge-md badge-circle badge-danger notification-number'
          dir='ltr'
        >
          {count}
        </span>
      )}
      {type === 'bullet' && bulletShow && (
        <span className='bullet bullet-dot bg-primary h-6px w-6px translate-middle  animation-blink notification-dot'></span>
      )}
    </div>
  );
};
