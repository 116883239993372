import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { axiosInstance } from '../../../network/apis';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import { Logo } from '../../shared/LogoWithCompany';

export const TermsAndConditionsUs = () => {
  const [data, setData] = useState('');
  const { locale } = useIntl();
  const terms = useRef();

  const getData = async () => {
    try {
      const res = await axiosInstance.get('/pages/terms');
      setData(res.data.body[locale]);
    } catch (err) {
      DisplayErrors(err);
    }
  };

  useEffect(() => {
    getData();
  });

  useEffect(() => {
    terms.current.innerHTML = data;
  }, [data]);

  return (
    <div className='mt-20 pb-20'>
      <div className='text-center'>
        <span className='bg-danger'>
          <Logo />
        </span>
      </div>
      <div ref={terms} className='container mt-20 mb-20'></div>
    </div>
  );
};
