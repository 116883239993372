/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux'; //useDispatch,
import { Prompt, useHistory } from 'react-router-dom';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import PhoneInput from 'react-phone-input-2';
import { useFormik } from 'formik';
import ar from 'react-phone-input-2/lang/ar.json';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Cookies from 'js-cookie';
import moment from 'moment';

import { RootState } from '../../../setup/redux/Store';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../_metronic/layout/core';
import { SeparatorLines } from '../../shared/Lines';
import { CompanyModel } from '../companies/CompanyModel';
// import * as AuthRedux from '../../modules/auth/store/AuthRedux';

import { userStatusColorsMap } from '../../../_metronic/constants/colors';
import { Input } from '../../shared/Input';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { formatDate } from '../../shared/tables/classes/DateViewer';
import { axiosInstance } from '../../../network/apis';
import { useUpdateProfileSchema } from './schema/UpdateProfileSchema';
import { UserModel } from '../auth/models/UserModel';
import { Label } from '../../shared/Label';
import { allowedImagesTypes } from '../../../_metronic/constants/general';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { UserSignature } from './UserSignature';
import { RolesDataListModel } from '../roles/types/Roles';

type Props = {
  mode: 'edit' | 'view';
};

const imageMaxSize = 2048;

export const UserProfile: FC<Props> = ({ mode }) => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const companyDetails: CompanyModel = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as CompanyModel;

  const roles: RolesDataListModel = useSelector<RootState>(
    (state) => state.roles.roles,
    shallowEqual
  ) as RolesDataListModel;

  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { initialValues, validationSchema } = useUpdateProfileSchema();
  const photoRef = useRef<HTMLInputElement | null>(null);
  const { formatMessage, locale } = useIntl();
  const [user, setUser] = useState<UserModel | any>({});
  const [photo, setPhoto] = useState<string | Blob>('');
  const [time, setTime] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(0);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  useEffect(() => {
    if (moment(Cookies.get('resendAfter')).isAfter(moment())) {
      const diff = moment(Cookies.get('resendAfter')).diff(moment());
      setTime(Math.random());
      setResendDisabled(diff);
    } else {
      Cookies.remove('resendAfter');
    }
  }, [resendDisabled, time]);
  /* ----------------------- Getting roles for dropdown ----------------------- */

  const requestProfile = async () =>
    await axiosInstance.get(`/${companyDetails.id}/profile`).then((res) => {
      // dispatch(AuthRedux.actions.setUser(res.data));
      // console.log(res.data);

      setUser(res.data);
    });

  useEffect(() => {
    requestProfile();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {},
  });

  const { errors, touched, values, setFieldValue } = formik;

  useEffect(() => {
    if (user.id) {
      setPhoto(user?.image);
      setFieldValue('first_name', user.first_name);
      setFieldValue('last_name', user.last_name);
      setFieldValue('phone', user.phone);
      setFieldValue('phoneValidity', user?.phone?.length > 0);
      setFieldValue('email', user.email);
      setFieldValue('position_ar', user?.position?.ar);
      setFieldValue('position_en', user?.position?.en);
      setFieldValue(
        'roles',
        user?.roles?.map((role: any) => ({
          value: role?.id.toString(),
          label: role?.name,
        }))
      );
    }
  }, [user]);

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);

  const shouldAsk = () => Object.keys(touched).length > 0;

  return (
    <div>
      {shouldAsk() && <Prompt message={$t('Are you sure you want to ignore the changes?')} />}

      <div className='d-flex flex-row mb-4 gap-15'>
        <p>{user?.name}</p>
        <div className='pl-10 pr-10'>
          <span className='menu-bullet mx-1'>
            <span className='bullet bullet-dot'></span>
          </span>
          {$t('Status')}
          <span className={`badge ${userStatusColorsMap.get(user?.active ? 1 : 0)?.badge} mx-2`}>
            {$t(userStatusColorsMap.get(user?.active ? 1 : 0)?.labelId || 'missing')}
          </span>
        </div>
      </div>

      <div className='content-wrapper bg-white b-radius-25'>
        <div className='d-flex px-5'>
          <div className='px-10 py-3 fs-6' style={{ width: '35%' }}>
            {$t('User Track')}
          </div>
          <div
            className='px-10 py-3 fs-6'
            style={{ width: '65%', borderRight: '1px solid #e1eaf5' }}
          >
            {$t('Personal Information')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5'>
          <div className='p-10' style={{ width: '35%' }}>
            {/* Date Of Profile Creation */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span>{' '}
              <span>{$t('Date Of Profile Creation')}:</span>{' '}
              <span className='text-blueGrey'>{formatDate(user?.created_at)}</span>
            </div>
            <SeparatorLines lines={1} />
            {/*  Date Of Last Seen */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span> <span>{$t('Date Of Last Seen')}:</span>{' '}
              <span className='text-blueGrey'>
                {formatDate(user?.last_seen || user?.created_at)}
              </span>
            </div>
            <SeparatorLines lines={1} />
            {/*  Number Of Created Docs */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span>
              <span>{$t('Number Of Created Docs')}:</span>
              <span className='text-blueGrey'>{user?.docs_count}</span>
            </div>
            <SeparatorLines lines={1} />
            {/*  Number Of Completed Docs */}
            <div className='my-3'>
              <span className='bullet bullet-dot'></span>
              <span>{$t('Number Of Completed Docs')}:</span>
              <span className='text-blueGrey'>{user?.completed_docs_count}</span>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate style={{ width: '65%' }}>
            <div className='p-10' style={{ width: '100%', borderRight: '1px solid #e1eaf5' }}>
              <div className='d-flex flex-row justify-content-between'>
                <div className='symbol symbol-circle avatar-border symbol-60px symbol-md-100px mx-3 mb-10'>
                  {typeof photo === 'string' && (
                    <img
                      src={photo.length <= 0 ? toAbsoluteUrl('/media/avatars/blank.png') : photo}
                    />
                  )}
                  {typeof photo !== 'string' && allowedImagesTypes.includes(photo.type) && (
                    <img src={URL.createObjectURL(photo)} alt='FIle' width='100%' />
                  )}
                  <input
                    type='file'
                    style={{ visibility: 'hidden' }}
                    ref={photoRef}
                    onChange={(e: any) => {
                      const file = e?.target?.files[0];
                      if (!allowedImagesTypes.includes(file?.type)) {
                        toast.error(
                          formatMessage({
                            id: 'You can only upload JPG, SVG,PNG, JPEG files format',
                          })
                        );
                        return;
                      }
                      if (file?.size / 1024 > imageMaxSize) {
                        toast.error(
                          formatMessage(
                            { id: 'The maximum file size is {maxSize}MB' },
                            { maxSize: `${imageMaxSize / 1024}` }
                          )
                        );
                      }
                      setPhoto(e?.target?.files?.[0] || '');
                    }}
                  />
                </div>
                {mode === 'view' && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm btn-primary-shadow'
                      title={$t('Edit user profile')}
                      onClick={() => history.push('/user-profile/edit')}
                    >
                      <i className='fa fa-edit cursor-pointer'></i>
                      {$t('Edit user profile')}
                    </button>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-6'>
                  <Input
                    disabled={mode === 'view'}
                    placeholder={$t('First Name')}
                    autoComplete='off'
                    labelId='First Name'
                    formik={formik}
                    name='first_name'
                  />
                </div>
                <div className='col-6'>
                  <Input
                    disabled={mode === 'view'}
                    placeholder={$t('Last Name')}
                    autoComplete='off'
                    labelId='Last Name'
                    formik={formik}
                    name='last_name'
                  />
                </div>
              </div>
              <div className='col-6'>
                <Input
                  disabled
                  placeholder={$t('Email')}
                  autoComplete='off'
                  labelId='Email'
                  formik={formik}
                  name='email'
                  type='email'
                />
              </div>
              <div className='row mt-3'>
                {/* Hide the unnecessary isRequired*/}
                <Label msg='Phone Number' htmlFor='recipient_type' />
                <div className='col-6 my-3' style={{ maxWidth: 'fit-content' }} dir='ltr'>
                  <PhoneInput
                    disabled={mode === 'view'}
                    localization={locale === 'ar' ? ar : undefined}
                    inputClass='form-control form-control-sm b-radius-16 basic-input'
                    country={'sa'}
                    placeholder={formatMessage({ id: 'Phone' })}
                    value={formik.values.phone}
                    onChange={(phone, data: any) => {
                      const valid = isValidPhoneNumber(
                        phone.slice(data.dialCode.length),
                        data.countryCode
                          .split('')
                          .map((l: string) => l.toUpperCase())
                          .join('')
                      );
                      formik.setFieldValue(`phone`, phone);
                      formik.setTouched({ ...formik.touched, phone: true });
                      formik.setFieldValue(`phoneValidity`, valid);
                    }}
                    onBlur={() => formik.setTouched({ ...formik.touched, phone: true })}
                  />
                </div>
                {errors?.phone && touched.phone && (
                  <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                    <span role='alert' className='text-danger'>
                      {errors?.phone}
                    </span>
                  </div>
                )}
                {touched?.phone && !values.phoneValidity && !errors?.phone && (
                  <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                    <span role='alert' className='text-danger'>
                      <FormattedMessage id='Invalid phone' />
                    </span>
                  </div>
                )}
              </div>

              <div className='row mt-3'>
                <div className='row mt-3'>
                  {locales.map((locale) => (
                    <div className='col-6'>
                      <Input
                        disabled={mode === 'view'}
                        placeholder={$t('Position Name in ' + locale.toUpperCase())}
                        autoComplete='off'
                        labelId={'Position Name in ' + locale.toUpperCase()}
                        formik={formik}
                        name={`position_${locale}`}
                        type={`position_${locale}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className='row mt-3'>
                <label className='form-label fs-6 my-1 fw-bolder text-dark' htmlFor={'roles'}>
                  <FormattedMessage id={'Roles'} />
                </label>
                <Select
                  id='roles'
                  isDisabled={mode === 'view'}
                  placeholder={$t('Roles')}
                  className='react-select smaller'
                  value={formik.values.roles}
                  options={roles?.data?.map((role) => ({
                    value: role.id.toString(),
                    label: role.name,
                  }))}
                  isMulti
                  onChange={(selection) => formik.setFieldValue('roles', selection)}
                />
                {formik?.errors?.roles && formik.touched.roles && (
                  <div className='fv-plugins-message-container mt-2 mx-3'>
                    <span role='alert' className='text-danger'>
                      {formik?.errors?.roles}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className='mt-10'>
        {user?.signatures && (
          <div>
            <UserSignature
              mode={mode}
              signatures={user?.signatures}
              requestProfile={requestProfile}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const UserLinkedProfileView: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'User Profile' })}</PageTitle>
      <UserProfile mode='view' />
    </div>
  );
};

export { UserLinkedProfileView };
