/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../../shared/Input';
import { useHistory } from 'react-router-dom';
import { emailPattern } from '../../../../_metronic/constants/patterns';
import { DisplayErrors } from '../../../../utils/DisplayErrors';
import { axiosInstance } from '../../../../network/apis';
import { $t } from '../../../../_metronic/i18n/formatMessage';

const initialValues = {
  email: '',
};

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const history = useHistory();

  const loginSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .matches(emailPattern, formatMessage({ id: 'Invalid email' }))
          .email(formatMessage({ id: 'Invalid email' }))
          .required(formatMessage({ id: 'This field is required' })),
      }),
    [formatMessage]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post('forgot-password', { email: values.email });
        toast.success(response.data.status);
        formik.resetForm();
        history.push('/');
      } catch (error: any) {
        DisplayErrors(error);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  return (
    <form
      noValidate
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-lg-16 pb-lg-10 p-md-10'
      style={{ backgroundImage: 'linear-gradient(to bottom, #fafbfd, #fff)', borderRadius: '30px' }}
      autoComplete='off'
      onSubmit={formik.handleSubmit}
      id='kt_login_signin_form'
      data-testid='sign-in-form'
    >
      <p>{$t('forget_password_message')}</p>
      <div className='fv-row mb-20 mt-10'>
        <Input
          placeholder={formatMessage({ id: 'Email' })}
          autoComplete='off'
          labelId='Email'
          formik={formik}
          name='email'
          type='email'
        />
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-sm w-100 mb-5 btn-primary btn-shadow btn-primary-shadow'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='Send' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Please wait...' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
