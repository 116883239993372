import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import SVG from 'react-inlinesvg';
import { $t } from '../../../../../_metronic/i18n/formatMessage';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { allowedImagesTypes } from '../../../../../_metronic/constants/general';

// typesOnly = pdfs , images and pdfs-images, xlsx
export const FileUploader = ({
  setFile,
  file,
  maxSize,
  supportedFiles,
  typesOnly,
  disabled = false,
}) => {
  const { formatMessage } = useIntl();
  let allowedOnly = [...supportedFiles];
  return (
    <>
      <div className='file-input d-block' style={{ backgroundColor: 'inherit' }}>
        <input
          style={{
            zIndex: '0',
          }}
          type='file'
          onChange={(e) => {
            const file = e.target.files[0];
            if (!allowedOnly.includes(file?.type)) {
              let msg = 'You can only upload JPG, SVG,PNG, JPEG files format';
              if (typesOnly === 'pdfs') msg = 'You can only upload PDF files format';
              if (typesOnly === 'pdfs-images')
                msg = 'You can only upload JPG, SVG,PNG, JPEG or PDF files format';
              if (typesOnly === 'xlsx') msg = 'You can only upload XLSX, XLS, CSV files format';
              toast.error(formatMessage({ id: msg }));
              return;
            }
            if (file?.size / 1024 > maxSize) {
              toast.error(
                formatMessage(
                  { id: 'The maximum file size is {maxSize}MB' },
                  { maxSize: `${maxSize / 1024}` }
                )
              );
            }

            setFile(e?.target?.files?.[0] || '');
            e.target.value = '';
          }}
          disabled={disabled}
        />
        {!disabled && <span className='button'>{$t('choose-file')}</span>}
        {!disabled && (
          <span className='label' data-js-label>
            {!file && <FormattedMessage id='No file selected' />}
          </span>
        )}
      </div>
      {typeof file === 'string' &&
        !file?.endsWith('.pdf') &&
        !file?.endsWith('.PDF') &&
        file.length > 0 && (
          <div
            className='bgi-no-repeat bgi-size-contain rounded min-h-100px mb-5 px-5'
            style={{
              height: '30%',
              width: '30%',
              backgroundImage: `url('${toAbsoluteUrl(file)}`,
              backgroundPosition: 'center',
            }}
          ></div>
        )}
      {(['application/pdf'].includes(file.type) ||
        (typeof file === 'string' && (file?.endsWith('.pdf') || file?.endsWith('.PDF')))) && (
        <>
          <SVG
            src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
            className='cursor-pointer'
            onClick={() => {
              window.open(file, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
            }}
          />
          {file.name ? file.name : file.split('/')[file.split('/').length - 1] || ''}
        </>
      )}
      {[
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        '.csv',
        'text/csv',
      ].includes(file.type) && (
        <>
          <SVG
            src={toAbsoluteUrl('/media/svg/files/xlsx_icon.svg')}
            className='cursor-pointer'
            style={{ width: '62px' }}
            onClick={() => {
              window.open(file, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
            }}
          />
          {file.name ? file.name : file.split('/')[file.split('/').length - 1] || ''}
        </>
      )}
      {allowedImagesTypes.includes(file.type) && (
        <img src={URL.createObjectURL(file)} alt='FIle' width='25%' />
      )}
    </>
  );
};
