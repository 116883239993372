import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ID } from '../../../../_metronic/constants/paths';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';
import { ProductListModel } from '../types/productList';
// import { user } from '../../../types/user';
import { getProductList, getCurrentProductRequest } from './ProductsCrud';

export enum IProductsActionTypes {
  GET_PRODUCTS = 'GET_PRODUCTS',
  FULLFULL_Products = 'FULLFULL_Products',
  GET_CURRENT_PRODUCT = 'GET_CURRENT_PRODUCT',
  FULLFULL_CURRENT_PRODUCT = 'FULLFULL_CURRENT_PRODUCT ',
}

const initialProductsState: IProductsState = {
  Products: undefined,
  currentProduct: undefined,
};

export interface IProductsState {
  Products?: ProductListModel;
  currentProduct?: any;
}

export const reducer = persistReducer(
  { storage, key: 'Products', whitelist: [''] },
  (state: IProductsState = initialProductsState, action: ActionWithPayload<IProductsState>) => {
    switch (action.type) {
      case IProductsActionTypes.FULLFULL_Products:
        return { ...state, Products: action.payload?.Products };
      case IProductsActionTypes.FULLFULL_CURRENT_PRODUCT:
        return { ...state, currentProduct: action.payload?.currentProduct };
      default:
        return state;
    }
  }
);

type CompanyProductsSearch = { companyId: ID | any; params?: any };
type CurrentProductBasicData = { companyId: ID | any; productId?: ID | any };

export const actions = {
  getProducts: ({ companyId, params }: CompanyProductsSearch) => ({
    type: IProductsActionTypes.GET_PRODUCTS,
    payload: { companyId, params },
  }),
  fulfillProducts: ({ Products }: IProductsState) => ({
    type: IProductsActionTypes.FULLFULL_Products,
    payload: { Products },
  }),
  getCurrentProduct: ({ companyId, productId }: CurrentProductBasicData) => ({
    type: IProductsActionTypes.GET_CURRENT_PRODUCT,
    payload: { companyId, productId },
  }),
  fulfillCurrentProduct: ({ currentProduct }: IProductsState) => ({
    type: IProductsActionTypes.FULLFULL_CURRENT_PRODUCT,
    payload: { currentProduct },
  }),
};

export function* getProductsSaga(action: ActionWithPayload<CompanyProductsSearch>) {
  try {
    // Getting Products request
    const { data } = yield getProductList({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    // Storing products to store
    yield put(actions.fulfillProducts({ Products: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentProduct(action: ActionWithPayload<CurrentProductBasicData>) {
  try {
    // Getting Products request
    const { data } = yield getCurrentProductRequest({
      companyId: action.payload?.companyId,
      productId: action.payload?.productId,
    });
    // Storing products to store
    yield put(actions.fulfillCurrentProduct({ currentProduct: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IProductsActionTypes.GET_PRODUCTS, getProductsSaga);
  yield takeLatest(IProductsActionTypes.GET_CURRENT_PRODUCT, getCurrentProduct);
}
