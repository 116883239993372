import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { Portal } from '../../../_metronic/partials';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { HandWritingSignature } from './components/HandWritingSignature';
import { ImageSignature } from './components/ImageSignature';
import { TypingSignature } from './components/TypingSignature';
import { maxSignatures, TABS } from './UserSignature';
import { UserModel } from '../auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup/redux/Store';

type AddSignatureModalProps = {
  totalSignatures: number;
  setOpenAddSignatureModal: Function;
  selectedTab: number;
  setSelectedTab: Function;
  name: string;
  setName: Function;
  image: string | Blob;
  setImage: Function;
  uploadSignature: (img: any) => Promise<void>;
  signatureImageRef: any;
  withoutClose?: boolean;
  isOneTimeSignature?: boolean;
  displayWarning?: boolean;
  isLoading: boolean;
};
export const AddSignatureModal: FC<AddSignatureModalProps> = ({
  totalSignatures,
  setOpenAddSignatureModal,
  selectedTab,
  setSelectedTab,
  name,
  setName,
  image,
  setImage,
  uploadSignature,
  signatureImageRef,
  withoutClose,
  isOneTimeSignature,
  displayWarning,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  const user: UserModel = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel;

  return (
    <Portal className='main_modal'>
      <AhadDialog
        titleId={$t('Add New Signature')}
        withoutClose={!!withoutClose}
        closeCallBack={() => setOpenAddSignatureModal(false)}
      >
        <div style={{ minWidth: '400px', width: '90%' }}>
          <div className='d-block d-flex justify-content-start flex-column flex-md-row w-90'>
            {TABS.map((tab) => (
              <div
                className={clsx('bg-white h-40px pt-3 px-2 details_nav', {
                  'blts-radius-20': tab.id === 1,
                  'brts-radius-20': tab.id === 3,
                })}
              >
                <span
                  className={clsx('mx-2 cursor-pointer text-blueGrey fs-6', {
                    current: selectedTab === tab.id,
                  })}
                  onClick={() => setSelectedTab(tab.id)}
                >
                  <i className={clsx(`far ${tab.tabIcon} mx-1`)}></i>
                  {$t(tab.tabId)}
                </span>
              </div>
            ))}
          </div>
          {displayWarning && user?.id && (
            <div className='alert alert-info mt-2 mw-400px w-400px'>
              <FormattedMessage id='signature_warning' />
            </div>
          )}
          <section className='company_profile_handler d-flex flex-column justify-content-center align-items-center'>
            {selectedTab === 1 && (
              <ImageSignature
                isLoading={isLoading}
                isOneTimeSignature={!!isOneTimeSignature}
                name={name}
                setName={setName}
                image={image}
                setImage={setImage}
                action={uploadSignature}
                totalSignatures={totalSignatures}
                signatureImageRef={signatureImageRef}
                setOpenAddSignatureModal={setOpenAddSignatureModal}
                maxSignatures={maxSignatures}
              />
            )}
            {selectedTab === 2 && (
              <div className='signature-upload-border  p-3 mt-4 '>
                <HandWritingSignature
                  isOneTimeSignature={isOneTimeSignature}
                  uploadSignature={uploadSignature}
                  name={name}
                  setName={setName}
                  isLoading={isLoading}
                  formatMessage={formatMessage}
                />
              </div>
            )}
            {selectedTab === 3 && (
              <TypingSignature
                isOneTimeSignature={!!isOneTimeSignature}
                uploadSignature={uploadSignature}
                name={name}
                isLoading={isLoading}
                setName={setName}
              />
            )}
          </section>
        </div>
      </AhadDialog>
    </Portal>
  );
};
