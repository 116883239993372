import { FC } from 'react';
import { useIntl } from 'react-intl';

type Props = any;

export const PreviewItemOptions: FC<Props> = ({ ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <select
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 form-select  '
      onChange={(e) => {
        if (e.target.value === 'Detailed document') {
          window.open(
            props.reportLinkes[e.target.value],
            '_blank', // <- This is what makes it open in a new window.
            'toolbar=yes,scrollbars=yes,resizable=yes'
          );
        } else if (e.target.value === 'Short document') {
          window.open(
            props.reportLinkes[e.target.value],
            '_blank', // <- This is what makes it open in a new window.
            'toolbar=yes,scrollbars=yes,resizable=yes'
          );
        } else {
          window.open(
            props.reportLinkes[e.target.value],
            // '_blank', // <- This is what makes it open in a new window.
            'toolbar=yes,scrollbars=yes,resizable=yes'
          );
        }

        e.target.selectedIndex = 0;
      }}
      {...props}
    >
      <option value='' hidden></option>
      {props.reportLinkes['Detailed document']?.length > 0 && (
        <option value='Detailed document' style={{ color: 'black', fontSize: '1.5rem' }}>
          {/* <FormattedMessage id='Detailed document' /> */}
          {formatMessage({ id: 'Detailed document' })}
        </option>
      )}
      {props.reportLinkes['Short document']?.length > 0 && (
        <option value='Short document' style={{ color: 'black', fontSize: '1.5rem' }}>
          {/* <FormattedMessage id='Short document' /> */}
          {formatMessage({ id: 'Short document' })}
        </option>
      )}
      {props.reportLinkes['Detailed table']?.length > 0 && (
        <option value='Detailed table' style={{ color: 'black', fontSize: '1.5rem' }}>
          {formatMessage({ id: 'Detailed table' })}
        </option>
      )}
      {props.reportLinkes['Transactions table']?.length > 0 && (
        <option value='Transactions table' style={{ color: 'black', fontSize: '1.5rem' }}>
          {formatMessage({ id: 'Transactions table' })}
        </option>
      )}
      {props.reportLinkes['Transaction Statement']?.length > 0 && (
        <option value='Transaction Statement' style={{ color: 'black', fontSize: '1.5rem' }}>
          {formatMessage({ id: 'Transaction Statement' })}
        </option>
      )}
    </select>
  );
};
