import 'react-phone-input-2/lib/style.css';

import {
  ClientTypes,
  PersonDecisionTypes,
  AccountTypes,
  ThirdPartyTypes,
} from '../../types/CompanyTypes';
import { ContactErrors, ContactTouched } from './ContactErrors';
import { FC, useEffect, useMemo, useState } from 'react';
import { FieldArray, FormikProvider, useFormik, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt, useHistory, useParams } from 'react-router-dom'; //, useLocation
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import * as ClientsRedux from '../clients/store/ClientsRedux';
import { $t } from '../../../_metronic/i18n/formatMessage';
import { CloseButtonRedShadowed } from '../../shared/CloseButtonRedShadowed';
import { DisplayErrors } from '../../../utils/DisplayErrors';
import useExitPrompt from '../../hooks/useExitPrompt/useExitPrompt';
import { usePermissions } from '../../hooks/usePermissions';
import { FileUploader } from '../../pages/Companies/CompanyDetails/components/FileUploader';
import { ICompany } from '../../types/companies';
import { Label } from '../../shared/Label';
import { Input } from '../../shared/Input';
// import { TableData } from '../../shared/tables/classes/TableData';
// import { EditItemWithModal } from '../../shared/tables/actions/EditItemWithModal';

// import { PageTitle } from '../../../_metronic/layout/core';
import PhoneInput from 'react-phone-input-2';
import { RootState } from '../../../setup/redux/Store';
// import SaveCancelBtnsGroup from '../../shared/SaveCancelBtnsGroup';
import Select from 'react-select';
import { SelectOptions } from '../../types/SelectOptions';
import { allowedImagesTypes } from '../../../_metronic/constants/general';
import ar from 'react-phone-input-2/lang/ar.json';
import { ThirdPartyTypeMap, AccountTypeMap } from '../../../_metronic/constants/general';
import { toSelectOption } from '../../../_metronic/helpers/functions/Select';
import { axiosInstance } from '../../../network/apis';
import clsx from 'clsx';
import { countires } from '../../../_metronic/constants/countries';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { languages } from '../../../_metronic/constants/languages';
import { serialize } from 'object-to-formdata';
import { toast } from 'react-toastify';
import { useAddClientSchema } from './useAddClientSchema';
import { useQuery } from 'react-query';
import AhadDialog from '../../../setup/shared/AhadDialog';
import { Portal } from '../../../_metronic/partials';
import { ErrorMessage } from '../../shared/ErrorMessage';
import { ErrorItemWarning } from '../../shared/tables/actions/ErrorItemWarning';
import { ErrorItemDanger } from '../../shared/tables/actions/ErrorItemDanger';

// type Props = {
//   isDisplay?: boolean;
// };
type Props = {
  mode: 'create' | 'edit' | 'view';
  displayRequiredSection?: number | string;
  onSelectAccountType?: number | string;
};

const fileMaxSize = 2 * 1024;
const allowedFiles = ['application/pdf', 'image/jpeg', 'jpeg', 'png', 'image/png', 'image/svg+xml'];

export const ClientsAddEdit: FC<Props> = ({ mode, displayRequiredSection }) => {
  usePermissions(['create-third_party']);
  const { userCan, userCanNot } = usePermissions();
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  // const location = useLocation();
  const [tax_number_document, setTax_number_document] = useState<any>('');
  const { initialValues, createNewCompaySchema } = useAddClientSchema();
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const [logo, setLogo] = useState<any>('');
  const [loading, setLoading] = useState(false);
  // const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  // const [editItemId, setEditItemId] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessageID, setModalMessageID] = useState<any>('');
  const [showErrorsConfirmationModal, setShowErrorsConfirmationModal] = useState(false);
  const [errorsConfirmation, setErrorsConfirmation] = useState<any>([]);

  /* ------------------------- getting data from store ------------------------ */
  const companyDetails: ICompany = useSelector<RootState>(
    (state) => state?.USER_companies.companyDetails,
    shallowEqual
  ) as ICompany;

  const displayedTabs: any = useSelector<RootState>(
    (state) => state?.USER_clients.displayedTabs,
    shallowEqual
  ) as any;

  const client: any = useSelector<RootState>(
    (state) => state?.USER_clients.singleUser,
    shallowEqual
  ) as any;

  /* ----------------------- end getting data from store ---------------------- */
  /* ----------------------------- formik starter ----------------------------- */
  const formik = useFormik({
    initialValues,
    validationSchema: createNewCompaySchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const payload: any = {};
      if (values.type && values.type.value) {
        payload['type'] = values.type.value;
      }
      payload['name'] = { ar: '', en: '' };
      payload['name']['ar'] = values.name_ar;
      payload['name']['en'] = values.name_en;
      payload['id_number'] = values.id_number;
      payload['tax_number'] = values.tax_number;
      payload['status'] = values.status;

      payload['country'] = values.country;
      payload['city'] = values.city;
      payload['district'] = values.district;
      payload['zip'] = values.zip;
      payload['address'] = {};
      payload['address']['ar'] = values.address_ar;
      payload['address']['en'] = values.address_en;
      payload['phone'] = values.phone.length > 5 ? values.phone : '';
      payload['account_type'] = values.account_type.value;
      payload['third_party_type'] = values.third_party_type.value;
      payload['reference_number'] = values.reference_number;
      payload['short_name'] = values.short_name;
      payload['tax_registered'] = values.tax_registered;

      if (+values.third_party_type.value === ThirdPartyTypes.Both) {
        payload['categories'] = values.purchases_categories.concat(values.sales_categories);
      } else if (+values.third_party_type.value === ThirdPartyTypes.Client) {
        payload['categories'] = values.sales_categories;
      } else if (+values.third_party_type.value === ThirdPartyTypes.Vendor) {
        payload['categories'] = values.purchases_categories;
      }

      if (values.type && values.type.value === ClientTypes.Entity) {
        // if (
        //   values?.contacts?.every(
        //     (contact: any) => contact.status.value !== PersonDecisionTypes.Approver
        //   )
        // ) {
        //   toast.error(formatMessage({ id: 'At least one approver needed' }));
        //   return;
        // }
        payload['registration_number'] = values.registration_number;

        payload['website'] = values.website;

        if (values.account_type.value === AccountTypes.Account) {
          payload['contacts'] = values?.contacts?.map((contact: any) => ({
            ...contact,
            status: contact.status.value,
          }));
        } else {
          delete payload['contacts'];
        }
      }

      if (values.type && values.type.value === ClientTypes.Individual) {
        payload['email'] = values.email;
        payload['phone'] = values.phone.length > 5 ? values.phone : '';
        payload['license_number'] = values.license_number;
      }

      if (
        [tax_number_document].some((file) => {
          return file?.size ? file.size / 1024 / 1024 > fileMaxSize : false;
        })
      ) {
        toast.error(
          formatMessage(
            { id: 'The maximum file size is {maxSize}MB' },
            { maxSize: `${fileMaxSize}` }
          )
        );
        setLoading(false);
        setSubmitting(false);
        return;
      }
      const formData = serialize(payload, { indices: true });

      if (typeof tax_number_document !== 'string')
        formData.append('registration_certificate', tax_number_document);

      if (logo) {
        formData.append('image', logo);
      }
      // console.log(values);
      try {
        if (mode === 'create') {
          await axiosInstance.post(`${companyDetails.id}/clients`, formData);
          toast.success(formatMessage({ id: 'Client has been successfully added!' }));
        } else if (mode === 'edit') {
          formData.append('_method', 'PUT');
          await axiosInstance.post(`${companyDetails.id}/clients/${client.id}`, formData);
          toast.success(formatMessage({ id: 'Client has been successfully updated!' }));
        }
        formik.resetForm();
        history.push(`/c/${companyDetails.slug}/company-management/clients-and-vendors`);
      } catch (err: any) {
        DisplayErrors(err);
        setSubmitting(false);
      }
      setLoading(false);
    },
  });

  const { values, errors, touched, setFieldValue } = formik;
  /* --------------------------- end formik starter --------------------------- */
  /* --------------------------- submitHandler --------------------------- */
  // const submitHandler = () => {
  // if (client?.show_warning && (!formik.isValid || Object.keys(errors).length === 0)) {
  //   setShowConfirmationModal(true);
  // } else {
  // formik.submitForm();
  // }
  // };

  /* --------------------------- end submitHandler --------------------------- */

  /* ------------------------- Get sales categories  ------------------------ */

  const { isLoading: salesCategoriesLoading, data: salesCategoriesList } = useQuery(
    'addClientSalesCategories',
    () =>
      axiosInstance(`/categories`, {
        params: {
          type: `1`,
          per_page: 0,
        },
      }).then((res) => {
        return res.data;
      }),
    { retryDelay: 300, retry: 3 }
  );
  // console.log('salesCategoriesList', salesCategoriesList);
  // set default selected values for sales categories

  useEffect(() => {
    if (!salesCategoriesLoading && salesCategoriesList?.sales?.length > 0) {
      formik.setFieldValue(
        `sales_categories`,
        salesCategoriesList?.sales
          ?.filter((category: any) => {
            return category.is_default === 1;
          })
          .map((category: any) => {
            return `${category.id}`;
          })
      );
    }
  }, [salesCategoriesList, formik.values.third_party_type]);

  /* -------------------------end Get sales categories  ------------------------ */
  /* ------------------------- Get Purchases categories  ------------------------ */

  const { isLoading: purchasesCategoriesLoading, data: purchasesCategoriesList } = useQuery(
    'addClientPurchasesCategories',
    () =>
      axiosInstance(`/categories`, {
        params: {
          type: `2`,
          per_page: 0,
        },
      }).then((res) => {
        return res.data;
      }),
    { retryDelay: 300, retry: 3 }
  );
  // console.log('purchasesCategoriesList', purchasesCategoriesList);
  // set default selected values for purchases categories

  useEffect(() => {
    if (!purchasesCategoriesLoading && purchasesCategoriesList?.purchases?.length > 0) {
      formik.setFieldValue(
        `purchases_categories`,
        purchasesCategoriesList?.purchases
          ?.filter((category: any) => {
            return category.is_default === 1;
          })
          .map((category: any) => {
            return `${category.id}`;
          })
      );
    }
  }, [purchasesCategoriesList, formik.values.third_party_type]);

  /* -------------------------end Get sales categories  ------------------------ */

  /* ----------------------------- select options ----------------------------- */
  const CompanyTypesOptions: SelectOptions = useMemo(
    () => [
      { value: ClientTypes.Individual, label: formatMessage({ id: 'Individual' }) },
      { value: ClientTypes.Entity, label: formatMessage({ id: 'Entity' }) },
    ],
    [formatMessage]
  );

  const ContactTypesOptions: SelectOptions = useMemo(
    () => [
      { value: PersonDecisionTypes.Approver, label: formatMessage({ id: 'Approver' }) },
      { value: PersonDecisionTypes['Not Approver'], label: formatMessage({ id: 'Not Approver' }) },
    ],
    [formatMessage]
  );

  const ThirdPartyTypesOptions = useMemo(() => {
    return Array.from(ThirdPartyTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const AccountTypesOptions = useMemo(() => {
    return Array.from(AccountTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);
  /* --------------------------- end select options --------------------------- */

  /* ------------------------- edit and display fetch ------------------------- */
  // const { data: client, refetch: getClientDetails } = useQuery(
  //   'Client',
  //   () =>
  //     axiosInstance(`${companyDetails.id}/clients/${params.clientId}`).then((res) => {
  //       return res.data;
  //     }),
  //   { enabled: false, keepPreviousData: false, cacheTime: 0 }
  // );
  function RequestData() {
    dispatch(
      ClientsRedux.actions.getSingleClient({
        userId: params.clientId,
        companyId: companyDetails.id,
      })
    );
  }
  useEffect(() => {
    if (params?.clientId) {
      RequestData();
    }
  }, [params]);

  useEffect(() => {
    if (client?.id) {
      if (client.logo) setLogo(client.logo);
      setFieldValue(
        'status',
        client?.is_disabled ? (client.is_disabled ? 1 : 0) : initialValues.status
      );
      setFieldValue(
        'tax_registered',
        client?.tax_registered ? (client.tax_registered ? 1 : 0) : initialValues.status
      );
      if (client.type)
        setFieldValue(
          'type',
          client.type === ClientTypes.Individual
            ? { value: ClientTypes.Individual, label: formatMessage({ id: 'Individual' }) }
            : {
                value: ClientTypes.Entity,
                label: formatMessage({ id: 'Entity' }),
              }
        );

      setFieldValue(
        'short_name',
        client?.short_name ? client.short_name : initialValues.short_name
      );
      setFieldValue('name_en', client?.name?.en ? client.name.en : initialValues.name_en);
      setFieldValue('name_ar', client?.name?.ar ? client.name.ar : initialValues.name_ar);
      setFieldValue(
        'reference_number',
        client.reference_number ? client.reference_number : initialValues.reference_number
      );
      setFieldValue('phone', client.phone ? client.phone : initialValues.phone);
      // setFieldValue('phoneValidity', client.phone ? true : false);
      setFieldValue('email', client.email ? client.email : initialValues.email);
      setFieldValue(
        'license_number',
        client.license_number ? client.license_number : initialValues.license_number
      );
      setFieldValue('slug', client.slug ? client.slug : initialValues.slug);
      setFieldValue(
        'registration_number',
        client.registration_number ? client.registration_number : initialValues.registration_number
      );
      setFieldValue('tax_number', client.tax_number ? client.tax_number : initialValues.tax_number);
      setFieldValue('id_number', client.id_number ? client.id_number : initialValues.id_number);
      setFieldValue('website', client.website ? client.website : initialValues.website);

      const country = countires?.find((country: any) => client?.country?.value === country?.iso);

      setFieldValue(
        'country',
        client.country
          ? country
            ? { label: country.emoji + ' ' + country.name[locale], value: country.iso }
            : null
          : initialValues.country
      );
      setFieldValue('city', client.city ? client.city : initialValues.city);
      setFieldValue(
        'address_en',
        client?.address?.en ? client.address.en : initialValues.address_en
      );
      setFieldValue(
        'address_ar',
        client?.address?.ar ? client.address.ar : initialValues.address_ar
      );
      setFieldValue('district', client?.district ? client.district : initialValues.district);
      setFieldValue('zip', client?.zip ? client.zip : initialValues.zip);
      setFieldValue('image', client?.image ? client.image : initialValues.image);
      setFieldValue(
        'account_type',
        AccountTypesOptions.filter(
          (AccountTypesOption) => AccountTypesOption.value === client?.account_type
        )[0]
      );
      setFieldValue(
        'third_party_type',
        ThirdPartyTypesOptions.filter(
          (ThirdPartyOption) => ThirdPartyOption.value === client?.third_party_type
        )[0]
      );
      formik.setFieldValue(
        `sales_categories`,
        client.categories?.sales?.map((category: any) => {
          return `${category.id}`;
        })
      );
      formik.setFieldValue(
        `purchases_categories`,
        client.categories?.purchases?.map((category: any) => {
          return `${category.id}`;
        })
      );
      setFieldValue(
        'contacts',
        client?.contacts?.map((contact: any) => ({
          name: { en: contact?.name?.en || contact?.name, ar: contact?.name?.ar || contact?.name },
          title: {
            en: contact?.title?.en || contact?.title,
            ar: contact?.title?.ar || contact?.title,
          },
          email: contact.email,
          status:
            contact.status === 'Approved'
              ? { value: PersonDecisionTypes.Approver, label: formatMessage({ id: 'Approver' }) }
              : {
                  value: PersonDecisionTypes['Not Approver'],
                  label: formatMessage({ id: 'Not Approver' }),
                },
          is_receiver: contact.is_receiver ? 1 : 0,
          phone: contact.phone ? contact.phone.toString() : '',
          phoneValidity: true,
        }))
      );
      setTax_number_document(client.registration_certificate);
    }
  }, [client]);

  useEffect(() => {
    if (client?.id) {
      if (client.logo) setLogo(client.logo);
      setFieldValue(
        'status',
        client?.is_disabled ? (client.is_disabled ? 1 : 0) : initialValues.status
      );
      setFieldValue(
        'tax_registered',
        client?.tax_registered ? (client.tax_registered ? 1 : 0) : initialValues.status
      );

      setFieldValue(
        'short_name',
        client?.short_name ? client.short_name : initialValues.short_name
      );
      setFieldValue('name_en', client?.name?.en ? client.name.en : initialValues.name_en);
      setFieldValue('name_ar', client?.name?.ar ? client.name.ar : initialValues.name_ar);
      setFieldValue(
        'reference_number',
        client.reference_number ? client.reference_number : initialValues.reference_number
      );
      setFieldValue('phone', client.phone ? client.phone : initialValues.phone);
      // setFieldValue('phoneValidity', client.phone ? true : false);
      setFieldValue('email', client.email ? client.email : initialValues.email);
      setFieldValue(
        'license_number',
        client.license_number ? client.license_number : initialValues.license_number
      );
      setFieldValue('slug', client.slug ? client.slug : initialValues.slug);
      setFieldValue(
        'registration_number',
        client.registration_number ? client.registration_number : initialValues.registration_number
      );
      setFieldValue('tax_number', client.tax_number ? client.tax_number : initialValues.tax_number);
      setFieldValue('id_number', client.id_number ? client.id_number : initialValues.id_number);
      setFieldValue('website', client.website ? client.website : initialValues.website);

      const country = countires?.find((country: any) => client?.country?.value === country?.iso);

      setFieldValue(
        'country',
        client.country
          ? country
            ? { label: country.emoji + ' ' + country.name[locale], value: country.iso }
            : null
          : initialValues.country
      );
      setFieldValue('city', client.city ? client.city : initialValues.city);
      setFieldValue(
        'address_en',
        client?.address?.en ? client.address.en : initialValues.address_en
      );
      setFieldValue(
        'address_ar',
        client?.address?.ar ? client.address.ar : initialValues.address_ar
      );
      setFieldValue('district', client?.district ? client.district : initialValues.district);
      setFieldValue('zip', client?.zip ? client.zip : initialValues.zip);
      setFieldValue('image', client?.image ? client.image : initialValues.image);
      setFieldValue(
        'account_type',
        AccountTypesOptions.filter(
          (AccountTypesOption) => AccountTypesOption.value === client?.account_type
        )[0]
      );
      setFieldValue(
        'third_party_type',
        ThirdPartyTypesOptions.filter(
          (ThirdPartyOption) => ThirdPartyOption.value === client?.third_party_type
        )[0]
      );
      formik.setFieldValue(
        `sales_categories`,
        client.categories?.sales?.map((category: any) => {
          return `${category.id}`;
        })
      );
      formik.setFieldValue(
        `purchases_categories`,
        client.categories?.purchases?.map((category: any) => {
          return `${category.id}`;
        })
      );
      setFieldValue(
        'contacts',
        client?.contacts?.map((contact: any) => ({
          name: { en: contact?.name?.en || contact?.name, ar: contact?.name?.ar || contact?.name },
          title: {
            en: contact?.title?.en || contact?.title,
            ar: contact?.title?.ar || contact?.title,
          },
          email: contact.email,
          status:
            contact.status === 'Approved'
              ? { value: PersonDecisionTypes.Approver, label: formatMessage({ id: 'Approver' }) }
              : {
                  value: PersonDecisionTypes['Not Approver'],
                  label: formatMessage({ id: 'Not Approver' }),
                },
          is_receiver: contact.is_receiver ? 1 : 0,
          phone: contact.phone ? contact.phone.toString() : '',
          phoneValidity: true,
        }))
      );
      setTax_number_document(client.registration_certificate);
    }
  }, [displayedTabs]);

  const toggleThirdParty = async () => {
    setFieldValue('status', formik.values.status === 0 ? 1 : 0);
    setShowModal(false);
  };
  const toggleTaxRegistered = async () => {
    setFieldValue('tax_registered', formik.values.tax_registered === 0 ? 1 : 0);
  };

  useEffect(() => {
    // console.log('formik.values', formik.values);
    if (formik.values.account_type.value === 2) {
      dispatch(
        ClientsRedux.actions.setDisplayedTabs({
          displayedTabs: [1, 4],
        })
      );
    } else if (formik.values?.type?.value === 2) {
      dispatch(
        ClientsRedux.actions.setDisplayedTabs({
          displayedTabs: [1, 2, 4],
        })
      );
    } else {
      dispatch(
        ClientsRedux.actions.setDisplayedTabs({
          displayedTabs: [1, 2, 3, 4],
        })
      );
    }
  }, [formik.values?.account_type?.value, formik.values?.type?.value]);

  const shouldAsk = () => Object.keys(touched).length > 0;

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
      dispatch(ClientsRedux.actions.fullFillDisplayedTabs({}));
      dispatch(ClientsRedux.actions.fulfillSingleUser({}));
    };
  }, []);

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='row my-3'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex justify-content-start'>
              {/* Errors */}
              <>
                {userCan('view-third_party') && client?.errors?.warning && (
                  <ErrorItemWarning
                    title={$t('Has errors')}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setErrorsConfirmation(
                        client?.errors?.warning?.map((error: any) => error?.text)
                      );
                      setShowErrorsConfirmationModal(true);
                    }}
                  />
                )}
                {userCan('view-third_party') && client?.errors?.danger && (
                  <ErrorItemDanger
                    title={$t('Has errors')}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setErrorsConfirmation(
                        client?.errors?.danger.map((error: any) => error?.text)
                      );
                      setShowErrorsConfirmationModal(true);
                    }}
                  />
                )}
              </>
              {/* Badges */}
              {/* {mode !== 'create' &&
                Object.entries(client ? client.errors_with_color : {}).map(([key, value]) => {
                  const badge = new TableData({
                    text: ``,
                    errors_with_color: value,
                  });
                  return <div className='m-2'>{badge.renderItem()}</div>;
                })} */}
            </div>
            {/* {mode === 'view' && userCan('update-third_party') && (
                <div
                  className='d-flex justify-content-end'
                  style={{ textAlign: 'end', alignSelf: 'center' }}
                >
                  <EditItemWithModal
                    id={client?.id}
                    title={$t('Edit')}
                    isButton
                    buttonMessageId={'Edit third party'}
                    messageId={
                      client?.can_update
                        ? client?.show_warning
                          ? 'This third party has been selected in created sale/purchase, are you sure you want to proceed the editing process?'
                          : ''
                        : 'This third party is included in a approved or draft report, are you sure you want to proceed the editing process?'
                    }
                    editURI={`/c/${companyDetails.slug}/company-management/clients-and-vendors/${client?.id}/edit`}
                    showButtonGroup={true}
                    editItemId={editItemId}
                    setEditItemId={setEditItemId}
                  />
                </div>
              )} */}
          </div>
        </div>
        {shouldAsk() && <Prompt message={$t('Are you sure you want to ignore the changes?')} />}
        <FormikProvider value={formik}>
          <form
            noValidate
            autoComplete='none'
            id='create_third_party'
            onSubmit={formik.handleSubmit}
          >
            <div className='row'>
              {displayRequiredSection === 1 && (
                <>
                  {/*  ------------------------ company/client short name en/ar -----------------------  */}
                  <div className='col-md-6 my-3 '>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Third party short name',
                      })}
                      required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Third party short name',
                      })}
                      name={'short_name'}
                      type={'short_name'}
                      formik={formik}
                      disabled={client ? mode === 'view' || !client?.can_update : mode === 'view'}
                    />
                  </div>
                  {/*  --------------------- end company/client short name en/ar -----------------------  */}
                  {/*  ------------------------Third party code/ Reference number -----------------------  */}
                  <div className='col-md-6 my-3'>
                    <Input
                      setTouchedOnInput
                      placeholder={formatMessage({
                        id: 'Third party code',
                      })}
                      // required
                      autoComplete='off'
                      labelId={formatMessage({
                        id: 'Third party code',
                      })}
                      name={'reference_number'}
                      type={'text'}
                      formik={formik}
                      disabled={mode === 'view'}
                    />
                  </div>
                  {/*  --------------------- end Third party code/ Reference number-----------------------  */}
                  {/*  ------------------------ ACCOUNT type -----------------------  */}
                  <div className='col-6'>
                    <Label
                      msg='Account type'
                      htmlFor='account_type'
                      isRequired={true}
                      // isRequired={mode === 'create'}
                    />
                    <Select
                      isDisabled={
                        client ? mode !== 'create' || !client?.can_update : mode !== 'create'
                      }
                      onChange={(value) => setFieldValue('account_type', value)}
                      onBlur={() => formik.setFieldTouched('account_type', true)}
                      options={AccountTypesOptions}
                      value={formik.values.account_type}
                      className='react-select smaller'
                      id='account_type'
                    />
                  </div>
                  {/*  --------------------- end ACCOUNT type -----------------------  */}
                  {/*  ------------------------ third party type -----------------------  */}
                  <div className='col-6 mb-3'>
                    <Label msg='Third party type' htmlFor='third_party_type' isRequired={true} />
                    <Select
                      isDisabled={
                        client ? mode === 'view' || client?.third_party_type === 3 : mode === 'view'
                      } //
                      onChange={(value) => setFieldValue('third_party_type', value)}
                      onBlur={() => formik.setFieldTouched('third_party_type', true)}
                      options={
                        mode !== 'edit'
                          ? ThirdPartyTypesOptions
                          : client?.third_party_type === 1
                          ? ThirdPartyTypesOptions.filter((option) => option.value !== 2)
                          : ThirdPartyTypesOptions.filter((option) => option.value !== 1)
                      }
                      value={formik.values.third_party_type}
                      className='react-select smaller'
                      id='third_party_type'
                    />
                  </div>
                  {/*  --------------------- end third party type -----------------------  */}

                  {/*  ------------------------ company/client long name en/ar -----------------------  */}
                  {locales.map((locale) => (
                    <div className='col-md-6 my-3'>
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id:
                            'Third party name in ' +
                            languages.find((lcl) => lcl.locale === locale)?.name_en,
                        })}
                        // required
                        autoComplete='off'
                        labelId={formatMessage({
                          id:
                            'Third party name in ' +
                            languages.find((lcl) => lcl.locale === locale)?.name_en,
                        })}
                        name={'name_' + locale}
                        type={'name_' + locale}
                        formik={formik}
                        disabled={mode === 'view'} //client ? mode === 'view' || !client?.can_update :
                      />
                    </div>
                  ))}
                  {/*  --------------------- end company/client long name en/ar -----------------------  */}

                  {/*  --------------------- Country -----------------------  */}
                  <div className='col-md-6 mb-3'>
                    <Label msg='Country' htmlFor='country-select' isRequired={true} />
                    <Select
                      placeholder={$t('Country')}
                      className='react-select smaller'
                      id='country-select'
                      value={formik.values.country}
                      onChange={(value) => {
                        formik.setFieldValue('country', value);
                        formik.setFieldValue('city', null);
                        formik.setFieldValue('district', null);
                        formik.setFieldValue('zip', null);
                      }}
                      options={countires?.map((country: any) => ({
                        value: country.iso,
                        label: country?.emoji + ' ' + country?.name?.[locale],
                      }))}
                      isDisabled={mode === 'view'}
                    />
                  </div>
                  {/*  --------------------- end Country -----------------------  */}

                  {/*  ------------------------ company type -----------------------  */}
                  <div className='col-6'>
                    {+values.account_type.value === AccountTypes.Account && (
                      <>
                        <label
                          className='form-label fs-6 my-1 fw-bolder text-dark required'
                          htmlFor={'company_type'}
                        >
                          <FormattedMessage id='Client/Vendor type' />
                        </label>
                        <Select
                          placeholder={$t('Client/Vendor type')}
                          onChange={(value) => formik.setFieldValue('type', value)}
                          onBlur={() => formik.setFieldTouched('type', true)}
                          options={CompanyTypesOptions}
                          value={formik.values.type}
                          className='react-select smaller'
                          id='company_type'
                          isDisabled={mode === 'view'}
                        />
                        {/* error message  */}
                        {errors?.type && formik.touched.type && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id={'This field is required'} />
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/*  ------------------------ end company type -----------------------  */}

                  {/*  ------------------------ third party status -----------------------  */}
                  {userCan('disable-third_party') && (
                    <div className='col-6 my-3'>
                      <label className='form-check form-switch form-check-custom form-check-solid'>
                        {/* Disable third party */}
                        <input
                          className='form-check-input'
                          type='checkbox'
                          title={$t('Disable third party')}
                          onClick={(e) => {
                            if (e.currentTarget.checked) {
                              setModalMessageID(
                                "If you disabled this third party, other users won't be able to select it in sales/purchases records anymore as client/supplier"
                              );
                            } else {
                              setModalMessageID(
                                'If you enabled this third party, other users will be able to select it in sales/purchases records as client/supplier'
                              );
                            }
                            setShowModal(true);
                          }}
                          checked={formik.values.status === 1 ? true : false}
                          disabled={mode === 'view'}
                        />
                        <span className='form-check-label fw-bold text-black-400'>
                          <FormattedMessage id='Disable third party' />
                        </span>
                      </label>
                    </div>
                  )}
                  {/*  ------------------------end third party status -----------------------  */}

                  {+values.account_type.value === AccountTypes.Account && (
                    <>
                      {/*  ------------------------ tax registered status -----------------------  */}
                      <div className='col-6 my-3'>
                        <label className='form-check form-switch form-check-custom form-check-solid'>
                          {/*  tax registered */}
                          <input
                            className='form-check-input'
                            type='checkbox'
                            title={$t('Tax registered')}
                            onClick={(e) => {
                              // if (e.currentTarget.checked) {
                              //   setFieldValue('tax_registered', 1);
                              // } else {
                              //   setFieldValue('tax_registered', 0);
                              // }
                              toggleTaxRegistered();
                            }}
                            checked={formik.values.tax_registered === 1 ? true : false}
                            disabled={mode === 'view'}
                            // !client?.tax_number
                            //   ? mode === 'view'
                            //   : client
                            //   ? mode === 'view' || !client?.can_update
                            //   : mode === 'view'
                          />
                          <span className='form-check-label fw-bold text-black-400'>
                            <FormattedMessage id='Tax registered' />
                          </span>
                        </label>
                      </div>
                      {/*  ------------------------end tax registered status -----------------------  */}

                      <div className='row'>
                        {/* ----------------- Company Commercial Registration Number ----------------- */}
                        {values.type && +values.type.value === ClientTypes.Entity && (
                          <div className='col-md-6'>
                            <Input
                              setTouchedOnInput
                              placeholder={formatMessage({
                                id: 'Company Commercial Registration Number',
                              })}
                              labelId={formatMessage({
                                id: 'Company Commercial Registration Number',
                              })}
                              formik={formik}
                              name='registration_number'
                              disabled={mode === 'view'}
                            />
                          </div>
                        )}
                        {/* --------------- end Company Commercial Registration Number --------------- */}

                        {/* --------------------- 'Company Tax Number' /'Tax ID' -------------------- */}
                        {/* 
                        <div className='col-md-4'>
                          <Input
                            setTouchedOnInput
                            required={+values.tax_registered === 1 ? true : false}
                            placeholder={formatMessage({
                              id:
                                +values.type.value === ClientTypes.Entity
                                  ? 'Company Tax Number'
                                  : 'Tax ID',
                            })}
                            labelId={formatMessage({
                              id:
                                +values.type.value === ClientTypes.Entity
                                  ? 'Company Tax Number'
                                  : 'Tax ID',
                            })}
                            formik={formik}
                            name='tax_number'
                            disabled={
                              client ? mode === 'view' || !client?.can_update : mode === 'view'
                            }
                          />
                        </div> */}
                        {/* -----------------  End 'Company Tax Number' /'Tax ID' -------------------- */}
                        {/* ----------------------- License Number/ 'Unified ID' / 'ID Number' ----------------------- */}
                        {values.type && (
                          <div className='col-md-6'>
                            <Input
                              setTouchedOnInput
                              placeholder={formatMessage({
                                id:
                                  values.type && +values.type.value === ClientTypes.Entity
                                    ? 'Unified ID'
                                    : 'ID Number',
                              })}
                              labelId={formatMessage({
                                id:
                                  values.type && +values.type.value === ClientTypes.Entity
                                    ? 'Unified ID'
                                    : 'ID Number',
                              })}
                              formik={formik}
                              name='id_number'
                              disabled={mode === 'view'}
                            />
                          </div>
                        )}

                        {values.type && +values.type.value === ClientTypes.Individual && (
                          <div className='col-md-6'>
                            <Input
                              setTouchedOnInput
                              placeholder={formatMessage({ id: 'License Number' })}
                              labelId={formatMessage({ id: 'License Number' })}
                              formik={formik}
                              name='license_number'
                              disabled={mode === 'view'}
                            />
                          </div>
                        )}

                        {/* ---------------------End 'Unified ID' / 'ID Number' ----------------------- */}
                      </div>
                    </>
                  )}
                </>
              )}

              {displayRequiredSection === 2 && (
                <>
                  {+values.account_type.value === AccountTypes.Account && (
                    <>
                      {/* -------------------------------- Location -------------------------------- */}
                      {/* ------------------------------ city options ------------------------------ */}
                      <div className='col-md-4'>
                        <Label msg='City' htmlFor='city-select' />
                        <Select
                          isDisabled={mode === 'view' || !values?.country?.value?.length}
                          placeholder={$t('City')}
                          className='react-select smaller'
                          onChange={(value) => formik.setFieldValue('city', value)}
                          onBlur={() => formik.setFieldTouched('city', true)}
                          value={formik.values.city}
                          id='city-select'
                          options={countires
                            .find((country: any) => country.iso === values?.country?.value)
                            ?.cities?.map((city: any) => ({
                              value: city.iso.toString(),
                              label: city?.name?.[locale],
                            }))}
                        />

                        {/* error message  */}
                        {errors?.city && formik.touched.city && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id={'This field is required'} />
                            </span>
                          </div>
                        )}
                      </div>
                      {/* ---------------------------- end city options ---------------------------- */}
                      {/* -------------------------------- district/zip -------------------------------- */}
                      <div className='col-md-4'>
                        <Input
                          setTouchedOnInput
                          placeholder={formatMessage({ id: 'District' })}
                          labelId={formatMessage({ id: 'District' })}
                          formik={formik}
                          name='district'
                          disabled={mode === 'view'}
                        />
                      </div>
                      <div className='col-md-4'>
                        <Input
                          setTouchedOnInput
                          placeholder={$t('Zip Code')}
                          labelId={$t('Zip Code')}
                          formik={formik}
                          name='zip'
                          disabled={mode === 'view'}
                        />
                      </div>
                      <div className='col-md-6'>
                        <Input
                          setTouchedOnInput
                          placeholder={$t('Address Information') + $t(' (AR)')}
                          labelId={$t('Address Information') + $t(' (AR)')}
                          formik={formik}
                          name='address_ar'
                          disabled={mode === 'view'}
                        />
                      </div>
                      <div className='col-md-6'>
                        <Input
                          setTouchedOnInput
                          placeholder={$t('Address Information') + $t(' (EN)')}
                          labelId={$t('Address Information') + $t(' (EN)')}
                          formik={formik}
                          name='address_en'
                          disabled={mode === 'view'}
                        />
                      </div>
                      {/* ----------------------------- End Location -------------------------------- */}
                      {/* ----------------------------- start Phone -------------------------------- */}
                      <div className='col-md-6'>
                        <Label msg='Phone Number' htmlFor='recipient_type' />
                        <div className=' my-3' dir='ltr' style={{ maxWidth: 'fit-content' }}>
                          <PhoneInput
                            localization={locale === 'ar' ? ar : undefined}
                            inputClass='form-control form-control-lg b-radius-16 basic-input'
                            country={'sa'}
                            placeholder={formatMessage({ id: 'Phone Number' })}
                            value={values.phone}
                            onChange={(phone, data: any) => {
                              const valid = isValidPhoneNumber(
                                phone.slice(data.dialCode.length),
                                data.countryCode
                                  .split('')
                                  .map((l: string) => l.toUpperCase())
                                  .join('')
                              );
                              formik.setFieldValue(`phone`, phone);
                              formik.setTouched({ ...formik.touched, phone: true });
                              formik.setFieldValue(`phoneValidity`, valid);
                            }}
                            onBlur={() => formik.setTouched({ ...formik.touched, phone: true })}
                            disabled={mode === 'view'}
                          />
                        </div>
                        {/* {errors?.phone && touched.phone && (
<div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
  <span role='alert' className='text-danger'>
    {errors?.phone}
  </span>
</div>
)} */}
                        {touched?.phone && !values.phoneValidity && !errors?.phone && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id='Invalid phone' />
                            </span>
                          </div>
                        )}
                      </div>
                      {/* ----------------------------- End Phone -------------------------------- */}

                      {/* ----------------------------- Email -------------------------------- */}

                      {values.type && +values.type.value === ClientTypes.Individual && (
                        <div className='col-6'>
                          <Input
                            setTouchedOnInput
                            placeholder={formatMessage({ id: 'Email' })}
                            labelId={formatMessage({ id: 'Email' })}
                            autoComplete='off'
                            formik={formik}
                            name='email'
                            type='text'
                            error={errors.email}
                            disabled={mode === 'view'}
                          />
                        </div>
                      )}
                      {/* -----------------------------end Email -------------------------------- */}

                      {/* --------------------------------- Website -------------------------------- */}
                      {values.type && +values.type.value === ClientTypes.Entity && (
                        <div className='col-md-6'>
                          <label
                            className={clsx('form-label fs-6 my-1 fw-bolder text-dark')}
                            htmlFor={'website'}
                          >
                            <FormattedMessage id='Website' />
                          </label>
                          <div className='website-field d-flex' dir='ltr'>
                            {!values?.website?.startsWith('http:') &&
                              !values?.website?.startsWith('www.') && (
                                <span className='text-lightGreyBlue mt-3 mx-1'>www.</span>
                              )}
                            <div className='flex-fill' dir={locale === 'ar' ? 'rtl' : 'ltr'}>
                              <Input
                                setTouchedOnInput
                                id='website'
                                placeholder={formatMessage({ id: 'Website' })}
                                autoComplete='off'
                                formik={formik}
                                type='website'
                                name='website'
                                disabled={mode === 'view'}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {/* ------------------------------- end website ------------------------------ */}

                      {/* ------------------------------ Company Logo ------------------------------ */}
                      {values.type && +values.type.value === ClientTypes.Entity && (
                        <div className='col-md-12'>
                          <label
                            className={clsx('form-label fs-6 my-1 fw-bolder text-dark')}
                            htmlFor={'city-select'}
                          >
                            <FormattedMessage id='Company Logo' />
                          </label>
                          <FileUploader
                            disabled={mode === 'view'}
                            setFile={setLogo}
                            file={logo}
                            maxSize={2048}
                            supportedFiles={allowedImagesTypes}
                            typesOnly='images'
                          />
                        </div>
                      )}
                      {/* ---------------------------- end company logo ---------------------------- */}
                    </>
                  )}
                </>
              )}

              {displayRequiredSection === 3 && (
                <>
                  {/*  ----------------------------- init form array ----------------------------  */}
                  {values.type &&
                    +values.type.value === ClientTypes.Entity &&
                    +values.account_type.value === AccountTypes.Account && (
                      <FieldArray name='contacts'>
                        {({ push, remove }) => {
                          const error: ContactErrors[] = errors?.contacts as ContactErrors[];
                          // const value = values.contacts;
                          const touched: any = formik.touched.contacts as ContactTouched[];
                          return (
                            <div className='d-flex flex-column justify-content-between p-5'>
                              <div className='row px-5 py-3'>
                                <FormattedMessage id='Company Contacts Details' />
                              </div>
                              <div style={{ border: '1px solid #dee8f3', borderRadius: '20px' }}>
                                <div>
                                  {values.contacts?.map((user: any, i: any, arr: any) => {
                                    return (
                                      <div
                                        key={JSON.stringify(i)}
                                        className='row d-flex flex-row p-5'
                                        style={{
                                          borderBottom:
                                            i !== arr.length - 1 ? '1px solid #dee8f3' : '',
                                        }}
                                      >
                                        <div className='row'>
                                          <div className='col-11'>
                                            <div className='row'>
                                              <div className={arr.length < 2 ? 'col-6' : 'col-6'}>
                                                <label
                                                  className={clsx(
                                                    'form-label fs-6 my-1 fw-bolder text-dark '
                                                  )}
                                                  htmlFor={
                                                    formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (AR)' })
                                                  }
                                                >
                                                  {formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (AR)' })}
                                                </label>
                                                <Input
                                                  setTouchedOnInput
                                                  disabled={mode === 'view'}
                                                  placeholder={
                                                    formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (AR)' })
                                                  }
                                                  id={
                                                    formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (AR)' })
                                                  }
                                                  autoComplete='off'
                                                  formik={formik}
                                                  name={`contacts[${i}]['name']['ar']`}
                                                  type='text'
                                                  error={
                                                    error?.length &&
                                                    error?.[i] &&
                                                    error?.[i].name?.ar
                                                  }
                                                  touched={touched?.[i]?.name?.ar}
                                                />
                                              </div>
                                              <div className={arr.length < 2 ? 'col-6' : 'col-6'}>
                                                <label
                                                  className={clsx(
                                                    'form-label fs-6 my-1 fw-bolder text-dark '
                                                  )}
                                                  htmlFor={
                                                    formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (EN)' })
                                                  }
                                                >
                                                  {formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (EN)' })}
                                                </label>
                                                <Input
                                                  setTouchedOnInput
                                                  disabled={mode === 'view'}
                                                  placeholder={
                                                    formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (EN)' })
                                                  }
                                                  id={
                                                    formatMessage({ id: 'Name' }) +
                                                    formatMessage({ id: ' (EN)' })
                                                  }
                                                  autoComplete='off'
                                                  formik={formik}
                                                  name={`contacts[${i}].name['en']`}
                                                  type='text'
                                                  error={
                                                    error?.length &&
                                                    error?.[i] &&
                                                    error?.[i]?.name?.en
                                                  }
                                                  touched={touched?.[i]?.name?.en}
                                                />
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className={arr.length < 2 ? 'col-6' : 'col-6'}>
                                                <Input
                                                  setTouchedOnInput
                                                  disabled={mode === 'view'}
                                                  placeholder={
                                                    formatMessage({ id: 'Job Title' }) +
                                                    formatMessage({ id: ' (AR)' })
                                                  }
                                                  labelId={
                                                    formatMessage({ id: 'Job Title' }) +
                                                    formatMessage({ id: ' (AR)' })
                                                  }
                                                  autoComplete='off'
                                                  formik={formik}
                                                  name={`contacts[${i}]['title']['ar']`}
                                                  type='text'
                                                  error={
                                                    error?.length &&
                                                    error?.[i] &&
                                                    error?.[i]?.title?.ar
                                                  }
                                                  touched={touched?.[i]?.title?.ar}
                                                />
                                              </div>
                                              <div className={arr.length < 2 ? 'col-6' : 'col-6'}>
                                                <Input
                                                  setTouchedOnInput
                                                  disabled={mode === 'view'}
                                                  placeholder={
                                                    formatMessage({ id: 'Job Title' }) +
                                                    formatMessage({ id: ' (EN)' })
                                                  }
                                                  labelId={
                                                    formatMessage({ id: 'Job Title' }) +
                                                    formatMessage({ id: ' (EN)' })
                                                  }
                                                  autoComplete='off'
                                                  formik={formik}
                                                  name={`contacts[${i}]['title']['en']`}
                                                  type='text'
                                                  error={
                                                    error?.length &&
                                                    error?.[i] &&
                                                    error?.[i]?.title?.en
                                                  }
                                                  touched={touched?.[i]?.title?.en}
                                                />
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-6'>
                                                <label
                                                  className={clsx(
                                                    'form-label fs-6 my-1 fw-bolder text-dark '
                                                  )}
                                                  htmlFor={'Email'}
                                                >
                                                  <FormattedMessage id='Email' />
                                                </label>
                                                <Input
                                                  disabled={mode === 'view'}
                                                  setTouchedOnInput
                                                  placeholder={formatMessage({ id: 'Email' })}
                                                  id='Email'
                                                  autoComplete='off'
                                                  formik={formik}
                                                  name={`contacts[${i}][email]`}
                                                  type='text'
                                                  error={
                                                    error?.length && error?.[i] && error?.[i].email
                                                  }
                                                  touched={touched?.[i]?.email}
                                                />
                                              </div>
                                              <div className='col-6'>
                                                <label
                                                  className={clsx(
                                                    'form-label fs-6 my-1 fw-bolder text-dark '
                                                  )}
                                                  htmlFor={'company_type'}
                                                >
                                                  <FormattedMessage id='Status' />
                                                </label>
                                                <Select
                                                  isDisabled={mode === 'view'}
                                                  onChange={(value) =>
                                                    formik.setFieldValue(
                                                      `contacts[${i}][status]`,
                                                      value
                                                    )
                                                  }
                                                  onBlur={() =>
                                                    formik.setFieldTouched(
                                                      `contacts[${i}][status]`,
                                                      true
                                                    )
                                                  }
                                                  value={formik.values.contacts[i].status}
                                                  options={ContactTypesOptions}
                                                  defaultValue={ContactTypesOptions[0]}
                                                  className='react-select smaller'
                                                  id='company_type'
                                                />
                                              </div>
                                              <div className='col-6 mt-3'>
                                                <label
                                                  className={clsx(
                                                    'form-label fs-6 my-1 fw-bolder text-dark '
                                                  )}
                                                  htmlFor={'Phone'}
                                                >
                                                  <FormattedMessage id='Phone Number' />
                                                </label>
                                                <div dir='ltr' style={{ maxWidth: 'max-content' }}>
                                                  <PhoneInput
                                                    disabled={mode === 'view'}
                                                    inputClass='form-control form-control-lg b-radius-16 basic-input my-1'
                                                    country={'sa'}
                                                    localization={locale === 'ar' ? ar : undefined}
                                                    placeholder={formatMessage({ id: 'Phone' })}
                                                    value={values.contacts[i].phone}
                                                    inputProps={{
                                                      name: `contacts[${i}][phone]`,
                                                    }}
                                                    onChange={(phone, data: any) => {
                                                      const isValid = isValidPhoneNumber(
                                                        phone.slice(data.dialCode.length),
                                                        data.countryCode
                                                          .split('')
                                                          .map((l: string) => l.toUpperCase())
                                                          .join('')
                                                      );
                                                      formik.setFieldValue(
                                                        `contacts[${i}].phone`,
                                                        phone
                                                      );
                                                      formik.setFieldValue(
                                                        `contacts[${i}].phoneValidity`,
                                                        isValid
                                                      );
                                                    }}
                                                    onBlur={() => {
                                                      formik.setTouched({
                                                        ...formik.touched,
                                                        contacts: touched
                                                          ? [...touched].map((x, index) => {
                                                              return i === index
                                                                ? { ...x, phone: true }
                                                                : { ...x };
                                                            })
                                                          : [[]].map((x, index) => {
                                                              return i === index
                                                                ? { ...x, phone: true }
                                                                : { ...x };
                                                            }),
                                                      });
                                                    }}
                                                  />
                                                  {touched?.[i]?.phone && error?.[i]?.phone && (
                                                    <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                      <span role='alert' className='text-danger'>
                                                        {error?.[i]?.phone}
                                                      </span>
                                                    </div>
                                                  )}
                                                  {/* {touched?.[i]?.phone &&
                                                    !value?.[i]?.phoneValidity &&
                                                    !error?.[i]?.phone && (
                                                      <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                                                        <span role='alert' className='text-danger'>
                                                          <FormattedMessage id='Invalid phone' />
                                                        </span>
                                                      </div>
                                                    )} */}
                                                </div>
                                              </div>
                                              <div className='col-6 my-auto'>
                                                <input
                                                  disabled={mode === 'view'}
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='recieve_a_copy'
                                                  checked={
                                                    formik.values.contacts[i].is_receiver === 0
                                                      ? false
                                                      : true
                                                  }
                                                  defaultChecked={false}
                                                  onChange={(value) => {
                                                    return formik.setFieldValue(
                                                      `contacts[${i}][is_receiver]`,
                                                      value.target.checked ? 1 : 0
                                                    );
                                                  }}
                                                  onBlur={() =>
                                                    formik.setFieldTouched(
                                                      `contacts[${i}][is_receiver]`,
                                                      true
                                                    )
                                                  }
                                                />
                                                <label
                                                  className={clsx(
                                                    'form-check-label fw-bold ps-2 fs-6  my-1 fw-bolder text-dark'
                                                  )}
                                                  htmlFor={'recieve_a_copy'}
                                                >
                                                  <FormattedMessage id='Receive a copy' />
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          {arr.length > 1 &&
                                            (mode === 'create' || mode === 'edit') && (
                                              <div className='col-1 mt-3'>
                                                <CloseButtonRedShadowed onClick={() => remove(i)} />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              {(mode === 'create' || mode === 'edit') && (
                                <button
                                  className='btn btn-outlined w-100 mt-4'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return push({
                                      name: { en: '', ar: '' },
                                      title: { en: '', ar: '' },
                                      email: '',
                                      status: {
                                        value: 1,
                                        label: formatMessage({ id: 'Approver' }),
                                      },
                                      phone: '',
                                      phoneValidity: false,
                                    });
                                  }}
                                >
                                  <i className='far fa-user'></i>
                                  <FormattedMessage id='Add Member' />
                                </button>
                              )}
                            </div>
                          );
                        }}
                      </FieldArray>
                    )}
                  {/*  ----------------------------- end FieldArray ----------------------------  */}
                </>
              )}

              {displayRequiredSection === 4 && (
                <>
                  <div className='row mb-10'>
                    {values.type && (
                      <div className='col-md-6'>
                        <Input
                          setTouchedOnInput
                          required={+values.tax_registered === 1 ? true : false}
                          placeholder={formatMessage({
                            id:
                              values.type && +values.type.value === ClientTypes.Entity
                                ? 'Company Tax Number'
                                : 'Tax ID',
                          })}
                          labelId={formatMessage({
                            id:
                              values.type && +values.type.value === ClientTypes.Entity
                                ? 'Company Tax Number'
                                : 'Tax ID',
                          })}
                          formik={formik}
                          name='tax_number'
                          disabled={mode === 'view'}
                        />
                      </div>
                    )}

                    <div className='col-md-6 mt-3'>
                      <FileUploader
                        setFile={setTax_number_document}
                        file={tax_number_document}
                        maxSize={2048}
                        supportedFiles={allowedFiles}
                        disabled={userCanNot('view-company') || mode === 'view'}
                        typesOnly='pdfs-images'
                      />
                    </div>
                  </div>
                  <div className='row mb-10'>
                    {/*  ---------------------  select sales categories-----------------------  */}

                    {(+values.third_party_type.value === ThirdPartyTypes.Client ||
                      +values.third_party_type.value === ThirdPartyTypes.Both) && (
                      <div className='col-6'>
                        <Label msg='Sales categories' htmlFor='sales_category' isRequired />
                        <div role='group' aria-labelledby='checkbox-group' className='mt-3'>
                          {!salesCategoriesLoading &&
                            salesCategoriesList?.sales.length > 0 &&
                            salesCategoriesList?.sales?.map((category: any) => (
                              <>
                                <div className='form-check form-check-custom form-check-solid '>
                                  {' '}
                                  <label className='form-check form-switch form-check-custom form-check-solid'>
                                    {/*  tax registered */}
                                    <Field
                                      key={category.id}
                                      type='checkbox'
                                      className='form-check-box'
                                      title={category.name}
                                      value={`${category.id}`}
                                      name='sales_categories'
                                      checked={formik.values?.sales_categories?.includes(
                                        `${category.id}`
                                      )}
                                      disabled={mode === 'view'}
                                    />
                                    <span className='form-check-label fw-bold text-black-400'>
                                      {category.name}
                                    </span>
                                  </label>
                                </div>
                              </>
                            ))}
                          <ErrorMessage name='sales_categories' formik={formik} />
                        </div>
                      </div>
                    )}
                    {(+values.third_party_type.value === ThirdPartyTypes.Vendor ||
                      +values.third_party_type.value === ThirdPartyTypes.Both) && (
                      <div className='col-6'>
                        <Label msg='Purchases categories' htmlFor='purchases_category' isRequired />
                        <div role='group' aria-labelledby='checkbox-group' className='mt-3'>
                          {!purchasesCategoriesLoading &&
                            purchasesCategoriesList?.purchases.length > 0 &&
                            purchasesCategoriesList?.purchases?.map((category: any) => (
                              <>
                                <div className='form-check form-check-custom form-check-solid '>
                                  {' '}
                                  <label className='form-check form-switch form-check-custom form-check-solid'>
                                    {/*  tax registered */}
                                    <Field
                                      key={category.id}
                                      type='checkbox'
                                      className='form-check-box'
                                      title={category.name}
                                      value={`${category.id}`}
                                      name='purchases_categories'
                                      checked={formik.values?.purchases_categories?.includes(
                                        `${category.id}`
                                      )}
                                      disabled={mode === 'view'}
                                    />
                                    <span className='form-check-label fw-bold text-black-400'>
                                      {category.name}
                                    </span>
                                  </label>
                                </div>
                              </>
                            ))}
                          <ErrorMessage name='purchases_categories' formik={formik} />
                        </div>
                      </div>
                    )}

                    {/*  --------------------- end select sales categories-----------------------  */}
                  </div>
                </>
              )}

              {(mode === 'create' || mode === 'edit') && (
                <>
                  <div className='col-md-8 mt-3'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <button
                          className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                          disabled={formik.isSubmitting}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            formik.submitForm();

                            // submitHandler();
                          }}
                        >
                          {!loading && (
                            <span className='indicator-label'>
                              <FormattedMessage id={'Save'} />
                            </span>
                          )}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              <FormattedMessage id='Please wait...' />
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className='col-md-4'>
                        <button
                          onClick={() => {
                            history.push(
                              `/c/${companyDetails.slug}/company-management/clients-and-vendors`
                            );
                          }}
                          type='button'
                          id='kt_login_signup_form_cancel_button'
                          className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                        >
                          <FormattedMessage id='Cancel' />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
                // <SaveCancelBtnsGroup
                //   saveBtnLabel='save'
                //   formik={formik}
                //   loading={loading}
                //   cancelOnClick={() => {
                //     history.push(`/c/${companyDetails.slug}/company-management/clients-and-vendors`);
                //   }}
                // />
              )}
            </div>
            {/* {showConfirmationModal && (
            <Portal className='full_modal'>
              <AhadDialog
                closeCallBack={
                  () => setShowConfirmationModal(false)

                  // history.push(location.pathname.replace('/invite-users', '/users'))
                }
                specialContainerClass='dialog-content-container'
                specialTitleFormat={<></>}
              >
                <>
                  <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                    <FormattedMessage id='This third party has been selected in created sale/purchase, are you sure you want to apply these changes?' />
                  </div>
                  <div className='col-md-8'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <button
                          className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                          disabled={formik.isSubmitting}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            formik.submitForm();
                          }}
                        >
                          {!loading && (
                            <span className='indicator-label'>
                              <FormattedMessage id={'Save'} />
                            </span>
                          )}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              <FormattedMessage id='Please wait...' />
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className='col-md-6'>
                        <button
                          onClick={() => {
                            setShowConfirmationModal(false);
                          }}
                          type='button'
                          id='kt_login_signup_form_cancel_button'
                          className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                        >
                          <FormattedMessage id='Cancel' />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </AhadDialog>
            </Portal>
          )} */}
          </form>
        </FormikProvider>

        {showModal && modalMessageID && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={
                () => setShowModal(false)

                // history.push(location.pathname.replace('/invite-users', '/users'))
              }
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '65%' }}>
                  <FormattedMessage id={modalMessageID} />
                </div>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-sm w-100 btn-primary btn-shadow btn-primary-shadow'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleThirdParty();
                        }}
                      >
                        <span className='indicator-label'>
                          <FormattedMessage id={'Confirm'} />
                        </span>
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        onClick={() => {
                          setShowModal(false);
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}

        {/*  ---------------------warnings confirmation Modal-----------------------  */}
        {showErrorsConfirmationModal && errorsConfirmation && (
          <Portal className='full_modal'>
            <AhadDialog
              closeCallBack={() => setShowErrorsConfirmationModal(false)}
              specialContainerClass='dialog-content-container'
              specialTitleFormat={<></>}
            >
              <>
                <div className='row mb-8 m-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                  {errorsConfirmation.length === 1 && (
                    <span className='min-w-250px'>{errorsConfirmation[0]}</span>
                  )}
                  {errorsConfirmation.length > 1 && (
                    <ol>
                      {errorsConfirmation.map((warning: any, index: any) => (
                        <li className='min-w-250px' key={index}>
                          {warning}
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
                {/* <div className='row mb-8 mt-2' style={{ fontSize: '1.5rem', width: '85%' }}>
                <span>{errorsConfirmation}</span>
              </div> */}
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          // setApproveWarnings(0);
                          setShowErrorsConfirmationModal(false);
                          // formik.submitForm();
                        }}
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-sm btn-danger btn-shadow btn-danger-shadow w-100 badge-gray shadowed'
                      >
                        <FormattedMessage id='Cancel' />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </AhadDialog>
          </Portal>
        )}
        {/*  ---------------------end warnings confirmation Modal-----------------------  */}
      </div>
    </>
  );
};

// const AddClientsWrapper: FC = () => {
//   usePermissions(['create-third_party']);
//   const { formatMessage } = useIntl();
//   return (
//     <div className='d-flex flex-column flex-root'>
//       <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Add third party' })}</PageTitle>
//       <ClientsAddEdit mode='create' />
//     </div>
//   );
// };

// const ClientsViewWrapper: FC = () => {
//   usePermissions(['view-third_party']);
//   const { formatMessage } = useIntl();
//   return (
//     <div className='d-flex flex-column flex-root'>
//       <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Preview third party' })}</PageTitle>
//       <ClientsAddEdit mode='view' />
//     </div>
//   );
// };

// const ClientsEditWrapper: FC = () => {
//   usePermissions(['update-third_party']);
//   const { formatMessage } = useIntl();
//   return (
//     <div className='d-flex flex-column flex-root'>
//       <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Edit third party' })}</PageTitle>
//       <ClientsAddEdit mode='edit' />
//     </div>
//   );
// };

// export { AddClientsWrapper, ClientsViewWrapper, ClientsEditWrapper };
