import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../../interface/ActionWithPayload';
import { getAllCompaniesSubscriptionPlans } from './SubscriptionPlansCRUD';

export enum ISubscriptionPlansActionTypes {
  GET_SUBSCRIPTION_PLANS = 'GET_SUBSCRIPTION_PLANS',
  FILL_FULL_SUBSCRIPTION_PLANS = 'FILL_FULL_SUBSCRIPTION_PLANS',
}

const initialSubscriptionPlansState: ISubscriptionPlansState = {
  subscription_plans: undefined,
};

export interface ISubscriptionPlansState {
  subscription_plans?: any;
}

export const reducer = persistReducer(
  { storage, key: 'SYS_subscription_plans', whitelist: [''] },
  (
    state: ISubscriptionPlansState = initialSubscriptionPlansState,
    action: ActionWithPayload<ISubscriptionPlansState>
  ) => {
    switch (action.type) {
      case ISubscriptionPlansActionTypes.FILL_FULL_SUBSCRIPTION_PLANS:
        return {
          ...state,
          subscription_plans: action.payload?.subscription_plans,
        };

      default:
        return state;
    }
  }
);
type CompaniesSearch = { params?: any };

export const actions = {
  getSubscriptionPlans: ({ params }: CompaniesSearch) => ({
    type: ISubscriptionPlansActionTypes.GET_SUBSCRIPTION_PLANS,
    payload: { params },
  }),
  fulFillSubscriptionPlans: ({ subscription_plans }: ISubscriptionPlansState) => ({
    type: ISubscriptionPlansActionTypes.FILL_FULL_SUBSCRIPTION_PLANS,
    payload: { subscription_plans },
  }),
};

export function* getSubscriptionPlans(action: ActionWithPayload<CompaniesSearch>) {
  try {
    const { data } = yield getAllCompaniesSubscriptionPlans({
      params: action.payload?.params,
    });
    yield put(actions.fulFillSubscriptionPlans({ subscription_plans: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(ISubscriptionPlansActionTypes.GET_SUBSCRIPTION_PLANS, getSubscriptionPlans);
}
