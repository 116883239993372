import { axiosInstance } from '../../../../network/apis';
import { endpoints, ID } from '../../../../_metronic/constants/paths';

export const companyReports = endpoints.companyReports;
export const companyTransactions = endpoints.companyTransactions;
export const companySingleReport = endpoints.companySingleReport;

export function getAllReports({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyReports(companyId), { params });
}

export function getAllTransactions({ companyId, params }: { companyId: ID; params?: any }) {
  return axiosInstance.get(companyTransactions(companyId), { params });
}

export function getReport({ companyId, reportId }: { companyId: ID; reportId: ID }) {
  return axiosInstance.get(companySingleReport(companyId, reportId));
}
