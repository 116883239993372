/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'; //
import { Prompt, useHistory } from 'react-router-dom'; //useLocation,
import { serialize } from 'object-to-formdata';
import { Form, FormikProvider, useFormik } from 'formik';

// import { Alert } from 'react-bootstrap-v5';
import Select from 'react-select';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { formatDate } from '../../../shared/tables/classes/DateViewer';

import 'react-intl-tel-input/dist/main.css';

import { DisplayErrors } from '../../../../utils/DisplayErrors';

import { $t } from '../../../../_metronic/i18n/formatMessage';
import { PageTitle } from '../../../../_metronic/layout/core';
import { SeparatorLines } from '../../../shared/Lines';

import {
  CodeRulesCategoryTypeMap,
  CodeRulesThirdPartyTypeMap,
  CodeRulesOperatorTypeMap,
  CodeRulesColumnTypeMap,
} from '../../../../_metronic/constants/general'; //DocumentDirection,
import { toSelectOption } from '../../../../_metronic/helpers/functions/Select';

import { axiosInstance } from '../../../../network/apis';
// import clsx from 'clsx';
import { countires } from '../../../../_metronic/constants/countries';

import { initialConfigPayload } from '../../../modules/admins/code_rules/helpers/codeRulesConfigHelpers';

import { useConfigureCodeRulesSchema } from './useConfigureCodeRulesSchema';
import { Input } from '../../../shared/Input';
import { Label } from '../../../shared/Label';
import { TextArea } from '../../../shared/textarea';

import SaveCancelBtnsGroup from '../../../shared/SaveCancelBtnsGroup';
import useExitPrompt from '../../../hooks/useExitPrompt/useExitPrompt';

type Props = {
  mode: 'create' | 'edit' | 'view';
};

export const ConfigNewCodeRule: FC<Props> = ({ mode }) => {
  const { setShowExitPrompt, setDisable } = useExitPrompt(false);
  const { codeRulesInitialValues, codeRulesValidationSchema } = useConfigureCodeRulesSchema();

  const history = useHistory();
  const { formatMessage, locale } = useIntl();

  const [loading, setLoading] = useState(false);
  const locales = locale === 'en' ? ['en', 'ar'] : ['ar', 'en'];

  /* ----------------------------- Getting Templates ----------------------------- */

  const createCodeRule = async (values: any, resetForm: any) => {
    setLoading(true);
    const payload: { [key: string]: any } = initialConfigPayload({
      ...values,
    });
    payload['name'] = { ar: '', en: '' };
    payload['name']['ar'] = values.name_ar.trim();
    payload['name']['en'] = values.name_en.trim();
    payload['error_message'] = { ar: '', en: '' };
    payload['error_message']['ar'] = values.error_message_ar.trim();
    payload['error_message']['en'] = values.error_message_en.trim();
    payload['category'] = values.category.value;
    if (values.category.value === categoryTypesOptions[0].value) {
      var startDate = new Date(values.start_date);
      startDate.setTime(startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000); //Adjust for Timezone
      let date_range = [startDate.toISOString()];
      if (values.present === 0) {
        var endDate = new Date(values.end_date);
        endDate.setTime(endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000); //Adjust for Timezone
        date_range.push(endDate.toISOString());
        // date_range.push(values.end_date);
      }
      payload['date_range'] = date_range;
    } else if (values.category.value === categoryTypesOptions[1].value) {
      let amount_range = [values.min_amount];
      if (
        values.max_amount &&
        values.max_amount !== '' &&
        values.max_amount !== 0 &&
        values.max_amount > values.min_amount
      ) {
        amount_range.push(values.max_amount);
      }
      payload['amount_range'] = amount_range;
    } else if (values.category.value === categoryTypesOptions[2].value) {
      let countries = {
        include: [],
        exclude: [],
      };
      if (values.is_not === 0) {
        countries.include = values.nationalities.map((country: any) => country.value);
      }
      if (values.is_not === 1) {
        countries.exclude = values.nationalities.map((country: any) => country.value);
      }
      payload['countries'] = countries;
    } else if (values.category.value === categoryTypesOptions[3].value) {
      payload['third_party_account_types'] = values.third_party_types.map(
        (type: any) => type.value
      );
    } else if (values.category.value === categoryTypesOptions[4].value) {
      payload['operator'] = values.operator.value;
      payload['column'] = values.column.value;
    }
    // console.log(values);

    delete payload['start_date'];
    delete payload['end_date'];
    delete payload['min_amount'];
    delete payload['max_amount'];
    delete payload['nationalities'];
    delete payload['third_party_types'];
    delete payload['present'];
    delete payload['is_not'];
    const formData = serialize(payload, { indices: true });
    // console.log(payload);

    try {
      await axiosInstance.post(`/admin/rules`, formData);
      resetForm();
      toast.success(formatMessage({ id: 'Code rule has been created successfully!' }));
      history.push(`/admin/code-rules`);
    } catch (err) {
      DisplayErrors(err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: codeRulesInitialValues,
    validationSchema: codeRulesValidationSchema,
    onSubmit: (values, { resetForm }) => createCodeRule(values, resetForm),
  });
  const { setFieldValue, setFieldTouched } = formik; //, values

  const shouldAsk = () => Object.keys(formik.touched).length > 0;

  /* -------------------------------- STORE -------------------------------- */
  /* ------------------------- getting data from store ------------------------ */

  /* ----------------------- end getting data from store ---------------------- */
  const categoryTypesOptions = useMemo(() => {
    return Array.from(CodeRulesCategoryTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const categoryTypesOptionsWithCondition = useMemo(() => {
    const res = categoryTypesOptions;
    res.pop();
    return res;
  }, []);
  const codeRulesThirdPartyTypeMapsOptions = useMemo(() => {
    return Array.from(CodeRulesThirdPartyTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const codeRulesOperatorTypeMapsOptions = useMemo(() => {
    return Array.from(CodeRulesOperatorTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  const codeRulesColumnTypeMapsOptions = useMemo(() => {
    return Array.from(CodeRulesColumnTypeMap.values())
      .flat()
      .map((option) => toSelectOption(option, locale));
  }, [locale]);

  /* -------------------------------- On Init -------------------------------- */

  useEffect(() => {
    setShowExitPrompt(shouldAsk());
  }, [formik]);

  useEffect(() => {
    return () => {
      setDisable();
      setShowExitPrompt(false);
    };
  }, []);
  return (
    <div>
      <div className='content-wrapper bg-white b-radius-25 '>
        <div className='d-flex px-5 '>
          <div className='d-flex justify-content-center px-10 py-3 fs-6 ' style={{ width: '100%' }}>
            {mode === 'create' && $t('Create Code Rule')}
            {mode === 'view' && $t('Preview Code Rule')}
            {mode === 'edit' && $t('Edit Code Rule')}
          </div>
        </div>
        <SeparatorLines lines={2} />
        <div className='d-flex px-5 '>
          <FormikProvider value={formik}>
            <Form id='create_code_rule' noValidate autoComplete='none' style={{ width: '100%' }}>
              {Object.keys(formik.touched).length > 0 && (
                <Prompt message={$t('Are you sure you want to ignore the changes?')} />
              )}
              <div className='p-10 ' style={{ width: '100%' }}>
                <div className='row'>
                  {/*  ------------------------ Code Rule Name -----------------------  */}
                  {locales.map((locale) => (
                    <div className='col-md-6'>
                      <Label
                        msg={formatMessage({
                          id: 'Code name in ' + locale.toUpperCase(),
                        })}
                        htmlFor={'Code name in ' + locale.toUpperCase()}
                        isRequired
                      />
                      <Input
                        setTouchedOnInput
                        placeholder={formatMessage({
                          id: 'Code name in ' + locale.toUpperCase(),
                        })}
                        required
                        autoComplete='off'
                        name={'name_' + locale}
                        type={'text'}
                        formik={formik}
                        disabled={mode === 'view'}
                      />
                    </div>
                  ))}
                  {/*  --------------------- end Code Rule Name-----------------------  */}
                  {/*  ------------------------ Code Rule Comparison Category -----------------------  */}
                  <div className='col-6'>
                    <Label
                      msg='Comparison Category'
                      htmlFor='comparison_category'
                      isRequired={mode === 'create'}
                    />
                    <Select
                      isDisabled={mode === 'view'}
                      onChange={(value) => setFieldValue('category', value)}
                      onBlur={() => formik.setFieldTouched('category', true)}
                      options={categoryTypesOptionsWithCondition}
                      defaultValue={categoryTypesOptions[0]}
                      className='react-select smaller'
                      id='comparison_category'
                    />
                  </div>
                  <div className='col-6'></div>
                  {/*  --------------------- end Code Rule Comparison Category -----------------------  */}
                  {/*  ------------------------ Code Rule Error Message en/ar -----------------------  */}
                  {locales.map((locale) => (
                    <div className='col-md-6 mt-2'>
                      <Label
                        msg={formatMessage({
                          id: 'Code Rule Error Message in ' + locale.toUpperCase(),
                        })}
                        htmlFor={'Code Rule Error Message in ' + locale.toUpperCase()}
                        isRequired
                      />
                      <TextArea
                        placeholder={formatMessage({
                          id: 'Code Rule Error Message in ' + locale.toUpperCase(),
                        })}
                        autoComplete='off'
                        name={'error_message_' + locale}
                        type='text'
                        formik={formik}
                        dir={locale === 'ar' ? 'rtl' : 'ltr'}
                        rows='6'
                        disabled={mode === 'view'}
                      />
                    </div>
                  ))}
                  {/*  --------------------- end Code Rule Error Message en/ar-----------------------  */}
                  {/*  ------------------------ Code Rule Date Range -----------------------  */}
                  {formik.values.category.value === categoryTypesOptions[0].value && (
                    <>
                      <div className='col-12'>
                        <label className={'form-label fs-6 my-1 fw-bolder text-dark required'}>
                          {formatMessage({
                            id: 'Date Range',
                          })}
                        </label>
                      </div>
                      <div className='row'>
                        <div className='col-md-4'>
                          <DatePicker
                            isClearable={mode !== 'view'}
                            selected={
                              formik.values.start_date ? new Date(formik.values.start_date) : null
                            }
                            dateFormat='dd/MM/yyyy'
                            placeholderText={formatMessage({
                              id: 'Start Date',
                            })}
                            id='start_date_id'
                            className='form-control form-control-md b-radius-16 basic-input '
                            onChange={(date: any) =>
                              formik.setFieldValue(
                                `start_date`,
                                date
                                  ? // remove offset
                                    new Date(
                                      date.getTime() - date.getTimezoneOffset() * 60000
                                    ).toISOString()
                                  : ''
                              )
                            }
                            maxDate={new Date()}
                            onChangeRaw={(e) => {
                              setFieldTouched(`start_date`, true, true);
                            }}
                            autoComplete='off'
                            disabled={mode === 'view'}
                          />
                          <ErrorMessage name='start_date' formik={formik} />
                        </div>
                        <div className='col-md-4 '>
                          <DatePicker
                            isClearable={mode !== 'view'}
                            selected={
                              formik.values.end_date ? new Date(formik.values.end_date) : null
                            }
                            dateFormat='dd/MM/yyyy'
                            placeholderText={formatMessage({
                              id: 'Start Date',
                            })}
                            id='end_date_id'
                            className='form-control form-control-md b-radius-16 basic-input '
                            onChange={(date: any) =>
                              formik.setFieldValue(
                                `end_date`,
                                date
                                  ? // remove offset
                                    new Date(
                                      date.getTime() - date.getTimezoneOffset() * 60000
                                    ).toISOString()
                                  : ''
                              )
                            }
                            maxDate={new Date()}
                            onChangeRaw={(e) => {
                              setFieldTouched(`end_date`, true, true);
                            }}
                            autoComplete='off'
                            disabled={
                              mode === 'view' ||
                              !formik.values.start_date ||
                              formik.values.present === 1
                            }
                          />
                          <ErrorMessage name='end_date' formik={formik} />
                        </div>
                        {/*  ------------------------ present check -----------------------  */}
                        <div className='col-md-4 my-3'>
                          <Input
                            disabled={mode === 'view'}
                            className={'form-check-input'}
                            type={'checkbox'}
                            name={'present'}
                            id='present'
                            defaultChecked={false}
                            checked={formik.values.present === 0 ? false : true}
                            onChange={(value: any) => {
                              return formik.setFieldValue(`present`, value.target.checked ? 1 : 0);
                            }}
                            onBlur={() => formik.setFieldTouched(`present`, true)}
                          />{' '}
                          {<Label msg='Present' htmlFor='present' />}
                        </div>
                      </div>
                      {formik.values.start_date && formik.values.present === 1 && (
                        <div>
                          {formatMessage(
                            {
                              id: 'Transaction date should be greater than or equal {startDate} till present. If not , show the error message',
                            },
                            { startDate: formatDate(formik.values.start_date) }
                          )}
                        </div>
                      )}
                      {formik.values.start_date &&
                        formik.values.end_date &&
                        formik.values.present === 0 &&
                        !formik.errors.start_date && (
                          <div>
                            {formatMessage(
                              {
                                id: 'Transaction date should be greater than or equal {startDate} and less than {endDate}. If not , show the error message',
                              },
                              {
                                startDate: formatDate(formik.values.start_date),
                                endDate: formatDate(formik.values.end_date),
                              }
                            )}
                          </div>
                        )}
                    </>
                  )}
                  {/*  --------------------- end present check -----------------------  */}
                  {/*  --------------------- end Code Rule Date Range-----------------------  */}
                  {/*  ------------------------ Code Rule Amount -----------------------  */}
                  {formik.values.category.value === categoryTypesOptions[1].value && (
                    <>
                      <div className='col-12'>
                        <label className={'form-label fs-6 my-1 fw-bolder text-dark required'}>
                          {formatMessage({
                            id: 'Amount',
                          })}
                        </label>
                      </div>
                      <div className='col-md-6'>
                        <Input
                          setTouchedOnInput
                          placeholder={formatMessage({
                            id: 'Min',
                          })}
                          // required
                          autoComplete='off'
                          // labelId={formatMessage({
                          //   id: 'Amount',
                          // })}
                          name={'min_amount'}
                          type={'text'}
                          formik={formik}
                          disabled={mode === 'view'}
                        />
                      </div>
                      <div className='col-md-6 '>
                        <Input
                          setTouchedOnInput
                          placeholder={formatMessage({
                            id: 'Max',
                          })}
                          // required
                          autoComplete='off'
                          // labelId={formatMessage({
                          //   id: 'Amount',
                          // })}
                          name={'max_amount'}
                          type={'text'}
                          formik={formik}
                          disabled={mode === 'view'}
                        />
                      </div>

                      {formik.values.min_amount &&
                        !formik.values.max_amount &&
                        !formik.errors.min_amount &&
                        !formik.errors.max_amount && (
                          <div>
                            {formatMessage(
                              {
                                id: 'Transaction amount should be greater than or equal {minAmount}. If not , show the error message',
                              },
                              { minAmount: formik.values.min_amount }
                            )}
                          </div>
                        )}
                      {formik.values.min_amount &&
                        formik.values.max_amount &&
                        !formik.errors.min_amount &&
                        !formik.errors.max_amount && (
                          <div>
                            {formatMessage(
                              {
                                id: 'Transaction amount should be greater than or equal {minAmount} and less than or equal {maxAmount}. If not , show the error message',
                              },
                              {
                                minAmount: formik.values.min_amount,
                                maxAmount: formik.values.max_amount,
                              }
                            )}
                          </div>
                        )}
                    </>
                  )}
                  {/*  --------------------- end Code Rule Amount-----------------------  */}
                  {/*  ------------------------ Code Rule Third party nationality -----------------------  */}
                  {formik.values.category.value === categoryTypesOptions[2].value && (
                    <>
                      {/*  --------------------- Nationalities -----------------------  */}
                      <div className='col-md-6 mb-3'>
                        <Label
                          msg='Nationalities'
                          htmlFor='nationalities-select'
                          isRequired={true}
                        />
                        <Select
                          placeholder={$t('Nationalities')}
                          className='react-select smaller'
                          id='nationalities-select'
                          value={formik.values.nationalities}
                          onChange={(value) => {
                            formik.setFieldValue('nationalities', value);
                          }}
                          onBlur={() => formik.setFieldTouched('nationalities', true)}
                          options={countires?.map((nationality: any) => ({
                            value: nationality.iso,
                            label: nationality?.emoji + ' ' + nationality?.name?.[locale],
                          }))}
                          isDisabled={mode === 'view'}
                          isMulti
                        />
                        {/* error message  */}
                        {formik.errors?.nationalities && formik.touched?.nationalities && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id={'This field is required'} />
                            </span>
                          </div>
                        )}
                      </div>
                      {/*  --------------------- end Nationalities -----------------------  */}

                      {/*  ------------------------ is not check -----------------------  */}
                      <div className='col-md-6 mt-10 '>
                        <Input
                          disabled={mode === 'view'}
                          className={'form-check-input'}
                          type={'checkbox'}
                          name={'is_not'}
                          id='is_not'
                          defaultChecked={false}
                          checked={formik.values.is_not === 0 ? false : true}
                          onChange={(value: any) => {
                            return formik.setFieldValue(`is_not`, value.target.checked ? 1 : 0);
                          }}
                          onBlur={() => formik.setFieldTouched(`is_not`, true)}
                        />{' '}
                        {<Label msg='Is not' htmlFor='is_not' />}
                      </div>
                      {/*  ------------------------end is not check -----------------------  */}

                      {formik.values.is_not === 0 && (
                        <div>
                          {formatMessage({
                            id: 'Third parties of the linked transactions must have the chosen nationalities. If not , show the error message.',
                          })}
                        </div>
                      )}
                      {formik.values.is_not === 1 && (
                        <div>
                          {formatMessage({
                            id: 'Third parties of the linked transactions must not have the chosen nationalities. If not , show the error message.',
                          })}
                        </div>
                      )}
                    </>
                  )}
                  {/*  --------------------- end Code Rule Third party nationality-----------------------  */}
                  {/*  ------------------------ Code Rule Third party type -----------------------  */}
                  {formik.values.category.value === categoryTypesOptions[3].value && (
                    <>
                      {/*  --------------------- Third party types -----------------------  */}
                      <div className='col-md-6 mb-3'>
                        <Label
                          msg='Third party type'
                          htmlFor='third_party_types-select'
                          isRequired={true}
                        />
                        <Select
                          placeholder={$t('Third party type')}
                          className='react-select smaller'
                          id='third_party_types-select'
                          value={formik.values.third_party_types}
                          onChange={(value) => {
                            formik.setFieldValue('third_party_types', value);
                          }}
                          onBlur={() => formik.setFieldTouched('third_party_types', true)}
                          options={codeRulesThirdPartyTypeMapsOptions}
                          isDisabled={mode === 'view'}
                          isMulti
                        />
                        {/* error message  */}
                        {formik.errors?.third_party_types && formik.touched?.third_party_types && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id={'This field is required'} />
                            </span>
                          </div>
                        )}
                      </div>
                      {/*  --------------------- end Third party types -----------------------  */}

                      <div>
                        {formatMessage({
                          id: 'Third parties of the linked transactions must have the chosen types. If not , show the error message.',
                        })}
                      </div>
                    </>
                  )}
                  {/*  --------------------- end Code Rule Third party type-----------------------  */}
                  {/*  ------------------------ Code Rule Transaction date -----------------------  */}
                  {formik.values.category.value === categoryTypesOptions[4].value && (
                    <>
                      {/*  --------------------- Third Transaction date -----------------------  */}
                      <div className='col-md-6 mb-3'>
                        <Label msg='Operator' htmlFor='operator-select' isRequired={true} />
                        <Select
                          placeholder={$t('Operator')}
                          className='react-select smaller'
                          id='operator-select'
                          value={formik.values.operator}
                          onChange={(value) => {
                            formik.setFieldValue('operator', value);
                          }}
                          onBlur={() => formik.setFieldTouched('operator', true)}
                          options={codeRulesOperatorTypeMapsOptions}
                          isDisabled={mode === 'view'}
                          // isMulti
                        />
                        {/* error message  */}
                        {formik.errors?.operator && formik.touched?.operator && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id={'This field is required'} />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        <Input
                          setTouchedOnInput
                          placeholder={formatMessage({
                            id: 'Number of months',
                          })}
                          required
                          autoComplete='off'
                          labelId={formatMessage({
                            id: 'Number of months',
                          })}
                          name={'period'}
                          type={'text'}
                          formik={formik}
                          disabled={mode === 'view'}
                        />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <Label msg='Comparison column' htmlFor='column-select' isRequired={true} />
                        <Select
                          placeholder={$t('Comparison column')}
                          className='react-select smaller'
                          id='column-select'
                          value={formik.values.column}
                          onChange={(value) => {
                            formik.setFieldValue('column', value);
                          }}
                          onBlur={() => formik.setFieldTouched('column', true)}
                          options={codeRulesColumnTypeMapsOptions}
                          isDisabled={mode === 'view'}
                          // isMulti
                        />
                        {/* error message  */}
                        {formik.errors?.column && formik.touched?.column && (
                          <div className='fv-plugins-message-container mt-2 mx-3 text-danger'>
                            <span role='alert' className='text-danger'>
                              <FormattedMessage id={'This field is required'} />
                            </span>
                          </div>
                        )}
                      </div>
                      {/*  --------------------- end Third Transaction date -----------------------  */}

                      {formik.values.operator.value === 1 && +formik.values.period > 0 && (
                        <div>
                          {formatMessage(
                            {
                              id: 'Transaction date should not be before than {period} month/s of the report date. If not , show the error message.',
                            },
                            {
                              period: formik.values.period,
                            }
                          )}
                        </div>
                      )}
                      {formik.values.operator.value === 2 && +formik.values.period > 0 && (
                        <div>
                          {formatMessage(
                            {
                              id: 'Transaction date should not be later than {period} month/s of the report date. If not , show the error message.',
                            },
                            {
                              period: formik.values.period,
                            }
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {/*  --------------------- end Code Rule Transaction date-----------------------  */}
                </div>

                {/* Save and Cancel Buttons */}
                {mode === 'create' && (
                  <>
                    <SaveCancelBtnsGroup
                      saveBtnLabel='save'
                      formik={formik}
                      loading={loading}
                      cancelOnClick={() => {
                        history.push(`/admin/code-rules`);
                      }}
                    />
                  </>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const ConfigNewCodeRuleCreate: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Code Rules' })}</PageTitle>
      {/* <div>Create New Code Rule</div> */}
      <ConfigNewCodeRule mode='create' />
    </div>
  );
};

const ConfigNewCodeRuleEdit: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Code Rules' })}</PageTitle>
      <ConfigNewCodeRule mode='edit' />
    </div>
  );
};

const ConfigNewCodeRuleView: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='d-flex flex-column flex-root'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Code Rules' })}</PageTitle>
      <ConfigNewCodeRule mode='view' />
    </div>
  );
};

export { ConfigNewCodeRuleCreate, ConfigNewCodeRuleEdit, ConfigNewCodeRuleView };
