import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  onClick?: Function;
  btnType?: 'outlined' | 'primary' | '';
  className?: string;
  fa: string;
  faIconMenu?: boolean;
  nameId: string;
  disabled?: boolean;
  testid?: string;
};
export const Button: FC<Props> = ({
  onClick,
  btnType,
  className,
  fa,
  faIconMenu,
  nameId,
  disabled,
  testid,
}) => {
  return (
    <button
      className={className ? `${className}` : 'btn btn-outlined me-2'}
      onClick={() => onClick && onClick()}
      disabled={disabled}
      data-testid={testid}
      id={testid}
    >
      {faIconMenu && (
        <span className='menu-icon'>
          <i className={`far fa-${fa}`}></i> &nbsp;
        </span>
      )}
      {!faIconMenu && <i className={`far fa-${fa}`}></i>}

      <FormattedMessage id={nameId} />
    </button>
  );
};
