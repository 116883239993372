import { FC } from 'react';
// import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { useLayout } from '../../core';
import { AsideMenu } from './AsideMenu';
// import { FormattedMessage } from 'react-intl';
import { UserType } from '../../../../app/types/companies';
import { KTSVG } from '../../../helpers'; //, toAbsoluteUrl

type Props = {
  user: UserType;
};
const AsideDefault: FC<Props> = ({ user }) => {
  const { config, classes } = useLayout();
  const { aside } = config;
  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* <div className='main-menu-msg-container'>
        <span className='border-line small-area'></span>
        <span className='aside-main-menu pt-1'>
          <FormattedMessage id='Main Menu' />
        </span>
        <span className='border-line large-area'></span>
      </div> */}
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto d-none' id='kt_aside_logo'>
        {/* begin::Logo */}

        {/* end::Logo */}
      </div>
      {/* begin::Aside toggler */}
      {aside.minimize && (
        <div
          id='kt_aside_toggle'
          className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle mt-3 '
          data-kt-toggle='true'
          data-kt-toggle-state='active'
          data-kt-toggle-target='body'
          data-kt-toggle-name='aside-minimize'
        >
          <KTSVG
            path={'/media/icons/duotune/arrows/arr080.svg'}
            className={'svg-icon-1 rotate-180'}
          />
        </div>
      )}
      {/* end::Aside toggler */}

      {/* end::Brand */}
      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} user={user} />
      </div>

      {/* end::Aside menu */}
    </div>
  );
};

export { AsideDefault };
