import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../interface/ActionWithPayload';

import { Search } from '../../../types/types';
import { getAllPurchasesInvoiceItems, getPurchasesLineItem } from './PurchasesLineItemsCRUD';

export enum IPurchasesActionTypes {
  GET_PURCHASES_LINE_ITEMS = 'GET_PURCHASES_LINE_ITEMS',
  FULL_FILL_PURCHASES_LINE_ITEMS = 'FULL_FILL_PURCHASES_LINE_ITEMS',
  GET_CURRENT_PURCHASE_LINE_ITEMS = 'GET_CURRENT_PURCHASE_LINE_ITEMS',
  FULL_FILL_CURRENT_PURCHASE_LINE_ITEMS = 'FULL_FILL_CURRENT_PURCHASE_LINE_ITEMS',
}

const initialPurchases: IPurchasesState = {
  purchasesLineItems: undefined,
  currentPurchaseLineItems: undefined,
};

export interface IPurchasesState {
  purchasesLineItems?: any;
  currentPurchaseLineItems?: any;
}

export const reducer = persistReducer(
  { storage, key: 'USER_purchases_line_items', whitelist: [''] },
  (state: IPurchasesState = initialPurchases, action: ActionWithPayload<IPurchasesState>) => {
    switch (action.type) {
      case IPurchasesActionTypes.FULL_FILL_PURCHASES_LINE_ITEMS:
        return { ...state, purchasesLineItems: action.payload?.purchasesLineItems };
      case IPurchasesActionTypes.FULL_FILL_CURRENT_PURCHASE_LINE_ITEMS:
        return { ...state, currentPurchaseLineItems: action.payload?.currentPurchaseLineItems };

      default:
        return state;
    }
  }
);

export const actions = {
  getPurchasesLineItems: ({ companyId, params }: Search) => ({
    type: IPurchasesActionTypes.GET_PURCHASES_LINE_ITEMS,
    payload: { companyId, params },
  }),
  fullFillPurchasesLineItems: ({ purchasesLineItems }: IPurchasesState) => ({
    type: IPurchasesActionTypes.FULL_FILL_PURCHASES_LINE_ITEMS,
    payload: { purchasesLineItems },
  }),
  getCurrentPurchaseLineItems: ({ companyId, purchaseId }: any) => ({
    type: IPurchasesActionTypes.GET_CURRENT_PURCHASE_LINE_ITEMS,
    payload: { companyId, purchaseId },
  }),
  fullFillCurrentPurchaseLineItems: ({ currentPurchaseLineItems }: IPurchasesState) => ({
    type: IPurchasesActionTypes.FULL_FILL_CURRENT_PURCHASE_LINE_ITEMS,
    payload: { currentPurchaseLineItems },
  }),
};

export function* getPurchasesLineItems(action: ActionWithPayload<Search>) {
  try {
    const { data } = yield getAllPurchasesInvoiceItems({
      companyId: action.payload?.companyId,
      params: action.payload?.params,
    });
    yield put(actions.fullFillPurchasesLineItems({ purchasesLineItems: data }));
  } catch (error) {
    //
  }
}

export function* getCurrentPurchaseLineItems(action: ActionWithPayload<any>) {
  try {
    const { data } = yield getPurchasesLineItem({
      companyId: action.payload?.companyId,
      purchaseId: action.payload?.purchaseId,
    });
    yield put(actions.fullFillCurrentPurchaseLineItems({ currentPurchaseLineItems: data }));
  } catch (error) {
    //
  }
}

export function* saga() {
  yield takeLatest(IPurchasesActionTypes.GET_PURCHASES_LINE_ITEMS, getPurchasesLineItems);
  yield takeLatest(
    IPurchasesActionTypes.GET_CURRENT_PURCHASE_LINE_ITEMS,
    getCurrentPurchaseLineItems
  );
}
