/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { PageTitle } from '../../../../_metronic/layout/core';
import { SerachFilters } from '../../../shared/SearchFilters';
import * as TransactionRedux from '../../../modules/admins/transactions/store/TransactionsRedux';
import * as CompanyRedux from '../../../modules/admins/companies/store/CompaniesRedux';
import * as TableRedux from '../../../store/TableRedux';
import { TableData } from '../../../shared/tables/classes/TableData';
import { TableSelection } from '../../../shared/tables/classes/TableSelection';
import { DateViewer } from '../../../shared/tables/classes/DateViewer';
import { TransactionsDataListModel } from '../../../modules/auth/models/TransactionModel';
import { $t } from '../../../../_metronic/i18n/formatMessage';
import { CommonTable2 } from '../../../shared/tables/Table2';
import { KTSVG } from '../../../../_metronic/helpers';
// import { FormatMessage } from '../../../../_metronic/i18n/formatMessage'; //$t,
// import { useHistory } from 'react-router-dom';
// import { DeleteItem } from '../../../shared/tables/actions/DeleteItem';
// import { EditItem } from '../../../shared/tables/actions/EditItem';
// import { ViewItem } from '../../../shared/tables/actions/ViewItem';
import { TableStatus } from '../../../shared/tables/classes';
// import { TableActions } from '../../../shared/tables/classes/TableActions';
// import { useHistory } from 'react-router-dom';
// import { ViewItem } from '../../../shared/tables/actions/ViewItem';
// import { EditItem } from '../../../shared/tables/actions/EditItem';
// import { CompaniesDataListModel } from '../../../modules/companies/CompanyModel';

const headerItems = [
  { id: 'Transaction ID', maxWidth: 280 },
  { id: 'User name', maxWidth: 280 },
  { id: 'Company name', maxWidth: 280 },
  { id: 'Subscription plan name', maxWidth: 300 },
  { id: 'Subscription type (monthly / yearly)', maxWidth: 140 },
  { id: 'Payment amount', maxWidth: 80, width: 80 },
  { id: 'Transaction status', maxWidth: 80, width: 80 },
  { id: 'Subscription start date', maxWidth: 80, width: 80 },
  { id: 'End date (renewal date)', maxWidth: 80, width: 80 },
  { id: 'Save card', maxWidth: 80, width: 80 },
  // { id: 'Actions', maxWidth: 280 },
];

const TransactionsContent: FC = () => {
  const TABLE_NAME = 'sys_transactions_list';
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  // const history = useHistory();
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const transactions: TransactionsDataListModel = useSelector<RootState>(
    (state) => state.SYS_transactions.transactions,
    shallowEqual
  ) as TransactionsDataListModel;

  // const companies: CompaniesDataListModel = useSelector<RootState>(
  //   ({ SYS_companies }) => SYS_companies.companies,
  //   shallowEqual
  // ) as CompaniesDataListModel;

  const tables: any = useSelector<RootState>((store) => store.table.tables) as any;

  const table = tables[TABLE_NAME];

  useEffect(() => {
    dispatch(TransactionRedux.actions.getTransactions({ params: {} }));
    dispatch(CompanyRedux.actions.getCompanies({ params: {} }));
  }, []);

  function RequestData() {
    dispatch(
      TransactionRedux.actions.getTransactions({
        params: { ...table?.query, page: table?.page, per_page: table?.pagination },
      })
    );
  }

  useEffect(() => {
    RequestData();
  }, []);

  const searchFilterHandler = () => {
    if (table?.page) {
      RequestData();
    }
  };

  const clearSearchFilterHandler = () => {
    if (table?.page) {
      dispatch(TableRedux.actions.clearQuery({ tableName: TABLE_NAME }));
      RequestData();
    }
  };

  useEffect(() => {
    RequestData();
  }, [table?.page, table?.pagination]);

  const rowItems = transactions?.data.map((transaction) => [
    new TableSelection({ display: true, id: transaction.id, tableName: TABLE_NAME }),
    new TableData({ text: transaction.id ?? '', show_title_message: true }),
    new TableData({ text: transaction.user?.name ?? '' }),
    new TableData({ text: transaction.company?.name ?? '' }),
    new TableData({ text: transaction.plan?.name ?? '' }),
    new TableData({
      text: `${
        transaction.plan?.period_type === 1
          ? formatMessage({ id: 'monthly' })
          : formatMessage({ id: 'yearly' })
      }`,
    }),
    new TableData({ text: transaction.price ?? '' }),
    new TableStatus({
      statusId:
        transaction.status === 1
          ? formatMessage({ id: 'success' })
          : transaction.status === 2
          ? formatMessage({ id: 'pending' })
          : formatMessage({ id: 'failure' }),

      badge:
        transaction.status === 1
          ? 'badge-success'
          : transaction.status === 2
          ? 'badge-warning'
          : 'badge-danger',

      title: transaction.status_message,
    }),
    new DateViewer({ date: transaction?.subscription?.start_date ?? '' }),
    new DateViewer({ date: transaction?.subscription?.end_date ?? '' }),
    new TableData({
      text: transaction.save_card ? formatMessage({ id: 'yes' }) : formatMessage({ id: 'no' }),
    }),
    // new TableActions(
    //   (
    //     <div className='d-flex flex-row'>
    //       <ViewItem
    //         title={$t('View')}
    //         onClick={() => history.push(`/admin/transactions/${transaction.id}`)}
    //       />
    //       <EditItem
    //         title={$t('View')}
    //         onClick={() => history.push(`/admin/transactions/${transaction.id}/edit`)}
    //       />
    //     </div>
    //   )
    // ),
  ]);

  return (
    <div className='table-wrapper card'>
      {/* begin::Header */}
      <div className='card-header border-0 '>
        {/*  ------------------------ start search and filters ------------------------  */}

        <div className='card-toolbar align-items-end flex-row'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => {
              setShowSearchFilters(!showSearchFilters);
            }}
          >
            {!showSearchFilters && (
              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
            )}
            {showSearchFilters && (
              <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
            )}
          </button>
        </div>

        {/*  ------------------------- End Search and filters -------------------------  */}
      </div>
      {/* end::Header */}

      {showSearchFilters && (
        <div className='fw-bold m-auto' style={{ width: '95%' }}>
          <div className='px-3'>
            <div className=' fs-6 text-dark fw-bolder px-3 py-4'>{$t('Search')} </div>
          </div>
          <div className='separator mb-3 opacity-75'></div>
          <SerachFilters
            leftHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'search',
                queryTerm: 'name',
                onChange: (value: string) => {
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'name',
                    })
                  );
                },
                placeholderId: 'Search anything',
              },
            ]}
            rightHandSide={[
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'dropdown',
                queryTerm: 'status',
                onChange: (value: any) =>
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'status',
                    })
                  ),
                placeholderId: 'Transaction status',
                options: [
                  { value: '1', label: formatMessage({ id: 'success' }) },
                  { value: '2', label: formatMessage({ id: 'pending' }) },
                  { value: '3', label: formatMessage({ id: 'failure' }) },
                ],
              },
              {
                TABLE_NAME: `${TABLE_NAME}`,
                type: 'dropdown',
                queryTerm: 'period_type',
                onChange: (value: any) =>
                  dispatch(
                    TableRedux.actions.updateQuery({
                      value: value,
                      tableName: TABLE_NAME,
                      queryTerm: 'period_type',
                    })
                  ),
                placeholderId: 'Subscription type (monthly / yearly)',
                options: [
                  { value: '1', label: formatMessage({ id: 'monthly' }) },
                  { value: '2', label: formatMessage({ id: 'yearly' }) },
                ],
              },
            ]}
            searchFilterHandler={searchFilterHandler}
            clearSearchFilterHandler={clearSearchFilterHandler}
          />
        </div>
      )}
      {transactions?.data && (
        <CommonTable2
          className='pb-5 pb-xl-8'
          tableName={TABLE_NAME}
          headerWithCheckbox
          allDisplayedIds={transactions?.data?.map((transaction) => transaction.id) || []}
          meta={transactions.meta}
          headerItems={headerItems}
          rowItems={rowItems}
          initPerPage={20}
        />
      )}
    </div>
  );
};

const TransactionsWrapper: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='d-flex flex-column flex-root' data-testid='page-title'>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'Transactions' })}</PageTitle>
      <TransactionsContent />
    </div>
  );
};

export { TransactionsWrapper };
